import { Fragment } from 'react';

import Spacing from 'src/design/styleguide/spacing/Spacing';
import TextInputWithError from 'src/design/components/textInput/TextInputWithError';
import InputGroupSubHeading from 'src/components/landingPages/smsf/formInputGroups/InputGroupSubHeading';
import { validateAbn } from 'src/utils/validation';

const SuperFormInputs = ({ trustName, abn, trusteeName, onChange, showErrors, hideTitle }) => {
  const abnCheck = validateAbn(abn);
  return (
    <Fragment>
      {!hideTitle
        ? (
          <InputGroupSubHeading
            testRef="super-fund-input-group-subheading"
          >Super Fund Information</InputGroupSubHeading>
        )
        : null}

      <Spacing bottom="x-small" top={hideTitle ? 'x-large' : null}>
        <TextInputWithError
          name="trustName"
          placeholder="Fund Name"
          onChange={value => onChange({ trustName: value })}
          value={trustName}
          error={showErrors && !trustName && 'Please enter a valid Trust name'}
          testRef="trustName-text-input"
          errorTestRef="trustName-error"
        />
      </Spacing>

      <Spacing bottom="x-small">
        <TextInputWithError
          name="abn"
          placeholder="ABN"
          onChange={value => onChange({ abn: value })}
          value={abn}
          isCharacterValid={TextInputWithError.validation.numbersOnly}
          isStringValid={TextInputWithError.validation.maxLength(11)}
          error={showErrors && !abnCheck.valid && abnCheck.reason}
          testRef="abn-text-input"
          errorTestRef="abn-error"
        />
      </Spacing>

      <Spacing bottom="x-small">
        <TextInputWithError
          name="trusteeName"
          placeholder="Trustee Name"
          onChange={value => onChange({ trusteeName: value })}
          value={trusteeName}
          error={showErrors && !trusteeName && 'Please enter the full name of the Trustee'}
          testRef="trusteeName-text-input"
          errorTestRef="trusteeName-error"
        />
      </Spacing>
    </Fragment>
  );
};

export default SuperFormInputs;
