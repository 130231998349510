import { createTheme } from '@mui/material';
import Colors from 'src/themes/brickx/colors';

const MuiTheme = createTheme({
  palette: {
    primary: {
      main: Colors.primary,
      light: Colors.pink50,
      contrastText: '#FFFFFF',
    },
  },
  components: {
    MuiIconButton: {
      defaultProps: {
        disableTouchRipple: true,
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        disableTouchRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disableTouchRipple: true,
      },
      styleOverrides: {
        root: {
          fontSize: 16,
          boxShadow: 'none',
          borderRadius: 6,
          textTransform: 'uppercase',
        },
        sizeLarge: {
          fontSize: 18,
        },
        sizeMedium: {
          fontSize: 16,
          letterSpacing: 1,
        },
        sizeSmall: {
          fontSize: 12,
        },
        '&:hover': {
          boxShadow: 'none',
        },
      },
    },
    MuiToggleButton: {
      defaultProps: {
        disableTouchRipple: true,
      },
    },
    MuiInputBase: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontSize: 18,
          boxShadow: null,
          borderRadius: 8,
        },
        input: {
          background: 'white!important',
          border: 'none!important',
          padding: '16.5px 14px!important',
        },
      },
      defaultProps: {},
    },
  },
  typography: {
    fontFamily: 'Calibre, sans-serif',
    fontSize: 22,
  },
  shadows: Array(25).fill('none'),
  h1: { fontWeight: 800 },
  h2: { fontWeight: 800 },
  h3: { fontWeight: 800 },
  h4: { fontWeight: 800 },
  h5: { fontWeight: 800 },
  h6: { fontWeight: 800 },
});

export default MuiTheme;
