import styled from 'styled-components';

const Layout = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  color: ${(p) => (p.disabled ? '#aaa' : p.isSelected ? 'white' : 'inherit')};
  border-radius: var(--bx-radius-lg);
  background: ${(p) =>
    p.disabled ? '#eee' : p.isSelected ? p.bgColor : 'transparent'};
  transition: all 0.1s;
  user-select: none;
  ${(p) => (p.isSelected ? 'box-shadow: var(--bx-shadow);' : '')}
  &:hover {
    cursor: pointer;
    filter: brightness(1.1);
    background-color: #d1d1d1;
  }
  &:active {
    transform: translateY(2px);
  }
  .bx-icon-checkbox--icon {
    font-size: 4rem;
    margin-bottom: -10px;
  }
  .bx-icon-checkbox--text {
    text-align: center;
    font-weight: 800;
    max-width: 100px;
    margin-top: 5px;
    line-height: 1.5rem;
  }
`;

export const IconCheckbox = ({
  icon,
  text,
  onClick,
  isSelected,
  disabled,
  testRef,
  colorSuite,
}) => {
  const selectedColorSuite = colorSuite
    ? {
        yolk: 'var(--bx-color---gradient-yolk)',
        blue: 'var(--bx-color---gradient-blue)',
      }[colorSuite]
    : 'var(--bx-color---gradient)';
  return (
    <Layout
      disabled={disabled}
      isSelected={isSelected}
      onClick={() => {
        if (onClick && !disabled) {
          onClick();
        }
      }}
      bgColor={selectedColorSuite}
      data-test-reference={testRef}
    >
      <div className="bx-icon-checkbox--icon">{icon}</div>
      <div className="bx-icon-checkbox--text">{text}</div>
    </Layout>
  );
};
