import PropertyCardStat from 'src/components/propertyCards/common/propertyCardStat/PropertyCardStat';
import { getHistoricalGrowthMetricsHighestNumberOfYearsGrowth, getPropertyCardHistoricalGrowthLabelYears } from 'scripts/utilities/propertyHelper';
import { historicalGrowthCardTitle } from 'src/settings/text';


const HistoricalSuburbGrowthStat = ({ historicalGrowthMetrics, propertyType }) => (
  <PropertyCardStat
    label={<span>{getPropertyCardHistoricalGrowthLabelYears(historicalGrowthMetrics)} {historicalGrowthCardTitle(propertyType)}</span>}
    value={ <span>{getHistoricalGrowthMetricsHighestNumberOfYearsGrowth(historicalGrowthMetrics)} <small>p.a.</small></span>}
    labelProps={{ 'data-test-reference': 'historical-growth-label' }}
    valueProps={{ 'data-test-reference': 'historical-growth-value' }}
  />
);

export default HistoricalSuburbGrowthStat;
