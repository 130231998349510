import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DocumentMeta from 'react-document-meta';

import { property as propertyPropType, monthlyDistributions as monthlyDistributionsPropType, monthlyUpdates as monthlyUpdatesPropType } from 'scripts/constants/PropTypes';

import { monthlyDistributionsSelector } from 'scripts/redux/selectors/property';

import { sendPropertyTabLoad } from 'scripts/redux/actions/segment/events/propertiesPageEvents';
import { fetchMonthlyDistributions } from 'scripts/redux/actions/property';

import CriticalNews from 'scripts/components/property/CriticalNews';
import CashFlowChart from 'scripts/components/property/CashFlowChart';
import KeyMetrics from 'scripts/components/property/distributions/KeyMetrics';
import YieldCalculations from 'scripts/components/property/distributions/YieldCalculations';
import ForecastUnitCashFlow from 'scripts/components/property/distributions/ForecastUnitCashFlow';
import ActualMonthlyDistributions from 'scripts/components/property/distributions/ActualMonthlyDistributions';
import Loading from 'src/components/loading/Loading';
import PrevNextBar from 'scripts/components/property/PrevNextBar';

import { DISTRIBUTIONS_META } from 'scripts/metadata/properties';


const mapStateToProps = (state) => ({
  ...monthlyDistributionsSelector(state)
});

@connect(mapStateToProps, {
  fetchMonthlyDistributions
})
export default class PropertyDistributions extends Component {
  static propTypes = {
    property: propertyPropType,
    isPreMarketOpenProperty: PropTypes.bool.isRequired,
    fetchMonthlyDistributions: PropTypes.func.isRequired,
    monthlyDistributions: monthlyDistributionsPropType,
    monthlyUpdates: monthlyUpdatesPropType
  };

  componentDidMount() {
    this._fetchMonthlyDistributions();
    sendPropertyTabLoad({ tabName: 'Monthly Distributions', propertyCode: this.props.property.propertyCode });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.property && nextProps.property !== this.props.property) {
      this._fetchMonthlyDistributions(nextProps);
    }
  }

  render() {
    const { property, isPreMarketOpenProperty, monthlyDistributions, monthlyUpdates } = this.props;
    const DISTRIBUTION_DISPLAYED = 12;
    if (!monthlyDistributions || !monthlyUpdates) {
      return <Loading/>;
    }
    return (
      <div className="property-overview monthly-distributions">
        <DocumentMeta {...DISTRIBUTIONS_META(property)} />
        <div className="container">
          <div>
            <DocumentMeta {...DISTRIBUTIONS_META(property)} />
            <div className="row">
              <div className="col-md-12">
                <CriticalNews criticalNews={property.criticalNews}/>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <KeyMetrics
                  property={property}
                  isPreMarketOpenProperty={isPreMarketOpenProperty}
                  monthlyDistributions={monthlyDistributions}
                  monthlyUpdates={monthlyUpdates}/>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <YieldCalculations property={property}/>
              </div>
            </div>
            <div className="row">
              <div className="col-md-5">
                <ForecastUnitCashFlow property={property}/>
              </div>
              <div className="col-md-7">
                <ActualMonthlyDistributions
                  monthlyDistributions={monthlyDistributions.slice(0, DISTRIBUTION_DISPLAYED)}
                  isPreMarketOpenProperty={isPreMarketOpenProperty}
                  property={property}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <CashFlowChart property={property}/>
              </div>
            </div>
            <PrevNextBar
              prevTitle="Summary"
              prevLink={`/properties/${property.propertyCode}/summary`}
              nextTitle="Capital Returns"
              nextLink={`/properties/${property.propertyCode}/returns`}/>
          </div>
        </div>
      </div>
    );
  }

  _fetchMonthlyDistributions(props = this.props) {
    const { fetchMonthlyDistributions, property: { propertyCode } } = props;
    fetchMonthlyDistributions(propertyCode);
  }
}
