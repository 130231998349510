const statsGetter = (state) => state.statistics.latestStats;
export const latestStatsSelector = (state) => ({
  latestStats: statsGetter(state)
});

const transactionsGetter = (state) => state.statistics.recentTrades;
export const recentPropertyTransactionsSelector = (state) => ({
  recentPropertyTransactions: transactionsGetter(state)
});

