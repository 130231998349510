import Spacing from 'src/design/styleguide/spacing/Spacing';
import StyledBodylink from 'src/design/components/hyperlink/StyledBodylink';


const OnboardingBackButton = (props) => (
  <Spacing top="2x-large">
    <StyledBodylink
      grey
      fullWidth
      testRef="onboarding-back-button"
      {...props}
    >
      Go Back
    </StyledBodylink>
  </Spacing>
);

export default OnboardingBackButton;
