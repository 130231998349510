import classNames from 'classnames';
import { Heading } from 'src/design/components/heading/Headings';
import styles from 'src/components/landingPages/common/coloredAngularBackgroundSection/ColoredAngularBackgroundSection.mscss';

const colorClass = (color) => styles[`coloredAngularBackground-${color}-background`];

const ColoredAngularBackgroundSection = ({
  color = 'coral',
  className,
  scrollToId,
  title,
  subtitle,
  children
}) => (
<section
  className={classNames(styles.coloredAngularBackground, className, colorClass(color))}
  id={scrollToId}
>
  <div className={classNames(styles['coloredAngularBackground--innerContainer'])}>
    <Heading><h2>{title}</h2></Heading>
    {subtitle
      ? (
        <div className={styles['coloredAngularBackground--subHeading']}>
          {subtitle}
        </div>
      )
      : null}

    {children}
  </div>
</section>
);

ColoredAngularBackgroundSection.colors = {
  PINK50: 'pink-50',
  CORAL: 'coral',
};

export default ColoredAngularBackgroundSection;
