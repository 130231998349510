import PropTypes from 'prop-types';
import { Link } from 'react-router';
import classNames from 'classnames';

import Popover from 'scripts/components/shared/Popover';
import Select from 'src/design/components/formSelect/Select';

const FinancialsPanel = ({
  title,
  titleIcon,
  linkText,
  link,
  linkIcon,
  className,
  children,
}) => (
  <div className={classNames('panel-financials-table', className)}>
    {title && (
      <div className="header">
        <div className="row">
          <div
            className={classNames('panel-financials-title', {
              'col-md-12': !linkText,
              'col-md-8': linkText,
            })}
          >
            {titleIcon && (
              <span className={`icn icn-small ${titleIcon}`}>&nbsp;</span>
            )}
            {link && <Link to={link}>{title}</Link>}
            {!link && title}
          </div>
          {linkText && (
            <div className="link hidden-xs col-md-4 text-right">
              {linkIcon && <span className={linkIcon}>&nbsp;</span>}
              {link && (
                <Link to={link}>
                  {linkText}&nbsp;<span className="fa fa-angle-right"></span>
                </Link>
              )}
              {!link && linkText}
            </div>
          )}
        </div>
      </div>
    )}

    <div className="body">{children}</div>
  </div>
);

FinancialsPanel.propTypes = {
  title: PropTypes.string,
  titleIcon: PropTypes.string,
  linkText: PropTypes.string,
  link: PropTypes.string,
  children: PropTypes.node,
};

const icons = {
  coins: <span className="icn icn-medium icn-coins" aria-hidden="true" />,
  graph: (
    <span className="icn icn-medium icn-line-chart-blue" aria-hidden="true" />
  ),
  gear: <span className="icn icn-medium icn-gear" aria-hidden="true" />,
  roi: <span className="icn icn-medium icn-roi" aria-hidden="true" />,
};

const RowContainer = ({ children, className }) => (
  <div className={classNames('financials-panel-row', className)}>
    {children}
  </div>
);

const SectionTitle = ({ title, icon, className, noPaddingTop }) => (
  <RowContainer
    className={classNames({
      'financials-panel-row--no-padding-top': noPaddingTop,
    })}
  >
    <div
      className={classNames(
        'financials-panel__section-title financials-panel__text',
        className
      )}
    >
      {icon && (
        <span className="financials-panel__section-title-icon">
          {icons[icon]}
        </span>
      )}
      {title}
    </div>
  </RowContainer>
);

const RowTitle = ({ title, icon, className, iconClassName }) => (
  <div
    className={classNames(
      'financials-panel-row__title financials-panel__text',
      { 'financials-panel-row__title--no-icon': !icon },
      className
    )}
  >
    <div className={classNames('financials-panel-row__icon', iconClassName)}>
      {icons[icon]}
    </div>
    {title}
  </div>
);

const RowTitleWithSelector = ({
  title,
  options = [],
  selected,
  onChange,
  className,
  selectAboveTitle,
  testRef,
}) => {
  const titleElement = (
    <div
      className={classNames(
        'financials-panel-row__title financials-panel__text'
      )}
    >
      {title}
    </div>
  );
  return (
    <div
      className={classNames(
        'financials-panel-row__title-with-selector',
        className
      )}
      data-test-reference={testRef}
    >
      {!selectAboveTitle && titleElement}
      <Select value={selected} onChange={(e) => onChange(e.target.value)}>
        {options.map(({ text, value }) => (
          <option key={`${text} - ${value}`} value={value}>
            {text}
          </option>
        ))}
      </Select>
      {selectAboveTitle && titleElement}
    </div>
  );
};

const customisedBarWidth = (length) => {
  if (length) {
    return ['100%', '100%', '49%', '33%', '25%'][length];
  }
  return '19%';
};

const HorizontalBarSelector = ({
  options = [],
  selected,
  onChange,
  reverse,
  className,
}) => (
  <div
    className={classNames(
      'financials-panel-horizontal-selector',
      { 'financials-panel-horizontal-selector--reverse': reverse },
      className
    )}
  >
    {options.map((option) => {
      const isSelected = selected === option;
      return (
        <div
          key={option}
          className="financials-panel-horizontal-selector__option"
          style={{ width: customisedBarWidth(options.length) }}
          onClick={() => onChange(option)}
        >
          <div
            className={classNames(
              'financials-panel-horizontal-selector__text',
              {
                'financials-panel-horizontal-selector__text--selected':
                  isSelected,
              }
            )}
          >
            {option}
          </div>
          <div
            className={classNames('financials-panel-horizontal-selector__bar', {
              'financials-panel-horizontal-selector__bar--selected': isSelected,
            })}
          ></div>
        </div>
      );
    })}
  </div>
);

const RowValue = ({ value, popoverText, className, valueClassName }) => (
  <div className={classNames('financials-panel-row__value', className)}>
    <span className={valueClassName}>{value}</span>{' '}
    {popoverText && <Popover placement="top" content={popoverText} />}
  </div>
);

const RowValueTotal = ({ valueClassName, ...props }) => (
  <RowValue
    {...props}
    valueClassName={classNames(
      valueClassName,
      'financials-panel__text--orange'
    )}
  />
);

export default FinancialsPanel;
export {
  SectionTitle,
  RowContainer,
  RowTitle,
  RowTitleWithSelector,
  HorizontalBarSelector,
  RowValue,
  RowValueTotal,
};
