import { Fragment } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import styled from 'styled-components';
import { breakpointSmall } from 'src/design/styleguide/common/breakpoints';
import { gridUnit } from 'src/design/styleguide/common/measurements';
import { smsfSubmittingSelector } from 'src/components/landingPages/smsf/smsfReducer';
import { userSelector } from 'scripts/redux/selectors/user';
import ClonedChild from 'src/design/styleguide/common/ClonedChild';
import ColoredAngularBackgroundSection from 'src/components/landingPages/common/coloredAngularBackgroundSection/ColoredAngularBackgroundSection';
import ContentSection from 'src/design/styleguide/contentSection/ContentSection';
import HowToInvest from 'src/components/landingPages/smsf/HowToInvest';
import Paragraph from 'src/design/components/bodyText/Paragraph';
import SmsfMeta from 'src/components/meta/SmsfMeta';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import SqmResearchRatingSection from 'src/components/landingPages/smsf/sqmResearchRatingSection/SqmResearchRatingSection';
import TopFAQsSection from 'src/components/landingPages/smsf/topFaqsSection/TopFAQsSection';
import WhyInvest from 'src/components/landingPages/smsf/WhyInvest';
import styles from 'src/components/landingPages/smsf/Smsf.mscss';

export const smsfHowToInvestId = () => 'how-to-invest';

const Testimonial = ({ name, image, message }) => (
  <div className={classNames(styles.testimonial)}>
    <img
      className={styles['testimonial--image']}
      src={image}
      alt={`Photo of ${name}`}
    />
    <div className={styles['testimonial--name']}>
      {name} | BrickX SMSF Investor
    </div>
    <div className={styles['testimonial--message']}>{message}</div>
  </div>
);

const SmsfPageSectionPadding = styled(ClonedChild)`
  && {
    padding-top: ${gridUnit * 20}px;
    padding-bottom: ${gridUnit * 20}px;

    ${breakpointSmall(`
      padding-top: ${gridUnit * 30}px;
      padding-bottom: ${gridUnit * 30}px;
      `)}
  }
`;

const Smsf = ({ user, smsfSubmitting }) => (
  <div>
    <SmsfMeta />

    <WhyInvest />

    <SmsfPageSectionPadding>
      <HowToInvest user={user} smsfSubmitting={smsfSubmitting} />
    </SmsfPageSectionPadding>

    <SmsfPageSectionPadding>
      <ContentSection backgroundColor="lightgrey">
        <TopFAQsSection />
      </ContentSection>
    </SmsfPageSectionPadding>

    <SmsfPageSectionPadding>
      <ColoredAngularBackgroundSection
        color="blue"
        title="What our customers are saying"
      >
        <div className={styles.testimonialListing}>
          <Testimonial
            name="Donna"
            image="/static/img/photo-smsf-testimonial-donna.png"
            message={
              <Fragment>
                <Spacing bottom="normal">
                  <Paragraph>
                    "I was attracted to BrickX because of the simplicity and
                    transparency of the structure."
                  </Paragraph>
                </Spacing>
                <Paragraph>
                  "BrickX gives me the flexibility I want to enter the property
                  market. At the same time I can diversify and invest in several
                  properties, spreading my risk."
                </Paragraph>
              </Fragment>
            }
          />
          <Testimonial
            name="Deon"
            image="/static/img/photo-smsf-testimonial-deon.png"
            message={
              <Paragraph>
                "BrickX gives me diversification into property with easy access
                to liquidity (if needed) via my SMSF that I cannot get anywhere
                else."
              </Paragraph>
            }
          />
        </div>
      </ColoredAngularBackgroundSection>
    </SmsfPageSectionPadding>

    <SqmResearchRatingSection />
  </div>
);

export default connect((state) => ({
  ...userSelector(state),
  ...smsfSubmittingSelector(state),
}))(Smsf);
