import ComplaintsMeta from 'src/components/meta/ComplaintsMeta';

import PageHeading from 'src/design/components/heading/PageHeading';
import PageContentBlock from 'src/design/components/pageContentBlock/PageContentBlock';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import Paragraph from 'src/design/components/bodyText/Paragraph';
import Styledlink from 'src/design/components/hyperlink/Styledlink';

const Complaints = () => (
  <div>
    <ComplaintsMeta/>
    <PageHeading>Complaints Handling</PageHeading>

    <PageContentBlock>
      <Spacing bottom="large">
        <Paragraph>
          We hope you will not have cause to do so, but if you have a complaint, in the first instance, please email us at info@brickx.com. Please include brief details of your complaint and your BrickX account if possible.  These details will be reviewed internally at BrickX and also further provided to the Compliance Manager at BrickX Financial Services Limited (BrickX Financial Services).
        </Paragraph>
        </Spacing>

      <Spacing bottom="large">
        <Paragraph>
          Acknowledgement of receipt of the complaint is to occur as soon as possible (generally within five working days), and every effort will be made to resolve the complaint within one month of date of receipt.
        </Paragraph>
      </Spacing>

      <Spacing bottom="large">
        <Paragraph>
          If your complaint is not resolved to your satisfaction after using our internal complaints procedures, you can contact the Australian Financial Complaints Authority (refer below contact details). BrickX Financial Services is a member of the Australian Financial Complaints Authority.
        </Paragraph>
      </Spacing>
      <Spacing bottom="large">
        <Paragraph>
          <strong>Australian Financial Complaints Authority</strong><br/>
          Online: <Styledlink href="https://www.afca.org.au">www.afca.org.au</Styledlink> <br/>
          Email: <Styledlink href="mailto:info@afca.org.au">info@afca.org.au</Styledlink> <br/>
          Phone: 1800 931 678 (free call)<br/>
          Mail: Australian Financial Complaints Authority <br/>
          GPO Box 3, Melbourne VIC 3001
        </Paragraph>
      </Spacing>
    </PageContentBlock>
  </div>
);

export default Complaints;
