import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { tradeSucceed as tradeSucceedPropType } from 'scripts/constants/PropTypes';
import { tradeSucceedSelector } from 'scripts/redux/selectors/market';
import { trackBuySuccess } from 'src/tracking/tradeSuccess';
import Loading from 'src/components/loading/Loading';
import BuySuccessMeta from 'src/components/meta/BuySuccessMeta';
import TradeSuccess from 'src/components/trades/TradeSuccess';
import { brick } from 'scripts/utilities/formatters';
import { renderCode } from 'src/settings/properties';

@connect((state) => ({
  ...tradeSucceedSelector(state),
}))
export default class BuySuccess extends Component {
  static propTypes = {
    tradeSucceed: tradeSucceedPropType,
  };

  componentDidMount() {
    const { tradeSucceed } = this.props;
    if (!tradeSucceed) return;

    const { data } = tradeSucceed;

    trackBuySuccess({
      propertyCode: data.propertyCode,
      quantity: data.quantityTransacted,
      brickPrice: data.basisPrice,
      tradeAmount: data.valueOfBricks,
      commissionAmount: data.transactionFee,
      total: data.total,
    });
  }

  render() {
    const { tradeSucceed } = this.props;

    if (!tradeSucceed) {
      return (
        <Fragment>
          <BuySuccessMeta />
          <Loading />
        </Fragment>
      );
    }

    const {
      data: { propertyCode, quantityTransacted },
    } = tradeSucceed;

    const greeting = `Congratulations, you have bought ${quantityTransacted::brick()} in ${renderCode(
      propertyCode
    )}.`;

    return (
      <Fragment>
        <BuySuccessMeta />
        <TradeSuccess propertyCode={propertyCode} greeting={greeting} />
      </Fragment>
    );
  }
}
