import withState from 'src/decorators/withState';

const Interval = withState({
  Component: () => null,
  componentDidMount: ({ callImmediately, callback, timeout }, state, setState, { setOnThis }) => {
    if (callImmediately) { callback(); }
    const intervalId = setInterval(callback, timeout);
    setOnThis('intervalId', intervalId);
  },
  componentWillUnmount(props, state, { getFromThis }) {
    clearInterval(getFromThis('intervalId'));
  },
});

export default Interval;
