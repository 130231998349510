import classNames from 'classnames';
import BodyText from 'src/design/components/bodyText/BodyText';

import styles from 'src/design/components/bulletList/BulletList.mscss';


const columns = {
  ONE: 'ONE',
  TWO: 'TWO',
  THREE: 'THREE',
};

const BulletList = ({
  items,
  responsiveColumns,
  textSize,
  className,
  listItemClassName,
  noLeftMargin,
  style,
}) => {
  const columnClassMap = responsiveColumns
    ? {
      [styles.oneColumn]: responsiveColumns.base === columns.ONE,
      [styles.twoColumns]: responsiveColumns.base === columns.TWO,
      [styles.threeColumns]: responsiveColumns.base === columns.THREE,

      [styles['oneColumn--breakpoint-small']]: responsiveColumns.small === columns.ONE,
      [styles['twoColumns--breakpoint-small']]: responsiveColumns.small === columns.TWO,
      [styles['three--breakpoint-small']]: responsiveColumns.small === columns.THREE,

      [styles['oneColumn--breakpoint-medium']]: responsiveColumns.medium === columns.ONE,
      [styles['twoColumns--breakpoint-medium']]: responsiveColumns.medium === columns.TWO,
      [styles['threeColumns--breakpoint-medium']]: responsiveColumns.medium === columns.THREE,

      [styles['oneColumn--breakpoint-large']]: responsiveColumns.large === columns.ONE,
      [styles['twoColumns--breakpoint-large']]: responsiveColumns.large === columns.TWO,
      [styles['threeColumns--breakpoint-large']]: responsiveColumns.large === columns.THREE,
    }
  : { [styles.oneColumn]: true };

  return (
    <BodyText textSize={textSize}>
      <ul
        className={classNames(styles.BulletList, className, {
          ...columnClassMap,
        })}
        style={style}
      >
        {items.map((item, index) =>
          <li
            className={classNames(styles.BulletListItem, listItemClassName, { [styles['BulletListItem-noLeftMargin']]: noLeftMargin })}
            key={index}
          >
            {item}
          </li>
        )}
      </ul>
    </BodyText>
  );
};

BulletList.columns = columns;

export default BulletList;
