import { getBmoTransactions, getSiTransactions } from 'src/utils/transactions';
import Loading from 'src/components/loading/Loading';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import Tabs from 'src/design/components/tabs/Tabs';

const hasMoreThanOnePlan = (detailedTransactions) =>
  detailedTransactions.length > 1;
const hasTransactionsInBothPlans = (detailedTransactions) =>
  detailedTransactions.every(({ transactions }) => transactions.length);
const hasNoTransactions = (detailedTransactions) =>
  !detailedTransactions.some((plan) => plan.transactions.length);
const getFirstPlanTransactions = (detailedTransactions) =>
  detailedTransactions.find((plan) => plan.transactions.length).transactions;
const getFirstPlan = (detailedTransaction) =>
  detailedTransaction.find((plan) => plan.transactions.length).planType;

const TransactionsTabs = ({
  detailedTransactions,
  renderTransactionsTable,
  tabsOptions,
  noTransactionsMessage,
}) => {
  if (!detailedTransactions) return <Loading notFullHeight />;
  if (hasNoTransactions(detailedTransactions)) return noTransactionsMessage;
  if (
    hasMoreThanOnePlan(detailedTransactions) &&
    hasTransactionsInBothPlans(detailedTransactions)
  ) {
    return (
      <Tabs
        panels={[
          {
            label: 'Smart Invest',
            labelTestRef: 'si-tab',
            panel: (
              <Spacing top={{ breakpointSmall: '2x-large' }}>
                {renderTransactionsTable(
                  getSiTransactions(detailedTransactions),
                  'smart_invest'
                )}
              </Spacing>
            ),
          },
          {
            label: 'Build My Own',
            labelTestRef: 'bmo-tab',
            panel: (
              <Spacing top={{ breakpointSmall: '2x-large' }}>
                {renderTransactionsTable(
                  getBmoTransactions(detailedTransactions),
                  'build_your_own'
                )}
              </Spacing>
            ),
          },
        ]}
        testRef="transactions-tabs"
        {...tabsOptions}
      />
    );
  }

  return renderTransactionsTable(
    getFirstPlanTransactions(detailedTransactions),
    getFirstPlan(detailedTransactions)
  );
};

export default TransactionsTabs;
