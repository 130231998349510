import { LOGIN, REFRESH_SESSION, LOGOUT, SESSION_TIME_OUT } from 'scripts/redux/actions/segment/SegmentEventTypes';
import { track, identify, reset as resetAnalytics } from 'src/tracking/analytics';


export function sendLoginSegmentEvents(data) {
  track(LOGIN, { firstName: data.givenName, email: data.email });
  identify(data.id, { firstName: data.givenName, email: data.email });
}

export function sendRefreshSessionSegmentEvents(data) {
  track(REFRESH_SESSION, { firstName: data.givenName, email: data.email });
}

export function sendLogoutSegmentEvents(sessionTimedOut) {
  track(sessionTimedOut ? SESSION_TIME_OUT : LOGOUT);
  resetAnalytics();
  if (window.mixpanel && window.mixpanel.cookie) {
    window.mixpanel.cookie.clear();
  }
}
