import { Fragment } from 'react';
import styled from 'styled-components';
import { SubHeading } from 'src/design/styleguide/Headings';
import { getThemeColor } from 'src/themes/themeUtils';
import { standardTransition } from 'src/design/styleguide/common/animations';
import Divider from 'src/design/components/divider/Divider';
import Expandable from 'src/design/components/expandable/Expandable';
import Icon from 'src/design/components/icon/Icon';
import Paragraph from 'src/design/styleguide/Paragraph';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import Text from 'src/design/styleguide/text/Text';
import withState from 'src/decorators/withState';

const Layout = styled.div`
padding: 40px 30px;
border-radius: var(--bx-ms---standard-border-radius);
transition: box-shadow .3s;
box-shadow: ${p => p.expanded && 'var(--bx-emb-shadow)'};
&:hover {
  cursor: pointer;
  img {
    filter: grayscale(0);
  }
}

`;

const CtaText = styled(Text)`
  color: ${getThemeColor('grey600')};
`;

const CtaIcon = styled(Icon)`
  color: ${getThemeColor('primary')};
  transition: transform ${standardTransition};
  ${props => props.flipped ? 'transform: rotate(180deg);' : ''}
`;

const ExpandBioButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  white-space: pre;
  cursor: pointer;
`;

const DarkDivider = styled(Divider)`
  && {
    background-color: ${getThemeColor('grey400')};
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Image = styled.img`
  max-height: 150px;
  filter: ${p => p.expanded ? 'grayscale(0)' : 'grayscale(1)'};
  transition: all 0.6s;
`;

const TeamMemberBioCard = withState({
  mapPropsToInitialState: () => ({ expanded: false }),
  Component: ({
    className,
    imageSrc,
    title,
    possessiveName,
    subtitle,
    description,
    state: { expanded },
    setState,
  }) => (
    <Layout className={className} onClick={() => setState({ expanded: !expanded })} expanded={expanded}>
      {
        imageSrc ? (
          <ImageContainer>
            <Image src={imageSrc} alt={`picure of ${title} - ${subtitle}`} expanded={expanded} />
          </ImageContainer>
        )
        : null
      }

      <Spacing top="normal">
        <SubHeading
          as="h3"
          fontSize="x-small"
        >
          {title}
        </SubHeading>
      </Spacing>

      <Paragraph align="center">
        {subtitle}
      </Paragraph>

      <ExpandBioButton
        data-test-reference="read-bio-button"
      >
        <CtaText>Read {possessiveName} bio</CtaText>
        <Spacing left="2x-small">
          <CtaIcon
            type={Icon.types.chevron_down}
            flipped={expanded}
          />
        </Spacing>
      </ExpandBioButton>

      <Expandable
        expanded={expanded}
      >
        <Fragment>
          <DarkDivider size="normal"/>
          {description}
        </Fragment>
      </Expandable>
    </Layout>
  ),
});

export default TeamMemberBioCard;
