import TermsPage from 'src/components/terms/TermsPage';
import styles from 'src/components/terms/Terms.mscss';

const RaizPromotionTerms = () => (
  <TermsPage title="Raiz Promotion Terms and Conditions">
    <ol className={styles.termsList}>
      <li>This promotion is offered by The Brick Exchange Pty Limited (ABN 27 600 762 749) (BrickX) as the manager of the BrickX Platform (ARSN 613 156 433). Theta Asset Management Ltd (ABN 37 071 807 684, AFSL 230920) (Theta) is the product issuer for the BrickX Platform, but is not responsible for offering or administering this BrickX promotion.</li>
      <li>The $15 bonus payment is being offered, and will be fully funded, by BrickX from its own funds and will not be sourced from the assets of the BrickX Platform or any BrickX Trust.</li>
      <li>This promotion is only available to prospective members aged over 18, who are Australian residents who do not have an existing account with BrickX regardless of whether or not they hold an Interest in the BrickX Platform and who have an existing account with Raiz and have received the promotional Raiz link in this email (‘eligible clients’).</li>
      <li>To receive the $15 bonus into their Raiz account, eligible clients must sign up to the BrickX website using the promotional Raiz link provided in this email and become a Brick Owner within 30 days. The promotional Raiz link must not be forwarded. The $15 bonus will be paid into the Raiz accounts of eligible clients on the 30 November 2018. For queries relating to the payment of the $15 bonus please contact Raiz at support@raizinvest.com.au. A Raiz account in the name of the eligible client must be open at the time the $15 bonus payment is made. There is no cash or cash equivalent.</li>
      <li>The $15 bonus payment is limited to one payment per individual and cannot be used in conjunction with any other promotion or offer.</li>
      <li>This promotion closes at 11.59pm AEDT on 20 November 2018. Any first purchases of Bricks by eligible clients after 11.59pm AEDT on 20 November 2018 will not qualify for the $15 bonus offer. BrickX has not considered the tax implications of the $15 bonus payment.</li>
      <li>BrickX reserves the right to withdraw this promotion at any time.</li>
      <li>This promotion is not open to BrickX, Raiz or Theta employees or their immediate families.</li>
      <li>All questions relating to this promotion should be directed to BrickX at info@brickx.com.</li>
      <li>Capitalised terms have the meaning given in the BrickX Platform product disclosure statement unless otherwise defined. Please note that BPAY payments can take up to 1-2 business days to be received and appear as cleared funds in your Digital Wallet. Payments can also be made through POLi to provide instant funds (up to $20,000).</li>
    </ol>
  </TermsPage>
);

export default RaizPromotionTerms;
