import PropertyCardStat from 'src/components/propertyCards/common/propertyCardStat/PropertyCardStat';
import { percentDecimal, dollar } from 'scripts/utilities/formatters';
import Copy from 'scripts/constants/Copy';

const calculateAboveBelowValuationText = (discount) => {
  if (discount === 0) {
    return Copy.aboveBelowValuation.equalToValuationText;
  }
  if (discount > 0) {
    return Copy.aboveBelowValuation.aboveValuationText;
  }
  if (discount < 0) {
    return Copy.aboveBelowValuation.belowValuationText;
  }
};

const AboveBelowValuation = ({ property }) => {
  const discount = property.financials.lowestAvailableBrickPrice
    ? property.financials.discountOnBrickValue
    : null;

  return (
    <div className="above-below-valuation">
      {discount ? (
        <span className="above-below-valuation__percent">
          {Math.abs(discount)::percentDecimal()}{' '}
        </span>
      ) : null}
      <span className="above-below-valuation__text">
        {calculateAboveBelowValuationText(discount)}
      </span>
    </div>
  );
};

const LowestAvailableBrickPriceStat = ({ property }) => (
  <div>
    <PropertyCardStat
      label="Lowest Available Brick Price"
      value={property.financials.lowestAvailableBrickPrice::dollar()}
      valueProps={{ 'data-test-reference': 'lowest-available-brick-price' }}
      style={PropertyCardStat.styles.PRIMARY}
    />
    <AboveBelowValuation property={property} />
  </div>
);

export default LowestAvailableBrickPriceStat;
