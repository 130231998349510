import _ from 'lodash';
import { brickFloor, numberDecimal } from 'scripts/utilities/formatters';
import {
  getHistoricalGrowthMetricsForProperty,
  getHistoricalGrowthMetricsHighestNumberOfYearsGrowthAmount,
} from 'scripts/utilities/propertyHelper';
import OrderSide from 'scripts/constants/OrderSide';
import Trading from 'scripts/constants/Trading';


export function order(o) {
  const tradeValue = o.quantity * o.price;
  const brokeragePercentage = o.orderSide === OrderSide.BUY ? Trading.BUY_BROKERAGE_PERCENTAGE : Trading.SELL_BROKERAGE_PERCENTAGE;
  const brokerage = brokeragePercentage * tradeValue;
  const totalValue = tradeValue + brokerage;
  return { tradeValue, brokeragePercentage, brokerage, totalValue };
}

export function getAvgInvestmentMetrics(properties, averageAnnualGrowthMetrics) {
  const numOfProperties = properties.length;

  const avgNetRentalYield = _.sum(_.map(properties, (property) => property.financials.netRentalYield)) / numOfProperties;
  const avgGrowthRate = _.sum(_.map(properties, (property) => {
    const growthMetricsForProperty = getHistoricalGrowthMetricsForProperty(averageAnnualGrowthMetrics, property);
    return getHistoricalGrowthMetricsHighestNumberOfYearsGrowthAmount(growthMetricsForProperty);
  })) / numOfProperties;
  const avgDebtRate = _.sum(_.map(properties, (property) => property.financials.lVR)) / numOfProperties;

  return {
    rentalYield: avgNetRentalYield,
    growthRate: avgGrowthRate,
    debtRate: avgDebtRate
  };
}

export function getMaximalInvestmentAmountAllowed(properties) {
  const latestPropertiesValuation = _.sum(
    _.map(properties, function (property) {
      return property.financials.latestValue;
    })
  );
  return latestPropertiesValuation * Trading.MAXIMUM_OWNERSHIP_PERCENTAGE * (1 + Trading.BUY_BROKERAGE_PERCENTAGE);
}

export function getAvgPropertyFinancialMetrics(properties) {
  const numOfProperties = properties.length;

  const financialMetricsOfProperties = _.map(properties, function (property) { return getPropertyFinancialMetrics(property); });
  const initialPercentOfPropertyAsset = _.sum(_.map(financialMetricsOfProperties, function (financialMetrics) { return financialMetrics.percentageOfPropertyAsset; })) / numOfProperties;
  const initialPercentOfAcquisitionCost = _.sum(_.map(financialMetricsOfProperties, function (financialMetrics) { return financialMetrics.percentageOfAcquisitionCost; })) / numOfProperties;
  const initialPercentOfCashReserve = _.sum(_.map(financialMetricsOfProperties, function (financialMetrics) { return financialMetrics.percentageOfCashReserve; })) / numOfProperties;
  return {
    initialPercentageOfPropertyAsset: initialPercentOfPropertyAsset::numberDecimal() * 1,
    initialPercentageOfAcquisitionCost: initialPercentOfAcquisitionCost::numberDecimal() * 1,
    initialPercentageOfCashReserve: initialPercentOfCashReserve::numberDecimal() * 1
  };
}

function getPropertyFinancialMetrics(property) {
  const percentOfPropertyAsset = property.financials.lastPropertyValuation / property.financials.latestValue;
  const percentOfAcquisitionCost = property.financials.unamortisedAcquisitionCosts / property.financials.latestValue;
  const percentOfCashReserve = property.financials.liveCashReserve / property.financials.latestValue;
  return {
    propertyCode: property.propertyCode,
    percentageOfPropertyAsset: percentOfPropertyAsset,
    percentageOfAcquisitionCost: percentOfAcquisitionCost,
    percentageOfCashReserve: percentOfCashReserve
  };
}


function aggregatedPercentageAtXYears(rate, years) {
  return Math.pow(1 + rate, years);
}

export function numberOfBricks(amount, brickPrice, brokerage) {
  return brickPrice > 0 ? (amount / (brickPrice * brokerage))::brickFloor() : 0;
}

/**
 * Initial investment amount on bricks which is the investment amount minus buying fees.
 * Formula: IVI = IAI / (1+BC):   IAI -> Investment Amount, BC -> buying broker charge rate
 * @param investmentAmount
 * @returns {number}
 */
export function initialBricksValueInvestmentAmount(investmentAmount) {
  const initialBricksValueInvested = investmentAmount / (1 + Trading.BUY_BROKERAGE_PERCENTAGE);
  return initialBricksValueInvested;
}

/**
 * The buying broker fee charged by BrickX.
 * Formula: BF = IAI - IVI
 * @param investmentAmount
 * @returns {number}
 */
function buyingFee(investmentAmount) {
  const initialBricksValueInvested = initialBricksValueInvestmentAmount(investmentAmount);
  const buyingBrokerFees = investmentAmount - initialBricksValueInvested;
  return buyingBrokerFees;
}

/**
 * Formula: IUA = IVI / (1-DR)
 * @param investmentAmount
 * @param debtRate
 * @returns {number}
 */
function initialUnderlyingAssets(investmentAmount, debtRate) {
  const initialBricksValueInvested = initialBricksValueInvestmentAmount(investmentAmount);
  const initialValueOfUnderlyingAssets = initialBricksValueInvested / (1 - debtRate);
  return initialValueOfUnderlyingAssets;
}

/**
 * Formula: IUAC = IUA * IPPACA (Initial Percentage of Acquisition Cost/Asset)
 * @param investmentAmount
 * @param debtRate
 * @param initialPercentOfAcquisitionCost
 * @returns {number}
 */
function initialUnamortisedAcquisitionCost(investmentAmount, debtRate, initialPercentOfAcquisitionCost) {
  const initialValueOfUnderlyingAssets = initialUnderlyingAssets(investmentAmount, debtRate);
  const initialValueOfUnamortisedAcquisitionCost = initialValueOfUnderlyingAssets * initialPercentOfAcquisitionCost;
  return initialValueOfUnamortisedAcquisitionCost;
}

/**
 * Formula: ICR = IUA * IPCRA (Initial Percentage of Cash Reserve/Asset)
 * @param investmentAmount
 * @param debtRate
 * @param initialPercentOfCashReserve
 * @returns {number}
 */
function initialCashReserve(investmentAmount, debtRate, initialPercentOfCashReserve) {
  const initialValueOfUnderlyingAssets = initialUnderlyingAssets(investmentAmount, debtRate);
  const initialValueOfCashReserve = initialValueOfUnderlyingAssets * initialPercentOfCashReserve;
  return initialValueOfCashReserve;
}

/**
 * Formula: IPA = IUA * IPPA (Initial Percentage of Property/Asset)
 * @param investmentAmount
 * @param debtRate
 * @param initialPercentOfPropertyAsset
 * @returns {number}
 */
function initialPropertyAsset(investmentAmount, debtRate, initialPercentOfPropertyAsset) {
  const initialValueOfUnderlyingAssets = initialUnderlyingAssets(investmentAmount, debtRate);
  const initialValueOfPropertyAsset = initialValueOfUnderlyingAssets * initialPercentOfPropertyAsset;
  return initialValueOfPropertyAsset;
}

/**
 * Formula: Debt = IUA * DR (DebtRate)
 * @param investmentAmount
 * @param debtRate
 * @returns {number}
 */
function debt(investmentAmount, debtRate) {
  const initialValueOfUnderlyingAssets = initialUnderlyingAssets(investmentAmount, debtRate);
  const debtAmount = initialValueOfUnderlyingAssets * debtRate;
  return debtAmount;
}

function percentageOfRemainingAmortisedAfterHoldingPeriod(holdingPeriodInYears, remainingMonthsOfAmortising) {
  const NUM_OF_MONTH_PER_YEAR = 12;
  const holdingPeriodInMonths = holdingPeriodInYears * NUM_OF_MONTH_PER_YEAR;
  const monthlyRateForRemainingAmortising = 1 / remainingMonthsOfAmortising;

  return 1 - Math.min(holdingPeriodInMonths, remainingMonthsOfAmortising) * monthlyRateForRemainingAmortising;
}

/**
 * Formula: AVXY = Max(IUAC * (1-year*0.2), 0) + ICR + IPA*1^(1+growthRate)
 * @param investmentAmount
 * @param growthRate
 * @param debtRate
 * @param initialPercentOfPropertyAsset
 * @param initialPercentOfAcquisitionCost
 * @param initialPercentOfCashReserve
 * @param holdingPeriodInYears
 * @param remainingMonthsOfAmortising
 * @returns {number}
 */
function assetValueAtXYears(investmentAmount, growthRate, debtRate, initialPercentOfPropertyAsset, initialPercentOfAcquisitionCost, initialPercentOfCashReserve, holdingPeriodInYears, remainingMonthsOfAmortising) {
  const initialValueOfUnamortisedAcquisitionCost = initialUnamortisedAcquisitionCost(investmentAmount, debtRate, initialPercentOfAcquisitionCost);
  const remainingUnamortisedAcquisitionCosts = Math.max(initialValueOfUnamortisedAcquisitionCost * percentageOfRemainingAmortisedAfterHoldingPeriod(holdingPeriodInYears, remainingMonthsOfAmortising), 0);
  const initialValueOfCashReserve = initialCashReserve(investmentAmount, debtRate, initialPercentOfCashReserve);
  const initialValueOfPropertyAsset = initialPropertyAsset(investmentAmount, debtRate, initialPercentOfPropertyAsset);
  const assetAtXYears = remainingUnamortisedAcquisitionCosts + initialValueOfCashReserve + initialValueOfPropertyAsset * aggregatedPercentageAtXYears(growthRate, holdingPeriodInYears);
  return assetAtXYears;
}

/**
 * The value of your invested Bricks at Year X.
 * Formula: IVXY = AVXY - Debt
 * @param investmentAmount
 * @param growthRate
 * @param holdingPeriodInYears
 * @param remainingMonthsOfAmortising
 * @returns {number}
 */
function investmentValueAtXYears(investmentAmount, growthRate, debtRate, initialPercentOfPropertyAsset, initialPercentOfAcquisitionCost, initialPercentOfCashReserve, holdingPeriodInYears, remainingMonthsOfAmortising) {
  const assetAtXYears = assetValueAtXYears(investmentAmount, growthRate, debtRate, initialPercentOfPropertyAsset, initialPercentOfAcquisitionCost, initialPercentOfCashReserve, holdingPeriodInYears, remainingMonthsOfAmortising);
  const debtAmount = debt(investmentAmount, debtRate);
  const investedValueAtXYears = assetAtXYears - debtAmount;
  return investedValueAtXYears;
}

/**
 * The capital returns from growth.
 * Formula: CRFG = (IVXY - IVI) * (1-debtRate)
 * @param investmentAmount
 * @param growthRate
 * @param holdingPeriodInYears
 * @param remainingMonthsOfAmortising
 * @returns {number}
 */
export function returnFromGrowth(investmentAmount, growthRate, debtRate, initialPercentOfPropertyAsset, initialPercentOfAcquisitionCost, initialPercentOfCashReserve, holdingPeriodInYears, remainingMonthsOfAmortising = Trading.NUM_OF_MONTHS_FOR_AMORTISING) {
  const investedValueAtXYears = investmentValueAtXYears(investmentAmount, growthRate, debtRate, initialPercentOfPropertyAsset, initialPercentOfAcquisitionCost, initialPercentOfCashReserve, holdingPeriodInYears, remainingMonthsOfAmortising);
  const initialBricksValueInvested = initialBricksValueInvestmentAmount(investmentAmount);
  const capitalReturnFromGrowth = (investedValueAtXYears - initialBricksValueInvested) * (1 - debtRate);
  return capitalReturnFromGrowth;
}

/**
 * The capital returns from Debt.
 * Formula: CRFD = (IVXY - IVI) * debtRate
 * @param investmentAmount
 * @param growthRate
 * @param debtRate
 * @param holdingPeriodInYears
 * @param remainingMonthsOfAmortising
 * @returns {number}
 */
export function returnFromDebt(investmentAmount, growthRate, debtRate, initialPercentOfPropertyAsset, initialPercentOfAcquisitionCost, initialPercentOfCashReserve, holdingPeriodInYears, remainingMonthsOfAmortising = Trading.NUM_OF_MONTHS_FOR_AMORTISING) {
  const investedValueAtXYears = investmentValueAtXYears(investmentAmount, growthRate, debtRate, initialPercentOfPropertyAsset, initialPercentOfAcquisitionCost, initialPercentOfCashReserve, holdingPeriodInYears, remainingMonthsOfAmortising);
  const initialBricksValueInvested = initialBricksValueInvestmentAmount(investmentAmount);
  const capitalReturnFromDebt = (investedValueAtXYears - initialBricksValueInvested) * debtRate;
  return capitalReturnFromDebt;
}

/**
 * The capital returns from Rent (distributions)
 * Formula: CRFR = IVI * NRY(NetRentYield) * years
 * @param investmentAmount
 * @param netRentalYield
 * @param years
 * @returns {number}
 */
export function returnFromRent(investmentAmount, netRentalYield, years) {
  const initialBricksValueInvested = initialBricksValueInvestmentAmount(investmentAmount);
  const capitalReturnFromRent = initialBricksValueInvested * netRentalYield * years;
  return capitalReturnFromRent;
}

/**
 * The selling broker fee charged by BrickX.
 * Formula: SF = IVXY * SC (sell broker charge rate)
 * @param investmentAmount
 * @param growthRate
 * @param debtRate
 * @param holdingPeriodInYears
 * @param remainingMonthsOfAmortising
 * @returns {number}
 */
function sellingFee(investmentAmount, growthRate, debtRate, initialPercentOfPropertyAsset, initialPercentOfAcquisitionCost, initialPercentOfCashReserve, holdingPeriodInYears, remainingMonthsOfAmortising) {
  const investedValueAtXYears = investmentValueAtXYears(investmentAmount, growthRate, debtRate, initialPercentOfPropertyAsset, initialPercentOfAcquisitionCost, initialPercentOfCashReserve, holdingPeriodInYears, remainingMonthsOfAmortising);
  const sellingFees = investedValueAtXYears * Trading.SELL_BROKERAGE_PERCENTAGE;
  return sellingFees;
}

/**
 * The total of buying & selling broker fees charged by BrickX.
 * Formula: TF = BF + SF
 * @param investmentAmount
 * @param growthRate
 * @param debtRate
 * @param holdingPeriodInYears
 * @param remainingMonthsOfAmortising
 * @returns {number}
 */
export function totalFees(investmentAmount, growthRate, debtRate, initialPercentOfPropertyAsset, initialPercentOfAcquisitionCost, initialPercentOfCashReserve, holdingPeriodInYears, remainingMonthsOfAmortising = Trading.NUM_OF_MONTHS_FOR_AMORTISING) {
  const sellingFees = sellingFee(investmentAmount, growthRate, debtRate, initialPercentOfPropertyAsset, initialPercentOfAcquisitionCost, initialPercentOfCashReserve, holdingPeriodInYears, remainingMonthsOfAmortising);
  const buyingFees = buyingFee(investmentAmount);
  const fees = buyingFees + sellingFees;
  return fees;
}

/**
 * Formula: IVAE = IVXY - SF
 * @param investmentAmount
 * @param growthRate
 * @param debtRate
 * @param initialPercentOfPropertyAsset
 * @param initialPercentOfAcquisitionCost
 * @param initialPercentOfCashReserve
 * @param holdingPeriodInYears
 * @param remainingMonthsOfAmortising
 * @returns {number}
 */
function investmentValueAtExit(investmentAmount, growthRate, debtRate, initialPercentOfPropertyAsset, initialPercentOfAcquisitionCost, initialPercentOfCashReserve, holdingPeriodInYears, remainingMonthsOfAmortising) {
  const investedValueAtXYears = investmentValueAtXYears(investmentAmount, growthRate, debtRate, initialPercentOfPropertyAsset, initialPercentOfAcquisitionCost, initialPercentOfCashReserve, holdingPeriodInYears, remainingMonthsOfAmortising);
  const sellingFees = sellingFee(investmentAmount, growthRate, debtRate, initialPercentOfPropertyAsset, initialPercentOfAcquisitionCost, initialPercentOfCashReserve, holdingPeriodInYears, remainingMonthsOfAmortising);
  const investedValueAtExit = investedValueAtXYears - sellingFees;
  return investedValueAtExit;
}

/**
 * Formula: ETR = IVAE - IAI + CRFR
 * @param investmentAmount
 * @param growthRate
 * @param debtRate
 * @param netRentalYield
 * @param initialPercentOfPropertyAsset
 * @param initialPercentOfAcquisitionCost
 * @param initialPercentOfCashReserve
 * @param holdingPeriodInYears
 * @param remainingMonthsOfAmortising
 * @returns {number}
 */
export function estimatedTotalReturns(investmentAmount, growthRate, debtRate, netRentalYield, initialPercentOfPropertyAsset, initialPercentOfAcquisitionCost, initialPercentOfCashReserve, holdingPeriodInYears, remainingMonthsOfAmortising = Trading.NUM_OF_MONTHS_FOR_AMORTISING) {
  const investedValueAtExit = investmentValueAtExit(investmentAmount, growthRate, debtRate, initialPercentOfPropertyAsset, initialPercentOfAcquisitionCost, initialPercentOfCashReserve, holdingPeriodInYears, remainingMonthsOfAmortising);
  const capitalReturnFromRent = returnFromRent(investmentAmount, netRentalYield, holdingPeriodInYears);
  const totalReturns = investedValueAtExit + capitalReturnFromRent - investmentAmount;
  return totalReturns;
}

/**
 * Formula: AVXY = IVXY + CRFR
 * @param investmentAmount
 * @param growthRate
 * @param debtRate
 * @param netRentalYield
 * @param initialPercentOfPropertyAsset
 * @param initialPercentOfAcquisitionCost
 * @param initialPercentOfCashReserve
 * @param holdingPeriodInYears
 * @param remainingMonthsOfAmortising
 * @returns {number}
 */
export function accountValueAtXYears(investmentAmount, growthRate, debtRate, netRentalYield, initialPercentOfPropertyAsset, initialPercentOfAcquisitionCost, initialPercentOfCashReserve, holdingPeriodInYears, remainingMonthsOfAmortising = Trading.NUM_OF_MONTHS_FOR_AMORTISING) {
  const investedValueAtXYears = investmentValueAtXYears(investmentAmount, growthRate, debtRate, initialPercentOfPropertyAsset, initialPercentOfAcquisitionCost, initialPercentOfCashReserve, holdingPeriodInYears, remainingMonthsOfAmortising);
  const capitalReturnFromRent = returnFromRent(investmentAmount, netRentalYield, holdingPeriodInYears);
  const accountValue = investedValueAtXYears + capitalReturnFromRent;
  return accountValue;
}

/**
 * Formula: AVAE = IVAE + CRFR
 * @param investmentAmount
 * @param growthRate
 * @param debtRate
 * @param netRentalYield
 * @param initialPercentOfPropertyAsset
 * @param initialPercentOfAcquisitionCost
 * @param initialPercentOfCashReserve
 * @param holdingPeriodInYears
 * @param remainingMonthsOfAmortising
 * @returns {number}
 */
export function accountValueAtExit(investmentAmount, growthRate, debtRate, netRentalYield, initialPercentOfPropertyAsset, initialPercentOfAcquisitionCost, initialPercentOfCashReserve, holdingPeriodInYears, remainingMonthsOfAmortising = Trading.NUM_OF_MONTHS_FOR_AMORTISING) {
  const investedValueAtExit = investmentValueAtExit(investmentAmount, growthRate, debtRate, initialPercentOfPropertyAsset, initialPercentOfAcquisitionCost, initialPercentOfCashReserve, holdingPeriodInYears, remainingMonthsOfAmortising);
  const capitalReturnFromRent = returnFromRent(investmentAmount, netRentalYield, holdingPeriodInYears);

  const accountValue = investedValueAtExit + capitalReturnFromRent;
  return accountValue;
}

export function calculateAnnualisedGrowthRate(capitalReturnsRate, holdingPeriodInYears) {
  return (capitalReturnsRate < 0 ? -1 : 1) * (Math.pow(Math.abs(capitalReturnsRate) + 1, 1 / holdingPeriodInYears) - 1);
}
