import { css } from 'styled-components';
import { getThemeColor } from 'src/themes/themeUtils';
import { gridUnit } from 'src/design/styleguide/common/measurements';

export const gridGap = `${gridUnit * 6}px;`;

export const layoutStyles = `
  display: grid;
  grid-gap: ${gridGap};
`;

export const specialGradient = css`linear-gradient(to bottom, ${getThemeColor('primary')}, #ff9d82);`;

export const topBottomPanelPadding = `${gridUnit * 8}px`;
export const sidePanelPadding = `${gridUnit * 4}px`;
export const sidePanelPaddingBreakpointSmall = `${gridUnit * 6}px`;
