import { track } from 'src/tracking/analytics';

export const trackJoinButtonClick = (name) => (values) => track(`Rent to buy waitlist ${name} join button clicked`, values);
export const trackChatButtonClick = (name) => () => track(`Rent to buy waitlist ${name} chat button clicked`);
export const trackContactUsButtonClick = (name) => () => track(`Rent to buy waitlist ${name} contact us button clicked`);
export const trackChatWidgetOpened = (name) => () => track(`Rent to buy waitlist ${name} chat opened`);

export const trackScreenSeen = ({ screenName }) => track('Rent to buy waitlist Extra Info step - screen viewed', { screenName });
export const trackNextClick = ({ screenName, incompleteFields }) => track('Rent to buy waitlist Extra Info step - next clicked', { screenName, incompleteFields });
export const trackBackClick = ({ screenName }) => track('Rent to buy waitlist Extra Info step - back clicked', { screenName });
export const trackEditClick = ({ screenName }) => track('Rent to buy waitlist Extra Info step - edit clicked', { screenName });
export const trackConsentCheckboxChanged = ({ checked }) => track('Rent to buy waitlist Extra Info step - consent checkbox interaction', { checked: checked ? 'Yes' : 'No' });
export const trackSubmit = (values) => track('Rent to buy waitlist Extra Info step - submit clicked', values);
