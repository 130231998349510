import { Component, cloneElement } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { BrickxLogoLight } from 'src/components/brickxLogo/BrickxLogo';
import { HeadingXSmall } from 'src/design/components/heading/Headings';
import {
  RG46ScheduleUrl,
  aboutUrl,
  complaintsUrl,
  contactUrl,
  feesAndTaxesHelpUrl,
  helpCenterUrl,
  howItWorksBuildMyOwnTabUrl,
  howItWorksUrl,
  loginUrl,
  mediaUrl,
  newsUrl,
  pdsUrl,
  privacyUrl,
  propertyTeamUrl,
  rentToBuyUrl,
  resetPasswordUrl,
  returnsCalculatorUrl,
  signupUrl,
  smartInvestUrl,
  smsfUrl,
  termsUrl,
  significantEventNoticeUrl,
  minorLandingPageUrl,
  wholesaleLandingPageUrl,
  privateSyndicationLandingPageUrl,
  brickxDevelopmentLandingPageUrl,
} from 'src/utils/pageUrls';
import { blurAppBackgroundSelector } from 'src/components/modals/modalsReducer';
import { getMemberStatus as getMemberStatusAction } from 'scripts/redux/actions/member';
import { memberSelector } from 'scripts/redux/selectors/member';
// import { trackButtonClick } from 'src/tracking/referrals';
import {
  user as userPropType,
  memberStatus as memberStatusType,
} from 'scripts/constants/PropTypes';
import { userSelector } from 'scripts/redux/selectors/user';
import Divider from 'src/design/components/divider/Divider';
import FinePrint from 'src/design/components/bodyText/FinePrint';
import PageMarginContainer from 'src/design/components/pageMarginContainer/PageMarginContainer';
import Styledlink from 'src/design/components/hyperlink/Styledlink';
import appStyles from 'src/components/app/App.mscss';
import styles from 'src/components/footer/Footer.mscss';

const LinkList = ({ title, children }) => (
  <div className={styles.linkList}>
    <HeadingXSmall className={styles.linkListHeading}>
      <h5>{title}</h5>
    </HeadingXSmall>
    {children}
  </div>
);

const LinkListItem = ({
  shouldShowIf = true,
  href,
  text,
  children,
  ...otherProps
} = {}) => {
  if (!shouldShowIf) return null;
  return children ? (
    cloneElement(children, {
      className: styles.linkListItem,
    })
  ) : (
    <Styledlink className={styles.linkListItem} href={href} {...otherProps}>
      {text}
    </Styledlink>
  );
};

const mapStateToProps = (state) => ({
  ...userSelector(state),
  ...memberSelector(state),
  ...blurAppBackgroundSelector(state),
});

@connect(mapStateToProps, {
  getMemberStatus: getMemberStatusAction,
})
export default class Footer extends Component {
  static propTypes = {
    user: userPropType,
    memberStatus: memberStatusType,
    getMemberStatus: PropTypes.func,
    blurAppBackground: PropTypes.bool,
  };

  componentDidMount() {
    this.props.getMemberStatus();
  }

  render() {
    return (
      <footer
        className={classNames(styles.footer, {
          [appStyles.blurryMainAppBody]: this.props.blurAppBackground,
        })}
      >
        <PageMarginContainer>
          <div className={styles.metaNavColumns}>
            <div className={styles.logoContainer}>
              <div>
                <BrickxLogoLight />
              </div>
              <div className={styles.socialContainer}>
                <div className={styles.socialTitle}>Follow us on:</div>
                <div className={styles.socialLinkContainer}>
                  <a
                    className={styles.socialLink}
                    target="_blank"
                    href="https://www.facebook.com/BrickXAU"
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                  <a
                    className={styles.socialLink}
                    target="_blank"
                    href="https://twitter.com/BRICKX"
                  >
                    <i className="fa fa-twitter"></i>
                  </a>
                  <a
                    className={styles.socialLink}
                    target="_blank"
                    href="https://www.linkedin.com/company/brickx---the-brick-exchange/mycompany"
                  >
                    <i className="fa fa-linkedin"></i>
                  </a>
                  <a
                    className={styles.socialLink}
                    target="_blank"
                    href="https://www.instagram.com/brickx_au/"
                  >
                    <i className="fa fa-instagram"></i>
                  </a>
                </div>
              </div>
            </div>

            <LinkList title="Invest">
              <LinkListItem text="How it Works" href={howItWorksUrl()} />
              <LinkListItem text="Smart Invest" href={smartInvestUrl()} />
              <LinkListItem
                text="Build my Own"
                href={howItWorksBuildMyOwnTabUrl()}
              />
              <LinkListItem text="Fees" href={feesAndTaxesHelpUrl()} />
              <LinkListItem
                text="BrickX Calculator"
                href={returnsCalculatorUrl()}
              />
              <LinkListItem
                text="Minors"
                href={minorLandingPageUrl()}
                forceExternal
              />
              <LinkListItem text="SMSF or Trust" href={smsfUrl()} />
              <LinkListItem
                text="Professional and Sophisticated"
                href={wholesaleLandingPageUrl()}
                forceExternal
              />
              <LinkListItem
                text="Select & Syndicate"
                href={privateSyndicationLandingPageUrl()}
                forceExternal
              />
              <LinkListItem
                text="BrickX Developments"
                href={brickxDevelopmentLandingPageUrl()}
                forceExternal
              />
              <LinkListItem
                text="Smart Renter"
                href={rentToBuyUrl()}
                forceExternal
              />
            </LinkList>

            <LinkList title="Company">
              <LinkListItem text="About Us" href={aboutUrl()} />
              <LinkListItem text="BrickX Team" href={propertyTeamUrl()} />
              <LinkListItem text="News" href={newsUrl()} />
              <LinkListItem text="Media Coverage" href={mediaUrl()} />
              <LinkListItem
                text={<span>Significant Event Notice</span>}
                href={significantEventNoticeUrl()}
              />
              <LinkListItem
                text={
                  <span>
                    Disclosure Notice <br />
                    (Change in Auditor)
                  </span>
                }
                href={'/disclosure-notice_change-in-auditor'}
              />
            </LinkList>

            <LinkList title="Terms">
              <LinkListItem text="PDS, FSG & TMD" href={pdsUrl()} />
              <LinkListItem
                text={
                  <span>
                    RG46 Schedule &<br />
                    Other Documents
                  </span>
                }
                href={RG46ScheduleUrl()}
              />
              <LinkListItem text="Terms & Conditions" href={termsUrl()} />
              <LinkListItem text="Privacy Policy" href={privacyUrl()} />
              <LinkListItem text="Complaints" href={complaintsUrl()} />
              <LinkListItem
                text="Direct Debit Request Service Agreement"
                href="//assets.brickx.com/downloads/ddr-sa.pdf"
              />
            </LinkList>

            <LinkList title="My Account">
              <LinkListItem text="Login" href={loginUrl()} />
              <LinkListItem text="Sign Up" href={signupUrl()} />
              <LinkListItem text="Forgot Password" href={resetPasswordUrl()} />
            </LinkList>

            <LinkList title="Support">
              <LinkListItem
                text="Help Centre"
                target="_blank"
                href={helpCenterUrl()}
              />
              <LinkListItem text="Contact Us" href={contactUrl()} />
            </LinkList>
          </div>
          <Divider onDarkBg bottom="normal" top="2x-large" />
          <FinePrint className={styles.termsText}>
            BrickX products are issued by BrickX Financial Services Limited (ABN
            67 616 118 088) (AFSL 494878) (BrickX Financial Services). The Brick
            Exchange Pty Ltd (ABN 27 600 762 749) (BrickX) is the manager of the
            BrickX Platform (ARSN 613 156 433). BrickX is an authorised
            representative (No. 001000043) of BrickX Financial Services Limited.
            The advice provided in relation to the BrickX Platform and Bricks,
            including on this website, is general advice only and has been
            prepared without taking into account your objectives, financial
            situation or needs. Before making any decision in relation to the
            BrickX Platform or Bricks you should read the Product Disclosure
            Statement (PDS) and consider whether they are right for you. The PDS
            is available at{' '}
            <Styledlink href={pdsUrl()}>brickx.com/pds</Styledlink>. Past
            performance is no indication of future performance. Any forecasts
            are subject to change without notice. Income and capital returns are
            not guaranteed. By accessing and using this website, you agree to be
            bound by our terms and conditions.{' '}
            <Styledlink href={termsUrl()}>Terms and Conditions</Styledlink>.
            Copyright © 2018 The Brick Exchange Pty Ltd (ABN 27 600 762 749).
            All rights reserved.
          </FinePrint>
        </PageMarginContainer>
      </footer>
    );
  }
}
