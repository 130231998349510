import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import SelectList from 'src/design/components/selectList/SelectList';
import TextInputWithError from 'src/design/components/textInput/TextInputWithError';
import { uniqueId } from 'lodash';

const Container = styled.div`
  position: relative;
`;

class AutoFillTextInput extends Component {
  static propTypes = {
    onSuggestionSelect: PropTypes.func,
    suggestions: PropTypes.array,
    listTestRef: PropTypes.string,
    listItemTestRef: PropTypes.string,
  };

  render() {
    const {
      onSuggestionSelect,
      suggestions,
      listTestRef,
      listItemTestRef,
      ...textInputProps
    } = this.props;

    return (
      <Container>
        <TextInputWithError {...textInputProps} />
        {suggestions && suggestions.length ? (
          <SelectList
            key={uniqueId()}
            onItemSelect={onSuggestionSelect}
            items={suggestions}
            testRef={listTestRef}
            itemTestRef={listItemTestRef}
            customFirstItemTestRef="address-suggestion-first-item"
          />
        ) : null}
      </Container>
    );
  }
}

export default AutoFillTextInput;
