import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { property as propertyType } from 'scripts/constants/PropTypes';

import { getPropertyViews } from 'scripts/redux/actions/propertyViews';
import { propertyViewsSelector } from 'scripts/redux/selectors/propertyView';

import BricksSoldProgressBar from 'scripts/components/property/BricksSoldProgressBar';
import PropertyCardStat from 'src/components/propertyCards/common/propertyCardStat/PropertyCardStat';

import { getBricksSoldPercentage } from 'scripts/utilities/propertyHelper';
import { daysUntil } from 'scripts/utilities/formatters';
import Formatters from 'scripts/utilities/formattersV2';

@connect(propertyViewsSelector, { getPropertyViews })
export default class ViewsOrSelldown extends Component {
  static propTypes = {
    property: propertyType,
    propertyViews: PropTypes.object,
    getPropertyViews: PropTypes.func,
  };

  componentDidMount() {
    this.props.getPropertyViews(this.props.property.propertyCode);
  }

  render() {
    const { property, propertyViews } = this.props;

    const views = propertyViews[property.propertyCode];
    const daysUntilSettlement = property.platformSettlementDate::daysUntil();
    const showSelldownBar = getBricksSoldPercentage(property) >= 50;

    let viewsOrSelldown = null;

    if (views) {
      viewsOrSelldown = (
        <PropertyCardStat
          label="Views"
          value={Formatters.numeric.number(views)}
          valueProps={{ 'data-test-reference': 'number-of-views' }}
          style={PropertyCardStat.styles.PRIMARY}
        />
      );
    }

    if (showSelldownBar) {
      viewsOrSelldown = <BricksSoldProgressBar property={property} showText />;
    }

    return (
      <div>
        {viewsOrSelldown}
        <div
          className={classNames(
            'views-or-selldown__days-left-until-settlement',
            {
              'views-or-selldown__days-left-until-settlement--margin-top':
                showSelldownBar,
            }
          )}
        >
          {daysUntilSettlement > 0 &&
            daysUntilSettlement <= 7 &&
            `${daysUntilSettlement} ${
              daysUntilSettlement > 1 ? 'days' : 'day'
            } left`}
        </div>
      </div>
    );
  }
}
