import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Heading from 'src/design/components/heading/Heading';
import Paragraph from 'src/design/components/bodyText/Paragraph';
import OnboardingScreen, {
  OnboardingScreenHeader,
  OnboardingScreenContentWide,
} from 'src/components/oldOnboarding/common/onboardingScreen/OnboardingScreen';
import FormCheckbox from 'src/design/components/formCheckbox/FormCheckbox';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import StyledBodylink from 'src/design/components/hyperlink/StyledBodylink';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import informWhenJsLoaded from 'src/decorators/informWhenJsLoaded';
import {
  termsUrl,
  pdsDirectUrl,
  fsgDirectUrl,
  tmdUrl,
} from 'src/utils/pageUrls';


const getDate18YearsAgo = () =>
  moment()
  .subtract(18, 'years')
  .format('DD-MM-YYYY');

@informWhenJsLoaded
class PdsAgreement extends Component {
  static propTypes = {
    user: PropTypes.shape({
      id: PropTypes.string,
      givenName: PropTypes.string,
    }),
    acceptPds: PropTypes.func,
    jsLoaded: PropTypes.bool,
  };

  state = {
    declaredOver18: false,
  };

  render() {
    const { user, acceptPds, jsLoaded } = this.props;
    const { declaredOver18 } = this.state;

    return (
      <OnboardingScreen testRef="pds-agreement-screen">
        <OnboardingScreenHeader>

          <Spacing bottom="large">
            <Heading>Hi, {user.givenName}</Heading>
          </Spacing>

          <Spacing bottom="2x-large">
            <Paragraph>Please agree to the Product Disclosure Statement</Paragraph>
          </Spacing>

        </OnboardingScreenHeader>

        <OnboardingScreenContentWide>

          <Spacing bottom="2x-large">
            <Paragraph>
              You should consider the
              <StyledBodylink href={pdsDirectUrl()} target="_blank" testRef="pds-link"> PDS </StyledBodylink>,
              <StyledBodylink href={fsgDirectUrl()} target="_blank" testRef="fsg-link"> FSG </StyledBodylink>,
              <StyledBodylink href={tmdUrl()} target="_blank" testRef="tmd-link"> TMD </StyledBodylink>
              and our
              <StyledBodylink href={termsUrl()} target="_blank" testRef="ts-and-cs-link"> Terms & Conditions </StyledBodylink>
            before making any investment decisions. The advice provided in relation to BrickX is general advice only and has been prepared without taking into account your objectives, financial situation, and needs.</Paragraph>
          </Spacing>

          <Spacing bottom="large">
            <FormCheckbox
              onChange={(checked) => this.setState({ declaredOver18: checked })}
              disabled={!jsLoaded}
              testRef="over-18-checkbox"
              labelTestRef="over-18-label"
            >I am 18 years or older and an Australian resident. I have read and accepted the terms and conditions and the PDS.</FormCheckbox>
          </Spacing>

          <Spacing bottom="large">
            <PrimaryButton
              onClick={() => acceptPds(user.id, getDate18YearsAgo())}
              color={PrimaryButton.colors.SECONDARY}
              fullWidth
              disabled={!declaredOver18}
              testRef="accept-button"
            >Next</PrimaryButton>
          </Spacing>

        </OnboardingScreenContentWide>
      </OnboardingScreen>
    );
  }
}

export default PdsAgreement;
