import { merge } from 'lodash';
import brickxTheme from 'src/themes/brickx/brickxTheme';
import {
  lavender,
  purpleLight,
  yellow50,
  yolk,
  blue500,
  blue50,
  primary,
  lightBackgroundPrimary,
  onDarkSurfacePrimary,
  blue800,
} from './brickx/colors';
import { purple } from './rentToBuy/colors';

export const themeForBrickXMinorLandingPage = merge({}, brickxTheme, {
  colors: {
    primary: yolk,
    lightBackgroundPrimary: yellow50,
    onDarkSurfacePrimary: '#FAC800',
  },
});

export const themeForBrickXAvenue = merge({}, brickxTheme, {
  colors: {
    primary: purple,
    lightBackgroundPrimary: purpleLight,
    onDarkSurfacePrimary: lavender,
  },
});

export const themeForBrickXWholesale = merge({}, brickxTheme, {
  colors: {
    primary: blue500,
    lightBackgroundPrimary: blue50,
    onDarkSurfacePrimary: blue500,
  },
});

export const themeForBrickXPrivateSyndicate = merge({}, brickxTheme, {
  colors: {
    primary: blue800,
    lightBackgroundPrimary: blue50,
    onDarkSurfacePrimary: blue800,
  },
});

export const themeForSmsfAccount = merge({}, brickxTheme, {
  colors: {
    primary: blue500,
    lightBackgroundPrimary: blue50,
    onDarkSurfacePrimary: blue500,
  },
});

export const themeForIndividualAccount = merge({}, brickxTheme, {
  colors: {
    primary,
    lightBackgroundPrimary,
    onDarkSurfacePrimary,
  },
});
