import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DynamicNumber from 'react-dynamic-number';

import {
  property as propertyPropType,
  account as accountPropType,
  myOrders as myOrdersPropType,
  user as userPropType,
  sellForm as sellFormPropType,
  tradeProposal as tradeProposalPropType,
} from 'scripts/constants/PropTypes';

import { confirmSell, sellDone } from 'scripts/redux/actions/market';
import {
  sendSellProposalEngagementEvent,
  sendSellProposalSubmitEvent,
} from 'scripts/redux/actions/segment/events/tradeEvents';

import { tradeProposalSelector } from 'scripts/redux/selectors/market';

import { fetchFees as fetchFeesAction } from 'scripts/redux/actions/market';
import { feesSelector } from 'scripts/redux/selectors/market';
import { userSelector } from 'scripts/redux/selectors/user';

import {
  brickPrice,
  dollarDecimal,
  brick,
  dollar,
  percent,
} from 'scripts/utilities/formatters';
import Trading from 'scripts/constants/Trading';

import { roundTransactionFee } from 'scripts/utilities/helpers';
import Trade from 'scripts/constants/Trade';
import Constants from 'scripts/constants/Constants';
import { validateTradeProposalQuantity } from 'scripts/utilities/tradeHelper';
import { checkUserWithTradePermission } from 'scripts/utilities/userAccountHelper';
import _ from 'lodash';
import FeePromoBanner from 'src/components/promos/Aug2022FeePromo/FeePromoBanner';
import { renderCode } from 'src/settings/properties';

const INTEGERS = 10;
const FRACTION = 0;
const ENABLE_HIGHEST_SELL_PRICE_VALIDATION = false;

const orderFor = (orders, propertyCode) => _.filter(orders, { propertyCode });

const mapStateToProps = (state) => ({
  ...userSelector(state),
  ...feesSelector(state),
  ...tradeProposalSelector(state),
});

@connect(mapStateToProps, {
  fetchFees: fetchFeesAction,
  confirmSell,
  sellDone,
})
export default class TradeProposalFormSell extends Component {
  static propTypes = {
    user: userPropType,
    account: accountPropType,
    property: propertyPropType,
    myOrders: myOrdersPropType,
    tradeProposal: tradeProposalPropType,
    sellForm: sellFormPropType,
    getTotalBricksOwned: PropTypes.func,
    confirmSell: PropTypes.func,
    sellDone: PropTypes.func,
    redirectTo: PropTypes.func,
    fetchFees: PropTypes.func,
    fees: PropTypes.shape({
      feeFreeEnabled: PropTypes.bool,
      buyFeeFreeEnabled: PropTypes.bool,
      sellFeeFreeEnabled: PropTypes.bool,
      buyFee: PropTypes.number,
      preOrderFee: PropTypes.number,
      sellFee: PropTypes.number,
    }),
  };

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  state = {
    sellProposalParams: {
      quantity: '',
      price: '',
      valueOfBricks: 0,
      transactionFee: 0,
      youReceive: 0,
      hasPriceError: false,
      hasQuantityError: false,
      priceErrorMsg: '',
      quantityErrorMsg: '',
    },
    loading: false,
    showPendingOrderLink: false,
    terms: {
      accepted: false,
      showErrorMessage: false,
    },
    totalBricksOwned: 0,
    totalBricksOwnedLoaded: false,
    totalBricksOnMyPendingSell: 0,
    totalBricksOnMyPendingSellLoaded: false,
  };

  componentDidMount() {
    const { sellForm, account, myOrders, fetchFees } = this.props;
    const { sellProposalParams } = this.state;

    fetchFees();

    this._handleQuantityChange(sellForm.quantity);
    this._updateTotalBricksOwned(account);
    this._updateTotalBricksOnMyPendingSell(myOrders);
    this._handlePriceChange(sellProposalParams.price);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !_.isNil(nextProps.account) &&
      nextProps.account !== this.props.account
    ) {
      this._updateTotalBricksOwned(nextProps.account);
    }

    if (
      !_.isNil(nextProps.myOrders) &&
      nextProps.myOrders !== this.props.myOrders
    ) {
      this._updateTotalBricksOnMyPendingSell(nextProps.myOrders);
    }
  }

  componentDidUpdate() {
    const { property, tradeProposal, sellDone, redirectTo } = this.props;
    if (tradeProposal && tradeProposal.type === Trade.CONFIRMED) {
      sellDone(tradeProposal.data);
      redirectTo(`/properties/${property.propertyCode}/sell-success`);
    }
  }

  render() {
    const {
      property,
      sellForm,
      fees: { sellFee, sellFeeFreeEnabled },
    } = this.props;
    const {
      totalBricksOwnedLoaded,
      totalBricksOwned,
      totalBricksOnMyPendingSell,
      totalBricksOnMyPendingSellLoaded,
      loading,
    } = this.state;

    const numOfBricksCanSell = this._calculateNumOfBricksCanSell();
    const submitButtonDisabled =
      this.state.sellProposalParams.hasPriceError ||
      this.state.sellProposalParams.hasQuantityError ||
      loading;

    const isSellFeeFree = sellFeeFreeEnabled;
    const sellFeeForDisplay = sellFeeFreeEnabled
      ? '0.00'
      : sellFee
      ? sellFee.toString()
      : '-';

    return (
      <div className="col-md-12 place-order-panel">
        <div className="hidden-xs">
          <div className="white-boxes-container">
            <div className="col-inline col-inline-4 col-no-padding-left">
              <div className="white-box">
                <div>You currently own</div>
                <div className="value total-bricks-owned">
                  {totalBricksOwnedLoaded
                    ? totalBricksOwned::brick()
                    : '- Bricks'}{' '}
                  in {renderCode(property.propertyCode)}
                </div>
              </div>
            </div>
            <div className="col-inline col-inline-3">
              <div className="white-box">
                <div>Pending Sell Orders</div>
                <div className="value total-bricks-pending-sell">
                  {totalBricksOnMyPendingSellLoaded
                    ? totalBricksOnMyPendingSell::brick()
                    : '- Bricks'}
                </div>
              </div>
            </div>
            <div className="col-inline col-inline-3 col-no-padding-right">
              <div className="white-box">
                <div>You can sell up to</div>
                <div className="value bricks-available-to-sell">
                  {totalBricksOwnedLoaded && totalBricksOnMyPendingSellLoaded
                    ? numOfBricksCanSell::brick()
                    : '- Bricks'}
                </div>
              </div>
            </div>
          </div>

          <div className="title">Sell your Bricks</div>
        </div>

        {isSellFeeFree && <FeePromoBanner />}

        <div className="white-boxes-container">
          <div className="col-inline col-inline-3 col-no-padding-left">
            <div className="white-box">
              Quantity
              <DynamicNumber
                id="id_sell_quantity"
                className="quantity-to-be-sell"
                name="sell_quantity"
                value={this.state.sellProposalParams.quantity}
                integer={INTEGERS}
                fraction={FRACTION}
                positive={Constants.TRUE}
                thousand={Constants.FALSE}
                placeholder="0"
                onChange={this._onQuantityChange}
              />
            </div>
          </div>
          <div className="col-inline col-inline-1 text-center hidden-xs">
            <i className="fa fa-times" aria-hidden="true"></i>
          </div>
          <div className="col-inline col-inline-3 input-group-sale-price-mobile">
            <div className="white-box">
              <div>Sale price</div>
              <div className="decimal-input dollar-input">
                <DynamicNumber
                  id="id_sell_price"
                  className="price"
                  name="price"
                  value={this.state.sellProposalParams.price}
                  integer={INTEGERS}
                  fraction={FRACTION}
                  positive={Constants.TRUE}
                  thousand={Constants.TRUE}
                  placeholder="0"
                  onChange={this._onPriceChange}
                />
              </div>
            </div>
          </div>
          <div className="col-inline col-inline-3 hidden-xs">
            <div className="white-box">
              <div>You receive</div>
              <div className="value order-you-receive">
                {this.state.sellProposalParams.youReceive::brickPrice()}
              </div>
            </div>
          </div>
        </div>
        {this.state.sellProposalParams.hasQuantityError && (
          <div className="row">
            <div className="col-xs-12 error sell-proposal-error sell-proposal-quantity-error">
              {this.state.sellProposalParams.quantityErrorMsg}
              {this.state.showPendingOrderLink && (
                <span>
                  &nbsp;
                  <a href="/account/my-pending-orders">
                    Manage my Pending Orders
                  </a>
                </span>
              )}
            </div>
          </div>
        )}

        {this.state.sellProposalParams.hasPriceError && (
          <div className="row">
            <div className="col-xs-12 error sell-proposal-error sell-proposal-price-error">
              {this.state.sellProposalParams.priceErrorMsg}
            </div>
          </div>
        )}

        <div className="gray-line"></div>
        <div className="row table-row">
          <div className="col-xs-7 col-no-padding-right col-left">
            <i aria-hidden="true"></i>
            {`${sellForm.quantity} ${
              sellForm.quantity < 2 ? 'Brick' : 'Bricks'
            } @ ${sellForm.price::dollarDecimal()}`}
          </div>
          <div className="col-xs-5 col-no-padding-left col-right bricks-value">
            {this.state.sellProposalParams.valueOfBricks::dollarDecimal()}
          </div>
        </div>
        <div className="gray-line"></div>
        <div className="row table-row">
          <div className="col-xs-7 col-no-padding-right col-left">
            <i aria-hidden="true" />
            Transaction fee (
            <span data-test-reference="transaction-fee">
              {sellFeeForDisplay}%
            </span>
            )
          </div>
          <div
            className="col-xs-5 col-no-padding-left col-right transaction-fee-value"
            data-test-reference="total-transaction-fee"
          >
            {(-this.state.sellProposalParams.transactionFee)::brickPrice()}
          </div>
        </div>
        <div className="gray-line"></div>
        <div className="row table-row">
          <div className="col-xs-7 col-no-padding-right col-left">
            <i aria-hidden="true"></i>You Receive
          </div>
          <div
            className="col-xs-5 col-no-padding-left col-right text-orange order-you-receive"
            data-test-reference="you-recieve"
          >
            {this.state.sellProposalParams.youReceive::brickPrice()}
          </div>
        </div>
        <div className="gray-line"></div>
        <br />
        <div className="action-buttons">
          <div>
            <div>
              <input
                type="checkbox"
                checked={this.state.terms.accepted}
                id="consent"
                required
                onChange={this._onAcceptTermsChange}
              />
              <label htmlFor="consent">&nbsp;I agree to this transaction</label>
            </div>
            {this.state.terms.showErrorMessage && (
              <span className="text-danger accept-terms-warning">
                Check this box before confirming order.
              </span>
            )}
          </div>

          <button
            className="button light-blue-button confirm-sell-button action-full-button"
            disabled={submitButtonDisabled}
            onClick={::this._confirmSell}
            data-test-reference="confirm-button"
          >
            CONFIRM SELL ORDER &nbsp;
            <span className="fa fa-angle-right"></span>
          </button>
        </div>
      </div>
    );
  }

  _updateTotalBricksOnMyPendingSell = (myOrders) => {
    const { property } = this.props;
    if (_.isEmpty(myOrders.pendingSell)) {
      this.setState({
        totalBricksOnMyPendingSell: 0,
        totalBricksOnMyPendingSellLoaded: true,
      });
    } else {
      const sellOrdersForProperty = orderFor(
        myOrders.pendingSell,
        property.propertyCode
      );
      const totalBricksOnMyPendingSell = _.reduce(
        sellOrdersForProperty,
        function (sum, order) {
          return sum + order.quantity;
        },
        0
      );

      this.setState({
        totalBricksOnMyPendingSell: totalBricksOnMyPendingSell,
        totalBricksOnMyPendingSellLoaded: true,
      });
    }
  };

  _updateTotalBricksOwned = (account) => {
    const { getTotalBricksOwned } = this.props;
    const totalBricksOwned = getTotalBricksOwned(account);

    this.setState({
      totalBricksOwned: totalBricksOwned,
      totalBricksOwnedLoaded: true,
    });
  };

  _calculateNumOfBricksCanSell = () => {
    return this.state.totalBricksOwnedLoaded &&
      this.state.totalBricksOnMyPendingSellLoaded
      ? this.state.totalBricksOwned - this.state.totalBricksOnMyPendingSell
      : 0;
  };

  _onQuantityChange = (event) => {
    var updatedQuantity = event.target.value;
    this._handleQuantityChange(updatedQuantity);
  };

  _getTransactionFeeRatio = () => {
    const {
      fees: { sellFee, sellFeeFreeEnabled },
    } = this.props;
    return sellFeeFreeEnabled ? 0 : sellFee ? sellFee / 100 : 0;
  };

  _handleQuantityChange = (updatedQuantity) => {
    const { sellForm } = this.props;
    const { totalBricksOnMyPendingSell } = this.state;
    const numOfBricksCanSell = this._calculateNumOfBricksCanSell();
    var updatedSellForm = sellForm;

    this.setState({
      showPendingOrderLink: false,
    });

    if (validateTradeProposalQuantity(updatedQuantity)) {
      const updatedQuantityInt = Number.parseInt(updatedQuantity, 10);

      if (
        numOfBricksCanSell > 0 &&
        updatedQuantityInt > numOfBricksCanSell &&
        totalBricksOnMyPendingSell > 0
      ) {
        updatedSellForm.hasQuantityError = true;
        updatedSellForm.quantityErrorMsg = `You can only sell ${numOfBricksCanSell::brick()}, as you currently have a Pending Sell Order.`;

        this.setState({
          showPendingOrderLink: true,
        });
      } else if (numOfBricksCanSell === 0) {
        updatedSellForm.hasQuantityError = true;
        updatedSellForm.quantityErrorMsg =
          'You do not have any Bricks eligible to sell. All of your Bricks are currently listed in a pending sell order.';
        this.setState({
          showPendingOrderLink: true,
        });
      } else if (updatedQuantityInt > numOfBricksCanSell) {
        updatedSellForm.hasQuantityError = true;
        updatedSellForm.quantityErrorMsg = `You can only sell ${numOfBricksCanSell::brick()}.`;
      } else {
        updatedSellForm.hasQuantityError = false;
        updatedSellForm.quantityErrorMsg = '';
      }

      const updatedValueOfBricks = sellForm.price * updatedQuantityInt;
      const transactionFeeRate = this._getTransactionFeeRatio();
      const updatedTransactionFee = (updatedValueOfBricks *
        transactionFeeRate)::roundTransactionFee();
      const youReceive = updatedValueOfBricks - updatedTransactionFee;

      updatedSellForm.valueOfBricks = updatedValueOfBricks;
      updatedSellForm.transactionFee = updatedTransactionFee;
      updatedSellForm.youReceive = youReceive;

      updatedSellForm.quantity = updatedQuantityInt;
    } else {
      updatedSellForm.hasQuantityError = true;
      updatedSellForm.quantityErrorMsg =
        'How many Bricks would you like to sell? Enter a quantity.';

      updatedSellForm.quantity = updatedQuantity;
    }

    this._updateForm(updatedSellForm);
    this._sendSellProposalEngagementEvent(updatedSellForm);
  };

  _onPriceChange = (event) => {
    const updatedPrice = event.target.value;
    this._handlePriceChange(updatedPrice);
  };

  _handlePriceChange = (updatedPrice) => {
    const { sellForm, property } = this.props;
    const isNotIndependentValuedProperty =
      !property.financials.isIndependentValued;
    var updatedSellForm = sellForm;

    if (this._validatePrice(updatedPrice)) {
      const updatedPriceInt = Number.parseInt(updatedPrice, 10);
      const lowestSellPriceAllowed = Math.ceil(
        property.financials.brickValue * Trading.LOWEST_SELL_PRICE_PERCENTAGE
      );
      const highestSellPriceAllowed = Math.floor(
        property.financials.brickValue * Trading.HIGHEST_SELL_PRICE_PERCENTAGE
      );
      const brickValueTitle = isNotIndependentValuedProperty
        ? 'Initial Brick Price'
        : 'latest Brick Valuation';

      if (updatedPriceInt < lowestSellPriceAllowed) {
        updatedSellForm.hasPriceError = true;
        updatedSellForm.priceErrorMsg = `Sorry, the minimum price you can list Bricks for sale in ${renderCode(
          property.propertyCode
        )} is ${lowestSellPriceAllowed::dollar()}. (${(1 -
          Trading.LOWEST_SELL_PRICE_PERCENTAGE)::percent()} lower than the ${brickValueTitle})`;
      } else if (
        ENABLE_HIGHEST_SELL_PRICE_VALIDATION &&
        updatedPriceInt > highestSellPriceAllowed
      ) {
        updatedSellForm.hasPriceError = true;
        updatedSellForm.priceErrorMsg = `Sorry, the maximum price you can list Bricks for sale in ${renderCode(
          property.propertyCode
        )} is ${highestSellPriceAllowed::dollar()}. (${(Trading.HIGHEST_SELL_PRICE_PERCENTAGE -
          1)::percent()} higher than the ${brickValueTitle})`;
      } else {
        updatedSellForm.hasPriceError = false;
        updatedSellForm.priceErrorMsg = '';
      }

      const updatedValueOfBricks = updatedPriceInt * sellForm.quantity;
      const transactionFeeRate = this._getTransactionFeeRatio();
      const updatedTransactionFee = (updatedValueOfBricks *
        transactionFeeRate)::roundTransactionFee();
      const youReceive = updatedValueOfBricks - updatedTransactionFee;

      updatedSellForm.valueOfBricks = updatedValueOfBricks;
      updatedSellForm.transactionFee = updatedTransactionFee;
      updatedSellForm.youReceive = youReceive;

      updatedSellForm.price = updatedPriceInt;
    } else {
      updatedSellForm.hasPriceError = true;
      updatedSellForm.priceErrorMsg =
        'Please enter a sale price for your Brick(s).';

      updatedSellForm.price = updatedPrice;

      this.setState({
        showPendingOrderLink: false,
      });
    }

    this._updateForm(updatedSellForm);
    this._sendSellProposalEngagementEvent(updatedSellForm);
  };

  _validatePrice = (price) => {
    var pattern = new RegExp('^[1-9]{1}[0-9]*$');
    return pattern.test(price);
  };

  _onAcceptTermsChange = () => {
    this.setState({
      terms: { accepted: !this.state.terms.accepted, showErrorMessage: false },
    });
  };

  async _confirmSell() {
    if (!this.state.terms.accepted) {
      this.setState({ terms: { showErrorMessage: true } });
      return;
    }

    this.setState({
      loading: true,
    });

    const { user, confirmSell, property, account, sellForm } = this.props;
    if (sellForm.hasPriceError || sellForm.hasQuantityError) return;
    if (checkUserWithTradePermission(user, this.context.router)) {
      const {
        sellProposalParams: {
          price,
          quantity,
          valueOfBricks,
          transactionFee,
          youReceive,
          totalBricksOwned,
        },
      } = this.state;
      const quantityInt = Number.parseInt(quantity, 10);
      await confirmSell(property.propertyCode, {
        price,
        quantity: quantityInt,
        valueOfBricks,
        transactionFee,
        total: youReceive,
      });
      sendSellProposalSubmitEvent({
        tradeProposalData: {
          propertyCode: property.propertyCode,
          quantity: quantityInt,
          basisPrice: price,
          valueOfBricks: valueOfBricks,
          transactionFee: transactionFee,
          total: youReceive,
        },
        account: account,
        totalBricksOwned: totalBricksOwned + quantityInt,
      });
    }
  }

  _buildSellProposalParams = (updatedSellForm) => {
    var updatedQuantity = updatedSellForm.quantity;
    if (!validateTradeProposalQuantity(updatedQuantity)) {
      updatedQuantity = 0;
    }

    var updatedPrice = updatedSellForm.price;
    if (!this._validatePrice(updatedPrice)) {
      updatedPrice = 0;
    }

    const updatedValueOfBricks = updatedPrice * updatedQuantity;
    const transactionFeeRate = this._getTransactionFeeRatio();
    const updatedTransactionFee = (updatedValueOfBricks *
      transactionFeeRate)::roundTransactionFee();
    const youReceive = updatedValueOfBricks - updatedTransactionFee;

    this.setState({
      sellProposalParams: {
        quantity: updatedSellForm.quantity,
        price: updatedSellForm.price,
        valueOfBricks: updatedValueOfBricks,
        transactionFee: updatedTransactionFee,
        youReceive: youReceive,
        hasPriceError: updatedSellForm.hasPriceError,
        hasQuantityError: updatedSellForm.hasQuantityError,
        quantityErrorMsg: updatedSellForm.quantityErrorMsg,
        priceErrorMsg: updatedSellForm.priceErrorMsg,
      },
    });
  };

  _updateForm = (updatedSellForm) => {
    this._buildSellProposalParams(updatedSellForm);
    updatedSellForm.callback(updatedSellForm);
  };

  _sendSellProposalEngagementEvent(sellForm) {
    const { property, account } = this.props;
    const { totalBricksOwned } = this.state;

    sendSellProposalEngagementEvent({
      tradeProposalData: {
        propertyCode: property.propertyCode,
        quantity: sellForm.quantity,
        basisPrice: sellForm.price,
        valueOfBricks: sellForm.valueOfBricks,
        transactionFee: sellForm.transactionFee,
        total: sellForm.youReceive,
      },
      account: account,
      totalBricksOwned: totalBricksOwned,
    });
  }
}
