import classNames from 'classnames';
import styled from 'styled-components';
import { getThemeColor } from 'src/themes/themeUtils';
import Button, {
  colors,
  getColor,
  sizes
} from 'src/design/components/button/Button';

const getSelectedColor = (props) => getThemeColor(getColor(props.color))(props);

const StyledButton = styled(({ asElement, ...props }) => <Button {...props} as={asElement}/>)`

  && {
    border-color: ${getSelectedColor};
    color: ${getSelectedColor};
  }

  &&:hover, &&:active {
    color: ${getSelectedColor};
  }

  &&[disabled] {
    border-color: ${getThemeColor('concrete')};
    color: ${getThemeColor('concrete')};
  }
`;

const SecondaryButton = ({ className, children, as, ...props }) => (
  <StyledButton
    className={classNames(className)}
    asElement={as}
    {...props}
  >{children}</StyledButton>
);

SecondaryButton.colors = colors;
SecondaryButton.sizes = sizes;

export default SecondaryButton;
