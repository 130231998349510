import classNames from 'classnames';
import DayOfMonthSelect from 'src/design/components/formSelect/DayOfMonthSelect';
import MonthOfYearSelect from 'src/design/components/formSelect/MonthOfYearSelect';
import NumberSelect from 'src/design/components/formSelect/NumberSelect';
import styles from 'src/design/components/dateOfBirth/DateOfBirth.mscss';
import withError from 'src/design/components/textInput/withError';


const DateOfBirth = withError(({
  className,
  day,
  month,
  year,
  error,
  ...props
}) => (
  <div className={className}>
    <div className={styles.dateOfBirthInput_container}>
      <DayOfMonthSelect
        className={styles.dateOfBirth_input}
        testRef={day.testRef}
        {...props}
        errorHighlight={error}
        value={day.value}
        onChange={day.onChange}
      />
      <MonthOfYearSelect
        className={styles.dateOfBirth_input}
        testRef={month.testRef}
        {...props}
        errorHighlight={error}
        value={month.value}
        onChange={month.onChange}
      />
      <NumberSelect
        className={classNames(styles.dateOfBirth_input, styles.dateOfBirth_year_input)}
        testRef={year.testRef}
        {...props}
        min={1900}
        max={new Date().getFullYear()}
        defaultValueText="YYYY"
        reversed
        errorHighlight={error}
        value={year.value}
        onChange={year.onChange}
      />
    </div>
  </div>
));

export default DateOfBirth;
