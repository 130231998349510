/**
 * Trim and Clean up multiple whitespaces in the text
 *
 * Examples:
 * input:       "   BrickX   is Great "
 * output:      "BrickX is Great"
 * @param {string} s
 * @returns string
 */
export const cleanString = (s) => s.replace(/ +(?= )/g, '').trim();
