import { headers, withCredentials } from 'scripts/services/helpers';
import {
  orderbook,
  propertyInvestors,
  propertyRemainingBricks,
  bricksTransactedLastMonth,
  purchaseProposal,
  confirmPurchase,
  confirmSell,
  cancelOrder,
  myOrders,
  myTrades,
  orders,
  trades,
  lastNTrades,
  marketStatus,
  returnsCalculatorStatus,
  promotions,
  setMarketStatus,
  transactions,
  investmentMetrics,
  setOrderBookStatus,
  orderBookStatus
} from 'scripts/services/api';
import request from 'scripts/services/request';

class MarketService {
  getOrderBook(propertyCode) {
    return request(orderbook(propertyCode), {
      ...withCredentials
    });
  }

  getPropertyRemainingBricks(propertyCode) {
    return request(propertyRemainingBricks(propertyCode), {
      ...withCredentials
    });
  }

  getNumOfBricksTransactedLastMonth(propertyCode) {
    return request(bricksTransactedLastMonth(propertyCode), {
      ...withCredentials
    });
  }

  getPropertyInvestors(propertyCode) {
    return request(propertyInvestors(propertyCode), {
      ...withCredentials
    });
  }

  getInvestmentMetrics() {
    return request(investmentMetrics(), {
      ...withCredentials
    });
  }

  placePurchaseProposal(propertyCode, quantity) {
    return request(purchaseProposal(propertyCode, quantity), {
      ...withCredentials
    });
  }

  confirmPurchase(propertyCode, buyProposal) {
    return request(confirmPurchase(propertyCode), {
      method: 'POST',
      ...withCredentials,
      headers,
      body: JSON.stringify(buyProposal)
    });
  }

  confirmSell(propertyCode, sellOrder) {
    return request(confirmSell(propertyCode), {
      method: 'POST',
      ...withCredentials,
      headers,
      body: JSON.stringify(sellOrder)
    });
  }

  cancelOrder(propertyCode, { orderId }) {
    return request(cancelOrder(propertyCode, orderId), {
      method: 'POST',
      ...withCredentials,
      headers,
      body: JSON.stringify({})
    });
  }

  getReturnsCalculatorStatus() {
    return request(returnsCalculatorStatus());
  }

  getMarketStatus() {
    return request(marketStatus(), {
      ...withCredentials
    });
  }


  getPromotions(userId) {
    return request(promotions(userId), {
      ...withCredentials
    });
  }

  setMarketStatus(enabled) {
    return request(setMarketStatus(), {
      method: 'POST',
      ...withCredentials,
      headers,
      body: JSON.stringify({ 'enabled': enabled })
    });
  }

  setOrderBookStatus(propertyCode, enabled) {
    return request(setOrderBookStatus(propertyCode), {
      method: 'POST',
      ...withCredentials,
      headers,
      body: JSON.stringify({ 'enabled': enabled })
    });
  }

  getOrderBookStatus(propertyCode) {
    return request(orderBookStatus(propertyCode), {
      ...withCredentials
    });
  }

  getOrders(propertyCode) {
    return request(orders(propertyCode), {
      ...withCredentials
    });
  }

  getTrades(propertyCode) {
    return request(trades(propertyCode), {
      ...withCredentials
    });
  }

  getLastNTrades(propertyCode, limit) {
    return request(lastNTrades(propertyCode, limit), {
      ...withCredentials
    });
  }

  getMyOrders(userId) {
    return request(myOrders(userId), {
      ...withCredentials
    });
  }

  getMyTrades(userId) {
    return request(myTrades(userId), {
      ...withCredentials
    });
  }

  transactions(userId = this.userId, year) {
    return request(transactions(userId, year), {
      ...withCredentials,
    });
  }
}

export default new MarketService();

export const __MarketServiceAngular__ = () => new MarketService();
