import React from 'react';
import styled from 'styled-components';
import { breakpointSmall } from 'src/design/styleguide/common/breakpoints';
import { getThemeColor } from 'src/themes/themeUtils';
import { howToSelectPropertyVideoId } from 'src/utils/videoIds';
import {
  trackClosePropertiesVideoExpandy,
  trackOpenPropertiesVideoExpandy,
} from 'src/tracking/propertiesExplainerExpandyVideo';
import Expandable from 'src/design/components/expandable/Expandable';
import Icon from 'src/design/components/icon/Icon';
import Paragraph from 'src/design/styleguide/Paragraph';
import PoppingCard from 'src/design/components/poppingCard/PoppingCard';
import Video from 'src/design/components/video/Video';
import withState from 'src/decorators/withState';

const ExplainerClickable = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 16px;
  cursor: pointer;

  ${breakpointSmall(`
    align-items: center;
  `)}
`;

const LeftTextAndIconContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

const TextContainer = styled.div`
  margin: 0 16px 0 16px;
`;

const MainText = styled(Paragraph)`
  font-size: 20px;
  font-weight: 500;

  ${breakpointSmall(`
    font-size: 24px;
  `)}
`;

const SecondaryText = styled(Paragraph)`
  font-size: 16px;

  ${breakpointSmall(`
    font-size: 20px;
  `)}
`;

const VideoIcon = styled(Icon).attrs({
  type: Icon.types.video,
})`
  color: ${getThemeColor('primary')};
  font-size: 40px
`;

const RotatingIcon = styled(Icon)`
font-size: 20px;
  color: ${getThemeColor('primary')};
  transition: transform 300ms ease-in-out;
  ${props => props.rotated ? 'transform: rotate(180deg);' : ''}
`;

const VideoContainer = styled.div`
  margin: -1px 0;
`;

const ExpandableExplainerVideo = withState({
  Component: ({ state, setState }) => {
    return (
      <PoppingCard withBorderTopFeature>
        <ExplainerClickable
          onClick={() => {
            state.expanded
              ? trackClosePropertiesVideoExpandy()
              : trackOpenPropertiesVideoExpandy();
            setState({ expanded: !state.expanded });
          }}
          data-test-reference="expandy-clickable-area"
        >
          <LeftTextAndIconContainer>
            <RotatingIcon type="chevron_down" rotated={state.expanded}/>
            <TextContainer>
              <MainText>Learn about how to select properties!</MainText>
              <SecondaryText>Watch this video explaining factors to consider when buying Bricks.</SecondaryText>
            </TextContainer>
          </LeftTextAndIconContainer>
          <VideoIcon/>
        </ExplainerClickable>

        <Expandable expanded={state.expanded}>
          <VideoContainer>
            <Video videoId={howToSelectPropertyVideoId} unload={!state.expanded}/>
          </VideoContainer>
        </Expandable>
      </PoppingCard>
    );
  },
});

export default ExpandableExplainerVideo;
