import classNames from 'classnames';
import LazyLoad from 'react-lazyload';

import { HeadingSmall } from 'src/design/components/heading/Headings';
import FinePrint from 'src/design/components/bodyText/FinePrint';
import Styledlink from 'src/design/components/hyperlink/Styledlink';

import styles from 'src/components/landingPages/common/propertyPerformanceCharting/PropertyPerformanceCharting.mscss';


const PropertyPerformanceChart = () => (
  <div className={classNames(styles.chartedArea)}>
    <figure className={styles.chartImageFigure}>
      <LazyLoad>
        <img
          className={styles.chartImage}
          src="/static/img/chart-property-performance-20-years.svg"
          alt="Residential property performance chart - showing the last 20 years"
        />
      </LazyLoad>
      <figcaption className={styles.chartLegend}>
        <div className={classNames(styles.legendItem, styles.legendResidential)}>Residential property</div>
        <div className={classNames(styles.legendItem, styles.legendShares)}>Share market</div>
        <div className={classNames(styles.legendItem, styles.legendCash)}>Cash rate</div>
      </figcaption>
    </figure>
    <HeadingSmall className={styles.chartTitle}><h3>See how the property market has performed over the last 20 years</h3></HeadingSmall>
    <FinePrint className={styles.chartDisclaimer}>This displays a hypothetical investment made on 31/7/1998 with performance reflecting the following indicators: CoreLogic Home Value Index (with 3% net rental yield, returns reinvested and 4% stamp duty) S&P/ASX 200 Total Return Index (with dividends reinvested) RBA Cash Rate (with interest reinvested). All returns are calculated before tax. Past performance is not necessarily an indication of future performance. <Styledlink href="//news.brickx.com/investing/property-vs-shares-vs-cash/">Read our blog to find out more</Styledlink></FinePrint>
    <div className={styles.clearFix} />
  </div>
);

export default PropertyPerformanceChart;
