import styled from 'styled-components';
import { SubHeading } from 'src/design/styleguide/Headings';
import { WelcomePageWidgetPanel } from 'src/components/dashboard/home/commonComponents';
import { breakpointSmall } from 'src/design/styleguide/common/breakpoints';
import { defineGridArea } from 'src/design/styleguide/common/styledComponentsUtils';
import { gridUnit } from 'src/design/styleguide/common/measurements';
import { minorLandingPageUrl } from 'src/utils/pageUrls';
import Image from 'src/design/components/image/Image';
import Paragraph from 'src/design/styleguide/Paragraph';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import Styledlink from 'src/design/components/hyperlink/Styledlink';
import history from 'src/browser/history';

const Container = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: ${gridUnit * 6}px;
  justify-items: center;

  ${breakpointSmall(`
    grid-template-columns: minmax(0, 1fr) auto;
    justify-items: start;
    align-items: start;
    ${defineGridArea('icon-area')}
    ${defineGridArea('button-area')}
    ${defineGridArea('text-area')}
    grid-template-areas:
      'icon-area button-area'
      'text-area text-area'
  `)}
`;

const StyledImage = styled(Image)`
  width: 70px;
`;

const PromoTextArea = styled.div`
  max-width: 500px;

  ${breakpointSmall(`
    max-width: 250px;
  `)}
`;

const PromoTitle = styled(SubHeading).attrs({
  as: 'h3',
  fontSize: '2x-small',
  fontWeight: 'bold',
})`
  ${breakpointSmall(`
    text-align: left;
  `)}
`;

const PromoText = styled(Paragraph).attrs({
  fontSize: '3x-small',
  align: 'center',
})`

  ${breakpointSmall(`
    text-align: left;
  `)}
`;


const MinorAccountPromoPanel = ({ className }) => {
  const signOutAndSignUp = () => {
    history.push('/logout-signup-minor');
  };

  const isComingSoon = false;

  if (isComingSoon) {
    return (
      <WelcomePageWidgetPanel className={className} title="BrickX Minor" testRef="minors-promo-panel">
        <Container>
          <StyledImage className="icon-area" src="https://assets.brickx.com/images/children-illlustrate.png" alt="enrol in smart invest icon"/>
          <PromoTextArea className="text-area">
            <PromoTitle>Invest for the future!</PromoTitle>
            <Spacing top="x-small">
              <PromoText>Coming Soon...</PromoText>
            </Spacing>
          </PromoTextArea>
          <PrimaryButton
            className="button-area"
            color={PrimaryButton.colors.SECONDARY}
            size={PrimaryButton.sizes.SMALL}
            link={minorLandingPageUrl()}
            testRef="minors-promo-panel-cta"
            forceExternal
          >
            Learn More
          </PrimaryButton>
        </Container>
      </WelcomePageWidgetPanel>
    );
  }

  return (
    <WelcomePageWidgetPanel className={className} title="BrickX Minor" testRef="minors-promo-panel">
      <Container>
        <StyledImage className="icon-area" src="https://assets.brickx.com/images/children-illlustrate.png" alt="enrol in smart invest icon"/>
        <PromoTextArea className="text-area">
          <PromoTitle>Introducing BrickX for Minors</PromoTitle>
          <Spacing top="x-small">
            <PromoText>Sign Up for a Minor account today!</PromoText>
          </Spacing>
          <Spacing top="x-small">
            <PromoText>NOTE: You will be signed out and redirected to the Signup page.</PromoText>
          </Spacing>
          <PromoText><Styledlink href={minorLandingPageUrl()} forceExternal>Learn more</Styledlink></PromoText>
        </PromoTextArea>
        <PrimaryButton
          className="button-area"
          color={PrimaryButton.colors.YOLK}
          size={PrimaryButton.sizes.SMALL}
          onClick={signOutAndSignUp}
          testRef="minors-promo-panel-cta"
        >
          Sign Up Now
        </PrimaryButton>
      </Container>
    </WelcomePageWidgetPanel>
  );
};

export default MinorAccountPromoPanel;
