import {
  ButtonsContainer,
  PrimaryButtonWithLessPadding,
  SecondaryButtonWithLessPadding,
  HorizontalAtMediumBreakpointContainer,
  PanelValueHeading,
  PanelValueText,
  TotalValueContainer,
  WelcomePageWidgetPanel,
  WidgetPanelLink
} from 'src/components/dashboard/home/commonComponents';
import { TOTAL_VALUE_POPOVER_TEXT } from 'src/components/dashboard/portfolio/helpText';
import { accountUrl, portfolioUrl, propertiesUrl, sellBricksUrl } from 'src/utils/pageUrls';
import { dollarDecimal } from 'src/utils/formats';
import Popover from 'scripts/components/shared/Popover';
import SecondaryButton from 'src/design/components/button/SecondaryButton';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import { getCustomButtonColor } from './DashboardHome';

const PortfolioPanel = ({ className, account, user }) => (
  <WelcomePageWidgetPanel
    className={className}
    title="Portfolio"
    action={<WidgetPanelLink text="Advanced View" href={portfolioUrl()} testRef="portfolio-panel-header-link"/>}
    testRef="portfolio-panel"
  >
    <HorizontalAtMediumBreakpointContainer>
      <TotalValueContainer>
        <PanelValueHeading>Total Value of My Bricks <Popover placement="top" content={TOTAL_VALUE_POPOVER_TEXT}/></PanelValueHeading>

        <Spacing top="2x-small">
          <PanelValueText testRef="portfolio-panel-total-value">
            {dollarDecimal(account.aggregatedPortfolio.totalValue)}
          </PanelValueText>
        </Spacing>
      </TotalValueContainer>

      <ButtonsContainer>
        <PrimaryButtonWithLessPadding
          color={getCustomButtonColor(user)}
          responsiveSizes={{ medium: 'SMALL' }}
          link={propertiesUrl()}
          testRef="portfolio-panel-buy-button-link"
        >
          Buy Bricks
        </PrimaryButtonWithLessPadding>

        <Spacing top="x-small">
          <SecondaryButtonWithLessPadding
            color={SecondaryButton.colors.PRIMARY}
            responsiveSizes={{ medium: 'SMALL' }}
            link={{
              pathname: sellBricksUrl(),
              state: { from: {
                name: 'My Dashboard',
                url: accountUrl(),
              } },
            }}
            testRef="portfolio-panel-sell-button-link"
          >
            Sell Bricks
          </SecondaryButtonWithLessPadding>
        </Spacing>
      </ButtonsContainer>
    </HorizontalAtMediumBreakpointContainer>
  </WelcomePageWidgetPanel>
);

export default PortfolioPanel;
