import React, { Component } from 'react';
import { RectShape } from 'react-placeholder/lib/placeholders';

import Configuration from 'scripts/constants/Configuration';
import Numbers from 'scripts/constants/Numbers';

import _ from 'lodash';


const BACK_GROUND_COLOR = Configuration.PLACEHOLDER_COMPONENT_BG_COLOR;
const DEFAULT_NUMBER_OF_ROWS = 5;

export default class OrderBookTableBodyPlaceholder extends Component {
  render() {
    return (
      <tbody>
        {[..._.range(Numbers.ZERO, DEFAULT_NUMBER_OF_ROWS)].map((index) =>
          <tr key={index}>
            <td className={`text-left sell-order-${index}-quantity`}>
              <RectShape color={BACK_GROUND_COLOR} style={{ height: 20 }}/>
            </td>
            <td className={`text-left sell-order-${index}-quantity`}>
              <RectShape color={BACK_GROUND_COLOR} style={{ height: 20 }}/>
            </td>
            <td className={`text-left sell-order-${index}-quantity`}>
              <RectShape color={BACK_GROUND_COLOR} style={{ height: 20 }}/>
            </td>
          </tr>
        )}
      </tbody>
    );
  }
}
