

import Tfn from 'scripts/components/account/settings/Tfn';
import InfoBox from 'scripts/components/shared/InfoBox';
import TfnForm from 'scripts/components/account/settings/TfnForm';
import Wallet from 'scripts/constants/Wallet';
import { percent } from 'scripts/utilities/formatters';


const TfnCompactLayout = () => {
  return (
    <Tfn>
      <div className="row form-row compact-layout">
        <div className="col-md-4">
          <label htmlFor="tfn">
            Please provide your Tax File Number (TFN)
          </label>
          <TfnForm/>
        </div>
        <div className="col-md-8">
          <InfoBox>
            Please enter your TFN to receive your full distributions. If you choose not to provide your TFN, BrickX is required to withhold tax at the highest marginal rate which is currently {Wallet.TAX_AMOUNT::percent()}.
          </InfoBox>
        </div>
      </div>
    </Tfn>
  );
};


export default TfnCompactLayout;
