import React, { Component } from 'react';
import DocumentMeta from 'react-document-meta';

import { property as propertyPropType, user as userPropType } from 'scripts/constants/PropTypes';
import TradeSucceed from 'scripts/components/property/trade/TradeSucceed';

import { SUCCESS_SELL_META } from 'scripts/metadata/statics';


export default class TradeSucceedSell extends Component {
  static propTypes = {
    user: userPropType,
    property: propertyPropType,
  };

  render() {
    const { user, property } = this.props;
    return (
      <div>
        <DocumentMeta {...SUCCESS_SELL_META} />
        <TradeSucceed user={user} property={property}/>
      </div>
    );
  }
}
