import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SelectionButton from 'src/design/components/button/SelectionButton';
import Heading from 'src/design/components/heading/Heading';
import Paragraph from 'src/design/components/bodyText/Paragraph';
import TextInput from 'src/design/components/textInput/TextInput';
import TextInputWithError from 'src/design/components/textInput/TextInputWithError';
import HelpDisclaimer from 'src/design/components/helpDisclaimer/HelpDisclaimer';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import OnboardingScreen, {
  OnboardingScreenHeader,
  OnboardingScreenContent,
} from 'src/components/oldOnboarding/common/onboardingScreen/OnboardingScreen';
import OnboardingNextButton from 'src/components/oldOnboarding/common/onboardingNextButton/OnboardingNextButton';
import OnboardingBackButton from 'src/components/oldOnboarding/common/onboardingBackButton/OnboardingBackButton';
import { onboardingProductSelectionUrl } from 'src/utils/pageUrls';
import { minimumSIAmount, minimumSIInitialAmount } from 'src/settings/funds';
import {
  trackDepositAmountSelectAttempt,
  trackDirectDebitAmountSelected,
} from 'src/tracking/onboarding';
import { dollar } from 'src/utils/formats';
import styles from 'src/components/oldOnboarding/smartInvestDetails/smartInvestAmountInput/SmartInvestAmountInput.mscss';

const isAmountValid = (amount) => amount >= minimumSIInitialAmount;

const AmountSelectionButton = ({ amount, onChange }) => (
  <SelectionButton
    className={classNames(styles.selectionButtonFullWidth, 'onboarding-screen__form-element--margin-8')}
    onClick={() => onChange(amount)}
    arrow
    testRef={`direct-debit-amount-selection-${amount}`}
  >{`$${amount}/month`}</SelectionButton>
);

class SmartInvestAmountInput extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
    onNext: PropTypes.func,
  };

  state = {
    error: false,
  };

  onNextButtonClick = () => {
    trackDepositAmountSelectAttempt({
      amount: this.props.value,
      product: 'Smart Invest',
      paymentMethod: 'Direct Debit',
    });

    if (isAmountValid(this.props.value)) {
      trackDirectDebitAmountSelected(this.props.value);
      this.props.onNext();
    } else {
      this.setState({ error: true });
    }
  }

  onTextInputChange = (value) => {
    this.props.onChange(value);
    if (this.state.error) { this.setState({ error: false }); }
  }

  render() {
    const { onChange, value, onNext } = this.props;
    const { error } = this.state;

    const onChangeAndNext = (amount) => {
      trackDirectDebitAmountSelected(amount);
      onChange(amount);
      onNext();
    };

    const valueEntered = !!(value && value.length);
    const showNextButton = valueEntered;

    return (
      <OnboardingScreen>
        <OnboardingScreenHeader>
          <Spacing bottom="large">
            <Heading>How much would you like to invest each month?</Heading>
          </Spacing>
          <Spacing bottom="large">
            <Paragraph className="onboarding-screen__form-element--margin-24">The initial minimum amount is ${minimumSIInitialAmount}. You can reset it to ${minimumSIAmount} or more in next month.</Paragraph>
          </Spacing>
          <Spacing bottom="large">
            <Paragraph className="onboarding-screen__form-element--margin-24">If you like, it's easy to skip a month or cancel anytime (in My Settings).</Paragraph>
          </Spacing>
        </OnboardingScreenHeader>
        <OnboardingScreenContent>
          <AmountSelectionButton amount="500" onChange={onChangeAndNext}/>
          <AmountSelectionButton amount="400" onChange={onChangeAndNext}/>
          <AmountSelectionButton amount="300" onChange={onChangeAndNext}/>
          <AmountSelectionButton amount="250" onChange={onChangeAndNext}/>
          <TextInputWithError
            className="onboarding-screen__form-element--margin-8"
            type="text"
            onChange={this.onTextInputChange}
            value={value}
            placeholder="Other, please specify"
            isCharacterValid={TextInput.validation.numbersOnly}
            highlighted={valueEntered}
            error={error && `The initial minimum amount is ${dollar(minimumSIInitialAmount)}/month`}
            testRef="direct-debit-amount-input"
            errorTestRef="minimum-amount-error"
          />
          {showNextButton && <OnboardingNextButton onClick={this.onNextButtonClick}/>}
          <Spacing top="large">
            <HelpDisclaimer>If you have insufficient funds in your nominated account, we will send you a notification and just try again the next month. Bank fees for insufficient funds may apply, check with your financial institution.</HelpDisclaimer>
          </Spacing>
          <OnboardingBackButton href={onboardingProductSelectionUrl()} testRef="back-to-product-selection-link"/>
        </OnboardingScreenContent>
      </OnboardingScreen>
    );
  }
}

export default SmartInvestAmountInput;
