import classNames from 'classnames';
import Button from 'src/design/components/button/Button';
import Icon from 'src/design/components/icon/Icon';
import styles from 'src/design/components/button/IconButton.mscss';

const IconButton = ({ className, type, ...props }) => (
  <Button className={classNames(styles.button, className)} {...props}>
    <Icon type={type}/>
  </Button>
);

IconButton.types = Icon.types;

export default IconButton;
