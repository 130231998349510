import classNames from 'classnames';
import { Link } from 'react-router';
import { linkWasClicked } from 'src/tracking/hyperlink';
import styles from 'src/design/components/hyperlink/Hyperlink.mscss';


const isExternalLink = (link) => typeof link !== 'object' && (
  link.match(/\/\//) || link.match(/mailto:/)
);

const Hyperlink = ({
  className,
  href,
  trackingDescription,
  onClick = () => {},
  forceExternal,
  testRef,
  children,
  ...otherProps
}) => {
  const commonProps = {
    className: classNames(styles.hyperlink, className),
    'data-test-reference': testRef,
    children,
    onClick: (...args) => {
      linkWasClicked(href, trackingDescription);
      onClick(...args);
    },
    ...otherProps,
  };

  const renderAsLink = href && !isExternalLink(href) && !forceExternal;
  return renderAsLink
    ? <Link to={href} {...commonProps}/>
    : <a href={href} {...commonProps}/>;
};

export default Hyperlink;
