import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isFunction from 'lodash.isfunction';
import history from 'src/browser/history';


export default class Hyperlink extends Component {
  static propTypes = {
    className: PropTypes.string,
    isExternalLink: PropTypes.bool,
    linkTo: PropTypes.string,
    onClickCallback: PropTypes.func,
    testRef: PropTypes.string,
  };

  render() {
    const { className, children, linkTo, isExternalLink, onClickCallback, testRef, ...otherProps } = this.props;
    return (
      <a
        href={linkTo}
        className={classNames(className, { 'active': false })}
        onClick={::this._onClick}
        target={isExternalLink ? '_blank' : ''}
        data-test-reference={testRef}
        {...otherProps}
      >
        {children}
      </a>
    );
  }

  _onClick = (e) => {
    const { onClickCallback, linkTo, isExternalLink } = this.props;
    if (!isExternalLink) {
      if (e) e.preventDefault();
      history.push(linkTo);
      if (isFunction(onClickCallback)) {
        onClickCallback();
      }
    }
  };
}
