import DocumentMeta from 'react-document-meta';
import { minimumDeposit } from 'src/settings/funds';
import { TERMS_META } from 'scripts/metadata/statics';

const BuyFirstBrickBonusTerms = () => (
  <div className="container">
    <DocumentMeta {...TERMS_META} />
    <div className="row">
      <div className="col-lg-12 terms-page">
        <h1>Bonus $50 offer terms and conditions</h1>
        <p><b>1.</b> This promotion is offered by The Brick Exchange Pty Limited (ABN 27 600 762 749) (BrickX) as the manager of the BrickX Platform (ARSN 613 156 433). Theta Asset Management Ltd (ABN 37 071 807 684, AFSL 230920) (Theta) is product issuer for the BrickX Platform, but is not responsible for offering or administering this BrickX promotion.</p>
        <p><b>2.</b> The $50 bonus payment is being offered, and will be fully funded by BrickX from its own funds and will not be sourced from the assets of the BrickX Platform or any BrickX Trust.</p>
        <p><b>3.</b> This promotion is only available to prospective members aged over 18, who are Australian residents and who are recorded on the BrickX Platform database but who do not hold an Interest in the BrickX Platform and who have received a promotional email directly from BrickX with the details of the promotion (‘eligible clients’).</p>
        <p><b>4.</b> To receive the $50 bonus into their Digital Wallet, eligible clients must deposit the minimum application amount of ${minimumDeposit} into their Digital Wallet by 11.59pm AEDT on 22nd November 2017 and own at least $500 in Bricks (calculated by reference to the purchase price of Bricks – excluding fees) as at 11.59pm on 15th December 2017.</p>
        <p><b>5.</b> The $50 bonus will be paid by BrickX into the Digital Wallets of eligible clients with the next monthly distribution paid in January 2018.</p>
        <p><b>6.</b> The $50 bonus payment is limited to one payment per individual.</p>
        <p><b>7.</b> This promotion activation period closes at 11.59pm AEDT on 22nd November 2017.</p>
        <p><b>8.</b> BrickX has not considered the tax implications of the $50 bonus payment.</p>
        <p><b>9.</b> BrickX reserves the right to withdraw this promotion at any time.</p>
        <p><b>10.</b> This promotion is not open to BrickX or Theta employees or their immediate families.</p>
        <p><b>11.</b> All questions relating to this promotion should be directed to BrickX at info@brickx.com</p>
        <p><b>12.</b> Capitalised terms have the meaning given in the BrickX Platform product disclosure statement unless otherwise defined.</p>
        <p><b>13.</b> Please note that BPAY payments can take up to 1-2 business days to be received and appear as cleared funds in your Digital Wallet. Payments can also be made through POLi to provide instant funds (up to $20,000).</p>
      </div>
    </div>
  </div>
);

export default BuyFirstBrickBonusTerms;
