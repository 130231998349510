// const SoldOut = ({ propertyCode, isPreOrderProperty }) => (
//     isPreOrderProperty
//     ? ""
//     :
//     <div className="col-xs-12 error error-sold-out">Sorry, we are unable to process your {isPreOrderProperty ? 'Pre-Order' : 'Order'}. All the Bricks for {propertyCode} have been {isPreOrderProperty ? 'Pre-Ordered' : 'Sold'}, and there are now 0 Bricks remaining.</div>

import { renderCode } from 'src/settings/properties';

// );
const SoldOut = ({ propertyCode, isPreOrderProperty }) => (
  <div className="col-xs-12 error error-sold-out">
    Sorry, we are unable to process your{' '}
    {isPreOrderProperty ? 'Pre-Order' : 'Order'}. All the Bricks for{' '}
    {renderCode(propertyCode)} have been{' '}
    {isPreOrderProperty ? 'Pre-Ordered' : 'Sold'}, and there are now 0 Bricks
    remaining.
  </div>
);

export default SoldOut;
