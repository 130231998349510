import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Heading } from 'src/design/components/heading/Headings';
import { SubHeading } from 'src/design/styleguide/Headings';
import { businessDetailsSelector } from 'scripts/redux/selectors/businessDetails';
import { changePasswordUrl } from 'src/utils/pageUrls';
import {
  deactivateAccount as deactivateAccountAction,
  saveEmailSubscriptions as saveEmailSubscriptionsAction,
} from 'scripts/redux/actions/user';
import { fetchBusinessDetails as fetchBusinessDetailsAction } from 'scripts/redux/actions/businessDetails';
import {
  hasNominatedBankAccount,
  hasBasicEmailSubscription,
} from 'scripts/utilities/userAccountHelper';
import { hasSetupPersonalDetails } from 'scripts/utilities/registrationHelper';
import { showModal as showModalAction } from 'src/components/modals/modalActions';
import {
  user as userPropType,
  businessDetails as businessDetailsPropType,
} from 'scripts/constants/PropTypes';
import { userSelector } from 'scripts/redux/selectors/user';
import BankDetailsForm from 'src/components/settings/BankDetailsForm';
import Divider from 'src/design/components/divider/Divider';
import FinePrint from 'src/design/styleguide/FinePrint';
import FormCheckbox from 'src/design/components/formCheckbox/FormCheckbox';
import Hyperlink from 'src/design/components/hyperlink/Hyperlink';
import InfoBox from 'src/design/components/infoBox/InfoBox';
import MyDetails from 'scripts/components/account/settings/MyDetails';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import SecondaryButton from 'src/design/components/button/SecondaryButton';
import Spacing, { getSizePx } from 'src/design/styleguide/spacing/Spacing';
import Tfn from 'src/components/settings/tfn/Tfn';
import styles from 'src/components/settings/Settings.mscss';

const DeactivateModalContent = ({ deactivateAccount, closeThisModal }) => {
  const onClick = async (e) => {
    e.preventDefault();
    const payload = await deactivateAccount();
    closeThisModal();
    window.location.href = payload.redirectTo;
  };

  return (
    <div className={styles.deactivateModalContent}>
      <Heading>
        <h2>Are you sure?</h2>
      </Heading>
      <Spacing top="2x-large" bottom="2x-large">
        <PrimaryButton color={PrimaryButton.colors.SECONDARY} onClick={onClick}>
          Yes, Deactivate Account
        </PrimaryButton>
      </Spacing>
      <FinePrint as="p">
        <strong>Did you know? </strong>
        Once you have deactivated your BrickX Account, you won't be able to use
        BrickX to buy and sell Bricks. You will still have access to login to
        obtain financial information on your prior investments. Even if your
        account is deactivated, BrickX is required to send occasional updates
        critical to informing you as a past investor, as per the regulations. We
        will only send you information when required.
      </FinePrint>
    </div>
  );
};

const SettingsContainer = styled.div`
  padding-top: ${getSizePx('x-large')};
`;

const SectionContainer = styled.div`
  &:last-child {
    .section-divider {
      display: none;
    }
  }
`;

const Section = ({ title, children }) => (
  <SectionContainer>
    <Spacing bottom="normal">
      <SubHeading fontSize="2x-small" align="left">
        {title}
      </SubHeading>
    </Spacing>
    {children}
    <Divider className="section-divider" />
  </SectionContainer>
);

const SectionActionButton = (props) => (
  <SecondaryButton
    color={SecondaryButton.colors.PRIMARY}
    size={SecondaryButton.sizes.EXTRA_SMALL}
    {...props}
  />
);

const ConnectedDeactivateModalContent = connect(null, {
  deactivateAccount: deactivateAccountAction,
})(DeactivateModalContent);

const mapStateToProps = (state) => ({
  ...userSelector(state),
  ...businessDetailsSelector(state),
});

@connect(mapStateToProps, {
  saveEmailSubscriptions: saveEmailSubscriptionsAction,
  fetchBusinessDetails: fetchBusinessDetailsAction,
  showModal: showModalAction,
})
export default class Settings extends Component {
  static propTypes = {
    user: userPropType,
    account: PropTypes.object,
    deactivateAccount: PropTypes.func,
    saveEmailSubscriptions: PropTypes.func,
    fetchBusinessDetails: PropTypes.func,
    businessDetails: businessDetailsPropType,
    showModal: PropTypes.func,
  };

  state = {
    basic: false,
  };

  componentDidMount() {
    const { user, fetchBusinessDetails } = this.props;
    this._initState(user);
    if (user.isBusinessAccount) {
      fetchBusinessDetails();
    }
  }

  _initState(user) {
    this.setState({
      basic: hasBasicEmailSubscription(user),
    });
  }

  _onEmailBasicChange(checked) {
    this.setState({
      basic: checked,
    });
  }

  async _onFormEmailSubscriptionsSubmit(e) {
    e.preventDefault();
    const { saveEmailSubscriptions } = this.props;
    const { basic } = this.state;
    await saveEmailSubscriptions({ basic });
  }

  render() {
    const { basic } = this.state;
    const { user, account, businessDetails, className, showModal } = this.props;

    if (!account || !user || (user.isBusinessAccount && !businessDetails)) {
      return null;
    }

    const isAccountDeactivable = account.isDeactivable;
    const isAccountActive = user.accountActive;

    return (
      <SettingsContainer className={className}>
        <Tfn backgroundColor="white" />
        {!hasNominatedBankAccount(user) && (
          <Spacing top="x-large">
            <BankDetailsForm />
          </Spacing>
        )}
        <Section title="My Details">
          {!hasSetupPersonalDetails(user) && (
            <InfoBox>
              <div>
                To enter your details,{' '}
                <Hyperlink href="/account/deposit">click here</Hyperlink>.
              </div>
            </InfoBox>
          )}
          <MyDetails user={user} businessDetails={businessDetails} />
          <Spacing top="normal">
            {/* <PrimaryButton link="/update-details">
              Update my details
            </PrimaryButton> */}
            <SectionActionButton link="/account/update-details">
              Update my details
            </SectionActionButton>
          </Spacing>
        </Section>
        <Section title="Newsletters">
          <form
            role="form"
            method="post"
            onSubmit={this._onFormEmailSubscriptionsSubmit.bind(this)}
          >
            <FormCheckbox
              onChange={(value) => this.setState({ basic: value })}
              checked={basic}
            >
              Include me in BrickX Marketing Emails
            </FormCheckbox>
            <Spacing top="x-small">
              <FormCheckbox disabled checked>
                Receive BrickX Financial documents
                <br />
                <FinePrint>
                  NOTE: BrickX is required to send certain financial documents
                  related to your investment, including transaction certificates
                  and tax information.
                </FinePrint>
              </FormCheckbox>
            </Spacing>
            <Spacing top="normal">
              <SectionActionButton type="submit">Update</SectionActionButton>
            </Spacing>
          </form>
        </Section>
        <Section title="Password">
          <SectionActionButton
            link={changePasswordUrl()}
            data-test-reference="settings__change-password-button"
          >
            Change Password
          </SectionActionButton>
        </Section>
        {isAccountActive ? (
          <Section title="Deactivate My Account">
            <InfoBox>
              {isAccountDeactivable ? (
                <div>
                  <strong>Did you know?</strong> Once you have deactivated your
                  BrickX account, you are unable to buy and sell Bricks. You can
                  access the BrickX website to obtain financial information on
                  your prior investments, including tax documents.
                </div>
              ) : (
                <div>
                  You are currently unable to deactivate your Account. To do so,
                  ensure Smart Invest is disabled, sell all your Bricks, and
                  withdraw your funds from the BrickX Wallet.
                </div>
              )}
            </InfoBox>
            <Spacing top="normal">
              <SectionActionButton
                type="submit"
                disabled={!isAccountDeactivable}
                onClick={() =>
                  showModal({ content: <ConnectedDeactivateModalContent /> })
                }
              >
                Deactivate My Account
              </SectionActionButton>
            </Spacing>
          </Section>
        ) : (
          <InfoBox critical>
            You account has been deactivated. You can still access the BrickX
            website to obtain financial information on your prior investments,
            including tax documents.
          </InfoBox>
        )}
      </SettingsContainer>
    );
  }
}
