const lightenDarkenHex = (color, percent) => {
  const num = parseInt(color, 16);
  const amount = Math.round(2.55 * percent);
  const R 	= (num >> 16) + amount;
  const B 	= (num >> 8 & 0x00FF) + amount;
  const G 	= (num & 0x0000FF) + amount;

  return (0x1000000
    + (R < 255 ? R < 1 ? 0 : R : 255) * 0x10000
    + (B < 255 ? B < 1 ? 0 : B : 255) * 0x100
    + (G < 255 ? G < 1 ? 0 : G : 255)
  ).toString(16).slice(1);
};

const lightenDarken = (color, percent) => `#${lightenDarkenHex(color.slice(1), percent)}`;

export const lighten = (color, percent) => lightenDarken(color, percent);
export const darken = (color, percent) => lightenDarken(color, -percent);
