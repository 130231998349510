export default {
  LOGIN: 'Login',
  REFRESH_SESSION: 'Refresh Session',
  LOGOUT: 'Logout',
  SESSION_TIME_OUT: 'Session Time Out',
  CLICK: 'Click a Link',
  LOAD_PAGE: 'Loaded a Page',
  FEEDBACK: {
    LIKE: 'Liked a feature',
    DISLIKE: 'Disliked a feature'
  },
  REGISTRATION: {
    SIGNUP_LINK: 'Sign Up Link',
    SIGNUP: 'Complete Signup',
    RESEND_EMAIL: 'Resend Verify Email',
    PDS: 'Complete PDS Check',
    VERIFY: 'Email Verified',
    SUBMIT_PERSONAL_DETAILS: 'Submit Personal Details',
    SETUP: 'Complete Personal Details',
    VEDA_CHECK: 'VEDA Check',
    VEDA_MANUAL_LICENSE_SUBMISSION: 'VEDA Manual License Submission',
  },
  PARTNER_REFERRAL: {
    VIEW: 'View Partner Referral Popup',
    CLICK: 'Click on Partner Referral Popup'
  },
  TRADE: {
    BUY: 'Buy',
    PRE_ORDER: 'Pre-Order',
    OPEN_TRADE_PANEL: 'Open Trade Panel',
    TRADE_PANEL_ENGAGEMENT: {
      TEXT: 'Trade Panel Engagement',
      PLACE_ORDER_BUTTON_CLICK: 'Place Order Button Click',
      RECENT_TRANSACTIONS_TAB_CLICK: 'Recent Transactions Tab Click',
      SEE_MORE_TRANSACTIONS_TAB_CLICK: 'See More Transactions Click',
      SEE_LESS_TRANSACTIONS_TAB_CLICK: 'See Less Transactions Click',
      BRICKS_AVAILABLE_TAB_CLICK: 'Order Book Tab Click',
      SEE_MORE_AVAILABLE_BRICKS_TAB_CLICK: 'See More Available Bricks Click',
      SEE_LESS_AVAILABLE_BRICKS_TAB_CLICK: 'See Less Available Bricks Click',
    },
    TRADE_PANEL_SUBMIT: 'Trade Panel Submit',
    PREMIUM_PRICE_ALERTS_ENGAGEMENT: {
      PROCEED: 'Proceed',
      CANCELLED: 'Cancelled',
      ALERT_1: 'Above Valuation Alert 1 Engagement',
      ALERT_2: 'Above Valuation Alert 2 Engagement',
    },
  },
  BUY: {
    BUY_SUCCEED: 'Buy Bricks Success - FE',
    FIRST_BRICK_PURCHASED: 'First Brick Purchased - FE'
  },
  PRE_ORDER: {
    PRE_ORDER_PLACED: 'Pre Order Bricks Success - FE',
    PRE_ORDER_CANCELLED: 'Pre Order Cancelled - FE',
  },
  SELL: {
    SELL: 'Sell',
    OPEN_SELL_PANEL: 'Open Sell Panel',
    SELL_PANEL_ENGAGEMENT: 'Sell Panel Engagement',
    SELL_PANEL_SUBMIT: 'Sell Panel Submit',
    SELL_PLACED: 'Sell Order Placed Success - FE',
    SELL_ORDER_CANCELLED: 'Sell Order Cancelled - FE',
  },
  DEPOSIT: {
    PAYMENT_METHOD: {
      POLI: 'POLi',
      BPAY: 'BPay'
    },
    CONTINUE_DEPOSIT: 'Continue Deposit',
    DEPOSIT_FAILED: 'Deposit Failed',
    DEPOSIT_SUCCEED: 'Deposit Succeed',
    DEPOSIT_SUCCEED_POLI: 'POLi Completed - FE'
  },
  REFERRAL_PARTNERSHIP_PROGRAMME: {
    LINK_COPIED: 'Referral Link Copied',
    FACEBOOK_BUTTON_CLICKED: 'Facebook Button Clicked',
    TWITTER_BUTTON_CLICKED: 'Twitter Button Clicked',
    LINKEDIN_BUTTON_CLICKED: 'LinkedIn Button Clicked',
  },
  FAQ: {
    OPEN_FAQ: 'Open FAQ'
  },
  LoginOrSignupPopup: {
    LoginPopup: 'Login Popup',
    SignupPopup: 'Signup Popup',
    Action: {
      SwitchedToSignup: 'Switched to Signup',
      SwitchedToLogin: 'Switched to Login',
      Submit: 'Submit',
      Cancel: 'Cancel',
    },
    PopupType: {
      SecondsDelay: '150 Seconds',
      LockedContent: 'Locked Content',
    }
  },
  CALCULATOR: {
    PROPERTY_CALCULATOR_ENGAGEMENT: 'Calculator per Property Engagement - FE',
    PROPERTY_CALCULATOR_CLICK_ON_BREAKDOWN_ENGAGEMENT: 'Calculator per Property Click on Breakdown - FE'
  },
  HISTORICAL_SUBURB_GROWTH: {
    GRAPH_ENGAGEMENT: 'Historical Suburb Graph Engagement - FE'
  },
};
