import request from 'scripts/services/request';
import { historicalBrickValues, financialMetrics } from 'scripts/services/api';
import { withCredentials } from 'scripts/services/helpers';

class FinancialsService {
  getHistoricalBrickValues(propertyCode) {
    return request(historicalBrickValues(propertyCode), {
      ...withCredentials
    });
  }

  getFinancialMetrics() {
    return request(financialMetrics(), { ...withCredentials });
  }
}

export default new FinancialsService();
