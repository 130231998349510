import { accountUrl, settingsUrl } from 'src/utils/pageUrls';
import {
  fetchAccountInfoV2,
  fetchAccountOverTime,
} from 'scripts/redux/actions/account';
import { fetchUserDetails } from 'scripts/redux/actions/user';
import { openUrlInNewWindow } from 'scripts/utilities/browser';
import { save } from 'src/browser/cookies';
import { sendConversionEvent } from 'src/tracking/onboarding';
import {
  smartInvestDetailsUrl,
  disableSmartInvestUrl,
  getStoredDirectDebitRequestUrl as getStoredDirectDebitRequestUrlUrl,
  smartInvestSkipAMonthUrl,
  smartInvestTopUpUrl,
  smartInvestWithdrawFromSiUrl,
} from 'scripts/services/api';
import { successAlert } from 'scripts/redux/actions/alerts';
import { userSelector } from 'scripts/redux/selectors/user';
import RegistrationStep from 'scripts/constants/RegistrationStep';
import SmartInvestService from 'scripts/services/SmartInvestService';
import getWithAuth from 'scripts/services/request/getWithAuth';
import history from 'src/browser/history';
import postWithAuth from 'scripts/services/request/postWithAuth';

const AUTO_INVEST_DETAILS_FETCH_REQUESTED =
  'AUTO_INVEST_DETAILS_FETCH_REQUESTED';
const AUTO_INVEST_DETAILS_FETCH_COMPLETED =
  'AUTO_INVEST_DETAILS_FETCH_COMPLETED';
const AUTO_INVEST_DETAILS_FETCH_FAILED = 'AUTO_INVEST_DETAILS_FETCH_FAILED';
const AUTO_INVEST_DETAILS_SAVE_REQUESTED = 'AUTO_INVEST_DETAILS_SAVE_REQUESTED';
const AUTO_INVEST_DETAILS_SAVE_COMPLETED = 'AUTO_INVEST_DETAILS_SAVE_COMPLETED';
const TOP_UP_TRANSFERING = 'TOP_UP_TRANSFERING';
const TOP_UP_COMPLETE = 'TOP_UP_COMPLETE';
const WITHDRAW_FROM_SI_PROCESSING = 'WITHDRAW_FROM_SI_PROCESSING';
const WITHDRAW_FROM_SI_COMPLETE = 'WITHDRAW_FROM_SI_COMPLETE';

export const fetchSmartInvestDetails = () => async (dispatch, getState) => {
  const { user } = userSelector(getState());
  dispatch({ type: AUTO_INVEST_DETAILS_FETCH_REQUESTED });
  return getWithAuth(smartInvestDetailsUrl(user.id))
    .then((payload) =>
      dispatch({
        type: AUTO_INVEST_DETAILS_FETCH_COMPLETED,
        payload,
      })
    )
    .catch(() => dispatch({ type: AUTO_INVEST_DETAILS_FETCH_FAILED }));
};

export function setSmartInvestDetails({
  amount,
  dayOfMonth,
  bankAccountName,
  bsb,
  bankAccountNumber,
}) {
  return async (dispatch, getState) => {
    const { user } = userSelector(getState());
    const payload = {
      amount,
      dayOfMonth,
      bankAccountName,
      bsb,
      bankAccountNumber,
    };
    dispatch({ type: AUTO_INVEST_DETAILS_SAVE_REQUESTED });
    return SmartInvestService.addSmartInvestDetails(user.id, payload)
      .then(() => dispatch(fetchUserDetails(user.id)))
      .then(() => dispatch({ type: AUTO_INVEST_DETAILS_SAVE_COMPLETED }));
  };
}

export function addSmartInvestDetails(smartInvestDetails) {
  return async function (dispatch, getState) {
    await dispatch(setSmartInvestDetails(smartInvestDetails));
    save('dashboard-not-yet-seen-after-completing-onboarding', true);
    // if the user hasnt yet purchased any bricks, send a conversion tracking event!
    const { user } = getState();
    if (user.nextRegistrationStep !== RegistrationStep.DONE) {
      sendConversionEvent('SI Signup');
    }
    history.push(accountUrl());
  };
}

export const updateSmartInvestDetails = setSmartInvestDetails;

export const updateSmartInvestAndGoBackToSettings =
  (values) => async (dispatch) =>
    dispatch(setSmartInvestDetails(values)).then(() =>
      history.push(settingsUrl())
    );

export const showDirectDebitRequestServiceAgreement = () => {
  return (dispatch, getState) => {
    const { user } = userSelector(getState());
    SmartInvestService.getDirectDebitRequestServiceAgreementPreviewUrl(
      user.id
    ).then((result) => openUrlInNewWindow(result.url));
  };
};

export const showDirectDebitRequest = (values) => {
  return (dispatch, getState) => {
    const { user } = userSelector(getState());
    SmartInvestService.getDirectDebitRequestPreviewUrl(user.id, values).then(
      (result) => openUrlInNewWindow(result.url)
    );
  };
};

export const getStoredDirectDebitRequestUrl = () => (dispatch, getState) => {
  const { user } = userSelector(getState());
  return getWithAuth(getStoredDirectDebitRequestUrlUrl(user.id));
};

const navigateToSmartInvestSettings = () => history.push(settingsUrl('1'));

export const disableSmartInvest = () => async (dispatch, getState) => {
  const { user } = userSelector(getState());

  return postWithAuth(disableSmartInvestUrl(user.id))
    .then(() => dispatch(fetchAccountInfoV2()))
    .then(navigateToSmartInvestSettings);
};

export const skipAMonthThenGoBackToSettings = () => (dispatch, getState) => {
  const { user } = userSelector(getState());

  return postWithAuth(smartInvestSkipAMonthUrl(user.id)).then(
    navigateToSmartInvestSettings
  );
};

export const topUp = (amount) => (dispatch, getState) => {
  const { user } = userSelector(getState());
  dispatch({ type: TOP_UP_TRANSFERING });

  return postWithAuth(smartInvestTopUpUrl(user.id), { amount }).then(() => {
    dispatch(
      successAlert(
        'The transfer to your Smart Invest was successful, and has been applied to your Funds Awaiting Investment.'
      )
    );
    return Promise.all([
      dispatch(fetchAccountInfoV2()),
      dispatch(fetchAccountOverTime()),
    ])
      .then(() => dispatch({ type: TOP_UP_COMPLETE }))
      .catch(() => dispatch({ type: TOP_UP_COMPLETE }));
  });
};

/**
 *
 * Action: transfer all amount in SI wallet to BMO wallet
 * ONLY IF
 * - The member's SI has been cancelled
 * - There's funds in the SI wallet (due to distribution payment made to SI)
 *
 */
export const withdrawFromSi = () => async (dispatch, getState) => {
  const { user } = userSelector(getState());
  dispatch({ type: WITHDRAW_FROM_SI_PROCESSING });
  await postWithAuth(smartInvestWithdrawFromSiUrl(user.id));
  await Promise.all([
    dispatch(fetchAccountInfoV2()),
    dispatch(fetchAccountOverTime()),
  ]);
  dispatch({ type: WITHDRAW_FROM_SI_COMPLETE });
  dispatch(successAlert('All amount has been transferred to your BMO wallet'));
};

export const smartInvestSelector = (state) => ({
  smartInvest: state.smartInvest,
});

const defaultState = { fetching: false, saving: false, transferring: false };

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case AUTO_INVEST_DETAILS_FETCH_REQUESTED:
      return { ...state, fetching: true };
    case AUTO_INVEST_DETAILS_FETCH_COMPLETED:
      return { ...state, ...payload, fetching: false };
    case AUTO_INVEST_DETAILS_FETCH_FAILED:
      return { ...state, fetching: false };
    case AUTO_INVEST_DETAILS_SAVE_REQUESTED:
      return { ...state, saving: true };
    case AUTO_INVEST_DETAILS_SAVE_COMPLETED:
      return { ...state, saving: false };
    case TOP_UP_TRANSFERING:
      return { ...state, transferring: true };
    case TOP_UP_COMPLETE:
      return { ...state, transferring: false };
    default:
      return state;
  }
};
