import { connect } from 'react-redux';
import styled from 'styled-components';
import { bankDetailsScreenshotUrl } from 'scripts/services/api';
import { contactUrl } from 'src/utils/pageUrls';
import { getThemeColor } from 'src/themes/themeUtils';
import { gridUnit } from 'src/design/styleguide/common/measurements';
import {
  slowDuration,
  standardFunction,
} from 'src/design/styleguide/common/animations';
import { userSelector } from 'scripts/redux/actions/user';
import FileUpload from 'src/design/components/fileUpload/FileUpload';
import Hyperlink from 'src/design/components/hyperlink/Hyperlink';
import Paragraph from 'src/design/styleguide/Paragraph';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import withState from 'src/decorators/withState';

const ScreenshotUploadContainer = styled.div`
  padding: ${gridUnit * 10}px;
  border: 1px solid
    ${(props) =>
      getThemeColor(props.fileSelected ? 'inputBorderGood' : 'inputBorder')(
        props
      )};
  transition: border-color ${slowDuration} ${standardFunction};
`;

const BankDetailsScreenshotUpload = connect(userSelector)(
  withState({
    mapPropsToInitialState: () => ({ fileSelected: false }),
    Component: ({
      className,
      testRef = 'file-input',
      onChange = () => {},
      user,
      setState,
      state: { fileSelected },
      ...props
    }) => (
      <ScreenshotUploadContainer
        className={className}
        fileSelected={fileSelected}
        data-test-reference="screenshot-upload-container"
      >
        <Paragraph>
          To verify that you have access to this bank account, please upload a
          screenshot or photo of a bank statement showing your{' '}
          <strong>Name</strong>, <strong>BSB</strong> and{' '}
          <strong>Account Number</strong>.
        </Paragraph>
        <Spacing top="x-large">
          <FileUpload
            ctaText="Select File"
            realTestRef={testRef}
            endpoint={bankDetailsScreenshotUrl(user.id)}
            formDataFieldName="bank_details"
            onChange={(file) => {
              setState({ fileSelected: !!file });
              onChange(file);
            }}
            accept=".png, .gif, .jpeg, .jpg, .pdf"
            {...props}
          />
        </Spacing>
        <Spacing top="x-large">
          <Paragraph>
            If you are having difficulty, please{' '}
            <Hyperlink href={contactUrl()} target="_blank">
              contact us
            </Hyperlink>
            .
          </Paragraph>
        </Spacing>
      </ScreenshotUploadContainer>
    ),
  })
);

export default BankDetailsScreenshotUpload;
