import DocumentMeta from 'react-document-meta';
import { SETTINGS_META } from 'scripts/metadata/account';
import PageHeading from 'src/design/components/heading/PageHeading';
import PageContentBlock from 'src/design/components/pageContentBlock/PageContentBlock';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import StyledBodylink from 'src/design/components/hyperlink/StyledBodylink';
import SmartInvestSettingsForm from 'src/components/settings/SmartInvestSettingsForm';
import Divider from 'src/design/components/divider/Divider';
import {
  settingsUrl,
} from 'src/utils/pageUrls';

import styles from 'src/components/settings/Settings.mscss';


const ManageSmartInvest = () => (
  <div
    className={styles.settings}
    data-test-reference="manage-smart-invest-page"
  >
    <DocumentMeta {...SETTINGS_META} />
    <PageHeading>Manage Smart Invest</PageHeading>

    <PageContentBlock>
      <SmartInvestSettingsForm submitButtonText="Save"/>

      <Divider />

      <Spacing bottom="2x-large" top="2x-large">
        <StyledBodylink
          href={settingsUrl()}
          fullWidth
          grey
          testRef="back-to-settings-link"
        >Back to Settings</StyledBodylink>
      </Spacing>
    </PageContentBlock>
  </div>
);

export default ManageSmartInvest;
