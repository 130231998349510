
import classNames from 'classnames';

import Popover from 'scripts/components/shared/Popover';


const GeneralInfoBox = ({ image, title, value, description, cssClasses }) => {
  return (
    <div className={classNames('general-info-item', cssClasses)}>
      <div className="general-info-icon text-left">
        {image}
      </div>
      <div className="general-info-details">
        <div className="general-info-title">
          {title}
                    {description &&
            <span className="general-info-descriptions"><Popover placement="bottom" content={description}/></span>
                    }
        </div>
        <div className="general-info-value">
          <span className="value blue-text">{value}</span>
        </div>
      </div>
    </div>
  );
};

export default GeneralInfoBox;
