import classNames from 'classnames';

import styles from 'src/components/landingPages/common/listItemNumber/ListItemNumber.mscss';


const ListItemNumber = ({ className, color, size }) => (
  <i className={classNames(
    styles.listItemNumber,
    styles[`listItemNumber--${color}`],
    styles[`listItemNumber--size-${size}`],
    className
  )}/>
);

ListItemNumber.counterResetClass = styles.listItemNumber__counterReset;

ListItemNumber.colors = {
  YOLK: 'yolk',
  SALMON: 'salmon',
  CHARCOAL: 'charcoal',
  BLUE: 'blue'
};

export default ListItemNumber;
