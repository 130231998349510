const PropertyTypes = {
  house: 'house',
  unit: 'unit',
  offices: 'offices',
  multiunit: 'multiunit',
  multihouse: 'multihouse',
  development: 'development',
  farm: 'farm',
  commercial: 'commercial',
};

export default PropertyTypes;
