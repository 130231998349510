import classNames from 'classnames';

import TextInput from 'src/design/components/textInput/TextInput';
import FormLabel from 'src/design/components/formLabel/FormLabel';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import BankNameProvider from 'src/decorators/BankNameProvider';
import {
  BankName,
  isBankNameValidCharacters
} from 'src/components/oldOnboarding/smartInvestDetails/bankDetailsInput/BankDetailsInput';

import styles from 'src/components/settings/Settings.mscss';


const BankAccountDetailsInput = ({
  bankAccountName,
  bankAccountBsb,
  bankAccountNumber,
  onBankAccountNameChange,
  onBankAccountBsbChange,
  onBankAccountNumberChange,
  onBankNameChange,
  className,
}) => {
  return (
    <div className={classNames(styles.bankDetailsInputArea, className)}>
      <FormLabel htmlFor="bank-account-name-input">Account name</FormLabel>
      <TextInput
        onChange={onBankAccountNameChange}
        value={bankAccountName}
        isCharacterValid={isBankNameValidCharacters}
        isStringValid={TextInput.validation.maxLength(32)}
        placeholder="John Smith"
        htmlFor="bank-account-name-input"
        testRef="bank-account-name-input"
      />

      <Spacing top="large">
        <FormLabel htmlFor="bank-account-name-input">BSB</FormLabel>
      </Spacing>
      <TextInput
        onChange={onBankAccountBsbChange}
        value={bankAccountBsb}
        isCharacterValid={TextInput.validation.numbersOnly}
        isStringValid={TextInput.validation.maxLength(6)}
        placeholder="XXXXXX (6 digits)"
        htmlFor="bank-account-bsb-input"
        testRef="bank-account-bsb-input"
      />
      <BankNameProvider bsb={bankAccountBsb} onChange={onBankNameChange}>
        <BankName/>
      </BankNameProvider>

      <Spacing top="large">
        <FormLabel htmlFor="bank-account-number-input">Account number</FormLabel>
      </Spacing>
      <TextInput
        onChange={onBankAccountNumberChange}
        value={bankAccountNumber}
        isCharacterValid={TextInput.validation.numbersOnly}
        isStringValid={TextInput.validation.maxLength(9)}
        placeholder="XXXXXX (6 to 9 digits)"
        htmlFor="bank-account-number-input"
        testRef="bank-account-number-input"
      />
    </div>
  );
};

export default BankAccountDetailsInput;
