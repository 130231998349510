import request from 'scripts/services/request';
import { withCredentials } from 'scripts/services/helpers';
import { facebookPageInfo } from 'scripts/services/api';


class FacebookService {
  getFacebookPageInfo() {
    return request(facebookPageInfo(), { ...withCredentials });
  }
}


export default new FacebookService();
