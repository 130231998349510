import update from 'immutability-helper';
import { createAction, handleActions } from 'redux-actions';
import UserService from 'scripts/services/UserService';

const BAFM = 'scripts/user/BAFM';

const bafm = createAction(BAFM);


export function fetchBafm() {
  return async function (dispatch, getState) {
    const { user } = getState();
    if (user.loggedIn) {
      const payload = await UserService.getBafm(user.id);
      return dispatch(bafm(payload));
    }
  };
}

const reducer = handleActions({
  [BAFM]: (state, action) => update(state, {
    $set: action.payload
  }),
}, null);

export default reducer;
