import _cloneDeep from 'lodash/cloneDeep';
import _last from 'lodash/last';
import { SMART_INVEST, BUILD_YOUR_OWN } from 'src/enums/planTypes';
import { property as propertyPropType } from 'scripts/constants/PropTypes';
import { todaysDateInSydney } from 'src/utils/time';
import Highchart from 'src/third-party/highchart/Highchart';
import Paragraph from 'src/design/styleguide/Paragraph';
import addAllDaysInBetweenDataPoints from 'src/components/portfolioWidget/portfolioOverTimeChart/utils/addAllDaysInBetweenDataPoints';

export const colors = {
  salmon: {
    solid: '#fa8c8c',
    gradient: [
      [0, 'rgba(252,126,15,0.2)'],
      [1, 'rgba(252,126,15,0)']
    ],
  },
  yolk: {
    solid: '#fac800',
    gradient: [
      [0, 'rgba(139,190,234,0.2)'],
      [1, 'rgba(139,190,234,0)']
    ],
  },
};

const chartLineConfig = (color) => ({
  showInLegend: false,
  color: color.solid,
  lineWidth: 1,
  marker: {
    symbol: 'circle'
  },
});

const getPlanDisplayName = (planType) => ({
  [SMART_INVEST]: 'Smart Invest',
  [BUILD_YOUR_OWN]: 'Build My Own',
}[planType]);

const getPlanColor = (planType) => ({
  [SMART_INVEST]: colors.salmon,
  [BUILD_YOUR_OWN]: colors.yolk,
}[planType]);

const withTimestamps = (list) => list.map(({ value, date }) => ({ value, date: new Date(date).valueOf() }));

const makeTheLatestDayConsistent = (plans) => {
  plans.map((plan) => {
    const latestPoint = _last(plan.accountValueAcrossTime);
    const todaysDate = todaysDateInSydney();
    if (latestPoint && latestPoint.date !== todaysDate) {
      plan.accountValueAcrossTime.push({ value: latestPoint.value, date: todaysDateInSydney() });
    }
  });
  return plans;
};

const PortfolioNoHistoryPlaceholder = () => (
  <div data-test-reference="portfolio-no-history-placeholder">
    <img
      className="portfolio-widget__no-bricks-placeholder"
      src="/static/img/illustration-colored-computer-screen-with-charts.svg"
      alt="An illustration of a computer screen, featuring various charting graphics"
    />
    <Paragraph>After your first deposit, we’ll show your total portfolio value here.</Paragraph>
  </div>
);

const isDataAvailable = (accountOverTime) => accountOverTime.some(({ accountValueAcrossTime }) => accountValueAcrossTime.length);

const PortfolioOverTimeChart = ({ className, data, legendDisabled }) => {
  if (!isDataAvailable(data)) return <PortfolioNoHistoryPlaceholder />;

  const equalizedLastDays = makeTheLatestDayConsistent(_cloneDeep(data));
  const series = equalizedLastDays.map((plan) => {
    const planType = plan.planSubscription.planType;
    return {
      ...chartLineConfig(getPlanColor(planType)),
      showInLegend: true,
      name: getPlanDisplayName(planType),
      data: addAllDaysInBetweenDataPoints(withTimestamps(plan.accountValueAcrossTime)).map(({ value, date }) => [date, value])
    };
  });

  const moreThanOnePlan = series.length > 1;

  const legendEnabled = moreThanOnePlan && !legendDisabled;

  return (
    <div className={className} data-test-reference="portfolio-chart">
      <Highchart
        data-test-reference="historical-portfolio-chart"
        config={{
          chart: {
            type: 'areaspline',
            backgroundColor: '#FFFFFF'
          },
          plotOptions: {
            series: {
              stacking: 'normal',
            },
          },
          xAxis: {
            type: 'datetime',
          },
          yAxis: {
            title: false,
            labels: { format: '${value:,.0f}' },
          },
          title: false,
          credits: {
            enabled: false
          },
          loading: false,
          legend: {
            enabled: legendEnabled,
            verticalAlign: 'top',
            align: 'right',
          },
          tooltip: {
            split: true,
            valuePrefix: '$',
            valueDecimals: 2,
          },
          series: series,
        }}
      />
    </div>
  );
};

PortfolioOverTimeChart.propTypes = {
  property: propertyPropType,
};

export default PortfolioOverTimeChart;
