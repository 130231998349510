import { Fragment } from 'react';
import classNames from 'classnames';
import withState from 'src/decorators/withState';
import PageMarginContainer from 'src/design/components/pageMarginContainer/PageMarginContainer';
import { Heading } from 'src/design/components/heading/Headings';
import BodyText from 'src/design/components/bodyText/BodyText';
import Paragraph from 'src/design/components/bodyText/Paragraph';
import Hyperlink from 'src/design/components/hyperlink/Hyperlink';
import Icon from 'src/design/components/icon/Icon';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import Tfn from 'src/components/settings/tfn/Tfn';
import { propertiesUrl, propertyUrl } from 'src/utils/pageUrls';
import { MOBILE_LARGE } from 'scripts/constants/WindowSize';
import styles from 'src/components/trades/TradeSuccess.mscss';
import { renderCode } from 'src/settings/properties';

const removeModalNonsenseToDealWithCrappyMobileBuyModal = () => {
  if (window.innerWidth <= MOBILE_LARGE) {
    const body = document.querySelector('body');
    const modalBackdrop = document.querySelector('.modal-backdrop');
    if (body) {
      body.classList.remove('modal-open');
    }
    if (modalBackdrop) {
      modalBackdrop.parentNode.removeChild(modalBackdrop);
    }
  }
};

const BreadCrumb = ({ children, href }) => (
  <BodyText textSize="small">
    <Hyperlink href={href}>{children}</Hyperlink>
  </BodyText>
);

const TradeSuccess = withState({
  componentDidMount: () => removeModalNonsenseToDealWithCrappyMobileBuyModal(),
  Component: ({ propertyCode, greeting }) => (
    <Fragment>
      <PageMarginContainer testRef="trade-success">
        <div className={styles.breadcrumbs}>
          <BreadCrumb href={propertyUrl(propertyCode)}>
            <Icon
              className={classNames(
                styles['breadcrumbs--chevrons'],
                styles['breadcrumbs--chevron-left']
              )}
              type="chevron_left"
            />
            Back to {renderCode(propertyCode)}
          </BreadCrumb>

          <BreadCrumb href={propertiesUrl()}>
            All properties
            <Icon
              className={classNames(
                styles['breadcrumbs--chevrons'],
                styles['breadcrumbs--chevron-right']
              )}
              type="chevron_right"
            />
          </BreadCrumb>
        </div>

        <img
          className={styles.streamersIcon}
          src="/static/img/referrals-icon-party-streamers.svg"
          alt="party-streamers-icon"
        />

        <Heading>
          <Spacing top="x-small">
            <h1 className={styles.heading}>{greeting}</h1>
          </Spacing>
        </Heading>

        <Spacing top="large">
          <Paragraph align="center">
            A confirmation email has been sent.
          </Paragraph>
        </Spacing>
      </PageMarginContainer>
      <Tfn
        innerContainerClassName={styles.tfnInnerContainer}
        title={
          <span>
            Please provide your
            <br />
            Tax File Number (TFN)
          </span>
        }
      />
    </Fragment>
  ),
});

export default TradeSuccess;
