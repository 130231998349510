import { Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { SubHeading } from 'src/design/styleguide/Headings';
import {
  WelcomePageWidgetPanel,
  SeeMoreLink,
} from 'src/components/dashboard/home/commonComponents';
import { breakpointSmall } from 'src/design/styleguide/common/breakpoints';
import { gridUnit } from 'src/design/styleguide/common/measurements';
import {
  mediumFontWeight,
  regularFontWeight,
} from 'src/design/styleguide/common/fonts';
import { settingsUrl } from 'src/utils/pageUrls';
import { userSelector } from 'scripts/redux/actions/user';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import Text from 'src/design/styleguide/text/Text';
import { hasMinorDetails } from 'scripts/utilities/userAccountHelper';

const SectionHeading = styled(SubHeading).attrs({
  as: 'h3',
  fontSize: '2x-small',
})`
  ${breakpointSmall(`
    text-align: left;
  `)}
`;

const SettingsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: ${gridUnit * 5}px;
  grid-row-gap: ${gridUnit}px;
`;

const SettingName = styled(Text).attrs({
  fontSize: '4x-small',
})`
  text-align: right;

  ${breakpointSmall(`
    text-align: left;
  `)}
`;

const SettingValue = styled(Text).attrs({
  fontSize: '4x-small',
})`
  font-weight: ${mediumFontWeight};
  word-wrap: break-word;

  ${breakpointSmall(`
    font-weight: ${regularFontWeight};
  `)}
`;

const AccountSettingsPanel = connect(userSelector)(({ className, user }) => {
  const showMinorDetails = hasMinorDetails(user);
  return (
    <WelcomePageWidgetPanel
      className={className}
      title="Account Settings"
      action={<SeeMoreLink href={settingsUrl()} />}
      testRef="account-settings-panel"
    >
      <SectionHeading>My Details</SectionHeading>
      <Spacing top="large">
        <SettingsContainer>
          {showMinorDetails && (
            <Fragment>
              <SettingName>
                <strong>Minor's Details:</strong>
              </SettingName>
              <SettingValue />

              <SettingName>Trust Name</SettingName>
              <SettingValue>{user.minorDetails.trustName}</SettingValue>

              <SettingName>Minor's Full Name</SettingName>
              <SettingValue>
                {[
                  user.minorDetails.firstName,
                  user.minorDetails.middleName,
                  user.minorDetails.lastName,
                ]
                  .filter((ele) => ele)
                  .join(' ')}
              </SettingValue>

              <SettingName>
                <strong>Adult's Details:</strong>
              </SettingName>
              <SettingValue />
            </Fragment>
          )}

          <SettingName>First Name</SettingName>
          <SettingValue testRef="account-settings-panel-first-name">
            {user.givenName}
          </SettingValue>

          <SettingName>Email</SettingName>
          <SettingValue testRef="account-settings-panel-email">
            {user.email}
          </SettingValue>

          <SettingName>Mobile</SettingName>
          <SettingValue testRef="account-settings-panel-mobile">
            {user.mobilePhone || 'Not Provided'}
          </SettingValue>
        </SettingsContainer>
      </Spacing>
    </WelcomePageWidgetPanel>
  );
});

export default AccountSettingsPanel;
