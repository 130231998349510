import { createAction, handleActions } from 'redux-actions';
import update from 'immutability-helper';

const BREADCRUMBS_HEIGHT = 'scripts/ui/BREADCRUMBS_HEIGHT';
const SUBHEADER_POSITION = 'scripts/ui/SUBHEADER_POSITION';
const SUBHEADER_OFFSET = 'scripts/ui/SUBHEADER_OFFSET';

const breadcrumbsHeight = createAction(BREADCRUMBS_HEIGHT);
const subheaderPosition = createAction(SUBHEADER_POSITION);
const subheaderOffset = createAction(SUBHEADER_OFFSET);

export const setBreadcrumbsHeight = (height) => breadcrumbsHeight(height);

export const setSubheaderPosition = (top) => subheaderPosition(top);

export const setScrollPosition = (y) => {
  window.scrollTo(0, y);
  return subheaderOffset(y);
};

const reducer = handleActions({
  [BREADCRUMBS_HEIGHT]: (state, action) => update(state, {
    breadcrumbsHeight: {
      $set: action.payload
    },
    subheaderOffset: {
      $set: state.subheaderPosition - action.payload
    }
  }),
  [SUBHEADER_POSITION]: (state, action) => update(state, {
    subheaderPosition: {
      $set: action.payload
    },
    subheaderOffset: {
      $set: action.payload - state.breadcrumbsHeight
    }
  }),
}, {
  breadcrumbsHeight: 0,
  subheaderPosition: 0,
  subheaderOffset: 0,
});

export default reducer;
