export default {
  SIGNUP: 'signup',
  VERIFY: 'verify',
  SMSF: 'smsf',
  PDS: 'pds',
  SETUP: 'setup',
  VEDA: 'veda',
  DEPOSIT: 'deposit',
  INVEST: 'invest',
  DONE: 'done',
};
