import { delay } from 'src/utils/time';

export const SHOW_TOAST = 'SHOW_TOAST';
export const PRE_DISMISS_TOAST = 'PRE_DISMISS_TOAST';
export const DISMISS_TOAST = 'DISMISS_TOAST';

const animatedDismissToastStart = ({ id }) => ({ type: PRE_DISMISS_TOAST, payload: id });
const animatedDismissToastEnd = ({ id }) => ({ type: DISMISS_TOAST, payload: id });

export const showToast = ({ ...payload }) => ({ type: SHOW_TOAST, payload });
export const dismissToast = ({ id }) => {
  return dispatch => {
    dispatch(animatedDismissToastStart({ id }));
    return delay(250).then(() => dispatch(animatedDismissToastEnd({ id })));
  };
};
