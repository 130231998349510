/* eslint-disable */

import { loginUrl, smsfUrl } from 'src/utils/pageUrls';
import FormSelect from 'src/design/components/formSelect/FormSelect';
import Heading from 'src/design/components/heading/Heading';
import HelpDisclaimer from 'src/design/components/helpDisclaimer/HelpDisclaimer';
import OnboardingNextButton from 'src/components/oldOnboarding/common/onboardingNextButton/OnboardingNextButton';
import OnboardingScreen, {
  OnboardingScreenHeader,
  OnboardingScreenContent,
} from 'src/components/oldOnboarding/common/onboardingScreen/OnboardingScreen';
import Paragraph from 'src/design/components/bodyText/Paragraph';
import SignupFormMeta from 'src/components/meta/SignupFormMeta';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import StyledBodylink from 'src/design/components/hyperlink/StyledBodylink';
import TextInput from 'src/design/components/textInput/TextInput';
import TextInputWithError from 'src/design/components/textInput/TextInputWithError';
import TickedBulletList from 'src/design/components/bulletList/TickedBulletList';
import styles from 'src/components/oldOnboarding/signup/SignupForm.mscss';
import { ACCOUNTTYPES } from 'src/settings/registration';
import styled from 'styled-components';
import { IconCheckbox } from 'src/bxDesign/form/IconCheckbox';
import {
  BsFillPersonFill,
  BsPeopleFill,
  BsBriefcaseFill,
} from 'react-icons/bs';
import { FaChild, FaCoins, FaPeopleArrows } from 'react-icons/fa';
import PrimaryButton from 'src/design/components/button/PrimaryButton';

const AccountTypeSelectorLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;

  @media (max-width: 425px) {
    .bx-icon-checkbox--icon {
      font-size: 3rem;
      margin-bottom: -5px;
    }
    .bx-icon-checkbox--text {
      font-size: 1.3rem;
    }
  }
`;

/**
 * The regular signup page
 */
const SignupForm = ({
  onSubmit,
  onLoginClick,
  givenName,
  email,
  howDidYouHearAboutUsAnswer,
  password,
  disabled,
  accountType,
  isSmsfSignupPage,
  hideSmsf,
  hideTrust,
}) => {
  const buttonColor = {
    [ACCOUNTTYPES.INDIVIDUAL]: PrimaryButton.colors.SECONDARY,
    [ACCOUNTTYPES.MINOR]: PrimaryButton.colors.YOLK,
    [ACCOUNTTYPES.SMSF]: PrimaryButton.colors.BLUE,
    [ACCOUNTTYPES.TRUST]: PrimaryButton.colors.BLUE,
  }[accountType.value];
  if (isSmsfSignupPage) {
    return (
      <form>
        <Spacing bottom="x-small">
          <TextInputWithError
            placeholder={
              accountType.value === ACCOUNTTYPES.MINOR
                ? 'First Name (Adult Trustee)'
                : 'First Name'
            }
            name="name"
            onChange={(value) => givenName.onChange({ target: { value } })}
            value={givenName.value}
            error={givenName.invalid && 'Please enter your first name'}
            testRef="first-name"
            errorTestRef="name-error"
            disabled={disabled}
          />
        </Spacing>
        <Spacing bottom="x-small">
          <TextInputWithError
            placeholder="Email Address"
            name="email"
            onChange={(value) => email.onChange({ target: { value } })}
            value={email.value}
            error={email.invalid && 'Please enter a valid email address'}
            testRef="email"
            errorTestRef="email-error"
            disabled={disabled}
          />
        </Spacing>
        <Spacing bottom="x-small">
          <TextInputWithError
            type="password"
            name="password"
            placeholder="Password"
            onChange={(value) => password.onChange({ target: { value } })}
            value={password.value}
            error={password.invalid && 'Please enter a valid password'}
            testRef="password"
            errorTestRef="password-error"
            disabled={disabled}
          />
        </Spacing>
        <Spacing bottom="normal">
          <HelpDisclaimer>
            Minimum 8 characters and at least 1 letter and 1 digit
          </HelpDisclaimer>
        </Spacing>
        <FormSelect
          testRef="how-did-you-hear-about-us-select"
          value={howDidYouHearAboutUsAnswer.value}
          onChange={(value) => howDidYouHearAboutUsAnswer.onChange({ value })}
          disabled={disabled}
        >
          <option value="">Where did you hear about us?</option>
          <option value="social: facebook">Facebook</option>
          <option value="social: instagram">Instagram</option>
          <option value="social: twitter">Twitter</option>
          <option value="social: linkedin">Linkedin</option>
          <option value="social: youtube">Youtube</option>
          <option value="search_engine">
            Search Engine (Google, Bing, etc.)
          </option>
          <option value="advert">Advertisement</option>
          <option value="referral">Friend or colleague</option>
          <option value="news">News</option>
          <option value="blog">Blog</option>
          <option value="newspaper">Newspaper</option>
          <option value="radio">Radio</option>
          <option value="current_investor">I am already a member!</option>
          <option value="other">Other (please specify)</option>
        </FormSelect>
        {howDidYouHearAboutUsAnswer.value === 'other' ? (
          <Spacing top="x-small">
            <TextInput
              testRef="how-did-you-hear-about-us-other-answer"
              value={howDidYouHearAboutUsAnswer.customValue}
              onChange={(customValue) =>
                howDidYouHearAboutUsAnswer.onChange({ customValue })
              }
            />
          </Spacing>
        ) : (
          ''
        )}
        <Spacing bottom="large">
          <OnboardingNextButton
            color={buttonColor}
            customText="Sign Up"
            onClick={onSubmit}
            testRef="submit-button"
            disabled={disabled}
          />
        </Spacing>
      </form>
    );
  }
  return (
    <OnboardingScreen testRef="signup-form">
      <SignupFormMeta />
      <OnboardingScreenHeader>
        <Spacing bottom="large">
          <Heading>Sign up now</Heading>
        </Spacing>
      </OnboardingScreenHeader>
      <OnboardingScreenContent>
        <Spacing bottom="large">
          <TickedBulletList
            className={styles.spasticCenteredTickedBulletList}
            listItemClassName={styles['spasticCenteredTickedBulletList--item']}
            textSize="x-small"
            items={[
              'Join more than 80,000 signups',
              'Buy Bricks today',
              'Find out about new BrickX properties',
              'Build a diverse BrickX property portfolio',
            ]}
          />
        </Spacing>
        <OnboardingScreenHeader>
          <Spacing bottom="large">
            <div>Select an Account Type</div>
          </Spacing>
        </OnboardingScreenHeader>
        <OnboardingScreenHeader>
          <Spacing bottom="large">
            <AccountTypeSelectorLayout>
              <IconCheckbox
                icon={<BsFillPersonFill />}
                text={'Individual'}
                isSelected={accountType.value === ACCOUNTTYPES.INDIVIDUAL}
                onClick={() => accountType.onChange(ACCOUNTTYPES.INDIVIDUAL)}
                testRef="individual-account-type-button"
              />
              <IconCheckbox
                icon={<FaChild />}
                text={'Minor'}
                isSelected={accountType.value === ACCOUNTTYPES.MINOR}
                onClick={() => accountType.onChange(ACCOUNTTYPES.MINOR)}
                testRef="minor-account-type-button"
                colorSuite="yolk"
              />
              {!hideSmsf && (
                <IconCheckbox
                  icon={<FaCoins />}
                  text={'SMSF'}
                  isSelected={accountType.value === ACCOUNTTYPES.SMSF}
                  onClick={() => accountType.onChange(ACCOUNTTYPES.SMSF)}
                  colorSuite="blue"
                />
              )}
              {!hideTrust && (
                <IconCheckbox
                  icon={<FaPeopleArrows />}
                  text={'Trust'}
                  isSelected={accountType.value === ACCOUNTTYPES.TRUST}
                  onClick={() => accountType.onChange(ACCOUNTTYPES.TRUST)}
                  colorSuite="blue"
                />
              )}
            </AccountTypeSelectorLayout>
          </Spacing>
        </OnboardingScreenHeader>
        <form>
          <Spacing bottom="x-small">
            <TextInputWithError
              placeholder={
                accountType.value === ACCOUNTTYPES.MINOR
                  ? 'First Name (Adult Trustee)'
                  : 'First Name'
              }
              name="name"
              onChange={(value) => givenName.onChange({ target: { value } })}
              value={givenName.value}
              error={givenName.invalid && 'Please enter your first name'}
              testRef="first-name"
              errorTestRef="name-error"
              disabled={disabled}
            />
          </Spacing>
          <Spacing bottom="x-small">
            <TextInputWithError
              placeholder="Email Address"
              name="email"
              onChange={(value) => email.onChange({ target: { value } })}
              value={email.value}
              error={email.invalid && 'Please enter a valid email address'}
              testRef="email"
              errorTestRef="email-error"
              disabled={disabled}
            />
          </Spacing>
          <Spacing bottom="x-small">
            <TextInputWithError
              type="password"
              name="password"
              placeholder="Password"
              onChange={(value) => password.onChange({ target: { value } })}
              value={password.value}
              error={password.invalid && 'Please enter a valid password'}
              testRef="password"
              errorTestRef="password-error"
              disabled={disabled}
            />
          </Spacing>
          <Spacing bottom="normal">
            <HelpDisclaimer>
              Minimum 8 characters and at least 1 letter and 1 digit
            </HelpDisclaimer>
          </Spacing>
          <FormSelect
            testRef="how-did-you-hear-about-us-select"
            value={howDidYouHearAboutUsAnswer.value}
            onChange={(value) => howDidYouHearAboutUsAnswer.onChange({ value })}
            disabled={disabled}
          >
            <option value="">Where did you hear about us?</option>
            <option value="social: facebook">Facebook</option>
            <option value="social: instagram">Instagram</option>
            <option value="social: twitter">Twitter</option>
            <option value="social: linkedin">Linkedin</option>
            <option value="social: youtube">Youtube</option>
            <option value="search_engine">
              Search Engine (Google, Bing, etc.)
            </option>
            <option value="advert">Advertisement</option>
            <option value="referral">Friend or colleague</option>
            <option value="news">News</option>
            <option value="blog">Blog</option>
            <option value="newspaper">Newspaper</option>
            <option value="radio">Radio</option>
            <option value="current_investor">I am already a member!</option>
            <option value="other">Other (please specify)</option>
          </FormSelect>
          {howDidYouHearAboutUsAnswer.value === 'other' ? (
            <Spacing top="x-small">
              <TextInput
                testRef="how-did-you-hear-about-us-other-answer"
                value={howDidYouHearAboutUsAnswer.customValue}
                onChange={(customValue) =>
                  howDidYouHearAboutUsAnswer.onChange({ customValue })
                }
              />
            </Spacing>
          ) : (
            ''
          )}
          <Spacing bottom="large">
            <OnboardingNextButton
              color={buttonColor}
              customText="Sign Up"
              onClick={onSubmit}
              testRef="submit-button"
              disabled={disabled}
            />
          </Spacing>
        </form>
        <Paragraph align="center">
          Already have an account?{' '}
          <StyledBodylink
            href={loginUrl()}
            onClick={onLoginClick}
            testRef="signup-form-login-button"
          >
            Log in
          </StyledBodylink>
        </Paragraph>
      </OnboardingScreenContent>
    </OnboardingScreen>
  );
};

export default SignupForm;
