import { connect } from 'react-redux';
import { BrickxLogoAgriGreen } from 'src/components/brickxLogo/BrickxLogo';
import { TopLevelMenuItem } from 'src/design/components/header/MenuItemTypes';
import { aboutUrl } from 'src/utils/pageUrls';
import { menuOpenSelector } from 'src/store/headerMenuReducer';
import { scrollToId } from 'src/browser/window';
import {
  toggleMenu as toggleMenuAction,
  closeMenu as closeMenuAction,
} from 'src/store/headerMenuActions';
import Header from 'src/design/components/header/Header';

const RentToBuyHeader = ({ menuOpen, toggleMenu, closeMenu }) => {
  return (
    <Header
      title="Agriculture"
      logo={<BrickxLogoAgriGreen forceExternal/>}
      mobileMenuOpen={menuOpen}
      onLogoClick={closeMenu}
      onHamburgerIconClick={toggleMenu}
    >
      <TopLevelMenuItem onClick={() => scrollToId('why-agri')} text="Why Agri?"/>
      <TopLevelMenuItem onClick={() => scrollToId('brickx-agri-faqs')} text="FAQs"/>
      <TopLevelMenuItem link={aboutUrl()} text="About Us" forceExternal/>
    </Header>
  );
};

export default connect(menuOpenSelector, {
  toggleMenu: toggleMenuAction,
  closeMenu: closeMenuAction,
})(RentToBuyHeader);
