import { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getOffsetHeight } from 'scripts/utilities/browser';
import { standardTransition } from 'src/design/styleguide/common/animations';
import {
  testRefToDataTestReference,
} from 'src/design/styleguide/common/styledComponentsUtils';

const getOffsetHeightIfElExists = (el) => el ? getOffsetHeight(el) : 0;

const ExpandableContainer = styled.div`
  overflow: hidden;
  transition: height ${standardTransition};
`;

const ContentContainer = styled.div.attrs(testRefToDataTestReference)`
  /* prevent vertical margins from breaking out of the container */
  padding-top: 1px;
  padding-bottom: 1px;
`;

class Expandable extends Component {
  static propTypes = {
    className: PropTypes.string,
    expanded: PropTypes.bool,
    testRef: PropTypes.string,
  };

  render() {
    const { className, expanded, children, testRef } = this.props;
    return (
      <ExpandableContainer
        className={className}
        style={{
          height: `${expanded
            ? getOffsetHeightIfElExists(this.content)
            : 0
          }px`
        }}
        data-test-reference={testRef}
      >
        <ContentContainer
          ref={(el) => this.content = el}
          testRef="content-container"
        >
          {children}
        </ContentContainer>
      </ExpandableContainer>
    );
  }
}

export default Expandable;
