import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { percentDecimal, percentDecimalWithSign, dollarDecimal } from 'scripts/utilities/formatters';
import { estimatedTotalReturns, calculateAnnualisedGrowthRate } from 'scripts/utilities/calculator/calculators';
import Trading from 'scripts/constants/Trading';


export default class PropertyReturnsCalculatorReturnsEstimation extends Component {
  static propTypes = {
    investmentAmount: PropTypes.number.isRequired,
    investmentPeriod: PropTypes.number.isRequired,
    annualGrowthRate: PropTypes.number.isRequired,
    debtRate: PropTypes.number.isRequired,
    netRentalYieldRate: PropTypes.number.isRequired,
    initialPercentOfPropertyAsset: PropTypes.number.isRequired,
    initialPercentOfAcquisitionCost: PropTypes.number.isRequired,
    initialPercentOfCashReserve: PropTypes.number.isRequired,
    remainingMonthsOfAmortising: PropTypes.number,
  };

  render() {
    const {
      investmentPeriod,
      investmentAmount,
      annualGrowthRate,
      debtRate,
      netRentalYieldRate,
      initialPercentOfPropertyAsset,
      initialPercentOfAcquisitionCost,
      initialPercentOfCashReserve,
      remainingMonthsOfAmortising
    } = this.props;

    const numOfRemainingMonthsOfAmortising = remainingMonthsOfAmortising ? remainingMonthsOfAmortising : Trading.NUM_OF_MONTHS_FOR_AMORTISING;
    const estimatedCapitalReturnsAtExit = estimatedTotalReturns(investmentAmount, annualGrowthRate, debtRate, netRentalYieldRate, initialPercentOfPropertyAsset, initialPercentOfAcquisitionCost, initialPercentOfCashReserve, investmentPeriod, numOfRemainingMonthsOfAmortising);
    const estimatedCapitalReturnsRate = investmentAmount === 0 ? 0 : estimatedCapitalReturnsAtExit / investmentAmount;
    const estimatedCapitalReturnsAnnualisedRate = calculateAnnualisedGrowthRate(estimatedCapitalReturnsRate, investmentPeriod);
    const positiveReturns = estimatedCapitalReturnsAtExit >= 0;

    return (
      <div className="return-calculator-results-overview__returns-estimation">
        <div className="return-calculator-results-overview__estimated-return">
          Estimated Return
          <div className="return-calculator-results-overview__estimated-return-value">
            {estimatedCapitalReturnsAtExit::dollarDecimal()}
          </div>
        </div>
        <div className="return-calculator-results-overview__estimated-return-rates">
          <div className={`return-calculator-results-overview__estimated-return-rate ${positiveReturns ? 'return-calculator-results-overview__estimated-return-rate--positive' : 'return-calculator-results-overview__estimated-return-rate--negative'}`}>
            {(estimatedCapitalReturnsRate)::percentDecimalWithSign()}
          </div>
          <div className={`return-calculator-results-overview__estimated-return-annualised-rate ${positiveReturns ? 'return-calculator-results-overview__estimated-return-annualised-rate--positive' : 'return-calculator-results-overview__estimated-return-annualised-rate--negative'}`}>
            ({estimatedCapitalReturnsAnnualisedRate::percentDecimal()} annualised)
          </div>
        </div>
      </div>
    );
  }
}
