
import { Component } from 'react';
import PropTypes from 'prop-types';

import { bricksSoldProgressBarTextToShow, getBricksSoldPercentage, isANewProperty } from 'scripts/utilities/propertyHelper';

import { property as propertyPropType } from 'scripts/constants/PropTypes';

import classNames from 'classnames';

export default class BricksSoldProgressBar extends Component {
  static propTypes = {
    property: propertyPropType.isRequired,
    showPopover: PropTypes.bool,
    showText: PropTypes.bool,
  };

  render() {
    const { showPopover, showText, property } = this.props;

    return (
      <div className="bricks-sold-progress">
        {showText && bricksSoldProgressBarTextToShow(property, showPopover)}
        <div className="bricks-sold-progress__bar">
          <div className={classNames('bricks-sold-progress__bar-fill', { 'bricks-sold-progress__bar-fill--new-property': isANewProperty(property) })} style={{ width: `${getBricksSoldPercentage(property)}%` }}></div>
        </div>
      </div>
    );
  }
}
