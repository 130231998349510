import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  property as propertyPropType,
  averageAnnualGrowthMetrics as averageAnnualGrowthMetricsPropType,
} from 'scripts/constants/PropTypes';
import { averageAnnualGrowthMetricsSelector } from 'scripts/redux/selectors/historicalGrowth';
import { propertiesSelector } from 'scripts/redux/selectors/properties';
import { allMonthlyDistributionsSelector } from 'scripts/redux/selectors/property';
import { fetchProperties } from 'scripts/redux/actions/properties';
import { fetchHistoricalGrowthMetrics } from 'scripts/redux/actions/historicalGrowth';
import { fetchAllMonthlyDistributions } from 'scripts/redux/actions/property';

const mapStateToProps = (state) => ({
  ...propertiesSelector(state),
  ...averageAnnualGrowthMetricsSelector(state),
  ...allMonthlyDistributionsSelector(state),
});

const mapDispatchToProps = {
  fetchProperties,
  fetchHistoricalGrowthMetrics,
  fetchAllMonthlyDistributions,
};

@connect(mapStateToProps, mapDispatchToProps)
export default class Properties extends Component {
  static propTypes = {
    averageAnnualGrowthMetrics: averageAnnualGrowthMetricsPropType,
    properties: PropTypes.arrayOf(propertyPropType).isRequired,
    fetchProperties: PropTypes.func.isRequired,
    fetchHistoricalGrowthMetrics: PropTypes.func.isRequired,
    fetchAllMonthlyDistributions: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const {
      fetchProperties,
      fetchHistoricalGrowthMetrics,
      fetchAllMonthlyDistributions,
    } = this.props;
    fetchProperties();
    fetchHistoricalGrowthMetrics();
    fetchAllMonthlyDistributions();
  }

  render() {
    const {
      properties,
      averageAnnualGrowthMetrics,
      allMonthlyDistributions,
      children,
    } = this.props;
    return (
      <div id="properties" className="properties">
        {children &&
          React.cloneElement(children, {
            properties,
            averageAnnualGrowthMetrics,
            allMonthlyDistributions,
          })}
      </div>
    );
  }
}
