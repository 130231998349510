import { browserHistory } from 'react-router';

const getHistory = () => browserHistory || {
  push: () => {},
  replace: () => {},
  goBack: () => {},
};

export default {
  push: (...args) => getHistory().push(...args),
  replace: (...args) => getHistory().replace(...args),
  goBack: (...args) => getHistory().goBack(...args),
};
