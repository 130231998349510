import moment from 'moment';
import { extendMoment } from 'moment-range';
import { TAX_STATEMENT_AVAILABLE_DATES } from 'src/settings/dates';
import { yearToFinancialYearRange } from 'src/utils/formats';

const momentWithRange = extendMoment(moment);

export const getSiTransactions = (transactionsResponse) => transactionsResponse.find((plan) => plan.planType === 'smart_invest').transactions;
export const getBmoTransactions = (transactionsResponse) => transactionsResponse.find((plan) => plan.planType === 'build_your_own').transactions;
export const truncateTransactions = (transactionsResponse, limit) =>
  transactionsResponse.map(({ transactions, ...rest }) => ({
    transactions: transactions.slice(0, limit),
    ...rest,
  }));

const hasTransactionsInFinancialYear = (transactionsResponse, year) => {
  const financialYearRange = momentWithRange().range(new Date(year - 1, 7, 1), new Date(year, 7, 1));
  return transactionsResponse.some((plan) => plan.transactions.some((transaction) => financialYearRange.contains(momentWithRange(transaction.timestamp))));
};

export const getAnnualStatementLinkDetailsForYear = (year) => ({
  title: `Annual Statements, FY ${yearToFinancialYearRange(year)}`,
  link: `/account/documents/annual-statements/${year}`,
  description: `View statements including your Annual Summary and Individual Tax Statements for the period 1 July ${year - 1} to 30 June ${year}`,
});

const getAnnualStatementLinkInfoOrEmpty = (transactionsResponse, year) => (hasTransactionsInFinancialYear(transactionsResponse, year) ? [getAnnualStatementLinkDetailsForYear(year)] : []);

export const getAllAnnualStatementLinkDetails = (transactionsResponse) => {
  return TAX_STATEMENT_AVAILABLE_DATES.map(y => getAnnualStatementLinkInfoOrEmpty(transactionsResponse, y)).reduce((prev, next) => [...prev, ...next], []);
};
