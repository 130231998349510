import React, { Fragment } from 'react';
import ContentContainer from 'src/design/styleguide/contentContainer/ContentContainer';
import ContentSection from 'src/design/styleguide/contentSection/ContentSection';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import {
  AccentHeadingLight,
  BigSection,
  FaqRow,
  HeaderContent,
  iconSizes,
  IconText,
  LandingPageLayout,
  NumberedPoint,
  SectionHeading,
  SocialLinkIcon,
  SocialLinksContainer,
  SocialLinkShareLabel,
  VideoContainer,
  WhyReasonsContainer2Grid,
  WhyReasonsContainer4Grid } from 'src/components/landingPages/common/design/PageContent';
import ContentBlock from 'src/design/styleguide/contentBlock/ContentBlock';
import { Heading } from 'src/design/styleguide/Headings';
import Header from 'src/design/components/header/Header';
import { BrickxLogoDark } from 'src/components/brickxLogo/BrickxLogo';
import { TopLevelMenuItem } from 'src/design/components/header/MenuItemTypes';
import {
  aboutUrl,
  brickXStructureUrl,
  dashboardUrl,
  feesAndTaxesHelpUrl,
  getFullImageAssetUrl,
  whatReportsWillIRecieveUrl
} from 'src/utils/pageUrls';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import { scrollToId } from 'src/browser/window';
import { menuOpenSelector } from 'src/store/headerMenuReducer';
import {
  toggleMenu as toggleMenuAction,
  closeMenu as closeMenuAction,
} from 'src/store/headerMenuActions';
import { connect } from 'react-redux';
import Video from 'src/design/components/video/Video';
import { howItWorksVideoId } from 'src/utils/videoIds';
import Footer from 'src/components/footer/Footer';
import Icon from 'src/design/components/icon/Icon';
import Paragraph from 'src/design/styleguide/Paragraph';
import Text from 'src/design/styleguide/text/Text';
import ListItemNumber from './common/listItemNumber/ListItemNumber';
import StyledBodylink from 'src/design/components/hyperlink/StyledBodylink';

const BrickXWholesaleLandingPageHeader = connect(menuOpenSelector, {
  toggleMenu: toggleMenuAction,
  closeMenu: closeMenuAction,
})(({ menuOpen, toggleMenu, closeMenu }) => (
  <Header
    title="Professional And Sophisticated"
    logo={<BrickxLogoDark />}
    mobileMenuOpen={menuOpen}
    onLogoClick={closeMenu}
    onHamburgerIconClick={toggleMenu}
  >
    <TopLevelMenuItem onClick={() => scrollToId('how-to-get-started')} text="How to get started"/>
    <TopLevelMenuItem onClick={() => scrollToId('brickx-professional-and-sophisticated-faqs')} text="FAQs"/>
    <TopLevelMenuItem link={aboutUrl()} text="About Us" forceExternal/>
  </Header>
));

const BrickXWholesaleLandingPageBody = () => {
  const pagePublicLink = `https://www.brickx.com/professional-and-sophisticated`;
  return (
    <Fragment>
      <ContentBlock
        overlayOpacity="0.8"
        overlayColor="charcoal"
        backgroundImage={getFullImageAssetUrl('/bg/melbourne-city-2.jpeg')}>
        <ContentSection as="section">
            <ContentContainer topPadding="x-large" bottomPadding="x-large">
              <HeaderContent>
                <Heading align="left">
                  <AccentHeadingLight>
                    Introducing
                    <br />
                    <strong>BrickX - Professional And Sophisticated Investors</strong>
                  </AccentHeadingLight>
                </Heading>
                <Spacing top="x-large" bottom="x-large">
                  <WhyReasonsContainer4Grid>
                    <IconText
                      icon={Icon.types.different_bricks}
                      text={<Paragraph color="white"><strong>Diversify</strong> your BrickX investments by accessing additional exclusive professional and sophisticated investor opportunities.</Paragraph>}
                      align="left"
                      size={iconSizes.lg}
                    />
                    <IconText
                      icon={Icon.types.hand_pointing_at_calendar}
                      text={<Paragraph color="white"><strong>Flexibility</strong> to opt in and out of BrickX professional and sophisticated offers.</Paragraph>}
                      align="left"
                      size={iconSizes.lg}
                    />
                    <IconText
                      icon={Icon.types.strategy}
                      text={<Paragraph color="white"><strong>Access</strong> opportunities from BrickX and the wider BrickX Group.</Paragraph>}
                      align="left"
                      size={iconSizes.lg}
                    />
                    <IconText
                      icon={Icon.types.investment_philosophy}
                      text={<Paragraph color="white"><strong>Participate</strong> in offers alongside other qualified professional and sophisticated investors.</Paragraph>}
                      align="left"
                      size={iconSizes.lg}
                    />
                  </WhyReasonsContainer4Grid>
                </Spacing>
                <Spacing bottom="normal">
                  <SocialLinksContainer>
                    <SocialLinkShareLabel><Text color="light"><strong>Share On: </strong></Text></SocialLinkShareLabel>
                    <SocialLinkIcon link={pagePublicLink} social="facebook" />
                    <SocialLinkIcon link={pagePublicLink} social="linkedin" />
                    <SocialLinkIcon link={pagePublicLink} social="twitter" />
                    <SocialLinkIcon link={pagePublicLink} social="whatsapp" />
                    <SocialLinkIcon link={pagePublicLink} social="email" icon="envelope" />
                  </SocialLinksContainer>
                </Spacing>
              </HeaderContent>
            </ContentContainer>
        </ContentSection>
      </ContentBlock>
      <BigSection
        id="how-to-get-started"
        as="section"
        topPadding="x-large"
        bottomPadding="x-large"
        backgroundColor="lightgrey"
      >
        <ContentSection>
          <SectionHeading>How to get started</SectionHeading>
        </ContentSection>
        <Spacing top="large">
          <WhyReasonsContainer2Grid>
            <ContentContainer className={ListItemNumber.counterResetClass}>
              <NumberedPoint colorCode="blue" heading="Easy Sign Up" text="Sign up from your dashboard – The first step to investing as a professional and sophisticated investor with BrickX."/>
              <NumberedPoint colorCode="blue" heading="Register as a professional and sophisticated investor" text="We will verify your Qualified Accountants certificate and let you know when you are ready to participate in excluded offerings to professional and sophisticated investors."/>
              <NumberedPoint colorCode="blue" heading="Deposit Funds" text="Log in to your BrickX account to deposit funds and start investing."/>
              <Spacing bottom="large">
                <PrimaryButton color={PrimaryButton.colors.SECONDARY} link={dashboardUrl()}>Sign up from Dashboard</PrimaryButton>
              </Spacing>
            </ContentContainer>
            <ContentContainer>
              <VideoContainer>
                <Video videoId={howItWorksVideoId} />
              </VideoContainer>
            </ContentContainer>
          </WhyReasonsContainer2Grid>
        </Spacing>
      </BigSection>
      <BigSection
        id="brickx-professional-and-sophisticated-faqs"
        width="large"
      >
        <SectionHeading>Top FAQs</SectionHeading>
        <hr />
        <FaqRow>
          <Text className="question">What is a Professional Investor?</Text>
          <Text className="answer">
            The definition of a professional investor encompasses several factors. The key attributes considered are whether the investor:
            <ul>
              <li>holds an Australian Financial Services License (AFSL);</li>
              <li>is regulated by the Australian Prudential Regulation Authority (APRA); or</li>
              <li>controls at least $10 million worth of assets.</li>
            </ul>
          </Text>
        </FaqRow>
        <hr />
        <FaqRow>
          <Text className="question">What is a Sophisticated Investor?</Text>
          <Text className="answer">
            A sophisticated investor is an investor who has a certificate from a qualified accountant certifying that they have:
            <ul>
              <li>net assets of at least $2.5 million; or</li>
              <li>a gross income for the previous two years of at least $250,000.</li>
            </ul>
            The investor will also be sophisticated if they are investing at least $500,000 into the BrickX Platform.
          </Text>
        </FaqRow>
        <hr />
        <FaqRow>
          <Text className="question">What reports will I receive?</Text>
          <Text className="answer">
            Once your BrickX Account is set up, all your investment information and reporting is available through the Account Dashboard,
            including your portfolio performance, transaction history, distribution payments, transaction confirmations,
            financial year-end reports and tax statements. Should you participate in a professional and sophisticated offering presented to you by a related BrickX party
            – similar reporting will be provided to you in relation to your investments.
            <br /><StyledBodylink href={whatReportsWillIRecieveUrl()}>Read More</StyledBodylink> </Text>
        </FaqRow>
        <hr />
        <FaqRow>
          <Text className="question">What are the Fees?</Text>
          <Text className="answer">
            BrickX charges a 0.5% transaction fee when you buy Bricks and a 0.5% transaction fee when you sell Bricks.
            Whether you hold your Bricks for 2 months or 2 years, you’ll only ever pay 0.5% of the transaction value each time you buy and sell.
            There are no funds under management, performance, contribution, service, or withdrawal fees.
            Should you participate in a professional and sophisticated offering presented to you by a related BrickX party –
            fees specific to that offer will be outlined to you in an additional information memorandum.
            <br /><StyledBodylink href={feesAndTaxesHelpUrl()}>Read More</StyledBodylink>
          </Text>
        </FaqRow>
        <hr />
        <FaqRow>
          <Text className="question">How is BrickX structured?</Text>
          <Text className="answer">
            The BrickX Platform is a managed investment scheme that is registered with ASIC.
            Each BrickX property is held in a separate trust by an external Trustee,
            BrickX Financial Services Limited (BrickX Financial Services) who holds AFSL No. 494878.
            BrickX is the owner of the technology and website behind the BrickX Platform.
            BrickX is a wholly owned subsidiary of Thundering Herd Pty Ltd and as such is able to access and offer additional
            forms of investment opportunity to qualified professional and sophisticated investors, with any such offer being regulated by the
            Australian Financial Services Licences it holds within the wider group of Thundering Herd owned companies.
            <br /><StyledBodylink href={brickXStructureUrl()}>Read More</StyledBodylink>
          </Text>
        </FaqRow>
      </BigSection>
    </Fragment>
  );
};

const BrickXWholesaleLandingPage = () => {
  return (
    <LandingPageLayout>
      <BrickXWholesaleLandingPageHeader />
      <BrickXWholesaleLandingPageBody />
      <Footer />
    </LandingPageLayout>);
};

export default BrickXWholesaleLandingPage;
