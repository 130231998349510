import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { validateName } from 'src/utils/validation';

import styles from 'src/design/components/textInput/TextInput.mscss';

const stripInvalidCharacters = (string, isCharacterValid) => Array
  .from(string)
  .reduce((previous, current) => isCharacterValid(current)
    ? `${previous}${current}`
    : previous
  , '');

class TextInput extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    isCharacterValid: PropTypes.func,
    isStringValid: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    highlighted: PropTypes.bool,
    error: PropTypes.bool,
    forId: PropTypes.string,
    testRef: PropTypes.string,
  };

  static defaultProps = {
    isStringValid: () => true,
    isCharacterValid: () => true,
    onChange: () => {},
    onBlur: () => {},
  };

  state = { value: this.props.value || '' };

  static getDerivedStateFromProps(props) {
    return props.value !== undefined
      ? { value: props.value }
      : null;
  }

  change = (value) => {
    const { isCharacterValid, isStringValid } = this.props;

    const invalidCharactersStrippedValue = stripInvalidCharacters(value, isCharacterValid);
    if (!isStringValid(invalidCharactersStrippedValue)) return;

    this.setState({ value: invalidCharactersStrippedValue });
    this.props.onChange(invalidCharactersStrippedValue);
  };

  render() {
    const {
      className,
      isCharacterValid,
      isStringValid,
      highlighted,
      onChange,
      onBlur,
      error,
      forId,
      testRef,
      handleChange,
      ...otherProps,
    } = this.props;

    const classMap = {
      [styles.highlighted]: highlighted,
      [styles.errorHighlighted]: error,
    };

    return (
      <input
        type="text"
        onChange={({ target: { value } }) => this.change(value)}
        onBlur={({ target: { value } }) => onBlur(value)}
        className={classNames(
          styles.textInput,
          styles.textInput__font,
          styles.textInput__spacing,
          styles.textInput__colors,
          classMap,
          className
        )}
        id={forId}
        data-test-reference={testRef}
        {...otherProps}
        value={this.state.value}
      />
    );
  }
}

TextInput.validation = {
  numbersOnly: (value) => /^\d*$/.test(value),
  numbersAndDecimalOnly: (value) => /^\d*\.{0,1}\d*$/.test(value),
  maxLength: (max) => (value) => !(value.length > max),
  alphaNumericOnly: (value) => /^[a-zA-Z0-9]*$/.test(value),
  name: (value) => validateName(value),
};

export default TextInput;
