import styled from 'styled-components';
import { Heading } from 'src/design/styleguide/Headings';
import { breakpointSmall } from 'src/design/styleguide/common/breakpoints';
import { gridUnit } from 'src/design/styleguide/common/measurements';

const SmsfPageSectionHeading = styled(Heading)`
  margin-bottom: ${gridUnit * 8}px;

  ${breakpointSmall(`
    margin-bottom: ${gridUnit * 16}px;
  `)}
`;

export default SmsfPageSectionHeading;
