import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchAccountInfoV2 } from 'scripts/redux/actions/account';
import {
  fetchSmartInvestDetails as fetchSmartInvestDetailsAction,
  updateSmartInvestAndGoBackToSettings as updateSmartInvestAndGoBackToSettingsAction,
  smartInvestSelector,
} from 'scripts/redux/actions/smartInvest';
import { getUserBankAccountDetails } from 'src/utils/user';
import { hasNominatedBankAccount } from 'scripts/utilities/userAccountHelper';
import { minimumSIAmount } from 'src/settings/funds';
import { user as userPropType } from 'scripts/constants/PropTypes';
import { userSelector } from 'scripts/redux/selectors/user';
import BankAccountDetailsInput from 'src/components/settings/BankAccountDetailsInput';
import BankDetailsScreenshotUpload from 'src/components/settings/bankDetailsScreenshotUpload/BankDetailsScreenshotUpload';
import DayOfMonthSelect from 'src/design/components/formSelect/DayOfMonthSelect';
import DirectDebitExpandableAgreements from 'src/components/common/DirectDebitExpandableAgreements';
import DirectDebitRequestCheckbox from 'src/components/oldOnboarding/smartInvestDetails/bankDetailsInput/DirectDebitRequestCheckbox';
import DirectDebitRequestServiceAgreementCheckbox from 'src/components/oldOnboarding/smartInvestDetails/bankDetailsInput/DirectDebitRequestServiceAgreementCheckbox';
import Divider from 'src/design/components/divider/Divider';
import FinePrint from 'src/design/components/bodyText/FinePrint';
import FormLabel from 'src/design/components/formLabel/FormLabel';
import Loading from 'src/components/loading/Loading';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import SubHeading from 'src/design/components/heading/SubHeading';
import TermsList from 'src/components/settings/TermsList';
import TextInputWithError from 'src/design/components/textInput/TextInputWithError';
import { bankDetailsUpdateUrl } from 'src/utils/pageUrls';

const BankAccountDetailsPlainText = ({ bankAccountName, bankAccountBsb, bankAccountNumber }) => (
  <div>
    <TermsList
      keyPairs={[
        { name: 'Account name', value: bankAccountName, testRef: 'bank-account-name-plain-text' },
        { name: 'BSB', value: bankAccountBsb, testRef: 'bank-account-bsb-plain-text' },
        { name: 'Account number', value: bankAccountNumber, testRef: 'bank-account-number-plain-text' },
      ]}
      testRef="bank-account-details-terms-list"
    />
    <FinePrint>This account is also used for receiving withdrawals.</FinePrint>
    <Spacing top="large">
      <PrimaryButton link={bankDetailsUpdateUrl()} color={PrimaryButton.colors.SECONDARY}>
        Update my bank details
      </PrimaryButton>
    </Spacing>
  </div>
);

@connect((state) => ({
  ...userSelector(state),
  ...smartInvestSelector(state),
}), {
  fetchSmartInvestDetails: fetchSmartInvestDetailsAction,
  fetchAccountInfo: fetchAccountInfoV2,
  updateSmartInvestAndGoBackToSettings: updateSmartInvestAndGoBackToSettingsAction
})
export default class SmartInvestSettingsForm extends Component {
  static propTypes = {
    user: userPropType,
    smartInvest: PropTypes.shape({
      amount: PropTypes.number,
      dayOfMonth: PropTypes.number,
      fetching: PropTypes.bool,
      saving: PropTypes.bool,
    }),
    updateSmartInvestAndGoBackToSettings: PropTypes.func,
    fetchSmartInvestDetails: PropTypes.func,
    className: PropTypes.string,
    submitButtonText: PropTypes.string,
  };

  state = {
    smartInvestAmount: '',
    smartInvestDayOfMonth: '',
    bankName: '',
    bankAccountName: getUserBankAccountDetails(this.props.user).bankAccountName,
    bankAccountBsb: getUserBankAccountDetails(this.props.user).BSB,
    bankAccountNumber: getUserBankAccountDetails(this.props.user).bankAccountNumber,
    agreeToDirectDebitRequest: false,
    agreeToDirectDebitRequestServiceAgreement: false,
    screenToShow: 'SmartInvestAmountInput',
    bankDetailsScreenshot: false,
  };

  componentDidMount() {
    this.props.fetchSmartInvestDetails();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { amount, dayOfMonth } = nextProps.smartInvest;

    this.setState({
      smartInvestAmount: `${amount !== undefined ? amount : this.state.smartInvestAmount}`,
      smartInvestDayOfMonth: `${dayOfMonth !== undefined ? dayOfMonth : this.state.smartInvestDayOfMonth}`,
    });
  }

  onFormSubmit = () => {
    const {
      smartInvestDayOfMonth: dayOfMonth,
      smartInvestAmount: amount,
      bankAccountName,
      bankAccountNumber,
      bankAccountBsb,
    } = this.state;

    const { updateSmartInvestAndGoBackToSettings } = this.props;

    updateSmartInvestAndGoBackToSettings({
      amount,
      dayOfMonth,
      bankAccountName,
      bsb: bankAccountBsb,
      bankAccountNumber
    });
  }

  render() {
    const { user, submitButtonText, smartInvest: { fetching, saving } } = this.props;
    if (fetching) return <Loading notFullHeight/>;

    const {
      bankName,
      bankAccountName,
      bankAccountBsb,
      bankAccountNumber,
      smartInvestAmount,
      smartInvestDayOfMonth,
      agreeToDirectDebitRequest,
      agreeToDirectDebitRequestServiceAgreement,
      bankDetailsScreenshot,
    } = this.state;

    const isLessThanMinimumDeposit = value => value < minimumSIAmount
      ? `Please note, minimum desposit amount is: $${minimumSIAmount}`
      : null;

    const hasBankDetailsAlreadySet = hasNominatedBankAccount(user);
    const bankDetailsFilled = bankAccountName && bankAccountBsb && bankAccountNumber;
    const bankDetailsValid = hasBankDetailsAlreadySet || (
      bankDetailsFilled &&
      bankAccountName.length >= 3 &&
      bankName &&
      bankAccountNumber.length >= 6
    );

    const smartInvestFieldsValid =
      smartInvestAmount &&
      !isLessThanMinimumDeposit(smartInvestAmount) &&
      smartInvestDayOfMonth;

    const bankStatementScreenshotUploadRequired = !hasBankDetailsAlreadySet;

    const submitDisabled =
      !smartInvestFieldsValid ||
      !bankDetailsValid ||
      !agreeToDirectDebitRequest ||
      !agreeToDirectDebitRequestServiceAgreement ||
      (bankStatementScreenshotUploadRequired && !bankDetailsScreenshot);

    const checkBoxesDisabled = !bankDetailsValid || !smartInvestFieldsValid;

    return (
      <form className={this.props.className} data-test-reference="smart-invest-settings-form">
        <Spacing bottom="normal">
          <SubHeading>Direct Debit Details</SubHeading>
        </Spacing>
        <FormLabel forId="monthly-amount">Monthly Amount ($)</FormLabel>
        <Spacing bottom="normal">
          <TextInputWithError
            forId="monthly-amount"
            placeholder="$AUD"
            testRef="direct-debit-amount-input"
            errorTestRef="direct-debit-error-msg"
            value={smartInvestAmount}
            error={isLessThanMinimumDeposit(smartInvestAmount)}
            onChange={amount => this.setState({ smartInvestAmount: amount })}
            isCharacterValid={TextInputWithError.validation.numbersOnly}
          />
        </Spacing>
        <FormLabel forId="day-of-month">Day of month to direct debt</FormLabel>
        <Spacing bottom="x-small">
          <DayOfMonthSelect
            onChange={dayOfMonth => this.setState({ smartInvestDayOfMonth: dayOfMonth })}
            value={smartInvestDayOfMonth}
            testRef="day-of-month-input"
          />
        </Spacing>
        <FinePrint>If the selected date does not exist, or falls on a weekend, it will be direct debited on the next business day.</FinePrint>

        <Divider />

        <Spacing bottom="normal">
          <SubHeading>Bank Account</SubHeading>
        </Spacing>

        {hasBankDetailsAlreadySet
          ? <BankAccountDetailsPlainText
              { ... { bankAccountName, bankAccountNumber, bankAccountBsb } }
            />
          : <BankAccountDetailsInput
              { ... { bankAccountName, bankAccountNumber, bankAccountBsb } }
              onBankNameChange={(val) => this.setState({ bankName: val })}
              onBankAccountNameChange={(val) => this.setState({ bankAccountName: val })}
              onBankAccountBsbChange={(val) => this.setState({ bankAccountBsb: val })}
              onBankAccountNumberChange={(val) => this.setState({ bankAccountNumber: val })}
            />
        }

        {bankStatementScreenshotUploadRequired && (
          <Spacing top="large">
            <BankDetailsScreenshotUpload onChange={(file) => this.setState({ bankDetailsScreenshot: file })}/>
          </Spacing>
        )}

        <Spacing top="large">
          <DirectDebitExpandableAgreements
            testRef="direct-debit-expandable-agreements"
          />
        </Spacing>

        <Spacing bottom="normal" top="large">
          <DirectDebitRequestCheckbox
            onChange={(checked) => this.setState({ agreeToDirectDebitRequest: checked })}
            checked={this.state.agreeToDirectDebitRequest}
            values={{
              smartInvestAmount: this.state.smartInvestAmount,
              smartInvestDayOfMonth: this.state.smartInvestDayOfMonth,
              bankAccountName: this.state.bankAccountName,
              bankAccountBsb: this.state.bankAccountBsb,
              bankAccountNumber: this.state.bankAccountNumber,
              agreeToDirectDebitRequest: this.state.agreeToDirectDebitRequest,
              agreeToDirectDebitRequestServiceAgreement: this.state.agreeToDirectDebitRequestServiceAgreement,
            }}
            testRef="direct-debit-request-checkbox"
            disabled={checkBoxesDisabled}
          />
        </Spacing>

        <DirectDebitRequestServiceAgreementCheckbox
          onChange={(checked) => this.setState({ agreeToDirectDebitRequestServiceAgreement: checked })}
          checked={this.state.agreeToDirectDebitRequestServiceAgreement}
          testRef="direct-debit-request-service-agreement-checkbox"
          disabled={checkBoxesDisabled}
        />

        <Spacing top="large">
          <PrimaryButton
            fullWidth
            color={PrimaryButton.colors.SECONDARY}
            disabled={submitDisabled || saving}
            loading={saving}
            loadingText="Saving"
            onClick={(e) => {
              e.preventDefault();
              this.onFormSubmit();
            }}
            testRef="submit-button"
          >{submitButtonText}</PrimaryButton>
        </Spacing>
      </form>
    );
  }
}
