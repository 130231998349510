/* global HOT */
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { browserHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import cookie from 'react-cookie';

import { fetchUserDetails } from 'scripts/redux/actions/user';
import configureStore from 'scripts/redux/configureStore';
import authStore from 'scripts/auth/authStore';
import { enable as enableClosedTesting } from 'src/store/closedTestingActions';
import Configuration from 'scripts/constants/Configuration';
import Root from './Root';

const store = configureStore(window.__data__);
const history = syncHistoryWithStore(browserHistory, store);

const renderFunction = HOT ? ReactDOM.render : ReactDOM.hydrate;

export default async function () {
  authStore.setAuthToken(cookie.load('Authorization'));
  await store.dispatch(enableClosedTesting(!!cookie.load(Configuration.COOKIES.CLOSED_TESTING)));
  await store.dispatch(fetchUserDetails());
  renderFunction(
    <Provider store={store}>
      <Root store={store} history={history}/>
    </Provider>,
    document.querySelector('#root')
  );
};
