import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { elements as elementsPropType } from 'scripts/constants/PropTypes';
import { BsX } from 'react-icons/bs';

import $ from 'jquery';

export default class Modal extends Component {
  static propTypes = {
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object
    ]),
    sizeClass: PropTypes.string,
    id: PropTypes.string,
    body: elementsPropType,
    footer: elementsPropType,
    hideFooter: PropTypes.bool,
    hideHeader: PropTypes.bool,
    hideCloseIcon: PropTypes.bool,
    noPadding: PropTypes.bool,
    darkBackground: PropTypes.bool,
    onClose: PropTypes.func,
    modalClassName: PropTypes.string,
    testRef: PropTypes.string,
  };

  componentDidMount() {
    this._assignCallbackToOnCloseEvent();
  }

  render() {
    const {
      title,
      body,
      footer,
      id,
      sizeClass,
      hideFooter,
      hideHeader,
      hideCloseIcon,
      className,
      modalClassName,
      noPadding,
      darkBackground,
      testRef,
    } = this.props;
    const sizeCls = sizeClass ? sizeClass : 'modal-lg';

    return (
      <div
        className={classNames('modal', modalClassName, {
          'no-padding': noPadding,
          'dark-background': darkBackground
        })}
        id={id}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        data-test-reference={testRef}
      >
        <div className={classNames('modal-dialog', sizeCls)} role="document">
          <div className="modal-content">
            {!hideHeader &&
              <div className="modal-header">
                <span className="modal-title" id="myModalLabel">{title}</span>
                <span className="modal-close">
                  <span className="close-panel hidden-xs" data-dismiss="modal">
                    <span className="close-icon"> <BsX /> </span>
                  </span>
                  <span className="close-panel visible-xs close-icon" data-dismiss="modal">
                    <span className="close-icon"> <BsX /> </span>
                  </span>
                </span>
              </div>
            }
            <div className={classNames('modal-body', className)}>
              {body}
              {hideHeader && (!hideCloseIcon && <span className="close-panel" data-dismiss="modal">
                  <span className="close-icon"><BsX /></span>
                </span>)
              }
            </div>
            {footer && !hideFooter && <div className="modal-footer">{footer}</div> }
          </div>
        </div>
      </div>
    );
  }

  open = () => {
    this.$modal.modal('show');
  };

  close = () => {
    this.$modal.modal('hide');
  };

  get $modal() {
    return $(`#${this.props.id}`);
  }

  _assignCallbackToOnCloseEvent = () => {
    const { id, onClose } = this.props;
    if (onClose) {
      $(`#${id}`).on('hidden.bs.modal', function () {
        onClose();
      });
    }
  }

}
