import {
  ButtonsContainer,
  PrimaryButtonWithLessPadding,
  SecondaryButtonWithLessPadding,
  HorizontalAtMediumBreakpointContainer,
  PanelValueHeading,
  PanelValueText,
  SeeMoreLink,
  TotalValueContainer,
  WelcomePageWidgetPanel
} from 'src/components/dashboard/home/commonComponents';
import { TOTAL_FUNDS_IN_WALLET_HELP_TEXT } from 'src/components/dashboard/home/helpText';
import { depositUrl, walletUrl, withdrawUrl } from 'src/utils/pageUrls';
import { dollarDecimal } from 'src/utils/formats';
import { getBuildYourOwnPlan, getPlanFunds, getSmartInvestPlan } from 'src/utils/account';
import Popover from 'scripts/components/shared/Popover';
import SecondaryButton from 'src/design/components/button/SecondaryButton';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import { getCustomButtonColor } from './DashboardHome';

const WalletPanel = ({ className, account, user }) => {
  const bmoPlan = getBuildYourOwnPlan(account);
  const siPlan = getSmartInvestPlan(account);
  const totalValue = getPlanFunds(bmoPlan) + getPlanFunds(siPlan);

  return (
    <WelcomePageWidgetPanel
      title="Wallet"
      action={<SeeMoreLink href={walletUrl()} testRef="wallet-panel-header-link"/>}
      className={className}
    >
      <HorizontalAtMediumBreakpointContainer>
        <TotalValueContainer>
          <PanelValueHeading>Total Funds in My Wallet <Popover placement="top" content={TOTAL_FUNDS_IN_WALLET_HELP_TEXT}/></PanelValueHeading>

          <Spacing top="2x-small">
            <PanelValueText testRef="wallet-panel-total-value">{dollarDecimal(totalValue)}</PanelValueText>
          </Spacing>
        </TotalValueContainer>

        <ButtonsContainer>
          <PrimaryButtonWithLessPadding
            color={getCustomButtonColor(user)}
            responsiveSizes={{ medium: 'SMALL' }}
            link={depositUrl()}
            testRef="wallet-panel-deposit-button-link"
          >
            Deposit Funds
          </PrimaryButtonWithLessPadding>

          <Spacing top="x-small">
            <SecondaryButtonWithLessPadding
              color={SecondaryButton.colors.PRIMARY}
              responsiveSizes={{ medium: 'SMALL' }}
              link={withdrawUrl()}
              testRef="wallet-panel-withdraw-button-link"
            >
              Withdraw Funds
            </SecondaryButtonWithLessPadding>
          </Spacing>
        </ButtonsContainer>
      </HorizontalAtMediumBreakpointContainer>
    </WelcomePageWidgetPanel>
  );
};

export default WalletPanel;
