import { UserStatus } from 'scripts/constants/Cookie';
import {
  VERIFY,
  PDS,
  SETUP,
  VEDA,
  DEPOSIT,
} from 'scripts/constants/RegistrationStep';
import {
  acceptPdsUrl,
  emailVerificationUrl,
  identityVerificationUrl,
  onboardingTmdUrl,
  vedaFailUrl,
} from 'src/utils/pageUrls';
import { load } from 'src/browser/cookies';
import Configuration from 'scripts/constants/Configuration';
import history from 'src/browser/history';

export const getUserBankAccountDetails = (user) =>
  user.financialDetails &&
  user.financialDetails.bankAccountDetails ||
  ({ bankAccountName: '', BSB: '', bankAccountNumber: '' });

export const isBsbValid = (bsb) => bsb.length === 6;
export const isBsbObfuscated = (bsb) => bsb.slice(0, 3) === 'XXX';
export const isLoggedIn = (user) => !!user.id;
export const isMemberFromCookie = () => {
  const memberStatusCookie = load(Configuration.COOKIES.MEMBERSHIP_STATUS);
  return memberStatusCookie === UserStatus.Member || memberStatusCookie === UserStatus.Investor;
};
export const isInvestorFromCookie = () => load(Configuration.COOKIES.MEMBERSHIP_STATUS) === UserStatus.Investor;
export const redirectToStepPage = (user) => () => {
  switch (user.nextRegistrationStep) {
    case VERIFY: return history.push(emailVerificationUrl());
    case PDS: return history.push(acceptPdsUrl());
    case SETUP: return history.push(identityVerificationUrl());
    case VEDA: return history.push(vedaFailUrl());
    case DEPOSIT: return history.push(onboardingTmdUrl());
    default:
  }
};
