import { useState } from 'react';
import OnboardingScreen, {
  OnboardingScreenHeader,
  OnboardingScreenContent,
  OnboardingFormLabel,
} from 'src/components/oldOnboarding/common/onboardingScreen/OnboardingScreen';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import Heading from 'src/design/components/heading/Heading';
import { Formik } from 'formik';
import Divider from 'src/design/components/divider/Divider';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import SmsVerify from './SmsVerify';
import UserService from 'scripts/services/UserService';
import { getCurrentUserId } from 'scripts/auth/session';
import TextInput from 'src/design/components/textInput/TextInput';
import { validateEmailAddress } from 'src/utils/validation';
import { settingsUrl } from 'src/utils/pageUrls';

const UpdateEmailComponent = ({ onClickBack }) => {
  const [hasSentSms, setHasSentSms] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const onSubmit = async (values) => {
    setError(null);
    const userId = getCurrentUserId();
    try {
      await UserService.updateEmail(userId, values);
      setSuccess(true);
    } catch (e) {
      if (e._body) {
        if (e._body === 'This email has been registerd.') {
          setError('This email is already registered to another account.');
        } else {
          setError(e._body);
        }
      }
    }
  };

  if (success) {
    return (
      <OnboardingScreen>
        <OnboardingScreenHeader>
          <Spacing bottom="x-large">
            <Heading>Your Email has been updated</Heading>
          </Spacing>
        </OnboardingScreenHeader>
        <OnboardingScreenContent>
          <PrimaryButton
            fullWidth
            color={PrimaryButton.colors.SECONDARY}
            link={settingsUrl()}
            forceExternal
          >
            RETURN TO DASHBOARD
          </PrimaryButton>
        </OnboardingScreenContent>
      </OnboardingScreen>
    );
  }

  const validateForm = (values) => {
    return values.newEmail && validateEmailAddress(values.newEmail);
  };

  return (
    <div className="inner">
      <div className="header">
        <Spacing bottom="x-large">
          <Heading>Update Email Address</Heading>
        </Spacing>
      </div>
      <div className="header">
        <Formik
          initialValues={{
            otpValue: '',
            newEmail: '',
          }}
          onSubmit={async (values) => onSubmit(values)}
        >
          {(props) => {
            const { values, handleSubmit, setFieldValue } = props;
            return (
              <form onSubmit={handleSubmit}>
                <OnboardingFormLabel>
                  Enter your new email address
                </OnboardingFormLabel>
                <TextInput
                  placeholder="eg. abc@brickx.com"
                  value={values.newEmail}
                  onChange={(v) => {
                    setFieldValue('newEmail', v);
                  }}
                  fullWidth
                />
                <Divider />
                {hasSentSms && (
                  <SmsVerify
                    onChangeCode={(v) => {
                      setError(null);
                      setFieldValue('otpValue', v);
                    }}
                  />
                )}
                {error && (
                  <Spacing>
                    <div className="text-red">{error}</div>
                  </Spacing>
                )}
                <Spacing top="normal">
                  {!hasSentSms ? (
                    <PrimaryButton
                      fullWidth
                      color={PrimaryButton.colors.SECONDARY}
                      onClick={() => setHasSentSms(true)}
                      disabled={!validateForm(values)}
                    >
                      NEXT
                    </PrimaryButton>
                  ) : (
                    <PrimaryButton
                      fullWidth
                      color={PrimaryButton.colors.SECONDARY}
                      disabled={values.otpValue.length !== 6}
                    >
                      SUBMIT
                    </PrimaryButton>
                  )}
                </Spacing>
                <Spacing top="normal">
                  <PrimaryButton
                    fullWidth
                    color={PrimaryButton.colors.PRIMARY}
                    onClick={onClickBack}
                  >
                    CANCEL
                  </PrimaryButton>
                </Spacing>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default UpdateEmailComponent;
