import { Heading } from 'src/design/components/heading/Headings';
import PageMarginContainer from 'src/design/components/pageMarginContainer/PageMarginContainer';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import Video from 'src/design/components/video/Video';
import styles from 'src/components/landingPages/common/headingVideoSection/HeadingVideoSection.mscss';


const HeadingVideoSection = ({
  videoId,
  title,
  captionContent,
  className,
  onPlay,
  onFinish,
  promoContent,
}) => (
  <PageMarginContainer className={className}>
    <section className={styles.headingVideoSection}>
      <div className={styles.headingParagraphContainer}>
        <Spacing bottom="large">
          <Heading align="left"><h1 className={styles.heading}>{title}</h1></Heading>
        </Spacing>
        {captionContent}
      </div>
      <div className={styles.videoContainer}>
        <Video className={styles.video} videoId={videoId} onPlay={onPlay} onFinish={onFinish} />
        {promoContent
          ? <Spacing top="large">{promoContent}</Spacing>
          : null}
      </div>
    </section>
  </PageMarginContainer>
);

export default HeadingVideoSection;
