import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  property as propertyPropType,
  historicalGrowthData as historicalGrowthDataPropType,
} from 'scripts/constants/PropTypes';

import FinancialsPanel from 'scripts/components/shared/FinancialsPanel';
import HistoricalSuburbPerformance from 'scripts/components/property/summary/HistoricalSuburbPerformance';
import {
  historicalGrowthChartHeader,
  historicalGrowthChartSubHeader,
} from 'src/settings/text';

export default class HistoricalSuburbPerformanceInFinancialsPanel extends Component {
  static propTypes = {
    property: propertyPropType,
    historicalGrowthData: historicalGrowthDataPropType,
    propertyTab: PropTypes.string,
  };

  render() {
    const { property, historicalGrowthData } = this.props;

    const chartTitle = historicalGrowthChartHeader(property.propertyType);
    const suburbStatePropertyTypeText =
      historicalGrowthChartSubHeader(property);

    return (
      <FinancialsPanel
        title={chartTitle}
        linkText={suburbStatePropertyTypeText}
      >
        <HistoricalSuburbPerformance
          property={property}
          historicalGrowthData={historicalGrowthData}
        />
      </FinancialsPanel>
    );
  }
}
