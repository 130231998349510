export default {
  PROPOSED: 'proposed',
  CONFIRMED: 'confirmed',
  SUCCEED: 'succeed',
  TRADE_TYPE: {
    BUY: 'buy',
    PRE_ORDER: 'pre-order',
    SELL: 'sell'
  },
  TRADE_MODE: {
    BUY: 'buy',
    SELL: 'sell',
    VIEW: 'view'
  }
};
