import { Component } from 'react';
import { connect } from 'react-redux';
import DocumentMeta from 'react-document-meta';
import PropTypes from 'prop-types';
import { DEPOSIT_META } from 'scripts/metadata/account';
import { accountSelector } from 'scripts/redux/selectors/account';
import { bafmSelector } from 'scripts/redux/selectors/bafm';
import {
  bankTransferMoneyArrivalDate,
  truncate,
} from 'scripts/utilities/helpers';
import { bpaySelector } from 'scripts/redux/selectors/bpay';
import { clean, dollar, numberDecimal } from 'scripts/utilities/formatters';
import { depositFunds } from 'scripts/redux/actions/wallet';
import { feeFreePromotionStatusSelector } from 'scripts/redux/selectors/market';
import {
  boVedaFailedUrl,
  feesPromo2018TermsUrl,
  smsfAndTrustInReviewUrl,
} from 'src/utils/pageUrls';
import { fetchBafm } from 'scripts/redux/actions/bafm';
import { fetchFeeFreePromotionStatus } from 'scripts/redux/actions/market';
import { getBpayDetails } from 'scripts/redux/actions/bpay';
import { memberSelector } from 'scripts/redux/selectors/member';
import { modalSelector } from 'scripts/redux/selectors/modals';
import { sendContinueDeposit } from 'scripts/redux/actions/segment/events/depositEvents';
import { trackDepositAmountSelectAttempt } from 'src/tracking/onboarding';
import {
  user as userPropType,
  modal as modalPropType,
  bpay as bPayPropType,
  bafm as bafmPropType,
  feeFreePromotionStatus as feeFreePromotionStatusPropType,
} from 'scripts/constants/PropTypes';
import { userSelector } from 'scripts/redux/selectors/user';
import DepositMethod from 'scripts/constants/DepositMethod';
import InfoBox from 'scripts/components/shared/InfoBox';
import InfoBox2 from 'src/design/components/infoBox/InfoBox.jsx';
import Loading from 'src/components/loading/Loading';
import Modal from 'scripts/components/shared/Modal';
import Styledlink from 'src/design/components/hyperlink/Styledlink';
import Wallet from 'scripts/constants/Wallet';
import withLegacyModals from 'src/decorators/withLegacyModals';
import styled from 'styled-components';
import ModalType from 'scripts/constants/ModalType';
import {
  isSmsfAndTrustUserProcessed,
  isUserBoAllVerified,
} from 'scripts/utilities/userAccountHelper';
import history from 'src/browser/history';

const UxDepositMethod = { MODAL: 'MODAL', REDIRECT: 'REDIRECT' };

const mapStateToProps = (state) => ({
  ...userSelector(state),
  ...accountSelector(state),
  ...modalSelector(state),
  ...bpaySelector(state),
  ...bafmSelector(state),
  ...feeFreePromotionStatusSelector(state),
  ...memberSelector(state),
});

const DollarInputWrapper = styled.div`
  margin: 30px 0;

  background-color: #eee;
  border-radius: var(--bx-radius);
  overflow: hidden;

  display: grid;
  grid-template-columns: calc(100% - 180px) 180px;

  .inputWrapper::before {
    font-size: 20px;
    content: '$';
    display: inline-block;
    padding: 0px;
    width: 20px;
    text-align: right;
    transform: translateY(-4px);
  }

  input {
    font-size: 30px;
    font-font-weight: 800;
    width: calc(100% - 20px);
    background-color: transparent;
    border: none;
  }

  input:focus {
    background-color: transparent;
  }

  .buttonWrapper {
    .button {
      width: 100%;
      height: 100%;
      border-radius: 0;
    }
  }

  @media only screen and (max-width: 768px) {
    & {
      grid-template-columns: 1fr;
    }
    input {
      margin: 10px 0;
    }
  }
`;

const PaymentSelector = styled.div`
  margin: 10px 0 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  .payment-panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
    box-sizing: border-box;
    border-radius: 20px;
    box-shadow: var(--bx-shadow);
    border: 3px solid transparent;
    &:hover:not(.payment-panel-selected) {
      cursor: pointer;
      border: 3px solid #eee;
    }
    img {
      margin: 20px 0;
    }
    .payment-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        font-size: 20px;
        font-weight: 800;
      }
    }
    .estimate-arrival {
      font-size: 20px;
      padding: 8px 0;
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      font-weight: 500;
      span {
        color: var(--bx-color---primary);
        text-transform: uppercase;
      }
    }
    ul {
      padding: 16px;
      padding-bottom: 0;
      margin-bottom: 0;
    }
    .poli-disclaimer {
      padding: 0 !important;
      margin: 10px 0;
      text-align: left;
    }
  }
  .payment-panel-selected {
    border: 3px solid #999;
  }

  @media only screen and (max-width: 768px) {
    & {
      grid-template-columns: 1fr;
      width: 100%;
    }
    .payment-panel {
      padding: 10px 20px;
    }
    .payment-title {
      span {
        font-size: 16px !important;
        font-weight: 800;
      }
    }
  }
`;

const IframeWrapper = styled.div`
  iframe {
    background-color: #eee;
    border-radius: 10px;
    padding: 20px;
  }
`;

@withLegacyModals
@connect(mapStateToProps, {
  depositFunds,
  getBpayDetails,
  fetchBafm,
  fetchFeeFreePromotionStatus,
})
export default class Deposit extends Component {
  static propTypes = {
    user: userPropType,
    bpay: bPayPropType.isRequired,
    modal: modalPropType,
    depositFunds: PropTypes.func.isRequired,
    getBpayDetails: PropTypes.func.isRequired,
    fetchBafm: PropTypes.func.isRequired,
    bafm: bafmPropType,
    fetchFeeFreePromotionStatus: PropTypes.func.isRequired,
    feeFreePromotionStatus: feeFreePromotionStatusPropType,
    memberStatus: PropTypes.shape({
      isMember: PropTypes.bool,
    }),
  };

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  state = {
    paymentMethod: {
      default: false,
      showDetails: false,
    },
    amount: '',
    isLoading: false,
    initialValue: true,
    amountExceeded: false,
    amountTooLow: false,
    poliUxMethod: UxDepositMethod.MODAL,
  };

  componentDidMount() {
    const { fetchFeeFreePromotionStatus, user } = this.props;

    const isUserBoAllVerified_ = isUserBoAllVerified(user);
    if (!isUserBoAllVerified_) {
      history.push(boVedaFailedUrl());
    }
    const isSmsfAndTrustUserProcessed_ = isSmsfAndTrustUserProcessed(user);
    if (!isSmsfAndTrustUserProcessed_) {
      history.push(smsfAndTrustInReviewUrl());
    }

    const pageWidth = window.innerWidth;
    const mobileSize = 991;
    const isMobile = pageWidth < mobileSize;

    this.setState({
      poliUxMethod: isMobile ? UxDepositMethod.REDIRECT : UxDepositMethod.MODAL,
    });

    const { fetchBafm } = this.props;

    fetchBafm();
    fetchFeeFreePromotionStatus();
    window.scrollTo(0, 0);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { poliUxMethod } = this.state;
    const { modal } = nextProps;
    if (modal) {
      if (poliUxMethod === UxDepositMethod.REDIRECT) {
        window.location.href = modal.data.url;
      } else {
        if (this.props.modal) {
          if (
            modal.type !== this.props.modal.type &&
            modal.type === ModalType.DEPOSIT
          ) {
            this.refs.modal.open();
          }
        } else if (modal.type === ModalType.DEPOSIT) {
          this.refs.modal.open();
        }
      }
    }
  }

  componentDidUpdate() {
    this._handleBPaySuccessRedirection();
  }

  _onSelectPaymentMethod = (isPoli) => {
    if (isPoli) {
      this.setState({
        paymentMethod: {
          default: true,
          showDetails: true,
        },
      });
      return;
    }
    this.setState({
      paymentMethod: {
        default: false,
        showDetails: true,
      },
    });
    return;
  };
  isMember = () => this.props.memberStatus && this.props.memberStatus.isMember;
  _amountExceeded(amount) {
    return amount > Wallet.MAXIMUM_DEPOSIT_AMOUNT;
  }
  _amountTooLow(amount) {
    return amount < Wallet.MINIMUM_DEPOSIT_AMOUNT && !this.isMember();
  }
  _amountTooLowMember(amount) {
    return amount < 1 && this.isMember() && this.state.paymentMethod.default;
  }
  _validateAmount(amount) {
    return (
      !this._amountExceeded(amount) &&
      !this._amountTooLow(amount) &&
      !this._amountTooLowMember(amount)
    );
  }
  _onAmountChange(amount) {
    this.setState({
      amount: amount,
      initialValue: false,
    });
  }
  clearValidationErrors() {
    this.setState({
      amountExceeded: false,
      amountTooLow: false,
    });
  }
  _updateStateWithValidation(amount) {
    const cleanedAmount = amount::clean();
    const amountExceeded = this._amountExceeded(cleanedAmount);
    const amountTooLow = this._amountTooLow(cleanedAmount);
    const amountTooLowMember = this._amountTooLowMember(cleanedAmount);
    this.setState({
      amountExceeded,
      amountTooLow,
      amountTooLowMember,
    });
  }
  _onAmountBlur = (e) => {
    this.setState({
      amount: e.target.value::numberDecimal(),
    });
  };
  _onPaymentMethodChange = () => {
    this.setState({
      paymentMethod: {
        default: !this.state.paymentMethod.default,
        showDetails: this.state.paymentMethod.showDetails,
      },
    });
  };
  _onFormSubmit(e) {
    e.preventDefault();
    const { amount, paymentMethod, poliUxMethod } = this.state;
    const usePoli = paymentMethod.default;
    const cleanedAmount = amount::clean();
    const method = this.state.paymentMethod.default
      ? DepositMethod.POLI
      : DepositMethod.BPAY;
    trackDepositAmountSelectAttempt({
      amount: amount,
      product: 'Build My Own',
      paymentMethod: method,
    });
    this._updateStateWithValidation(amount);
    this._onAmountChange(amount);
    if (this._validateAmount(cleanedAmount)) {
      if (this.state.paymentMethod.default) {
        sendContinueDeposit({ method: DepositMethod.POLI, amount: amount });

        const { depositFunds } = this.props;
        depositFunds({
          amount: cleanedAmount::truncate(),
        });
      } else {
        sendContinueDeposit({ method: DepositMethod.BPAY, amount: amount });

        const { getBpayDetails } = this.props;
        getBpayDetails();
      }

      if (usePoli && poliUxMethod === UxDepositMethod.REDIRECT) {
        this.setState({ isLoading: false });
      } else {
        this.setState({ isLoading: true });
      }
    }
  }
  _handleBPaySuccessRedirection = () => {
    const { bpay } = this.props;
    const { isLoading, paymentMethod } = this.state;
    const useBpay = !paymentMethod.default;
    if (isLoading && useBpay && bpay.refNumber) {
      this._redirectTo('/account/deposit/show-bpay');
    }
  };
  _redirectTo = (path) => {
    const { router } = this.context;
    router.push(path);
  };
  render() {
    const { modal, bafm, feeFreePromotionStatus, user } = this.props;
    const {
      isLoading,
      amountExceeded,
      amountTooLow,
      amountTooLowMember,
      paymentMethod,
      amount,
    } = this.state;
    // const usePoli = paymentMethod.default;
    const useBpay = !paymentMethod.default;
    const isAccountActive = 'accountActive' in user ? user.accountActive : true;
    if (!bafm || !feeFreePromotionStatus) {
      return <Loading />;
    }
    const feeFreePromoContent = (
      <span>
        Buy Bricks today to take advantage of the No Fees to Buy Bricks promo.
        Promo ends 11.59pm, 20 Nov.{' '}
        <Styledlink href={feesPromo2018TermsUrl()}>See T&Cs</Styledlink>
      </span>
    );
    return (
      <div id="deposit" className="deposit">
        <DocumentMeta {...DEPOSIT_META} />
        <div className="container">
          {feeFreePromotionStatus.enabled && (
            <InfoBox>{feeFreePromoContent}</InfoBox>
          )}
          <div className="row">
            <div className="col-md-12">
              <h1>
                <strong>Deposit Funds</strong>
              </h1>
              {!isAccountActive ? (
                <InfoBox2 critical>
                  Sorry, you have deactivated your account. To deposit funds,
                  please sign up for a new account. Please contact us with any
                  questions at{' '}
                  <a href="mailto:info@brickx.com">info@brickx.com</a>.
                </InfoBox2>
              ) : (
                <div className="white-box">
                  <form
                    onSubmit={::this._onFormSubmit}
                    data-test-reference="deposit-form"
                  >
                    <div className="row form-row">
                      <div className="col-md-8 col-md-offset-2">
                        <DollarInputWrapper>
                          <div className="inputWrapper">
                            <input
                              type="number"
                              value={amount}
                              placeholder="0"
                              onChange={(e) => {
                                this._onAmountChange(e.target.value);
                                this.clearValidationErrors();
                              }}
                              data-test-reference="amount-input"
                            />
                          </div>
                          <div className="buttonWrapper">
                            <button
                              id="continue-deposit-button"
                              className="button orange-button right-arrow-button button-continue-deposit"
                            >
                              {isLoading ? 'PLEASE WAIT' : 'CONTINUE'}
                            </button>
                          </div>
                        </DollarInputWrapper>
                        {amountExceeded && (
                          <div
                            className="error-message"
                            data-test-reference="amount-input-error"
                          >
                            Enter a valid amount (maximum{' '}
                            {Wallet.MAXIMUM_DEPOSIT_AMOUNT::dollar()}).
                          </div>
                        )}
                        {amountTooLow && (
                          <div
                            className="error-message"
                            data-test-reference="amount-input-error"
                          >
                            Minimum initial deposit is{' '}
                            {Wallet.MINIMUM_DEPOSIT_AMOUNT::dollar()}.
                          </div>
                        )}
                        {amountTooLowMember && (
                          <div
                            className="error-message"
                            data-test-reference="amount-input-error"
                          >
                            Minimum amount is $1 for the POLi payment method.
                          </div>
                        )}
                      </div>
                    </div>
                    <PaymentSelector>
                      {/* <div
                        id="poli-payment-panel"
                        className={`payment-panel ${
                          usePoli ? ' payment-panel-selected' : ''
                        }`}
                        onClick={() => this._onSelectPaymentMethod(true)}
                        data-test-reference="poli-payment-panel"
                      >
                        <div className="payment-title">
                          <img
                            src="/static/images/deposit/aupost_poli_logo.jpeg"
                            width="120"
                          />
                          <span>Immediate Funds (Poli)</span>
                        </div>
                        {usePoli && (
                          <div>
                            <div className="estimate-arrival">
                              ESTIMATED ARRIVAL:{' '}
                              <span>
                                {bafm.bafmLimitReached
                                  ? `${bankTransferMoneyArrivalDate()}*`
                                  : 'INSTANT'}
                              </span>
                            </div>
                            <ul>
                              <li>Access your funds immediately*</li>
                              <li>Quick and easy to pay online</li>
                              <li>No registration needed</li>
                              <li>No fees</li>
                            </ul>
                            <div className="poli-disclaimer">
                              {bafm.bafmLimitReached
                                ? '*Your account has reached the advanced funds allocation of $20,000. Any additional funds transferred through POLi will be delivered to your BrickX Wallet after the transfer is received (1-2 business days).'
                                : '*Up to $20,000. Remaining funds delivered after your transfer is received (1-2 business days).'}
                            </div>
                          </div>
                        )}
                      </div> */}
                      <div
                        id="bpay-payment-panel"
                        className={`payment-panel ${
                          useBpay ? ' payment-panel-selected' : ''
                        }`}
                        onClick={() => this._onSelectPaymentMethod(false)}
                        data-test-reference="bpay-payment-panel"
                      >
                        <div className="payment-title">
                          <img
                            src="/static/images/deposit/bpay_logo.png"
                            width="100"
                          />
                          <span>Bank Transfer (BPAY)</span>
                        </div>
                        {useBpay && (
                          <div>
                            <div className="estimate-arrival">
                              ESTIMATED ARRIVAL:{' '}
                              <span>{bankTransferMoneyArrivalDate()}</span>
                            </div>
                            <ul>
                              <li>BPAY details provided on the next page</li>
                              <li>Funds delivered in 1-2 business days</li>
                              <li>No registration needed</li>
                              <li>No fees</li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </PaymentSelector>
                  </form>
                </div>
              )}
            </div>
          </div>
          <Modal
            ref="modal"
            className="modal-poli"
            sizeClass="modal-lg"
            body={
              <div id="poli-modal-popup" className="text-center">
                <div className="row">
                  <IframeWrapper>
                    {modal && modal.data ? (
                      <iframe
                        className="poli-iframe"
                        src={modal.data.url}
                        frameBorder="0"
                      ></iframe>
                    ) : (
                      ''
                    )}
                  </IframeWrapper>
                </div>
              </div>
            }
          />
        </div>
      </div>
    );
  }
}
