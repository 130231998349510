import {
  START_SUBMITTING,
  STOP_SUBMITTING,
} from 'src/components/landingPages/smsf/smsfActions';

const defaultState = {
  smsfSubmitting: false,
};

export const smsfSubmittingSelector = (state) => ({ smsfSubmitting: state.smsf.smsfSubmitting });

const reducer = (state = defaultState, { type }) => {
  switch (type) {
    case START_SUBMITTING: return { ...state, ...{ smsfSubmitting: true } };
    case STOP_SUBMITTING: return { ...state, ...defaultState };
    default: return state;
  }
};

export default reducer;
