import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Popover from 'scripts/components/shared/Popover';
import classNames from 'classnames';
import ReactPlaceholder from 'react-placeholder';
import _ from 'lodash';

import {
  account as accountPropType,
  property as propertyPropType,
  orderAggregated as orderPropType,
} from 'scripts/constants/PropTypes';

import RecentlySold from 'scripts/components/property/RecentlySold';
import BrickValuationBar from 'scripts/components/property/trade/BrickValuationBar';
import OrderBookTableBodyPlaceholder from 'scripts/components/placeholders/trade/OrderBookTableBodyPlaceholder';

import { scrollTo } from 'scripts/utilities/helpers';
import {
  sendRecentTransactionsTabClickEvent,
  sendBricksAvailableTabClickEvent,
  sendSeeLessAvailableBricksLinkClickEvent,
  sendSeeMoreAvailableBricksLinkClickEvent,
} from 'scripts/redux/actions/segment/events/tradeEvents';
import Constants from 'scripts/constants/Constants';
import Configuration from 'scripts/constants/Configuration';
import {
  brickPrice,
  number,
  percentDecimalWithSign,
} from 'scripts/utilities/formatters';
import { renderCode } from 'src/settings/properties';

export default class OrderBookTrade extends Component {
  static propTypes = {
    account: accountPropType,
    property: propertyPropType.isRequired,
    ordersSell: PropTypes.arrayOf(orderPropType),
    tradeType: PropTypes.string.isRequired,
  };

  state = {
    showAllOrders: false,
    tabsArr: [true, false],
  };

  render() {
    const { account, property, ordersSell, tradeType } = this.props;

    const isIndependentValued = !property.financials.isIndependentValued;
    const brickValueText = isIndependentValued
      ? 'Initial Brick Price'
      : 'Brick Valuation';
    const discountText = brickValueText;
    const discountPopoverText = `The percent difference between the Brick Price and the ${brickValueText}. Buying below the ${brickValueText} is considered Below Valuation, and is represented by -.  Buying above the ${brickValueText} is considered Above Valuation, and is represented by +.`;

    const numOfOrders = (ordersSell && ordersSell.length) || 0;
    const noBricksAvailable = ordersSell && numOfOrders === 0;
    return (
      <div className="table-orders">
        <div>
          <BrickValuationBar
            property={property}
            showIcon={Constants.TRUE}
            showValuationDate={Constants.TRUE}
          />
          <div className="nav-tabs hidden-xs">
            <div
              className={classNames('tab', { active: this.state.tabsArr[0] })}
              onClick={() => this._onTabClick(0)}
            >
              Bricks Available to Buy
            </div>
            <div
              className={classNames('tab recently-sold-tab', {
                active: this.state.tabsArr[1],
              })}
              onClick={() => this._onTabClick(1)}
            >
              Recent Transactions
            </div>
          </div>

          <div
            className={classNames('orders-trades-tab', {
              active: this.state.tabsArr[0],
            })}
          >
            <table className="table table-bordered table-striped table-hover bricks-available">
              <thead>
                <tr>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th className="hidden-xs">
                    % Difference from
                    <br />
                    {discountText}
                    &nbsp;
                    <Popover placement="top" content={discountPopoverText} />
                  </th>
                  <th className="visible-xs">
                    -/+ &nbsp;
                    <Popover placement="top" content={discountPopoverText} />
                  </th>
                </tr>
              </thead>

              {noBricksAvailable ? (
                <tbody>
                  <tr>
                    <td className="text-center no-bricks" colSpan="3">
                      Sorry, there are 0 Bricks currently available in{' '}
                      {renderCode(property.propertyCode)}
                    </td>
                  </tr>
                </tbody>
              ) : (
                <ReactPlaceholder
                  ready={ordersSell && account}
                  customPlaceholder={<OrderBookTableBodyPlaceholder />}
                >
                  <tbody>
                    {this.state.showAllOrders
                      ? ordersSell &&
                        ordersSell
                          .slice(
                            0,
                            Configuration.MAXIMUM_NUM_TRANSACTIONS_SHOWN
                          )
                          .map((_order, index) => {
                            return (
                              <tr key={index}>
                                <td
                                  className={`text-left sell-order-${index}-quantity`}
                                >
                                  {_order.quantity::number()}
                                </td>
                                <td
                                  className={`text-left sell-order-${index}-brick-price`}
                                >
                                  <span>{_order.price::brickPrice()}</span>
                                </td>
                                <td
                                  className={`text-left sell-order-${index}-discount`}
                                >
                                  {((_order.price -
                                    property.financials.brickValue) /
                                    property.financials
                                      .brickValue)::percentDecimalWithSign()}
                                </td>
                              </tr>
                            );
                          })
                      : ordersSell &&
                        ordersSell.map((_order, index) => {
                          if (
                            index < Configuration.DEFAULT_NUM_TRANSACTIONS_SHOWN
                          ) {
                            return (
                              <tr key={index}>
                                <td
                                  className={`text-left sell-order-${index}-quantity`}
                                >
                                  {_order.quantity::number()}
                                </td>
                                <td
                                  className={`text-left sell-order-${index}-brick-price`}
                                >
                                  <span>{_order.price::brickPrice()}</span>
                                </td>
                                <td
                                  className={`text-left sell-order-${index}-discount`}
                                >
                                  {((_order.price -
                                    property.financials.brickValue) /
                                    property.financials
                                      .brickValue)::percentDecimalWithSign()}
                                </td>
                              </tr>
                            );
                          }
                        })}
                    {numOfOrders >
                      Configuration.DEFAULT_NUM_TRANSACTIONS_SHOWN && (
                      <tr key={numOfOrders + 1}>
                        <td
                          className="show-button"
                          colSpan="3"
                          onClick={this._expand}
                        >
                          {this.state.showAllOrders
                            ? '- Show Less'
                            : '+ Show More'}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </ReactPlaceholder>
              )}
            </table>
          </div>

          <div
            className={classNames('orders-trades-tab', {
              active: this.state.tabsArr[1],
            })}
          >
            <RecentlySold
              property={property}
              isPreOrderProperty={false}
              tradeType={tradeType}
              account={account}
            />
          </div>

          <div className="note">
            Note: Bricks are bought starting with the lowest available price.
          </div>
        </div>
      </div>
    );
  }

  _resetTabs = () => {
    this.setState({
      tabsArr: [false, false],
    });
  };

  _onTabClick = (index) => {
    this._resetTabs();
    const { tabsArr } = this.state;
    this.setState({
      tabsArr: _.map(tabsArr, function (n, i) {
        return i === index ? true : false;
      }),
    });

    const { account, property, tradeType } = this.props;
    if (index === 0) {
      sendBricksAvailableTabClickEvent(
        tradeType,
        property.propertyCode,
        account
      );
    } else if (index === 1) {
      sendRecentTransactionsTabClickEvent(
        tradeType,
        property.propertyCode,
        account
      );
    }
  };

  _expand = (e) => {
    e.preventDefault();
    const { account, property, tradeType } = this.props;
    if (this.state.showAllOrders) {
      scrollTo('.property-nav');
      sendSeeLessAvailableBricksLinkClickEvent(
        tradeType,
        property.propertyCode,
        account
      );
    } else {
      sendSeeMoreAvailableBricksLinkClickEvent(
        tradeType,
        property.propertyCode,
        account
      );
    }
    this.setState({
      showAllOrders: !this.state.showAllOrders,
    });
  };
}
