import { css } from 'styled-components';

export const poppingCardBorderStyles = css`
  border-radius: var(--bx-ms---widget-border-radius);
  box-shadow: var(--bx-emb-shadow);
  background-color: var(--bx-color---white);
`;

export const poppingCardBorderTopFeatureStyles = css`
  border-top: var(--bx-emb-widget-top-highlight);
`;
