export function HarmonyConfig() {
  const location = window.location.hostname;
  const sourceOfTruth = 'GNAF';

  if (location === 'dev.brickx.io') {
    return {
      username: 'dev_brickx',
      password: 'HxDweIqCWewc52AZ4H5rAndm3WqHDdRV',
      env: 'DEV',
      sourceOfTruth,
    };
  } else if (location === 'staging.brickx.io') {
    return {
      username: 'brickx_staging',
      password: 'XbwWrL9PqQqvG0ggBzwh71KwEFtzd0ak',
      env: 'STAGING',
      sourceOfTruth,
    };
  } else if (location === 'www.brickx.com') {
    return {
      username: 'brickx_live',
      password: 'oGCV58B7MOWa7GcCffQenPvPlI54OWql',
      env: 'LIVE',
      sourceOfTruth,
    };
  } else if (location === 'localhost') {
    return {
      username: 'brickxliveruser',
      password: 'CwB78BGMrzs8QWo82jZ8uwknqcL2tQWd',
      env: 'LOCAL',
      sourceOfTruth,
    };
  } else if (location === 'poochie.brickx.io') {
    return {
      username: 'brickx_poochie',
      password: 'Clzqml6AprWT0KU6f8XqWWkfKnGgwgt3',
      env: 'POOCHIE',
      sourceOfTruth,
    };
  }
}
