import { Heading } from 'src/design/components/heading/Headings';
import Paragraph from 'src/design/components/bodyText/Paragraph';
import PageMarginContainer from 'src/design/components/pageMarginContainer/PageMarginContainer';
import SecondaryButton from 'src/design/components/button/SecondaryButton';
import Spacing from 'src/design/styleguide/spacing/Spacing';
// import ReferralSessionTimedOutDriver from 'src/components/sessionTimeout/referralSessionTimedOutDriver/ReferralSessionTimedOutDriver';

// import { isMemberFromCookie } from 'src/utils/user';
import { loginUrl } from 'src/utils/pageUrls';

import styles from 'src/components/sessionTimeout/sessionTimedOutPage/SessionTimedOutPage.mscss';

const SessionTimedOutPage = () => (
  <PageMarginContainer className={styles.pageContainer}>
    <Spacing bottom="normal">
      <Heading>
        <h1 className={styles.heading}>Thanks for<br /> stopping by</h1>
      </Heading>
    </Spacing>
    <Spacing bottom="x-large">
      <Paragraph>You are now safely logged out.</Paragraph>
    </Spacing>
    <SecondaryButton
      color={SecondaryButton.colors.PRIMARY}
      link={loginUrl()}
      testRef="log-back-in-cta"
    >Log Back In</SecondaryButton>
    {/* {isMemberFromCookie() && <ReferralSessionTimedOutDriver testRef="session-timed-out-referral-driver"/>} */}
  </PageMarginContainer>
);

export default SessionTimedOutPage;
