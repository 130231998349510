import classNames from 'classnames';
import Icon from 'src/design/components/icon/Icon';
import styles from 'src/design/components/infoBox/InfoBox.mscss';

const InfoBox = ({ children, className, critical, testRef }) => (
  <div
    className={classNames(
      styles.infoBox,
      { [styles['infoBox--critical']]: critical },
      className
    )}
    data-test-reference={testRef}
  >
    <div
      className={classNames(styles.icon, {
        [styles['icon--critical']]: critical,
      })}
    >
      <Icon
        type={Icon.types.info_outline}
        className={classNames(styles.caret, styles.centered)}
      />
    </div>
    <div className={styles.description}>
      <div className={classNames(styles.centered, styles.noLeftMargin)}>
        {children}
      </div>
    </div>
  </div>
);

export default InfoBox;
