import { Fragment } from 'react';
import { connect } from 'react-redux';
import { fetchProperties as fetchPropertiesAction } from 'scripts/redux/actions/properties';
import { propertiesSelector } from 'scripts/redux/selectors/properties';
import Loading from 'src/components/loading/Loading';
import PortfolioPropertiesTable from 'src/components/dashboard/portfolio/PortfolioPropertiesTable';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import WidgetPanel from 'src/components/dashboard/common/WidgetPanel';
import withState from 'src/decorators/withState';

const PropertiesPanel = withState({
  componentDidMount: ({ fetchProperties }) => fetchProperties(),
  Component: ({ portfolio, properties, checkboxes }) => {
    return (
      <WidgetPanel
        title="My Properties"
        contentTestRef="properties-panel-content"
      >
        {properties.length ? (
          <Fragment>
            <Spacing bottom="2x-large">{checkboxes}</Spacing>
            <PortfolioPropertiesTable
              portfolio={portfolio}
              properties={properties}
            />
          </Fragment>
        ) : (
          <Loading notFullHeight />
        )}
      </WidgetPanel>
    );
  },
});

export default connect(propertiesSelector, {
  fetchProperties: fetchPropertiesAction,
})(PropertiesPanel);
