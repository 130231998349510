import classNames from 'classnames';
import styles from 'src/design/components/formLabel/FormLabel.mscss';

const FormLabel = ({ className, children, forId, testRef }) =>
  <label
    className={classNames('sg-help--text', styles.formLabel, className)}
    htmlFor={forId}
    data-test-reference={testRef}
  >{children}</label>;

export default FormLabel;
