import React from 'react';
import { Bar, Line } from 'react-chartjs-2';
import 'chart.js/auto';
import 'chartjs-adapter-moment';
import Colors from 'src/themes/brickx/colors';

class HelpersClass {
  generateSimpleTimeseriesConfigs({
    timeseries,
    datasets,
    labels,
    label,
    lineColor,
    timeKey,
    valueKey,
    isYDollar,
  }) {
    return {
      type: 'line',
      data: {
        datasets: datasets || [
          {
            label: label || 'Value',
            data: timeseries.map((ele) => ele[valueKey || 'value']),
            borderColor: lineColor || Colors.primary,
            backgroundColor: lineColor || Colors.primary,
            tension: 0.2,
          },
        ],
        labels: labels || timeseries.map((ele) => ele[timeKey || 'date']),
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'month',
              unitStepSize: 1,
              tooltipFormat: 'MMM YY',
              displayFormats: {
                month: 'MMM YY',
              },
            },
            grid: {
              display: false,
            },
          },
          y: {
            ...(isYDollar
              ? {
                  ticks: {
                    callback: (v) => `$${v.toFixed(2)}`,
                  },
                }
              : {}),
          },
        },
        plugins: {
          legend: {
            labels: {
              borderRadius: 2,
            },
          },
        },
      },
    };
  }
}

export const Helpers = new HelpersClass();

const ChartJsBarWrapper = (props) => {
  return <Bar {...props} />;
};

export const ChartJsLineWrapper = (props) => {
  return <Line {...props} />;
};

export default ChartJsBarWrapper;
