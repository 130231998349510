import { Heading, HeadingXSmall } from 'src/design/components/heading/Headings';
import { transactionFeeText } from 'src/settings/text';
import {
  whatReportsWillIRecieveUrl,
  feesAndTaxesHelpUrl,
  brickXStructureUrl,
} from 'src/utils/pageUrls';
import ContentContainer from
  'src/design/styleguide/contentContainer/ContentContainer';
import Paragraph from 'src/design/components/bodyText/Paragraph';
import StyledBodylink from 'src/design/components/hyperlink/StyledBodylink';
import styles from 'src/components/landingPages/smsf/topFaqsSection/TopFAQsSection.mscss';

const Faq = ({ title, linkUrl, children }) => (
  <div className={styles.faqItem}>
    <HeadingXSmall className={styles['faqItem--title']}><h6>{title}</h6></HeadingXSmall>
    <Paragraph className={styles['faqItem--paragraph']}>
      {children} <StyledBodylink href={linkUrl}>Read more</StyledBodylink>
    </Paragraph>
  </div>
);

const TopFAQsSection = (props) => (
  <ContentContainer
    size="medium"
    {...props}
  >
    <div className={styles.topFAQsSection}>
      <Heading align="center"><h2>Top FAQs</h2></Heading>

      <div className={styles.faqListing}>

        <Faq title="What Reports will I receive?" linkUrl={whatReportsWillIRecieveUrl()}>
          Once your BrickX Account is set up, all your investment information and reporting is available through the Account Dashboard, including your portfolio performance, transaction history, distribution payments, transaction confirmations, financial year-end reports and tax statements.
        </Faq>

        <Faq title="What are the Fees?" linkUrl={feesAndTaxesHelpUrl()}>
          BrickX charges a {transactionFeeText()} transaction fee when you buy Bricks and a {transactionFeeText()} transaction fee when you sell Bricks. Whether you hold your Bricks for 2 months or 2 years, you’ll only ever pay {transactionFeeText()} of the transaction value each time you buy and sell. There are no funds under management, performance, contribution, service, or withdrawal fees.
        </Faq>

        <Faq title="How is BrickX structured?" linkUrl={brickXStructureUrl()}>
          The BrickX Platform is a managed investment scheme that is registered with ASIC. Each BrickX property is held in a separate trust by an external Trustee, BrickX Financial Services Limited (BrickX Financial Services) who holds AFSL No. 494878.  BrickX is the owner of the technology and website behind the BrickX Platform.
        </Faq>
      </div>
    </div>
  </ContentContainer>
);

export default TopFAQsSection;
