import * as breakpoints from 'src/design/styleguide/common/breakpoints';

const unknownBreakpoint = () => {};
const baseBreakpoint = (x) => x;
const getBreakpoint = (breakpoint) => ({
  base: baseBreakpoint,
  ...breakpoints,
})[breakpoint] || unknownBreakpoint;

const generateResponsiveStyles = (value, generateStyles) => typeof value === 'string'
  ? generateStyles(value)
  : Object.keys(value)
    .map((breakpoint) => getBreakpoint(breakpoint)(generateStyles(value[breakpoint])));

export default generateResponsiveStyles;
