import { createAction, handleActions } from 'redux-actions';

import UserService from 'scripts/services/UserService';
import update from 'immutability-helper';

const INDIVIDUAL_TAX_REPORT_URL = 'scripts/account/INDIVIDUAL_TAX_REPORT_URL';

export const individualTaxReportUrl = createAction(INDIVIDUAL_TAX_REPORT_URL);

export function fetchIndividualTaxReportUrl(year) {
  return async function (dispatch, getState) {
    const { user } = getState();
    const payload = await UserService.getIndividualTaxReportUrl(user.id, year);
    return dispatch(individualTaxReportUrl(payload));
  };
}
const reducer = handleActions({
  [INDIVIDUAL_TAX_REPORT_URL]: (state, action) => update(state, {
    tax: {
      $set: action.payload
    }
  }),
}, {
  tax: null
});

export default reducer;
