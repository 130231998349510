import { logout } from 'scripts/redux/actions/user';

import Configuration from 'scripts/constants/Configuration';
import { _getCurrentUser } from 'scripts/interceptors/interceptorHelper';
import { isUserLoggedIn } from 'scripts/utilities/userAccountHelper';


export const requireUserLoginInterceptor = (store, nextState, replaceState) => {
  const user = _getCurrentUser(store);
  const loggedIn = isUserLoggedIn(user);
  if (!loggedIn) {
    replaceState({ pathname: Configuration.PAGE_URLS.ONBOARDING.LOGIN, state: { nextPathname: nextState.location.pathname } });
  }
};

export const logoutInterceptor = (store, nextState, replaceState) => {
  const user = _getCurrentUser(store);
  const loggedIn = isUserLoggedIn(user);
  if (loggedIn) {
    replaceState({ pathname: Configuration.PAGE_URLS.HOME_PAGE, state: { nextPathname: nextState.location.pathname } });
    store.dispatch(logout());
  } else {
    replaceState({ pathname: Configuration.PAGE_URLS.ONBOARDING.LOGIN, state: { nextPathname: nextState.location.pathname } });
  }
};

export const requireUserLogoutInterceptor = (store, nextState, replaceState) => {
  const user = _getCurrentUser(store);
  const loggedIn = isUserLoggedIn(user);
  if (loggedIn) {
    replaceState({ pathname: Configuration.PAGE_URLS.ACCOUNT.DASHBOARD, state: { nextPathname: nextState.location.pathname } });
  }
};

export const loggedInUsersRedirectionInterceptor = (store, nextState, replaceState) => {
  const user = _getCurrentUser(store);
  const loggedIn = isUserLoggedIn(user);
  if (loggedIn) {
    replaceState({ pathname: Configuration.PAGE_URLS.ACCOUNT.DASHBOARD, state: { nextPathname: nextState.location.pathname } });
  }
};
