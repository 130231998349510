import styled from 'styled-components';
import { Heading } from 'src/design/styleguide/Headings';
import ContentBlock from 'src/design/styleguide/contentBlock/ContentBlock';
import Image from 'src/design/components/image/Image';
import Paragraph from 'src/design/styleguide/Paragraph';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import StyledLink from 'src/design/components/hyperlink/Styledlink';

const Illustration = styled(Image)`
  display: block;
  max-width: 200px;
  margin: 0 auto;
`;

const PriceAlertsRemoved = () => (
  <ContentBlock
    topPadding="large"
    bottomPadding="large"
    width="small"
    verticallyCentered
  >
    <Illustration src="/static/img/illustration-hammer-house-build.svg" alt="hammer and house renovations illustration"/>

    <Spacing top="2x-large">
      <Heading>Important update on Price Alerts</Heading>
    </Spacing>

    <Spacing top="2x-large">
      <Paragraph align="center">Due to a high volume of member feedback indicating poor usability of the Price Alerts, we have decided to remove this feature. We are currently working on a new solution which will provide a clearer, more intuitive view of how Brick prices are trading - watch this space!</Paragraph>
    </Spacing>

    <Paragraph align="center">If you have any questions or concerns regarding this change, or any other feedback on how we can improve BrickX please do not hesitate to contact us at <StyledLink href="/contact">brickx.com/contact</StyledLink>.</Paragraph>
  </ContentBlock>
);

export default PriceAlertsRemoved;
