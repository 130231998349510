import { createSelector } from 'reselect';
import _ from 'lodash';

import { getAdjacent } from 'scripts/utilities/helpers';

const propertiesGetter = (state) => state.properties.list;
const propertyGetter = (state) => state.properties.selected;
const smartInvestPropertyGetter = (state) => state.properties.smartInvestProperties;
// const propertiesIndexedGetter = (state) => state.properties.indexed;

export const propertySelector = (state) => ({
  property: propertyGetter(state)
});

export const propertiesSelector = (state) => ({
  properties: propertiesGetter(state)
});

export const smartInvestPropertiesSelector = (state) => ({
  smartInvestProperties: smartInvestPropertyGetter(state)
});

export const adjacentPropertiesSelector = createSelector(
  propertiesGetter,
  propertySelector,
  (properties, { property }) => ({
    adjacentProperties: getAdjacent(properties, property)
  })
);

export const similarPropertiesSelector = createSelector(
  propertiesGetter,
  propertySelector,
  (properties, { property }) => ({
    similarProperties: _.reject(properties, property)
  })
);
