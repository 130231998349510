import update from 'immutability-helper';
import { createAction, handleActions } from 'redux-actions';
import UserService from 'scripts/services/UserService';

const DETAILED_TRANSACTIONS = 'scripts/user/DETAILED_TRANSACTIONS';
const DEPOSIT_TRANSACTIONS = 'scripts/user/DEPOSIT_TRANSACTIONS';
const DEPOSIT_INFO = 'scripts/user/DEPOSIT_INFO';

const detailedTransactions = createAction(DETAILED_TRANSACTIONS);
const depositTransactions = createAction(DEPOSIT_TRANSACTIONS);
const depositInfo = createAction(DEPOSIT_INFO);

export function fetchDetailedTransactionsWithLimit(limit) {
  return async function (dispatch, getState) {
    const { user } = getState();
    if (user.loggedIn) {
      const payload = await UserService.getDetailedTransactionsWithLimit(
        user.id,
        limit
      );
      return dispatch(detailedTransactions(payload));
    }
  };
}

export function fetchDetailedTransactions() {
  return async function (dispatch, getState) {
    const { user } = getState();
    if (user.loggedIn) {
      const payload = await UserService.getDetailedTransactions(user.id);
      return dispatch(detailedTransactions(payload));
    }
  };
}

export function fetchDetailedTransactionsForYear(year) {
  return async function (dispatch, getState) {
    const { user } = getState();
    if (user.loggedIn) {
      const payload = await UserService.getDetailedTransactionsForYear(
        user.id,
        year
      );
      return dispatch(detailedTransactions(payload));
    }
  };
}

export function fetchDepositTransactions(depositId) {
  return async function (dispatch, getState) {
    const { user } = getState();
    try {
      if (user.loggedIn) {
        const payload = await UserService.getDepositTransactions(
          user.id,
          depositId
        );
        return dispatch(depositTransactions(payload));
      }
    } catch (e) {
      console.log('Failed to retrieve deposit transactions.');
    }
  };
}

export function fetchDepositInfo(depositId) {
  return async function (dispatch, getState) {
    const { user } = getState();
    try {
      if (user.loggedIn) {
        const payload = await UserService.getDepositInfo(depositId);
        return dispatch(depositInfo(payload));
      }
    } catch (e) {
      console.log('Failed to retrieve deposit information.');
    }
  };
}

const reducer = handleActions(
  {
    [DETAILED_TRANSACTIONS]: (state, action) =>
      update(state, {
        detailedTransactions: {
          $set: action.payload,
        },
      }),
    [DEPOSIT_INFO]: (state, action) =>
      update(state, {
        depositInfo: {
          $set: action.payload,
        },
      }),
  },
  { detailedTransactions: null, depositInfo: null }
);

export default reducer;
