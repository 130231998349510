import styled from 'styled-components';
import { headerHeight } from 'src/design/styleguide/common/measurements';
import Header from 'src/components/landingPages/brickxAgriculture/BrickxAgriHeader';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow: hidden;
  position: relative;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: calc(100vh - ${headerHeight});
  width: 100%;
`;

const BrickxAgriApp = ({ children }) => (
  <Root>
    {/* TODO: Add Meta */}
    <Header/>
    <ContentContainer>
      {children}
    </ContentContainer>
  </Root>
);

export default BrickxAgriApp;
