import React, { Component } from 'react';

const isElementScrollableInContainer = (scrollingElement, scrollingContainer) => scrollingElement.offsetWidth > scrollingContainer.offsetWidth;
const isElementScrolled = (scrollTarget) => scrollTarget.scrollLeft > 0;
const isElementScrolledToTheEnd = (scrollTarget, scrollingElement) => {
  return Math.ceil(scrollTarget.scrollLeft + scrollTarget.offsetWidth) >= scrollingElement.offsetWidth; // Use ceil because sometimes scrollLeft is a fraction of a pixel less than max
};

const withScrollable = (WrappedComponent) => class WithScrollable extends Component {
  state = {
    isScrolled: false,
    isScrollable: false,
    isScrolledToTheEnd: false,
  };

  constructor(props) {
    super(props);

    this.scrollingElement = React.createRef();
    this.scrollingContainer = React.createRef();
  }

  componentDidMount() {
    this.checkIfElementIsScrollable();
    window.addEventListener('resize', this.checkIfElementIsScrollable);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkIfElementIsScrollable);
  }

  checkIfElementIsScrollable = () => {
    const isScrollable = isElementScrollableInContainer(this.scrollingElement.current, this.scrollingContainer.current);
    isScrollable !== this.state.isScrollable && this.setState({ isScrollable });
  };

  checkIfElementIsScrolled = ({ target }) => {
    const isScrolled = isElementScrolled(target);
    const isScrolledToTheEnd = isElementScrolledToTheEnd(target, this.scrollingElement.current);
    (isScrolled !== this.state.isScrolled || isScrolledToTheEnd !== this.state.isScrolledToTheEnd)
      && this.setState({ isScrolled, isScrolledToTheEnd });
  }

  render() {
    return (
      <WrappedComponent
        {...this.props}
        onContainerScroll={this.checkIfElementIsScrolled}
        elementRef={this.scrollingElement}
        containerRef={this.scrollingContainer}
        isScrolled={this.state.isScrolled}
        isScrollable={this.state.isScrollable}
        isScrolledToTheEnd={this.state.isScrolledToTheEnd}
      />
    );
  }
};

export default withScrollable;
