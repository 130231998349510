import Heading from 'src/design/components/heading/Heading';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import OnboardingScreen, {
  OnboardingScreenHeader,
  OnboardingScreenContent,
} from 'src/components/oldOnboarding/common/onboardingScreen/OnboardingScreen';
import OnboardingNextButton from 'src/components/oldOnboarding/common/onboardingNextButton/OnboardingNextButton';
import FormInput from 'src/components/flows/common/FormInput';
import { slugToTitle } from 'src/utils/formats';

const Signup = ({ stepId, fields, onChange, onSubmit }) => (
  <OnboardingScreen>
    <OnboardingScreenHeader>
      <Spacing bottom="large">
        <Heading testRef="page-title">{slugToTitle(stepId)}</Heading>
      </Spacing>
    </OnboardingScreenHeader>

    <OnboardingScreenContent>
      <form>
        {fields.map((field) =>
          <FormInput
            key={field.id}
            field={field}
            onChange={(value) => onChange(field.id, value)}
          />
        )}
        <Spacing bottom="large">
          <OnboardingNextButton
            customText="Sign Up"
            preventDefault
            testRef="submit-button"
            onClick={onSubmit}
          />
        </Spacing>
      </form>
    </OnboardingScreenContent>
  </OnboardingScreen>
);

export default Signup;
