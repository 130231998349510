import { Component, cloneElement } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  blurAppBackgroundSelector,
  modalsSelector,
} from 'src/components/modals/modalsReducer';
import { closeMenu as closeMenuAction } from 'src/store/headerMenuActions';
import { fetchMaintenanceStatus } from 'scripts/redux/actions/systems';
import { headerBarVisibleSelector } from 'src/store/headerBarReducer';
import { maintenanceStatusSelector } from 'scripts/redux/selectors/systems';
import {
  user as userPropType,
  maintenanceStatus as maintenanceStatusPropType,
} from 'scripts/constants/PropTypes';
import { userSelector } from 'scripts/redux/selectors/user';
import Alerts from 'scripts/components/Alerts';
import DefaultMeta from 'src/components/meta/DefaultMeta';
import Footer from 'src/components/footer/Footer';
import Header from 'src/components/header/Header';
import LoginOrSignupPopup from 'src/components/oldOnboarding/login/LoginOrSignupPopup';
import Modals from 'src/components/modals/Modals';
import NewUserOnboardingPopup from 'src/components/newUserOnboardingPopup/NewUserOnboardingPopup';
import SessionTimeout from 'src/components/sessionTimeout/sessionTimeout/SessionTimeout';
import Toasts from 'src/components/toasts/Toasts';
import styles from 'src/components/app/App.mscss';
import { ThemeProvider } from '@mui/material';
import MuiTheme from 'src/themes/mui/theme';

const mapStateToProps = (state) => ({
  ...userSelector(state),
  ...maintenanceStatusSelector(state),
  ...modalsSelector(state),
  ...blurAppBackgroundSelector(state),
  ...headerBarVisibleSelector(state),
});

@connect(mapStateToProps, {
  fetchMaintenanceStatus,
  closeMenu: closeMenuAction,
})
export default class App extends Component {
  static propTypes = {
    user: userPropType.isRequired,
    maintenanceStatus: maintenanceStatusPropType,
    fetchMaintenanceStatus: PropTypes.func.isRequired,
    closeMenu: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
    modals: PropTypes.array,
    // ------------------------------------------------------------------------------
    blurAppBackground: PropTypes.bool,
    headerBarVisible: PropTypes.bool,
    //   ^
    // @TODO: Perhaps at some point, these two bits of state
    // should be moved into a new reducer for over-arching App-level state ... ?
  };

  componentDidMount() {
    this.props.fetchMaintenanceStatus();
  }

  render() {
    const {
      user,
      children,
      maintenanceStatus,
      location,
      blurAppBackground,
      headerBarVisible,
      closeMenu,
    } = this.props;
    const isMaintenanceMode = maintenanceStatus
      ? maintenanceStatus.enabled
      : false;
    const currentPath = location.pathname;
    return (
      <ThemeProvider theme={MuiTheme}>
        <div className={styles.app}>
          <DefaultMeta />
          <Alerts />
          {headerBarVisible ? <Header currentPath={currentPath} /> : null}
          {!isMaintenanceMode && <SessionTimeout />}
          {!isMaintenanceMode && <LoginOrSignupPopup user={user} />}
          {!isMaintenanceMode && (
            <NewUserOnboardingPopup currentPath={currentPath} />
          )}
          <div
            className={styles.modalLayer}
            data-test-reference="modal-layer"
            onClick={closeMenu}
          >
            <div
              className={classNames(styles.contentAndFooterLayer, {
                [styles['contentAndFooterLayer--modalOpen']]: blurAppBackground,
              })}
              data-test-reference="content-and-footer-layer"
            >
              <section
                className={classNames(styles.contentContainer)}
                role="main"
              >
                {children && cloneElement(children, { user })}
              </section>
              <Footer />
            </div>
            <Modals />
          </div>
          <Toasts />
        </div>
      </ThemeProvider>
    );
  }
}
