import React, { Component } from 'react';


export default class PropertyReturnsCalculatorReturnsEstimationPlaceholder extends Component {

  render() {
    return (
      <div className="return-calculator-results-overview__returns-estimation">
        <div className="return-calculator-results-overview__estimated-return">
          Estimated Return
          <div className="return-calculator-results-overview__estimated-return-value">
            ---
          </div>
        </div>
        <div className="return-calculator-results-overview__estimated-return-rates">
          <div className="return-calculator-results-overview__estimated-return-annualised-rate">
            ---
          </div>
          <div className="return-calculator-results-overview__estimated-return-annualised-rate">
            ---
          </div>
        </div>
      </div>
    );
  }
}
