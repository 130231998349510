import { createAction, handleActions } from 'redux-actions';
import update from 'immutability-helper';

import HistoricalGrowthService from 'scripts/services/HistoricalGrowthService';

export const averageAnnualGrowthMetrics = createAction(
  'scripts/historicalGrowth/AVERAGE_ANNUAL_GROWTH_METRICS'
);
export const historicalGrowthData = createAction(
  'scripts/historicalGrowth/DATA'
);

export const fetchHistoricalGrowthMetrics = () => (dispatch) =>
  HistoricalGrowthService.getHistoricalGrowthMetrics()
    .then((payload) => dispatch(averageAnnualGrowthMetrics(payload)))
    .catch(() => {});

export function fetchHistoricalGrowthData(
  historicalGrowthKey,
  state,
  propertyType
) {
  return async function (dispatch) {
    const payload = await HistoricalGrowthService.getHistoricalGrowthData(
      historicalGrowthKey,
      state,
      propertyType
    );
    dispatch(historicalGrowthData(payload));
  };
}

export const clearHistoricalGrowthData = () => historicalGrowthData([]);

export const defaultState = {
  metrics: {},
  data: [],
};

export default handleActions(
  {
    [averageAnnualGrowthMetrics]: (state, action) =>
      update(state, {
        metrics: { $set: action.payload },
      }),
    [historicalGrowthData]: (state, action) =>
      update(state, {
        data: { $set: action.payload },
      }),
  },
  defaultState
);
