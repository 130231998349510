import StyledBodylink from 'src/design/components/hyperlink/StyledBodylink';
import history from 'src/browser/history';

// @TODO: might be worth setting a "setRouteLeaveHook()" to ensure that we are
// not bouncing them out of the BX Website with this one ... ?
const Backlink = ({ onClick, ...props }) => (
  <StyledBodylink
    onClick={(e) => {
      onClick && onClick(e);
      history.goBack();
    }}
    {...props}
  />
);

export default Backlink;
