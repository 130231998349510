import { connect } from 'react-redux';
import DocumentMeta from 'react-document-meta';
import DynamicNumber from 'react-dynamic-number';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import { RETURNS_CALCULATOR } from 'scripts/metadata/statics';
import {
  averageAnnualGrowthMetricsSelector,
} from 'scripts/redux/selectors/historicalGrowth';
import {
  fetchHistoricalGrowthMetrics as fetchHistoricalGrowthMetricsAction,
} from 'scripts/redux/actions/historicalGrowth';
import { fetchProperties as fetchPropertiesAction } from 'scripts/redux/actions/properties';
import { getAvgInvestmentMetrics, getMaximalInvestmentAmountAllowed } from 'scripts/utilities/calculator/calculators';
import { isUserLoggedIn } from 'scripts/utilities/userAccountHelper';
import { percentDecimal, percent, numberDecimal } from 'scripts/utilities/formatters';
import { propertiesSelector } from 'scripts/redux/selectors/properties';
import { propertiesUrl } from 'src/utils/pageUrls';
import { user as userPropType, property as propertyPropType } from 'scripts/constants/PropTypes';
import { userSelector } from 'scripts/redux/selectors/user';
import CalculateAveragesCard from 'scripts/components/calculators/CalculateAveragesCard';
import FineprintParagraph from 'src/design/styleguide/FinePrintParagraph';
import HowDoIExitSection from 'src/components/landingPages/howItWorks/howDoIExitSection/HowDoIExitSection';
import HowDoIMakeMoneySection from 'src/components/landingPages/common/howDoIMakeMoneySection/HowDoIMakeMoneySection';
import InfoBox from 'scripts/components/shared/InfoBox';
import Loading from 'src/components/loading/Loading';
import ReturnsCalculatorHoldingPeriodSelector from 'scripts/components/calculators/ReturnsCalculatorHoldingPeriodSelector';
import ReturnsCalculatorResultPanel from 'scripts/components/calculators/ReturnsCalculatorResultPanel';
import SliderComponent from 'scripts/components/shared/SliderComponent';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import Trading from 'scripts/constants/Trading';

const SLIDER_MIN_NRY = 0;
const SLIDER_MAX_NRY = 5.00;
const SLIDER_STEP_NRY = 0.1;
const SLIDER_MARKS_NRY = {};

const SLIDER_MIN_ACR = -10.00;
const SLIDER_MAX_ACR = 20.00;
const SLIDER_STEP_ACR = 0.1;
const SLIDER_MARKS_ACR = {};

const SLIDER_MIN_DEBT = 0;
const SLIDER_MAX_DEBT = 40.00;
const SLIDER_STEP_DEBT = 1;
const SLIDER_MARKS_DEBT = {};

const mapStateToProps = (state) => ({
  ...propertiesSelector(state),
  ...userSelector(state),
  ...averageAnnualGrowthMetricsSelector(state),
});

@connect(mapStateToProps, {
  fetchProperties: fetchPropertiesAction,
  fetchHistoricalGrowthMetrics: fetchHistoricalGrowthMetricsAction,
})
export default class ReturnsCalculator extends Component {
  static propTypes = {
    user: userPropType.isRequired,
    properties: PropTypes.arrayOf(propertyPropType).isRequired,
    averageAnnualGrowthMetrics: PropTypes.object,
    fetchProperties: PropTypes.func.isRequired,
    fetchHistoricalGrowthMetrics: PropTypes.func.isRequired,
  };

  state = {
    showCalculationResult: false,
    netRentalYield: 0,
    annualGrowth: 0,
    debt: 0,
    investmentAmount: 10000,
    investmentPeriod: 5,
  };

  componentDidMount() {
    this.props.fetchProperties();
    this.props.fetchHistoricalGrowthMetrics();
    window.scrollTo(0, 0);
  }

  render() {
    const { user, properties, averageAnnualGrowthMetrics } = this.props;
    if (_.isEmpty(properties) || !averageAnnualGrowthMetrics) {
      return <Loading/>;
    }

    const { investmentAmount, netRentalYield, investmentPeriod, annualGrowth, debt } = this.state;

    const maximalInvestmentAmountAllowed = getMaximalInvestmentAmountAllowed(properties);

    const annualGrowthRate = annualGrowth / 100;
    const debtRate = debt / 100;
    const netRentalYieldRate = netRentalYield / 100;

    const initialPercentOfPropertyAsset = 0.95;
    const initialPercentOfAcquisitionCost = 0.04;
    const initialPercentOfCashReserve = 0.01;

    const avgInvestmentMetrics = getAvgInvestmentMetrics(properties, averageAnnualGrowthMetrics);
    const avgNetRentalYield = avgInvestmentMetrics.rentalYield;
    const avgAnnualGrowth = avgInvestmentMetrics.growthRate;
    const avgDebt = avgInvestmentMetrics.debtRate;

    const netRentalYieldPopover = `Net Rent Yield measures the amount of net rental income (after expenses and any interest payments) the asset produces each year, as a percentage of the investment amount. The average across BrickX Properties is ${avgNetRentalYield::percentDecimal()}.`;
    const annualGrowthPopover = `Capital Returns is your estimate of the yearly change in property value.<br/><br/>${avgAnnualGrowth::percentDecimal()} is an average of the 20-Year* Historical Suburb Growth figure across all current BrickX Properties. (Source: CoreLogic)<br/><br/>*Some properties may be in suburbs with less than 20 years of historical growth data.`;
    const debtPopover = `Debt measures the mortgage amount as a percent of the total property value. The average of ${avgDebt::percentDecimal()} is an average across all BrickX Properties, including 0% debt properties.`;

    return (
      <div id="returns-calculator" className="returns-calculator-page">
        <DocumentMeta {...RETURNS_CALCULATOR} />
        <div className="calculator">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1> The BrickX Calculator</h1>
                <div className="description">
                  <span>
                    Find out how key factors can influence returns by changing your inputs for the investment criteria.
                  </span>
                </div>
                <CalculateAveragesCard onCalculateNowButtonClick={() => this._loadAvgMetrics(avgNetRentalYield, avgAnnualGrowth, avgDebt)}/>
              </div>
            </div>

            <div className="row calculator-form-fields">
              <div className="col-md-5">
                <div className="filters">
                  <h3>Choose your investment amount and period:</h3>
                  <div className="form-horizontal">
                    <div className="form-group">
                      <div className="col-md-6">
                        <div className="label-input">I would like to invest</div>
                      </div>
                      <div className="col-md-6">
                        <div className="dollar-input">
                          <DynamicNumber
                            id="investment-amount"
                            name="investment-amount"
                            value={investmentAmount}
                            separator={'.'}
                            integer={10}
                            fraction={2}
                            positive
                            thousand
                            placeholder="Enter Amount"
                            onChange={this._updateInvestmentAmount}
                            data-test-reference="investment-amount"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="col-md-6">
                        <div className="label-input">Holding Period</div>
                      </div>
                      <div className="col-md-6">
                        <div className="select-box">
                          <ReturnsCalculatorHoldingPeriodSelector
                            investmentPeriod={investmentPeriod}
                            updateHoldingPeriodCallback={this._updateInvestmentPeriod}
                          />
                        </div>
                      </div>
                    </div>
                    {maximalInvestmentAmountAllowed < investmentAmount &&
                      <div className="form-group info-box-disclosures">
                        <InfoBox className="small">Your investment amount is over the maximum ownership ({Trading.MAXIMUM_OWNERSHIP_PERCENTAGE::percent()} per property) in Bricks at this point. More properties in the BrickX Platform in the future means you may invest a higher amount in the future to a maximum of {Trading.MAXIMUM_OWNERSHIP_PERCENTAGE::percent()} per property.</InfoBox>
                      </div>
                    }
                  </div>
                </div>
              </div>

              <div className="col-md-7">
                <div className="filters">
                  <h3>Estimate your ideal property investment criteria (per year):</h3>
                  <SliderComponent
                    ref={(netRentYieldSlider) => { this.netRentYieldSlider = netRentYieldSlider; }}
                    title="Net Rent Yield"
                    name="net-rent-yield"
                    minValue={SLIDER_MIN_NRY}
                    maxValue={SLIDER_MAX_NRY}
                    value={netRentalYield}
                    step={SLIDER_STEP_NRY}
                    marks={SLIDER_MARKS_NRY}
                    popover={netRentalYieldPopover}
                    icon="/static/images/icons/icn_coins.png"
                    updateSliderValueCallBack={this._updateNetRentalYield}
                    showTitle
                    textInputTestRef="net-rental-yield"
                  />
                  <hr/>
                  <SliderComponent
                    ref={(annualGrowthSlider) => { this.annualGrowthSlider = annualGrowthSlider; }}
                    title="Capital Returns"
                    name="annual-growth-rate"
                    minValue={SLIDER_MIN_ACR}
                    maxValue={SLIDER_MAX_ACR}
                    value={annualGrowth}
                    step={SLIDER_STEP_ACR}
                    marks={SLIDER_MARKS_ACR}
                    popover={annualGrowthPopover}
                    icon="/static/images/icons/icn_line_chart_blue.png"
                    updateSliderValueCallBack={this._updateAnnualGrowth}
                    showTitle
                    textInputTestRef="annual-growth"
                  />
                  <br/>
                  <h3>Estimate your ideal percentage of debt:</h3>
                  <SliderComponent
                    ref={(debtRateSlider) => { this.debtRateSlider = debtRateSlider; }}
                    title="% of Debt"
                    name="debt-rate"
                    minValue={SLIDER_MIN_DEBT}
                    maxValue={SLIDER_MAX_DEBT}
                    value={debt}
                    step={SLIDER_STEP_DEBT}
                    marks={SLIDER_MARKS_DEBT}
                    popover={debtPopover}
                    icon="/static/images/icons/icn_debt.png"
                    updateSliderValueCallBack={this._updateDebt}
                    showTitle
                    textInputTestRef="debt-rate"
                  />
                </div>
              </div>
            </div>

            {this.state.showCalculationResult &&
              <ReturnsCalculatorResultPanel
                className="row"
                investmentPeriod={investmentPeriod}
                investmentAmount={investmentAmount}
                annualGrowthRate={annualGrowthRate}
                debtRate={debtRate}
                netRentalYieldRate={netRentalYieldRate}
                initialPercentOfPropertyAsset={initialPercentOfPropertyAsset}
                initialPercentOfAcquisitionCost={initialPercentOfAcquisitionCost}
                initialPercentOfCashReserve={initialPercentOfCashReserve}
              />
            }

            <Spacing top="normal">
              <FineprintParagraph>Past performance does not indicate future performance.</FineprintParagraph>
            </Spacing>

            { !(isUserLoggedIn(user)) &&
              <div className="row">
                <div className="col-md-12 invite">
                  <h3>Ready to start your property market journey?</h3>
                  <a href={propertiesUrl()} className="button orange-button right-arrow-button">View Properties</a>
                </div>
              </div>
            }

          </div>
        </div>

        <HowDoIMakeMoneySection darkBackground/>
        <HowDoIExitSection/>
      </div>
    );
  }

  _updateInvestmentAmount = (event) => {
    const updatedAmount = (event.target.value).split(',').join('') * 1;
    if (!isNaN(updatedAmount)) {
      this.setState({
        investmentAmount: updatedAmount
      });
    }
  };

  _updateInvestmentPeriod = (investmentPeriod) => {
    this.setState({
      investmentPeriod: investmentPeriod
    });
  };

  _updateNetRentalYield = (value) => {
    this.setState({
      netRentalYield: (value * 1)::numberDecimal() * 1,
      showCalculationResult: true
    });
  };

  _updateAnnualGrowth = (value) => {
    this.setState({
      annualGrowth: (value * 1)::numberDecimal() * 1,
      showCalculationResult: true
    });
  };

  _updateDebt = (value) => {
    this.setState({
      debt: (value * 1)::numberDecimal() * 1,
      showCalculationResult: true
    });
  };

  _loadAvgMetrics = (avgNetRentalYield, avgAnnualGrowth, avgDebt) => {
    const formattedAvgNRY = (avgNetRentalYield * 100)::numberDecimal() * 1;
    const formattedAvgAGR = (avgAnnualGrowth * 100)::numberDecimal() * 1;
    const formattedAvgDR = (avgDebt * 100)::numberDecimal() * 1;
    this.setState({
      netRentalYield: formattedAvgNRY,
      annualGrowth: formattedAvgAGR,
      debt: formattedAvgDR,
      showCalculationResult: true
    });

    this.netRentYieldSlider._updateInputValue(formattedAvgNRY);
    this.annualGrowthSlider._updateInputValue(formattedAvgAGR);
    this.debtRateSlider._updateInputValue(formattedAvgDR);
  };
}
