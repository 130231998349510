import { createAction, handleActions } from 'redux-actions';
import update from 'immutability-helper';
import _ from 'lodash';

import PropertyService from 'scripts/services/PropertyService';

const SUBURB_POLYGONS = 'scripts/properties/SUBURB_POLYGONS';
const SUBURB_GROWTH = 'scripts/properties/SUBURB_GROWTH';

const suburbPolygons = createAction(SUBURB_POLYGONS);

export function fetchSuburbPolygons(suburbId) {
  return async function (dispatch) {
    const payload = await PropertyService.getSuburb(suburbId);
    return dispatch(suburbPolygons(payload));
  };
}

const reducer = handleActions({
  [SUBURB_POLYGONS]: (state, action) => update(state, {
    $merge: {
      [action.payload.properties.id]: action.payload
    }
  }),
  [SUBURB_GROWTH]: (state, action) => {
    const growthData = action.payload;
    const slug = growthData.suburbSlug;
    const suburb = _.find(state, { properties: { slug } });
    return update(state, {
      [suburb.properties.id]: {
        properties: {
          $merge: {
            growthData
          }
        }
      }
    });
  }
}, {});

export default reducer;
