import Spacing from 'src/design/styleguide/spacing/Spacing';
// import SecondaryButton from 'src/design/components/button/SecondaryButton';
import StyledBodylink from 'src/design/components/hyperlink/StyledBodylink';
import GetStartedButton from 'src/components/landingPages/common/getStartedButton/GetStartedButton';
// import HandwrittenTipText from 'src/design/components/handwrittenTipText/HandwrittenTipText';

import { benefitsAndRisksUrl } from 'src/utils/pageUrls';

import styles from 'src/components/landingPages/common/howDoIMakeMoneySection/HowDoIMakeMoneySection.mscss';


const SecondaryCtaContent = () => (
  <div className={styles.secondaryCtaContent}>
    <GetStartedButton />
    <Spacing top="small">
      <StyledBodylink
        fullWidth
        href={benefitsAndRisksUrl()}
      >Learn about the benefits and risks</StyledBodylink>
    </Spacing>
  </div>
);

export default SecondaryCtaContent;
