import styled from 'styled-components';
import { standardTransition } from 'src/design/styleguide/common/animations';
import Icon from 'src/design/components/icon/Icon';

const RotatingIcon = styled(Icon)`
  transition: transform ${standardTransition};
  ${props => props.rotated ? 'transform: rotate(180deg);' : ''}
`;

export default RotatingIcon;
