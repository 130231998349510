// TODO: does not belong in constants, move it to configuration
import { getTransactionFeeRatio } from 'src/settings/financials';

export default {
  BUY_BROKERAGE_PERCENTAGE: getTransactionFeeRatio(),
  SELL_BROKERAGE_PERCENTAGE: getTransactionFeeRatio(),
  MAXIMUM_PRICE_DEVIATION_PERCENTAGE: 0.1,
  MAXIMUM_OWNERSHIP_PERCENTAGE: 0.05,
  LOWEST_SELL_PRICE_PERCENTAGE: 0.8,
  HIGHEST_SELL_PRICE_PERCENTAGE: 1.2,
  MAXIMUM_NUM_OF_BRICKS_PER_USER_PER_PROPERTY: 500,
  TOTAL_BRICKS_PER_PROPERTY: 10000,
  TRADE_SUCCEED_SOCIAL_SHARE_THRESHOLD: 0.1,
  PREMIUM_PRICE_FIRST_LIMIT: 0.05,
  PREMIUM_PRICE_SECOND_LIMIT: 0.1,
  NUM_OF_MONTHS_FOR_AMORTISING: 60
};
