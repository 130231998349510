import PropertyCardStat from 'src/components/propertyCards/common/propertyCardStat/PropertyCardStat';

import { getBrickPriceStats } from 'src/utils/property';
import { dollarDecimal, monthYearShorten } from 'scripts/utilities/formatters';

const BrickPriceStat = ({ property }) => {
  const { brickValueType, value, date } = getBrickPriceStats({ property });
  return (
    <PropertyCardStat
      label={
        <span>
          {brickValueType}
          <br/>
          <span className="brick-price-stat__date" data-test-reference="brick-value-date">
            ({date::monthYearShorten()})
          </span>
        </span>
      }
      value={value::dollarDecimal()}
      valueProps={{ 'data-test-reference': 'brick-value' }}
    />
  );
};

export default BrickPriceStat;
