import classNames from 'classnames';

const getBackgroundColorClass = (backgroundColor) => ({
  'BLUE': 'property-card__button-container-background--blue',
}[backgroundColor]);

const PropertyCardButtonContainer = ({ backgroundColor, children }) => (
  <div className={classNames('property-card__button-container', getBackgroundColorClass(backgroundColor))}>
    {children}
  </div>
);

export default PropertyCardButtonContainer;
