import SegmentEventTypes from 'scripts/redux/actions/segment/SegmentEventTypes';
import Trade from 'scripts/constants/Trade';
import { dollarDecimal } from 'scripts/utilities/formatters';
import { track } from 'src/tracking/analytics';

export function sendFirstBrickPurchasedSegmentEvents({ propertyCode, quantity, basisPrice, valueOfBricks, transactionFee, total }) {
  return track(SegmentEventTypes.BUY.FIRST_BRICK_PURCHASED, {
    'Property Code': propertyCode,
    'Quantity': quantity,
    'Average Brick Price': basisPrice,
    'Value of Bricks': valueOfBricks,
    'Transaction Fees': transactionFee,
    'Order Total': total,
    value: total,
  });
}


/**
 * Base Events
 */

function _sendOrderButtonClickEvent(eventType, { propertyCode, tradeType }) {
  return track(eventType, { 'Property Code': propertyCode, 'Trade Type': tradeType });
}

function _sendTradeEvent(eventType, tradeType, { tradeProposalData, account, totalBricksOwned, title }) {
  return track(eventType, {
    'Trade Type': tradeType,
    'Property Code': tradeProposalData.propertyCode,
    'Quantity': tradeProposalData.quantity,
    'Average Brick Price': tradeProposalData.basisPrice,
    'Value of Bricks': tradeProposalData.valueOfBricks,
    'Funds Available to Trade': account.availableToTradeBalance,
    'Current Bricks Owned in Property': totalBricksOwned,
    'Transaction Fees': tradeProposalData.transactionFee,
    'Order Total': tradeProposalData.total,
    'Title': title
  });
}

/**
 * Trade (Buy & Pre-Order) Events
 */

// Trade Button click events
export function sendTradeButtonClickEvent({ propertyCode, tradeType }) {
  _sendOrderButtonClickEvent(SegmentEventTypes.TRADE.OPEN_TRADE_PANEL, { propertyCode, tradeType });
}

// Trade Engagement Events

function _sendTradePanelEngagementEvent(tradeType, { tradeProposalData, account, totalBricksOwned }) {
  _sendTradeEvent(SegmentEventTypes.TRADE.TRADE_PANEL_ENGAGEMENT.TEXT, tradeType, { tradeProposalData, account, totalBricksOwned, title: 'Qty Field' });
}

export function sendBuyProposalEngagementEvent({ tradeProposalData, account, totalBricksOwned }) {
  _sendTradePanelEngagementEvent(SegmentEventTypes.TRADE.BUY, { tradeProposalData, account, totalBricksOwned });
}

export function sendPreOrderProposalEngagementEvent({ tradeProposalData, account, totalBricksOwned }) {
  _sendTradePanelEngagementEvent(SegmentEventTypes.TRADE.PRE_ORDER, { tradeProposalData, account, totalBricksOwned });
}

function _sendTradePanelEngagementTabClickEvent(tradeType, propertyCode, account, title) {
  return track(SegmentEventTypes.TRADE.TRADE_PANEL_ENGAGEMENT.TEXT, {
    'Trade Type': tradeType,
    'Property Code': propertyCode,
    'Funds Available to Trade': account.availableToTradeBalance,
    'Title': title
  });
}

export function sendPlaceOrderButtonClickEvent(tradeType, propertyCode, account) {
  _sendTradePanelEngagementTabClickEvent(tradeType, propertyCode, account, SegmentEventTypes.TRADE.TRADE_PANEL_ENGAGEMENT.PLACE_ORDER_BUTTON_CLICK);
}

export function sendRecentTransactionsTabClickEvent(tradeType, propertyCode, account) {
  _sendTradePanelEngagementTabClickEvent(tradeType, propertyCode, account, SegmentEventTypes.TRADE.TRADE_PANEL_ENGAGEMENT.RECENT_TRANSACTIONS_TAB_CLICK);
}

export function sendSeeMoreTransactionsLinkClickEvent(tradeType, propertyCode, account) {
  _sendTradePanelEngagementTabClickEvent(tradeType, propertyCode, account, SegmentEventTypes.TRADE.TRADE_PANEL_ENGAGEMENT.SEE_MORE_TRANSACTIONS_TAB_CLICK);
}

export function sendSeeLessTransactionsLinkClickEvent(tradeType, propertyCode, account) {
  _sendTradePanelEngagementTabClickEvent(tradeType, propertyCode, account, SegmentEventTypes.TRADE.TRADE_PANEL_ENGAGEMENT.SEE_LESS_TRANSACTIONS_TAB_CLICK);
}

export function sendBricksAvailableTabClickEvent(tradeType, propertyCode, account) {
  _sendTradePanelEngagementTabClickEvent(tradeType, propertyCode, account, SegmentEventTypes.TRADE.TRADE_PANEL_ENGAGEMENT.BRICKS_AVAILABLE_TAB_CLICK);
}

export function sendSeeMoreAvailableBricksLinkClickEvent(tradeType, propertyCode, account) {
  _sendTradePanelEngagementTabClickEvent(tradeType, propertyCode, account, SegmentEventTypes.TRADE.TRADE_PANEL_ENGAGEMENT.SEE_MORE_AVAILABLE_BRICKS_TAB_CLICK);
}

export function sendSeeLessAvailableBricksLinkClickEvent(tradeType, propertyCode, account) {
  _sendTradePanelEngagementTabClickEvent(tradeType, propertyCode, account, SegmentEventTypes.TRADE.TRADE_PANEL_ENGAGEMENT.SEE_LESS_AVAILABLE_BRICKS_TAB_CLICK);
}

// Trade Proposal Submit Events

function _sendTradePanelSubmitEvent(tradeType, { tradeProposalData, account, totalBricksOwned }) {
  _sendTradeEvent(SegmentEventTypes.TRADE.TRADE_PANEL_SUBMIT, tradeType, { tradeProposalData, account, totalBricksOwned });
}

export function sendBuyProposalSubmitEvent({ tradeProposalData, account, totalBricksOwned }) {
  _sendTradePanelSubmitEvent(SegmentEventTypes.TRADE.BUY, { tradeProposalData, account, totalBricksOwned });
}

export function sendPreOrderProposalSubmitEvent({ tradeProposalData, account, totalBricksOwned }) {
  _sendTradePanelSubmitEvent(SegmentEventTypes.TRADE.PRE_ORDER, { tradeProposalData, account, totalBricksOwned });
}


/**
 * Sell Events
 */

// Sell Button click events
export function sendSellButtonClickEvent({ propertyCode, tradeType }) {
  _sendOrderButtonClickEvent(SegmentEventTypes.SELL.OPEN_SELL_PANEL, { propertyCode, tradeType });
}

// Trade Engagement Events

function _sendSellPanelEngagementEvent(tradeType, { tradeProposalData, account, totalBricksOwned }) {
  _sendTradeEvent(SegmentEventTypes.SELL.SELL_PANEL_ENGAGEMENT, tradeType, { tradeProposalData, account, totalBricksOwned });
}

export function sendSellProposalEngagementEvent({ tradeProposalData, account, totalBricksOwned }) {
  _sendSellPanelEngagementEvent(SegmentEventTypes.TRADE.SELL, { tradeProposalData, account, totalBricksOwned });
}

// Trade Proposal Submit Events

function _sendSellPanelSubmitEvent(tradeType, { tradeProposalData, account, totalBricksOwned }) {
  _sendTradeEvent(SegmentEventTypes.SELL.SELL_PANEL_SUBMIT, tradeType, { tradeProposalData, account, totalBricksOwned });
}

export function sendSellProposalSubmitEvent({ tradeProposalData, account, totalBricksOwned }) {
  _sendSellPanelSubmitEvent(SegmentEventTypes.TRADE.SELL, { tradeProposalData, account, totalBricksOwned });
}


/**
 * Buy, Pre-Order & Sell Success Events
 */

export function sendTradeSuccessEvent(type, { propertyCode, quantity, brickPrice, tradeAmount, commissionAmount, total }) {
  var eventType = SegmentEventTypes.BUY.BUY_SUCCEED;
  if (type === Trade.TRADE_TYPE.SELL) {
    eventType = SegmentEventTypes.SELL.SELL_PLACED;
  } else if (type === Trade.TRADE_TYPE.PRE_ORDER) {
    eventType = SegmentEventTypes.PRE_ORDER.PRE_ORDER_PLACED;
  }

  return track(eventType, {
    'Property Code': propertyCode,
    'Quantity': quantity,
    'Average Brick Price': brickPrice,
    'Value of Bricks': tradeAmount,
    'Transaction Fees': commissionAmount,
    'Order Total': total,
    'value': total
  });
}

/**
 * Cancel Pending Order Event
 */

export function sendCancelPendingOrderEvent(eventType, { orderId, propertyCode, quantity, price, date, commission }) {
  const valueOfBricks = quantity * price;
  const transactionFees = valueOfBricks * commission;
  const total = valueOfBricks * (1 + commission);
  return track(eventType, {
    'Property Code': propertyCode,
    'Quantity': quantity,
    'Brick Price': price,
    'Date of Order': date,
    'Order Id': orderId,
    'Value of Bricks': valueOfBricks::dollarDecimal(),
    'Transaction Fees': transactionFees::dollarDecimal(),
    'Order Total': total::dollarDecimal(),
  });
}

/**
 * Premium Price Alerts events
 */

export function sendFirstPremiumAlertEvent(action) {
  return track(SegmentEventTypes.TRADE.PREMIUM_PRICE_ALERTS_ENGAGEMENT.ALERT_1, {
    'action': action
  });
}

export function sendSecondPremiumAlertEvent(action) {
  return track(SegmentEventTypes.TRADE.PREMIUM_PRICE_ALERTS_ENGAGEMENT.ALERT_2, {
    'action': action
  });
}
