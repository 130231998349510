import { Component } from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import 'owlcarousel/js';
import $ from 'jquery';

import { elements as elementsPropType } from 'scripts/constants/PropTypes';

import Icon from 'scripts/components/shared/Icon';


// @TODO: OMFG this carousel is a piece of shit
// We need to rip this out ASAP.
// I jut checked, and it errors out a butt-load in chrome, on touch devices:
// "[Intervention] Unable to preventDefault inside passive event listener
// due to target being treated as passive. See <URL>"

export default class Carousel extends Component {
  static propTypes = {
    startPosition: PropTypes.number,
    children: elementsPropType,
    showOneSlideOnly: PropTypes.bool,
    autoplay: PropTypes.bool,
    autoplayTimeout: PropTypes.number,
    useDots: PropTypes.bool,
    disableNav: PropTypes.bool,
    dotsClass: PropTypes.string,
    dotClass: PropTypes.string,
    stageOuterClass: PropTypes.string,
    className: PropTypes.string,
    disableLoop: PropTypes.bool,
    useLazyLoad: PropTypes.bool,
    onChanged: PropTypes.func,
  };

  static defaultProps = {
    startPosition: 0,
    showOneSlideOnly: false,
    autoplay: false,
    autoplayTimeout: 5000,
  };

  state = {
    carouselLoaded: false,
  };

  componentDidMount() {
    let items = 3;
    let responsive = {
      0: { items: 1 },
      979: { items: 2 },
      1200: { items: 3 },
      2000: { items: 4 }
    };

    if (this.props.showOneSlideOnly) {
      items = 1;
      responsive = null;
    }

    $(this.refs.carousel).owlCarousel({
      items,
      lazyLoad: !! this.props.useLazyLoad,
      loop: !! !this.props.disableLoop,
      margin: 2,
      nav: !! !this.props.disableNav,
      dots: !! this.props.useDots,
      dotsClass: this.props.dotsClass,
      dotClass: this.props.dotClass,
      stageOuterClass: this.props.stageOuterClass,
      startPosition: this.props.startPosition,
      autoplay: this.props.autoplay,
      autoplayTimeout: this.props.autoplayTimeout,
      autoplayHoverPause: true,
      navText: [
        ReactDOMServer.renderToStaticMarkup(<Icon type="fa" className="fa-fw" name="chevron-left"/>),
        ReactDOMServer.renderToStaticMarkup(<Icon type="fa" className="fa-fw" name="chevron-right"/>)
      ],
      responsive,
      navClass: ['carousel__nav-prev', 'carousel__nav-next'],
      onInitialized: () => this.setState({ 'carouselLoaded': true }),
      onChanged: this.props.onChanged,
    });
  }

  componentWillUnmount() {
    $.data(this.refs.carousel, 'owl.carousel').destroy();
  }

  render() {
    const { children, className } = this.props;

    // If you get invariant violation: id 124 doesn't exist or something, put an <img/> somewhere inside your slide markup.
    // Something to do with owl carousel messing up the react elements when there's no img in there and react not liking it ¯\_(ツ)_/¯
    return (
      <div
        className={classNames(className, 'owl-carousel carousel', {
          'carousel--not-loaded': !this.state.carouselLoaded
        })}
        ref="carousel"
      >
        {children}
      </div>
    );
  }
}
