import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import account from 'scripts/redux/actions/account';
import addressLookup from 'src/store/addressLookupReducer';
import alerts from 'scripts/redux/actions/alerts';
import auth from 'scripts/redux/actions/auth';
import bafm from 'scripts/redux/actions/bafm';
import bpay from 'scripts/redux/actions/bpay';
import businessDetails from 'scripts/redux/actions/businessDetails';
import closedTesting from 'src/store/closedTestingReducer';
import contents from 'scripts/redux/actions/contents';
import dashboardAlert from 'scripts/redux/actions/dashboardAlert';
import rentToBuyExtraInfo from 'src/rentToBuy/extraInfoStore';
import facebook from 'scripts/redux/actions/facebook';
import financials from 'scripts/redux/actions/financials';
import flows from 'src/components/flows/store/flowsReducer';
import headerBar from 'src/store/headerBarReducer';
import headerMenu from 'src/store/headerMenuReducer';
import historicalGrowth from 'scripts/redux/actions/historicalGrowth';
import identityVerificationForm from 'src/store/identityVerificationFormReducer';
import loginOrSignupModal from 'src/store/loginOrSignupModalReducer';
import market from 'scripts/redux/actions/market';
import member from 'scripts/redux/actions/member';
import modals from 'scripts/redux/actions/modals';
import monthlyUpdates from 'scripts/redux/actions/monthlyUpdates';
import newModals from 'src/components/modals/modalsReducer';
import properties from 'scripts/redux/actions/properties';
import property from 'scripts/redux/actions/property';
import propertyViews from 'scripts/redux/actions/propertyViews';
import referral from 'scripts/redux/actions/referral';
import registration from 'scripts/redux/actions/registration';
import reports from 'scripts/redux/actions/reports';
import resetPassword from 'scripts/redux/actions/resetPassword';
import segment from 'scripts/redux/actions/segment';
import smartInvest from 'scripts/redux/actions/smartInvest';
import smsf from 'src/components/landingPages/smsf/smsfReducer';
import statistics from 'scripts/redux/actions/statistics';
import suburbs from 'scripts/redux/actions/suburbs';
import successAnswer from 'scripts/redux/actions/successAnswer';
import systems from 'scripts/redux/actions/systems';
import toasts from 'src/components/toasts/toastsReducer';
import transactions from 'scripts/redux/actions/transactions';
import ui from 'scripts/redux/actions/ui';
import user from 'scripts/redux/actions/user';
import wallet from 'scripts/redux/actions/wallet';

const rootReducer = combineReducers({
  ui,
  user,
  alerts,
  account,
  member,
  auth,
  resetPassword,
  properties,
  property,
  suburbs,
  market,
  modals,
  financials,
  registration,
  monthlyUpdates,
  bafm,
  bpay,
  wallet,
  transactions,
  successAnswer,
  segment,
  systems,
  facebook,
  contents,
  statistics,
  referral,
  businessDetails,
  dashboardAlert,
  reports,
  routing,
  historicalGrowth,
  loginOrSignupModal,
  propertyViews,
  smartInvest,
  closedTesting,
  identityVerificationForm,
  addressLookup,
  headerMenu,
  toasts,
  newModals,
  headerBar,
  smsf,
  flows,
  rentToBuyExtraInfo,
});

export default rootReducer;
