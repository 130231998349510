import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Heading } from 'src/design/styleguide/Headings';
import { onboardingProductSelectionUrl, onboardingTmdUrl } from 'src/utils/pageUrls';
import { showModal as showModalAction } from 'src/components/modals/modalActions';
import { trackInvestmentTermQuestion } from 'src/tracking/onboarding';
import FinePrintParagraph from 'src/design/styleguide/FinePrintParagraph';
import OnboardingScreen, {
  OnboardingScreenContent,
  OnboardingScreenHeader,
} from 'src/components/oldOnboarding/common/onboardingScreen/OnboardingScreen';
import SelectionButton from 'src/design/components/button/SelectionButton';
import ShortTermAnswerSelectedPopup from
  'src/components/oldOnboarding/onboardingQuestions/ShortTermAnswerSelectedPopup';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import history from 'src/browser/history';
import OnboardingBackButton from '../common/onboardingBackButton/OnboardingBackButton';

const navigateToNextPage = () => history.push(onboardingProductSelectionUrl());

const AnswerSelectionButton = ({ answer, onClick }) => (
  <Spacing bottom="x-small">
    <SelectionButton fullWidth arrow testRef="answer" onClick={() => onClick(answer)}>{answer}</SelectionButton>
  </Spacing>
);

@connect(null, {
  showModal: showModalAction,
})
export default class OnboardingQuestions extends Component {
  static propTypes = {
    showModal: PropTypes.func,
  };

  render() {
    const { showModal } = this.props;

    const selectAnswer = (answer) => {
      trackInvestmentTermQuestion(answer);
      navigateToNextPage();
    };

    const openModal = (answer) => {
      showModal({
        content: <ShortTermAnswerSelectedPopup onContinue={() => selectAnswer(answer)}/>
      });
    };

    return (
      <OnboardingScreen testRef="onboarding-questions">
        <OnboardingScreenHeader>
          <Spacing bottom="large">
            <Heading>How long are you thinking of investing for?</Heading>
          </Spacing>
        </OnboardingScreenHeader>
        <OnboardingScreenContent>
          <AnswerSelectionButton
            answer="Long term (5+ years)"
            onClick={selectAnswer}
          />
          <AnswerSelectionButton
            answer="Medium term (1-5 years)"
            onClick={selectAnswer}
          />
          <AnswerSelectionButton
            answer="Short term (Less than a year)"
            onClick={openModal}
          />
          <Spacing top="large">
            <FinePrintParagraph testRef="disclaimer">Please note: for this question, we would like this information to understand our investors generally and to help us improve our product and service in the future. We do not provide personal financial product advice.</FinePrintParagraph>
          </Spacing>
        </OnboardingScreenContent>
        <OnboardingBackButton href={onboardingTmdUrl()} />
      </OnboardingScreen>
    );
  }
}
