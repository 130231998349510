import { _getCurrentUser } from 'scripts/interceptors/interceptorHelper';
import {
  acceptPdsUrl,
  emailVerificationUrl,
  identityVerificationUrl,
  loginUrl,
  settingsUrl,
  vedaFailUrl
} from 'src/utils/pageUrls';
import { isLoggedIn } from 'src/utils/user';
import { userSelector } from 'scripts/redux/actions/user';
import Configuration from 'scripts/constants/Configuration';
import RegistrationStep from 'scripts/constants/RegistrationStep';

const stepToRedirect = (step) => ({
  [RegistrationStep.VERIFY]: emailVerificationUrl(),
  [RegistrationStep.PDS]: acceptPdsUrl(),
  [RegistrationStep.SETUP]: identityVerificationUrl(),
  [RegistrationStep.VEDA]: vedaFailUrl(),
})[step];

const redirectToSettingsUrls = [
  identityVerificationUrl(),
  vedaFailUrl(),
];

export const userLoggedInInterceptor = (store, nextState, replaceState) => {
  const { user } = userSelector(store.getState());

  const loggedIn = isLoggedIn(user);
  if (!loggedIn) replaceState({ pathname: loginUrl(), state: { nextPathname: nextState.location.pathname } });
};

export const userWithDetailsCompletedInterceptor = (store, nextState, replaceState) => {
  const { user } = userSelector(store.getState());

  userLoggedInInterceptor(store, nextState, replaceState);

  const redirectUrl = stepToRedirect(user.nextRegistrationStep);

  redirectUrl
    ? nextState.location.pathname !== redirectUrl && replaceState(redirectUrl)
    : redirectToSettingsUrls.includes(nextState.location.pathname) && replaceState(settingsUrl());
};

export const userWithEverythingDoneInterceptor = (store, nextState, replace) => {
  const user = _getCurrentUser(store);
  if (user.nextRegistrationStep !== RegistrationStep.DONE) {
    replace(Configuration.PAGE_URLS.ACCOUNT.DASHBOARD);
  }
};
