import classNames from 'classnames';
import { Heading } from 'src/design/components/heading/Headings';
import styles from 'src/components/landingPages/howItWorks/common/subSectionHeading/SubSectionHeading.mscss';

const SubSectionHeading = ({ className, children, ...props }) => (
  <Heading
    className={classNames(styles.subSectionHeading, className)}
    {...props}
  >
    <h3>{children}</h3>
  </Heading>
);

export default SubSectionHeading;
