import PropTypes from 'prop-types';
import { loginUrl, smsfUrl } from 'src/utils/pageUrls';
import { smsfSignUpAndSetup as smsfSignUpAndSetupAction } from 'scripts/redux/actions/registration';
import{ smsfSubmittingSelector } from 'src/components/landingPages/smsf/smsfReducer';
import {
  startSubmitting as startSubmittingAction,
  stopSubmitting as stopSubmittingAction,
} from 'src/components/landingPages/smsf/smsfActions';
import { trackButtonClick } from 'src/components/landingPages/smsf/tracking';
import Divider from 'src/design/components/divider/Divider';
import FormSubmitButton from 'src/components/landingPages/smsf/forms/FormSubmitButton';
import Paragraph from 'src/design/components/bodyText/Paragraph';
import SignupFormInputs from 'src/components/landingPages/smsf/formInputGroups/SignupFormInputs';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import StyledBodylink from 'src/design/components/hyperlink/StyledBodylink';
import SuperFormInputs from 'src/components/landingPages/smsf/formInputGroups/SuperFormInputs';
import history from 'src/browser/history';
import withSubmittableForm from 'src/decorators/withSubmittableForm';
import { ACCOUNTTYPES } from 'src/settings/registration';

const SwitchToLoginButton = () => (
  <StyledBodylink
    testRef="login-with-redirect-link"
    onClick={() => {
      trackButtonClick({ button: 'Form Login Button', from: 'Logged Out Signup Form' });
      history.push({
        pathname: loginUrl(),
        state: {
          nextPathname: smsfUrl(),
        }
      });
    }}
  >Log in</StyledBodylink>
);

const LoggedOutForm = ({
  values: { givenName, email, password, trustName, abn, trusteeName, showErrors },
  onChange,
  onSubmit,
  ...props
}) => {
  const { smsfSubmitting } = props;

  return (
    <form>
      <SignupFormInputs
        {...{ email, password, givenName, onChange, showErrors }}
      />

      <SuperFormInputs
        {...{ trustName, abn, trusteeName, onChange, showErrors }}
      />

      <FormSubmitButton {...{ onChange, onSubmit, smsfSubmitting }}>
        Sign Up
      </FormSubmitButton>

      <Divider top="normal" bottom="normal">Or</Divider>

      <Spacing top="large">
        <Paragraph align="center">
          Already have an Account? <SwitchToLoginButton />
        </Paragraph>
      </Spacing>
    </form>
  );
};

LoggedOutForm.propTypes = {
  smsfSubmitting: PropTypes.bool,
};

export default withSubmittableForm({
  Form: LoggedOutForm,
  mapDispatchToProps: {
    smsfSignUpAndSetup: smsfSignUpAndSetupAction,
    startSubmitting: startSubmittingAction,
    stopSubmitting: stopSubmittingAction,
  },
  mapPropsToInitialFormValues: () => ({
    givenName: '',
    email: '',
    password: '',
    trustName: '',
    abn: '',
    trusteeName: '',
    showErrors: false,
    submitting: false,
  }),
  mapStateToProps: (state) => ({
    ...smsfSubmittingSelector(state),
  }),
  onSubmit: ({ formValues, props }) => {
    const { givenName, email, password, trustName, abn, trusteeName } = formValues;
    const { smsfSubmitting, smsfSignUpAndSetup, startSubmitting, stopSubmitting } = props;
    const isFormSubmittable = givenName
      && email
      && password
      && trustName
      && abn
      && trusteeName
      && !smsfSubmitting;

    if (isFormSubmittable) {
      startSubmitting();
      trackButtonClick({ button: 'Submit Button', from: 'Logged Out Signup Form' });
      smsfSignUpAndSetup({ givenName, email, password, trustName, abn, trusteeName, accountType: ACCOUNTTYPES.INDIVIDUAL, howDidYouHearAboutUsAnswer: 'SMSF' })
        .catch((e) => {
          console.error('something went wrong!', e);
        })
        .finally(() => stopSubmitting());
    }
  },
});
