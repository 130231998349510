import { createAction, handleActions } from 'redux-actions';
import update from 'immutability-helper';

const MODAL = 'scripts/modals/MODAL';

const modal = createAction(MODAL);

export const openModal = (modalId, data) => modal({ type: modalId, data: data });

const reducer = handleActions({
  [MODAL]: (state, action) => update(state, {
    $set: action.payload
  }),
}, null);

export default reducer;
