import { Fragment, cloneElement } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Heading } from 'src/design/styleguide/Headings';
import { breakpointSmall } from 'src/design/styleguide/common/breakpoints';
import { getFontSize } from 'src/design/styleguide/text/Text';
import { showModal as showModalAction } from 'src/components/modals/modalActions';
import {
  testRefToDataTestReference,
} from 'src/design/styleguide/common/styledComponentsUtils';
import { trackJoinButtonClick } from 'src/tracking/rentToBuyWaitlist';
import Loading from 'src/components/loading/Loading';
import PoppingCard from 'src/design/components/poppingCard/PoppingCard';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import withState from 'src/decorators/withState';
import RegistrationForm from './RegistrationForm';

const RegistrationProvider = ({
  fields,
  children,
  ...props,
}) => !fields ? (
  <Spacing top="7x-large" bottom="7x-large">
    <Loading notFullHeight/>
  </Spacing>
) : cloneElement(children, {
  fields,
  ...props,
});

const RegistrationFormAtfContainer = styled.form.attrs(testRefToDataTestReference)`
  ${breakpointSmall(`
    max-width: 500px;
  `)}
`;

const RegistrationFormFieldsAndButton = connect(null, { showModal: showModalAction })(withState({
  Component: () => {
    return ((
      <Fragment>
        <RegistrationForm />
      </Fragment>
    ));
  },
}));

const RegistrationFormAtf = (props) => (
  <RegistrationFormAtfContainer testRef="atf-registration-widget">
    <RegistrationFormFieldsAndButton
      trackSubmitClick={trackJoinButtonClick('atf')}
      {...props}
    />
  </RegistrationFormAtfContainer>
);

const RegistrationFormBottomContainer = styled.form.attrs(testRefToDataTestReference)`
  padding: 24px;

  ${breakpointSmall(`
    padding: 48px;
  `)}
`;

const BottomHeading = styled(Heading)`
  font-size: ${getFontSize('small')};

  ${breakpointSmall(`
    font-size: ${getFontSize('normal')};
  `)}
`;

const RegistrationFormBottom = ({
  className,
  ...props,
}) => (
  <PoppingCard className={className} withBorderTopFeature>
    <RegistrationFormBottomContainer testRef="bottom-registration-widget">
      <Spacing bottom="3x-large">
        <BottomHeading>
          Register your interest now!
        </BottomHeading>
      </Spacing>

      <RegistrationFormFieldsAndButton
        trackSubmitClick={trackJoinButtonClick('bottom')}
        {...props}
      />
    </RegistrationFormBottomContainer>
  </PoppingCard>
);

export const RegistrationWidgetAtf = (props) => (
  <RegistrationProvider {...props}>
    <RegistrationFormAtf/>
  </RegistrationProvider>
);

export const RegistrationWidgetBottom = (props) => (
  <RegistrationProvider {...props}>
    <RegistrationFormBottom/>
  </RegistrationProvider>
);
