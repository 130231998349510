import { getHarmonySuggestions } from 'src/utils/harmony';

export const ADDRESS_SUGGESTIONS_SUCCESS = 'ADDRESS_SUGGESTIONS_SUCCESS';
export const ADDRESS_SUGGESTIONS_CLEAR = 'ADDRESS_SUGGESTIONS_CLEAR';

export const fetchAddressSuggestions = (address) => (dispatch) => {
  if (address.length < 4) {
    return dispatch({ type: ADDRESS_SUGGESTIONS_CLEAR });
  }

  getHarmonySuggestions(address, (response) => dispatch({ type: ADDRESS_SUGGESTIONS_SUCCESS, payload: response.payload }));
};
