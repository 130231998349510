import Hyperlink from 'src/design/components/hyperlink/Hyperlink';

const PropertyCardContainer = ({ link, children }) => (
  <div className="property-card-container">
    <Hyperlink className="link-no-hover-style" href={link} testRef="whole-card-link">
      {children}
    </Hyperlink>
  </div>
);

export default PropertyCardContainer;
