import Meta from 'src/components/meta/Meta';
import Configuration from 'scripts/constants/Configuration';

const PropertiesMeta = () => <Meta
  title="View the Properties | BrickX"
  description={`Invest in quality Australian properties chosen by our specialist team. Join thousands of Aussies building toward a home of their own.`}
  keywords="Properties"
  image={Configuration.PROPERTY_THUMB_PLACEHOLDER}
/>;

export default PropertiesMeta;
