import {
  ADDRESS_SUGGESTIONS_CLEAR,
  ADDRESS_SUGGESTIONS_SUCCESS
} from 'src/store/addressLookupActions';

export const addressSuggestionsSelector = (state) => ({
  addressSuggestions: state.addressLookup.addressSuggestions,
});

const defaultState = {
  addressSuggestions: [],
};

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case ADDRESS_SUGGESTIONS_SUCCESS: return { ...state, addressSuggestions: payload };
    case ADDRESS_SUGGESTIONS_CLEAR: return { ...state, addressSuggestions: [] };
    default: return state;
  }
};
