import PropertyCardStatLayoutContainer from 'src/components/propertyCards/common/propertyCardBodyFiveStatLayout/PropertyCardStatLayoutContainer';
import PropertyCardFourStatLayout from 'src/components/propertyCards/common/propertyCardBodyFiveStatLayout/PropertyCardFourStatLayout';
import PropertyCardPrimaryStatContainer from 'src/components/propertyCards/common/propertyCardBodyFiveStatLayout/PropertyCardPrimaryStatContainer';

const PropertyCardBodyFiveStatLayout = ({ primary, secondaries }) => (
  <PropertyCardStatLayoutContainer>
    <PropertyCardFourStatLayout
      first={secondaries[0]}
      second={secondaries[1]}
      third={secondaries[2]}
      fourth={secondaries[3]}
    />
    <PropertyCardPrimaryStatContainer primary={primary}/>
  </PropertyCardStatLayoutContainer>
);

export default PropertyCardBodyFiveStatLayout;
