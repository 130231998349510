import {
  flowsFieldsSelector,
  flowsStepIdSelector,
  flowsUserIdSelector,
} from 'src/components/flows/store/flowsReducer';
import { flowsFirstStepUrl, flowsNextStepUrl } from 'scripts/services/api';
import { getPlural } from 'src/utils/formats';
import generateFlowFieldErrorText from 'src/components/flows/common/generateFlowFieldErrorText';
import post from 'scripts/services/request/post';
import request from 'scripts/services/request';

export const GET_STEP_COMPLETE = 'GET_STEP_COMPLETE';
export const FIELD_UPDATED = 'FIELD_UPDATED';
export const ERRORS_UPDATED = 'ERRORS_UPDATED';
export const SERVER_ERRORS_UPDATED = 'SERVER_ERRORS_UPDATED';
export const USER_ID_UPDATED = 'USER_ID_UPDATED';

export const updateUserId = (userId) => ({
  type: USER_ID_UPDATED,
  payload: userId,
});

export const updateField = (fieldId, value) => ({
  type: FIELD_UPDATED,
  payload: { fieldId, value },
});

export const updateErrors = (errors) => ({
  type: ERRORS_UPDATED,
  payload: errors,
});

export const updateServerErrors = (serverErrors) => ({
  type: SERVER_ERRORS_UPDATED,
  payload: serverErrors,
});

export const getNextStep =
  ({ flowType }) =>
  (dispatch, getState) => {
    const { userId } = flowsUserIdSelector(getState());

    const requestFirstStep = () => request(flowsFirstStepUrl(flowType));
    const requestNextStep = () => request(flowsNextStepUrl(flowType, userId));

    const makeRequest = !userId
      ? requestFirstStep
      : () => requestNextStep().catch(requestFirstStep);

    makeRequest().then((res) =>
      dispatch({ type: GET_STEP_COMPLETE, payload: res })
    );
  };

const getMessageFromErrorHint = (hint) =>
  ({
    'invalid-email': 'Please enter a valid email',
    'invalid-password': 'Please enter a valid password',
  }[hint]);

const getCharactersPlural = (number) =>
  getPlural({
    number,
    singular: 'character',
    plural: 'characters',
  });

const getValidationMessage = (validation, value) => {
  switch (validation.rule) {
    case 'regex':
      return (
        !RegExp(validation.regex).test(value) &&
        (getMessageFromErrorHint(validation.hint) ||
          `Your input of '${value}' failed the regex /${validation.regex}/`)
      );

    case 'min-length':
      return (
        value.trim().length < validation.min &&
        `Please enter at least ${validation.min} ${getCharactersPlural(
          validation.min
        )}`
      );

    case 'min-value':
      return (
        value < validation.min &&
        `Please enter a value that is the same or greater than ${validation.min}`
      );

    case 'value-in':
      return !validation.in.includes(value) && `Please select a value`;

    case 'must-be-true': {
      return 'NO';
    }

    default:
      return false;
  }
};

const getFieldEmptyMessage = (fieldType, value) => {
  switch (fieldType) {
    case 'int':
      return typeof value !== 'number' && 'Please enter a value';

    case 'boolean':
      return typeof value !== 'boolean' && 'Please select a value';

    default:
      return false;
  }
};

const getError = (field, messageOverride = {}) => {
  const emptyFieldMessage = getFieldEmptyMessage(field.type, field.value);
  const customEmptyFieldMessage = messageOverride.empty;

  const errors = field.validations
    .map((validation) => {
      const message = getValidationMessage(
        validation,
        field.value,
        messageOverride
      );
      const customMessage = messageOverride[validation.rule];
      return message && customMessage ? customMessage : message;
    })
    .concat([
      emptyFieldMessage && customEmptyFieldMessage
        ? customEmptyFieldMessage
        : emptyFieldMessage,
    ])
    .filter((message) => !!message);

  if (!errors.length) return null;
  return generateFlowFieldErrorText(errors);
};

const validate = (fields, { include, messageOverrides = {} } = {}) => {
  const includedFields =
    include && include.length
      ? fields.filter(({ id }) => include.includes(id))
      : fields;

  return includedFields.reduce((errors, field) => {
    const error = getError(field, messageOverrides[field.id]);
    if (error) return { ...errors, [field.id]: error };

    return errors;
  }, null);
};

export const validateFields = (validateOptions) => (dispatch, getState) => {
  const { fields } = flowsFieldsSelector(getState());
  const errors = validate(fields, validateOptions);
  errors && dispatch(updateErrors(errors));
  return errors;
};

export const submitStep =
  ({ flowType, onSubmitSuccess } = {}) =>
  (dispatch, getState) => {
    const { userId: storedUserId } = flowsUserIdSelector(getState());
    const { fields } = flowsFieldsSelector(getState());
    const { currentStepId } = flowsStepIdSelector(getState());

    const errors = dispatch(validateFields());
    if (errors) return Promise.resolve();

    const fieldsPayload = fields.reduce(
      (acc, field) => ({ ...acc, [field.id]: field.value }),
      {}
    );

    const payload = {
      stepId: currentStepId,
      fields: fieldsPayload,
    };

    const requestUrl = !storedUserId
      ? flowsFirstStepUrl(flowType)
      : flowsNextStepUrl(flowType, storedUserId);

    return post(requestUrl, payload)
      .then((res) => {
        res && res.userId && dispatch(updateUserId(res.userId));
        onSubmitSuccess && onSubmitSuccess(res);
      })
      .then(() => dispatch(getNextStep({ flowType })))
      .catch((e) => dispatch(updateServerErrors(e.message)));
  };
