const depreciationsGetter = (state) => state.financials.depreciations;
const historicalBrickValuesGetter = (state) => state.financials.historicalBrickValues;
const financialMetricsGetter = (state) => state.financials.metrics;


export const depreciationsSelector = (state) => ({
  depreciations: {
    list: depreciationsGetter(state)
  }
});

export const historicalBrickValuesSelector = (state) => ({
  historicalBrickValues: historicalBrickValuesGetter(state)
});

export const financialMetricsSelector = (state) => ({
  metrics: financialMetricsGetter(state)
});
