import request from 'scripts/services/request';
import {
  registerCustomerReferral,
  retrieveCustomerReferral,
  shareViaEmails,
  registerPartnerReferral,
  checkCustomerEligibility,
} from 'scripts/services/api';
import { headers, withCredentials } from 'scripts/services/helpers';


class ReferralService {
  registerCustomerReferral(data) {
    return request(registerCustomerReferral, {
      method: 'POST',
      headers,
      body: JSON.stringify(data)
    });
  }

  registerPartnerReferral(data) {
    return request(registerPartnerReferral, {
      method: 'POST',
      headers,
      ...withCredentials,
      body: JSON.stringify(data)
    });
  }

  shareViaEmails(userId, data) {
    return request(shareViaEmails(userId), {
      ...withCredentials,
      headers,
      method: 'POST',
      body: JSON.stringify(data)
    });
  }

  getCustomerReferral(userId) {
    return request(retrieveCustomerReferral(userId), {
      ...withCredentials,
    });
  }

  checkCustomerReferrerEligibility(userId) {
    return request(checkCustomerEligibility(userId), {
      ...withCredentials,
    });
  }
}

export default new ReferralService();
