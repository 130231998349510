import { Component } from 'react';
import PropTypes from 'prop-types';


export default class DepositLink extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    text: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    angleRight: PropTypes.bool
  };
  render() {
    const { id, text, className, onClick } = this.props;
    return (
      <a href="/account/deposit" id={id} className={className} onClick={onClick}>
        { text
          ?
          text
          :
          'Deposit Funds'
        }
      </a>
    );
  }
}

