import React from 'react';
import PropertyTypes from 'src/enums/propertyTypes';
import Colors from 'src/themes/brickx/colors';
import styled from 'styled-components';

const colorMapping = {
  [PropertyTypes.house]: Colors.primary,
  [PropertyTypes.unit]: Colors.primary,
  [PropertyTypes.multihouse]: Colors.primary,
  [PropertyTypes.multiunit]: Colors.primary,
  [PropertyTypes.offices]: Colors.blue500,
  [PropertyTypes.development]: Colors.blue900,
  [PropertyTypes.commercial]: Colors.blue500,
  [PropertyTypes.farm]: Colors.green,
};

const Layout = styled.div`
  padding: 3px 10px;
  border-radius: 3px;
  font-size: 1.2rem;
  font-weight: 800;
  background-color: ${(props) => props.bgColor || Colors.primary};
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

const PropertyTypeChip = ({ property }) => {
  return (
    <Layout bgColor={colorMapping[property.propertyType]}>
      {property.propertyType}
    </Layout>
  );
};

export default PropertyTypeChip;
