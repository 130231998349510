import classNames from 'classnames';
import Select from 'src/design/components/formSelect/Select';
import styles from 'src/design/components/formSelect/FormSelect.mscss';


const FormSelect = ({
  className,
  selectClassName,
  arrowClassName,
  onChange,
  children,
  fullWidth,
  errorHighlight,
  forId,
  ...otherProps
}) => {
  const classMap = {
    [styles.fullWidth]: fullWidth,
  };

  const selectClassMap = {
    [styles.fullWidth]: fullWidth,
    [styles.formSelect_errorHighlight]: errorHighlight,
  };

  return (
    <Select
      className={classNames(styles.formSelect, classMap, className)}
      selectClassName={classNames(styles.formSelect__select, selectClassMap, selectClassName)}
      arrowClassName={classNames(styles.formSelect__arrow, arrowClassName)}
      onChange={(e) => onChange(e.target.value)}
      id={forId}
      {...otherProps}
    >{children}</Select>
  );
};

export default FormSelect;
