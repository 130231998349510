import { Fragment } from 'react';
import { Heading, HeadingSmall } from 'src/design/components/heading/Headings';
import { customerServiceMemberNickname } from 'src/settings/text';
import { helpCenterUrl } from 'src/utils/pageUrls';
import { hot } from 'react-hot-loader';
import FinePrint from 'src/design/components/bodyText/FinePrint';
import PageMarginContainer from 'src/design/components/pageMarginContainer/PageMarginContainer';
import Paragraph from 'src/design/components/bodyText/Paragraph';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import Styledlink from 'src/design/components/hyperlink/Styledlink';
import styles from 'src/components/terms/ReChangeResults.mscss';

const ParagraphWithoutBreak = (props) => <Paragraph {...props}/>;

const ParagraphWithBreak = (props) => (
  <Fragment>
    <ParagraphWithoutBreak {...props}/>
    <br/>
  </Fragment>
);

const MainHeading = ({ children }) => (
  <Heading>
    <Spacing top="2x-large">
      <h1>{children}</h1>
    </Spacing>
  </Heading>
);

const SubHeading = ({ children }) => (
  <Fragment>
    <HeadingSmall>
      <Spacing top="normal" bottom="normal">
        <h4>{children}</h4>
      </Spacing>
    </HeadingSmall>
  </Fragment>
);

const ReChangeResults = () => (
  <PageMarginContainer size="thin" testRef="re-change-results">
    <MainHeading>Thank you for your support in the Change of Responsible Entity</MainHeading>

    <img
      className={styles.image}
      src="/static/img/illustration-thank-you.svg"
      alt="the words 'thank you' with two people celebrating on either side"
    />

    <Spacing top="2x-large">
      <ParagraphWithBreak>
        We would like to take this opportunity to thank our BrickX Members for supporting us over the last 2 months.
      </ParagraphWithBreak>
    </Spacing>

    <ParagraphWithBreak>
      All Members were invited to vote on the retirement of our existing responsible entity, Theta Asset Management Limited (Theta) and the appointment of BrickX Financial Services Limited (BrickX Financial Services) as the new responsible entity.  The proposed changes support the business in enhancing operations and cost efficiencies for all Members as we continue to grow.
    </ParagraphWithBreak>

    <SubHeading>The results are in</SubHeading>

    <ParagraphWithBreak>
      The vote was held on Tuesday 22 January 2019 to consolidate and calculate the final result. Both <strong>resolution 1</strong> (Retirement of Theta as Responsible Entity) and <strong>resolution 2</strong> (General Power) <strong>passed</strong> with the majority vote*.
    </ParagraphWithBreak>

    <SubHeading>So, what’s next?</SubHeading>

    <ParagraphWithBreak>
      Theta and BrickX Financial Services are currently working together to ensure a smooth transition.  It is important to note that the change in responsible entity will not have an impact on the day to day management of the Platform, however Members can expect to see amendments to the website and key documents to reflect this change.
    </ParagraphWithBreak>

    <ParagraphWithBreak><strong>For any further queries please contact {customerServiceMemberNickname()} via the <Styledlink href={helpCenterUrl()}>Help Center</Styledlink></strong></ParagraphWithBreak>

    <ParagraphWithBreak>
      We look forward to working with our Members as we continue to build the BrickX Platform.
    </ParagraphWithBreak>

    <ParagraphWithBreak>
      Thanks!
    </ParagraphWithBreak>

    <FinePrint>
      *The calculations for the resolutions were independently reviewed and verified by Boroughs Australia Pty Limited.
    </FinePrint>
    <br/>
  </PageMarginContainer>
);

export default hot(module)(ReChangeResults);
