import classNames from 'classnames';

import { connect } from 'react-redux';
import withState from 'src/decorators/withState';
import {
  fetchSmartInvestDetails as fetchSmartInvestDetailsAction,
  smartInvestSelector,
} from 'scripts/redux/actions/smartInvest';
import { userSelector } from 'scripts/redux/selectors/user';

import { Heading } from 'src/design/components/heading/Headings';
import BodyText from 'src/design/components/bodyText/BodyText';
import Paragraph from 'src/design/components/bodyText/Paragraph';
import StyledBodylink from 'src/design/components/hyperlink/StyledBodylink';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import Spacing from 'src/design/styleguide/spacing/Spacing';

import { dollar, shortDate } from 'src/utils/formats';
import { settingsManageUrl } from 'src/utils/pageUrls';
import { getUserBankAccountDetails } from 'src/utils/user';

import styles from 'src/components/oldDashboard/welcomeWidgets/WelcomeWidgets.mscss';


const WelcomeWidget = ({ user, smartInvest, onNext }) => {
  if (!user || !smartInvest) return null;
  const { givenName } = user;
  const { bankAccountName, bankAccountNumber, BSB } = getUserBankAccountDetails(user);
  const { amount, nextDebitDate } = smartInvest;

  return (
    <div
      className={styles.welcomeWidget}
      data-test-reference="welcome-greeting-screen"
    >
      <div className={styles.innerContainer}>
        <img
          className={styles['welcomeWidget--header-image']}
          src="/static/img/icon-champaign-glasses.svg"
          alt="Drawing of a celebration, with champaign glasses and streamers"
        />
        <Heading><h2>Congratulations, <span data-test-reference="given-name">{givenName}</span>!</h2></Heading>
        <Spacing top="large" bottom="large">
          <Paragraph textSize="large">Your investment journey is about to begin.</Paragraph>
        </Spacing>

        {/* @TODO: this will show if they're on the referral path
          <Paragraph>You will get $40 referral reward ..... asdf.k jdlksjadskl;j;daslkj</Paragraph>
        */}

        <Spacing top="large" bottom="large">
          <ul className={styles.directDebitInfo}>
            <li className={styles.directDebitInfoListItem}>
              <BodyText className={styles.directDebitInfoLabel}>Your monthly amount</BodyText>
              <BodyText
                className={styles.directDebitInfoValue}
                testRef="dd-amount"
              >{dollar(amount)}</BodyText>
            </li>

            <li className={classNames(styles.directDebitInfoListItem, styles.highlightedRow)}>
              <BodyText className={styles.directDebitInfoLabel}>First direct debit</BodyText>
              <BodyText
                className={styles.directDebitInfoValue}
                testRef="dd-next-date"
              >{shortDate(nextDebitDate)}</BodyText>
            </li>

            <li className={styles.directDebitInfoListItem}>
              <BodyText className={styles.directDebitInfoLabel}>Your bank account</BodyText>
              <BodyText
                className={styles.directDebitInfoValue}
                testRef="bank-account-details"
              >
                {bankAccountName}<br />
                {BSB}<br />
                {bankAccountNumber}
              </BodyText>
            </li>
          </ul>
        </Spacing>
        <Spacing bottom="large">
          <StyledBodylink fullWidth href={settingsManageUrl()}>Edit</StyledBodylink>
        </Spacing>
        <PrimaryButton
          color={PrimaryButton.colors.SECONDARY}
          onClick={() => onNext()}
          testRef="greeting-got-it-button"
        >Got It</PrimaryButton>
      </div>
    </div>
  );
};

export default connect((state) => ({
  ...userSelector(state),
  ...smartInvestSelector(state),
}),
  {
    fetchSmartInvestDetails: fetchSmartInvestDetailsAction,
  },
)(withState({
  Component: WelcomeWidget,
  componentDidMount: (props) => {
    props.fetchSmartInvestDetails();
  },
}));
