import { connect } from 'react-redux';
import { BrickxLogoRentToBuyDark } from 'src/components/brickxLogo/BrickxLogo';
import { TopLevelMenuItem } from 'src/design/components/header/MenuItemTypes';
import { aboutUrl } from 'src/utils/pageUrls';
import { menuOpenSelector } from 'src/store/headerMenuReducer';
import { scrollToId } from 'src/browser/window';
import {
  toggleMenu as toggleMenuAction,
  closeMenu as closeMenuAction,
} from 'src/store/headerMenuActions';
import Header from 'src/design/components/header/Header';

const RentToBuyHeader = ({ menuOpen, toggleMenu, closeMenu }) => {
  return (
    <Header
      title="Smart Renter"
      logo={<BrickxLogoRentToBuyDark forceExternal/>}
      mobileMenuOpen={menuOpen}
      onLogoClick={closeMenu}
      onHamburgerIconClick={toggleMenu}
    >
      <TopLevelMenuItem onClick={() => scrollToId('why-rent-to-buy')} text="Why Smart Renter"/>
      <TopLevelMenuItem onClick={() => scrollToId('how-rent-to-buy-works')} text="How it Works"/>
      <TopLevelMenuItem onClick={() => scrollToId('rent-to-buy-faqs')} text="FAQs"/>
      <TopLevelMenuItem link={aboutUrl()} text="About Us" forceExternal/>
    </Header>
  );
};

export default connect(menuOpenSelector, {
  toggleMenu: toggleMenuAction,
  closeMenu: closeMenuAction,
})(RentToBuyHeader);
