import React, { Fragment, useState } from 'react';
import { Link } from 'react-router';
import styled from 'styled-components';
import {
  BodyTableRow,
  ColumnHeading,
  Table,
  TableData,
} from 'src/design/components/table/Table';
import { breakpointSmall } from 'src/design/styleguide/common/breakpoints';
import { dateTime, dollarDecimal } from 'scripts/utilities/formatters';
import { sendLinkClickEvent } from 'scripts/redux/actions/segment/events/systemEvents';
import { detailedTransactionsAsCSV } from '../../../ui/scripts/services/api';
import _ from 'lodash';
import { IconButton } from '@mui/material';
import { BsChevronLeft, BsChevronRight, BsDownload } from 'react-icons/bs';
import { renderCode } from 'src/settings/properties';

const ButtonGroupWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px 0;
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
  grid-gap: 10px;
  div {
    /* margin-left: 10px; */
  }
  .button {
    .fa {
      margin: 0 5px;
    }
  }
  .pagination-label {
    margin: 0 10px;
  }
`;

const Details = ({ transaction, onLinkClick }) => {
  if (transaction.transactionType === 'TRADE') {
    return (
      <Link to={transaction.details} onClick={onLinkClick} target="_blank">
        <span className="widget-icon">Download PDF</span>
      </Link>
    );
  }

  return <div>{transaction.details}</div>;
};

const RestrictedWidthTableData = styled(TableData)`
  ${breakpointSmall(`
    white-space: normal;
  `)}
`;

const TransactionsTable = ({
  className,
  transactions,
  trackingLocation,
  testRef,
  userid,
  plan,
  downloadEnabled,
}) => {
  const [page, setPage] = useState(0);

  const nPerPage = 50;
  const pages = _.ceil(transactions.length / nPerPage);
  const paginationEnabled = pages > 1;

  const onPreviousPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  const onNextPage = () => {
    if (page < pages - 1) {
      setPage(page + 1);
    }
  };

  const transactionChunks = _.chunk(transactions, nPerPage);

  return (
    <Fragment>
      <ButtonGroupWrapper>
        {paginationEnabled && (
          <ButtonGroup>
            {downloadEnabled && (
              <IconButton
                onClick={() =>
                  window.location.replace(
                    detailedTransactionsAsCSV(userid, plan)
                  )
                }
              >
                <BsDownload />
              </IconButton>
            )}
            <IconButton onClick={onPreviousPage}>
              <BsChevronLeft />
            </IconButton>
            <div className="pagination-label">
              page {page + 1} of {pages}
            </div>
            <IconButton onClick={onNextPage}>
              <BsChevronRight />
            </IconButton>
          </ButtonGroup>
        )}
      </ButtonGroupWrapper>
      <Table className={className} testRef={testRef}>
        <thead>
          <tr>
            <ColumnHeading>Property</ColumnHeading>
            <ColumnHeading>Reference</ColumnHeading>
            <ColumnHeading>Date</ColumnHeading>
            <ColumnHeading>Transaction</ColumnHeading>
            <ColumnHeading>Amount</ColumnHeading>
            <ColumnHeading>Details</ColumnHeading>
          </tr>
        </thead>
        <tbody>
          {transactionChunks[page].map((transaction, index) => (
            <BodyTableRow key={index} testRef="table-row">
              <TableData testRef="property-code-cell">
                {renderCode(transaction.propertyCode)}
              </TableData>
              <TableData testRef="reference-cell">
                {transaction.reference}
              </TableData>
              <TableData testRef="date-cell">
                {transaction.timestamp::dateTime()}
              </TableData>
              <RestrictedWidthTableData testRef="description-cell">
                {transaction.description}
              </RestrictedWidthTableData>
              <TableData testRef="amount-cell">
                {transaction.amount::dollarDecimal()}
              </TableData>
              <RestrictedWidthTableData testRef="details-cell">
                <Details
                  transaction={transaction}
                  onLinkClick={() =>
                    sendLinkClickEvent({
                      from: trackingLocation,
                      title: 'Download Transaction Certificate File Link',
                    })
                  }
                />
              </RestrictedWidthTableData>
            </BodyTableRow>
          ))}
        </tbody>
      </Table>
    </Fragment>
  );
};

export default TransactionsTable;
