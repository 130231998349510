export const HOW_IT_WORKS_META = {
  title: 'How it Works | Property Investment | BrickX',
  description: "How to Buy and Sell 'Bricks' to make Capital Returns and earn Monthly Distributions by investing in prime Australian real estate for as little as $100.",
  meta: {
    name: {
      keywords: 'How it Works'
    }
  }
};

export const GLOSSARY_META = {
  title: 'Glossary | Property Investment Terms | BrickX',
  description: 'Provide yourself with the essential clarity and understanding needed to make a well informed property investment decision with BrickX.',
  meta: {
    name: {
      keywords: ''
    }
  }
};

export const CONTACT_META = {
  title: 'Contact Us | BrickX',
  description: "If you have any questions or ideas, we'd love to talk to you! Find our contact details here.",
  meta: {
    name: {
      keywords: ''
    }
  }
};


export const PROPERTY_TEAM_META = {
  title: 'Property Team | BrickX Property Experience',
  description: 'The BrickX Property Team combines years of investment and property experience to deliver high quality investment properties to the BrickX Platform. Invest today.',
  meta: {
    name: {
      keywords: ''
    }
  }
};


export const SMSF_META = {
  title: 'SMSF Property Investment | BrickX',
  description: 'Investing in the BrickX Platform is possible with your SMSF. Find out how you can control your investment future through investing in property with BrickX.',
  meta: {
    name: {
      keywords: ''
    }
  }
};


export const LOGIN_META = {
  title: 'Login | BrickX',
  description: 'Create a free account or login to the BrickX Platform to buy and sell Bricks and be a part of the future of Australian property investment. Invest today.',
  meta: {
    name: {
      keywords: ''
    }
  }
};


export const PRIVACY_META = {
  title: 'Privacy Policy | BrickX',
  description: 'Ensuring your privacy is of enormous value to our team. As such, we go to great lengths to ensure your security and privacy on the BrickX Platform.',
  meta: {
    name: {
      keywords: ''
    }
  }
};


export const TERMS_META = {
  title: 'Terms and Conditions | BrickX',
  description: 'Read through our Terms and Conditions to ensure you understand everything you need to know regarding the rules and regulations of the BrickX Platform.',
  meta: {
    name: {
      keywords: ''
    }
  }
};


export const DISCLOSURES_META = {
  title: 'Disclosure for BrickX Returns Calculator | BrickX',
  description: 'Read through our Disclosures to ensure you understand everything you need to know regarding the rules and regulations of the BrickX Platform.',
  meta: {
    name: {
      keywords: ''
    }
  }
};


export const COMPLAINTS_META = {
  title: 'Complaints | BrickX',
  description: 'We hope you will not have cause to do so, but if you feel as though you have a valid complaint, please find the information needed to do so here.',
  meta: {
    name: {
      keywords: ''
    }
  }
};


export const RESET_PASSWORD_META = {
  title: 'Forgot Password | BrickX',
  description: 'Forgotten your password? Follow the instructions to get you back and building your property portfolio in no time.',
  meta: {
    name: {
      keywords: ''
    }
  }
};


export const PDS_META = {
  title: 'PDS | BrickX',
  description: 'Read through our Product Disclosure Statement to learn more and to understand the details of investing in property with BrickX.',
  meta: {
    name: {
      keywords: ''
    }
  }
};


export const SUCCESS_PURCHASE_META = {
  title: 'Successful Trade | Property Investment | BrickX',
  description: '',
  meta: {
    name: {
      keywords: ''
    }
  }
};

export const SUCCESS_SELL_META = {
  title: 'Successful Sell | Property Investment | BrickX',
  description: '',
  meta: {
    name: {
      keywords: ''
    }
  }
};

export const SUCCESS_PREORDER_META = {
  title: 'Successful Preorder | Property Investment | BrickX',
  description: '',
  meta: {
    name: {
      keywords: ''
    }
  }
};


export const RETURNS_CALCULATOR = {
  title: 'Calculate your Potential Returns with BrickX | Property Investment | BrickX',
  description: '',
  meta: {
    name: {
      keywords: ''
    }
  }
};

export const SURVEY_TERMS_AND_CONDITIONS = {
  title: 'Survey Terms and Conditions | Property Investment | BrickX',
  description: '',
  meta: {
    name: {
      keywords: ''
    }
  }
};

export const SEPTEMBER_2018_FACEBOOK_COMPETITION_TERMS_AND_CONDITIONS = {
  title: 'Facebook Competition Sept \'18 Terms and Conditions | BrickX'
};

export const MEDIA = {
  title: 'BrickX in the Media | Property Investment | BrickX',
  description: '',
  meta: {
    name: {
      keywords: ''
    }
  }
};

export const MEDIA_ENQUIRIES = {
  title: 'Media Enquiries | Property Investment | BrickX',
  description: '',
  meta: {
    name: {
      keywords: ''
    }
  }
};


export const NOT_FOUND = {
  title: 'Page not found | Property Investment | BrickX',
  description: '',
  meta: {
    name: {
      keywords: ''
    }
  }
};


export const MAINTENANCE = {
  title: 'Maintenance | Property Investment | BrickX',
  description: '',
  meta: {
    name: {
      keywords: ''
    }
  }
};


export const SESSION_TIMED_OUT = {
  title: 'Session Timed Out | Property Investment | BrickX',
  description: '',
  meta: {
    name: {
      keywords: ''
    }
  }
};
