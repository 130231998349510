
import { Component } from 'react';
import PropTypes from 'prop-types';


export default class ExtendibleText extends Component {
  static propTypes = {
    children: PropTypes.node,
    initialHeight: PropTypes.number.isRequired
  };

  state = {
    extended: false
  };

  render() {
    const { children, initialHeight } = this.props;
    const { extended } = this.state;
    const styleInitial = { height: `${initialHeight}px` };
    const styleExtended = { height: 'auto' };
    const style = extended ? styleExtended : styleInitial;
    return (
      <div className="extendible-name">
        <div className="text" style={style}>
          {children}
        </div>

        { extended ?
          <a onClick={::this._expand}>See less</a>
          :
          <a onClick={::this._expand}>See more</a>
        }
      </div>
    );
  }

  _expand(e) {
    e.preventDefault();
    this.setState({
      extended: !this.state.extended
    });
  }
}
