import { Fragment } from 'react';
import { connect } from 'react-redux';
import { kebabCase } from 'lodash';
import moment from 'moment';
import styled, { css } from 'styled-components';
import { Heading, SubHeading } from 'src/design/styleguide/Headings';
import {
  breakpointSmall,
  breakpointXSmall
} from 'src/design/styleguide/common/breakpoints';
import {
  clearHarmonyObject as clearHarmonyObjectAction,
  harmonyAddressObjectSelector,
  setHarmonyObject as setHarmonyObjectAction,
} from 'src/components/landingPages/brickxAgriculture/extraInfoStore';
import { establishHarmonyAddressLookupTransaction } from 'src/utils/harmony';
import { getThemeColor } from 'src/themes/themeUtils';
import { gridUnit } from 'src/design/styleguide/common/measurements';
import { identityCheckConsentCheckboxText } from 'src/settings/text';
import {
  poppingCardBorderStyles,
  poppingCardBorderTopFeatureStyles,
} from 'src/design/components/poppingCard/poppingCardStyles';
import { slugToTitle } from 'src/utils/formats';
import {
  trackBackClick,
  trackConsentCheckboxChanged,
  trackEditClick,
  trackNextClick,
  trackScreenSeen,
  trackSubmit
} from 'src/tracking/rentToBuyWaitlist';
import AddressInput from 'src/design/components/addressInput/AddressInput';
import ContentBlock from 'src/design/styleguide/contentBlock/ContentBlock';
import DateOfBirth from 'src/design/components/dateOfBirth/DateOfBirth';
import Divider from 'src/design/components/divider/Divider';
import FlowFormInput from 'src/components/flows/common/FlowFormInput';
import FormCheckbox from 'src/design/components/formCheckbox/FormCheckbox';
import FormSelect from 'src/design/components/formSelect/FormSelect';
import Paragraph from 'src/design/styleguide/Paragraph';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import Styledlink from 'src/design/components/hyperlink/Styledlink';
import flowInput from 'src/components/flows/common/flowInput';
import withError from 'src/design/components/textInput/withError';
import withLabel from 'src/design/components/textInput/withLabel';
import withState from 'src/decorators/withState';

const aboutYouScreenHeadingText = 'Tell us about you';
const aboutYourHomeScreenHeadingText = 'About your home';
const whereLiveScreenHeadingText = 'Where do you want to live?';
const lastQuestionsScreenHeadingText = 'A few last questions';
const verifyAnswersHeadingText = 'Review your answers';

const aboutYouFieldIds = [
  'last-name',
  'dob',
  'address',
  'is-aussie',
  'own-buyer',
];

const aboutYouErrorMessages = {
  'last-name': {
    'min-length': 'Please enter enter a valid name',
  },
  dob: {
    'regex': 'Please enter a valid date of birth',
  },
};

const aboutYourHomeFieldIds = [
  'price-range',
  'total-savings',
  'bedrooms-min',
  'special-reqs',
  'first-home-buyer',
];

const aboutYourHomeErrorMessages = {
  'price-range': {
    'min-length': 'Please select a value',
  },
  'total-savings': {
    'min-length': 'Please select a value',
  },
  'bedrooms-min': {
    'empty': 'Please select a value',
  },
};

const whereYouWantToLiveFieldIds = [
  'state',
  'suburbs',
];

const whereYouWantToLiveErrorMessages = {
  'suburbs': {
    'min-length': 'Please enter the suburb(s) you prefer',
  },
};

const lastQuestionsFieldIds = [
  'buy-within-months',
  'provide-testimonials',
];

const lastQuestionsErrorMessages = {
  'buy-within-months': {
    empty: 'Please select a value'
  },
};

const getFieldTitle = (fieldId) => ({
  'last-name': slugToTitle('last-name'),
  'dob': 'Date of Birth',
  'address': slugToTitle('address'),
  'is-aussie': 'Are you an Australian Citizen or Permanent Resident?',
  'own-buyer': 'Will you be buying the property on your own?',
  'price-range': 'How much do you want to spend on your home?',
  'total-savings': 'How much have you saved?',
  'bedrooms-min': 'Minimum Bedrooms',
  'special-reqs': 'Do you have any special requirements?',
  'first-home-buyer': 'Do you currently own any property in Australia?',
  'state': 'Which state?',
  'suburbs': 'Which suburbs?',
  'buy-within-months': 'If deposit was not an issue, when would you want to buy?',
  'provide-testimonials': 'Are you willing to provide testimonials to BrickX?',
})[fieldId];

const getFieldDisplayValue = (id, value) => {
  if (value === true) return 'Yes';
  if (value === false) return 'No';
  if (id === 'dob') return moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY');
  if (id === 'buy-within-months') {
    if (value === 0) return 'Now';
    return `Within ${value} months`;
  }

  return value;
};

const StyledContentBlock = styled(ContentBlock)`
  ${breakpointSmall(css`
    background-color: ${getThemeColor('grey50')};
  `)}
`;

const ScreenContainer = styled.div`
  ${breakpointSmall(css`
    padding: ${gridUnit * 8}px;

    ${poppingCardBorderStyles}
    ${poppingCardBorderTopFeatureStyles}
  `)}
`;

const verticalSpaceBetweenFields = `${gridUnit * 8}px`;
const verticalSpaceBetweenFieldsMinusNormalSpacing = `${gridUnit * (8 - 4)}px`;

const FieldsContainer = styled.div`
  margin-top: ${verticalSpaceBetweenFields};
`;

const TwoFieldsContainer = styled(FieldsContainer)`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  grid-row-gap: ${verticalSpaceBetweenFieldsMinusNormalSpacing};
  grid-template-areas:
    'left'
    'right';

  ${breakpointXSmall(`
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-column-gap: ${gridUnit * 4}px;
    grid-template-areas: '
      left right
    ';
  `)}
`;

const LeftField = styled.div`
  grid-area: left;
`;

const RightField = styled.div`
  grid-area: right;
`;

const FullWidthFieldLayout = ({ field }) => (
  <FieldsContainer>
    {field}
  </FieldsContainer>
);
const TwoFieldLayout = ({ className, leftField, rightField }) => (
  <TwoFieldsContainer className={className}>
    <LeftField>{leftField}</LeftField>
    <RightField>{rightField}</RightField>
  </TwoFieldsContainer>
);

const DateOfBirthWithLabel = withLabel(DateOfBirth);

const serializeDate = (day, month, year) => {
  const dateMoment = moment(new Date(year, month - 1, day));
  return dateMoment.isValid() ? dateMoment.format('YYYY-MM-DD') : '';
};
const deSerializeDate = (dateString) => {
  const parsedDate = moment(dateString, 'YYYY-MM-DD');
  return {
    day: parsedDate.date(),
    month: parsedDate.month() + 1,
    year: parsedDate.year(),
  };
};

const FormDateOfBirth = flowInput(withState({
  mapPropsToInitialState: ({ field }) => field.value ? deSerializeDate(field.value) : {},
  componentDidUpdate: ({ onChange }, { day, month, year }, prevProps, { day: prevDay, month: prevMonth, year: prevYear }) => {
    if (`${day}${month}${year}` === `${prevDay}${prevMonth}${prevYear}`) return;

    const dateString = serializeDate(day, month, year);
    dateString && onChange(dateString);
  },
  Component: ({ setState, state: { day, month, year }, field, ...props }) => (
    <DateOfBirthWithLabel
      day={{
        value: day,
        onChange: (value) => setState({ day: value }),
        testRef: `${field.id}-day-field`,
      }}
      month={{
        value: month,
        onChange: (value) => setState({ month: value }),
        testRef: `${field.id}-month-field`,
      }}
      year={{
        value: year,
        onChange: (value) => setState({ year: value }),
        testRef: `${field.id}-year-field`,
      }}
      error={field.error}
      errorTestRef={`${field.id}-error`}
      {...props}
    />
  ),
}));

const FormSelectWithLabelAndError = withLabel(withError(FormSelect));

const FlowFormSelect = flowInput(({ field, ...props }) => (
  <FormSelectWithLabelAndError
    value={field.value}
    error={field.error}
    testRef={`${field.id}-field`}
    errorTestRef={`${field.id}-error`}
    {...props}
  />
));

const validNumberOrNone = (number) => {
  const parsed = parseInt(number, 10);
  return isNaN(parsed) ? undefined : parsed;
};

const FlowFormNumberSelect = flowInput(({ field, onChange, ...props }) => (
  <FormSelectWithLabelAndError
    value={`${field.value}`}
    error={field.error}
    testRef={`${field.id}-field`}
    errorTestRef={`${field.id}-error`}
    onChange={(value) => onChange(validNumberOrNone(value))}
    {...props}
  />
));

const FlowFormAddressSelect = flowInput(connect(harmonyAddressObjectSelector, { setHarmonyObject: setHarmonyObjectAction, clearHarmonyObject: clearHarmonyObjectAction })(
  withLabel(({ field, onChange, harmonyAddressObject, setHarmonyObject, clearHarmonyObject, ...props }) => (
  <AddressInput
    showError={field.error}
    testRef={`${field.id}-field`}
    errorTestRef={`${field.id}-error`}
    {...props}
    value={field.value}
    harmonyValue={harmonyAddressObject}
    onChange={(value) => {
      onChange(value);
      clearHarmonyObject();
    }}
    onSuggestionSelect={(h) => {
      onChange(h.fullAddress);
      setHarmonyObject(h);
    }}
  />
))));


const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin-top: ${gridUnit * 8}px;

  ${breakpointXSmall(`
    flex-direction: row-reverse;
    justify-content: space-between;
  `)}
`;

const WiderButton = styled(PrimaryButton)`
  width: 100%;

  ${breakpointXSmall(`
    max-width: 200px;
  `)}
`;

const BackButton = ({ className, onClick, ...props }) => (
  <Paragraph
    className={className}
    align="center"
  >
    <Styledlink
      grey
      onClick={onClick}
      testRef="previous-button"
      {...props}
    >
      Go back
    </Styledlink>
  </Paragraph>
);

const NavigationButtons = ({ onNext, onPrevious, ...props }) => (
  <ButtonContainer>
    {onNext && (
      <Spacing top={{ base: 'x-small', breakpointXSmall: 'none' }}>
        <WiderButton
          color={PrimaryButton.colors.SECONDARY}
          onClick={onNext}
          testRef="next-button"
          {...props}
        >
          Next
        </WiderButton>
      </Spacing>
    )}

    {onPrevious && <BackButton onClick={onPrevious}/>}
  </ButtonContainer>
);

const addComponentDidMount = (callback, WrappedComponent) => withState({
  componentDidMount: () => callback(),
  Component: WrappedComponent,
});


const aboutYouName = 'AboutYouScreen';
const AboutYouScreen = addComponentDidMount(
  () => trackScreenSeen({ screenName: aboutYouName }),
  ({ onNext, onPrevious }) => (
    <Fragment>
      <Spacing bottom="2x-large">
        <Heading testRef="screen-title">{aboutYouScreenHeadingText}</Heading>
      </Spacing>

      <Paragraph>Now that you've registered, the next step is for us to gather some details about you.</Paragraph>

      <Paragraph>To <strong>return to this page</strong> and complete your application, please refer to the <strong>link in the email</strong> we've just sent you.</Paragraph>

      <TwoFieldLayout
        leftField={<FlowFormInput fieldId="last-name"/>}
        rightField={<FormDateOfBirth label={getFieldTitle('dob')} fieldId="dob"/>}
      />
      <FullWidthFieldLayout field={<FlowFormAddressSelect label={getFieldTitle('address')} fieldId="address"/>}/>
      <FullWidthFieldLayout field={<FlowFormInput label={getFieldTitle('is-aussie')} fieldId="is-aussie"/>}/>
      <FullWidthFieldLayout field={<FlowFormInput label={getFieldTitle('own-buyer')} fieldId="own-buyer"/>}/>

      <NavigationButtons
        onNext={onNext({ fieldIds: aboutYouFieldIds, messageOverrides: aboutYouErrorMessages })}
        onPrevious={onPrevious}
      />
    </Fragment>
  )
);

const aboutYourHomeName = 'AboutYourHomeScreen';
const AboutYourHomeScreen = addComponentDidMount(
  () => trackScreenSeen({ screenName: aboutYourHomeName }),
  ({ onNext, onPrevious }) => (
    <Fragment>
      <Spacing bottom="2x-large">
        <Heading testRef="screen-title">{aboutYourHomeScreenHeadingText}</Heading>
      </Spacing>
      <FullWidthFieldLayout field={
        <Fragment>
          <FlowFormSelect fieldId="price-range" label={getFieldTitle('price-range')}>
            <option value="">Please Select</option>
            <option value="Less than $400,000">{'Less than $400,000'}</option>
            <option value="$400,000 - $500,000">$400,000 - $500,000</option>
            <option value="$500,000 - $600,000">$500,000 - $600,000</option>
            <option value="$600,000 - $700,000">$600,000 - $700,000</option>
            <option value="$700,000 - $800,000">$700,000 - $800,000</option>
            <option value="$800,000 - $900,000">$800,000 - $900,000</option>
            <option value="$900,000 - $1,000,000">$900,000 - $1,000,000</option>
            <option value="$1,000,000 - $1,100,000">$1,000,000 - $1,100,000</option>
            <option value="Over $1,100,000">Over $1,100,000</option>
          </FlowFormSelect>
        </Fragment>
      }/>

      <FullWidthFieldLayout field={
        <Fragment>
          <FlowFormSelect fieldId="total-savings" label={getFieldTitle('total-savings')}>
            <option value="">Please Select</option>
            <option value="0%">0%</option>
            <option value="<5%">{'<5%'}</option>
            <option value="5-10%">5-10%</option>
          </FlowFormSelect>
        </Fragment>
      }/>


      <FullWidthFieldLayout field={
        <Fragment>
          <FlowFormNumberSelect fieldId="bedrooms-min" label={getFieldTitle('bedrooms-min')}>
            <option value="">Please Select</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
          </FlowFormNumberSelect>
        </Fragment>
      }/>


      <FullWidthFieldLayout field={
        <Fragment>
          <FlowFormInput
            fieldId="special-reqs"
            label={getFieldTitle('special-reqs')}
          />
        </Fragment>
      }/>

      <FullWidthFieldLayout field={<FlowFormInput fieldId="first-home-buyer" label={getFieldTitle('first-home-buyer')}/>}/>
      <NavigationButtons
        onNext={onNext({ fieldIds: aboutYourHomeFieldIds, messageOverrides: aboutYourHomeErrorMessages })}
        onPrevious={onPrevious}
      />
    </Fragment>
  )
);

const whereName = 'WhereScreen';
const WhereScreen = addComponentDidMount(
  () => trackScreenSeen({ screenName: whereName }),
  ({ onNext, onPrevious }) => (
    <Fragment>
      <Spacing bottom="2x-large">
        <Heading testRef="screen-title">{whereLiveScreenHeadingText}</Heading>
      </Spacing>
      <FullWidthFieldLayout field={<FlowFormInput label={getFieldTitle('state')} fieldId="state"/>}/>

      <FullWidthFieldLayout field={
        <FlowFormInput
          fieldId="suburbs"
          label={getFieldTitle('suburbs')}
          placeholder="eg. Epping, Randwick"
        />
      }/>

      <NavigationButtons
        onNext={onNext({ fieldIds: whereYouWantToLiveFieldIds, messageOverrides: whereYouWantToLiveErrorMessages })}
        onPrevious={onPrevious}
      />
    </Fragment>
  )
);

const lastQuestionsName = 'LastQuestionsScreen';
const LastQuestionsScreen = addComponentDidMount(
  () => trackScreenSeen({ screenName: lastQuestionsName }),
  ({ onNext, onPrevious }) => (
    <Fragment>
      <Spacing bottom="2x-large">
        <Heading testRef="screen-title">{lastQuestionsScreenHeadingText}</Heading>
      </Spacing>
      <FullWidthFieldLayout field={
        <Fragment>
          <FlowFormNumberSelect fieldId="buy-within-months" label={getFieldTitle('buy-within-months')}>
            <option value="">Please Select</option>
            <option value="0">Now</option>
            <option value="6">Within 6 months</option>
            <option value="12">Within 12 months</option>
          </FlowFormNumberSelect>
        </Fragment>
      }/>

      <FullWidthFieldLayout field={<FlowFormInput fieldId="provide-testimonials" label={getFieldTitle('provide-testimonials')}/>}/>

      <NavigationButtons
        onNext={onNext({ fieldIds: lastQuestionsFieldIds, messageOverrides: lastQuestionsErrorMessages })}
        onPrevious={onPrevious}
      />
    </Fragment>
  )
);

const SectionHeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SectionHeading = ({ className, title, onEditClick }) => (
  <SectionHeadingContainer className={className}>
    <SubHeading
      align="left"
      fontSize="x-small"
      testRef={`${kebabCase(title)}-section-heading`}
      >
        {title}
      </SubHeading>
      <Paragraph>
        <Styledlink
          grey
          onClick={onEditClick}
          testRef={`${kebabCase(title)}-section-edit-button`}
        >
          Edit
        </Styledlink>
      </Paragraph>
  </SectionHeadingContainer>
);

const FieldAnswer = ({ field }) => (
  <TwoFieldLayout
    leftField={<Paragraph fontWeight="medium">{getFieldTitle(field.id)}</Paragraph>}
    rightField={<Paragraph testRef={`${field.id}-submit-preview-field`}>{getFieldDisplayValue(field.id, field.value)}</Paragraph>}
  />
);

const HeadingDivider = styled(Divider)`
  &::before, &::after {
    background-color: ${getThemeColor('onSurfaceContrast800')};
  }
`;

const AnswersSection = ({ fields, title, onEditClick }) => (
  <Fragment>
    <Spacing top="6x-large">
      <SectionHeading title={title} onEditClick={onEditClick}/>
    </Spacing>
    <HeadingDivider top="normal"/>
    {fields.map((field, index) => (
      <Fragment key={field.id}>
        <FieldAnswer field={field}/>
        {index < fields.length - 1 && <Divider/>}
      </Fragment>
    ))}
  </Fragment>
);

const VerifyScreen = withState({
  componentDidMount: () => trackScreenSeen({ screenName: 'Verify Answers' }),
  Component: ({ fields, jumpToScreen, onSubmit, setState, state }) => (
    <Fragment>
      <Heading testRef="screen-title">{verifyAnswersHeadingText}</Heading>
      <AnswersSection
        fields={fields.filter(({ id }) => aboutYouFieldIds.includes(id))}
        title={aboutYouScreenHeadingText}
        onEditClick={() => jumpToScreen('AboutYouScreen')}
      />

      <AnswersSection
        fields={fields.filter(({ id }) => aboutYourHomeFieldIds.includes(id))}
        title={aboutYourHomeScreenHeadingText}
        onEditClick={() => jumpToScreen('AboutYourHomeScreen')}
      />

      <AnswersSection
        fields={fields.filter(({ id }) => whereYouWantToLiveFieldIds.includes(id))}
        title={whereLiveScreenHeadingText}
        onEditClick={() => jumpToScreen('WhereScreen')}
      />

      <AnswersSection
        fields={fields.filter(({ id }) => lastQuestionsFieldIds.includes(id))}
        title={lastQuestionsScreenHeadingText}
        onEditClick={() => jumpToScreen('LastQuestionsScreen')}
      />

      <Spacing top="4x-large">
        <FormCheckbox
          onChange={(value) => {
            trackConsentCheckboxChanged({ checked: value });
            setState({ consentGiven: value });
          }}
          testRef="ts-and-cs-checkbox"
        >
          {identityCheckConsentCheckboxText()}
        </FormCheckbox>
      </Spacing>

      <Spacing top="2x-large">
        <PrimaryButton
          fullWidth
          color={PrimaryButton.colors.SECONDARY}
          onClick={() => {
            trackSubmit({ lastName: fields.find(({ id }) => id === 'last-name').value });
            onSubmit({ onSubmitSuccess: establishHarmonyAddressLookupTransaction });
          }}
          disabled={!state.consentGiven}
          testRef="submit-button"
        >
          Submit
        </PrimaryButton>
      </Spacing>
    </Fragment>
  ),
});

const screens = {
  AboutYouScreen: { next: 'AboutYourHomeScreen' },
  AboutYourHomeScreen: { next: 'WhereScreen', previous: 'AboutYouScreen' },
  WhereScreen: { next: 'LastQuestionsScreen', previous: 'AboutYourHomeScreen' },
  LastQuestionsScreen: { next: 'VerifyScreen', previous: 'WhereScreen' },
  VerifyScreen: { previous: 'LastQuestionsScreen' },
};

const onNextGenerator = ({ setState, currentScreen, validateFields }) => ({ fieldIds, messageOverrides } = {}) => () => {
  const errors = validateFields({ include: fieldIds, messageOverrides });
  trackNextClick({ screenName: currentScreen, incompleteFields: errors ? Object.keys(errors) : [] });
  !errors && setState({ currentScreen: screens[currentScreen].next });
};

const onPreviousGenerator = ({ setState, currentScreen }) => () => {
  trackBackClick({ screenName: currentScreen });
  setState({ currentScreen: screens[currentScreen].previous });
};

const ExtraInfo = withState({
  mapPropsToInitialState: () => ({
    currentScreen: 'AboutYouScreen',
  }),
  Component: ({ state: { currentScreen }, setState, validateFields, ...props }) => {
    const onNext = onNextGenerator({ setState, currentScreen, validateFields });
    const onPrevious = onPreviousGenerator({ setState, currentScreen });
    const jumpToScreen = (screenName) => {
      trackEditClick({ screenName });
      setState({ currentScreen: screenName });
    };

    return (
      <StyledContentBlock
        topPadding="medium"
        bottomPadding="medium"
        width="small"
        verticallyCentered
        testRef="extra-info-page"
      >
        <ScreenContainer>
          {(() => {
            switch (currentScreen) {
              case 'AboutYouScreen': return (<AboutYouScreen {...props} onNext={onNext}/>);
              case 'AboutYourHomeScreen': return (<AboutYourHomeScreen {...props} onNext={onNext} onPrevious={onPrevious}/>);
              case 'WhereScreen': return (<WhereScreen {...props} onNext={onNext} onPrevious={onPrevious}/>);
              case 'LastQuestionsScreen': return (<LastQuestionsScreen {...props} onNext={onNext} onPrevious={onPrevious}/>);
              case 'VerifyScreen': return <VerifyScreen {...props} jumpToScreen={jumpToScreen} onPrevious={onPrevious}/>;
              default: return null;
            }
          })()}
        </ScreenContainer>
      </StyledContentBlock>
    );
  },
});

export default ExtraInfo;
