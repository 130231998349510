import styled from 'styled-components';
import {
  boldFontWeight,
  lightFontWeight,
  mediumFontWeight,
  regularFontWeight,
  standard,
} from 'src/design/styleguide/common/fonts';
import { getThemeColor } from 'src/themes/themeUtils';
import { gridUnit } from 'src/design/styleguide/common/measurements';
import {
  testRefToDataTestReference,
} from 'src/design/styleguide/common/styledComponentsUtils';
import generateResponsiveStyles from 'src/design/styleguide/common/generateResponsiveStyles';

const getColor = (colorType) => ({
  light: 'white',
})[colorType];

export const getFontWeight = (fontType) => ({
  light: lightFontWeight,
  regular: regularFontWeight,
  medium: mediumFontWeight,
  bold: boldFontWeight,
})[fontType];

export const getFontSize = (fontSize) => ({
  'large': `${gridUnit * 12}px`,
  'normal': `${gridUnit * 10}px`,
  'small': `${gridUnit * 8}px`,
  'x-small': `${gridUnit * 6}px`,
  '1.5x-small': `${gridUnit * 5.5}px`,
  '2x-small': `${gridUnit * 5}px`,
  '3x-small': `${gridUnit * 4.5}px`,
  '4x-small': `${gridUnit * 4}px`,
})[fontSize];

const getLineHeight = (lineHeight) => ({
  large: '1.35',
  medium: '1.15',
  small: '1.1',
  none: '1',
})[lineHeight];

const Text = styled.span.attrs(testRefToDataTestReference)`
  font-family: ${standard};
  ${props => props.align && generateResponsiveStyles(props.align, (value) => `text-align: ${value};`)}
  ${props => props.fontWeight && generateResponsiveStyles(props.fontWeight, (value) => `font-weight: ${getFontWeight(value)};`)}
  ${props => props.fontSize && generateResponsiveStyles(props.fontSize, (value) => `font-size: ${getFontSize(value)};`)}
  ${props => props.lineHeight && generateResponsiveStyles(props.lineHeight, (value) => `line-height: ${getLineHeight(value)};`)}

  ${props => props.color && `
    && {
      color: ${getThemeColor(getColor(props.color) || props.color)(props)};
    }
  `}
`;

export default Text;
