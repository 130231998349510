import ContentBlock from 'src/design/styleguide/contentBlock/ContentBlock';

const pageMarginContainerSizeToContentContainer = (size) => ({
  thin: 'small',
  normal: 'medium',
  wide: 'large',
})[size];

const PageMarginContainer = ({
  className,
  innerContainerClassName,
  children,
  size = 'wide',
  backgroundColor,
  testRef,
  withVerticalMargin,
  ...otherProps
} = {}) => (
  <ContentBlock
    className={className}
    backgroundColor={backgroundColor}
    width={pageMarginContainerSizeToContentContainer(size)}
    innerContainerClassName={innerContainerClassName}
    testRef={testRef}
    withVerticalMargin={withVerticalMargin}
    {...otherProps}
  >
    {children}
  </ContentBlock>
);

PageMarginContainer.colors = {
  GREY: 'lightgrey',
};

PageMarginContainer.sizes = {
  THIN: 'thin',
  NORMAL: 'normal',
  WIDE: 'wide',
};

export default PageMarginContainer;
