import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ReturnsCalculatorReturnDetails from 'scripts/components/calculators/ReturnsCalculatorReturnDetails';
import ReturnsCalculatorAccountValueGraph from 'scripts/components/calculators/ReturnsCalculatorAccountValueGraph';
import InfoBox from 'scripts/components/shared/InfoBox';

import { percentDecimal } from 'scripts/utilities/formatters';

import Trading from 'scripts/constants/Trading';


export default class ReturnsCalculatorResultPanel extends Component {
  static propTypes = {
    className: PropTypes.string,
    hideEstimatedReturn: PropTypes.bool,
    investmentAmount: PropTypes.number.isRequired,
    investmentPeriod: PropTypes.number.isRequired,
    annualGrowthRate: PropTypes.number.isRequired,
    debtRate: PropTypes.number.isRequired,
    netRentalYieldRate: PropTypes.number.isRequired,
    initialPercentOfPropertyAsset: PropTypes.number.isRequired,
    initialPercentOfAcquisitionCost: PropTypes.number.isRequired,
    initialPercentOfCashReserve: PropTypes.number.isRequired,
  };

  render() {
    const {
      className,
      hideEstimatedReturn,
      investmentPeriod,
      investmentAmount,
      annualGrowthRate,
      debtRate,
      netRentalYieldRate,
      initialPercentOfPropertyAsset,
      initialPercentOfAcquisitionCost,
      initialPercentOfCashReserve
    } = this.props;

    return (
      <div className={`return-calculator-results ${className}`}>
        <div className="col-md-6 col-md-push-6">
          <ReturnsCalculatorReturnDetails
            hideEstimatedReturn={hideEstimatedReturn}
            investmentPeriod={investmentPeriod}
            investmentAmount={investmentAmount}
            annualGrowthRate={annualGrowthRate}
            debtRate={debtRate}
            netRentalYieldRate={netRentalYieldRate}
            initialPercentOfPropertyAsset={initialPercentOfPropertyAsset}
            initialPercentOfAcquisitionCost={initialPercentOfAcquisitionCost}
            initialPercentOfCashReserve={initialPercentOfCashReserve}
          />
        </div>
        <div className="col-md-6 col-md-pull-6">
          <ReturnsCalculatorAccountValueGraph
            className="filters"
            investmentPeriod={investmentPeriod}
            investmentAmount={investmentAmount}
            annualGrowthRate={annualGrowthRate}
            debtRate={debtRate}
            netRentalYieldRate={netRentalYieldRate}
            initialPercentOfPropertyAsset={initialPercentOfPropertyAsset}
            initialPercentOfAcquisitionCost={initialPercentOfAcquisitionCost}
            initialPercentOfCashReserve={initialPercentOfCashReserve}
          />
        </div>

        <div className="col-md-12 info-box-disclosures">
          <InfoBox className="small">
            Did you know? The graph illustrates that whether you hold your Bricks for 1 year or 10 years, you only pay the {Trading.BUY_BROKERAGE_PERCENTAGE::percentDecimal()} transaction fee each time you buy and sell. Therefore, the shorter your holding period, the larger the impact of the transaction fees on your Total ROI.
          </InfoBox>
        </div>
      </div>
    );
  }
}
