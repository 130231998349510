import React, { Fragment } from 'react';
import ContentContainer from 'src/design/styleguide/contentContainer/ContentContainer';
import ContentSection from 'src/design/styleguide/contentSection/ContentSection';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import {
  AccentHeadingLight,
  BigSection,
  FaqRow,
  HeaderContent,
  HeaderParagraph,
  LandingPageLayout,
  NumberedPoint,
  SectionHeading,
  SocialLinkIcon,
  SocialLinksContainer,
  SocialLinkShareLabel,
  StaticIconText,
  WhyReasonsContainer,
} from 'src/components/landingPages/common/design/PageContent';
import ContentBlock from 'src/design/styleguide/contentBlock/ContentBlock';
import { Heading } from 'src/design/styleguide/Headings';
import Header from 'src/design/components/header/Header';
import { BrickxLogoDark } from 'src/components/brickxLogo/BrickxLogo';
import { TopLevelMenuItem } from 'src/design/components/header/MenuItemTypes';
import { aboutUrl, getFullImageAssetUrl } from 'src/utils/pageUrls';
import { scrollToId } from 'src/browser/window';
import { menuOpenSelector } from 'src/store/headerMenuReducer';
import {
  toggleMenu as toggleMenuAction,
  closeMenu as closeMenuAction,
} from 'src/store/headerMenuActions';
import { connect } from 'react-redux';
import Footer from 'src/components/footer/Footer';
import Text from 'src/design/styleguide/text/Text';
import ListItemNumber from 'src/components/landingPages/common/listItemNumber/ListItemNumber';

const BrickXSyndicateYourOwnLandingPageHeader = connect(menuOpenSelector, {
  toggleMenu: toggleMenuAction,
  closeMenu: closeMenuAction,
})(({ menuOpen, toggleMenu, closeMenu }) => (
  <Header
    title="Select & Syndicate"
    logo={<BrickxLogoDark />}
    mobileMenuOpen={menuOpen}
    onLogoClick={closeMenu}
    onHamburgerIconClick={toggleMenu}
  >
    <TopLevelMenuItem
      onClick={() => scrollToId('what-it-is')}
      text="What is BrickX Select & Syndicate?"
    />
    <TopLevelMenuItem
      onClick={() => scrollToId('why')}
      text="Why Select & Syndicate?"
    />
    <TopLevelMenuItem onClick={() => scrollToId('faqs')} text="FAQs" />
    <TopLevelMenuItem link={aboutUrl()} text="About Us" forceExternal />
  </Header>
));

const BrickXSyndicateYourOwnLandingPageBody = () => {
  const pagePublicLink = `https://www.brickx.com/private-syndication`;
  return (
    <Fragment>
      <ContentBlock
        overlayOpacity="0.8"
        overlayColor="charcoal"
        backgroundImage={getFullImageAssetUrl('/bg/melbourne-suburb.jpeg')}
      >
        <ContentSection as="section">
          <ContentContainer topPadding="x-large" bottomPadding="x-large">
            <HeaderContent>
              <Heading align="left">
                <AccentHeadingLight>
                  Introducing
                  <br />
                  <strong>BrickX Select & Syndicate</strong>
                </AccentHeadingLight>
              </Heading>
              <Spacing top="normal">
                <HeaderParagraph>
                  <Text color="light">
                    Private property syndications now available!
                  </Text>
                </HeaderParagraph>
              </Spacing>
              <Spacing bottom="normal">
                <HeaderParagraph>
                  <Text color="light">
                    BrickX Select & Syndicate facilitates property acquisitions
                    of your choosing amongst people you know!
                  </Text>
                </HeaderParagraph>
              </Spacing>
              <Spacing bottom="normal">
                <SocialLinksContainer>
                  <SocialLinkShareLabel>
                    <Text color="light">
                      <strong>Share On: </strong>
                    </Text>
                  </SocialLinkShareLabel>
                  <SocialLinkIcon link={pagePublicLink} social="facebook" />
                  <SocialLinkIcon link={pagePublicLink} social="linkedin" />
                  <SocialLinkIcon link={pagePublicLink} social="twitter" />
                  <SocialLinkIcon link={pagePublicLink} social="whatsapp" />
                  <SocialLinkIcon
                    link={pagePublicLink}
                    social="email"
                    icon="envelope"
                  />
                </SocialLinksContainer>
              </Spacing>
            </HeaderContent>
          </ContentContainer>
        </ContentSection>
      </ContentBlock>
      <BigSection
        id="what-it-is"
        as="section"
        topPadding="x-large"
        bottomPadding="x-large"
        backgroundColor="white"
      >
        <ContentSection>
          <SectionHeading>What is BrickX Select & Syndicate?</SectionHeading>
        </ContentSection>
        <Spacing top="large">
          <ContentContainer className={ListItemNumber.counterResetClass}>
            <NumberedPoint
              colorCode="darkblue"
              heading="Select your property"
              text="BrickX will assist you with the acquisition due diligence."
            />
            <NumberedPoint
              colorCode="darkblue"
              heading="Create your syndicate and lock in your funding"
              text="You put together your syndicate of investors who commit to taking up 100% of the Bricks in the BrickX Trust relating to your chosen property."
            />
            <NumberedPoint
              colorCode="darkblue"
              heading="A BrickX Trust buys the property for your syndicate"
              text="BrickX manages the purchase, settlement, funding and administration of your purchase – this even includes the sourcing of debt finance if required."
            />
            <NumberedPoint
              colorCode="darkblue"
              heading="BrickX will handle the boring stuff"
              text="Once the property is purchased BrickX will manage the administrative aspects of the syndication for you."
            />
          </ContentContainer>
        </Spacing>
      </BigSection>

      <BigSection
        id="why"
        as="section"
        topPadding="x-large"
        bottomPadding="x-large"
        backgroundColor="lightgrey"
      >
        <ContentSection>
          <SectionHeading>Why Select & Syndicate?</SectionHeading>
        </ContentSection>
        <WhyReasonsContainer>
          <StaticIconText
            size="medium"
            icon="static/img/brickx-private-syndicate/icon-control-decision.svg"
            title="Control your purchase decision"
            text="See something you like and want to act – go ahead you now can."
          />
          <StaticIconText
            size="medium"
            icon="static/img/brickx-private-syndicate/icon-private-syndicate.svg"
            title="Keep your syndicate private"
            text="You can structure a private syndicate or open it up to other BrickX investors to create liquidity post acquisition."
          />
          <StaticIconText
            size="medium"
            icon="static/img/brickx-private-syndicate/icon-ease-and-efficient.svg"
            title="Ease and Efficiency"
            text="Rather than working out how to establish a private trust of your own and keeping the peace amongst investors you can rely on BrickX to manage this for you."
          />
        </WhyReasonsContainer>
      </BigSection>
      <BigSection id="faqs" width="large">
        <SectionHeading>FAQs</SectionHeading>
        <hr />
        <FaqRow>
          <Text className="question">Who can apply?</Text>
          <Text className="answer">
            BrickX Select & Syndicate has been designed to enable BrickX members
            to choose their own property for syndication – they are also enabled
            to lead and limit the syndication to investors of their choosing –
            while also able to later open the offer up to other investors
            through the BrickX Platform should they want to.
            <br />
            Any Australian tax resident that are over the age of 18.
          </Text>
        </FaqRow>
        <hr />
        <FaqRow>
          <Text className="question">How is the lease structured?</Text>
          <Text className="answer">
            Properties are always purchased subject to an acceptable lease being
            in place.
            <br />
            Rent is calculated based on the agreed rate at the time the lease is
            initiated and will be reviewed on an annual basis to ensure it is in
            line with agreed lease terms.
          </Text>
        </FaqRow>
        <hr />
        <FaqRow>
          <Text className="question">
            Can Debt be used to purchase the property?
          </Text>
          <Text className="answer">
            Yes - BrickX can assist you with placing debt into the Trust to
            facilitate the property purchase. If debt is used to fund the
            initial purchase of the property the debt is held in the name of the
            BrickX Trust and not yours, the debt itself will be secured by the
            property.
          </Text>
        </FaqRow>
        <hr />
        <FaqRow>
          <Text className="question">How is the property selected?</Text>
          <Text className="answer">
            The BrickX property team will review properties you select ensuring
            that the assets are acceptable to the BrickX Platform.
            <br />
            The BrickX Property Investment Management team will also assist with
            the financial modelling to assess the properties ability to cover
            its costs, as well as the costs involved in running a compliant
            trust and also to provide you with a prospective forecast in
            respects of any potential income that it may generate.
          </Text>
        </FaqRow>
        <hr />
        <FaqRow>
          <Text className="question">
            What happens at the end of the trust term?
          </Text>
          <Text className="answer">
            The initial trust term is 5 years.
            <br />
            At the end of a trust term, the standard BrickX processes will kick
            in:
            <br />A member vote will be managed where you and your syndicate
            will vote on whether you want to:
            <ul>
              <li>Sell the property;</li>
              <li>Extend the term.</li>
            </ul>
          </Text>
        </FaqRow>
        <hr />
        <FaqRow>
          <Text className="question">Can I rent or sublease the property?</Text>
          <Text className="answer">
            Yes - For the duration of the agreement, state-based standard
            tenancy rules will apply.
          </Text>
        </FaqRow>
        <hr />
        <FaqRow>
          <Text className="question">What documents will I need to apply?</Text>
          <Text className="answer">
            Once you have completed the initial application, BrickX will touch
            base with you to discuss your property buying strategy with you and
            the BrickX process in more detail.
          </Text>
        </FaqRow>
        <hr />
      </BigSection>
    </Fragment>
  );
};

const BrickXSyndicateYourOwnLandingPage = () => {
  return (
    <LandingPageLayout>
      <BrickXSyndicateYourOwnLandingPageHeader />
      <BrickXSyndicateYourOwnLandingPageBody />
      <Footer />
    </LandingPageLayout>
  );
};

export default BrickXSyndicateYourOwnLandingPage;
