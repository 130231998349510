import Meta from 'src/components/meta/Meta';

const SmsfMeta = () => (
  <Meta
    title="Invest with your SMSF or Trust | BrickX"
    description="Diversify your SMSF or Trust portfolio with residential property. Setting up your account is simple."
  />
);

export default SmsfMeta;
