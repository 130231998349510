import { headers, withCredentials } from 'scripts/services/helpers';
import {
  profile,
  updatePassword,
  deactivateAccount,
  annualIndividualStatement,
  isMember,
  bafm,
  detailedTransactions,
  detailedTransactionsWithLimit,
  detailedTransactionsForYear,
  tfnDetails,
  bankDetails,
  financialDetails,
  emailSubscriptions,
  contact,
  contactWithAttachments,
  requestEmailResetPasswordToken,
  validateResetPasswordToken,
  processResetPassword,
  depositTransactions,
  depositInfo,
  userAccount,
  login,
  refreshSession,
  bpay,
  bpayDepositEmail,
  businessDetails,
  smsOtp,
  dashboardAlert,
  individualTaxReportUrl,
  updateAddressUrl,
  updateMobileNumberUrl,
  updateEmailUrl,
  updateBankDetailsUrl,
  distributionStatementUrl,
} from 'scripts/services/api';
import request from 'scripts/services/request';

class UserService {
  getUserDetails(userId = this.userId) {
    return request(profile(userId), {
      ...withCredentials,
    });
  }

  getBpayDetails(userId) {
    return request(bpay(userId), {
      ...withCredentials,
    });
  }

  sendBpayDepositEmail(userId) {
    return request(bpayDepositEmail(userId), {
      method: 'POST',
      ...withCredentials,
    });
  }

  updateUserDetails(userId = this.userId, details) {
    return request(profile(userId), {
      method: 'PUT',
      ...withCredentials,
      headers,
      body: JSON.stringify(details),
    });
  }

  saveTfnDetails(userId = this.userId, details) {
    return request(tfnDetails(userId), {
      method: 'PUT',
      ...withCredentials,
      headers,
      body: JSON.stringify(details),
    });
  }

  saveBankDetails(userId = this.userId, details) {
    return request(bankDetails(userId), {
      method: 'PUT',
      ...withCredentials,
      headers,
      body: JSON.stringify(details),
    });
  }

  saveEmailSubscriptions(userId = this.userId, subscriptionPreferences) {
    return request(emailSubscriptions(userId), {
      method: 'PUT',
      ...withCredentials,
      headers,
      body: JSON.stringify(subscriptionPreferences),
    });
  }

  getFinancialDetails(userId = this.userId) {
    return request(financialDetails(userId), {
      ...withCredentials,
    });
  }

  getUserEmailSubscriptions(userId = this.userId) {
    return request(emailSubscriptions(userId), {
      ...withCredentials,
    });
  }

  updatePassword(userId = this.userId, passwords) {
    return request(updatePassword(userId), {
      method: 'PUT',
      ...withCredentials,
      headers,
      body: JSON.stringify(passwords),
    });
  }

  getAccountInfo(userId = this.userId) {
    return request(userAccount(userId), {
      ...withCredentials,
    });
  }

  getDetailedTransactions(userId = this.userId) {
    return request(detailedTransactions(userId), {
      ...withCredentials,
    });
  }

  getDetailedTransactionsWithLimit(userId = this.userId, limit) {
    return request(detailedTransactionsWithLimit(userId, limit), {
      ...withCredentials,
    });
  }

  getDetailedTransactionsForYear(userId, year) {
    return request(detailedTransactionsForYear(userId, year), {
      ...withCredentials,
    });
  }

  getDepositTransactions(userId, depositId) {
    return request(depositTransactions(userId, depositId), {
      ...withCredentials,
    });
  }

  getDepositInfo(userId, depositId) {
    return request(depositInfo(userId, depositId), {
      ...withCredentials,
    });
  }

  getMemberStatus(userId = this.userId) {
    return request(isMember(userId), {
      ...withCredentials,
    });
  }

  getBafm(userId = this.userId) {
    return request(bafm(userId), {
      ...withCredentials,
    });
  }

  deactivateAccount(userId = this.userId) {
    return request(deactivateAccount(userId), {
      method: 'POST',
      ...withCredentials,
    });
  }

  annualIndividualStatement(userId = this.userId, year) {
    return request(annualIndividualStatement(userId, year), {
      ...withCredentials,
    });
  }

  contact(data) {
    return request(contact(), {
      method: 'POST',
      ...withCredentials,
      headers,
      body: JSON.stringify(data),
    });
  }

  contactWithAttachments(data) {
    return request(contactWithAttachments(), {
      method: 'POST',
      ...withCredentials,
      headers,
      body: JSON.stringify(data),
    });
  }

  login(data) {
    return request(login(), {
      method: 'POST',
      ...withCredentials,
      headers,
      body: JSON.stringify(data),
    });
  }

  refreshSession(userId, data) {
    return request(refreshSession(userId), {
      method: 'POST',
      ...withCredentials,
      headers,
      body: JSON.stringify(data),
    });
  }

  requestResetPasswordToken(email) {
    return request(requestEmailResetPasswordToken(), {
      method: 'POST',
      ...withCredentials,
      headers,
      body: JSON.stringify(email),
    });
  }

  validateResetPasswordToken(token) {
    return request(validateResetPasswordToken(), {
      method: 'POST',
      headers,
      ...withCredentials,
      body: JSON.stringify({ token: token }),
    });
  }

  processResetPassword(data, token) {
    return request(processResetPassword(), {
      method: 'POST',
      ...withCredentials,
      headers,
      body: JSON.stringify({
        password: data.password,
        confirmPassword: data.confirmPassword,
        token: token,
      }),
    });
  }

  getBusinessDetails(userId) {
    return request(businessDetails(userId), {
      ...withCredentials,
    });
  }

  getSmsOtp(userId = this.userId) {
    return request(smsOtp(userId), {
      ...withCredentials,
    });
  }

  getDashboardAlert(userId = this.userId) {
    return request(dashboardAlert(userId), {
      ...withCredentials,
    });
  }

  getIndividualTaxReportUrl(userId = this.userId, year) {
    return request(individualTaxReportUrl(userId, year), {
      ...withCredentials,
    });
  }

  updateAddress(userId, payload) {
    return request(updateAddressUrl(userId), {
      method: 'PUT',
      headers,
      ...withCredentials,
      body: JSON.stringify(payload),
    });
  }

  updateMobileNumber(userId, payload) {
    return request(updateMobileNumberUrl(userId), {
      method: 'PUT',
      headers,
      ...withCredentials,
      body: JSON.stringify(payload),
    });
  }

  updateEmail(userId, payload) {
    return request(updateEmailUrl(userId), {
      method: 'PUT',
      headers,
      ...withCredentials,
      body: JSON.stringify(payload),
    });
  }

  updateBankDetails(userId, payload) {
    return request(updateBankDetailsUrl(userId), {
      method: 'PUT',
      headers,
      ...withCredentials,
      body: JSON.stringify(payload),
    });
  }

  getDistributionStatement({ userId, startDate, endDate }) {
    return request(distributionStatementUrl(userId, startDate, endDate), {
      method: 'GET',
      headers,
      ...withCredentials,
    });
  }
}

export default new UserService();
