import PropTypes from 'prop-types';
import { number } from 'scripts/utilities/formatters';
import {
  property as propertyType,
  propertyAverageAnnualGrowthMetrics as historicalGrowthMetricsType,
} from 'scripts/constants/PropTypes';
import BrickPriceStat from 'src/components/propertyCards/common/stats/BrickPriceStat';
import EstNetRentalYieldStat from 'src/components/propertyCards/common/stats/EstNetRentalYieldStat';
import HistoricalSuburbGrowthStat from 'src/components/propertyCards/common/stats/HistoricalSuburbGrowthStat';
import LowestAvailableBrickPriceStat from 'src/components/propertyCards/propertyCardMarketOpen/lowestAvailableBrickPriceStat/LowestAvailableBrickPriceStat';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import PropertyCardBodyFiveStatLayout from 'src/components/propertyCards/common/propertyCardBodyFiveStatLayout/PropertyCardBodyFiveStatLayout';
import PropertyCardButtonContainer from 'src/components/propertyCards/common/propertyCardButtonContainer/PropertyCardButtonContainer';
import PropertyCardContainer from 'src/components/propertyCards/common/propertyCardContainer/PropertyCardContainer';
import PropertyCardFeature from 'src/components/propertyCards/common/propertyCardHero/PropertyCardHero';
import PropertyCardHeader from 'src/components/propertyCards/common/propertyCardHeader/PropertyCardHeader';
import PropertyCardStat from 'src/components/propertyCards/common/propertyCardStat/PropertyCardStat';

const PropertyCardMarketOpen = ({
  property,
  historicalGrowthMetrics,
  flag,
}) => {
  return (
    <PropertyCardContainer link={`/properties/${property.propertyCode}`}>
      <PropertyCardHeader property={property} flag={flag} />
      <PropertyCardFeature property={property} />
      <PropertyCardBodyFiveStatLayout
        primary={<LowestAvailableBrickPriceStat property={property} />}
        secondaries={[
          <EstNetRentalYieldStat property={property} />,
          <HistoricalSuburbGrowthStat
            historicalGrowthMetrics={historicalGrowthMetrics}
            propertyType={property.propertyType}
          />,
          <BrickPriceStat property={property} />,
          <PropertyCardStat
            label="Investors"
            value={
              property.investmentMetrics
                ? property.investmentMetrics.investors::number()
                : '-'
            }
            valueProps={{ 'data-test-reference': 'investors' }}
          />,
        ]}
      />
      <PropertyCardButtonContainer>
        <PrimaryButton
          className="property-card__button"
          color={PrimaryButton.colors.SECONDARY}
          arrow
          testRef="button"
        >
          VIEW PROPERTY
        </PrimaryButton>
      </PropertyCardButtonContainer>
    </PropertyCardContainer>
  );
};

PropertyCardMarketOpen.propTypes = {
  property: propertyType,
  historicalGrowthMetrics: historicalGrowthMetricsType,
  flag: PropTypes.element,
};

export default PropertyCardMarketOpen;
