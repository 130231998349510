import DocumentMeta from 'react-document-meta';
import { TRANSACTION_HISTORY_META } from 'scripts/metadata/account';
import NoTransactionsMessageWithIllustration from 'src/components/transactions/NoTransactionsMessageWithIllustration';
import TransactionsTable from 'src/components/transactions/TransactionsTable';
import TransactionsTabs from 'src/components/transactions/TransactionsTabs';
import WidgetPanel from 'src/components/dashboard/common/WidgetPanel';
import withDetailedTransactions from 'src/decorators/withDetailedTransactions';

const Transactions = withDetailedTransactions(
  ({ detailedTransactions, user }) => (
    <WidgetPanel title="Transaction History" testRef="transactions-page">
      <DocumentMeta {...TRANSACTION_HISTORY_META} />
      <TransactionsTabs
        detailedTransactions={detailedTransactions}
        noTransactionsMessage={<NoTransactionsMessageWithIllustration />}
        renderTransactionsTable={(transactions, plan) => (
          <TransactionsTable
            downloadEnabled
            transactions={transactions}
            trackingLocation="My Transactions Page"
            userid={user.id}
            plan={plan}
          />
        )}
      />
    </WidgetPanel>
  )
);

export default Transactions;
