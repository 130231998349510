import classNames from 'classnames';

import { Heading, HeadingXSmall } from 'src/design/components/heading/Headings';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import Paragraph from 'src/design/components/bodyText/Paragraph';
import Carousel from 'scripts/components/shared/Carousel';

import styles from 'src/components/landingPages/common/testimonialsCarousel/TestimonialsCarousel.mscss';

const TestimonialSlide = ({ image, person, quote, className }) => (
  <div className={classNames(className, styles.testimonialSlide)}>
    <img className={styles.testimonialImage} src={image} alt={person} />
    <Spacing top="normal" bottom="normal">
      <HeadingXSmall className={styles.testimonialPerson}><h4>{person}</h4></HeadingXSmall>
    </Spacing>
    <Paragraph textSize="small" className={styles.testimonialQuote}>{quote}</Paragraph>
  </div>
);

const TestimonialsCarousel = ({ title, className }) => {
  return (
    <section className={classNames(styles.brickXTestimonials, className)}>
      <div className={styles.innerContainer}>
        <Heading align="center"><h2>{title}</h2></Heading>
        <Spacing top="2x-large" bottom="2x-large">
          <Carousel
            dontLoop
            disableNav
            useDots
            showOneSlideOnly
            dotsClass={styles.testimonialDots}
            dotClass={styles.testimonialDot}
            className={styles.testimonialCarousel}
          >
            <TestimonialSlide
              image="/static/img/testimonial-katie.png"
              person="Katie - Brick Owner since 2016"
              quote="Entering the property market right now is unrealistic for me because of the price. BrickX has changed that. BrickX helps me save money in line with the property market and it only takes minutes to invest."
            />
            <TestimonialSlide
              image="/static/img/testimonial-oscar.png"
              person="Oscar - Brick Owner since 2016"
              quote="With BrickX I can instantly invest in property at really affordable prices. BrickX offers a range of excellent investment properties to choose from. With all the data BrickX provides, I feel confident in my investment decisions."
            />
            <TestimonialSlide
              image="/static/img/testimonial-petar.png"
              person="Petar - Brick Owner since 2016"
              quote="BrickX allows me to have a diverse portfolio without the need for tens-of-thousands-of-dollars up front for equity. I also don't have the hassle of managing tenants or needing insurance. I use BrickX as a savings and investment account; one in which I see real value and growth."
            />
          </Carousel>
        </Spacing>
      </div>
    </section>
  );
};

export default TestimonialsCarousel;
