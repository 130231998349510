import DirectDebitRequestServiceAgreementLink from 'src/components/oldOnboarding/smartInvestDetails/bankDetailsInput/DirectDebitRequestServiceAgreementLink';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import ExpandingInfoBox from 'src/design/components/expandingInfoBox/ExpandingInfoBox';
import Paragraph from 'src/design/components/bodyText/Paragraph';

const directDebitRequestServiceAgreementLink = <DirectDebitRequestServiceAgreementLink testRef="direct-debit-request-service-agreement-expandy-text-link">The Brick Exchange Pty Ltd Direct Debit Request Service Agreement</DirectDebitRequestServiceAgreementLink>;

const DirectDebitExpandableAgreements = ({ className, testRef }) => (
  <ExpandingInfoBox
    title="Request and Authority to debit"
    testRef={testRef}
    className={className}
  >
    <Spacing bottom="normal">
      <Paragraph>You request and authorise The Brick Exchange Pty Ltd (ID no. 510216; ABN 27 600 762 749) to arrange, through its own financial institution, a debit to Your nominated account any amount The Brick Exchange Pty Ltd has deemed payable by You.</Paragraph>
    </Spacing>
    <Spacing bottom="normal">
      <Paragraph>This debit or charges will be made through the Bulk Electronic Clearing System (BECS) from Your account held at the financial institution You have nominated above and will be subject to the terms and conditions of {directDebitRequestServiceAgreementLink}.</Paragraph>
    </Spacing>
    <Spacing bottom="normal">
      <Paragraph>By signing and/or providing us with a valid instruction in respect to this Direct Debit Request, You have understood and agreed to the terms and conditions governing the debit
    arrangements between You and The Brick Exchange Pty Ltd as set out in this Request and in {directDebitRequestServiceAgreementLink}.</Paragraph>
    </Spacing>
  </ExpandingInfoBox>
);


export default DirectDebitExpandableAgreements;
