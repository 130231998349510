import IdentityVerificationFormMinor from './IdentityVerificationFormMinor';
import IdentityVerificationFormIndividual from './IdentityVerificationFormIndividual';
import { ACCOUNTTYPES } from 'src/settings/registration';
import IdentityVerificationFormSmsfAndTrust from './IdentityVerificationFormSmsf';

const IdentityVerificationForm = (props) => {
  if (props.user) {
    if (props.user.accountType === ACCOUNTTYPES.MINOR.toUpperCase()) {
      return <IdentityVerificationFormMinor {...props} />;
    }
    if (props.user.accountType === ACCOUNTTYPES.SMSF.toUpperCase()) {
      return <IdentityVerificationFormSmsfAndTrust {...props} />;
    }
    if (props.user.accountType === ACCOUNTTYPES.TRUST.toUpperCase()) {
      return <IdentityVerificationFormSmsfAndTrust {...props} />;
    }
    return <IdentityVerificationFormIndividual {...props} />;
  }
};

export default IdentityVerificationForm;
