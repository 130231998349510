import PageMarginContainer from 'src/design/components/pageMarginContainer/PageMarginContainer';
import { HeadingLarge } from 'src/design/components/heading/Headings';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import Paragraph from 'src/design/components/bodyText/Paragraph';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import SubFooter from 'src/components/subFooter/SubFooter';

import DocumentMeta from 'react-document-meta';
import { NOT_FOUND } from 'scripts/metadata/statics';

import { homeUrl } from 'src/utils/pageUrls';

import styles from 'src/components/landingPages/notFound/NotFoundPage.mscss';

const NotFound = () => (
  <div className={styles.notFoundPage}>
    <DocumentMeta {...NOT_FOUND} />
    <PageMarginContainer
      className={styles['notFoundPage--container']}
      innerContainerClassName={styles['notFoundPage--innerContainer']}
    >
      <HeadingLarge>
        <h1>404 Error</h1>
      </HeadingLarge>
      <Spacing top="x-large" bottom="3x-large">
        <Paragraph>Sorry, we couldn't find that page.</Paragraph>
      </Spacing>
      <PrimaryButton link={homeUrl()} color={PrimaryButton.colors.SECONDARY}>
        Back to Home
      </PrimaryButton>
    </PageMarginContainer>
    <SubFooter />
  </div>
);

export default NotFound;
