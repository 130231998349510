import { connect } from 'react-redux';
import { modalsSelector } from 'src/components/modals/modalsReducer';

import Modal from 'src/components/modals/Modal';

import styles from 'src/components/modals/Modals.mscss';

const Modals = ({ modals }) => {
  return modals.length > 0
    ? (
      <ul
        className={styles.modals}
        data-test-reference="modals-listing"
      >
        {modals.map(modal => <Modal key={modal.id} {...modal} />)}
      </ul>
    )
    : null;
};

export default connect(modalsSelector)(Modals);
