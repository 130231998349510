import {
  Box,
  Button,
  ButtonGroup,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { MdBarChart } from 'react-icons/md';
import { ChartJsLineWrapper } from 'scripts/components/chartjs/ChartJsWrapper';
import PropertyService from 'scripts/services/PropertyService';
import Formatters from 'scripts/utilities/formattersV2';
import Loading from 'src/components/loading/Loading';
import Colors from 'src/themes/brickx/colors';
import moment from 'moment';
import MuiTheme from 'src/themes/mui/theme';

const PeriodEnums = {
  all: 'all',
  ytd: 'ytd',
  m6: 'm6',
  m12: 'm12',
  m24: 'm24',
  m36: 'm36',
};

const generateChartConfig = (timeseries, compareWith, period) => {
  let timeseriesToDisplay = [];
  if (period === PeriodEnums.all) {
    timeseriesToDisplay = timeseries;
  } else if (period === PeriodEnums.ytd) {
    timeseriesToDisplay = timeseries.filter(
      (ele) => moment(ele.date) >= moment().startOf('year')
    );
  } else if (period === PeriodEnums.m6) {
    timeseriesToDisplay = timeseries.filter(
      (ele) => moment(ele.date) >= moment().subtract(6, 'M')
    );
  } else if (period === PeriodEnums.m12) {
    timeseriesToDisplay = timeseries.filter(
      (ele) => moment(ele.date) >= moment().subtract(12, 'M')
    );
  } else if (period === PeriodEnums.m24) {
    timeseriesToDisplay = timeseries.filter(
      (ele) => moment(ele.date) >= moment().subtract(24, 'M')
    );
  } else if (period === PeriodEnums.m36) {
    timeseriesToDisplay = timeseries.filter(
      (ele) => moment(ele.date) >= moment().subtract(36, 'M')
    );
  }
  return {
    type: 'line',
    data: {
      datasets: [
        {
          type: 'line',
          label: `Avg Brick Price ($/brick)`,
          data: timeseriesToDisplay.map((ele) => ele['price']),
          borderColor: Colors.primary,
          fill: 'start',
          backgroundColor: (context) => {
            const ctx = context.chart.ctx;
            const gradient = ctx.createLinearGradient(0, 0, 0, 200);
            gradient.addColorStop(0, 'rgba(255,130,130, 0.4)');
            gradient.addColorStop(1, 'rgba(255, 118, 118, 0.1)');
            return gradient;
          },
          tension: 0.2,
          pointRadius: 4,
        },
        ...(compareWith === 'brick-value'
          ? [
              {
                type: 'bar',
                label: `Brick Value ($/brick)`,
                data: timeseriesToDisplay.map((ele) => ele['brickValue']),
                backgroundColor: Colors.grey500,
                tension: 0.2,
                pointRadius: 0,
                borderRadius: 6,
                maxBarThickness: 40,
                yAxisID: 'y1',
              },
            ]
          : []),
        ...(compareWith === 'property-value'
          ? [
              {
                type: 'bar',
                label: `Property Value ($)`,
                data: timeseriesToDisplay.map(
                  (ele) => ele['propertyValuation']
                ),
                backgroundColor: Colors.blue500,
                tension: 0.2,
                pointRadius: 0,
                borderRadius: 6,
                maxBarThickness: 40,
                yAxisID: 'y1',
              },
            ]
          : []),
        ...(compareWith === 'volume'
          ? [
              {
                type: 'bar',
                label: `Volume (bricks)`,
                data: timeseriesToDisplay.map((ele) => ele['quantity']),
                backgroundColor: Colors.blue800,
                tension: 0.2,
                pointRadius: 0,
                borderRadius: 6,
                maxBarThickness: 40,
                yAxisID: 'y1',
              },
            ]
          : []),
      ],
      labels: timeseriesToDisplay.map((ele) => ele['date']),
    },
    options: {
      animation: {
        duration: 200,
      },
      maintainAspectRatio: false,
      spanGaps: true,
      scales: {
        x: {
          type: 'time',
          time: {
            unit: 'month',
            unitStepSize: 1,
            tooltipFormat: 'MMM YY',
            displayFormats: {
              month: 'MMM YY',
            },
          },

          grid: {
            display: false,
          },
          ticks: {
            color: '#ffffff',
          },
        },
        y: {
          ticks: {
            color: '#ffffff',
            callback: (v) => Formatters.numeric.dollarDecimal(v),
          },
          beginAtZero: false,
          grid: {
            color: '#EDF2F7',
            display: false,
          },
          title: {
            display: true,
            text: 'Brick Price',
            color: '#fff',
          },
        },
        ...(compareWith === 'brick-value'
          ? {
              y1: {
                ticks: {
                  color: '#ffffff',
                  callback: (v) => Formatters.numeric.dollarDecimal(v),
                },
                position: 'right',
                // beginAtZero: false,
                grid: {
                  color: '#EDF2F7',
                  display: false,
                },
                title: {
                  display: true,
                  text: 'Brick Value',
                  color: '#fff',
                },
              },
            }
          : {}),
        ...(compareWith === 'property-value'
          ? {
              y1: {
                ticks: {
                  color: '#ffffff',
                  callback: (v) => Formatters.numeric.dollar(v),
                },
                position: 'right',
                // beginAtZero: false,
                grid: {
                  color: '#EDF2F7',
                  display: false,
                },
                title: {
                  display: true,
                  text: 'Property Value',
                  color: '#fff',
                },
              },
            }
          : {}),
        ...(compareWith === 'volume'
          ? {
              y1: {
                ticks: {
                  color: '#ffffff',
                },
                position: 'right',
                // beginAtZero: false,
                grid: {
                  color: '#EDF2F7',
                  display: false,
                },
                title: {
                  display: true,
                  text: 'Trading Volume',
                  color: '#fff',
                },
              },
            }
          : {}),
      },
      plugins: {
        legend: {
          labels: {
            borderRadius: 2,
            color: '#fff',
          },
        },
      },
    },
  };
};

const HistoricalBrickPricePanel = ({ propertyCode }) => {
  const [timeseries, setTimeseries] = useState(null);
  const [compareWith, setCompareWith] = useState(null);
  const [period, setPriod] = useState('all');

  const actions = {
    fetchPricesAndValues: async () => {
      const response = await PropertyService.getMarketTrackerData(propertyCode);
      const timeseries = response.timeseries.filter(
        (ele) => moment(ele.date) >= moment().subtract(5, 'y')
      );
      setTimeseries(timeseries);
    },
  };

  useEffect(() => {
    actions.fetchPricesAndValues();
  }, []);

  const chartConfig = timeseries
    ? generateChartConfig(timeseries, compareWith, period)
    : null;

  return (
    <Box>
      {!chartConfig ? (
        <Loading notFullHeight />
      ) : (
        <Box>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            spacing={1}
            mb={1}
            sx={{
              maxHeight: '25px',
            }}
          >
            <ButtonGroup>
              {/* <Button
                startIcon={<MdStackedLineChart />}
                size="small"
                variant={
                  compareWith === 'brick-value' ? 'contained' : 'outlined'
                }
                onClick={() =>
                  setCompareWith(
                    compareWith === 'brick-value' ? null : 'brick-value'
                  )
                }
              >
                Brick Value
              </Button>
              <Button
                startIcon={<MdStackedLineChart />}
                size="small"
                variant={
                  compareWith === 'property-value' ? 'contained' : 'outlined'
                }
                onClick={() =>
                  setCompareWith(
                    compareWith === 'property-value' ? null : 'property-value'
                  )
                }
              >
                Property Value
              </Button> */}
              <Button
                startIcon={<MdBarChart />}
                size="small"
                variant={compareWith === 'volume' ? 'contained' : 'outlined'}
                onClick={() =>
                  setCompareWith(compareWith === 'volume' ? null : 'volume')
                }
                sx={{
                  whiteSpace: 'nowrap',
                }}
              >
                Trading Volume
              </Button>
            </ButtonGroup>
            <Box
              sx={{
                bgcolor: MuiTheme.palette.primary.light,
                borderRadius: 1,
              }}
            >
              <ToggleButtonGroup
                value={period}
                color="primary"
                size="small"
                onChange={(e, newVal) => {
                  newVal ? setPriod(newVal) : setPriod(PeriodEnums.all);
                }}
                exclusive
                sx={{ height: '100%' }}
              >
                <ToggleButton title="All" value={PeriodEnums.all}>
                  ALL
                </ToggleButton>
                <ToggleButton title="Year to date" value={PeriodEnums.ytd}>
                  YTD
                </ToggleButton>
                <ToggleButton title="Last 6 months" value={PeriodEnums.m6}>
                  6M
                </ToggleButton>
                <ToggleButton title="Last 12 months" value={PeriodEnums.m12}>
                  12M
                </ToggleButton>
                <ToggleButton title="Last 24 months" value={PeriodEnums.m24}>
                  2Y
                </ToggleButton>
                <ToggleButton title="Last 36 months" value={PeriodEnums.m36}>
                  3Y
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Stack>
          <Box height="300px">
            <ChartJsLineWrapper key={compareWith} {...chartConfig} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default HistoricalBrickPricePanel;
