import {
  chartAccent01,
  chartAccent02,
  chartAccent03,
  chartAccent04,
  chartAccent05,
  chartAccent06,
} from 'src/themes/brickx/colors';
import {
  dollar,
  dollarDecimal,
  floatToPercentDecimal,
} from 'src/utils/formats';
import { hasMoreThanOnePropertyOwned } from 'src/utils/account';
import Highchart from 'src/third-party/highchart/Highchart';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import WidgetPanel from 'src/components/dashboard/common/WidgetPanel';
import _ from 'lodash';
import { renderCode } from 'src/settings/properties';

const PortfolioBreakdownPanel = ({ account, portfolio, checkboxes }) =>
  hasMoreThanOnePropertyOwned(account) && (
    <WidgetPanel
      title="Portfolio Breakdown"
      contentTestRef="portfolio-breakdown-panel-content"
    >
      <Spacing bottom="2x-large">{checkboxes}</Spacing>
      <Highchart
        hotReload
        testRef="portfolio-breakdown-chart"
        config={{
          chart: {
            type: 'bar',
            height: 48 * (portfolio.records.length + 1),
          },
          credits: {
            enabled: false,
          },
          title: false,
          legend: {
            enabled: false,
          },
          xAxis: {
            type: 'category',
            tickLength: 0,
          },
          yAxis: {
            title: false,
            labels: {
              formatter: function () {
                return dollar(this.value);
              },
            },
          },
          plotOptions: {
            series: {
              dataLabels: {
                enabled: true,
                style: { color: 'black', textOutline: 'none' },
                formatter: function () {
                  return `${dollarDecimal(
                    this.y
                  )} <span style="opacity: 0.5;">${this.percentage}</span>`;
                },
              },
            },
          },
          tooltip: { enabled: false },
          series: [
            {
              colorByPoint: true,
              cursor: 'pointer',
              colors: [
                chartAccent01,
                chartAccent02,
                chartAccent03,
                chartAccent04,
                chartAccent05,
                chartAccent06,
              ],
              data: _.orderBy(portfolio.records, ['propertyCode']).map(
                (data) => ({
                  name: `${renderCode(data.propertyCode)}`,
                  y: data.totalBrickValue,
                  percentage: floatToPercentDecimal(
                    data.totalBrickValue / portfolio.totalValue
                  ),
                })
              ),
            },
          ],
        }}
      />
    </WidgetPanel>
  );

export default PortfolioBreakdownPanel;
