import { connect } from 'react-redux';
import { load as loadCookie } from 'src/browser/cookies';
import {
  sendPopupSignupCancelEvent,
  sendPopupSignupSubmitEvent,
  sendPopupSwitchedToLoginEvent,
} from 'scripts/redux/actions/segment/events/loginOrSignupPopupEvents';
import { sessionStorage } from 'src/browser/window';
import { showModal as showModalAction } from 'src/components/modals/modalActions';
import { signupUrl, loginUrl } from 'src/utils/pageUrls';
import {
  trackPopupSeen,
  trackPopupSubmit,
} from 'src/tracking/newUserOnboardingPopup';
import { userSelector } from 'scripts/redux/selectors/user';
import Configuration from 'scripts/constants/Configuration';
import SignUpForm from 'scripts/components/onboardingOld/SignUpForm';
import withState from 'src/decorators/withState';

const DEFAULT_TIMEOUT = 150000;

const getSessionStartTime = () => {
  var startTime = sessionStorage.getItem('sessionStartTime');
  if (!startTime) {
    startTime = new Date().getTime();
    sessionStorage.setItem('sessionStartTime', startTime);
  }
  return startTime;
};

const NewUserOnboardingPopup = () => null;

const pathNotLoginOrSignup = (path) =>
  path !== signupUrl() && path !== loginUrl();

const trackingProps = { launchedFrom: 'Timed Pop-Up' };

const SignupFormModal = ({ onSubmit, onSwitchToLoginForm }) => (
  <div id="login" data-test-reference="signup-or-login-popup-variant">
    <SignUpForm
      isSignupPage
      isPopup
      onSignupSuccessCallback={onSubmit}
      onLoginClick={onSwitchToLoginForm}
    />
  </div>
);

/**
 *A Popup Singup form display while non-members viewing the website after a period of time.
 */
export default connect(
  (state) => ({
    ...userSelector(state),
  }),
  {
    showModal: showModalAction,
  }
)(
  withState({
    Component: NewUserOnboardingPopup,
    componentDidMount: ({ user, showModal, currentPath }) => {
      const userStatus = loadCookie(Configuration.COOKIES.MEMBERSHIP_STATUS);
      const timeoutDuration =
        DEFAULT_TIMEOUT - (new Date().getTime() - getSessionStartTime());
      const timerEnabled =
        timeoutDuration > 0 &&
        !user.loggedIn &&
        !userStatus &&
        pathNotLoginOrSignup(currentPath);
      if (timerEnabled) {
        setTimeout(() => {
          trackPopupSeen();
          showModal({
            content: (
              <SignupFormModal
                onSubmit={() => {
                  sendPopupSignupSubmitEvent(trackingProps);
                  trackPopupSubmit();
                }}
                onSwitchToLoginForm={() =>
                  sendPopupSwitchedToLoginEvent(trackingProps)
                }
              />
            ),
            onClose: () => sendPopupSignupCancelEvent(trackingProps),
          });
        }, timeoutDuration);
      }
    },
  })
);
