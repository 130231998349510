import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled, { css } from 'styled-components';
import { getThemeColor } from 'src/themes/themeUtils';
import TextInput from 'src/design/components/textInput/TextInput';

const Appendage = styled.div`
  ${props => props.error && css`border-color: ${getThemeColor('inputBorderError')};`}
`;

const DollarInput = ({ className, disabled, noDecimalSuffix, error, ...otherProps }) => (
  <div className={classNames('dollar-text-input', { 'dollar-text-input--disabled': disabled }, className)}>
    <Appendage className="dollar-text-input__prefix dollar-text-input__appendage" error={error}>
      <div>$</div>
    </Appendage>
    <TextInput {...{ disabled, error, ...otherProps }}/>
    {noDecimalSuffix
      ? ''
      : (
        <Appendage className="dollar-text-input__suffix dollar-text-input__appendage" error={error} data-test-reference="decimal-suffix">
          <div>.00</div>
        </Appendage>
      )
    }
  </div>
);

DollarInput.propTypes = {
  className: PropTypes.string,
  disable: PropTypes.bool,
};

DollarInput.validation = TextInput.validation;

export default DollarInput;
