import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  accountOverTimeSelector,
  fetchAccountOverTime as fetchAccountOverTimeAction,
} from 'scripts/redux/actions/account';
import Loading from 'src/components/loading/Loading';
import PortfolioOverTimeChart from 'src/components/portfolioWidget/portfolioOverTimeChart/PortfolioOverTimeChart';
import WidgetPanel from 'src/components/dashboard/common/WidgetPanel';
import withState from 'src/decorators/withState';

const HistoricalPanelRoot = styled(WidgetPanel)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const HistoricalPanel = withState({
  componentDidMount: ({ fetchAccountOverTime }) => fetchAccountOverTime(),
  Component: ({ accountOverTime }) => (
    <HistoricalPanelRoot title="Historical Value of My Portfolio" contentTestRef="historical-panel-content">
      {accountOverTime.length
        ? <PortfolioOverTimeChart className="portfolio-widget__chart" data={accountOverTime}/>
        : <Loading notFullHeight/>
      }
    </HistoricalPanelRoot>
  ),
});

export default connect(accountOverTimeSelector, { fetchAccountOverTime: fetchAccountOverTimeAction })(HistoricalPanel);
