import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import {
  property as propertyPropType,
} from 'scripts/constants/PropTypes';
import { smartInvestPropertiesSelector } from 'scripts/redux/selectors/properties';
import { fetchSmartInvestProperties } from 'scripts/redux/actions/properties';

import Carousel from 'scripts/components/shared/Carousel';
import { HeadingXSmall } from 'src/design/components/heading/Headings';
import BodyText from 'src/design/components/bodyText/BodyText';
import Spacing from 'src/design/styleguide/spacing/Spacing';

import styles from 'src/components/landingPages/common/featuredPropertiesCarousel/FeaturedPropertiesCarousel.mscss';

const FeaturedPropertyImage = ({ imageSet, address, suburb, state, testRef }) =>
  <img
    data-test-reference={testRef}
    className={styles.propertyImage}
    srcSet={`${imageSet.resized252Url} 252w,
      ${imageSet.resized595Url} 595w,
      ${imageSet.optimizedUrl} 900w`}
    sizes={`(max-width: 280px) 252px,
      (max-width: 620px) 595px,
      900px`}
    src={imageSet.resized595Url}
    alt={`Photograph of ${address}, ${suburb} ${state}`}
  />;


const mapStateToProps = (state) => ({
  ...smartInvestPropertiesSelector(state),
});

@connect(mapStateToProps, {
  fetchSmartInvestProperties,
})
export default class FeaturedPropertiesCarousel extends Component {
  static propTypes = {
    smartInvestProperties: PropTypes.arrayOf(propertyPropType),
    fetchSmartInvestProperties: PropTypes.func,
    className: PropTypes.string,
  };

  state = {
    currentSlideIndex: 0,
  };

  componentDidMount() {
    this.props.fetchSmartInvestProperties();
  }

  onSlideChanged = ev => {
    const { index } = ev.item;
    if (index !== null && index !== this.state.currentSlideIndex) {
      this.setState({ currentSlideIndex: index });
    }
  };

  render() {
    const { smartInvestProperties, className } = this.props;
    const { currentSlideIndex } = this.state;

    // Early exit if there's not yet any data:
    if (!smartInvestProperties || smartInvestProperties.length === 0) return null;

    const currentProperty = smartInvestProperties[currentSlideIndex];
    const currentPropertyAddress = `${currentProperty.address}, ${currentProperty.suburb} ${currentProperty.state}`;

    return (
      <section className={classNames(styles.featuredProperties, className)}>
        <div className={styles.propertyInfoOverlay}>
          <div className={styles.propertyInfoColumn}>
            <BodyText textSize="small" className={styles.propertyInfoLabel}>Featured Properties</BodyText>
            <Spacing top="x-small">
              <HeadingXSmall>
                <h4 className={styles.propertyAddress}>{currentPropertyAddress}</h4>
              </HeadingXSmall>
            </Spacing>
          </div>
          <div
            className={classNames(styles.propertyInfoColumn, styles.carouselDots)}
          >
            {smartInvestProperties.map((property, index) =>
              <i
                key={index}
                data-test-reference="carousel-dot"
                className={classNames(styles.carouselDot, {
                  [styles.carouselDotActive]: index === currentSlideIndex,
                })}
              />
            )}
          </div>
        </div>
        <Carousel
          // @TODO: useLazyLoad is tricky when using srcSets. We might need to circle back to this.
          // useLazyLoad
          // @TODO: note, the below is needed to not screw up the custom dots related logic
          disableNav
          disableLoop
          showOneSlideOnly
          autoplay
          autoplayTimeout={4000}
          onChanged={this.onSlideChanged}
          data-test-reference="carousel"
        >
          {smartInvestProperties.map((property, index) =>
            <FeaturedPropertyImage
              key={index}
              imageSet={property.propertyImages[0]}
              address={property.address}
              suburb={property.suburb}
              state={property.state}
              testRef="property-image"
            />
          )}
        </Carousel>
      </section>
    );
  }
}
