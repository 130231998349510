import { connect } from 'react-redux';
import DocumentMeta from 'react-document-meta';
import React, { Component } from 'react';
import { MAINTENANCE_META } from 'scripts/metadata/statics';
import { maintenanceStatus as maintenanceStatusPropType } from 'scripts/constants/PropTypes';
import { maintenanceStatusSelector } from 'scripts/redux/selectors/systems';
import history from 'src/browser/history';


const mapStateToProps = (state) => ({
  ...maintenanceStatusSelector(state),
});

@connect(mapStateToProps, {})
export default class Maintenance extends Component {
  static propTypes = {
    maintenanceStatus: maintenanceStatusPropType,
  };

  componentDidUpdate() {
    const { maintenanceStatus } = this.props;
    if (maintenanceStatus && !maintenanceStatus.enabled) {
      history.goBack();
    }
  }

  render() {
    return (
      <div className="maintenance-page">
        <DocumentMeta {...MAINTENANCE_META} />
        <div className="container">
          <div className="row">
            <div className="row col-md-8 col-md-offset-2 text-center">
              <h2>We&rsquo;ll be back soon!</h2>
              <p>We&rsquo;re performing some maintenance right now, sorry for the inconvenience. We’ll be back online shortly!</p>
            </div>
          </div>
          <div className="row col-md-8 col-md-offset-2">
            <img src="/static/images/maintenance.png" className="img-responsive"/>
          </div>
        </div>
      </div>
    );
  }
}
