import { Helmet } from 'react-helmet';
import { load } from 'src/browser/cookies';

import {
  submitVedaFailForm as submitVedaFailFormAction,
} from 'scripts/redux/actions/registration';
import {
  identityVerificationFormSubmittingSelector,
} from 'src/store/identityVerificationFormReducer';
import withSubmittableForm from 'src/decorators/withSubmittableForm';

import OnboardingScreen, {
  OnboardingScreenHeader,
  OnboardingScreenContentWide,
} from 'src/components/oldOnboarding/common/onboardingScreen/OnboardingScreen';

import { Heading } from 'src/design/components/heading/Headings';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import Divider from 'src/design/components/divider/Divider';
import Paragraph from 'src/design/components/bodyText/Paragraph';
import InfoBox from 'src/design/components/infoBox/InfoBox';
import FormLabel from 'src/design/components/formLabel/FormLabel';
import TextInput from 'src/design/components/textInput/TextInput';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import FormSelect from 'src/design/components/formSelect/FormSelect';
import StyledBodylink from 'src/design/components/hyperlink/StyledBodylink';

import * as StatesAndTerritories from 'src/enums/australianStatesAndTerritories.js';

import { helpCenterUrl } from 'src/utils/pageUrls';

import styles from 'src/components/oldOnboarding/vedaRetry/VedaRetry.mscss';

const VedaRetryForm = ({ onSubmit, onChange, values, submitting }) => {
  const isStateInvalid = () => values.licenceState !== 'please-select';
  const isNumberInvalid = () => !!values.licenceNumber;
  const isFormInvalid = () => isStateInvalid() && isNumberInvalid();
  const shouldDisableSubmitButton = submitting || !isFormInvalid();
  const userHasPreviouslySubmitted = load('veda-drivers-licence-already-collected');

  return (
    <OnboardingScreen testRef="veda-retry-screen">
      <Helmet>
        <title>Manual Verification Needed | BrickX</title>
      </Helmet>

      <OnboardingScreenHeader>
        <Heading><h1>Extra information needed</h1></Heading>
        <Spacing top="2x-large" bottom="2x-large">
          <Paragraph>
            It looks like we also need your driver's licence details in order to verify your identity.
          </Paragraph>
        </Spacing>
      </OnboardingScreenHeader>
      <OnboardingScreenContentWide className={styles.vedaRetryContainer}>

        <div className={styles['vedaRetryContainer--leftColumn']}>
          <FormLabel forId="licence-number">Drivers licence number</FormLabel>
          <Spacing bottom="normal">
            <TextInput
              forId="licence-number"
              testRef="licence-number-input"
              value={values.licenceNumber}
              onChange={value => onChange({ licenceNumber: value })}
              isCharacterValid={TextInput.validation.alphaNumericOnly}
            />
          </Spacing>

          <FormLabel forId="licence-state">Drivers licence state</FormLabel>
          <Spacing bottom="normal">
            <FormSelect
              forId="licence-state"
              testRef="state-select"
              onChange={value => onChange({ licenceState: value })}
            >
              <option key="Please Select" value="please-select">
                Please choose state
              </option>
              {Object.keys(StatesAndTerritories).map(key =>
                <option
                  key={key}
                  value={key}
                >
                  {StatesAndTerritories[key]}
                </option>
              )}
            </FormSelect>
          </Spacing>

          {userHasPreviouslySubmitted
            ? (
              <Paragraph className={styles.alreadySubmittedMessaging}>
                You've already provided us with your licence details. Please check your email for next steps and to verify manually.
              </Paragraph>
            )
            : (
              <PrimaryButton
                color={PrimaryButton.colors.SECONDARY}
                testRef="submit-button"
                disabled={shouldDisableSubmitButton}
                onClick={onSubmit}
                loading={submitting}
                loadingText="Processing"
                fullWidth
              >
                Submit
              </PrimaryButton>
            )}
        </div>

        <Divider top="2x-large" bottom="2x-large" className={styles.divider}/>

        <Spacing bottom="large" className={styles['vedaRetryContainer--rightColumn']}>
          <InfoBox>
            If you do not have an Australian licence, please submit your passport number, then check your email for further instructions. For more information, please visit our <StyledBodylink href={helpCenterUrl()}>Help Centre</StyledBodylink>.
          </InfoBox>
        </Spacing>

      </OnboardingScreenContentWide>
    </OnboardingScreen>
  );
};

export default withSubmittableForm({
  Form: VedaRetryForm,
  mapDispatchToProps: {
    submitVedaFailForm: submitVedaFailFormAction,
  },
  mapStateToProps: (state) => ({
    ...identityVerificationFormSubmittingSelector(state),
  }),
  mapPropsToInitialFormValues: () => ({
    licenceNumber: '',
    licenceState: 'please-select',
  }),
  onSubmit: ({ formValues, props }) => {
    const { submitVedaFailForm } = props;
    const { licenceNumber, licenceState } = formValues;
    return submitVedaFailForm({ licenceNumber, licenceState });
  }
});
