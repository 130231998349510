import Highcharts from 'highcharts';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class Highchart extends Component {
  static propTypes = {
    config: PropTypes.object,
    hotReload: PropTypes.boolean,
  };

  constructor(props) {
    super(props);
    this.chartContainer = React.createRef();
  }

  componentDidMount() {
    Highcharts.setOptions({
      lang: {
        thousandsSep: ',',
      },
      chart: {
        style: {
          fontFamily: 'Arial',
        },
      },
    });
    this.renderChart();
  }

  componentDidUpdate() {
    if (this.props.hotReload) {
      this.renderChart();
    }
  }

  renderChart() {
    Highcharts.chart(this.chartContainer.current, this.props.config);
  }

  render() {
    return <div ref={this.chartContainer} />;
  }
}
