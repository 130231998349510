import { createAction, handleActions } from 'redux-actions';
import PropertyService from 'scripts/services/PropertyService';

const getNumberOfViews = (mixpanelResponse) => {
  const event = mixpanelResponse.data && mixpanelResponse.data.values['Loaded a Page'];
  return Object.keys(event).reduce((prev, key) => prev + event[key], 0);
};

const getComplete = createAction('propertyViews/GET_COMPLETE');

export function getPropertyViews(propertyCode) {
  return async function (dispatch) {
    const mixpanelResponse = await PropertyService.getNumberOfViews(propertyCode);
    const numberOfViews = getNumberOfViews(mixpanelResponse);
    return dispatch(getComplete({ numberOfViews, propertyCode }));
  };
}

export default handleActions({
  [getComplete]: (state, action) => {
    const { propertyCode, numberOfViews } = action.payload;
    return {
      ...state,
      [propertyCode]: numberOfViews
    };
  }
}, {});
