import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import BodyText from 'src/design/components/bodyText/BodyText';

import {
  myOrder as myOrderPropType,
  property as propertyPropType,
} from 'scripts/constants/PropTypes';

import {
  number,
  brickPrice,
  date,
  daysTillNow,
  uuid,
} from 'scripts/utilities/formatters';
import {
  getByPropertyCode,
  roundTransactionFee,
} from 'scripts/utilities/helpers';
import { renderCode } from 'src/settings/properties';

export default class MySellOrderRow extends Component {
  static propTypes = {
    myOrder: myOrderPropType,
    properties: PropTypes.arrayOf(propertyPropType),
    action: PropTypes.func,
  };

  render() {
    const { myOrder, action } = this.props;

    return (
      <tr key={myOrder.orderId} id={myOrder.orderId}>
        <td className="text-left">
          <BodyText>
            <Link to={`/properties/${myOrder.propertyCode}`}>
              {renderCode(myOrder.propertyCode)} -{' '}
              {this._getPropertySuburb(myOrder.propertyCode)}
            </Link>
          </BodyText>
        </td>
        <td className="hidden-xs hidden-sm">
          <BodyText>{myOrder.date::date()}</BodyText>
        </td>
        <td className="text-center hidden-xs hidden-sm">
          <BodyText>{myOrder.date::daysTillNow()}</BodyText>
        </td>
        <td className="text-center">
          <BodyText>{myOrder.quantity::number()}</BodyText>
        </td>
        <td className="text-center">
          <BodyText>{myOrder.price::brickPrice()}</BodyText>
        </td>
        <td className="text-center">
          <BodyText>{this._amountYouReceived(myOrder)::brickPrice()}</BodyText>
        </td>
        <td className="hidden-xs hidden-sm">
          <BodyText>
            {this._getLowestAvailableBrickPrice(
              myOrder.propertyCode
            )::brickPrice()}
          </BodyText>
        </td>
        <td className="text-center hidden-xs hidden-sm">
          <BodyText>{myOrder.orderId::uuid()}</BodyText>
        </td>
        <td className="text-center">
          <BodyText>{action && action(myOrder)}</BodyText>
        </td>
      </tr>
    );
  }

  _getPropertySuburb = (propertyCode) => {
    const property = this._getProperty(propertyCode);
    return property ? property.suburb : '';
  };

  _getLowestAvailableBrickPrice = (propertyCode) => {
    const property = this._getProperty(propertyCode);
    const lowestAvailableBrickPrice = property
      ? property.financials.lowestAvailableBrickPrice
      : 0;
    return lowestAvailableBrickPrice;
  };

  _getProperty = (propertyCode) => {
    const { properties } = this.props;
    const property = getByPropertyCode(properties, propertyCode);
    return property;
  };

  _amountYouReceived = (order) => {
    const transactionFee = order.price * order.quantity * order.commission;
    return order.price * order.quantity - transactionFee::roundTransactionFee();
  };
}
