import { useState, useEffect, Fragment } from 'react';
import { identityCheckConsentCheckboxText } from 'src/settings/text';
import DateOfBirth from 'src/design/components/dateOfBirth/DateOfBirth';
import Divider from 'src/design/components/divider/Divider';
import FormCheckbox from 'src/design/components/formCheckbox/FormCheckbox';
import HelpDisclaimer from 'src/design/components/helpDisclaimer/HelpDisclaimer';
import InfoBox from 'src/design/components/infoBox/InfoBox';
import InputError from 'src/design/components/inputError/InputError';
import OnboardingNextButton from 'src/components/oldOnboarding/common/onboardingNextButton/OnboardingNextButton';
import OnboardingScreen, {
  OnboardingScreenHeader,
  OnboardingScreenContent,
  OnboardingFormLabel,
} from 'src/components/oldOnboarding/common/onboardingScreen/OnboardingScreen';
import Paragraph from 'src/design/components/bodyText/Paragraph';
import RadioSelect from 'src/design/components/radioSelect/RadioSelect';
import ResidentialAddress from 'src/components/oldOnboarding/identityVerification/ResidentialAddress';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import TextInputWithError from 'src/design/components/textInput/TextInputWithError';
import SmsfOnboarding from 'src/components/onboarding/smsf/SmsfAndTrustOnboarding';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import moment from 'moment';
import _ from 'lodash';
import { ACCOUNTTYPES } from 'src/settings/registration';
import FileUpload from 'src/design/components/fileUpload/FileUpload';
import Styledlink from 'src/design/components/hyperlink/Styledlink';
import { wholesaleLandingPageUrl } from 'src/utils/pageUrls';

const usCitizenFormValueToFieldValue = (formValue) => {
  if (formValue === true) return 'yes';
  if (formValue === false) return 'no';
  return '';
};

const usCitizenFieldValueToFormValue = (fieldValue) => {
  if (fieldValue === 'yes') return true;
  if (fieldValue === 'no') return false;
};

const submitAllowed = (values) =>
  values.givenName &&
  values.familyName &&
  values.address &&
  values.mobilePhone &&
  values.dayOfBirth &&
  values.monthOfBirth &&
  values.yearOfBirth &&
  usCitizenFormValueToFieldValue(values.usCitizen) &&
  values.tsAndCsAgreed &&
  (!values.isWholesale ||
    (values.isWholesale &&
      values.certificate &&
      values.certificateLabel &&
      values.wholesaleConfirm));

const EnterDetailsAsTheyAppear = (props) => (
  <InfoBox {...props}>
    <Paragraph>
      Please enter details exactly as they appear on your ID.
    </Paragraph>
  </InfoBox>
);

const IdentityVerificationFormSmsfAndTrust = ({
  onSubmit,
  onChange,
  values,
  identityVerificationFormErrors,
  identityVerificationFormErrorsCleared,
  submitting,
  user,
}) => {
  const [step, setStep] = useState(0);
  const [errors, setErrors] = useState({});
  const setSmsfDetails = (smsfDetails) => {
    onChange({ smsfDetails });
  };

  const isSmsf = user.accountType === ACCOUNTTYPES.SMSF.toUpperCase();
  const isTrust = user.accountType === ACCOUNTTYPES.TRUST.toUpperCase();

  const selfValidateForm = () => {
    setErrors({});
    let newErrors = {};
    const dob = `${values.yearOfBirth}-${values.monthOfBirth}-${values.dayOfBirth}`;
    const age = moment().diff(moment(dob), 'years');
    if (age < 18) {
      newErrors.dob = 'You must be at least 18 years old';
    }
    // phone number
    if (!/^04\d{8}$/.test(values.mobilePhone)) {
      newErrors.mobilePhone =
        'Please enter a valid Australian mobile number which should start with 04 and contain 10 digits without spaces';
    }
    setErrors(newErrors);
    if (_.isEmpty(newErrors)) setStep(1);
    if (!_.isEmpty(newErrors)) window.scrollTo(0, 0);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  const Common = {
    Title: () => (
      <h1>
        <strong>
          <span style={{ color: 'var(--bx-color---blue-600)' }}>
            BrickX{isSmsf && ' SMSF'}
            {isTrust && ' Trust'}
          </span>
          <br />
        </strong>
      </h1>
    ),
  };

  if (step === 0) {
    return (
      <OnboardingScreen testRef="identity-verification-screen">
        <OnboardingScreenHeader>
          <Common.Title />
          <Spacing bottom="large">
            <h2>Step 1: Please tell us a bit about yourself</h2>
          </Spacing>
          <Spacing bottom="large">
            <Paragraph>
              We are required by Australian law to verify all BrickX investors
            </Paragraph>
          </Spacing>
        </OnboardingScreenHeader>
        <OnboardingScreenContent>
          <form>
            <Spacing bottom="large">
              <EnterDetailsAsTheyAppear />
            </Spacing>
            <OnboardingFormLabel>First Name</OnboardingFormLabel>
            <Spacing bottom="normal">
              <TextInputWithError
                value={values.givenName}
                onChange={(value) => {
                  identityVerificationFormErrorsCleared('givenName');
                  onChange({ givenName: value });
                }}
                placeholder="John"
                error={
                  identityVerificationFormErrors.givenName &&
                  'Please enter a valid first name (alphabets only)'
                }
                testRef="given-name"
                errorTestRef="given-name-error"
              />
            </Spacing>
            <OnboardingFormLabel>Middle Name (Optional)</OnboardingFormLabel>
            <Spacing bottom="normal">
              <TextInputWithError
                value={values.middleName}
                onChange={(value) => {
                  identityVerificationFormErrorsCleared('middleName');
                  onChange({ middleName: value });
                }}
                placeholder="William"
                error={
                  identityVerificationFormErrors.middleName &&
                  'Please enter a valid first name (alphabets only)'
                }
                testRef="middle-name"
                errorTestRef="middle-name-error"
              />
            </Spacing>
            <OnboardingFormLabel>Last Name</OnboardingFormLabel>
            <Spacing bottom="normal">
              <TextInputWithError
                value={values.familyName}
                onChange={(value) => {
                  identityVerificationFormErrorsCleared('familyName');
                  onChange({ familyName: value });
                }}
                placeholder="Smith"
                error={
                  identityVerificationFormErrors.familyName &&
                  'Please enter a valid last name (alphabets only)'
                }
                testRef="family-name"
                errorTestRef="family-name-error"
              />
            </Spacing>
            <ResidentialAddress
              value={values.address}
              harmonyValue={values.harmonyAddressObject}
              onChange={(value) => {
                identityVerificationFormErrorsCleared('address');
                onChange({
                  address: value,
                  harmonyAddressObject: undefined,
                });
              }}
              onSuggestionSelect={(harmonyAddressObject) => {
                onChange({
                  address: harmonyAddressObject.fullAddress,
                  harmonyAddressObject,
                });
              }}
              showError={identityVerificationFormErrors.address}
            />
            <OnboardingFormLabel>Mobile</OnboardingFormLabel>
            <Spacing bottom="normal">
              <TextInputWithError
                value={values.mobilePhone}
                onChange={(value) => {
                  identityVerificationFormErrorsCleared('mobilePhone');
                  onChange({ mobilePhone: value });
                }}
                placeholder="e.g. 04xxxxxxxx"
                error={
                  errors.mobilePhone
                    ? errors.mobilePhone
                    : identityVerificationFormErrors.mobilePhone &&
                      'Please enter a valid Australian mobile number'
                }
                testRef="phone-number"
                errorTestRef="phone-number-error"
              />
            </Spacing>
            <OnboardingFormLabel>Date of Birth</OnboardingFormLabel>
            <Spacing bottom="normal">
              <DateOfBirth
                day={{
                  value: values.dayOfBirth,
                  onChange: (value) => {
                    identityVerificationFormErrorsCleared('dateOfBirth');
                    onChange({ dayOfBirth: value });
                  },
                  testRef: 'day-of-birth-select',
                }}
                month={{
                  value: values.monthOfBirth,
                  onChange: (value) => {
                    identityVerificationFormErrorsCleared('dateOfBirth');
                    onChange({ monthOfBirth: value });
                  },
                  testRef: 'month-of-birth-select',
                }}
                year={{
                  value: values.yearOfBirth,
                  onChange: (value) => {
                    identityVerificationFormErrorsCleared('dateOfBirth');
                    onChange({ yearOfBirth: value });
                  },
                  testRef: 'year-of-birth-select',
                }}
                error={
                  errors.dob
                    ? errors.dob
                    : identityVerificationFormErrors.dateOfBirth &&
                      'You must be at least 18 years old to register'
                }
                errorTestRef="date-of-birth-error"
              />
            </Spacing>
            <Divider top="normal" bottom="normal" />

            {/* ================ US CITIZEN ================ */}

            <OnboardingFormLabel>Are you a US citizen?</OnboardingFormLabel>
            <Spacing bottom="x-small">
              <RadioSelect
                name="us-citizen"
                value={usCitizenFormValueToFieldValue(values.usCitizen)}
                onChange={(value) =>
                  onChange({ usCitizen: usCitizenFieldValueToFormValue(value) })
                }
                options={[
                  { value: 'yes', labelText: 'Yes' },
                  { value: 'no', labelText: 'No' },
                ]}
                testRef="us-citizen"
              />
            </Spacing>
            <HelpDisclaimer>
              Your answer does not affect your eligibility to invest, and is for
              reporting purposes only.
            </HelpDisclaimer>
            <Divider top="normal" bottom="normal" />

            {/* ================ WHOLESALE INVESTOR ================ */}

            <OnboardingFormLabel>
              Are you a <strong>Professional or Sophisticated Investor</strong>?{' '}
              <Styledlink
                href={wholesaleLandingPageUrl()}
                forceExternal
                target="_blank"
              >
                Learn More
              </Styledlink>
            </OnboardingFormLabel>
            <Spacing bottom="large">
              <RadioSelect
                name="is-wholesale"
                value={values.isWholesale}
                onChange={(value) => {
                  onChange({ isWholesale: value === 'true' });
                }}
                options={[
                  { value: true, labelText: 'Yes' },
                  { value: false, labelText: 'No' },
                ]}
                testRef="is-wholesale"
              />
            </Spacing>
            {values.isWholesale && (
              <Fragment>
                <Spacing bottom="large">
                  <Paragraph color="onSurfaceContrast500">
                    Note: To qualify as a professional or sophisticated
                    investor, your accountant must be able to verify that you as
                    the investor (including the net assets or gross income or
                    any companies or trusts controlled by you the Investor as
                    defined in section 50AA of the Corporations Act) has either:
                    <br />
                    <strong>
                      <br />
                      (i) Net assets of at least AUD$2,500,000; OR
                      <br />
                      (ii) Gross income for each of the last 2 financial years
                      of at least AUD$250,000 a year.
                    </strong>
                    <br />
                    <br />
                    To register on the BrickX Platform as a professional or
                    sophisticated investor, please upload a Qualified Accounts
                    Certification.
                    <br />
                    <br />
                    <i>
                      You can{' '}
                      <Styledlink
                        forceExternal
                        target="_blank"
                        href="https://assets.brickx.com/downloads/qualified_accountants_certificate.pdf"
                      >
                        click here
                      </Styledlink>{' '}
                      to download a Qualified Accountant’s Certificate template
                    </i>
                  </Paragraph>
                </Spacing>
                <Spacing bottom="normal">
                  <FileUpload
                    ctaText="Select File"
                    realTestRef={'wholesale-fileupload'}
                    formDataFieldName="wholesale_certificate"
                    onChange={(file) => {
                      if (file) {
                        const r = new FileReader();
                        r.readAsDataURL(file);
                        r.onloadend = (e) => {
                          const base64Str = e.target.result.split(',')[1];
                          const certificate = {
                            certificateLabel: file.name,
                            certificate: base64Str,
                          };
                          onChange(certificate);
                        };
                      }
                    }}
                    accept=".png, .gif, .jpeg, .jpg, .pdf"
                  />
                </Spacing>
                <Spacing>
                  <Paragraph>
                    By registering as a professional and sophisticated Investor
                    with BrickX, I consent to being offered exclusive
                    professional and sophisticated investor financial products
                    and services from BrickX and it’s related parties.
                  </Paragraph>
                </Spacing>
                <Spacing top="normal" bottom="x-samll">
                  <RadioSelect
                    name="wholesale-confirm"
                    value={values.wholesaleConfirm}
                    onChange={(value) => {
                      onChange({ wholesaleConfirm: value === 'true' });
                    }}
                    options={[
                      { value: true, labelText: 'Yes' },
                      { value: false, labelText: 'No' },
                    ]}
                    testRef="wholesale-confirm"
                  />
                </Spacing>
              </Fragment>
            )}
            <Divider top="normal" bottom="normal" />
            <FormCheckbox
              value={values.tsAndCsAgreed}
              onChange={(value) => onChange({ tsAndCsAgreed: value })}
              testRef="ts-and-cs-checkbox"
              labelTestRef="ts-and-cs-checkbox-label"
            >
              {identityCheckConsentCheckboxText()}
            </FormCheckbox>
            <OnboardingNextButton
              testRef="next-button"
              disabled={!submitAllowed(values) || submitting}
              color={PrimaryButton.colors.BLUE}
              onClick={(e) => {
                e.preventDefault();
                selfValidateForm();
              }}
            />
            {identityVerificationFormErrors.requestError ? (
              <Spacing top="x-small">
                <InputError error="We are having some trouble contacting our verification service at the moment. Please try again later." />
              </Spacing>
            ) : null}
          </form>
        </OnboardingScreenContent>
      </OnboardingScreen>
    );
  }
  if (step === 1) {
    return (
      <OnboardingScreen testRef="identity-verification-screen">
        <OnboardingScreenHeader>
          <Common.Title />
          <Spacing bottom="large">
            <h2>
              Step 2: Please provide your {isTrust ? 'Trust' : 'SMSF'} details
            </h2>
          </Spacing>
        </OnboardingScreenHeader>
        <OnboardingScreenContent>
          <SmsfOnboarding
            userData={values}
            setSmsfDetails={setSmsfDetails}
            onSubmit={onSubmit}
            identityVerificationFormErrors={identityVerificationFormErrors}
            isTrust={isTrust}
          />
        </OnboardingScreenContent>
      </OnboardingScreen>
    );
  }
};

export default IdentityVerificationFormSmsfAndTrust;
