import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  property as propertyPropType,
  user as userPropType,
} from 'scripts/constants/PropTypes';

import InvestPanelMarketOpen from 'scripts/components/property/trade/InvestPanelMarketOpen';
import InvestPanelPreOrder from 'scripts/components/property/trade/InvestPanelPreOrder';
import InvestPanelComingSoon from 'scripts/components/property/trade/InvestPanelComingSoon';

export default class InvestPanel extends Component {
  static propTypes = {
    user: userPropType.isRequired,
    property: propertyPropType.isRequired,
    isComingSoonProperty: PropTypes.bool.isRequired,
    isPreOrderProperty: PropTypes.bool.isRequired,
    handleBuyButtonClick: PropTypes.func.isRequired,
    handleSellButtonClick: PropTypes.func.isRequired,
    fetchMyOrdersInfo: PropTypes.func.isRequired,
    getTotalBricksOwned: PropTypes.func.isRequired,
  };

  render() {
    const {
      user,
      property,
      isPreOrderProperty,
      isComingSoonProperty,
      getTotalBricksOwned,
      fetchMyOrdersInfo,
      handleBuyButtonClick,
      handleSellButtonClick,
    } = this.props;
    return (
      <div>
        {isComingSoonProperty ? (
          <InvestPanelComingSoon user={user} property={property} />
        ) : isPreOrderProperty ? (
          <InvestPanelPreOrder
            user={user}
            property={property}
            handleBuyButtonClick={handleBuyButtonClick}
          />
        ) : (
          <InvestPanelMarketOpen
            user={user}
            property={property}
            getTotalBricksOwned={getTotalBricksOwned}
            fetchMyOrdersInfo={fetchMyOrdersInfo}
            handleBuyButtonClick={handleBuyButtonClick}
            handleSellButtonClick={handleSellButtonClick}
          />
        )}
      </div>
    );
  }
}
