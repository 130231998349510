// TODO: does not belong in constants, move it to config
import { colors } from './chartConfig';

export default {
  showInLegend: false,
  color: colors.orange,
  lineWidth: 3,
  marker: {
    symbol: 'circle'
  }
};
