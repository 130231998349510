import { cloneElement } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
  DropDownMenuItem,
  InnerMenuItem,
  LinkMenuItem,
  OnClickCloseMenuProvider,
  TopLevelMenuItem,
} from 'src/design/components/header/MenuItemTypes';
import { isLoggedIn } from 'src/utils/user';
import { memberSelector } from 'scripts/redux/selectors/member';
import { trackButtonClick } from 'src/tracking/referrals';
import { userSelector } from 'scripts/redux/actions/user';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import menuItemTypeStyles from 'src/design/components/header/MenuItemTypes.mscss';
import * as pageUrls from 'src/utils/pageUrls';
import styles from 'src/components/header/menu/MenuItems.mscss';
import SmallBadge from 'src/design/components/badge/SmallBadge';
import styled from 'styled-components';
import { getThemeColor } from 'src/themes/themeUtils';
import {
  // themeForBrickXAvenue,
  themeForBrickXMinorLandingPage,
  themeForBrickXPrivateSyndicate,
  themeForBrickXWholesale,
} from 'src/themes/landingPageThemes';

const SubHeroItemInnerWrapper = styled.div`
  border-left: 4px solid transparent;
  .title {
    color: var(--bx-color---charcoal);
    font-weight: bold;
    font-size: 1.8rem;
  }
  .sub-title {
    margin-top: -4px;
    color: var(--bx-color---grey-600);
    font-size: 1.3rem;
  }
  &:hover,
  &:focus,
  &:active {
    border-left-color: ${(props) =>
      props.primaryColor || getThemeColor('primary')};
    background-color: ${(props) =>
      props.backgroundColor || getThemeColor('lightBackgroundPrimary')};
  }
`;

const SubHeroItem = ({
  link,
  text,
  subText,
  isNew,
  primaryColor,
  backgroundColor,
}) => (
  <SubHeroItemInnerWrapper {...{ primaryColor, backgroundColor }}>
    <div
      className={classNames(
        styles.heroMenuItemContainer,
        menuItemTypeStyles.hoverTrigger
      )}
    >
      <LinkMenuItem link={link}>
        <div className="title">{text}</div>
        <div className="sub-title">{subText}</div>
        {isNew && (
          <div className="badge-wrapper">
            <SmallBadge text="New !" color={primaryColor} />
          </div>
        )}
      </LinkMenuItem>
    </div>
  </SubHeroItemInnerWrapper>
);

const BrickxMinorsMenuItem = () => (
  <SubHeroItem
    link={pageUrls.minorLandingPageUrl()}
    text="BrickX for Minors"
    subText={<div>Get kids on the property ladder sooner!</div>}
    primaryColor={themeForBrickXMinorLandingPage.colors.primary}
    backgroundColor={
      themeForBrickXMinorLandingPage.colors.lightBackgroundPrimary
    }
  />
);

// const BrickxSmartRenterMenuItem = () => (
//   <SubHeroItem
//     link={pageUrls.rentToBuyUrl()}
//     text="Smart Renter"
//     subText={<div>Purchase your Home sooner!</div>}
//     primaryColor={themeForBrickXAvenue.colors.primary}
//     backgroundColor={themeForBrickXAvenue.colors.lightBackgroundPrimary}
//     isNew
//   />
// );

const BrickxWholesaleMenuItem = () => (
  <SubHeroItem
    link={pageUrls.wholesaleLandingPageUrl()}
    text="BrickX - Professional and Sophisticated Investor"
    subText={
      <div>
        Investing as a professional and sophisticated investor with BrickX
      </div>
    }
    primaryColor={themeForBrickXWholesale.colors.primary}
    backgroundColor={themeForBrickXWholesale.colors.lightBackgroundPrimary}
  />
);

const BrickxPrivateSyndicationMenuItem = () => (
  <SubHeroItem
    link={pageUrls.privateSyndicationLandingPageUrl()}
    text="BrickX - Select & Syndicate"
    subText={<div>Private property syndications now available!</div>}
    primaryColor={themeForBrickXPrivateSyndicate.colors.primary}
    backgroundColor={
      themeForBrickXPrivateSyndicate.colors.lightBackgroundPrimary
    }
  />
);

const BrickxDevelopmentsMenuItem = () => (
  <SubHeroItem
    link={pageUrls.brickxDevelopmentLandingPageUrl()}
    text="BrickX Developments"
    subText={<div>Development syndications now available!</div>}
    primaryColor={themeForBrickXPrivateSyndicate.colors.primary}
    backgroundColor={
      themeForBrickXPrivateSyndicate.colors.lightBackgroundPrimary
    }
  />
);

const SmartInvestMenuItem = () => (
  <SubHeroItem
    link={pageUrls.smartInvestUrl()}
    text="Smart Invest"
    subText={
      <div>
        Hassle free investing in Bricks.
        <br />
        We'll do the work for you.
      </div>
    }
  />
);

const BuildMyOwnMenuItem = () => (
  <SubHeroItem
    link={pageUrls.howItWorksBuildMyOwnTabUrl()}
    text="Build My Own"
    subText="Use your knowledge to build your own portfolio."
  />
);

const ReturnsCalculatorMenuItem = () => (
  <SubHeroItem
    link={pageUrls.returnsCalculatorUrl()}
    text="BrickX Calculator"
    subText="Understand more about returns."
  />
);

const HeroMenuDropDownContainer = ({ children }) => (
  <div className={styles.heroMenuDropDownContainer}>{children}</div>
);

const ButtonsContainer = ({ children }) => (
  <div className={styles.buttonsContainer}>{children}</div>
);

const AboutMenuItem = () => (
  <DropDownMenuItem text="About" testRef="about-menu">
    <InnerMenuItem link={pageUrls.aboutUrl()} text="About Us" />
    <InnerMenuItem link={pageUrls.propertyTeamUrl()} text="BrickX Team" />
    <InnerMenuItem link={pageUrls.feesAndTaxesHelpUrl()} text="Fees" />
    <InnerMenuItem link={pageUrls.helpCenterUrl()} text="Help Centre" />
    <InnerMenuItem link={pageUrls.contactUrl()} text="Contact Us" />
  </DropDownMenuItem>
);

const ProductsMenuItem = () => (
  <DropDownMenuItem text="Products" testRef="invest-in-property-menu">
    <HeroMenuDropDownContainer>
      <BrickxMinorsMenuItem />
      {/* <BrickxSmartRenterMenuItem /> */}
      <BrickxWholesaleMenuItem />
      <BrickxPrivateSyndicationMenuItem />
      <BrickxDevelopmentsMenuItem />
      <SmartInvestMenuItem />
      <BuildMyOwnMenuItem />
      <ReturnsCalculatorMenuItem />
    </HeroMenuDropDownContainer>
  </DropDownMenuItem>
);

const PublicMenuFragment = () =>
  [
    <TopLevelMenuItem link={pageUrls.howItWorksUrl()} text="How It Works" />,
    <TopLevelMenuItem link={pageUrls.propertiesUrl()} text="Properties" />,
    <ProductsMenuItem />,
    <TopLevelMenuItem link={pageUrls.newsUrl()} text="News" />,
    <AboutMenuItem />,
    <ButtonsContainer>
      <OnClickCloseMenuProvider>
        <TopLevelMenuItem
          className={classNames(styles.menuItemLogin)}
          link={pageUrls.loginUrl()}
          text="Login"
        />
      </OnClickCloseMenuProvider>
      <OnClickCloseMenuProvider>
        <PrimaryButton
          className={styles.button}
          color={PrimaryButton.colors.SECONDARY}
          link={pageUrls.signupUrl()}
          responsiveSizes={{
            large: PrimaryButton.sizes.EXTRA_SMALL,
          }}
        >
          Sign Up
        </PrimaryButton>
      </OnClickCloseMenuProvider>
    </ButtonsContainer>,
  ].map((element, index) => cloneElement(element, { key: index }));

const trackInviteMatesButton = () => {
  return trackButtonClick({
    location: 'App header bar',
    button: 'Invite Mates',
  });
};

const LoggedInMenuFragment = (isMember) =>
  [
    <TopLevelMenuItem
      link={pageUrls.dashboardUrl()}
      text="Dashboard"
      testRef="dashboard-top-level"
    />,
    <TopLevelMenuItem link={pageUrls.propertiesUrl()} text="Properties" />,
    <ProductsMenuItem />,
    <TopLevelMenuItem link={pageUrls.newsUrl()} text="News" />,
    <AboutMenuItem />,
    <DropDownMenuItem text="My Account" testRef="account-menu">
      <InnerMenuItem link={pageUrls.dashboardUrl()} text="Dashboard" />
      <InnerMenuItem link={pageUrls.portfolioUrl()} text="Portfolio" />
      <InnerMenuItem link={pageUrls.transactionsUrl()} text="Transactions" />
      <InnerMenuItem link={pageUrls.documentsUrl()} text="My Documents" />
      <InnerMenuItem link={pageUrls.pendingOrdersUrl()} text="Pending Orders" />
      <InnerMenuItem link={pageUrls.depositUrl()} text="Deposit Funds" />
      <InnerMenuItem link={pageUrls.withdrawUrl()} text="Withdraw Funds" />
      {isMember && false ? (
        <InnerMenuItem
          link={pageUrls.referralsUrl()}
          text="Invite Mates"
          onClick={trackInviteMatesButton}
        />
      ) : null}
      <InnerMenuItem link={pageUrls.settingsUrl()} text="Settings" />
      <InnerMenuItem link={pageUrls.switchAccountUrl()} text="Switch Account" />
      <InnerMenuItem link={pageUrls.logoutUrl()} text="Logout" />
    </DropDownMenuItem>,
  ].map((element, index) => cloneElement(element, { key: index }));

const Menu = ({ user, memberStatus }) =>
  isLoggedIn(user)
    ? LoggedInMenuFragment(memberStatus && memberStatus.isMember)
    : PublicMenuFragment();

export default connect((state) => ({
  ...userSelector(state),
  ...memberSelector(state),
}))(Menu);
