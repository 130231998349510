import styled from 'styled-components';
import { SubHeading } from 'src/design/styleguide/Headings';
import { WelcomePageWidgetPanel } from 'src/components/dashboard/home/commonComponents';
import { breakpointSmall } from 'src/design/styleguide/common/breakpoints';
import { propertiesUrl } from 'src/utils/pageUrls';
import { refereeGiftAmount } from 'src/settings/financials';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import Spacing from 'src/design/styleguide/spacing/Spacing';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BuyBricksButton = styled(PrimaryButton)`
  width: 100%;

  ${breakpointSmall(`
    width: auto;
  `)}
`;

export default ({ className }) => (
  <WelcomePageWidgetPanel className={className} testRef="referee-buy-bricks-dashboard-widget">
    <Container>
      <SubHeading as="h4" fontSize="x-small">Buy Bricks now to get your {refereeGiftAmount}</SubHeading>
      <Spacing top="normal">
        <BuyBricksButton
          link={propertiesUrl()}
          color={PrimaryButton.colors.SECONDARY}
        >
          Buy Bricks
        </BuyBricksButton>
      </Spacing>
    </Container>
  </WelcomePageWidgetPanel>
);
