import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { userSelector } from 'scripts/redux/actions/user';
import OnboardingScreen, {
  OnboardingScreenHeader,
} from 'src/components/oldOnboarding/common/onboardingScreen/OnboardingScreen';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import Paragraph from 'src/design/styleguide/Paragraph';

const BoVedaFailed = ({ user }) => {
  const idCheckFailedBeneficiaries = user.boDetails.filter(
    (ele) => !ele.idChecked
  );

  return (
    <OnboardingScreen>
      <Helmet>
        <title>Additional ID Check Failed | BrickX</title>
      </Helmet>
      <OnboardingScreenHeader>
        <h1>
          <strong>Additional ID Check Fail</strong>
        </h1>
        <Paragraph>
          Unfortunately the person/s listed below did not pass identification
          verification:
        </Paragraph>
        <Paragraph align="center">
          {idCheckFailedBeneficiaries.map((ele) => (
            <li>
              {ele.givenName} {ele.middleName || ''} {ele.familyName}
            </li>
          ))}
        </Paragraph>
        <Paragraph>
          Please provide the following details for each person listed above.
        </Paragraph>
        <hr />
        <Paragraph align="left">
          <ol>
            <li>
              Picture of drivers licence or passport (scanned image or photo)
            </li>
            <li>
              If passport ID is provided, please also provide evidence of your
              residential address by providing a utility bill or bank statement.
            </li>
            <li>
              Your consent to complete a manual verification (Email reply
              stating "I give consent to verify follwing details")
            </li>
          </ol>
        </Paragraph>
        <hr />
        <Paragraph>
          <h2>
            <strong>What will we do?</strong>
          </h2>
          We verify the identity of all BrickX investors in accordance with our
          AML/CTF obligations, using our trusted partner, Equifax. Once you
          reply we will proceed with the verification and let you know as soon
          as your account is registered.
        </Paragraph>
        <Paragraph>
          <PrimaryButton
            color="primary"
            link="mailto:info@brickx.com?subject=Additional ID Check for SMSF/Trust"
          >
            Email Us
          </PrimaryButton>
        </Paragraph>
      </OnboardingScreenHeader>
    </OnboardingScreen>
  );
};

const mapStateToProps = (state) => ({
  ...userSelector(state),
});
export default connect(mapStateToProps, {})(BoVedaFailed);
