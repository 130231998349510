import { delay } from 'src/utils/time';
import {
  modalsSelector,
  savedScrollPositionSelector,
} from 'src/components/modals/modalsReducer';
import {
  getScrollY,
  scrollTo,
} from 'src/browser/window';

export const TOGGLE_APP_BACKGROUND_BLUR = 'TOGGLE_APP_BACKGROUND_BLUR';
export const SHOW_MODAL = 'SHOW_MODAL';
export const PRE_DISMISS_MODAL = 'PRE_DISMISS_MODAL';
export const DISMISS_MODAL = 'DISMISS_MODAL';
export const DISMISS_ALL_MODALS = 'DISMISS_ALL_MODALS';
export const SAVE_SCROLL_POSITION = 'SAVE_SCROLL_POSITION';

const animatedCloseModalStart = ({ id }) => ({ type: PRE_DISMISS_MODAL, payload: id });
const animatedCloseModalEnd = ({ id }) => ({ type: DISMISS_MODAL, payload: id });
const saveScrollPosition = (scrollPosition) => ({ type: SAVE_SCROLL_POSITION, payload: scrollPosition });

export const toggleAppBackgroundBlur = boolean => dispatch =>
  Promise.resolve(dispatch({ type: TOGGLE_APP_BACKGROUND_BLUR, payload: boolean }));

export const showModal = ({ ...payload }) => (dispatch, getState) => {
  const { modals } = modalsSelector(getState());
  const isFirstModal = modals.length === 0;
  if (isFirstModal) {
    dispatch(saveScrollPosition(getScrollY()));
    dispatch(toggleAppBackgroundBlur(true));
  }
  return dispatch({ type: SHOW_MODAL, payload });
};

export const closeModal = ({ id }) => (dispatch, getState) => {
  const state = getState();
  const { modals } = modalsSelector(state);
  const { savedScrollPosition } = savedScrollPositionSelector(state);
  const isLastModal = modals.length === 1;
  isLastModal && dispatch(toggleAppBackgroundBlur(false))
    .then(() => scrollTo(0, savedScrollPosition));
  dispatch(animatedCloseModalStart({ id }));
  return delay(250).then(() => dispatch(animatedCloseModalEnd({ id })));
};

export const closeAllModals = () => ({ type: DISMISS_ALL_MODALS });
