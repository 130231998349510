import { Fragment } from 'react';
import InputError from 'src/design/components/inputError/InputError';

const withError = (WrappedComponent) => ({ error, errorTestRef, ...props }) => (
  <Fragment>
    <WrappedComponent
      error={!!error}
      {...props}
    />
    {error ? <InputError error={error} errorTestRef={errorTestRef}/> : null}
  </Fragment>
);

export default withError;
