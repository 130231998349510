import styled, { css } from 'styled-components';
import { getThemeColor } from 'src/themes/themeUtils';
import { gridUnit } from 'src/design/styleguide/common/measurements';
import { testRefToDataTestReference } from 'src/design/styleguide/common/styledComponentsUtils';
import ClonedChild from 'src/design/styleguide/common/ClonedChild';
import Text from 'src/design/styleguide/text/Text';
import withScrollable from 'src/decorators/withScrollable';

const dividerBorderStyle = css`1px solid ${getThemeColor('onSurfaceContrast200')}`;

const shadowCommonStyles = `
  position: absolute;
  top: 0;
  height: 100%;
  width: 8px;
`;

export const StickyHeader = styled(ClonedChild)`
  position: sticky;
  left: 0;
  background-color: ${getThemeColor('surface')};

  && {
    padding-right: ${gridUnit * 4}px;
  }
`;

const OuterContainer = styled.div.attrs(testRefToDataTestReference)`
  position: relative;

  &::after {
    ${shadowCommonStyles}
    ${props => props.isScrollable && !props.scrolledToTheEnd && "content: ' ';"}
    right: 0;
    background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(0,0,0,0.1) 100%);
  }

  &::before {
    ${shadowCommonStyles}
    ${props => props.isScrollable && props.scrolled && "content: ' '"};
    left: 0;
    background: linear-gradient(to right, rgba(0,0,0,0.1) 0%,rgba(0,0,0,0) 100%);
  }

  ${props => props.withStickyHeader && css`
    ${StickyHeader}::before {
      ${shadowCommonStyles}
      ${props.isScrollable && props.scrolled && "content: ' '"};
      left: 100%;
      background: linear-gradient(to right, rgba(0,0,0,0.1) 0%,rgba(0,0,0,0) 100%);
    }
  `}
`;

const TableCell = styled(Text).attrs({
  fontWeight: 'regular',
  fontSize: '4x-small',
  color: 'onSurfaceContrast800',
})`
  padding:
    ${gridUnit * 2}px
    ${gridUnit * 4}px
    ${gridUnit * 2}px
    0;

  vertical-align: top;
  white-space: nowrap;

  &:first-child {
    padding-left: ${gridUnit * 2}px;
  }

  &:last-child {
    padding-right: ${gridUnit * 2}px;
  }
`;

const FooterTableCell = styled(TableCell).attrs({
  fontWeight: 'bold',
})`
  border-top: ${dividerBorderStyle};
  background-color: ${getThemeColor('backgroundFaintGrey')};
  padding-top: ${gridUnit * 4}px;
  padding-bottom: ${gridUnit * 4}px;
`;

export const SecondColumn = styled(ClonedChild)`
  && {
    padding-left: ${gridUnit * 2}px;
  }
`;

export const MultiColumnHeadingText = styled(Text).attrs({
  as: 'div',
  fontWeight: 'regular',
  fontSize: '4x-small',
  color: 'onSurfaceContrast800',
  align: 'center',
})`
  ${props => props.spaceRight && `margin-right: ${gridUnit * 4}px;`}
  padding-bottom: ${gridUnit * 4}px;
  border-bottom: ${dividerBorderStyle};
`;

export const ColumnHeading = styled(TableCell).attrs({
  as: 'th',
  scope: 'col',
})`
  border-bottom: ${dividerBorderStyle};
`;

export const TableHeading = styled(TableCell).attrs({
  as: 'th',
})``;

export const TableData = styled(TableCell).attrs({
  as: 'td',
})``;

export const BodyTableRow = styled.tr.attrs(testRefToDataTestReference)`
  &:first-child {
    ${TableCell} {
      padding-top: ${gridUnit * 4}px;
    }
  }

  &:last-child {
    ${TableCell} {
      padding-bottom: ${gridUnit * 4}px;
    }
  }
`;

export const FooterTableHeading = styled(FooterTableCell).attrs({
  as: 'th',
})``;

export const FooterTableData = styled(FooterTableCell).attrs({
  as: 'td',
})``;

const TableContainer = styled.div`
  overflow-x: auto;
`;

const TableElement = styled.table`
  width: 100%;

  ${props => props.centeredRows && css`
    ${TableData} {
      vertical-align: middle;
    }
  `}
`;

export const Table = withScrollable(({
  className,
  onContainerScroll,
  elementRef,
  containerRef,
  isScrollable,
  isScrolled,
  isScrolledToTheEnd,
  withStickyHeader,
  centeredRows,
  children,
  testRef,
}) => (
  <OuterContainer className={className} isScrollable={isScrollable} scrolled={isScrolled} scrolledToTheEnd={isScrolledToTheEnd} withStickyHeader={withStickyHeader} testRef={testRef}>
    <TableContainer ref={containerRef} onScroll={onContainerScroll}>
      <TableElement centeredRows={centeredRows} ref={elementRef}>
        {children}
      </TableElement>
    </TableContainer>
  </OuterContainer>
));
