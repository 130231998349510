import { connect } from 'react-redux';
import DocumentMeta from 'react-document-meta';
import { SETTINGS_META } from 'scripts/metadata/account';
import PageHeading from 'src/design/components/heading/PageHeading';
import SubHeading from 'src/design/components/heading/SubHeading';
import PageContentBlock from 'src/design/components/pageContentBlock/PageContentBlock';
import Paragraph from 'src/design/components/bodyText/Paragraph';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import SecondaryButton from 'src/design/components/button/SecondaryButton';
import withState from 'src/decorators/withState';
import {
  fetchSmartInvestDetails as fetchSmartInvestDetailsAction,
  skipAMonthThenGoBackToSettings as skipAMonthThenGoBackToSettingsAction,
  smartInvestSelector,
} from 'scripts/redux/actions/smartInvest';
import { dollar, shortDate } from 'src/utils/formats';
import { settingsUrl } from 'src/utils/pageUrls';
import styles from 'src/components/settings/Settings.mscss';


const SmartInvestSkipAMonth = ({ smartInvest, skipAMonthThenGoBackToSettings }) => {
  const { amount, nextDebitDate, nextSkipDebitDate } = smartInvest;

  return (
    <div
      className={styles.settings}
      data-test-reference="enable-smart-invest-page"
    >
      <DocumentMeta {...SETTINGS_META} />
      <PageHeading>Skip a Month</PageHeading>

      <PageContentBlock>
        <Spacing bottom="large">
          <SubHeading className={styles.areYouSureSubHeading}>Are you sure?</SubHeading>
        </Spacing>

        <Paragraph align="center" testRef="explanation">
          By clicking YES, we will skip the direct debit of {dollar(amount)} on {shortDate(nextDebitDate)} and the next direct debit will be on {shortDate(nextSkipDebitDate)}.
        </Paragraph>

        <Spacing top="2x-large">
          <div className={styles.yesNoBtns}>
            <SecondaryButton
              onClick={skipAMonthThenGoBackToSettings}
              testRef="yes-button"
            >Yes</SecondaryButton>
            <SecondaryButton
              link={settingsUrl('1')}
              color={SecondaryButton.colors.SECONDARY}
              testRef="no-button"
            >No</SecondaryButton>
          </div>
        </Spacing>
      </PageContentBlock>
    </div>
  );
};

export default connect(
  smartInvestSelector,
  {
    fetchSmartInvestDetails: fetchSmartInvestDetailsAction,
    skipAMonthThenGoBackToSettings: skipAMonthThenGoBackToSettingsAction,
  },
)(withState({
  Component: SmartInvestSkipAMonth,
  componentDidMount: (props) => props.fetchSmartInvestDetails(),
}));
