import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { SubHeading } from 'src/design/styleguide/Headings';
import { onboardingQuestionsUrl } from 'src/utils/pageUrls';
import { showModal as showModalAction } from 'src/components/modals/modalActions';
import OnboardingScreen, {
  OnboardingScreenContent,
  OnboardingScreenHeader,
} from 'src/components/oldOnboarding/common/onboardingScreen/OnboardingScreen';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import history from 'src/browser/history';
import TMDWarningPopup from './TMDWarningPopup';
import styled from 'styled-components';
import _ from 'lodash';
import moment from 'moment';
import { sendTMDWaring } from 'scripts/redux/actions/registration';
import { getCurrentUserId } from 'scripts/auth/session';

const navigateToNextPage = () => history.push(onboardingQuestionsUrl());

const QuestionWrapper = styled.div`
margin: 10px 0 40px;
font-size: 20px;
`;

const ButtonGroup = styled.div`
margin: 10px 0;
display: flex;
align-items: center;
justify-content: flex-end;
.button {
  margin-right: 10px;
}
`;

const AnswerBar = styled.div`
margin: 10px 0;
text-align: right;
.yes {
  color: green;
}
.no {
  color: red;
}
`;


@connect(null, {
  showModal: showModalAction,
  sendTMDWaringApi: sendTMDWaring
})
export default class OnboardingTMD extends Component {
  static propTypes = {
    showModal: PropTypes.func,
    sendTMDWaring: PropTypes.func
  };

  state = {
    qStep: 0,
    questions: [{
      q: <QuestionWrapper><p>
        The BrickX Platform (<strong>Scheme</strong>) offers two investment options (Smart Invest and Build My Own) and requires you to select the investment option that best aligns with your needs.  Do you accept that BrickX does not select the relevant option for you and cannot provide you with personal advice? <br /><br /> Are you comfortable with selecting your own option and, if you select Build My Own, the specific BrickX property Trusts to invest in?
      </p>
      </QuestionWrapper>, a: null
    }, {
      q: <QuestionWrapper><p>
        Whilst the Platform does offer the ability to list your Bricks for sale to other investors, do you accept that having your capital returned to you will be subject to there being a willing buyer at a corresponding price, or that you may have to wait an extended period of time for a liquidity event to exit a specific BrickX property Trust?
      </p>
      </QuestionWrapper>, a: null
    }, {
      q: <QuestionWrapper><p>
        Do you accept that if you choose to invest in the Smart Invest option, you will not be able to withdraw any funds from your BrickX account until Smart Invest is disabled (noting that you can disable Smart Invest at any time)?
      </p></QuestionWrapper>, a: null
    }, {
      q: <QuestionWrapper><p>
        Trusts under the Scheme may use debt / gearing to acquire property assets. Do you accept that any capital gains or losses (which are determined by changes in the value of the underlying property) will be magnified, depending on the level of gearing employed?
      </p></QuestionWrapper>, a: null
    }, {
      q: <QuestionWrapper><p>
        Do you accept that the capital value of your investment in the Scheme is not guaranteed and you may not get your investment or capital value back in a timely manner or at all?
      </p></QuestionWrapper>, a: null
    }, {
      q: <QuestionWrapper><p>
        The income or distributions paid under the Scheme is not guaranteed and may vary over time. Do you accept that you should not invest if you are reliant on the income you receive from the Scheme to meet your day to day living requirements and cannot bear any fluctuations in your income distributions?
      </p></QuestionWrapper>, a: null
    }, {
      q: <QuestionWrapper><p>
        If you choose to invest using the Smart Invest option, do you accept that all income distributions will be allocated to Funds Awaiting Investment for the acquisition of additional Bricks until Smart Invest is disabled?
      </p></QuestionWrapper>, a: null
    }, {
      q: <QuestionWrapper><p>
        Diversification is an important principal of investing. Do you accept you should not invest all your savings in a single investment or asset type and this investment should form part of a broader investment portfolio?
      </p></QuestionWrapper>, a: null
    }]
  }

  render() {
    const { showModal, sendTMDWaringApi } = this.props;
    const { qStep, questions } = this.state;

    const userId = getCurrentUserId();
    const selectAnswer = () => {
      navigateToNextPage();
    };

    const trackTMDWarning = () => {
      const result = {
        t: moment().format(),
        n: questions.map((ele, seq) => [seq + 1, ele.a]).filter(ele => !ele[1]).map(ele => ele[0]).join(','),
      };
      sendTMDWaringApi(userId, result);
    };

    const openTMDWarning = () => {
      trackTMDWarning();
      showModal({
        content: <TMDWarningPopup onContinue={() => selectAnswer()}/>
      });
    };

    const onSelectAnswer = (answer) => {
      const newQuestions = questions;
      newQuestions[qStep].a = answer;
      if (qStep < questions.length - 1) {
        this.setState({
          qStep: qStep + 1,
          questions: newQuestions
        });
      } else if (qStep === questions.length - 1) {
        // Final Decision
        const allQuestionsTrue = _.every(newQuestions.map(e => e.a));
        if (!allQuestionsTrue) {
          openTMDWarning();
        } else if (allQuestionsTrue) {
          navigateToNextPage();
        }
      }
    };

    const onPreviousQuestion = () => {
      if (qStep > 0) {
        this.setState({
          qStep: qStep - 1
        });
      }
    };

    return (
      <OnboardingScreen testRef="onboarding-questions">
        <OnboardingScreenHeader>
          <Spacing bottom="large">
            <SubHeading fontSize="small">Almost there! Just a few more questions we are required by law to ask you before getting started.</SubHeading>
          </Spacing>
        </OnboardingScreenHeader>
        <OnboardingScreenContent>
          <h3>Question {qStep + 1} of {questions.length}</h3>
          {questions[qStep].q}
          <ButtonGroup>
            {qStep > 0 && <div className="button" onClick={onPreviousQuestion}>Previous Question</div>}
            <div className="button very-very-dark-blue-button " onClick={() => onSelectAnswer(false)}>No</div>
            <div className="button orange-button right-arrow-button" onClick={() => onSelectAnswer(true)}>Yes</div>
          </ButtonGroup>
          <AnswerBar>
            {questions[qStep].a === true && <span className="yes">You answer is YES</span>}
            {questions[qStep].a === false && <span className="no">You answer is NO</span>}
          </AnswerBar>
        </OnboardingScreenContent>
      </OnboardingScreen>
    );
  }
}
