import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Popover from 'scripts/components/shared/Popover';
import UpDown from 'scripts/components/shared/UpDown';
import InfoBox from 'scripts/components/shared/InfoBox';

import { percentDecimal, dollarDecimal } from 'scripts/utilities/formatters';
import { estimatedTotalReturns, accountValueAtExit, returnFromGrowth, returnFromDebt, returnFromRent, totalFees } from 'scripts/utilities/calculator/calculators';

import Trading from 'scripts/constants/Trading';


export default class ReturnsCalculatorReturnDetails extends Component {
  static propTypes = {
    hideEstimatedReturn: PropTypes.bool,
    investmentAmount: PropTypes.number.isRequired,
    investmentPeriod: PropTypes.number.isRequired,
    annualGrowthRate: PropTypes.number.isRequired,
    debtRate: PropTypes.number.isRequired,
    netRentalYieldRate: PropTypes.number.isRequired,
    initialPercentOfPropertyAsset: PropTypes.number.isRequired,
    initialPercentOfAcquisitionCost: PropTypes.number.isRequired,
    initialPercentOfCashReserve: PropTypes.number.isRequired,
    remainingMonthsOfAmortising: PropTypes.number,
  };

  render() {
    const {
      hideEstimatedReturn,
      investmentAmount,
      investmentPeriod,
      annualGrowthRate,
      debtRate,
      netRentalYieldRate,
      initialPercentOfPropertyAsset,
      initialPercentOfAcquisitionCost,
      initialPercentOfCashReserve,
      remainingMonthsOfAmortising
    } = this.props;

    const numOfRemainingMonthsOfAmortising = remainingMonthsOfAmortising ? remainingMonthsOfAmortising : Trading.NUM_OF_MONTHS_FOR_AMORTISING;
    const returnsFromCapitalGrowth = returnFromGrowth(investmentAmount, annualGrowthRate, debtRate, initialPercentOfPropertyAsset, initialPercentOfAcquisitionCost, initialPercentOfCashReserve, investmentPeriod, numOfRemainingMonthsOfAmortising);
    const returnsFromDistributions = returnFromRent(investmentAmount, netRentalYieldRate, investmentPeriod);
    const returnsFromDebt = returnFromDebt(investmentAmount, annualGrowthRate, debtRate, initialPercentOfPropertyAsset, initialPercentOfAcquisitionCost, initialPercentOfCashReserve, investmentPeriod, numOfRemainingMonthsOfAmortising);
    const estimatedAccountValueAtExit = accountValueAtExit(investmentAmount, annualGrowthRate, debtRate, netRentalYieldRate, initialPercentOfPropertyAsset, initialPercentOfAcquisitionCost, initialPercentOfCashReserve, investmentPeriod, numOfRemainingMonthsOfAmortising);
    const estimatedCapitalReturnsAtExit = estimatedTotalReturns(investmentAmount, annualGrowthRate, debtRate, netRentalYieldRate, initialPercentOfPropertyAsset, initialPercentOfAcquisitionCost, initialPercentOfCashReserve, investmentPeriod, numOfRemainingMonthsOfAmortising);
    const estimatedCapitalReturnsRate = investmentAmount === 0 ? 0 : estimatedCapitalReturnsAtExit / investmentAmount;
    const totalFeesAmount = totalFees(investmentAmount, annualGrowthRate, debtRate, initialPercentOfPropertyAsset, initialPercentOfAcquisitionCost, initialPercentOfCashReserve, investmentPeriod, numOfRemainingMonthsOfAmortising);

    const returnFromDebtPopover = 'The amplification from your capital returns, positive or negative, from having a mortgage on your BrickX property investments.';
    const totalFeesPopover = `BrickX charges a ${(Trading.BUY_BROKERAGE_PERCENTAGE)::percentDecimal()} transaction fee for buying and selling Bricks.`;


    return (
      <div className="return-calculator-returns-info filters">
        {hideEstimatedReturn
          ?
          <div className="return-calculator-returns-info__spaces">
          </div>
          :
          <div className="return-calculator-returns-info__estimated-return">
            Estimated Return
            <div className="value" data-test-reference="estimated-returns-container">
              {estimatedCapitalReturnsAtExit::dollarDecimal()}&nbsp;
              <UpDown value={estimatedCapitalReturnsRate} valueFormatted={(estimatedCapitalReturnsRate)::percentDecimal()}/>
            </div>
          </div>
        }
        <div className="return-calculator-returns-info__initial-investment">
          Initial BrickX investment
          <div className="value-right">{investmentAmount::dollarDecimal()}</div>
        </div>
        <div className="return-calculator-returns-info__details">
          <div className="return-calculator-returns-info__distributions">Distributions from Rent<div className="value-right">{returnsFromDistributions::dollarDecimal()}</div></div>
          <div className="return-calculator-returns-info__capital-growth">Capital Returns<div className="value-right">{returnsFromCapitalGrowth::dollarDecimal()}</div></div>
          <div className="return-calculator-returns-info__debt">Additional Returns from Debt <span className="slider-label"><Popover content={returnFromDebtPopover} placement="top"/></span><div className="value-right">{returnsFromDebt::dollarDecimal()}</div></div>
          <div className="return-calculator-returns-info__total-fees">Transaction Fees <span className="slider-label"><Popover content={totalFeesPopover} placement="top"/></span><div className="value-right">-{totalFeesAmount::dollarDecimal()}</div></div>
        </div>
        <div className="return-calculator-returns-info__account-value-at-exit">
          <div>
            Est. Account Value
            <div className="value-right">
              <UpDown value={estimatedCapitalReturnsRate} valueFormatted={(estimatedCapitalReturnsRate)::percentDecimal()}/>
              &nbsp;{estimatedAccountValueAtExit::dollarDecimal()}
            </div>
          </div>
        </div>
        {estimatedAccountValueAtExit < 0 &&
          <div className="return-calculator-returns-info__negative-account-value-disclosures info-box-disclosures">
            <InfoBox className="small">
              Note: Based on your chosen inputs, the Calculator is showing a negative estimated account value.  Please note that the Calculator is for illustrative purposes only and when you invest in the BrickX Platform your maximum loss cannot be more than your original investment amount.
            </InfoBox>
          </div>
        }
      </div>
    );
  }
}
