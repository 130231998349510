import { Component } from 'react';
import PropTypes from 'prop-types';
import Markdown from 'react-remarkable';

import { monthlyUpdates as monthlyUpdatesPropType } from 'scripts/constants/PropTypes';

import FinancialsPanel from 'scripts/components/shared/FinancialsPanel';

import { monthYear } from 'scripts/utilities/formatters';
import _ from 'lodash';


export default class MonthlyUpdates extends Component {
  static propTypes = {
    isPreorderOrComingSoonProperty: PropTypes.bool.isRequired,
    monthlyUpdates: monthlyUpdatesPropType
  };

  render() {
    const { monthlyUpdates, isPreorderOrComingSoonProperty } = this.props;
    const title = isPreorderOrComingSoonProperty ? 'Key Information for Pre-Order' : `Monthly Updates: ${monthlyUpdates.length ? (monthlyUpdates[0].date)::monthYear() : '' }`;

    return (
      <FinancialsPanel className="panel-financials-monthly-updates" title={title}>
        {_.isEmpty(monthlyUpdates)
          ?
          <p className="no-news">There are no updates.</p>
          :
          <div className="update-text">
            <div>
              <Markdown source={monthlyUpdates[0].monthlyDistributionInfo}/>
              <Markdown source={monthlyUpdates[0].brickValuationInfo}/>
              <Markdown source={monthlyUpdates[0].additionalInfo}/>
            </div>
          </div>
        }
      </FinancialsPanel>
    );
  }
}

