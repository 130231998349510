import {
  IDENTITY_VERIFICATION_FORM_ERROR,
  IDENTITY_VERIFICATION_FORM_ERROR_CLEARED,
  SUBMIT_IDENTITY_VERIFICATION_FORM_START,
  SUBMIT_IDENTITY_VERIFICATION_FORM_FINISH,
  STORE_IDENTITY_PAYLOAD,
} from 'scripts/redux/actions/registration';

export const identityVerificationFormErrorsSelector = (state) => ({
  identityVerificationFormErrors: state.identityVerificationForm.errors,
});

export const identityVerificationFormSubmittingSelector = (state) => ({
  submitting: state.identityVerificationForm.submitting,
});

export const identityPayloadForVedaRetrySelector = (state) => ({
  identityPayloadForVedaRetry: state.identityVerificationForm.identityPayloadForVedaRetry,
});

const defaultState = {
  errors: {},
  submitting: false,
  identityPayloadForVedaRetry: {},
};

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case SUBMIT_IDENTITY_VERIFICATION_FORM_START: return { ...state, submitting: true };
    case SUBMIT_IDENTITY_VERIFICATION_FORM_FINISH: return { ...state, submitting: false };
    case IDENTITY_VERIFICATION_FORM_ERROR: return {
      ...state,
      errors: {
        ...state.errors,
        ...payload,
      },
    };
    case IDENTITY_VERIFICATION_FORM_ERROR_CLEARED: return {
      ...state,
      errors: {
        ...state.errors,
        [payload]: undefined,
      },
    };
    case STORE_IDENTITY_PAYLOAD: return { ...state, identityPayloadForVedaRetry: payload };
    default: return state;
  }
};
