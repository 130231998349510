import decode from 'jwt-decode';
import universalStore from 'scripts/auth/universalStore';


const decodeToken = (token) => {
  if (!token) { return {}; }
  return decode(token);
};

export default {
  setAuthToken(authToken) {
    try { decode(authToken); } catch (e) { return this.clear(); }
    universalStore.set('authToken', authToken);
  },
  getAuthToken() {
    return universalStore.get('authToken');
  },
  getUserId() {
    return decodeToken(universalStore.get('authToken')).sub;
  },
  getExpiry() {
    return decodeToken(universalStore.get('authToken')).exp;
  },
  clear() {
    universalStore.set('authToken', null);
  }
};
