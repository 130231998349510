import { createAction, handleActions } from 'redux-actions';
import update from 'immutability-helper';

import UserService from 'scripts/services/UserService';


const IS_A_MEMBER = 'scripts/user/IS_A_MEMBER';
const isAMember = createAction(IS_A_MEMBER);


export function getMemberStatus() {
  return async function (dispatch, getState) {
    const { user } = getState();
    if (user.loggedIn) {
      const payload = await UserService.getMemberStatus(user.id);
      return dispatch(isAMember(payload));
    }
  };
}

const reducer = handleActions({
  [IS_A_MEMBER]: (state, action) => update(state, {
    status: {
      $set: action.payload
    }
  }),
}, {
  status: null
});

export default reducer;
