import classNames from 'classnames';

import Icon from 'src/design/components/icon/Icon';

import styles from 'src/components/propertyCards/common/propertyFeaturesBar/propertyFeaturesAmenities/PropertyFeaturesAmenities.mscss';
import { Stack } from '@mui/material';
import PropertyTypeChip from 'src/components/propertyCards/common/PropertyTypeChip';

const PropertyFeaturesAmenities = ({ property, className }) => {
  if (['unit', 'house'].includes(property.propertyType)) {
    return (
      <div className={classNames(styles.features, className)}>
        <PropertyTypeChip property={property} />
        <ul
          className={styles.amenityList}
          data-test-reference="property-features-amenities-list"
        >
          <li className={styles.amenityItem}>
            <div
              className={styles.amenityAmount}
              data-test-reference="number-of-bedrooms"
            >
              {property.bedroomCount}
            </div>
            <Icon className={styles.amenityIcon} type="bed_rooms" />
          </li>
          <li className={styles.amenityItem}>
            <div
              className={styles.amenityAmount}
              data-test-reference="number-of-bathrooms"
            >
              {property.bathroomCount}
            </div>
            <Icon className={styles.amenityIcon} type="bath_rooms" />
          </li>
          <li className={styles.amenityItem}>
            <div
              className={styles.amenityAmount}
              data-test-reference="number-of-carspots"
            >
              {property.carSpotCount}
            </div>
            <Icon className={styles.amenityIcon} type="car_parks" />
          </li>
        </ul>
      </div>
    );
  }
  if (['offices'].includes(property.propertyType)) {
    return (
      <div className={classNames(styles.features, className)}>
        <PropertyTypeChip property={property} />
        <ul
          className={styles.amenityList}
          data-test-reference="property-features-amenities-list"
        >
          <li className={styles.amenityItem}>
            <div
              className={styles.amenityAmount}
              data-test-reference="number-of-carspots"
            >
              {property.sqmArea} m²
            </div>
            <img src="/static/images/icons/icn_area.png" width="23" />
          </li>
          <li className={styles.amenityItem}>
            <div
              className={styles.amenityAmount}
              data-test-reference="area_sqm"
            >
              {property.carSpotCount}
            </div>
            <Icon className={styles.amenityIcon} type="car_parks" />
          </li>
        </ul>
      </div>
    );
  }
  if (['multiunit', 'multihouse'].includes(property.propertyType)) {
    return (
      <div className={classNames(styles.features, className)}>
        <PropertyTypeChip property={property} />
        <ul
          className={styles.amenityList}
          data-test-reference="property-features-amenities-list"
        >
          <li className={styles.amenityItem}>
            <div
              className={styles.amenityAmount}
              data-test-reference="area_sqm"
            >
              {property.multiPropertyPlan.numberOfProperties}
            </div>
            <img
              src={
                property.propertyType === 'multiunit'
                  ? '/static/icons/properties/icn_unit_pink.png'
                  : '/static/images/icons/icn_house.png'
              }
              height="20"
            />
          </li>
        </ul>
      </div>
    );
  }
  if (['development'].includes(property.propertyType)) {
    return (
      <Stack direction={'row'} spacing={1}>
        <PropertyTypeChip property={property} />
        <ul
          className={styles.amenityList}
          data-test-reference="property-features-amenities-list"
        >
          {property.bedroomCount > 0 && (
            <li className={styles.amenityItem}>
              <div
                className={styles.amenityAmount}
                data-test-reference="number-of-bedrooms"
              >
                {property.bedroomCount}
              </div>
              <Icon className={styles.amenityIconBlue} type="bed_rooms" />
            </li>
          )}
          {property.bathroomCount > 0 && (
            <li className={styles.amenityItem}>
              <div
                className={styles.amenityAmount}
                data-test-reference="number-of-bathrooms"
              >
                {property.bathroomCount}
              </div>
              <Icon className={styles.amenityIconBlue} type="bath_rooms" />
            </li>
          )}
          {property.carSpotCount > 0 && (
            <li className={styles.amenityItem}>
              <div
                className={styles.amenityAmount}
                data-test-reference="number-of-carspots"
              >
                {property.carSpotCount}
              </div>
              <Icon className={styles.amenityIconBlue} type="car_parks" />
            </li>
          )}
        </ul>
      </Stack>
    );
  }
  return (
    <div className={classNames(styles.features, className)}>
      <PropertyTypeChip property={property} />
      <ul
        className={styles.amenityList}
        data-test-reference="property-features-amenities-list"
      ></ul>
    </div>
  );
};

export default PropertyFeaturesAmenities;
