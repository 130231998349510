import React, { Component } from 'react';
import { Link } from 'react-router';


export default class PropertyReturnsCalculatorDisclaimer extends Component {
  render() {
    return (
      <small className="asterisk-info">
        Note: The BrickX Calculator is for illustrative purposes only and has been prepared without taking account of your objectives, financial situation or needs. You should consider the&nbsp;
        <Link to="/terms/property-returns-calculator-disclosures" target="_blank">
          Assumptions and Qualifications
        </Link>
        &nbsp;to understand how the calculator works and how the results can differ from actual investment returns.
      </small>
    );
  }
}
