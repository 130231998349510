import SegmentEventTypes from 'scripts/redux/actions/segment/SegmentEventTypes';
import Analytics from 'src/tracking/analytics';
import { suburb, year, date } from 'scripts/utilities/formatters';
import { track, identify } from 'src/tracking/analytics';

export function sendSignUpLinkClick({ from, located }) {
  return track(SegmentEventTypes.CLICK, { 'From': from, 'Title': SegmentEventTypes.REGISTRATION.SIGNUP_LINK, 'Located': located });
}

export function sendCompleteSignUpSegmentEvents(user, isPopup, trackingProps) {
  const payloadData = {
    firstName: user.givenName,
    email: user.email,
    'Signup Date': (user.created)::date(),
    fromComponent: 'Signup Page',
    From: 'Signup Page',
  };

  if (isPopup) {
    payloadData.From = 'Pop-Up Modal';

    if (trackingProps && trackingProps.launchedFrom) {
      payloadData.fromComponent = trackingProps.launchedFrom;
      payloadData.propertyCode = trackingProps.propertyCode;
    } else {
      console.error('no "launchedFrom" data supplied');
    }
  }

  // Analytics.alias(user.id);
  Analytics.identify(user.id, { firstName: user.givenName, email: user.email });
  Analytics.track(SegmentEventTypes.REGISTRATION.SIGNUP, payloadData);
}

export function sendResendVerifyEmailSegmentEvents(data) {
  track(SegmentEventTypes.REGISTRATION.RESEND_EMAIL, data);
}

export function sendAcceptPdsSegmentEvents(data) {
  track(SegmentEventTypes.REGISTRATION.PDS, data);
}


export function sendVerifyTokenSegmentEvents(data, user) {
  user && identify(user.id, { firstName: user.givenName, email: user.email });
  track(SegmentEventTypes.REGISTRATION.VERIFY, data);
}

export function sendSetupSegmentEvents(data) {
  track(SegmentEventTypes.REGISTRATION.SETUP, data);
}

export function sendVedaPassSegmentEvents(data) {
  track(SegmentEventTypes.REGISTRATION.VEDA_CHECK, data);
}

export function sendVedaFailFormSubmitSegmentEvents({ user, licence }) {
  Analytics.identify(user.id, { firstName: user.givenName, email: user.email });
  Analytics.track(SegmentEventTypes.REGISTRATION.VEDA_MANUAL_LICENSE_SUBMISSION, licence);
}

export function sendSubmitPersonalDetailsFormEvent({ address, dateOfBirth }) {
  return track(SegmentEventTypes.REGISTRATION.SUBMIT_PERSONAL_DETAILS,
    { 'Post Code': address::suburb(), 'Year of Birth': dateOfBirth::year() }
    );
}
