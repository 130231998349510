import $ from 'jquery';
import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import { getReferral } from 'scripts/utilities/referralHelper';
import { getReferralCookie } from 'src/utils/referrals';
import { registration as registrationPropType } from 'scripts/constants/PropTypes';
import { registrationSelector } from 'scripts/redux/selectors/registration';
import { signUpThenRedirect as signUpThenRedirectAction } from 'scripts/redux/actions/registration';
import { trackHowDidYouHearAboutUsQuestion } from 'src/tracking/onboarding';
import INVALID_EMAILS from 'scripts/constants/invalidEmails';
import Regex from 'scripts/utilities/regex';
import SignupForm from 'src/components/oldOnboarding/signup/SignupForm';
import { ACCOUNTTYPES } from 'src/settings/registration';

const getEmailFromLocation = (location) =>
  (location && location.state && location.state.email) || '';
// const getReferralTokenFromLocation = (location) =>
//   location && location.query && location.query.token;

const mapStateToProps = (state) => ({
  ...registrationSelector(state),
});

@connect(mapStateToProps, {
  signUpThenRedirect: signUpThenRedirectAction,
})
export default class SignUpForm extends Component {
  static propTypes = {
    signUpThenRedirect: PropTypes.func,
    onSignupSuccessCallback: PropTypes.func,
    registration: registrationPropType,
    isPopup: PropTypes.bool,
    isSignupPage: PropTypes.bool,
    onLoginClick: PropTypes.func,
    className: PropTypes.string,
    location: PropTypes.shape({
      state: PropTypes.shape({
        email: PropTypes.string,
      }),
    }),
    isMinor: PropTypes.bool,
    isSmsf: PropTypes.bool,
    isTrust: PropTypes.bool,
    isSmsfSignupPage: PropTypes.bool,
  };

  state = {
    email: {
      value: getEmailFromLocation(this.props.location),
      invalid: false,
    },
    givenName: {
      value: '',
      invalid: false,
    },
    password: {
      value: '',
      invalid: false,
    },
    howDidYouHearAboutUsAnswer: {
      value: '',
      customValue: '',
    },
    accountType: {
      value: this.props.isMinor
        ? ACCOUNTTYPES.MINOR
        : this.props.isSmsf
        ? ACCOUNTTYPES.SMSF
        : this.props.isTrust
        ? ACCOUNTTYPES.TRUST
        : ACCOUNTTYPES.INDIVIDUAL,
    },
    javascriptReady: false,
  };

  componentDidMount() {
    $('[data-toggle]').popover();
    this.setState({ javascriptReady: true }); // eslint-disable-line react/no-did-mount-set-state
  }

  render() {
    const {
      className,
      isSignupPage,
      onLoginClick,
      isSmsfSignupPage,
      hideSmsf,
      hideTrust,
    } = this.props;
    const {
      email,
      givenName,
      password,
      howDidYouHearAboutUsAnswer,
      accountType,
    } = this.state;

    // ========================= NEW SIGNUP FORM ENTRY
    const newSignupForm = (
      <SignupForm
        onSubmit={::this._onSignUpFormSubmit}
        onLoginClick={onLoginClick}
        accountType={{
          ...accountType,
          onChange: this._onAccountTypeChange,
        }}
        givenName={{
          ...givenName,
          onChange: this._onGivenNameChange,
        }}
        email={{
          ...email,
          onChange: this._onEmailChange,
        }}
        password={{
          ...password,
          onChange: this._onPasswordChange,
        }}
        howDidYouHearAboutUsAnswer={{
          ...howDidYouHearAboutUsAnswer,
          onChange: this._howDidYouHearAboutUsAnswer,
        }}
        disabled={!this.state.javascriptReady}
        isSmsfSignupPage={isSmsfSignupPage}
        hideSmsf={hideSmsf}
        hideTrust={hideTrust}
      />
    );

    const oldSignupForm = (
      <form
        className={classNames('signup-form', className)}
        role="form"
        id="signup-form"
        onSubmit={::this._onSignUpFormSubmit}
        data-test-reference="old-signup-form"
      >
        <div className="form-group">
          <input
            type="text"
            className="signup-form__field signup-form__given-name form-control"
            id="givenName"
            name="givenName"
            value={givenName.value}
            placeholder="First name"
            onChange={this._onGivenNameChange}
            data-trigger="focus"
            data-toggle="popover"
            data-placement="top"
            data-html="true"
            data-content="Field required, letters only."
            disabled={!this.state.javascriptReady}
            data-test-reference="name-input-field"
          />
          <div className="form-message__item error-name">
            {givenName.invalid && 'Please enter your first name'}
          </div>
        </div>

        <div className="form-group">
          <input
            type="text"
            className="signup-form__field signup-form__email form-control"
            id="email"
            name="email"
            value={email.value}
            placeholder="Email"
            onChange={this._onEmailChange}
            data-trigger="focus"
            data-toggle="popover"
            data-placement="top"
            data-html="true"
            data-content="Your Email should be valid. You will be asked to verify in the next step."
            disabled={!this.state.javascriptReady}
            data-test-reference="email-input-field"
          />
          <div className="form-message__item error-email">
            {email.invalid && 'Please enter an email address'}
          </div>
        </div>

        <div className="form-group">
          <input
            type="password"
            className="signup-form__field signup-form__password form-control"
            id="password"
            name="password"
            value={password.value}
            placeholder="Password"
            onChange={this._onPasswordChange}
            data-trigger="focus"
            data-toggle="popover"
            data-placement="top"
            data-html="true"
            data-content="Your password should contain:<ul><li>at least 8 characters;</li><li>at least 1 letter and 1 digit.</li></ul>"
            disabled={!this.state.javascriptReady}
            data-test-reference="password-input-field"
          />
          <div className="form-message__item error-password">
            {password.invalid && 'Please enter a password'}
          </div>
        </div>
        <button
          type="submit"
          className="button orange-button right-arrow-button signup-form__submit-button"
          value="Submit"
          disabled={!this.state.javascriptReady}
          data-test-reference="submit-button"
        >
          Create your Free Account
        </button>
      </form>
    );

    return isSignupPage ? newSignupForm : oldSignupForm;
  }

  _onAccountTypeChange = (value) => {
    this.setState({ accountType: { value } });
  };

  _onGivenNameChange = (e) => {
    this.setState({ givenName: { value: e.target.value } });
  };

  _onEmailChange = (e) => {
    this.setState({ email: { value: e.target.value.trim() } });
  };

  _onPasswordChange = (e) => {
    this.setState({ password: { value: e.target.value } });
  };

  _howDidYouHearAboutUsAnswer = (values) => {
    this.setState({
      howDidYouHearAboutUsAnswer: {
        ...this.state.howDidYouHearAboutUsAnswer,
        ...values,
      },
    });
  };

  _validateGivenName = () => {
    const givenName = this.state.givenName;
    givenName.invalid = !givenName.value.match(Regex.name);
    this.setState({ givenName: givenName });
  };

  _validateEmail = () => {
    const email = this.state.email;
    email.invalid =
      !email.value.match(Regex.email) ||
      _.includes(INVALID_EMAILS, email.value) ||
      email.value === '';
    this.setState({ email: email });
  };

  _validatePassword = () => {
    const password = this.state.password;
    password.invalid =
      !password.value.trim().match(Regex.password) ||
      password.value.trim() === '';
    this.setState({ password: password });
  };

  _validateForm = () => {
    this._validateEmail();
    this._validateGivenName();
    this._validatePassword();
  };

  _onSignUpFormSubmit(e) {
    e.preventDefault();
    this._validateForm();
    const {
      email,
      givenName,
      password,
      accountType,
      howDidYouHearAboutUsAnswer,
    } = this.state;
    if (email.invalid || givenName.invalid || password.invalid) {
      return;
    }
    if (howDidYouHearAboutUsAnswer.value) {
      trackHowDidYouHearAboutUsQuestion(
        howDidYouHearAboutUsAnswer.value === 'Other'
          ? `Other: ${howDidYouHearAboutUsAnswer.customValue}`
          : howDidYouHearAboutUsAnswer.value
      );
    }
    const { isPopup, signUpThenRedirect, onSignupSuccessCallback } = this.props;
    const { partnerReferralId } = getReferral();
    const { referralRecordId: customerReferralId } = getReferralCookie();
    const formatHowDidYouHearAboutUsAnswer = (howDidYouHearAboutUsAnswer) => {
      if (!howDidYouHearAboutUsAnswer.value) return '';
      if (howDidYouHearAboutUsAnswer.value === 'other') {
        return `other: ${howDidYouHearAboutUsAnswer.customValue}`;
      }
      return howDidYouHearAboutUsAnswer.value;
    };
    signUpThenRedirect(
      {
        givenName: givenName.value,
        email: email.value,
        password: password.value,
        accountType: accountType.value,
        howDidYouHearAboutUsAnswer: formatHowDidYouHearAboutUsAnswer(
          howDidYouHearAboutUsAnswer
        ),
        customerReferralId,
        partnerReferralId,
      },
      isPopup
    );
    if (onSignupSuccessCallback) onSignupSuccessCallback();
  }
}
