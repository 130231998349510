const store = {};

const get = (key) => {
  if (!CLIENT) {
    const getNamespace = require('continuation-local-storage').getNamespace;
    const namespace = getNamespace('myAppNamespace');
    return namespace.get(key);
  }
  return store[key];
};

const set = (key, value) => {
  if (!CLIENT) {
    const getNamespace = require('continuation-local-storage').getNamespace;
    const namespace = getNamespace('myAppNamespace');
    namespace.set(key, value);
  } else {
    store[key] = value;
  }
};

export default {
  get,
  set,
};
