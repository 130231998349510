import PrimaryButton from 'src/design/components/button/PrimaryButton';

import { signupUrl } from 'src/utils/pageUrls';

const GetStartedButton = ({ className, ...props }) =>
  <PrimaryButton
    className={className}
    link={signupUrl()}
    color={PrimaryButton.colors.SECONDARY}
    {...props}
  >
    Sign Up Now
  </PrimaryButton>;

export default GetStartedButton;
