import { withRouter } from 'react-router';
import styled, { css } from 'styled-components';
import { SubHeading } from 'src/design/styleguide/Headings';
import { boldFontWeight } from 'src/design/styleguide/common/fonts';
import {
  breakpointMedium,
  breakpointSmall,
} from 'src/design/styleguide/common/breakpoints';
import {
  boVedaFailedUrl,
  dashboardUrl,
  documentsUrl,
  logoutUrl,
  pendingOrdersUrl,
  portfolioUrl,
  settingsUrl,
  smsfAndTrustInReviewUrl,
  transactionsUrl,
  walletUrl,
} from 'src/utils/pageUrls';
import { getThemeColor } from 'src/themes/themeUtils';
import {
  gridGap,
  specialGradient,
} from 'src/components/dashboard/common/styles';
import {
  gridUnit,
  sideGutter,
} from 'src/design/styleguide/common/measurements';
import { shadowDepth1 } from 'src/design/styleguide/common/embellishments';
import { testRefToDataTestReference } from 'src/design/styleguide/common/styledComponentsUtils';
import { connect } from 'react-redux';
import { userSelector } from 'scripts/redux/actions/user';
import ContentBlock from 'src/design/styleguide/contentBlock/ContentBlock';
import Expandable from 'src/design/components/expandable/Expandable';
import Hyperlink from 'src/design/components/hyperlink/Hyperlink';
import Icon from 'src/design/components/icon/Icon';
import Image from 'src/design/components/image/Image';
import RotatingIcon from 'src/design/components/icon/RotatingIcon';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import Text from 'src/design/styleguide/text/Text';
import withFetching from 'src/decorators/withFetching';
import withState from 'src/decorators/withState';
import {
  hasMinorDetails,
  isMinorAccount,
  isSMSForTrustAccount,
  isSmsfAndTrustUserProcessed,
  isUserBoAllVerified,
} from 'scripts/utilities/userAccountHelper';
import {
  themeForBrickXMinorLandingPage,
  themeForSmsfAccount,
} from 'src/themes/landingPageThemes';
import history from 'src/browser/history';

const menuItems = [
  {
    href: dashboardUrl(),
    iconType: 'dashboard',
    text: 'Dashboard',
    itemTestRef: 'dashboard-home-nav-item',
  },
  {
    href: portfolioUrl(),
    iconType: 'portfolio',
    text: 'Portfolio',
    itemTestRef: 'portfolio-nav-item',
  },
  {
    href: walletUrl(),
    iconType: 'wallet',
    text: 'Wallet',
    itemTestRef: 'wallet-nav-item',
  },
  {
    href: pendingOrdersUrl(),
    iconType: 'pending_orders',
    text: 'Pending Orders',
    itemTestRef: 'pending-orders-nav-item',
  },
  {
    href: transactionsUrl(),
    iconType: 'transactions',
    text: 'Transaction History',
    itemTestRef: 'transaction-history-nav-item',
  },
  {
    href: documentsUrl(),
    iconType: 'documents',
    text: 'Documents',
    itemTestRef: 'documents-nav-item',
  },
  {
    href: settingsUrl(),
    iconType: 'settings',
    text: 'Account Settings',
    itemTestRef: 'settings-nav-item',
  },
  {
    href: logoutUrl(),
    iconType: 'logout',
    text: 'Logout',
    itemTestRef: 'logout-nav-item',
  },
];

const MenuHeaderContainer = styled.div.attrs(testRefToDataTestReference)`
  padding: ${gridUnit * 3}px ${gridUnit * 4}px ${gridUnit * 6}px
    ${gridUnit * 4}px;
  background: ${specialGradient};
  cursor: pointer;

  ${breakpointSmall(css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${gridUnit * 12}px 0 ${gridUnit * 8}px 0;
    background: ${getThemeColor('surface')};
  `)}
`;

const CurrentPageHeading = styled(SubHeading)`
  display: flex;
  justify-content: space-between;

  ${breakpointSmall(`
    display: none;
  `)}
`;

const MenuHeaderIcon = styled(Image)`
  display: none;

  ${breakpointSmall(`
    display: block;
    width: 24px;
  `)}
`;

const MenuHeaderText = styled(Text).attrs({
  as: 'p',
  fontSize: { base: '3x-small', breakpointSmall: '4x-small' },
})`
  color: ${getThemeColor('onDarkSurfaceContrast')};

  ${breakpointSmall(css`
    color: ${getThemeColor('onSurfaceContrast800')};
  `)}
`;

const isActive = (location, href) => location.pathname.includes(href);

const withActiveRoute = (WrappedComponent) =>
  withRouter(({ location, ...props }) => (
    <WrappedComponent {...props} active={isActive(location, props.href)} />
  ));

const getActiveMenuItemText = (location) => {
  const activeMenuItem = menuItems.find(({ href }) => isActive(location, href));
  return activeMenuItem ? activeMenuItem.text : '';
};

const renderCustomMenuHeaderIcon = (user) => {
  if (isMinorAccount(user)) {
    return (
      <MenuHeaderIcon src="/static/img/icon-dashboard-navigation-header-feature-yolk.svg" />
    );
  }
  if (isSMSForTrustAccount(user)) {
    return (
      <MenuHeaderIcon src="/static/img/icon-dashboard-navigation-header-feature-blue.svg" />
    );
  }
  return (
    <MenuHeaderIcon src="/static/img/icon-dashboard-navigation-header-feature.svg" />
  );
};

const getCustomThemeColor = (user) => {
  if (isMinorAccount(user)) {
    return themeForBrickXMinorLandingPage.colors.primary;
  }
  if (isSMSForTrustAccount(user)) {
    return themeForSmsfAccount.colors.primary;
  }
  return getThemeColor('primary');
};

const MenuHeader = withRouter(
  withFetching(
    [userSelector],
    []
  )(({ user, location, expanded, ...props }) => {
    const hasMinorDetailsResult = hasMinorDetails(user);
    return (
      <MenuHeaderContainer {...props}>
        {renderCustomMenuHeaderIcon(user)}
        <Spacing
          top={{ base: 'none', breakpointSmall: 'normal' }}
          bottom={{ base: 'large', breakpointSmall: 'none' }}
        >
          <MenuHeaderText testRef="dashboard-navigation-greeting">
            {hasMinorDetailsResult ? (
              <span>
                {user.givenName}'s Account
                <br />
                <small>as trustee for {user.minorDetails.firstName}</small>
              </span>
            ) : (
              `${user.givenName}'s Account`
            )}
          </MenuHeaderText>
        </Spacing>
        <CurrentPageHeading
          fontSize="x-small"
          fontWeight="bold"
          color="onDarkSurfaceContrast"
          align="left"
          testRef="header-current-page"
        >
          {getActiveMenuItemText(location)}
          <RotatingIcon
            type="chevron_down"
            rotated={expanded}
            data-test-reference="chevron-icon"
          />
        </CurrentPageHeading>
      </MenuHeaderContainer>
    );
  })
);

const NavigationPanel = styled.div`
  background: ${specialGradient};
  border-radius: var(--bx-radius);
  box-shadow: var(--bx-shadow);
  overflow: hidden;
  margin-left: var(--bx-ms---layout-mobile-padding);
  margin-right: var(--bx-ms---layout-mobile-padding);
  margin-bottom: var(--bx-ms---layout-mobile-padding);

  ${breakpointSmall(css`
    margin-left: 0;
    margin-right: 0;
    background: ${getThemeColor('surface')};
    padding-bottom: ${gridUnit * 22}px;
  `)}
`;

const MenuItemListPanel = styled(Expandable)`
  background: ${getThemeColor('surface')};
  box-shadow: ${shadowDepth1};

  ${breakpointSmall(`
    height: auto !important;
    box-shadow: none;
  `)}
`;

const MenuItemList = styled.ul`
  padding: ${gridUnit * 6}px 0;
  margin: 0;

  ${breakpointSmall(`
    padding: 0;
  `)}
`;

const MenuItemLink = styled(Hyperlink)`
  display: flex;
  align-items: center;
  padding: ${gridUnit * 4}px;
  color: ${getThemeColor('onSurfaceContrast500')};

  &:hover {
    background-color: var(--bx-color---background);
  }

  ${(props) =>
    props.active &&
    css`
      color: ${getThemeColor('onSurfaceContrast800')};
      border-left: ${gridUnit}px solid
        ${(p) => p.highlightColor || getThemeColor('primary')};
      padding-left: ${gridUnit * 3}px;
      font-weight: ${boldFontWeight};
      background-color: var(--bx-color---background);
    `}
`;

const MenuItemLi = styled.li`
  & + & {
    margin-top: ${gridUnit * 2}px;
  }
`;

const MenuItem = withActiveRoute(
  ({ className, text, iconType, highlightColor, ...props }) => (
    <MenuItemLi>
      <MenuItemLink
        className={className}
        highlightColor={highlightColor}
        {...props}
      >
        <Spacing right="normal">
          <Text as={Icon} type={iconType} fontSize="2x-small" />
        </Spacing>
        <Text as="p" fontSize="2x-small">
          {text}
        </Text>
      </MenuItemLink>
    </MenuItemLi>
  )
);

const Navigation = ({
  className,
  expanded,
  onExpand,
  onItemSelect,
  user,
  testRef,
}) => (
  <div className={className} data-test-reference={testRef}>
    <NavigationPanel>
      <MenuHeader
        expanded={expanded}
        onClick={onExpand}
        testRef="expandable-menu-header"
      />
      <MenuItemListPanel expanded={expanded}>
        <MenuItemList>
          {menuItems.map(({ href, iconType, text, itemTestRef }) => (
            <MenuItem
              key={href}
              href={href}
              onClick={onItemSelect}
              iconType={iconType}
              text={text}
              highlightColor={getCustomThemeColor(user)}
              testRef={itemTestRef}
            />
          ))}
        </MenuItemList>
      </MenuItemListPanel>
    </NavigationPanel>
  </div>
);

const PageContainer = styled(ContentBlock)`
  ${breakpointSmall(css`
    padding: ${gridUnit * 10}px ${sideGutter} ${gridUnit * 15}px ${sideGutter};

    background-color: ${getThemeColor('backgroundFaintGrey')};
  `)}
`;

const PageContainerStyledInner = ({ className, ...props }) => (
  <PageContainer innerContainerClassName={className} {...props} />
);

const NavigationContainer = styled(PageContainerStyledInner)`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;

  ${breakpointSmall(`
    display: grid;
    grid-template-columns: 220px minmax(0, 1fr);
    grid-column-gap: ${gridGap};
  `)}

  ${breakpointMedium(`
    grid-template-columns: 276px minmax(0, 1fr);
  `)}
`;

const ContentContainer = styled.div`
  flex: 1 0 auto;
`;

const BottomNavigation = styled(Navigation)`
  ${breakpointSmall(`display: none;`)}
`;

const DashboardNavigation = connect(
  (state) => ({
    ...userSelector(state),
  }),
  {}
)(
  withState({
    name: 'DashboardNavigation',
    mapPropsToInitialState: () => ({
      topNavExpanded: false,
      bottomNavExpanded: false,
    }),
    componentDidMount: ({ user }) => {
      const isUserBoAllVerified_ = isUserBoAllVerified(user);
      if (!isUserBoAllVerified_) {
        history.push(boVedaFailedUrl());
      }
      const isSmsfAndTrustUserProcessed_ = isSmsfAndTrustUserProcessed(user);
      if (!isSmsfAndTrustUserProcessed_) {
        history.push(smsfAndTrustInReviewUrl());
      }
    },
    Component: ({
      children,
      state: { topNavExpanded, bottomNavExpanded },
      setState,
      user,
    }) => {
      const toggleTopNav = () => setState({ topNavExpanded: !topNavExpanded });
      const toggleBottomNav = () =>
        setState({ bottomNavExpanded: !bottomNavExpanded });
      const closeNav = () =>
        setState({
          topNavExpanded: false,
          bottomNavExpanded: false,
        });
      return (
        <NavigationContainer noGutter testRef="dashboard-navigation">
          <Spacing bottom={{ base: 'x-large', breakpointSmall: 'none' }}>
            <Navigation
              onExpand={toggleTopNav}
              expanded={topNavExpanded}
              onItemSelect={closeNav}
              user={user}
              testRef="top-nav-bar"
            />
          </Spacing>
          <ContentContainer>{children}</ContentContainer>
          <Spacing top="x-large">
            <BottomNavigation
              onExpand={toggleBottomNav}
              expanded={bottomNavExpanded}
              onItemSelect={closeNav}
              testRef="bottom-nav-bar"
            />
          </Spacing>
        </NavigationContainer>
      );
    },
  })
);

export default DashboardNavigation;
