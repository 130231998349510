import { cloneElement } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { Heading } from 'src/design/styleguide/Headings';
import {
  breakpoint2xSmall,
  breakpointLarge
} from 'src/design/styleguide/common/breakpoints';
import { getFontSize, getFontWeight } from 'src/design/styleguide/text/Text';
import { getThemeColor } from 'src/themes/themeUtils';
import {
  gridUnit,
  headerHeight,
  sideGutter
} from 'src/design/styleguide/common/measurements';
import {
  standardDuration,
  standardFunction
} from 'src/design/styleguide/common/animations';
import { testRefToDataTestReference } from 'src/design/styleguide/common/styledComponentsUtils';
import IconButton from 'src/design/components/button/IconButton';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import styles from 'src/design/components/header/Header.mscss';

const HamburgerButton = styled(IconButton)`
  && {
    font-size: ${gridUnit * 6}px;
    height: 100%;
    margin-right: -${sideGutter};
    padding: 0 ${sideGutter};
    background-color: #ffffff;
    ${props => props.mobileMenuOpen && `color: ${getThemeColor('primary')(props)};`}

    ${breakpointLarge(`
      display: none;
    `)}
  }
`;

const IconTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled(Heading)`
  font-size: 20px;
  font-weight: ${getFontWeight('regular')};

  ${breakpoint2xSmall(`
    font-size: ${getFontSize('x-small')};
    font-weight: ${getFontWeight('medium')};
  `)}
`;

const HeaderContainer = styled.div`
  padding: 0 ${sideGutter};
`;

const HeaderInnerContainer = styled.header.attrs(testRefToDataTestReference)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  max-width: 1200px;
  height: ${headerHeight};
  margin-left: auto;
  margin-right: auto;
  transition: background ${standardDuration} ${standardFunction};
`;

const Header = ({ title, children, logo, mobileMenuOpen, onLogoClick, onHamburgerIconClick, hidden }) => {
  return (
    <HeaderContainer>
      <HeaderInnerContainer
        testRef="header-container"
      >
        <IconTitleContainer>
          {logo && cloneElement(logo, {
            className: styles.brickXLogo,
            onClick: onLogoClick,
            testRef: 'brickx-logo'
          })}
          <Spacing left="normal">
            <Title>
              {title}
            </Title>
          </Spacing>
        </IconTitleContainer>
        {hidden ? null : (
          <div
            className={classNames(styles.menu, { [styles.menu_closed]: !mobileMenuOpen })}
            data-test-reference="menu"
          >
            {children}
          </div>
        )}
        {(hidden || !children) ? null : (
          <HamburgerButton
            type={IconButton.types.menu}
            onClick={onHamburgerIconClick}
            mobileMenuOpen={mobileMenuOpen}
            testRef="menu-button"
          />
        )}
      </HeaderInnerContainer>
    </HeaderContainer>
  );
};

export default Header;
