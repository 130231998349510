import { createAction, handleActions } from 'redux-actions';
import update from 'immutability-helper';


const SUCCESS = 'scripts/success/SUCCESS';
const success = createAction(SUCCESS);

export const successAnswer = (answer) => success(answer);

const reducer = handleActions({
  [SUCCESS]: (state, action) => update(state, {
    $set: action.payload
  })
}, null);

export default reducer;
