import styled from 'styled-components';
import { HeadingSmall } from 'src/design/components/heading/Headings';
import { redirectToStepPage } from 'src/utils/user';
import ContentContainer from 'src/design/styleguide/contentContainer/ContentContainer';
import Paragraph from 'src/design/components/bodyText/Paragraph';
import SecondaryButton from 'src/design/components/button/SecondaryButton';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import styles from 'src/components/oldOnboarding/welcomeBack/WelcomeBack.mscss';

const WelcomeBackContainer = styled(ContentContainer).attrs({
  topPadding: { breakpointSmall: 'large' },
  bottomPadding: { breakpointSmall: 'large' },
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const WelcomeBack = ({ user }) => (
  <WelcomeBackContainer testRef="welcome-back-page">
    <Spacing bottom="2x-large">
      <img
        src="/static/img/icon-filling-form-out.svg"
        alt="Illustration of a girl drawing on a form with a giant pencil"
      />
    </Spacing>

    <Spacing bottom="x-small">
      <HeadingSmall><h1>Welcome back {user.givenName}</h1></HeadingSmall>
    </Spacing>

    <Spacing bottom="large">
      <Paragraph textSize="small">Looks like you didn't get a chance to finish the setup steps.</Paragraph>
    </Spacing>

    <SecondaryButton
      className={styles.continueButton}
      color={SecondaryButton.colors.PRIMARY}
      testRef="welcome-back-continue-button"
      onClick={redirectToStepPage(user)}
    >
      Continue
    </SecondaryButton>
  </WelcomeBackContainer>
);

export default WelcomeBack;
