import styled from 'styled-components';
import { getThemeColor } from 'src/themes/themeUtils';
import Icon from 'src/design/components/icon/Icon';

const Container = styled.div`
  display: flex;
  align-items: center;
  ${props => props.signum === 'positive' && `color: ${getThemeColor('green')(props)}`};
  ${props => props.signum === 'negative' && `color: ${getThemeColor('red')(props)}`};
`;

const getSignumOfValue = (value) => value > 0
  ? 'positive'
  : value < 0
  ? 'negative'
  : 'equal';

const UpDownNumber = ({ value, children, noSymbolZero }) => {
  const signum = getSignumOfValue(value);

  return (
    <Container signum={signum}>
      {signum === 'positive' && <Icon type="caret_up"/>}
      {signum === 'negative' && <Icon type="caret_down"/>}
      {signum === 'equal' && !noSymbolZero && '= '}
      {children}
    </Container>
  );
};

export default UpDownNumber;
