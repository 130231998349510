import PageMarginContainer from 'src/design/components/pageMarginContainer/PageMarginContainer';
import { HeadingSmall } from 'src/design/components/heading/Headings';
import BodyText from 'src/design/components/bodyText/BodyText';
import Paragraph from 'src/design/components/bodyText/Paragraph';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import SecondaryButton from 'src/design/components/button/SecondaryButton';
import LazyLoad from 'react-lazyload';
import styles from 'scripts/components/property/MoreAboutBrickxProperties.mscss';

const Item = ({
  imageSrc,
  title,
  description,
  buttonText,
  buttonLink,
}) => (
  <div className={styles.item}>
    <a href={buttonLink}>
      <LazyLoad>
        <img className={styles.item__image} src={imageSrc}/>
      </LazyLoad>
    </a>
    <BodyText align="center" color="salmon">
      <h3>{title}</h3>
    </BodyText>
    <Spacing top="small">
      <Paragraph
        className={styles.item__description}
        align="center"
        textSize="small"
      >
        {description}
      </Paragraph>
    </Spacing>
    <Spacing top="large">
      <SecondaryButton
        arrow
        size={SecondaryButton.sizes.EXTRA_SMALL}
        link={buttonLink}
      >
        {buttonText}
      </SecondaryButton>
    </Spacing>
  </div>
);

const MoreAboutBrickxProperties = () => (
  <PageMarginContainer>
    <HeadingSmall align="center">
      <h2>More about BrickX Properties</h2>
    </HeadingSmall>
    <Spacing top="large">
      <div className={styles.itemContainer}>
        <Item
          // imageSrc="/static/images/propertyexperts.png"
          // imageSrc="https://image.flaticon.com/icons/png/512/476/476759.png"
          title="BrickX Team & Partners"
          description="Learn about our investment philosophy, our experienced management team and partners including NAB and Corelogic."
          buttonText="Meet the BrickX Team"
          buttonLink="/team"
        />
        <Item
          // imageSrc="/static/images/laptop_survey.png"
          // imageSrc="https://image.flaticon.com/icons/png/512/476/476705.png"
          title="Feedback on Properties"
          description="At BrickX we strive to represent our investors' needs. Take 5 minutes to give feedback and help us learn about your investment criteria."
          buttonText="Give Property Feedback"
          buttonLink="https://www.surveymonkey.com/r/BRICKXPropertyFeedback"
        />
      </div>
    </Spacing>
  </PageMarginContainer>
);

export default MoreAboutBrickxProperties;
