import { BsDownload } from 'react-icons/bs';
import Tag from 'src/design/components/Tag';
import Colors from 'src/themes/brickx/colors';
import styled from 'styled-components';

export const TopDocumentsContainer = styled.div`
  display: grid;
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
  grid-template-columns: repeat(1, 1fr);
  grid-gap: var(--bx-ms---grid-unit-3);

  margin-bottom: var(--bx-ms---grid-unit-6);

  .documentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--bx-ms---grid-unit-5);
    background-color: ${Colors.backgroundFaintGrey};
    border-radius: var(--bx-radius);
    transition: all 0.3s;
    position: relative;

    &:hover {
      background-color: ${Colors.white};
      cursor: pointer;
      box-shadow: var(--bx-shadow);
    }
    .icon {
      font-size: 5rem;
      color: ${Colors.primary};
    }
    .title {
      font-size: 1.8rem;
      font-weight: bold;
      text-align: center;
      line-height: 2rem;
    }
    .subTitle {
      margin-top: var(--bx-ms---grid-unit-3);
      text-align: center;
    }
    .hoverIcon {
      display: block;
      font-size: 2rem;
      position: absolute;
      right: var(--bx-ms---grid-unit-5);
      top: var(--bx-ms---grid-unit-5);
      animation: moveUpAndFadeIn 0.2s;
      @media (min-width: 768px) {
        display: none;
      }
    }

    &:hover {
      .hoverIcon {
        display: block;
      }
    }
  }
`;

export const TopDocumentContainer = ({
  title,
  onClick,
  onClickTestRef,
  subTitle,
  icon,
  isNew,
  hoverIcon,
}) => {
  const showHoverIcon = hoverIcon;
  const shownIcon = typeof hoverIcon === 'boolean' ? <BsDownload /> : hoverIcon;
  return (
    <div
      className="documentContainer"
      onClick={onClick}
      data-test-reference={onClickTestRef}
    >
      {icon && <div className="icon">{icon}</div>}
      <div className="title">
        {title} {isNew && <Tag label="new" />}
      </div>
      <div className="subTitle">{subTitle}</div>
      {showHoverIcon && <div className="hoverIcon">{shownIcon}</div>}
    </div>
  );
};
