import classNames from 'classnames';
import { connect } from 'react-redux';
import withState from 'src/decorators/withState';

import { saveBankDetails as saveBankDetailsAction } from 'scripts/redux/actions/user';

import InfoBox from 'src/design/components/infoBox/InfoBox';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import BankAccountDetailsInput from 'src/components/settings/BankAccountDetailsInput';
import Spacing from 'src/design/styleguide/spacing/Spacing';

import styles from 'src/components/settings/Settings.mscss';


const BankDetailsForm = ({ state, setState, saveBankDetails, className }) => {
  const {
    bankName,
    bankAccountName,
    bankAccountBsb,
    bankAccountNumber,
    submitting,
  } = state;

  const onSubmit = () => {
    setState({ submitting: true });
    saveBankDetails({ bankAccountName, bsb: bankAccountBsb, bankAccountNumber });
  };

  const bankDetailsFilled = bankAccountName && bankAccountBsb && bankAccountNumber;
  const bankDetailsValid = bankDetailsFilled
    && bankAccountName.length >= 3
    && bankName
    && bankAccountNumber.length >= 6;

  const submitDisabled = !bankDetailsValid || submitting;

  return (
    <div
      className={classNames(className, styles.bankDetailsFormContainer)}
      data-test-reference="old-settings-bank-details-form"
    >
      <Spacing bottom="large">
        <InfoBox className={styles.bankDetailsFormInfoBox}>
          Please enter your Bank Account details to withdraw your wallet funds.
        </InfoBox>
      </Spacing>
      <div className={styles.bankDetailsFormInputsArea}>
        <BankAccountDetailsInput
          { ... { bankAccountName, bankAccountNumber, bankAccountBsb } }
          onBankNameChange={(val) => setState({ bankName: val })}
          onBankAccountNameChange={(val) => setState({ bankAccountName: val })}
          onBankAccountBsbChange={(val) => setState({ bankAccountBsb: val })}
          onBankAccountNumberChange={(val) => setState({ bankAccountNumber: val })}
        />
        <Spacing top="large" bottom="large">
          <PrimaryButton
            fullWidth
            color={PrimaryButton.colors.SECONDARY}
            disabled={submitDisabled}
            loading={submitting}
            loadingText="Saving"
            onClick={(e) => {
              e.preventDefault();
              onSubmit();
            }}
            testRef="submit-button"
          >Save</PrimaryButton>
        </Spacing>
      </div>
    </div>
  );
};

export default connect(null, {
  saveBankDetails: saveBankDetailsAction,
})(withState({
  Component: BankDetailsForm,
  mapPropsToInitialState: () => ({
    bankName: '',
    bankAccountName: '',
    bankAccountBsb: '',
    bankAccountNumber: '',
    submitting: false,
  }),
}));
