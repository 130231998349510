import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ResetPasswordMeta from 'src/components/meta/ResetPasswordMeta';
import { alertsSelector } from 'scripts/redux/selectors/alerts';
import { requestResetPasswordToken } from 'scripts/redux/actions/resetPassword';
import OnboardingScreen, {
  OnboardingScreenHeader,
  OnboardingScreenContent,
} from 'src/components/oldOnboarding/common/onboardingScreen/OnboardingScreen';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import { Heading } from 'src/design/components/heading/Headings';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import Styledlink from 'src/design/components/hyperlink/Styledlink';
import { loginUrl } from 'src/utils/pageUrls';

const mapStateToProps = (state) => ({
  ...alertsSelector(state),
});

@connect(mapStateToProps, {
  requestResetPasswordToken,
})
export default class ResetPassword extends Component {
  static propTypes = {
    requestResetPasswordToken: PropTypes.func.isRequired,
  };

  state = {
    email: ''
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <OnboardingScreen id="reset-password" className="reset-password">
        <ResetPasswordMeta />
        <OnboardingScreenHeader>
          <Spacing bottom="2x-large">
            <Heading><h1 className="login-header">Reset your password</h1></Heading>
          </Spacing>
        </OnboardingScreenHeader>
        <OnboardingScreenContent>
          <div style={{ textAlign: 'center' }}>
            <div className="white-box reset-password-request-token">
              <p>
                Enter your email.<br/>
                We'll email you instructions<br/>
                on how to reset your password
              </p>
              <form className="reset-password-request-token-form" onSubmit={::this._onResetPasswordRequestSubmit}>
                <Spacing bottom="normal">
                  <input type="email" className="reset-password-request-token-form__email large" placeholder="Email" required onChange={::this._onEmailChange}/>
                </Spacing>
                <PrimaryButton color={PrimaryButton.colors.SECONDARY} type="submit" fullWidth arrow>RESET PASSWORD</PrimaryButton>
              </form>
              <Styledlink href={loginUrl()}>Return to login</Styledlink>
            </div>
          </div>
        </OnboardingScreenContent>
      </OnboardingScreen>
    );
  }

  _onEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  _onResetPasswordRequestSubmit(e) {
    e.preventDefault();
    const { requestResetPasswordToken } = this.props;
    requestResetPasswordToken(this.state);
  }
}
