import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  breakpoint2xSmall,
  breakpointMedium,
  breakpointSmall,
} from 'src/design/styleguide/common/breakpoints';
import { fetchProperties as fetchPropertiesAction } from 'scripts/redux/actions/properties';
import { getBrandColor } from 'src/themes/themeUtils';
import { gridUnit } from 'src/design/styleguide/common/measurements';
import { howItWorksUrl } from 'src/utils/pageUrls';
import { propertiesSelector } from 'scripts/redux/selectors/properties';
import { scrollToId } from 'src/browser/window';
import { smsfHowToInvestId } from 'src/components/landingPages/smsf/Smsf';
import ClonedChild from 'src/design/styleguide/common/ClonedChild';
import ContentBlock from 'src/design/styleguide/contentBlock/ContentBlock';
import FinePrintParagraph from 'src/design/styleguide/FinePrintParagraph';
import Icon from 'src/design/components/icon/Icon';
import Paragraph from 'src/design/styleguide/Paragraph';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import SecondaryButton from 'src/design/components/button/SecondaryButton';
import SmsfPageSectionHeading from 'src/components/landingPages/smsf/SmsfPageSectionHeading';
import createBreakpoint from 'src/design/styleguide/common/createBreakpoint';
import withState from 'src/decorators/withState';
const buttonsFitBreakpoint = createBreakpoint('480px');

const StyledWhyInvest = styled(ContentBlock)`
  padding-top: ${gridUnit * 10}px;
  padding-bottom: ${gridUnit * 10}px;

  ${breakpointSmall(`
    padding-top: ${gridUnit * 20}px;
    padding-bottom: ${gridUnit * 20}px;
  `)}

  ${breakpointMedium(`
    padding-top: ${gridUnit * 30}px;
    padding-bottom: ${gridUnit * 30}px;
  `)}
`;

const HeaderHeading = styled(SmsfPageSectionHeading)`
  font-size: 40px;

  ${breakpoint2xSmall(`
    font-size: 48px;
  `)}

  ${buttonsFitBreakpoint(`
    text-align: left;
  `)}
`;

const ReasonsContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpointSmall(`
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  `)}
`;

const IconTextContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: ${gridUnit * 6}px;

  &:last-child {
    padding-bottom: 0;
  }

  ${breakpointSmall(`
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0px;
    max-width: 180px;
    margin-right: ${gridUnit * 2}px;
    margin-left: ${gridUnit * 2}px;
    padding-bottom: 0;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  `)}
`;

const StyledIcon = styled(Icon)`
  flex: 0 0 60px;
  color: ${getBrandColor.blue.primary};
  font-size: ${(props) => props.size || '45px'};
  padding-right: ${gridUnit * 2}px;
  text-align: center;
`;

const IconText = ({ icon, iconSize, children }) => (
  <IconTextContainer>
    <StyledIcon type={icon} size={iconSize} />
    <Paragraph color="light">{children}</Paragraph>
  </IconTextContainer>
);

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${gridUnit * 10}px;

  ${buttonsFitBreakpoint(`
    flex-direction: row;
  `)}

  ${breakpointSmall(`
    margin-top: ${gridUnit * 12}px;
  `)}
`;

const SignupButton = styled(PrimaryButton)`
  margin-bottom: ${gridUnit * 2}px;

  ${buttonsFitBreakpoint(`
    margin-bottom: 0;
    margin-right: ${gridUnit * 4}px;
  `)}
`;

const CtaButton = styled(ClonedChild)`
  white-space: nowrap;
`;

const FinePrint = styled(FinePrintParagraph)`
  margin-top: ${gridUnit * 10}px;

  ${buttonsFitBreakpoint(`
    text-align: left;
  `)}

  ${breakpointSmall(`
    margin-top: ${gridUnit * 12}px;
  `)}
`;

const FinePrintBr = styled.br`
  display: none;

  ${breakpointSmall(`
    display: block;
  `)}
`;

const WhyInvest = connect(
  (state) => ({
    numberOfProperties: propertiesSelector(state).properties.length || '--',
  }),
  { fetchProperties: fetchPropertiesAction }
)(
  withState({
    componentDidMount: ({ fetchProperties }) => fetchProperties(),
    Component: ({ className, numberOfProperties }) => (
      <StyledWhyInvest
        className={className}
        backgroundImage="/static/img/photo-retired-couple-walking-with-bikes-in-the-forest.jpg"
        backgroundPositionX="75%"
        overlayColor="charcoal"
        overlayOpacity="0.9"
        width="medium"
      >
        <HeaderHeading color="light">
          Why invest your
          <br />
          SMSF or Trust with BrickX?
        </HeaderHeading>

        <ReasonsContainer>
          <IconText icon={Icon.types.different_bricks}>
            <strong>Diversify</strong> your SMSF or other Trust's exposure to
            the property market across BrickX’s {numberOfProperties} properties.
          </IconText>

          <IconText icon={Icon.types.hand_pointing_at_calendar}>
            <strong>Flexibility</strong> to buy and sell Bricks at any time.*
          </IconText>

          <IconText icon={Icon.types.funnel}>
            <strong>Simplify</strong> investing in the property market with our
            quick setup. Leave property management to us!
          </IconText>

          <IconText icon={Icon.types.transparent_glass}>
            <strong>Transparency</strong> in property information including
            forecasted cash flows, pricing and valuations.
          </IconText>
        </ReasonsContainer>

        <ButtonsContainer>
          <CtaButton>
            <SignupButton
              color={PrimaryButton.colors.BLUE}
              onClick={() => scrollToId(smsfHowToInvestId())}
            >
              Get Started Now
            </SignupButton>
          </CtaButton>

          <CtaButton>
            <SecondaryButton
              color={SecondaryButton.colors.DARK_CONTRAST}
              link={howItWorksUrl()}
              target="_blank"
            >
              Learn How BrickX Works
            </SecondaryButton>
          </CtaButton>
        </ButtonsContainer>

        <FinePrint align="center">
          *Note: For the period your deposited funds are pending (estimated 1-2
          days),
          <FinePrintBr /> you are not eligible to list your Bricks for sale.
          This only applies for advanced funds.
        </FinePrint>
      </StyledWhyInvest>
    ),
  })
);

export default WhyInvest;
