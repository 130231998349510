import classNames from 'classnames';


const rootStyleClass = { PRIMARY: 'property-card-stat--primary' };
const labelStyleClass = { PRIMARY: 'property-card-stat__label--primary' };
const valueStyleClass = { PRIMARY: 'property-card-stat__value--primary' };

const PropertyCardStat = ({ label, value, style, valueProps, labelProps }) => !value ? null : (
  <div className={classNames('property-card-stat', rootStyleClass[style])}>
    <div className={classNames('property-card-stat__label', labelStyleClass[style])} {...labelProps}>{label}</div>
    <div className={classNames('property-card-stat__value', valueStyleClass[style])} {...valueProps}>{value}</div>
  </div>
);

PropertyCardStat.styles = { PRIMARY: 'PRIMARY' };

export default PropertyCardStat;
