import FormCheckbox from 'src/design/components/formCheckbox/FormCheckbox';
import DirectDebitRequestLink from 'src/components/oldOnboarding/smartInvestDetails/bankDetailsInput/DirectDebitRequestLink';


const DirectDebitRequestCheckbox = ({ disabled, values, ...props }) => {
  const link = (
    <DirectDebitRequestLink
      values={values}
      disabled={disabled}
      testRef="direct-debit-request-link"
    >
      The Brick Exchange Pty Ltd Direct Debit Request available here
    </DirectDebitRequestLink>
  );

  return (
    <FormCheckbox
      disabled={disabled}
      {...props}
    >
      I confirm and authorise BrickX to proceed with {link}.
    </FormCheckbox>
  );
};

export default DirectDebitRequestCheckbox;
