import classNames from 'classnames';
import Hyperlink from 'src/design/components/hyperlink/Hyperlink';
import { homeUrl } from 'src/utils/pageUrls';
import styles from 'src/components/brickxLogo/BrickxLogo.mscss';

const BrickxLogo = ({ className, src, onClick, testRef, ...props }) => (
  <Hyperlink
    className={classNames(styles.root, className)}
    href={homeUrl()}
    onClick={onClick}
    testRef={testRef}
    {...props}
  >
    <img
      src={src}
      alt="BrickX logo"
    />
  </Hyperlink>
);

export const BrickxLogoDark = (props) => (<BrickxLogo {...props} src="/static/img/bx-logo-dark.svg"/>);
export const BrickxLogoRentToBuyDark = (props) => (<BrickxLogo {...props} src="/static/img/bx-rent-to-buy-logo-dark.svg"/>);
export const BrickxLogoLight = (props) => (<BrickxLogo {...props} src="/static/img/bx-logo-light.svg"/>);
export const BrickxLogoAgriGreen = (props) => (<BrickxLogo {...props} src="/static/img/bx-agri-green.svg"/>);
export const BrickxMinorLogo = (props) => (<BrickxLogo {...props} src="/static/img/bx-logo-yolk.svg"/>);
// export const BrickxWholesaleLogo = (props) => (<BrickxLogo {...props} src="/static/img/bx-logo-yolk.svg"/>);
