import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Numbers from 'scripts/constants/Numbers';
import _ from 'lodash';


export default class ReturnsCalculatorHoldingPeriodSelector extends Component {
  static propTypes = {
    investmentPeriod: PropTypes.number.isRequired,
    updateHoldingPeriodCallback: PropTypes.func.isRequired,
    className: PropTypes.string
  };

  render() {
    const { investmentPeriod, className } = this.props;
    return (
      <select id="investment-period" className={className} value={investmentPeriod} onChange={this._updateInvestmentPeriod} data-test-reference="holding-period">
        {[..._.range(Numbers.ZERO, Numbers.TEN)].map((index) =>
          <option key={index + 1} value={index + 1}>{(index + 1) === 1 ? '1 year' : `${index + 1} years`}</option>
        )}
      </select>
    );
  }

  _updateInvestmentPeriod = (event) => {
    this.props.updateHoldingPeriodCallback(event.target.value * 1);
  }
}
