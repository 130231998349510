import React from 'react';
import { AccountSwitchMeta } from 'src/components/meta/LoginMeta';
import OnboardingScreen, {
  OnboardingScreenHeader,
  OnboardingScreenContent,
} from 'src/components/oldOnboarding/common/onboardingScreen/OnboardingScreen';
import LoginForm from 'src/components/oldOnboarding/login/LoginForm';
import Spacing from 'src/design/styleguide/spacing/Spacing';

const AddNewAccountPage = ({ onPreviousPage }) => {
  return (
    <OnboardingScreen>
      <AccountSwitchMeta />
      <OnboardingScreenContent>
        <OnboardingScreenHeader>
          <div className="stack stack-row stack-between">
            <div className="button" onClick={onPreviousPage}>
              <i className="fa fa-chevron-left"></i> back
            </div>
          </div>
          <Spacing bottom="large">
            <h1>
              <strong>Add another Account</strong>
            </h1>
          </Spacing>
          <LoginForm doRedirect />
        </OnboardingScreenHeader>
      </OnboardingScreenContent>
    </OnboardingScreen>
  );
};

export default AddNewAccountPage;
