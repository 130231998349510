import FinancialsPanel from 'scripts/components/shared/FinancialsPanel';
import { property as propertyPropType } from 'scripts/constants/PropTypes';
import { capitalize, dollar } from 'scripts/utilities/formatters';
import { InitialDebt } from 'src/settings/financials';

const DebtBreakdown = ({ property }) => {
  const isGeared = property.financials.gearingEffect > 0;
  const isFixedTerm =
    isGeared && property.financials.debtTerms.fixedLoanTerms > 0;
  return (
    <FinancialsPanel className="debt-breakdown-panel" title="Debt Breakdown">
      <div className="row my-row">
        <div className="col-xs-7 col-no-padding-right bold">Initial Debt</div>
        <div className="col-xs-5 col-no-padding-left text-right initial-debt">
          {isGeared
            ? InitialDebt[property.propertyCode] !== undefined
              ? InitialDebt[property.propertyCode]::dollar()
              : property.financials.debtTerms.initialDebt::dollar()
            : '$0'}
        </div>
      </div>
      <div className="gray-line" />

      <div className="row my-row">
        <div className="col-xs-7 col-no-padding-right bold">Loan Terms</div>
        <div className="col-xs-5 col-no-padding-left text-right loan-terms">
          {isGeared
            ? `${property.financials.debtTerms.loanTerms} Years`
            : '0 Year'}
        </div>
      </div>
      <div className="gray-line" />

      <div className="row my-row">
        <div className="col-xs-7 col-no-padding-right bold">Loan Type</div>
        <div className="col-xs-5 col-no-padding-left text-right loan-term-type">
          {isGeared
            ? property.financials.debtTerms.loanTermType::capitalize()
            : 'NA'}
        </div>
      </div>
      <div className="gray-line" />

      <div className="row my-row">
        <div className="col-xs-6 col-no-padding-right bold">Fixed Terms</div>
        <div className="col-xs-6 col-no-padding-left text-right fixed-terms">
          {isFixedTerm
            ? `${property.financials.debtTerms.fixedLoanTerms} Years (Interest Only)`
            : 'NA'}
        </div>
      </div>

      <div className="gray-line" />

      <div className="row my-row">
        <div className="col-xs-7 col-no-padding-right bold">Interest Rate</div>
        <div className="col-xs-5 col-no-padding-left text-right interest-rate">
          {isGeared
            ? `${property.financials.debtTerms.interestRatePerc}%`
            : '0%'}
        </div>
      </div>

      <div className="gray-line" />

      <div className="row my-row note">
        <div className="col-md-12">
          Note:{' '}
          {isGeared ? `${property.financials.debtTerms.loanDescription}` : 'NA'}
        </div>
      </div>
    </FinancialsPanel>
  );
};

DebtBreakdown.propTypes = {
  property: propertyPropType.isRequired,
};

export default DebtBreakdown;
