import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Highchart from 'src/third-party/highchart/Highchart';

import { dollar, roundPercentDecimal } from 'scripts/utilities/formatters';
import { estimatedTotalReturns, accountValueAtExit, accountValueAtXYears } from 'scripts/utilities/calculator/calculators';
import Trading from 'scripts/constants/Trading';
import _ from 'lodash';


const CHART_SPACING = [20, 0, 0, 0]; // eslint-disable-line no-magic-numbers
const SALMON = '#FF8282';
const OFF_WHITE = '#ffffff'; // $grey-200

export default class ReturnsCalculatorAccountValueGraph extends Component {
  static propTypes = {
    className: PropTypes.string,
    showGraphTitleOnTop: PropTypes.bool,
    investmentAmount: PropTypes.number.isRequired,
    investmentPeriod: PropTypes.number.isRequired,
    annualGrowthRate: PropTypes.number.isRequired,
    debtRate: PropTypes.number.isRequired,
    netRentalYieldRate: PropTypes.number.isRequired,
    initialPercentOfPropertyAsset: PropTypes.number.isRequired,
    initialPercentOfAcquisitionCost: PropTypes.number.isRequired,
    initialPercentOfCashReserve: PropTypes.number.isRequired,
    remainingMonthsOfAmortising: PropTypes.number,
  };

  render() {
    const {
      className,
      showGraphTitleOnTop,
      investmentPeriod,
      investmentAmount,
      annualGrowthRate,
      debtRate,
      netRentalYieldRate,
      initialPercentOfPropertyAsset,
      initialPercentOfAcquisitionCost,
      initialPercentOfCashReserve,
      remainingMonthsOfAmortising
    } = this.props;

    const numOfRemainingMonthsOfAmortising = remainingMonthsOfAmortising ? remainingMonthsOfAmortising : Trading.NUM_OF_MONTHS_FOR_AMORTISING;
    const estimatedCapitalReturnsAtExit = estimatedTotalReturns(investmentAmount,
      annualGrowthRate,
      debtRate,
      netRentalYieldRate,
      initialPercentOfPropertyAsset,
      initialPercentOfAcquisitionCost,
      initialPercentOfCashReserve,
      investmentPeriod,
      numOfRemainingMonthsOfAmortising,
    );
    const graphTitle = { text: 'Est. Account Value', style: { color: '#41414b' } };

    return (
      <div className={`return-calculator__graph ${className}`}>
        <Highchart hotReload key="chart-return-calculator" className="return-calculator__highchart" config={{
          chart: {
            type: 'areaspline',
            backgroundColor: OFF_WHITE,
            spacing: CHART_SPACING,
          },
          title: showGraphTitleOnTop ? graphTitle : { text: null },
          legend: {
            enabled: false,
            itemStyle: {
              'color': SALMON, 'cursor': 'pointer', 'fontSize': '12px', 'fontWeight': 'bold'
            }
          },
          xAxis: {
            categories: this._getXaxisValue(),
            title: {
              text: 'Length of Investment',
              style: { color: '#41414b' }
            },
            labels: {
              style: { color: '#41414b' },
              align: 'left',
              x: -1
            },
          },
          yAxis: {
            title: showGraphTitleOnTop ? { text: null } : graphTitle,
            labels: {
              style: { color: '#41414b' },
              formatter: function () {
                return this.value::dollar();
              }
            },
            min: estimatedCapitalReturnsAtExit <= 0 ? estimatedCapitalReturnsAtExit : 0
          },
          credits: {
            enabled: false
          },
          plotOptions: {
            series: {
              fillOpacity: 0.5,
              animation: false
            },
            areaspline: {
              lineColor: '#666666',
              lineWidth: 1,
              marker: {
                lineWidth: 1,
                lineColor: '#666666'
              },
              threshold: null,
            }
          },
          tooltip: {
            shared: true,
            crosshairs: {
              color: 'white',
              width: 1.5,
              dashStyle: 'solid'
            },
            headerFormat: `<span style="font-size: 10px"><b>Year </b>{point.key}</span><br/>`,
            backgroundColor: '#ffffff',
            borderRadius: 8,
            borderWidth: 1,
            shadow: false,
            useHTML: true,
            valuePrefix: '$',
          },
          series: [
            {
              name: 'Account Value',
              color: SALMON,
              lineColor: SALMON,
              data: this._getXaxisValue().map((years) => {
                var result = 0;
                if (years === investmentPeriod) {
                  result = accountValueAtExit(investmentAmount, annualGrowthRate, debtRate, netRentalYieldRate, initialPercentOfPropertyAsset, initialPercentOfAcquisitionCost, initialPercentOfCashReserve, years)::roundPercentDecimal();
                } else {
                  result = accountValueAtXYears(investmentAmount, annualGrowthRate, debtRate, netRentalYieldRate, initialPercentOfPropertyAsset, initialPercentOfAcquisitionCost, initialPercentOfCashReserve, years)::roundPercentDecimal();
                }
                return result;
              })
            }
          ]
        }}/>
      </div>

    );
  }

  _getXaxisValue = (investmentPeriod = this.props.investmentPeriod) => {
    return _.range(0, investmentPeriod + 1);
  }
}
