import { createAction, handleActions } from 'redux-actions';
import update from 'immutability-helper';
import { notFoundUrl } from 'src/utils/pageUrls';
import {
  saveReferralCookie,
  saveReferralPreActivationCookie,
} from 'src/utils/referrals';
import { setReferral } from 'scripts/utilities/referralHelper';
import Constants from 'scripts/constants/Constants';
import ReferralService from 'scripts/services/ReferralService';
import history from 'src/browser/history';

const CUSTOMER_REFERRAL = 'scripts/referral/CUSTOMER_REFERRAL';
const customerReferral = createAction(CUSTOMER_REFERRAL);
const REFERRAL_REGISTRATION = 'scripts/referral/REFERRAL_REGISTRATION';
const referralRegistration = createAction(REFERRAL_REGISTRATION);

export function registerCustomerReferral(token) {
  return function (dispatch) {
    if (!token) return history.push(notFoundUrl());

    return ReferralService.registerCustomerReferral({ token: token })
      .then((payload) => {
        saveReferralCookie(payload);
        saveReferralPreActivationCookie(payload);
        return dispatch(referralRegistration(payload));
      })
      .catch(() => history.push(notFoundUrl()));
  };
}

export function registerPartnerReferral(token) {
  return async function (dispatch) {
    try {
      const registrationReferral =
        await ReferralService.registerPartnerReferral({ token: token });
      setReferral(registrationReferral, Constants.TRUE);
      dispatch(referralRegistration(registrationReferral));
    } catch (e) {
      console.error(e);
    }
  };
}

export function fetchCustomerReferral(userId) {
  return (dispatch, getState) => {
    const { user } = getState();
    return ReferralService.getCustomerReferral(userId || user.id).then(
      (payload) => dispatch(customerReferral(payload))
    );
  };
}

export function shareReferralViaEmails(userId, data) {
  return async function () {
    try {
      return await ReferralService.shareViaEmails(userId, data);
    } catch (e) {
      console.error('Failed to share referral via email.');
    }
  };
}

export function checkCustomerReferralEligibility(userId) {
  return () =>
    ReferralService.checkCustomerReferrerEligibility(userId).catch((err) =>
      Promise.reject(err)
    );
}

const reducer = handleActions(
  {
    [CUSTOMER_REFERRAL]: (state, action) =>
      update(state, {
        customerReferral: {
          $set: action.payload,
        },
      }),
    [REFERRAL_REGISTRATION]: (state, action) =>
      update(state, {
        registration: {
          $set: action.payload,
        },
      }),
  },
  {
    customerReferral: null,
    registration: null,
  }
);

export default reducer;
