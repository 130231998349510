import { css, keyframes } from 'styled-components';
import { gridUnit } from 'src/design/styleguide/common/measurements';

export const standardDuration = '250ms';
export const slowDuration = '350ms';
export const standardFunction = 'ease-in-out';
export const standardTransition = `${standardDuration} ${standardFunction}`;

const moveUpAndFadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(15px);
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
`;

const moveDownAndFadeOut = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(15px);
  }
`;

const pulsing = keyframes`
  from { opacity: 0.5; }
  to { opacity: 0.2; }
`;

const eggTimer = keyframes`
  0% { transform: rotate(180deg); }
  40% { transform: rotate(180deg); }
  60% { transform: rotate(0deg); }
  100% { transform: rotate(0deg); }
`;

const wipeDownwards = keyframes`
  0% { height: 100%; }
  40% { height: 0%; }
  60% { height: 0%; }
  100% { height: 100%; }
`;

const scaleBulge = keyframes`
  from { transform: scale(1); }
  to { transform: scale(1.3); }
`;

const bobUpAndDown = keyframes`
  from { transform: translateY(-${gridUnit}px); }
  to { transform: translateY(${gridUnit}px); }
`;

export const animationInfinitePulsing = () => css`
  animation: ${pulsing} 300ms linear 0s infinite alternate;
`;

export const animationEggTimerSpin = () => css`
  animation: ${eggTimer} 1.25s linear 0s infinite alternate;
`;

export const animationWipeDownwards = () => css`
  animation: ${wipeDownwards} 2.5s linear -0.55s infinite;
`;

export const animationGentleScaleBulge = () => css`
  animation: ${scaleBulge} 0.9s ${standardFunction} 0s infinite alternate;
`;


export const animationBobUpAndDown = () => css`
  animation: ${bobUpAndDown} 500ms ${standardFunction} 0s infinite alternate;
`;

export const animationMoveUpAndFadeIn = (delay = '0ms', duration = standardDuration) => css`
  animation: ${moveUpAndFadeIn} ${duration} ${standardFunction};
  animation-delay: ${delay};
  animation-fill-mode: both;
`;

export const animationMoveDownAndFadeOut = (delay = '0ms', duration = standardDuration) => css`
  animation: ${moveDownAndFadeOut} ${duration} ${standardFunction};
  animation-delay: ${delay};
  animation-fill-mode: both;
`;
