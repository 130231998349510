import { Component } from 'react';
import { connect } from 'react-redux';
import DocumentMeta from 'react-document-meta';
import PropTypes from 'prop-types';
import { DEPOSIT_META } from 'scripts/metadata/account';
import { bankTransferMoneyArrivalDate } from 'scripts/utilities/helpers';
import {
  bpay as bpayPropType,
  feeFreePromotionStatus as feeFreePromotionStatusPropType
} from 'scripts/constants/PropTypes';
import { bpaySelector } from 'scripts/redux/selectors/bpay';
import { feeFreePromotionStatusSelector } from 'scripts/redux/selectors/market';
import { feesPromo2018TermsUrl } from 'src/utils/pageUrls';
import { fetchFeeFreePromotionStatus } from 'scripts/redux/actions/market';
import { sendBpayDepositEmail } from 'scripts/redux/actions/bpay';
import InfoBox from 'scripts/components/shared/InfoBox';
import Modal from 'scripts/components/helpers/Modal';
import Styledlink from 'src/design/components/hyperlink/Styledlink';


const mapStateToProps = (state) => ({
  ...bpaySelector(state),
  ...feeFreePromotionStatusSelector(state)
});

@connect(mapStateToProps, {
  sendBpayDepositEmail,
  fetchFeeFreePromotionStatus
})
export default class BpayDetails extends Component {
  static propTypes = {
    bpay: bpayPropType.isRequired,
    sendBpayDepositEmail: PropTypes.func.isRequired,
    fetchFeeFreePromotionStatus: PropTypes.func.isRequired,
    feeFreePromotionStatus: feeFreePromotionStatusPropType
  };

  componentDidMount() {
    const { fetchFeeFreePromotionStatus, bpay, sendBpayDepositEmail } = this.props;
    fetchFeeFreePromotionStatus();

    window.scrollTo(0, 0);

    if (bpay && bpay.refNumber) {
      sendBpayDepositEmail();
    }
  }

  render() {
    const { bpay, feeFreePromotionStatus } = this.props;
    const billerCode = bpay.billerCode ? bpay.billerCode : '180653';
    const ref = bpay.refNumber ? bpay.refNumber : 'XXX XXX XXXX';

    return (
      <div className="deposit-success deposit-success-bpay">
        <DocumentMeta {...DEPOSIT_META} />
        <div className="container">
          {feeFreePromotionStatus && feeFreePromotionStatus.enabled &&
            <InfoBox className="deposit-success__settings-blue-notification">
              Buy Bricks today to take advantage of the No Fees to Buy Bricks promo. Promo ends 11.59pm, 20 Nov. <Styledlink href={feesPromo2018TermsUrl()}>See T&Cs</Styledlink>
              {/* Want to take advantage of the No Fees to Buy Bricks promo? Promo ends 11.59pm, 20 Nov. The estimated arrival for your funds is {bankTransferMoneyArrivalDate()}. Choose POLi to receive your funds immediately (up to $20k). <Styledlink href={feesPromo2018TermsUrl()}>See T&Cs</Styledlink> */}
            </InfoBox>
          }
          <div className="deposit-success-body">
            <div className="deposit-success__title deposit-success-bpay__title"><strong>Your BPAY details</strong></div>
            <div className="deposit-success__sub-title deposit-success-bpay__sub-title">Now, log in to your internet banking or contact your bank to make the deposit using BPAY</div>
            <div className="deposit-success-bpay-info">
              <div className="deposit-success-bpay-block-wrap">
              <div className="deposit-success-bpay-block">
                <div className="deposit-success-bpay-block__details">
                  <div className="deposit-success-bpay-block__logo">
                    <img className="deposit-success-bpay-logo__image" src="/static/images/deposit/bpay_logo_small.png" />
                  </div>
                  <div className="deposit-success-bpay-block__info">
                    <div className="deposit-success-bpay-block__info-text">Biller Code: <span className="biller-code"><strong>{billerCode}</strong></span></div>
                    <div className="deposit-success-bpay-block__info-text">Ref: <span className="customer-reference"><strong>{ref}</strong></span></div>
                  </div>
                </div>
                <hr className="deposit-success-bpay-block__separator"/>
                <div className="deposit-success-bpay-block__description">
                  Telephone &amp; Internet Banking – BPAY®
                  <p className="deposit-success-bpay-block__description-contact">Contact your bank or financial institution to make this  payment from your cheque, savings, debit or transaction account. More info: <a href="http://www.bpay.com.au" target="_blank">www.bpay.com.au</a></p>
                </div>
              </div>
              </div>
              <div className="deposit-success-bpay-other-details">
                <div className="deposit-success-bpay-estimated-arrival">
                  <img className="deposit-success-bpay-estimated-arrival__image" src="/static/images/icons/icn_calendar.png"/>
                  ESTIMATED ARRIVAL: <span className="deposit-success-bpay-estimated-arrival__date text-orange">{bankTransferMoneyArrivalDate()}</span>
                </div>
                <ul className="deposit-success-bpay-good-points">
                  <li>Your unique BPAY Details</li>
                  <li>Simple and free - no fees</li>
                  <li>No registrations needed</li>
                  <li>Funds delivered in 1-2 business day</li>
                </ul>
                Note: BrickX does not accept payment using credit cards via BPAY.
                <a onClick={this._onLearnMoreClick} className="deposit-success-bpay-learn-more-link"><span className="deposit-success-bpay-learn-more-link__arrow fa fa-angle-right"/>LEARN MORE</a>
              </div>
            </div>
            <div className="deposit-success-bpay-bank-info">
              <div className="deposit-success-bpay-bank-info__navigate-title deposit-success__title">Navigate to your bank</div>
              <div className="deposit-success-bpay-bank-info__navigate-sub-title deposit-success__sub-title">Don’t see your bank? No worries, we accept BPAY from any BPAY participating financial institution - simply log in to your internet or telephone banking.</div>
              <div className="deposit-success-bpay-bank-info__banks">
                <a href="https://www.my.commbank.com.au/netbank/Logon/Logon.aspx" target="_blank"><img className="deposit-success-bpay-bank-info__bank-logo" src="/static/images/bank-icons/commbank.gif"/></a>
                <a href="https://banking.westpac.com.au/wbc/banking/handler?TAM_OP=login&segment=personal&logout=false" target="_blank"><img className="deposit-success-bpay-bank-info__bank-logo" src="/static/images/bank-icons/westpac.gif"/></a>
                <a href="https://www.anz.com/INETBANK/bankmain.asp?_ga=2.252931849.1224788643.1497488531-1390836690.1497488531" target="_blank"><img className="deposit-success-bpay-bank-info__bank-logo" src="/static/images/bank-icons/ANZ.gif"/></a>
                <a href="https://ib.nab.com.au/nabib/index.jsp" target="_blank"><img className="deposit-success-bpay-bank-info__bank-logo" src="/static/images/bank-icons/NAB.gif"/></a>
                <a href="https://ibanking.stgeorge.com.au/ibank/loginPage.action" target="_blank"><img className="deposit-success-bpay-bank-info__bank-logo" src="/static/images/bank-icons/stgeorge.gif"/></a>
                <a href="https://www.ingdirect.com.au/securebanking/" target="_blank"><img className="deposit-success-bpay-bank-info__bank-logo" src="/static/images/bank-icons/ING.gif"/></a>
              </div>
            </div>
          </div>
        </div>
        <Modal sizeClass="modal-md" id="bpay-notes-modal" ref="bpayNotesModal" title="How long will it take to receive my funds?" body={
          <div className="deposit-success-bpay-notes">
            <div className="deposit-success-bpay-notes__funds-available-time">
              <p className="bold">If you made your BPAY Deposit <span className="text-orange">before</span> 5:00pm (AEST) Monday to Friday:</p>
              <p>Your funds should reach your account the following morning, however some Banks may take a little longer. As soon as we receive your funds, we will inform you via email and credit your Digital Wallet.</p>
              <p className="bold">If you made your BPAY Deposit <span className="text-orange">after</span> 5:00pm (AEST) Monday to Friday:</p>
              <p>These deposits are usually processed the following business day which will add at least a day until your funds reach your Digital Wallet. Payments made on Fridays after 5:00pm (AEST), will not be processed by your bank until the following Monday and therefore will not be in your Digital Wallet account until Tuesday morning.</p>
              <p className="bold">Note: BrickX does not accept payment using credit cards via BPAY.</p>
            </div>
          </div>
        }/>
      </div>
    );
  }

  _onLearnMoreClick = () => {
    this.refs.bpayNotesModal.open();
  }
}
