

import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from 'scripts/components/shared/Icon';


const UpDown = ({ className, value, valueFormatted, testRef }) => {
  const isUp = value >= 0;
  return (
    <span
      className={classNames(className, 'up-down', {
        'up': isUp,
        'down': !isUp
      })}
      data-test-reference={testRef}
    >
      <Icon type="fa" name={{
        'caret-up': isUp,
        'caret-down': !isUp
      }}/>
      {valueFormatted}
    </span>
  );
};

UpDown.propTypes = {
  value: PropTypes.number.isRequired,
  valueFormatted: PropTypes.node.isRequired,
};

export default UpDown;
