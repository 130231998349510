import { Component } from 'react';
import { TextRow, RectShape } from 'react-placeholder/lib/placeholders';

import Configuration from 'scripts/constants/Configuration';
import { isMobile } from 'scripts/utilities/helpers';


const backgroundColor = Configuration.PLACEHOLDER_COMPONENT_BG_COLOR;

class PropertyCardFinancialInfo extends Component {
  render() {
    return (
      <div className="row financial-info-placeholder">
        <div className="col-xs-4 col-sm-6">
          <div>
            <TextRow color={backgroundColor}/>
          </div>
          <div>
            <TextRow color={backgroundColor}/>
          </div>
        </div>
        <div className="col-xs-4 col-sm-6">
          <div>
            <TextRow color={backgroundColor}/>
          </div>
          <div>
            <TextRow color={backgroundColor}/>
          </div>
        </div>
      </div>
    );
  }
}

class PropertyCardBodyContent extends Component {
  render() {
    return (
      <div className="body-content-placeholder">
        {isMobile()
          ?
          <div>
            <div className="general-info-placeholder">
              <div className="col-xs-4 col-sm-6 general-info-box-placeholder">
                <div>
                  <TextRow color={backgroundColor}/>
                </div>
                <div>
                  <TextRow color={backgroundColor}/>
                </div>
              </div>
              <div className="col-xs-4 col-sm-6 general-info-box-placeholder">
                <div>
                  <TextRow color={backgroundColor}/>
                </div>
                <div>
                  <TextRow color={backgroundColor}/>
                </div>
              </div>
              <div className="col-xs-4 col-sm-6 general-info-box-placeholder">
                <div>
                  <RectShape color={backgroundColor} className="button-placeholder"/>
                </div>
              </div>
            </div>
          </div>
          :
          <div>
            <PropertyCardFinancialInfo/>

            <div className="row">
              <div className="col-md-12">
                <div className="grey-line"/>
              </div>
            </div>

            <div className="row">
              <div className="general-info-placeholder">
                <div className="col-xs-4 col-sm-6 general-info-box-placeholder">
                  <div>
                    <TextRow color={backgroundColor}/>
                  </div>
                  <div>
                    <TextRow color={backgroundColor}/>
                  </div>
                </div>
                <div className="col-xs-4 col-sm-6 general-info-box-placeholder">
                  <div>
                    <TextRow color={backgroundColor}/>
                  </div>
                  <div>
                    <TextRow color={backgroundColor}/>
                  </div>
                </div>

              </div>
              <div className="col-md-12">
                <RectShape color={backgroundColor} className="brick-price-info-box-placeholder"/>
                <RectShape color={backgroundColor} className="button-placeholder"/>
              </div>
            </div>
          </div>
        }
      </div>

    );
  }
}

export default class PropertyCardPlaceHolder extends Component {
  render() {
    return (
      <div className="property-card-placeholder">

        <div className="header-placeholder">
          <RectShape color={backgroundColor} className="header-placeholder"/>
          <img src="/static/img/bx-logo-x-only-placeholder.svg" width={150}/>
        </div>

        <div className="body">
          <PropertyCardBodyContent/>
        </div>
      </div>
    );
  }
}
