/* global CLIENT */
import moment from 'moment-timezone';
import _ from 'lodash';
import $ from 'jquery';
import DateTime from 'scripts/constants/DateTime';

export const getByPropertyCode = (properties, propertyCode) =>
  _.find(properties, { propertyCode: propertyCode.toUpperCase() });

export const getAdjacent = (collection, query, wrap = true) => {
  const index = _.indexOf(collection, _.find(collection, query));
  if (index === -1) return [];
  const prevIndex = index - 1;
  const nextIndex = index + 1;
  return [
    collection[
      wrap ? (prevIndex + collection.length) % collection.length : prevIndex
    ],
    collection[wrap ? nextIndex % collection.length : nextIndex],
  ];
};

export function normalizePercent() {
  return this / 100;
}

export function normalizeNumber() {
  return this * 100;
}

export function roundTransactionFee() {
  if (!this && this !== 0) {
    return undefined;
  }
  return Math.floor(this * 100) / 100;
}

export function truncate(numDigits = 2) {
  const factor = 10 ** numDigits;
  return Math.round(this * factor) / factor;
}

export function scrollTo(anchorPoint) {
  const animationLength = 700;
  $('html, body').animate(
    {
      scrollTop: $(anchorPoint).offset().top,
    },
    animationLength
  );
}

export function scrollToTop() {
  window.scrollTo(0, 0);
}

export function bankTransferMoneyArrivalDate() {
  var date = moment();

  const dayOfTheWeek = date.day();
  const hourOfTheDay = date.hour();
  const lastHour = 17;
  const sunday = 0;
  const tuesday = 2;
  const friday = 5;
  const saturday = 6;
  const nextTuesday = 9;

  if (dayOfTheWeek === sunday) {
    date = date.day(tuesday);
  } else if (
    dayOfTheWeek === saturday ||
    (dayOfTheWeek === friday && hourOfTheDay >= lastHour)
  ) {
    date = date.day(nextTuesday);
  } else if (dayOfTheWeek === friday) {
    date = date.day(saturday);
  } else if (hourOfTheDay >= lastHour) {
    date = date.add('days', 2);
  } else if (hourOfTheDay < lastHour) {
    date = date.add('days', 1);
  }

  return date.format('ddd DD MMM');
}

export function isMobile() {
  if (!CLIENT) {
    return false;
  }
  return window.matchMedia('(max-width: 767px)').matches; // eslint-disable-line no-magic-numbers
}

export function isXmasAndNYHoliday() {
  const today = moment().tz(DateTime.TIME_ZONE);
  const startDateOfHoliday = moment('2018-12-21T17:00:00').tz(
    DateTime.TIME_ZONE
  );
  const endDateOfHoliday = moment('2019-01-06T23:59:59').tz(DateTime.TIME_ZONE);
  return today.isBetween(startDateOfHoliday, endDateOfHoliday);
}
