import classNames from 'classnames';
import { HeadingXSmall } from 'src/design/components/heading/Headings';

import styles from 'src/components/landingPages/common/pinkAllCapsSubtitle/PinkAllCapsSubTitle.mscss';

const PinkAllCapsSubTitle = ({ children, className }) => (
  <HeadingXSmall
    className={classNames(styles.pinkAllCapsSubtitle, className)}
  >
    <h5>{children}</h5>
  </HeadingXSmall>
);

export default PinkAllCapsSubTitle;
