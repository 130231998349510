import request from 'scripts/services/request';
import {
  signUp,
  resendEmail,
  acceptPds,
  verifyToken,
  mydetails,
  contact,
  tmdWarning,
  abnLookup,
  acnLookup,
  myMinorDetails,
  wholesaleInvestorRegister,
  smartRenterDetailsUrl,
  mySmsfDetails,
} from 'scripts/services/api';
import { headers, withCredentials } from 'scripts/services/helpers';

class RegistrationService {
  signUp(data) {
    return request(signUp(), {
      method: 'POST',
      headers,
      body: JSON.stringify(data),
    });
  }

  resendEmail(userId) {
    return request(resendEmail(userId), {
      method: 'POST',
      headers,
      ...withCredentials,
      body: JSON.stringify({}),
    });
  }

  acceptPds(userId, dateOfBirth) {
    return request(acceptPds(userId), {
      method: 'POST',
      headers,
      ...withCredentials,
      body: JSON.stringify({ dateOfBirth }),
    });
  }

  verifyToken(token) {
    return request(verifyToken(token), {
      method: 'POST',
      headers,
      body: JSON.stringify({}),
    });
  }

  sendVedaEmail(data) {
    return request(contact(), {
      method: 'POST',
      ...withCredentials,
      headers,
      body: JSON.stringify(data),
    });
  }

  sendFreshdeskSuperDetails(data) {
    return request(contact(), {
      method: 'POST',
      ...withCredentials,
      headers,
      body: JSON.stringify(data),
    });
  }

  sendTMDWarning(userId, payload) {
    return request(tmdWarning(userId), {
      method: 'POST',
      ...withCredentials,
      headers,
      body: JSON.stringify(payload),
    });
  }

  submitSetupForm({
    id,
    givenName,
    middleName,
    familyName,
    dateOfBirth,
    address,
    mobilePhone,
    australianTaxResidency,
    usCitizen,
    isManualAddress,
    tfn,
    harmonyAddressObject,
    licence,
  }) {
    return request(mydetails(id), {
      method: 'POST',
      headers,
      ...withCredentials,
      body: JSON.stringify({
        givenName,
        middleName,
        familyName,
        dateOfBirth,
        address,
        mobilePhone,
        australianTaxResidency,
        usCitizen,
        isManualAddress,
        tfn,
        harmonyAddressObject,
        licence,
      }),
    });
  }

  submitMinorSetupForm({
    id,
    givenName,
    familyName,
    middleName,
    dateOfBirth,
    address,
    mobilePhone,
    australianTaxResidency,
    usCitizen,
    isManualAddress,
    tfn,
    harmonyAddressObject,
    licence,
    minorGivenName,
    minorFamilyName,
    minorMiddleName,
    minorDateOfBirth,
    minorRelation,
  }) {
    return request(myMinorDetails(id), {
      method: 'POST',
      headers,
      ...withCredentials,
      body: JSON.stringify({
        givenName,
        familyName,
        middleName,
        dateOfBirth,
        address,
        mobilePhone,
        australianTaxResidency,
        usCitizen,
        isManualAddress,
        tfn,
        harmonyAddressObject,
        licence,
        minorDetails: {
          givenName: minorGivenName,
          middleName: minorMiddleName,
          familyName: minorFamilyName,
          dateOfBirth: minorDateOfBirth,
          minorAccountRelation: minorRelation,
        },
      }),
    });
  }

  submitSmsfSetupForm({
    id,
    givenName,
    familyName,
    middleName,
    dateOfBirth,
    address,
    mobilePhone,
    australianTaxResidency,
    usCitizen,
    isManualAddress,
    harmonyAddressObject,
    licence,
    smsfDetails,
  }) {
    return request(mySmsfDetails(id), {
      method: 'POST',
      headers,
      ...withCredentials,
      body: JSON.stringify({
        givenName,
        familyName,
        middleName,
        dateOfBirth,
        address,
        mobilePhone,
        australianTaxResidency,
        usCitizen,
        isManualAddress,
        harmonyAddressObject,
        licence,
        tfn: smsfDetails.tfn,
        smsfDetails,
      }),
    });
  }

  verifyAbn(abn) {
    return request(abnLookup(abn), { ...withCredentials });
  }

  verifyAcn(acn) {
    return request(acnLookup(acn), { ...withCredentials });
  }

  signupWholesaleInvestor({
    id,
    questionnaire,
    certificateExpired,
    certificate,
    certificateLabel,
  }) {
    return request(wholesaleInvestorRegister(id), {
      method: 'POST',
      headers,
      ...withCredentials,
      body: JSON.stringify({
        questionnaire,
        certificateExpired,
        certificate,
        certificateLabel,
      }),
    });
  }

  applySmartRenter({
    accountId,
    propertyAddressStreet,
    propertyAddressSuburb,
    propertyAddressState,
    propertyAddressPostcode,
    propertyMaximumDeposit,
    propertyMaximumPurchase,
    propertyLink,
  }) {
    return request(smartRenterDetailsUrl(accountId), {
      method: 'POST',
      headers,
      ...withCredentials,
      body: JSON.stringify({
        propertyAddressStreet,
        propertyAddressSuburb,
        propertyAddressState,
        propertyAddressPostcode,
        propertyMaximumDeposit,
        propertyMaximumPurchase,
        propertyLink,
      }),
    });
  }
}

export default new RegistrationService();
