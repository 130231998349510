import moment from 'moment-timezone';

export const todaysDateInSydney = () => moment().tz('Australia/Sydney').format('YYYY-MM-DD');
export const delay = (time) => new Promise(resolve => setTimeout(resolve, time));
export const isAfterReChangeVoteMeetingStarts = () => moment().isAfter(moment.tz('2019-01-22 10:00', 'Australia/Sydney'));
export const isTimeDuringBrickXBusinessHours = () => {
  const currentTime = moment().tz('Australia/Sydney');
  const weekDay = currentTime.isoWeekday();
  const isWeekday = (weekDay >= 1 && weekDay <= 5) ? true : false;
  const openingTime = moment.tz(`${currentTime.format('YYYY-MM-DD')} 08:30`, 'Australia/Sydney');
  const closingTime = moment.tz(`${currentTime.format('YYYY-MM-DD')} 17:59:59:999`, 'YYYY-MM-DD H:m:s:S', 'Australia/Sydney');
  const isDuringBusinessHours = currentTime.isBetween(openingTime, closingTime, null, '[]');
  return isWeekday && isDuringBusinessHours;
};
export const daysToNow = (date) => moment().diff(date, 'days');
