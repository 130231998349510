import React from 'react';
import { connect } from 'react-redux';
import { Fragment } from 'react';
import styled from 'styled-components';
import { SubHeading } from 'src/design/styleguide/Headings';
import { WelcomePageWidgetPanel, SeeMoreLink } from 'src/components/dashboard/home/commonComponents';
import { breakpointSmall } from 'src/design/styleguide/common/breakpoints';
import {
  fetchSmartInvestDetails as fetchSmartInvestDetailsAction,
  smartInvestSelector
} from 'scripts/redux/actions/smartInvest';
import { getDirectDebitDetailsFromApiResponse } from 'src/utils/directDebit';
import { getSmartInvestPlan, planIsCancelled } from 'src/utils/account';
import { gridUnit } from 'src/design/styleguide/common/measurements';
import { mediumFontWeight, regularFontWeight } from 'src/design/styleguide/common/fonts';
import { settingsEnableUrl, settingsUrl } from 'src/utils/pageUrls';
import { userSelector } from 'scripts/redux/actions/user';
import SecondaryButton from 'src/design/components/button/SecondaryButton';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import Text from 'src/design/styleguide/text/Text';
import withState from 'src/decorators/withState';

const SectionHeading = styled(SubHeading).attrs({
  as: 'h3',
  fontSize: '2x-small',
})`
  ${breakpointSmall(`
    text-align: left;
  `)}
`;

const SettingsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: ${gridUnit * 5}px;
  grid-row-gap: ${gridUnit}px;
`;

const SettingName = styled(Text).attrs({
  fontSize: '4x-small',
})`
  text-align: right;

  ${breakpointSmall(`
    text-align: left;
  `)}
`;

const SettingValue = styled(Text).attrs({
  fontSize: '4x-small',
})`
  font-weight: ${mediumFontWeight};
  word-wrap: break-word;

  ${breakpointSmall(`
    font-weight: ${regularFontWeight};
  `)}
`;

const SmartInvestDisabledContent = () => (
  <Fragment>
    <Text>Smart Invest is currently deactivated.</Text>
    <Spacing top="large">
      <SecondaryButton
        size={SecondaryButton.sizes.SMALL}
        color={SecondaryButton.colors.PRIMARY}
        link={settingsEnableUrl()}
        fullWidth
        testRef="smart-invest-settings-panel-enable-smart-invest-button"
      >
        Enable Now
      </SecondaryButton>
    </Spacing>
  </Fragment>
);

const SmartInvestContent = connect(smartInvestSelector, {
  fetchSmartInvestDetails: fetchSmartInvestDetailsAction,
})(withState({
  componentDidMount: (props) => props.fetchSmartInvestDetails(),
  Component: ({ smartInvest }) => {
    const values = getDirectDebitDetailsFromApiResponse(smartInvest);
    if (!values) { return null; }

    const {
      monthlyAmount,
      nextDebitDate,
    } = values;

    return (
      <Fragment>
        <Spacing bottom="large">
          <SettingsContainer>
            <Text>Smart Invest is <strong>Enabled</strong></Text>
          </SettingsContainer>
        </Spacing>
        <SectionHeading>Direct Debit Details</SectionHeading>
        <Spacing top="large">
          <SettingsContainer>
            <SettingName>Monthly Amount</SettingName>
            <SettingValue testRef="smart-invest-settings-panel-direct-debit-monthly-amount">
              {monthlyAmount}
            </SettingValue>

            <SettingName>Next Debit</SettingName>
            <SettingValue testRef="smart-invest-settings-panel-direct-debit-next-debit-date">
              {nextDebitDate}
            </SettingValue>
          </SettingsContainer>
        </Spacing>
      </Fragment>
    );
  }
}));


const SmartInvestSettingPanel = connect(userSelector)(({ className, account }) => {
  const smartInvestCancelled = planIsCancelled(getSmartInvestPlan(account));
  return (<WelcomePageWidgetPanel
    className={className}
    title="Smart Invest Settings"
    action={<SeeMoreLink href={settingsUrl('0')}/>}
    testRef="smart-invest-settings-panel"
  >
    {
      smartInvestCancelled
      ? <SmartInvestDisabledContent />
      : <SmartInvestContent />
    }
  </WelcomePageWidgetPanel>);
});

export default SmartInvestSettingPanel;
