import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { userSelector } from 'scripts/redux/selectors/user';

import { saveTfnDetails } from 'scripts/redux/actions/user';


const mapStateToProps = (state) => ({
  ...userSelector(state),
});

@connect(mapStateToProps, {
  saveTfnDetails
})
export default class TfnForm extends Component {
  static propTypes = {
    saveTfnDetails: PropTypes.func.isRequired,
  };

  state = {
    tfn: {
      $error: false,
      value: ''
    }
  };
  render() {
    const { tfn } = this.state;
    return (
      <form role="form" method="post" onSubmit={::this._onFormTfnSubmit}>
        <input type="text" id="tfn" value={tfn.value} onChange={::this._onTFNInputChange} maxLength="9" placeholder="Enter your TFN"/>
        <div className="form-message__container">
          <div className="form-message__item">
            {tfn.$error && 'Enter a valid 8 or 9 digit tax file number.'}
          </div>
        </div>
        <input type="submit" className="button submit-tfn" value="Save"/>
      </form>
    );
  }

  _onTFNInputChange(e) {
    const tfnValue = e.target.value;
    this.setState({
      tfn: {
        value: tfnValue,
        $error: false
      }
    });
  }
  async _onFormTfnSubmit(e) {
    e.preventDefault();
    const { tfn } = this.state;
    if (!tfn.value.match('^[0-9]{8}(?:[0-9]{1})?$')) {
      this.setState({
        tfn: {
          $error: true,
          value: tfn.value
        }
      });
    } else {
      this.props.saveTfnDetails({
        tfn: tfn.value
      });
      this.setState({
        tfn: {
          $error: false,
          value: ''
        }
      });
    }
  }
}
