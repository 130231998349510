export const grey50 = '#FAFAFA';
export const grey100 = '#F5F5F5';
export const grey200 = '#eeeeee';
export const grey300 = '#E7E7EC';
export const grey400 = '#D7D7DC';
export const grey500 = '#B3B3B8';
export const grey550 = '#75757A';
export const grey600 = '#6B6B6F';
export const grey700 = '#57575C';
export const grey800 = '#41414b';
export const grey900 = '#202029';

export const pink50 = '#FFEEF1';
export const pink100 = '#FFCABC';
export const pink200 = '#FFA891';
export const pink300 = '#FF8282';
export const pink400 = '#FF6560';
export const pink500 = '#FF5545';
export const pink600 = '#F7342A';
export const pink700 = '#ED2C28';
export const pink800 = '#DD0200';
export const pink900 = '#BF1000';

export const yellow50 = '#FFFAEA';
export const yellow100 = '#FFF0C8';
export const yellow200 = '#FFE79F';
export const yellow300 = '#FFDE71';
export const yellow400 = '#FAC800';
export const yellow500 = '#FABE00';
export const yellow600 = '#FBB000';
export const yellow700 = '#FC9D00';
export const yellow800 = '#FD8B00';
export const yellow900 = '#FE6A00';

export const blue50 = '#E4F2FC';
export const blue100 = '#BDDEF9';
export const blue200 = '#95C9F5';
export const blue300 = '#6EB4F0';
export const blue400 = '#53A4ED';
export const blue500 = '#3B95EB';
export const blue600 = '#3687DE';
export const blue700 = '#3075CB';
export const blue800 = '#2A64B9';
export const blue900 = '#1F479A';

export const charcoal = grey800;
export const salmon = pink300;
export const yolk = yellow400;
export const hollandaise = yellow200;
export const coral = pink100;
export const butter = yellow100;
export const azure = blue300;
export const babyBlue = blue100;
export const shale = grey200;
export const concrete = grey400;
export const white = '#FFFFFF';
export const green = '#268900';
export const greenLight = '#e8f5e4';
export const red = pink800;
export const purple = '#885ADD';
export const purpleLight = '#F2E7FF';
export const lavender = '#BC89FF';

export const primary = salmon;
export const secondary = azure;
export const smsfAndTrustThemeColor = blue500;

export const lightBackgroundPrimary = pink50;
export const inputBorder = concrete;
export const inputBorderFocus = yolk;
export const inputBorderError = red;
export const inputBorderGood = green;

export const onSurfaceContrast800 = charcoal;
export const onSurfaceContrast500 = grey550;
export const onSurfaceContrast200 = grey500;
export const onSurfaceBlue = blue700;
export const onSurfaceRed = pink800;
export const onSurfaceGreen = green;
export const backgroundLightGrey = grey100;
export const backgroundFaintGrey = grey50;
export const surface = white;
export const onDarkSurfacePrimary = primary;
export const onDarkSurfaceContrast = white;

export const chartAccent01 = 'rgba(255, 101, 96, 0.9)';
export const chartAccent02 = 'rgba(253, 139, 0, 0.9)';
export const chartAccent03 = 'rgba(250, 190, 0, 0.9)';
export const chartAccent04 = 'rgba(94, 186, 61, 0.9)';
export const chartAccent05 = 'rgba(110, 180, 240, 0.9)';
export const chartAccent06 = 'rgba(188, 137, 255, 0.9)';

const Colors = {
  grey50,
  grey100,
  grey200,
  grey300,
  grey400,
  grey500,
  grey550,
  grey600,
  grey700,
  grey800,
  grey900,
  pink50,
  pink100,
  pink200,
  pink300,
  pink400,
  pink500,
  pink600,
  pink700,
  pink800,
  pink900,
  yellow50,
  yellow100,
  yellow200,
  yellow300,
  yellow400,
  yellow500,
  yellow600,
  yellow700,
  yellow800,
  yellow900,
  blue50,
  blue100,
  blue200,
  blue300,
  blue400,
  blue500,
  blue600,
  blue700,
  blue800,
  blue900,
  charcoal,
  salmon,
  yolk,
  hollandaise,
  coral,
  butter,
  azure,
  babyBlue,
  shale,
  concrete,
  white,
  green,
  greenLight,
  red,
  purple,
  purpleLight,
  lavender,
  primary,
  secondary,
  lightBackgroundPrimary,
  inputBorder,
  inputBorderFocus,
  inputBorderError,
  inputBorderGood,
  onSurfaceContrast800,
  onSurfaceContrast500,
  onSurfaceContrast200,
  onSurfaceBlue,
  onSurfaceRed,
  onSurfaceGreen,
  backgroundLightGrey,
  backgroundFaintGrey,
  surface,
  onDarkSurfacePrimary,
  onDarkSurfaceContrast,
  chartAccent01,
  chartAccent02,
  chartAccent03,
  chartAccent04,
  chartAccent05,
  chartAccent06,
  smsfAndTrustThemeColor,
};

export default Colors;
