import { cloneElement } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import styled from 'styled-components';
import {
  closeMenu as closeMenuAction,
  toggleSubMenu as toggleSubMenuAction,
} from 'src/store/headerMenuActions';
import { getThemeColor } from 'src/themes/themeUtils';
import { openSubMenusSelector } from 'src/store/headerMenuReducer';
import BodyText from 'src/design/components/bodyText/BodyText';
import Expandable from 'src/design/components/expandable/Expandable';
import Hyperlink from 'src/design/components/hyperlink/Hyperlink';
import Icon from 'src/design/components/icon/Icon';
import styles from 'src/design/components/header/MenuItemTypes.mscss';

export const OnClickCloseMenuProvider = connect(null, { closeMenu: closeMenuAction })(
  ({ closeMenu, children, onClick } = {}) => cloneElement(
    children, {
      onClick: () => {
        if (typeof onClick === 'function') onClick();
        closeMenu();
      },
    },
  )
);

export const LinkMenuItem = ({ link, children, onClick, ...props }) => (
  <OnClickCloseMenuProvider onClick={onClick}>
    <Hyperlink href={link} {...props}>
      {children}
    </Hyperlink>
  </OnClickCloseMenuProvider>
);

export const TopLevelMenuItemText = styled(BodyText)`
  font-size: 18px !important;
  font-weight: 500;
  transition: all .1s ease;
  &:hover, &:focus, &:active {
    color: ${getThemeColor('primary')};
    background-color: ${getThemeColor('lightBackgroundPrimary')};
    i{
      color: ${getThemeColor('primary')};
    }
  }
`;

const SubMenuItemText = styled(BodyText)`
  font-size: 17px !important;
  font-weight: 500;
`;

export const TopLevelMenuItem = ({ className, text, ...otherProps }) => (
  <LinkMenuItem {...otherProps}>
    <TopLevelMenuItemText>
      <div className={classNames(
        className,
        styles.menuItemContainer,
        styles.menuItemContent,
        styles.menuItemTopLevelIndent,
        styles.makeMenuItemSameHeightAsButton
      )}>
        {text}
      </div>
    </TopLevelMenuItemText>
  </LinkMenuItem>
);

export const InnerMenuItem = ({ text, ...otherProps }) => (
  <LinkMenuItem {...otherProps}>
    <SubMenuItemText>
      <div className={classNames(
        styles.menuItemContent,
        styles.menuItemSecondLevelIndent,
        styles.hoverTrigger,
        styles.hoverTargetColor,
        styles.hoverTargetBackgroundColor,
      )}>
        {text}
      </div>
    </SubMenuItemText>
  </LinkMenuItem>
);

export const DropDownMenuItem = connect(openSubMenusSelector, { toggleSubMenu: toggleSubMenuAction })(
  ({ id, text, children, testRef, toggleSubMenu, openSubMenus }) => {
    const toggleMenu = () => toggleSubMenu(id || text);
    const menuOpen = !!openSubMenus[id || text];

    return (
      <div className={classNames(styles.menuItemContainer)} data-test-reference={testRef}>
        <TopLevelMenuItemText>
          <div
            className={classNames(
              styles.menuItemDropDownRoot,
              styles.menuItemContent,
              styles.menuItemTopLevelIndent,
              styles.makeMenuItemSameHeightAsButton,
              styles.hoverTrigger
            )}
            onClick={toggleMenu}
            data-test-reference="dropdown-title"
          >
            <div className={classNames(styles.hoverTargetColor, { [styles.menuItemDropDownText_open]: menuOpen })} data-test-reference="drop-down-text">{text}</div> <Icon className={classNames(styles.dropdown_icon, { [styles.dropdown_icon_open]: menuOpen })} type={Icon.types.chevron_down} testRef="drop-down-icon"/>
          </div>
        </TopLevelMenuItemText>
        <Expandable
            className={styles.subMenu}
            expanded={menuOpen}
            testRef="drop-down-menu"
          >
          <div className={styles.subMenuInner}>
            {children}
          </div>
        </Expandable>
      </div>
    );
  }
);
