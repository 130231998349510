import { createAction, handleActions } from 'redux-actions';
import { errorAlert } from 'scripts/redux/actions/alerts';
import ContentsService from 'scripts/services/ContentsService';
import update from 'immutability-helper';


const INDEX = 'scripts/contents/INDEX';
const GET_MEDIA = 'scripts/contents/GET_MEDIA';
const GET_WEBINAR = 'scripts/contents/GET_WEBINAR';
const GET_KEY_DATES = 'scripts/contents/GET_KEY_DATES';
const GET_HELPFUL_LINKS = 'scripts/contents/GET_HELPFUL_LINKS';
const GET_PROPERTIES_RG46 = 'scripts/contents/GET_PROPERTIES_RG46';

export const indexAction = createAction(INDEX);
export const getMediaAction = createAction(GET_MEDIA);
export const getWebinarAction = createAction(GET_WEBINAR);
export const getKeyDatesAction = createAction(GET_KEY_DATES);
export const getHelpfulLinksAction = createAction(GET_HELPFUL_LINKS);
export const getPropertiesRg46Action = createAction(GET_PROPERTIES_RG46);

export function fetchIndexContents() {
  return async function (dispatch) {
    try {
      const payload = await ContentsService.getIndexContents();
      dispatch(indexAction(payload));
    } catch (e) {
      dispatch(errorAlert(e.message));
    }
  };
}

export function fetchMedia() {
  return async function (dispatch) {
    try {
      const payload = await ContentsService.getMedia();
      dispatch(getMediaAction(payload));
    } catch (e) {
      dispatch(errorAlert(e.message));
    }
  };
}

export function fetchWebinar() {
  return async function (dispatch) {
    try {
      const payload = await ContentsService.getWebinar();
      dispatch(getWebinarAction(payload));
    } catch (e) {
      dispatch(errorAlert(e.message));
    }
  };
}

export function fetchKeyDates() {
  return async function (dispatch) {
    try {
      const payload = await ContentsService.getKeyDates();
      dispatch(getKeyDatesAction(payload));
    } catch (e) {
      dispatch(errorAlert(e.message));
    }
  };
}

export function fetchHelpfulLinks() {
  return async function (dispatch) {
    try {
      const payload = await ContentsService.getHelpfulLinks();
      dispatch(getHelpfulLinksAction(payload));
    } catch (e) {
      dispatch(errorAlert(e.message));
    }
  };
}

export function fetchPropertiesRg46() {
  return async function (dispatch) {
    try {
      const payload = await ContentsService.getPropertiesRg46();
      dispatch(getPropertiesRg46Action(payload));
    } catch (e) {
      dispatch(errorAlert(e.message));
    }
  };
}


const reducer = handleActions({
  [INDEX]: (state, action) => update(state, {
    index: {
      $set: action.payload
    }
  }),
  [GET_MEDIA]: (state, action) => update(state, {
    media: {
      $set: action.payload
    }
  }),
  [GET_WEBINAR]: (state, action) => update(state, {
    webinar: {
      $set: action.payload
    }
  }),
  [GET_KEY_DATES]: (state, action) => update(state, {
    keyDates: {
      $set: action.payload
    }
  }),
  [GET_HELPFUL_LINKS]: (state, action) => update(state, {
    helpfulLinks: {
      $set: action.payload
    }
  }),
  [GET_PROPERTIES_RG46]: (state, action) => update(state, {
    propertiesRg46: {
      $set: action.payload
    }
  }),
}, {
  index: null,
  media: null,
  webinar: null,
  keyDates: null,
  helpfulLinks: null,
  propertiesRg46: null,
});

export default reducer;
