const THOUSAND = 1000;
const MILLION = 1000000;

module.exports = function (val) {
  let lbl = '';
  // Hello... super basic formatter with assumptions!
  if (val <= 100) {
    if (val === 0) {
      lbl = '0';
    } else {
      // Consider it a percentage
      lbl = `${+val.toFixed(2)}%`;
    }
  } else {
    let rounded;
    let suffix;
    // Consider it money
    if (val >= MILLION) {
      // Millions
      rounded = (val / MILLION);
      suffix = 'm';
    } else {
      // Thousands
      rounded = (val / THOUSAND);
      suffix = 'k';
    }
    lbl = `$${rounded}${suffix}`;
  }
  return lbl;
};
