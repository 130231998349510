import { Fragment } from 'react';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import TextInputWithError from 'src/design/components/textInput/TextInputWithError';
import HelpDisclaimer from 'src/design/components/helpDisclaimer/HelpDisclaimer';
import InputGroupSubHeading from 'src/components/landingPages/smsf/formInputGroups/InputGroupSubHeading';

import { validateEmailAddress, validatePassword } from 'src/utils/validation';

const SignupFormInputs = ({ showErrors, onChange, givenName, email, password }) => {
  const emailCheck = validateEmailAddress(email);
  const passwordCheck = validatePassword(password);

  return (
    <Fragment>
      <InputGroupSubHeading
        testRef="user-info-input-group-subheading"
      >User Information</InputGroupSubHeading>

      <Spacing bottom="x-small">
        <TextInputWithError
          placeholder="First Name"
          name="name"
          onChange={value => onChange({ givenName: value })}
          value={givenName}
          isStringValid={TextInputWithError.validation.name}
          error={showErrors && !givenName && 'Please enter a valid first name'}
          testRef="firstName-text-input"
          errorTestRef="firstName-error"
        />
      </Spacing>

      <Spacing bottom="x-small">
        <TextInputWithError
          placeholder="Email Address"
          name="email"
          onChange={value => onChange({ email: value })}
          value={email}
          error={showErrors && !emailCheck && 'Please enter a valid email address'}
          testRef="email-text-input"
          errorTestRef="email-error"
        />
      </Spacing>

      <Spacing bottom="x-small">
        <TextInputWithError
          type="password"
          name="password"
          placeholder="Password"
          onChange={value => onChange({ password: value })}
          value={password}
          error={showErrors && !passwordCheck && 'Please enter a valid password'}
          testRef="password-text-input"
          errorTestRef="password-error"
        />
      </Spacing>

      <HelpDisclaimer>
        Minimum 8 characters and at least 1 letter and 1 digit
      </HelpDisclaimer>
    </Fragment>
  );
};

export default SignupFormInputs;
