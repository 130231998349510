import { HarmonyConfig } from 'src/browser/harmonyConfigEnvMapper';
import AutoFillTextInput from 'src/design/components/autoFillTextInput/AutoFillTextInput';
import harmony from 'src/browser/harmony';
import { useEffect, useState } from 'react';
import { getHarmonySuggestions } from 'src/utils/harmony';
import _ from 'lodash';
import { settings } from './addressInputConfigs';

const REJECTED_POSTAL_TYPES = settings.REJECTED_POSTAL_TYPES;

const AddressInput = ({ onChange, onSuggestionSelect, value }) => {
  const [suggestions, setSuggestions] = useState([]);
  useEffect(() => {
    const harmonyConfig = HarmonyConfig();
    harmony.init(
      harmonyConfig.username,
      harmonyConfig.password
      // harmony[harmonyConfig.GNAF]
    );
  }, []);
  const updateSuggestions = (query) => {
    query.length >= 4 &&
      getHarmonySuggestions(query, (rsp) => {
        if (rsp.status === 'SUCCESS' && rsp.payload.length > 0) {
          let suggestionResult = rsp.payload;
          suggestionResult =
            suggestionResult &&
            suggestionResult.filter(
              (ele) => !_.includes(REJECTED_POSTAL_TYPES, ele.postalType)
            );
          setSuggestions(suggestionResult);
        }
      });
  };
  return (
    <AutoFillTextInput
      placeholder="e.g. 100 Smith Street"
      value={value}
      onChange={(newValue) => {
        if (newValue === '') {
          setSuggestions([]);
          onChange(newValue);
          return;
        }
        updateSuggestions(newValue);
        onChange(newValue);
      }}
      suggestions={suggestions.map((ele) => ele.fullAddress)}
      onSuggestionSelect={(idx) => {
        if (onSuggestionSelect) {
          onSuggestionSelect(suggestions[idx]);
          setSuggestions([]);
        }
      }}
      testRef="address"
      errorTestRef="address-error"
      listTestRef="address-suggestions-list"
      listItemTestRef="address-suggestions-item"
    />
  );
};

export default AddressInput;
