
import { Link } from 'react-router';

const PrevNextBar = ({ prevTitle, prevLink, nextTitle, nextLink }) => {
  return (
    <div className="row prev-next-bar">
      <div className="col-xs-6 text-left">
        {prevTitle &&
          <Link to={prevLink} className="button prev-button">
            <span className="fa fa-angle-left"></span>{prevTitle}
          </Link>}
      </div>
      <div className="col-xs-6 text-right">
        {nextTitle &&
          <Link to={nextLink} className="button next-button">
            {nextTitle}<span className="fa fa-angle-right"></span>
          </Link>}
      </div>
    </div>
  );
};
export default PrevNextBar;
