import { smsfSetup as smsfSetupAction } from 'scripts/redux/actions/registration';
import { smsfSubmittingSelector } from 'src/components/landingPages/smsf/smsfReducer';
import {
  startSubmitting as startSubmittingAction,
  stopSubmitting as stopSubmittingAction,
} from 'src/components/landingPages/smsf/smsfActions';
import { trackButtonClick } from 'src/components/landingPages/smsf/tracking';
import FormSubmitButton from 'src/components/landingPages/smsf/forms/FormSubmitButton';
import SuperFormInputs from 'src/components/landingPages/smsf/formInputGroups/SuperFormInputs';
import withSubmittableForm from 'src/decorators/withSubmittableForm';

const LoggedInForm = ({
  values: { trustName, abn, trusteeName, showErrors, smsfSubmitting },
  onChange,
  onSubmit,
}) => {
  return (
    <form>
      <SuperFormInputs
        hideTitle
        {...{ trustName, abn, trusteeName, onChange, showErrors }}
      />
      <FormSubmitButton {...{ onChange, onSubmit, smsfSubmitting }}>
        Set Up
      </FormSubmitButton>
    </form>
  );
};

export default withSubmittableForm({
  Form: LoggedInForm,
  mapDispatchToProps: {
    smsfSetup: smsfSetupAction,
    startSubmitting: startSubmittingAction,
    stopSubmitting: stopSubmittingAction,
  },
  mapStateToProps: (state) => ({
    ...smsfSubmittingSelector(state),
  }),
  mapPropsToInitialFormValues: () => ({
    trustName: '',
    abn: '',
    trusteeName: '',
    showErrors: false,
  }),
  onSubmit: ({ formValues, props }) => {
    const { trustName, abn, trusteeName } = formValues;
    const { smsfSubmitting, smsfSetup, startSubmitting, stopSubmitting } =
      props;
    const isFormSubmittable =
      trustName && abn && trusteeName && !smsfSubmitting;
    if (isFormSubmittable) {
      startSubmitting();
      trackButtonClick({
        button: 'Submit Button',
        from: 'Logged In Signup Form',
      });
      smsfSetup({ trustName, abn, trusteeName })
        .catch((e) => {
          console.error('something went wrong!', e);
        })
        .finally(() => stopSubmitting());
    } else {
      console.error('Form is not complete!');
    }
  },
});
