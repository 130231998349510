import { Heading } from 'src/design/components/heading/Headings';
import SelectionButton from 'src/design/components/button/SelectionButton';
import Spacing from 'src/design/styleguide/spacing/Spacing';


import styles from 'src/components/landingPages/smartInvest/moreOnSmartInvest/MoreOnSmartInvest.mscss';

const ExternalLinkSelectButton = ({ text, ...props }) =>
  <SelectionButton
    arrow
    className={styles.externalLinkButton}
    {...props}
  >{text}</SelectionButton>;

const MoreOnSmartInvest = ({ }) => {
  return (
    <section className={styles.moreOnSiContainer}>
      <div className={styles.innerContainer}>
        <img
          className={styles.icon}
          src="/static/img/icon-document-and-magnifying-glass.svg"
          alt="icon containting a document being looked at with a magnifying glass"
        />
        <Spacing top="normal" bottom="3x-large">
          <Heading><h2>More on Smart Invest</h2></Heading>
        </Spacing>
        <div className={styles.linkListing}>
          <ExternalLinkSelectButton
            text="Getting Started"
            link="http://help.brickx.com/support/solutions/articles/17000077270-how-do-i-create-an-account-to-invest-in-bricks-"
          />
          <ExternalLinkSelectButton
            text="Minimum Investment"
            link="http://help.brickx.com/support/solutions/articles/17000077273-is-there-a-minimum-investment-"
          />
          <ExternalLinkSelectButton
            text="Making Returns"
            link="http://help.brickx.com/support/solutions/17000081352"
          />
          <ExternalLinkSelectButton
            text="Fees and Costs"
            link="http://help.brickx.com/support/solutions/17000081353"
          />
          <ExternalLinkSelectButton
            text="Properties"
            link="http://help.brickx.com/support/solutions/articles/17000074801-how-are-the-properties-chosen-"
          />
          <ExternalLinkSelectButton
            text="Risks"
            link="http://help.brickx.com/support/solutions/articles/17000077302-risks-you-should-be-aware-of"
          />
        </div>
      </div>
    </section>
  );
};

export default MoreOnSmartInvest;
