import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import cookie from 'react-cookie';

import { user as userPropType, registration as registrationPropType } from 'scripts/constants/PropTypes';
import { userSelector } from 'scripts/redux/selectors/user';
import { registrationSelector } from 'scripts/redux/selectors/registration';
import { resendEmail, verifyToken } from 'scripts/redux/actions/registration';

import VerifyEmail from 'src/components/oldOnboarding/verifyEmail/VerifyEmail';

import RegistrationStep from 'scripts/constants/RegistrationStep';
import Configuration from 'scripts/constants/Configuration';


const mapStateToProps = (state) => ({
  ...userSelector(state),
  ...registrationSelector(state)
});

@connect(mapStateToProps, {
  resendEmail,
  verifyToken,
})
export default class Verification extends Component {
  static propTypes = {
    params: PropTypes.shape({
      token: PropTypes.string
    }),
    user: userPropType.isRequired,
    registration: registrationPropType,
    resendEmail: PropTypes.func.isRequired,
    verifyToken: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { verifyToken, params: { token } } = this.props;
    if (!_.isNil(token)) {
      verifyToken(token);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { user } = this.props;
    const nextRegistrationStep = nextProps.registration && nextProps.registration.type;
    const success = nextProps.registration && nextProps.registration.data.success;

    if (success || nextRegistrationStep !== RegistrationStep.VERIFY) {
      window.location.href = Configuration.PAGE_URLS.ONBOARDING.ACCEPT_PDS;
    } else if (!!user) {
      window.location.href = Configuration.PAGE_URLS.ONBOARDING.LOGIN;
    }
  }


  render() {
    const { user, params: { token }, registration } = this.props;
    const isVerifyTokenRequest = !_.isNil(token) && !(!_.isNil(registration) && registration.type === RegistrationStep.VERIFY && !registration.data.success);
    if (isVerifyTokenRequest) {
      return null;
    }

    var userId;
    if (user) {
      userId = user.id;
    } else {
      userId = cookie.load(Configuration.COOKIES.SIGN_UP_VERIFY_TOKEN);
    }

    return <VerifyEmail userId={userId}/>;
  }
}
