import { connect } from 'react-redux';
import DocumentMeta from 'react-document-meta';
import Markdown from 'react-remarkable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import { PDS_META } from 'scripts/metadata/statics';
import {
  dollar,
  dateWithSlash,
  months,
  dateHalfExtended,
} from 'scripts/utilities/formatters';
import { fetchProperties } from 'scripts/redux/actions/properties';
import { fetchPropertiesRg46 } from 'scripts/redux/actions/contents';
import { memberSelector } from 'scripts/redux/selectors/member';
import {
  propertiesRg46 as propertiesRg46PropType,
  property as propertyPropType,
  user as userPropType,
} from 'scripts/constants/PropTypes';
import { propertiesRg46Selector } from 'scripts/redux/selectors/contents';
import { propertiesSelector } from 'scripts/redux/selectors/properties';
import { userSelector } from 'scripts/redux/selectors/user';
import Hyperlink from 'scripts/components/shared/Hyperlink';
import Icon from 'scripts/components/shared/Icon';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import { renderCode } from 'src/settings/properties';
import Formatters from 'scripts/utilities/formattersV2';

const mapStateToProps = (state) => ({
  ...userSelector(state),
  ...memberSelector(state),
  ...propertiesSelector(state),
  ...propertiesRg46Selector(state),
});

@connect(mapStateToProps, {
  fetchProperties,
  fetchPropertiesRg46,
})
export default class LatestPds extends Component {
  static propTypes = {
    user: userPropType.isRequired,
    properties: PropTypes.arrayOf(propertyPropType).isRequired,
    contents: PropTypes.shape({
      propertiesRg46: PropTypes.arrayOf(propertiesRg46PropType),
    }),
    fetchProperties: PropTypes.func.isRequired,
    fetchPropertiesRg46: PropTypes.func.isRequired,
    memberStatus: PropTypes.shape({
      isMember: PropTypes.bool,
    }),
  };

  componentDidMount() {
    const { fetchProperties, fetchPropertiesRg46 } = this.props;
    fetchProperties();
    fetchPropertiesRg46();
    window.scrollTo(0, 0);
  }

  render() {
    const {
      properties,
      contents: { propertiesRg46 },
    } = this.props;
    const isUserMember =
      this.props.memberStatus && this.props.memberStatus.isMember;
    const rg46Ordered = _.orderBy(
      propertiesRg46,
      ['dateLastChangeMade'],
      ['desc']
    );
    const propertiesMergedWithRg46 = _.map(properties, function (prop) {
      const rg46 = _.filter(propertiesRg46, {
        propertyCode: prop.propertyCode,
      })[0];
      return _.mergeWith(prop, { propertyRg46: rg46 || {} });
    });
    const orderedProperties = _.orderBy(
      propertiesMergedWithRg46,
      ['platformSettlementDate'],
      ['asc']
    );

    const customRow = ({ index, property }) => {
      const leaseTermCopy =
        property.tenantStatus.leaseTerm === 'month_to_month'
          ? 'Month-month'
          : property.tenantStatus.leaseLength::months();
      if (property.propertyCode === 'ARN01') {
        return (
          <tr key={index} data-test-reference="rg46-row">
            <td>
              <Hyperlink
                linkTo={`/properties/${property.propertyCode}/summary`}
              >
                {renderCode(property.propertyCode)}
              </Hyperlink>
            </td>
            <td>
              45, 55, 59, 202, 243, 247 / {property.address}, {property.suburb}{' '}
              {property.state} {property.postcode} <br />
            </td>
            <td className="text-right">
              {property.financials.purchasePrice::dollar()}
            </td>
            <td>
              <Markdown source={property.propertyRg46.borrowing} />
            </td>
            <td>{property.settlementDate::dateWithSlash()}</td>
            <td>
              {property.financials.lastPropertyValuation::dollar()} <br />
              {property.financials.isIndependentValued
                ? `Report Dated ${property.financials.latestValuationDate::dateWithSlash()}`
                : `(Based on purchase price, ${property.exchangeDate::dateWithSlash()})`}
            </td>
            <td>N/A</td>
            <td className="text-right">
              {property.tenantStatus.leaseAgreementSigned
                ? property.financials.weeklyRentalIncome::dollar()
                : 'N/A'}
            </td>
            <td>
              {property.tenantStatus.leaseAgreementSigned ? '100%' : 'N/A'}
            </td>
            <td>
              Apartment 45 - 12 months (commencing from 26/11/2021)
              <br />
              <br />
              Apartment 55 - 13 months (commencing from 17/12/2021)
              <br />
              <br />
              Apartment 59 - 13 months (commencing from 17/12/2021)
              <br />
              <br />
              Apartment 202 - 13 months (commencing from 23/12/2021)
              <br />
              <br />
              Apartment 243 - 13 months (commencing from 23/12/2021)
              <br />
              <br />
              Apartment 247 - 18 months (commencing from 4/12/2021)
              <br />
              <br />
            </td>
          </tr>
        );
      }
      return (
        <tr key={index} data-test-reference="rg46-row">
          <td>
            <Hyperlink linkTo={`/properties/${property.propertyCode}/summary`}>
              {renderCode(property.propertyCode)}
            </Hyperlink>
          </td>
          <td>
            {property.address}, {property.suburb}, {property.state}
          </td>
          <td className="text-right">
            {property.financials.purchasePrice::dollar()}
          </td>
          <td>
            <Markdown source={property.propertyRg46.borrowing} />
          </td>
          <td>{property.settlementDate::dateWithSlash()}</td>
          <td>
            {property.financials.lastPropertyValuation::dollar()} <br />
            {property.financials.isIndependentValued
              ? `Report Dated ${property.financials.latestValuationDate::dateWithSlash()}`
              : `(Based on purchase price, ${property.exchangeDate::dateWithSlash()})`}
          </td>
          <td>N/A</td>
          <td className="text-right">
            {property.tenantStatus.leaseAgreementSigned
              ? property.financials.weeklyRentalIncome::dollar()
              : 'N/A'}
          </td>
          <td>{property.tenantStatus.leaseAgreementSigned ? '100%' : 'N/A'}</td>
          <td>
            {property.tenantStatus.leaseAgreementSigned &&
              `${leaseTermCopy} (commencing from ${property.tenantStatus.leaseStartDate::dateWithSlash()})`}
            {!property.tenantStatus.leaseAgreementSigned && 'N/A'}
          </td>
        </tr>
      );
    };
    return (
      <div className="pds-page-latest">
        <DocumentMeta {...PDS_META} />
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>
                RG46 Schedule
                {rg46Ordered.length > 0 && (
                  <span>
                    (updated on{' '}
                    <span data-test-reference="rg46-date">
                      {rg46Ordered[0].dateLastChangeMade::dateHalfExtended()}
                    </span>
                    )
                  </span>
                )}
              </h1>
              <p>
                BrickX Financial Services Limited (ABN 67 616 118 088) (AFSL
                494878) (Responsible Entity) is the responsible entity of the
                BrickX Platform (ARSN 613 156 433) and The Brick Exchange Pty
                Ltd (ABN 27 600 762 749) (BrickX) is the manager. This RG46
                Schedule has been prepared by BrickX as manager and will be
                updated for non-materially adverse information from time to time
                and will be available for Members at{' '}
                <a href="https://www.brickx.com/pds/latest-pds-updates">
                  www.brickx.com/pds/latest-pds-updates
                </a>
                . Members can request a paper copy of this RG46 Schedule free of
                charge at any time. This RG46 Schedule should be read in
                conjunction with the Product Disclosure Statement, which can be
                viewed{' '}
                <u>
                  <a href="/pds">here</a>
                </u>
                .
              </p>

              <hr />
              <h3 className="text-left">
                <b>Property schedule</b>
              </h3>

              <p>
                Below are the disclosures required by ASIC Regulatory Guide 46:
                Unlisted property schemes – improving disclosure for retail
                investors (ASIC RG46) relating to the properties.
              </p>

              <table
                className="table table-bordered"
                style={{
                  fontSize: '13px',
                  '& ul': {
                    'padding-inline-start': '10px!important',
                  },
                }}
              >
                <thead>
                  <tr>
                    <th>Property Code</th>
                    <th>Property Address</th>
                    <th>Property Purchase Price</th>
                    <th>Borrowing</th>
                    <th>Settlement Date</th>
                    <th>Most recent Independent Valuation</th>
                    <th>Most recent non-Independent Valuation</th>
                    <th>Rent per week</th>
                    <th>Occupancy rate</th>
                    <th>Lease Expiry</th>
                  </tr>
                </thead>
                <tbody>
                  {orderedProperties.length > 0 &&
                    orderedProperties[0].propertyCode &&
                    _.map(orderedProperties, function (property, index) {
                      return customRow({ property, index });
                    })}
                </tbody>
              </table>

              <br />
              <h3 className="text-left">
                <b>Gearing Schedule</b>
              </h3>

              <p>
                The BrickX Platform currently has loan agreements in place,
                pursuant to which Macquarie Bank Limited (ABN 46 008 583 542)
                (Lender) has made a loan to BrickX Financial Services Limited
                (ABN 67 616 118 088) as trustee of the BrickX Trusts.
              </p>

              <p>
                Additionally, the BrickX Platform has loan agreements in place,
                pursuant to which BridgeLane Capital Pty Limited (ACN 138 379
                638) (Lender) has made a loan to BrickX Trusco Pty Limited (ACN
                602 627 770) (Debtor) to fund the acquisition of certain
                properties available on the BrickX Platform.
              </p>

              <p>
                Below are the disclosures required by ASIC RG46 relating to
                these loans.
              </p>

              <p>
                For the purposes of this Gearing Schedule, “BrickX Trust” means
                a unit trust holding a property which is available on the BrickX
                Platform and which has a borrowing arrangement in place
                disclosed in the Property Schedule at the start of this RG46
                Schedule.
              </p>

              <br />
              <h4>Disclosure principle 1: Gearing ratio</h4>
              <p>
                ASIC RG46.62 The Responsible Entity should disclose each BrickX
                Trust’s gearing ratio as calculated in accordance with the
                prescribed formula.
              </p>
              <p>ASIC RG46 requires the gearing ratio to be calculated as:</p>

              <center>
                <div>
                  <strong>
                    <div className="text-center formula">
                      <div className="row">
                        <div className="col-xs-4 col-md-offset-4 text-center">
                          <div className="numerator">
                            Total Interest - Bearing Liabilities
                          </div>
                          <hr />
                          Total Assets
                        </div>
                      </div>
                    </div>
                  </strong>
                </div>
              </center>

              <p>
                The gearing ratio for each property held in a BrickX Trust on
                the BrickX Platform as at the date of this RG46 Schedule is set
                out in the above Property Schedule.
              </p>
              <p>
                ASIC RG46.63 The liabilities and assets used to calculate the
                gearing ratio should be based on the BrickX Trust’s latest
                financial statements.
              </p>

              <p>The ratio above is based on the following:</p>

              <ul>
                {_.map(orderedProperties, (property, index) => (
                  <li
                    key={index}
                    data-test-reference="rg46-gearing-calculation"
                  >
                    {renderCode(property.propertyCode)} -{' '}
                    {property.propertyRg46.gearingRatioCalculationBase}
                  </li>
                ))}
              </ul>

              <p>
                ASIC RG46.64 If the scheme has material off-balance sheet
                financing, the Responsible Entity should disclose a ‘look
                through’ gearing ratio to determine the BrickX Platform’s level
                of risk.
              </p>

              <p>
                The BrickX Platform does not have material off- balance sheet
                financing.
              </p>

              <p>
                ASIC RG46.65 The Responsible Entity should explain what the
                gearing ratio means in practical terms, and how investors can
                use the ratio to determine a BrickX Trust’s level of risk.
              </p>

              <p>
                The gearing ratio indicates the extent to which the properties
                held in BrickX Trusts’ are funded by borrowings. The gearing
                ratio gives an indication of the potential risks faced by a
                BrickX Trust as a result of its borrowings due to, for example,
                an increase in interest rates or a decrease in values.
              </p>

              <p>
                A higher gearing ratio means a higher reliance on interest
                bearing liabilities to fund assets and exposes the BrickX Trust
                to increased funding costs if interest rates rise, or a
                potential breach of banking covenants if asset values fall.
              </p>

              <br />
              <h4>Disclosure principle 2: Interest cover ratio</h4>

              <p>
                ASIC RG46.71 The Responsible Entity should disclose each BrickX
                Trust’s interest cover ratio calculated in accordance with
                prescribed formula.
              </p>
              <p>
                ASIC RG46 requires the interest cover ratio to be calculated as:
              </p>

              <center>
                <div>
                  <strong>
                    <div className="text-center formula">
                      <div className="row">
                        <div className="col-md-4 col-md-offset-4 text-center">
                          <div className="numerator">
                            EBITDA - Unrealised Gains + Unrealised Losses
                          </div>
                          <hr />
                          Interest Expense
                        </div>
                      </div>
                    </div>
                  </strong>
                </div>
              </center>

              <p>
                The interest cover ratio (calculated in accordance with ASIC
                disclosure principles) for each property held in a BrickX Trust
                on the BrickX Platform as at the date of this RG46 Schedule is
                set out in the above Property Schedule
              </p>

              <p>
                ASIC RG46.72 The EBITDA and interest expense figures used to
                calculate the interest cover ratio should be consistent with
                those disclosed in the BrickX Trust’s latest financial
                statements.
              </p>

              <p>Interest cover ratio calculation is based on:</p>

              <ul>
                {_.map(orderedProperties, (property, index) => (
                  <li
                    key={index}
                    data-test-reference="rg46-gearing-calculation-2"
                  >
                    {renderCode(property.propertyCode)} -{' '}
                    {property.propertyRg46.gearingRatioCalculationBase}
                  </li>
                ))}
              </ul>

              <p>
                ASIC RG46.74 The Responsible Entity should explain how investors
                can use the interest cover ratio to assess the BrickX Trust’s
                ability to meet its interest payments
              </p>

              <p>
                The interest cover ratio measures the ability of the BrickX
                Trust to meet its interest payments on borrowings from its
                earnings. The level of interest cover gives an indication of the
                BrickX Trust’s financial health in paying both interest to the
                debt finance provider and distributions to Brick Holders. A
                higher interest cover ratio indicates greater available funds
                with which to pay interest costs and distributions. The lower
                the interest cover ratio, the higher the risk that the BrickX
                Trust will not be able to meet its interest payments. It is a
                key measure of the risks associated with the BrickX Trust’s
                borrowings and the sustainability of borrowings.
              </p>

              <br />
              <h4>Disclosure principle 3: Scheme borrowing</h4>

              <p>
                ASIC RG46.78 If a scheme has borrowed funds (on or off balance
                sheet), the Responsible Entity should disclose the following
                information:
              </p>

              <ul>
                <li>
                  Debt facility maturities (including the aggregate amount owing
                  and maturity profile) – set out in the above Property Schedule
                </li>
                <li>
                  The amount (expressed as a percentage) by which the operating
                  cash flow or the value of the asset(s) used as security for
                  the facility must fall before there is a breach of covenants
                  in any credit facility
                  <ul>
                    <ul>
                      {_.map(orderedProperties, (property, index) => (
                        <li
                          key={index}
                          data-test-reference="rg46-breach-covenant"
                        >
                          {renderCode(property.propertyCode)} -{' '}
                          {property.propertyRg46.breachOfCovenant}
                        </li>
                      ))}
                    </ul>
                  </ul>
                </li>
                <li>
                  For each credit facility:
                  <ul>
                    <li>
                      the aggregate undrawn amount
                      <ul>
                        {_.map(orderedProperties, (property, index) => (
                          <li key={index}>
                            {renderCode(property.propertyCode)} -{' '}
                            {property.propertyRg46.aggregateUndrawnAmount}
                          </li>
                        ))}
                      </ul>
                    </li>
                    <li>
                      the assets to which the facility relates – set out in the
                      above Property Schedule
                    </li>
                    <li>
                      the loan to value and interest cover covenants under the
                      terms of the facility
                      <ul>
                        {_.map(orderedProperties, (property, index) => (
                          <li
                            key={index}
                            data-test-reference="rg46-loan-interest"
                          >
                            {renderCode(property.propertyCode)} -{' '}
                            {property.propertyRg46.loanToValueAndInterest}
                          </li>
                        ))}
                      </ul>
                    </li>
                    <li>
                      the interest rate of the facility – set out in the above
                      Property Schedule
                    </li>
                    <li>whether the facility is hedged – not applicable</li>
                  </ul>
                </li>
                <li>
                  Details of any terms within the facility that may be invoked
                  as a result of scheme members exercising their rights under
                  the scheme constitution – not applicable
                </li>
                <li>
                  The fact that amounts owing to lenders and other creditors of
                  the scheme rank before an investor’s interests in the scheme
                </li>
              </ul>

              <p>
                The key terms of any loan relating to a property held in a
                BrickX Trust on the BrickX Platform as at the date of this RG46
                Schedule is set out in the above Property Schedule.
              </p>
              <p>
                Borrowings in relation to a property will be secured by a
                registered mortgage over that property (held in the relevant
                BrickX Trust). This security interest will rank ahead of any
                other unsecured loans and Brick Holders’ interests in the assets
                of the trust.
              </p>
              <p>
                On and from the date of a loan, interest will accrue on the
                principal amount outstanding of the loan on a daily basis at the
                rate set out in the above Property Schedule, or such other rate
                as specified by the Lender from time to time.
              </p>
              <p>
                ASIC RG46.79 If any of the BrickX Platform’s borrowings or
                credit facilities are to mature within the next 12 months, the
                Responsible Entity should make appropriate disclosure about the
                prospects of refinancing; or possible alternative actions.
              </p>
              <p>
                ASIC RG46.80 The Responsible Entity should explain any risks
                associated with the BrickX Platform’s borrowing maturity
                profile, including whether borrowings have been hedged and if so
                to what extent.
              </p>
              <p>Not applicable.</p>
              <p>
                ASIC RG46.81 the Responsible Entity disclose any information
                about fund borrowing and breaches of loan covenants that is
                reasonably required by investors.
              </p>
              <p>Not applicable.</p>
              <br />

              <h3>
                <strong>Short Term Funding Arrangements</strong>
              </h3>
              <p>
                BrickX and/or its related entities may from time to time enter
                into loan arrangements on arms length terms to provide funding
                to the relevant BrickX Trust for operational purposes.
              </p>
              <p>
                BrickX has agreed to provide a loan to BRW01, to manage the
                operational costs of the Trust due to urgent repairs required to
                the property in July 2018. Loan details:
              </p>
              <ul>
                <li>
                  Loan value: $18,887 at 31 December 2020 (originally $29,000)
                </li>
                <li>Loan commencement: October 2018</li>
                <li>Interest rate: 5.00% pa</li>
                <li>Loan Type: Unsecured, No fixed maturity date</li>
                <li>ICR for this Trust is 2.46 inclusive of this loan</li>
              </ul>
              <Spacing bottom="normal">
                <p>
                  Note that the funding amount and any other related charges is
                  to be repaid in full from the relevant BrickX Trust before
                  distributions resume being paid to investors (which are now
                  projected to between Oct-Dec 2021 assuming no additional
                  unplanned maintenance).
                </p>
              </Spacing>

              <p>
                Last Updated:
                {rg46Ordered.length > 0 && (
                  <span data-test-reference="rg46-date">
                    {' '}
                    {Formatters.time.dateWithSlash(
                      rg46Ordered[0].dateLastChangeMade
                    )}
                  </span>
                )}
              </p>
            </div>
          </div>
          {isUserMember && (
            <div
              className="row"
              data-test-reference="member-restricted-documents"
            >
              <h1>Other Disclosure Documents</h1>
              <div className="col-md-12 other-disclosure-documents">
                <div className="documents">
                  <div>
                    <a
                      href="https://assets.brickx.com/downloads/BRICKX+Interim+Financial+Report.pdf"
                      className="document"
                    >
                      <Icon
                        type="fa"
                        className="fa-file-text"
                        name="cloud-download"
                      />
                      BrickX Platform: Interim Financial Report for the
                      half-year ended 31 December 2016
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://assets.brickx.com/downloads/BrickX_Platform_Audited_Financials_FY17_LODGED.pdf"
                      className="document"
                    >
                      <Icon
                        type="fa"
                        className="fa-file-text"
                        name="cloud-download"
                      />
                      BrickX Platform: Annual Financial Report for the year
                      ended 30 Jun 2017
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://assets.brickx.com/downloads/BrickX_Platform_HY_Dec_2017_Financial_Statements.pdf"
                      className="document"
                    >
                      <Icon
                        type="fa"
                        className="fa-file-text"
                        name="cloud-download"
                      />
                      BrickX Platform: Interim Financial Report for the
                      half-year ended 31 December 2017
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://assets.brickx.com/downloads/BrickX_Scheme_-_Financial_Statements_-_30_June_2018.pdf"
                      className="document"
                    >
                      <Icon
                        type="fa"
                        className="fa-file-text"
                        name="cloud-download"
                      />
                      BrickX Platform: Annual Financial Report for the year
                      ended 30 June 2018
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://assets.brickx.com/downloads/brickx-platform-financials-interim-dec-18.pdf"
                      className="document"
                    >
                      <Icon
                        type="fa"
                        className="fa-file-text"
                        name="cloud-download"
                      />
                      BrickX Platform: Interim Financial Report for the
                      half-year ended 31 December 2018
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-md-12">
              <div className="text-center">
                <a
                  href="/pds"
                  className="button orange-button right-arrow-button"
                >
                  Back to PDS
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
