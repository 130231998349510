import { Fragment } from 'react';
import { HeadingSmall } from 'src/design/components/heading/Headings';
import Paragraph from 'src/design/components/bodyText/Paragraph';
import FinePrint from 'src/design/components/bodyText/FinePrint';
import Styledlink from 'src/design/components/hyperlink/Styledlink';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import SecondaryButton from 'src/design/components/button/SecondaryButton';
import Divider from 'src/design/components/divider/Divider';
import ScrollDownHint from 'src/design/components/scrollDownHint/ScrollDownHint';
import PoppingCard from 'src/design/components/poppingCard/PoppingCard';
import {
  pdsUrl,
  returnsCalculatorDisclosuresUrl,
} from 'src/utils/pageUrls';
import withState from 'src/decorators/withState';
import styles from 'scripts/components/calculators/CalculateAveragesCard.mscss';

const CalculateAveragesCard = withState({
  Component: ({ setState, state, onCalculateNowButtonClick }) => (
    <PoppingCard withBorderTopFeature>
      <div className={styles.propertySmartInvestSignupDriver__cardContent}>
        <Spacing bottom="large">
          <HeadingSmall>
            <h2 className={styles.propertySmartInvestSignupDriver__heading}>Not sure where to start?</h2>
          </HeadingSmall>
        </Spacing>

        <div className={styles.propertySmartInvestSignupDriver__content}>
          <div className={styles.propertySmartInvestSignupDriver__text}>
            <Spacing bottom="normal">
              <Paragraph>
                Load the averages across BrickX property investments and change from there.
              </Paragraph>
            </Spacing>

            <FinePrint>
              Consider whether investing in Bricks is right for you by reading the <Styledlink target="_blank" href={pdsUrl()}>PDS</Styledlink>.
            </FinePrint>
          </div>

          <div className={styles.propertySmartInvestSignupDriver__ctas}>
            <PrimaryButton
              className={styles.propertySmartInvestSignupDriver__signupButton}
              color={PrimaryButton.colors.SECONDARY}
              onClick={() => {
                !state.showScrollDownHint && setState({ showScrollDownHint: true });
                onCalculateNowButtonClick();
              }}
              testRef="calculate-now-button"
            >
              Calculate Now
            </PrimaryButton>

            <SecondaryButton
              color={SecondaryButton.colors.PRIMARY}
              link={returnsCalculatorDisclosuresUrl()}
              target="_blank"
            >
              See Calculator T&Cs
            </SecondaryButton>
          </div>
        </div>
        {state.showScrollDownHint && (
          <Fragment>
            <Divider top="normal" bottom="normal"/>
            <ScrollDownHint text="Scroll down"/>
          </Fragment>
        )}
      </div>
    </PoppingCard>
  ),
  mapPropsToInitialState: () => ({ showScrollDownHint: false })
});

export default CalculateAveragesCard;
