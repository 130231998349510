import styled from 'styled-components';
import { headerHeight } from 'src/design/styleguide/common/measurements';
import RentToBuyDefaultMeta from 'src/components/meta/RentToBuyDefaultMeta';
import RentToBuyHeader from 'src/rentToBuy/RentToBuyHeader';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow: hidden;
  position: relative;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: calc(100vh - ${headerHeight});
  width: 100%;
`;

const RentToBuyApp = ({ children }) => (
  <Root>
    <RentToBuyDefaultMeta/>
    <RentToBuyHeader/>
    <ContentContainer>
      {children}
    </ContentContainer>
  </Root>
);

export default RentToBuyApp;
