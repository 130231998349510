import { createAction, handleActions } from 'redux-actions';
import update from 'immutability-helper';
import { addPersonData as addPersonDataToRollbar } from 'scripts/analytics/rollbar';
import { clearReferral } from 'scripts/utilities/referralHelper';
import {
  countryCodePhoneNumber,
  now,
  suburb,
  year,
} from 'scripts/utilities/formatters';
import { establishHarmonyAddressLookupTransaction } from 'src/utils/harmony';
import {
  fetchUserDetails,
  setupUserSession,
  userSelector,
} from 'scripts/redux/actions/user';
import { identityPayloadForVedaRetrySelector } from 'src/store/identityVerificationFormReducer';
import { isInvestor } from 'scripts/utilities/userAccountHelper';
import { modalTrackingPropsSelector } from 'src/store/loginOrSignupModalReducer';
import {
  accountUrl,
  vedaFailUrl,
  smsfSuccessUrl,
  emailVerificationUrl,
  onboardingTmdUrl,
  smsfAndTrustInReviewUrl,
} from 'src/utils/pageUrls';
import {
  save as saveCookie,
  remove as removeCookie,
} from 'src/browser/cookies';
import {
  sendCompleteSignUpSegmentEvents,
  sendResendVerifyEmailSegmentEvents,
  sendAcceptPdsSegmentEvents,
  sendVerifyTokenSegmentEvents,
  sendVedaFailFormSubmitSegmentEvents,
  sendSetupSegmentEvents,
  sendVedaPassSegmentEvents,
} from 'scripts/redux/actions/segment/events/registrationEvents';
import { successAlert, errorAlert } from 'scripts/redux/actions/alerts';
import { updateLocationHref } from 'src/browser/window';
import Configuration from 'scripts/constants/Configuration';
import IdentityCheck from 'scripts/constants/IdentityCheck';
import RegistrationService from 'scripts/services/RegistrationService';
import RegistrationStep from 'scripts/constants/RegistrationStep';
import history from 'src/browser/history';

const REGISTRATION_PROGRESS = 'scripts/registration/PROGRESS';
const registrationProgress = createAction(REGISTRATION_PROGRESS);

export const signUpStep = (data) =>
  registrationProgress({
    type: RegistrationStep.SIGNUP,
    data,
  });

export const verifyStep = (data) =>
  registrationProgress({
    type: RegistrationStep.VERIFY,
    data,
  });

// export const smsfStep = (data) => registrationProgress({
//   type: RegistrationStep.SMSF,
//   data
// });

export const pdsStep = (data) =>
  registrationProgress({
    type: RegistrationStep.PDS,
    data,
  });

export const setUpStep = (data) =>
  registrationProgress({
    type: RegistrationStep.SETUP,
    data,
  });

export const vedaStep = (data) =>
  registrationProgress({
    type: RegistrationStep.VEDA,
    data,
  });

export const depositStep = (data) =>
  registrationProgress({
    type: RegistrationStep.DEPOSIT,
    data,
  });

export const investStep = (data) =>
  registrationProgress({
    type: RegistrationStep.INVEST,
    data,
  });

export const doneStep = (data) =>
  registrationProgress({
    type: RegistrationStep.DONE,
    data,
  });

export function signUp(
  { givenName, email, password, accountType, ...other },
  isPopup
) {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        addPersonDataToRollbar({
          id: email,
          email: email,
          username: `${email} ${givenName} (signup attempt)`,
        });

        const signUpResponse = await RegistrationService.signUp({
          givenName,
          email,
          password,
          accountType,
          ...other,
        });
        const { user } = signUpResponse;
        const { trackingProps } = modalTrackingPropsSelector(getState());
        sendCompleteSignUpSegmentEvents(user, isPopup, trackingProps);

        addPersonDataToRollbar({
          id: user.email,
          email: user.email,
          username: `${user.email} (${user.givenName} ${user.familyName})`,
        });

        dispatch(setupUserSession(signUpResponse));

        clearReferral();

        dispatch(verifyStep(user));

        resolve(user);
      } catch (e) {
        dispatch(errorAlert(e.message));
        reject(e.message);
      }
    });
  };
}

export function signUpThenRedirect(...args) {
  return function (dispatch) {
    return dispatch(signUp(...args))
      .then((user) => {
        removeCookie('referral_pre_activation');
        saveCookie(Configuration.COOKIES.SIGN_UP_VERIFY_TOKEN, user.userId);
        // @TODO: I wonder, will a user ever be an investor
        // by this point in the onboarding...?
        if (isInvestor(user)) {
          saveCookie(Configuration.COOKIES.MEMBERSHIP_STATUS, 'i');
        } else {
          saveCookie(Configuration.COOKIES.MEMBERSHIP_STATUS, 's');
        }
        updateLocationHref(emailVerificationUrl());
      })
      .catch(() => {});
  };
}

export function smsfSetupThenRedirect({ trustName, abn, trusteeName }) {
  return function (dispatch, getState) {
    const {
      user: { familyName, givenName, email, mobilePhone },
    } = userSelector(getState());
    return RegistrationService.sendFreshdeskSuperDetails({
      messageType: `SMSF Setup Request`,
      email,
      name: familyName ? `${givenName} ${familyName}` : `${givenName}`,
      phoneNumber: mobilePhone
        ? mobilePhone::countryCodePhoneNumber()
        : 'undefined',
      message: `${trustName}  ,  ${abn}  ,  ${trusteeName}`,
    })
      .then(() => history.push(smsfSuccessUrl()))
      .catch((e) => {
        console.error('Something went wrong!', e);
        dispatch(
          errorAlert(`Something went wrong, please try again.
          Error details: ${JSON.stringify(e)}`)
        );
      });
  };
}

export function smsfSetup({ ...props }) {
  return function (dispatch) {
    return dispatch(smsfSetupThenRedirect(props));
  };
}

export function smsfSignUpAndSetup({ ...props }) {
  return function (dispatch) {
    return dispatch(signUp(props))
      .then((user) => dispatch(fetchUserDetails(user.id)))
      .then(() =>
        dispatch(
          smsfSetupThenRedirect({
            trustName: props.trustName,
            abn: props.abn,
            trusteeName: props.trusteeName,
          })
        )
      );
  };
}

export function resendEmail({ userId } = {}) {
  return async function (dispatch, getState) {
    try {
      const { user } = userSelector(getState());
      const { message } = await RegistrationService.resendEmail(
        user.id || userId
      );

      const resendEmailSegmentPayload = {
        'Email Sent': true,
        'Email Sent Date': now(),
      };
      sendResendVerifyEmailSegmentEvents(resendEmailSegmentPayload);

      dispatch(successAlert(message));
    } catch (e) {
      const resendEmailSegmentPayload = { 'Email Sent': false };
      sendResendVerifyEmailSegmentEvents(resendEmailSegmentPayload);

      dispatch(errorAlert(e.message));
    }
  };
}

export function acceptPds(userId, dateOfBirth) {
  return async function (dispatch) {
    try {
      await RegistrationService.acceptPds(userId, dateOfBirth);
      dispatch(setUpStep());

      const acceptPdsSegmentPayload = {
        'Complete PDS': true,
        'Complete PDS Date': now(),
      };
      sendAcceptPdsSegmentEvents(acceptPdsSegmentPayload);
    } catch (e) {
      const acceptPdsSegmentPayload = { 'Complete PDS': false };
      sendAcceptPdsSegmentEvents(acceptPdsSegmentPayload);

      dispatch(errorAlert(e.message));
    }
  };
}

export function verifyToken(token) {
  return async function (dispatch) {
    try {
      const verifyTokenResponse = await RegistrationService.verifyToken(token);
      dispatch(setupUserSession(verifyTokenResponse));
      dispatch(verifyStep({ success: true }));
      const tokenVerifySegmentPayload = {
        'Complete Verify': true,
        'Complete Verify Date': now(),
      };
      sendVerifyTokenSegmentEvents(
        tokenVerifySegmentPayload,
        verifyTokenResponse.user
      );
    } catch (e) {
      dispatch(verifyStep({ success: false }));
      const tokenVerifySegmentPayload = { 'Complete Verify': false };
      sendVerifyTokenSegmentEvents(tokenVerifySegmentPayload);
      dispatch(errorAlert(e.message));
    }
  };
}

export function sendTMDWaring(userId, payload) {
  return async function () {
    await RegistrationService.sendTMDWarning(userId, payload);
  };
}

export const SUBMIT_IDENTITY_VERIFICATION_FORM_START =
  'SUBMIT_IDENTITY_VERIFICATION_FORM_START';
export const SUBMIT_IDENTITY_VERIFICATION_FORM_FINISH =
  'SUBMIT_IDENTITY_VERIFICATION_FORM_FINISH';
export const STORE_IDENTITY_PAYLOAD = 'STORE_IDENTITY_PAYLOAD';
export const IDENTITY_VERIFICATION_FORM_ERROR =
  'IDENTITY_VERIFICATION_FORM_ERROR';
const identityVerificationFormErrors = (payload) => ({
  type: IDENTITY_VERIFICATION_FORM_ERROR,
  payload,
});

export const IDENTITY_VERIFICATION_FORM_ERROR_CLEARED =
  'IDENTITY_VERIFICATION_FORM_ERROR_CLEARED';
export const identityVerificationFormErrorsCleared = (errorToClear) => ({
  type: IDENTITY_VERIFICATION_FORM_ERROR_CLEARED,
  payload: errorToClear,
});

export function submitIdentityForVerification(payload, handleFailedVedaResult) {
  return function (dispatch, getState) {
    dispatch({ type: SUBMIT_IDENTITY_VERIFICATION_FORM_START });
    const { user } = userSelector(getState());
    return RegistrationService.submitSetupForm(payload)
      .then(() => dispatch(fetchUserDetails(user.id)))
      .then(() => {
        const {
          user: { identityCheck: identityCheckStatus },
        } = userSelector(getState());
        dispatch({ type: SUBMIT_IDENTITY_VERIFICATION_FORM_FINISH });
        const segmentPassOrFailPayload = {
          'VEDA Result': 'Pass',
          'VEDA Pass Date': now(),
          givenName: user.givenName,
          attemptNumber: typeof handleFailedVedaResult === 'function' ? 2 : 1,
        };
        if (payload.isWholesale) {
          RegistrationService.signupWholesaleInvestor({
            id: user.id,
            questionnaire: payload.questionnaire,
            certificate: payload.certificate,
            certificateLabel: payload.certificateLabel,
            certificateExpired: '01-01-2099', // TODO: this shouldn't be hard-coded
          }).then((res) => {
            console.log(res);
          });
        }
        if (identityCheckStatus === IdentityCheck.PASSED) {
          history.push(onboardingTmdUrl());
          sendVedaPassSegmentEvents(segmentPassOrFailPayload);
        } else {
          sendVedaPassSegmentEvents({
            ...segmentPassOrFailPayload,
            ...{ 'VEDA Result': 'Fail' },
          });
          history.push(vedaFailUrl());
          if (typeof handleFailedVedaResult === 'function') {
            handleFailedVedaResult();
          }
        }
      })
      .catch((e) => {
        if (e.message.startsWith && e.message.startsWith(' veda returned')) {
          dispatch(
            identityVerificationFormErrors({
              requestError:
                'We are having some trouble contacting our verification service at the moment. Please try again later.',
            })
          );
          return dispatch({ type: SUBMIT_IDENTITY_VERIFICATION_FORM_FINISH });
        }
        const { address, dateOfBirth } = payload;
        dispatch({ type: SUBMIT_IDENTITY_VERIFICATION_FORM_FINISH });
        dispatch(identityVerificationFormErrors(e.message));
        sendSetupSegmentEvents({
          'Post Code': address::suburb(),
          'Year of Birth': dateOfBirth::year(),
          'Complete Details': false,
        });
        // console.error(e);
      });
  };
}

export function submitIdentityVerificationForm(identityData) {
  return function (dispatch, getState) {
    const { user } = userSelector(getState());
    const isManualAddress = !identityData.harmonyAddressObject;
    const payload = { id: user.id, isManualAddress, ...identityData };
    establishHarmonyAddressLookupTransaction();
    sendSetupSegmentEvents({
      'Post Code': identityData.address::suburb(),
      'Year of Birth': identityData.dateOfBirth::year(),
      'Complete Details': true,
    });
    dispatch({ type: STORE_IDENTITY_PAYLOAD, payload });
    dispatch(submitIdentityForVerification(payload));
  };
}

// /////////// Minor

export function submitMinorIdentityForVerification(
  payload,
  handleFailedVedaResult
) {
  return function (dispatch, getState) {
    dispatch({ type: SUBMIT_IDENTITY_VERIFICATION_FORM_START });
    const { user } = userSelector(getState());
    return RegistrationService.submitMinorSetupForm(payload)
      .then(() => dispatch(fetchUserDetails(user.id)))
      .then(() => {
        const {
          user: { identityCheck: identityCheckStatus },
        } = userSelector(getState());
        dispatch({ type: SUBMIT_IDENTITY_VERIFICATION_FORM_FINISH });
        const segmentPassOrFailPayload = {
          'VEDA Result': 'Pass',
          'VEDA Pass Date': now(),
          givenName: user.givenName,
          attemptNumber: typeof handleFailedVedaResult === 'function' ? 2 : 1,
        };
        if (identityCheckStatus === IdentityCheck.PASSED) {
          history.push(onboardingTmdUrl());
          sendVedaPassSegmentEvents(segmentPassOrFailPayload);
        } else {
          sendVedaPassSegmentEvents({
            ...segmentPassOrFailPayload,
            ...{ 'VEDA Result': 'Fail' },
          });
          history.push(vedaFailUrl());
          if (typeof handleFailedVedaResult === 'function') {
            handleFailedVedaResult();
          }
        }
      })
      .catch((e) => {
        if (e.message.startsWith && e.message.startsWith(' veda returned')) {
          dispatch(
            identityVerificationFormErrors({
              requestError:
                'We are having some trouble contacting our verification service at the moment. Please try again later.',
            })
          );
          return dispatch({ type: SUBMIT_IDENTITY_VERIFICATION_FORM_FINISH });
        }
        const { address, dateOfBirth } = payload;
        dispatch({ type: SUBMIT_IDENTITY_VERIFICATION_FORM_FINISH });
        dispatch(identityVerificationFormErrors(e.message));
        sendSetupSegmentEvents({
          'Post Code': address::suburb(),
          'Year of Birth': dateOfBirth::year(),
          'Complete Details': false,
        });
        console.error(e);
      });
  };
}

export function submitMinorIdentityVerificationForm(identityData) {
  return function (dispatch, getState) {
    const { user } = userSelector(getState());
    const isManualAddress = !identityData.harmonyAddressObject;
    const payload = { id: user.id, isManualAddress, ...identityData };
    establishHarmonyAddressLookupTransaction();
    dispatch({ type: STORE_IDENTITY_PAYLOAD, payload });
    dispatch(submitMinorIdentityForVerification(payload));
  };
}

// /////////// Smsf

export function submitSmsfIdentityForVerification(
  payload,
  handleFailedVedaResult
) {
  return function (dispatch, getState) {
    dispatch({ type: SUBMIT_IDENTITY_VERIFICATION_FORM_START });
    const { user } = userSelector(getState());
    return RegistrationService.submitSmsfSetupForm(payload)
      .then(() => dispatch(fetchUserDetails(user.id)))
      .then(() => {
        const {
          user: { identityCheck: identityCheckStatus },
        } = userSelector(getState());
        dispatch({ type: SUBMIT_IDENTITY_VERIFICATION_FORM_FINISH });
        const segmentPassOrFailPayload = {
          'VEDA Result': 'Pass',
          'VEDA Pass Date': now(),
          givenName: user.givenName,
          attemptNumber: typeof handleFailedVedaResult === 'function' ? 2 : 1,
        };
        console.log(payload);
        if (payload.isWholesale) {
          RegistrationService.signupWholesaleInvestor({
            id: user.id,
            questionnaire: payload.questionnaire,
            certificate: payload.certificate,
            certificateLabel: payload.certificateLabel,
            certificateExpired: '01-01-2099', // TODO: this shouldn't be hard-coded
          }).then((res) => {
            console.log(res);
          });
        }
        if (identityCheckStatus === IdentityCheck.PASSED) {
          history.push(smsfAndTrustInReviewUrl());
          sendVedaPassSegmentEvents(segmentPassOrFailPayload);
        } else {
          sendVedaPassSegmentEvents({
            ...segmentPassOrFailPayload,
            ...{ 'VEDA Result': 'Fail' },
          });
          history.push(vedaFailUrl());
          if (typeof handleFailedVedaResult === 'function') {
            handleFailedVedaResult();
          }
        }
      })
      .catch((e) => {
        if (e.message.startsWith && e.message.startsWith(' veda returned')) {
          dispatch(
            identityVerificationFormErrors({
              requestError:
                'We are having some trouble contacting our verification service at the moment. Please try again later.',
            })
          );
          return dispatch({ type: SUBMIT_IDENTITY_VERIFICATION_FORM_FINISH });
        }
        const { address, dateOfBirth } = payload;
        dispatch({ type: SUBMIT_IDENTITY_VERIFICATION_FORM_FINISH });
        dispatch(identityVerificationFormErrors(e.message));
        sendSetupSegmentEvents({
          'Post Code': address::suburb(),
          'Year of Birth': dateOfBirth::year(),
          'Complete Details': false,
        });
        console.error(e);
      });
  };
}

export function submitSmsfIdentityVerificationForm(identityData) {
  return function (dispatch, getState) {
    const { user } = userSelector(getState());
    const isManualAddress = !identityData.harmonyAddressObject;
    const payload = { id: user.id, isManualAddress, ...identityData };
    establishHarmonyAddressLookupTransaction();
    dispatch({ type: STORE_IDENTITY_PAYLOAD, payload });
    dispatch(submitSmsfIdentityForVerification(payload));
  };
}

export function submitVedaFailForm(licence) {
  return function (dispatch, getState) {
    const { identityPayloadForVedaRetry } = identityPayloadForVedaRetrySelector(
      getState()
    );
    dispatch(
      identityPayloadForVedaRetry.givenName
        ? retryIdentityVerification(identityPayloadForVedaRetry, licence)
        : sendManualVedaEmail(licence)
    );
  };
}

function retryIdentityVerification(identityData, licence) {
  return function (dispatch, getState) {
    const { user } = userSelector(getState());
    const isManualAddress = !identityData.harmonyAddressObject;
    const payload = { id: user.id, isManualAddress, licence, ...identityData };
    dispatch(
      submitIdentityForVerification(payload, () =>
        dispatch(sendManualVedaEmail(licence))
      )
    );
  };
}

function sendManualVedaEmail(licence) {
  return function (dispatch, getState) {
    const { user } = userSelector(getState());
    dispatch({ type: SUBMIT_IDENTITY_VERIFICATION_FORM_START });
    return RegistrationService.sendVedaEmail({
      name: `${user.givenName} ${user.familyName}`,
      email: user.email,
      phoneNumber: user.mobilePhone::countryCodePhoneNumber(),
      investor: false,
      messageType: `Manual VEDA`,
      message: `${licence.licenceNumber}, ${licence.licenceState}`,
    })
      .then(() => {
        sendVedaFailFormSubmitSegmentEvents({
          user: {
            id: user.id,
            givenName: user.givenName,
            email: user.email,
          },
          licence,
        });
        saveCookie('veda-drivers-licence-already-collected', true);
        history.push(accountUrl());
        dispatch(
          errorAlert(
            `Sorry. Some of your information could not be verified online. Please check your email for next steps and to verify manually.`
          )
        );
      })
      .catch((e) => {
        console.log(e);
        dispatch(errorAlert(e.message));
      })
      .finally(() =>
        dispatch({ type: SUBMIT_IDENTITY_VERIFICATION_FORM_FINISH })
      );
  };
}

export function startInvest(depositAmount) {
  return async function (dispatch) {
    try {
      await dispatch(fetchUserDetails());
      dispatch(investStep({ depositAmount }));
    } catch (e) {
      dispatch(errorAlert(e.message));
    }
  };
}

const reducer = handleActions(
  {
    [registrationProgress]: (state, action) =>
      update(state, {
        $set: action.payload,
      }),
  },
  {
    type: 'signup',
    data: '',
  }
);

export default reducer;
