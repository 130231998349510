

import { Component } from 'react';
import PropTypes from 'prop-types';

import { myOrder as myOrderPropType, property as propertyPropType } from 'scripts/constants/PropTypes';

import MyPreOrderRow from 'scripts/components/account/myOrders/MyPreOrderRow';
import Popover from 'scripts/components/shared/Popover';

import { date as dateSort } from 'scripts/utilities/sorters';
import { brickPrice } from 'scripts/utilities/formatters';

import _ from 'lodash';

const ORDER_TOTAL_POPOVER_TEXT = `The total cost of your pre-order, including fees.`;


export default class MyPreOrdersTable extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    myOrders: PropTypes.arrayOf(myOrderPropType),
    properties: PropTypes.arrayOf(propertyPropType).isRequired,
    action: PropTypes.func,
    emptyMessage: PropTypes.element
  };

  state = {
    expanded: {}
  };

  render() {
    const { title, myOrders, properties, action, emptyMessage } = this.props;
    return (
      <div className="panel panel-default panel-orders">
        <div className="panel-heading">
          <h3 className="panel-title"><span className="icn icn-small icn-pending-white"></span>{title}</h3>
        </div>
        {_.isEmpty(myOrders)
          ?
          <div className="panel-body">
            {emptyMessage}
          </div>
          :
          <table className="table table-bordered table-hover pending-pre-orders-table">
            <thead>
              <tr>
                <th className="text-left">Property</th>
                <th className="hidden-xs hidden-sm">Date ordered</th>
                <th className="hidden-xs hidden-sm">
                  <span>Settlement<br/>date</span>
                </th>
                <th>
                  <span className="hidden-xs hidden-sm">Qty of<br/>Bricks</span>
                  <span className="hidden-md hidden-lg">Qty</span>
                </th>
                <th>Brick<br/>Initial Price</th>
                <th>Order Total <Popover placement="top" content={ORDER_TOTAL_POPOVER_TEXT}/></th>
                <th className="hidden-xs hidden-sm">Reference</th>
                <th>{/* no actions */}</th>
              </tr>
            </thead>
            <tbody>
              {myOrders::dateSort(false).map((myOrder, index) =>
                <MyPreOrderRow key={index} myOrder={myOrder} properties={properties} action={action}/>
              )}
            </tbody>

            <tfoot>
              <tr>
                <td colSpan="5" className="text-left hidden-xs hidden-sm">TOTAL</td>
                <td colSpan="3" className="text-left hidden-md hidden-lg">TOTAL</td>
                <td className="total-amount-you-pay">{this._totalAmountYouPay(myOrders)::brickPrice()}</td>
                <td colSpan="3" className="hidden-xs hidden-sm"></td>
              </tr>
            </tfoot>
          </table>
        }
      </div>
    );
  }

  _totalAmountYouPay = (orders) => orders.reduce((sum, order) => order.totalPriceInclFees + sum, 0);
}
