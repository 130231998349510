import Divider from 'src/design/components/divider/Divider';
import Text from 'src/design/styleguide/text/Text';

const InputGroupSubHeading = ({ children, testRef }) => (
  <Divider top="x-large" bottom="normal" testRef={testRef}>
    <Text fontSize="3x-small" lineHeight="small">{children}</Text>
  </Divider>
);

export default InputGroupSubHeading;
