import { Component, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Markdown from 'react-remarkable';
import _ from 'lodash';

import AlertType from 'scripts/constants/AlertType';
import { alert as alertPropType } from 'scripts/constants/PropTypes';
import { alertsSelector } from 'scripts/redux/selectors/alerts';
import { closeAlert } from 'scripts/redux/actions/alerts';

const AlertMessage = ({ children }) => (
  <div name="alert-message" className="alert-message">
    {children}
  </div>
);

const mapStateToProps = (state) => ({
  ...alertsSelector(state),
});

const AlertBox = ({ onClose, index, alert, autoClose }) => {
  const INITTIME = 10;

  const [timeLeft, setTimeLeft] = useState(INITTIME);

  const runCounter = () => {
    if (autoClose) {
      if (timeLeft === 0) onClose();
      if (timeLeft > 0) {
        const timeout = setTimeout(() => {
          setTimeLeft(timeLeft - 1);
        }, 1000);
        return () => {
          clearTimeout(timeout);
        };
      }
    }
  };

  useEffect(() => {
    runCounter();
  }, [timeLeft]);

  return (
    <li
      data-test-reference={`${alert.type}-alert-type`}
      key={index}
      className={classNames('alert alert-dismissable', {
        'alert-success': alert.type === AlertType.SUCCESS,
        'alert-warning': alert.type === AlertType.WARNING,
        'alert-danger': alert.type === AlertType.ERROR,
      })}
      role="alert"
    >
      {_.isString(alert.message) && (
        <AlertMessage>
          <Markdown source={alert.message} />
        </AlertMessage>
      )}
      {_.isObject(alert.message) &&
        _.toPairs(alert.message).map(([key, message]) => (
          <AlertMessage>
            <b>{key}</b>: {message}
          </AlertMessage>
        ))}
      <div className="close-btn" aria-hidden="true" onClick={onClose}>
        Confirm{' '}
        {autoClose && (
          <div style={{ marginTop: '-10px' }}>
            <small>
              <small>close in {timeLeft}s</small>
            </small>
          </div>
        )}
      </div>
    </li>
  );
};

@connect(mapStateToProps, {
  closeAlert,
})
export default class AccountAlerts extends Component {
  static propTypes = {
    alerts: PropTypes.arrayOf(alertPropType),
    closeAlert: PropTypes.func.isRequired,
  };

  render() {
    const { alerts } = this.props;
    if (_.isEmpty(alerts)) {
      return null;
    }
    return (
      <ul className="alerts">
        {alerts.map((alert, index) => (
          <AlertBox
            alert={alert}
            index={index}
            onClose={() => this._dismissAlert(index)}
          />
        ))}
      </ul>
    );
  }

  _dismissAlert(index) {
    this.props.closeAlert(index);
  }
}
