import _ from 'lodash';
import moment from 'moment';

const iteratees = {
  date: (item) => moment(item.date).valueOf(),
};
const keyMapper = (key) => _.get(iteratees, key, key);

function genericSort(keys, orders) {
  var ordersInStringFormat = [];
  _.each(orders, function (element, index) {
    ordersInStringFormat[index] = element ? 'asc' : 'desc';
  });
  return _.orderBy(this, keys.map(keyMapper), ordersInStringFormat);
}

export function price(ascending = true) {
  return this::genericSort(['price'], [ascending]);
}

export function date(ascending = true) {
  return this::genericSort(['date'], [ascending]);
}

export function priceDate(priceAsc = true, dateAsc = true) {
  return this::genericSort(['price', 'date'], [priceAsc, dateAsc]);
}
