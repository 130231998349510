import { Fragment } from 'react';
import classNames from 'classnames';
import { Heading, HeadingXSmall } from 'src/design/components/heading/Headings';
import {
  howItWorksUrl,
  propertiesUrl,
  propertyTeamUrl,
} from 'src/utils/pageUrls';
import { howItWorksVideoId } from 'src/utils/videoIds';
import { scrollToId } from 'src/browser/window';
import AboveTheFoldPromo from 'src/components/landingPages/home/AboveTheFoldPromo';
import Analytics from 'src/tracking/analytics';
import BodyText from 'src/design/components/bodyText/BodyText';
import FinancialMetricsBar from 'src/components/landingPages/home/FinancialMetricsBar';
import GetStartedButton from 'src/components/landingPages/common/getStartedButton/GetStartedButton';
import HeadingVideoSection from 'src/components/landingPages/common/headingVideoSection/HeadingVideoSection';
import HomeMeta from 'src/components/meta/HomeMeta';
import HowItWorksSection from 'src/components/landingPages/common/howItWorksSection/HowItWorksSection';
import PageMarginContainer from 'src/design/components/pageMarginContainer/PageMarginContainer';
import Paragraph from 'src/design/components/bodyText/Paragraph';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import Referrals from 'src/components/landingPages/home/Referrals';
import SecondaryButton from 'src/design/components/button/SecondaryButton';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import StyledBodylink from 'src/design/components/hyperlink/StyledBodylink';
import SubFooter from 'src/components/subFooter/SubFooter';
import TickedBulletList from 'src/design/components/bulletList/TickedBulletList';
import styles from 'src/components/landingPages/home/Home.mscss';
import withState from 'src/decorators/withState';

const ThreeIllustratedPoints = ({ className, children }) => (
  <div className={classNames(styles.threeIllustratedPoints, className)}>
    {children}
  </div>
);

const IllustratedPoint = ({ className, children }) => (
  <div className={classNames(styles.illustratedPoint, className)}>
    {children}
  </div>
);

const PropertiesBanner = () => (
  <PageMarginContainer
    className={classNames(styles.propertiesBanner)}
    as="section"
  >
    <div className={styles.propertiesInner}>
      <Spacing bottom="large">
        <Heading className={styles.propertiesTitle} align="center">
          <h2>BrickX Properties</h2>
        </Heading>
      </Spacing>
      <Spacing top="large" bottom="normal">
        <img
          className={styles.propertyCardsImage}
          src="/static/img/photo-brickx-property-cards.png"
          srcSet="/static/img/photo-brickx-property-cards.png 600w, /static/img/photo-brickx-property-cards@2x.png 1200w, /static/img/photo-brickx-property-cards@3x.png 1800w"
          alt="3 property cards of current BrickX properties"
        />
      </Spacing>
      <div className={styles.propertyBodyContent}>
        <Paragraph>
          Choose which property to buy Bricks in (or use Smart Invest). Our
          range of properties is carefully selected by our specialist Property
          Team based on growth potential.
        </Paragraph>
        <Spacing top="large" bottom="x-small">
          <Paragraph>Property details include:</Paragraph>
        </Spacing>
        <TickedBulletList
          responsiveColumns={{
            base: TickedBulletList.columns.ONE,
            small: TickedBulletList.columns.TWO,
          }}
          items={[
            'Historical suburb growth',
            'Independent property valuations',
            'Forecasted cash flows',
            'Estimated net rental income and yield',
          ]}
          textSize="small"
        />
        <Spacing top="large">
          <div className={styles.propertyBodyCtasRow}>
            <SecondaryButton
              link={propertiesUrl()}
              fullWidth
              className={styles.propertyBodyCta}
            >
              View Properties
            </SecondaryButton>
            <SecondaryButton
              link={propertyTeamUrl()}
              fullWidth
              className={styles.propertyBodyCta}
            >
              Meet the BrickX Team
            </SecondaryButton>
          </div>
        </Spacing>
      </div>
    </div>
  </PageMarginContainer>
);

const Home = ({ location }) => {
  return (
    <div className={styles.home} data-test-reference="home-page">
      <HomeMeta />

      <Referrals tokens={location.query} />

      <HeadingVideoSection
        videoId={howItWorksVideoId}
        onPlay={() => Analytics.track('Home Page Video Play')}
        onFinish={() => Analytics.track('Home Page Video Finished Watching')}
        title="Want an easier way to invest in property?"
        captionContent={
          <Fragment>
            <Paragraph>
              BrickX is an award-winning platform that provides a simple and low
              cost way to access the property market.
            </Paragraph>
            <Spacing top="normal" bottom="2x-large">
              <Paragraph>
                It’s the new, easier way to invest in property.
              </Paragraph>
            </Spacing>
            <div className={styles.headingVideoLinksRow}>
              <GetStartedButton
                className={styles['headingVideoLinksRow--getStartedButton']}
              />
              <SecondaryButton
                link={howItWorksUrl()}
                className={styles['headingVideoLinksRow--learnMoreButton']}
              >
                Learn How it Works
              </SecondaryButton>
            </div>
          </Fragment>
        }
        promoContent={<AboveTheFoldPromo />}
      />

      <HowItWorksSection
        backgroundColor="grey"
        cta={
          <PrimaryButton
            color={PrimaryButton.colors.SECONDARY}
            link={howItWorksUrl()}
          >
            Learn How it Works
          </PrimaryButton>
        }
      />

      <PropertiesBanner />

      <section className={classNames(styles.whyChooseBrickX)}>
        <div
          className={classNames(
            styles.innerContainer,
            styles.whyChooseBrickXInner
          )}
        >
          <Heading align="center">
            <h2>Why Choose BrickX?</h2>
          </Heading>
          <ThreeIllustratedPoints>
            <IllustratedPoint className={styles.illustratedPoint}>
              <img
                className={classNames(styles.illustration)}
                src="/static/img/icon-selection-indicator-with-lightning-bolt.svg"
                alt=""
              />
              <HeadingXSmall className={styles.illustratedPointTitle}>
                <h5>Simplicity</h5>
              </HeadingXSmall>
              <Paragraph>
                Select your property and within minutes you can own Bricks in an
                investment property.
              </Paragraph>
            </IllustratedPoint>
            <IllustratedPoint className={styles.illustratedPoint}>
              <img
                className={classNames(styles.illustration)}
                src="/static/img/icon-open-doorway.svg"
                alt=""
              />
              <HeadingXSmall className={styles.illustratedPointTitle}>
                <h5>Finally - Access to Property Market</h5>
              </HeadingXSmall>
              <Paragraph>
                Feeling locked out of the property market? With Bricks from
                under $50, now there’s an affordable way to invest.
              </Paragraph>
            </IllustratedPoint>
            <IllustratedPoint className={styles.illustratedPoint}>
              <img
                className={classNames(styles.illustration)}
                src="/static/img/icon-light-bulb-with-house-inside.svg"
                alt=""
              />
              <HeadingXSmall className={styles.illustratedPointTitle}>
                <h5>Property team expertise</h5>
              </HeadingXSmall>
              <Paragraph>
                Properties are hand-picked by our property team.
                <StyledBodylink href={propertyTeamUrl()}>
                  Learn More
                </StyledBodylink>
              </Paragraph>
            </IllustratedPoint>
          </ThreeIllustratedPoints>
        </div>
      </section>

      <FinancialMetricsBar />

      <section className={styles.whoBacksBrickX}>
        <div
          className={classNames(
            styles.innerContainer,
            styles.whoBacksBrickXInnerContainer
          )}
        >
          <Spacing bottom="normal">
            <BodyText className={styles.salmonLabelText}>
              <h6>BrickX is a wholly owned subsidiary of Thundering Herd.</h6>
            </BodyText>
          </Spacing>
          <Spacing bottom="large">
            <Heading className={styles.propertiesTitle} align="center">
              <h2>About Thundering Herd</h2>
            </Heading>
          </Spacing>
          <Paragraph>
            Thundering Herd is a Venture Capital & Private Equity Investment
            firm that has developed its own proprietary technology platform to
            facilitate the identification, assessment, lead investment and
            syndication of unique investment opportunities in companies ranging
            from early stage start-ups to private companies and exchange traded
            companies. Thundering Herd is a lead investor that also facilitates
            an exclusive network of qualified investors to sub-syndicate into
            transactions it identifies. Some Investors within the Thundering
            Herd network choose to have Thundering Herd manage their funds
            alongside its own in a Funds Management and Trustee capacity.
          </Paragraph>
          <div className={styles.sponsorLogos}>
            <a
              style={{ width: '200px' }}
              target="_blank"
              href="https://thunderingherd.com"
            >
              <img src="/static/img/TH-logo.png" alt="Thunderingherd logo" />
            </a>
          </div>
        </div>
      </section>

      <SubFooter />
    </div>
  );
};

export default withState({
  Component: Home,
  componentDidMount: ({ location }) => {
    location.query.scrollTo && scrollToId(location.query.scrollTo);
  },
  UNSAFE_componentWillReceiveProps: (props, nextProps) =>
    nextProps.location.query.scrollTo &&
    scrollToId(nextProps.location.query.scrollTo),
});
