import StyledBodylink from 'src/design/components/hyperlink/StyledBodylink';

export const OnClicklink = ({
  onClick,
  children,
  disabled,
  ...props
}) => disabled
  ? <span>{children}</span>
  : <StyledBodylink
    onClick={(e) => {
      e.preventDefault();
      onClick();
    }}
    {...props}
  >{children}</StyledBodylink>;

export default OnClicklink;
