import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import DynamicNumber from 'react-dynamic-number';

import { dollarDecimal } from 'scripts/utilities/formatters';
import { sendOtp } from 'scripts/redux/actions/user';

import {
  user as userPropType,
  account as accountPropType,
} from 'scripts/constants/PropTypes';

import Loading from 'src/components/loading/Loading';
import Icon from 'scripts/components/shared/Icon';
import Constants from 'scripts/constants/Constants';
import { detailsUpdateUrl } from 'src/utils/pageUrls';

const INTEGERS = 10;
const FRACTION = 2;

class WithdrawForm extends Component {
  static propTypes = {
    user: userPropType.isRequired,
    account: accountPropType.isRequired,
    updateAmountCallback: PropTypes.func,
    sendOtp: PropTypes.func,
  };

  state = {
    amount: 0,
    hasError: false,
    errorMsg: '',
    loading: false,
  };

  render() {
    const nominatedBankDetails = this._nominatedBankDetails();
    const { account } = this.props;
    const { loading, hasError, amount } = this.state;

    return (
      <div className="row">
        <div className="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2">
          {account.availableToTradeBalance <= 0 && (
            <div className="withdraw-note">
              <Icon type="fa" name="exclamation-triangle" />
              You do not have any funds to withdraw. Your account balance is
              currently {0::dollarDecimal()}.
            </div>
          )}
          <div className="panel-withdraw">
            <div className="funds-available">
              <div className="title">Funds available to withdraw</div>
              <div className="amount">
                <strong>
                  {account.availableToTradeBalance::dollarDecimal()}
                </strong>
              </div>
            </div>
            <form onSubmit={this._onSubmit}>
              <div className="row">
                <div className="col-xs-12">
                  <div className="input">
                    <span className="title">Account Name</span>
                    <span className="value">
                      {nominatedBankDetails.accountName}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <div className="input">
                    <span className="title">BSB</span>
                    <span className="value">{nominatedBankDetails.bsb}</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <div className="input">
                    <span className="title">Account Number</span>
                    <span className="value">
                      {nominatedBankDetails.accNumber}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <span>
                    <br />
                    <a href={detailsUpdateUrl()}>Click Here</a> if you want to
                    update the bank account details.
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <div className="form-group">
                    <DynamicNumber
                      className="light-blue-input"
                      id="id_amount"
                      name="amount"
                      separator={'.'}
                      integer={INTEGERS}
                      fraction={FRACTION}
                      positive={Constants.TRUE}
                      thousand={Constants.TRUE}
                      placeholder="Enter Withdraw Amount"
                      onChange={this._updateWithdrawAmount}
                    />
                  </div>
                </div>
                {hasError && (
                  <div className="col-xs-12 error">{this.state.errorMsg}</div>
                )}
              </div>
              <button
                disabled={
                  loading ||
                  account.availableToTradeBalance <= 0 ||
                  hasError ||
                  amount <= 0
                }
                id="continue-withdraw-button"
                className="button orange-button right-arrow-button"
              >
                NEXT
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }

  _nominatedBankDetails = () => {
    const { user } = this.props;
    return {
      accountName: user.financialDetails.bankAccountDetails.bankAccountName,
      bsb: user.financialDetails.bankAccountDetails.BSB,
      accNumber: user.financialDetails.bankAccountDetails.bankAccountNumber,
    };
  };

  _updateWithdrawAmount = (event) => {
    const updatedAmount = event.target.value.split(',').join('') * 1;
    if (isNaN(updatedAmount)) return;

    const { account } = this.props;
    const fundsAvailable = account.availableToTradeBalance;

    if (updatedAmount <= 0) {
      this.setState({
        amount: updatedAmount,
        hasError: true,
        errorMsg: 'Please enter valid withdraw amount.',
      });
    } else if (updatedAmount > fundsAvailable) {
      this.setState({
        amount: updatedAmount,
        hasError: true,
        errorMsg: 'Please enter amount smaller than Funds available.',
      });
    } else {
      this.setState({
        amount: updatedAmount,
        hasError: false,
      });
    }
  };

  _onSubmit = (e) => {
    e.preventDefault();
    if (this.state.hasError) {
      return;
    }

    this._requestOtp();
  };

  async _requestOtp() {
    this.setState({
      loading: true,
    });

    await this.props.sendOtp();

    this.setState({
      loading: false,
    });

    this.props.updateAmountCallback(this.state.amount);
  }
}

export default class Withdraw extends Component {
  static propTypes = {
    user: userPropType.isRequired,
    account: accountPropType.isRequired,
    updateAmountCallback: PropTypes.func.isRequired,
    sendOtp: PropTypes.func.isRequired,
  };

  state = {
    amount: null,
  };

  render() {
    const { user, account, updateAmountCallback, sendOtp } = this.props;
    if (!user || !account) {
      return <Loading />;
    }

    const bankAccountNominated =
      user.financialDetails &&
      user.financialDetails.bankAccountDetails &&
      account.wallet;
    return (
      <div>
        {bankAccountNominated ? (
          <WithdrawForm
            user={user}
            account={account}
            updateAmountCallback={updateAmountCallback}
            sendOtp={sendOtp}
          />
        ) : (
          <div className="row">
            <div className="col-md-6 col-md-offset-3">
              <div className="withdraw-note">
                <Icon type="fa" name="exclamation-triangle" />
                You have not completed your nominated bank account. Please fill
                in this information your{' '}
                <Link to="/account/settings">settings page</Link> to continue,
                and withdraw funds.
              </div>
              <div className="panel-withdraw">
                <div className="funds-available">
                  <div className="title">Funds available to withdraw</div>
                  <div className="amount">
                    {account.availableToTradeBalance::dollarDecimal()}
                  </div>
                </div>
                <Link to="/account/settings">
                  <div className="button orange-button right-arrow-button">
                    NOMINATE MY BANK ACCOUNT
                  </div>
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
