import request from 'scripts/services/request';
import {
  historicalGrowthMetrics,
  historicalGrowthData,
} from 'scripts/services/api';
import { withCredentials } from 'scripts/services/helpers';

class HistoricalGrowthService {
  getHistoricalGrowthMetrics() {
    return request(historicalGrowthMetrics(), {
      ...withCredentials
    });
  }

  getHistoricalGrowthData(historicalGrowthKey, state, propertyType) {
    return request(historicalGrowthData(historicalGrowthKey, state, propertyType), {
      ...withCredentials
    });
  }
}

export default new HistoricalGrowthService();
