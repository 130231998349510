import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { property as propertyPropType } from 'scripts/constants/PropTypes';

import Popover from 'scripts/components/shared/Popover';

import { brickPrice, monthYear } from 'scripts/utilities/formatters';
import { isAPreOrderProperty } from 'scripts/utilities/propertyHelper';

import Copy from 'scripts/constants/Copy.js';


const View = ({
  className,
  showIcon,
  showValuationDate,
  brickValueTitle,
  brickValuationDate,
  brickValueText,
  brickValuePopoverText,
}) => (
  <div className={classNames('brick-valuation-bar', showIcon ? '' : 'no-icon', className)}>
    <div className="wrap-the-col">
      <div className="col-md-8 col-xs-7 col-no-padding">
        <div className={classNames(showIcon ? 'left-side-icon' : 'left-side-no-icon')}>
          {showIcon &&
            <img src="/static/img/bx-logo-x-only.svg"/>
          }
          <span className="brick-valuation-title">{brickValueTitle}</span>
          {showValuationDate &&
            <div className="valuation-date">{brickValuationDate}</div>
          }
        </div>
      </div>
      <div className="col-md-4 col-xs-5 col-no-padding brick-valuation-value" data-test-reference="brick-value">
        {brickValueText}&nbsp;
        <span className="text-off-black"><Popover placement="top" content={brickValuePopoverText}/></span>
      </div>
    </div>
  </div>
);

export default class BrickValuationBar extends Component {
  static propTypes = {
    className: PropTypes.string,
    property: propertyPropType,
    showIcon: PropTypes.bool,
    showValuationDate: PropTypes.bool,
  };

  render() {
    const { className, showIcon, showValuationDate, property } = this.props;

    if (!property) {
      return (
        <View
          {...{
            className,
            showIcon,
            showValuationDate,
            brickValueTitle: '---',
            brickValuationDate: '---',
            brickValueText: '$__.00',
            brickValuePopoverText: '',
          }}
        />
      );
    }

    const isPreorderOrComingSoonProperty = isAPreOrderProperty(property);
    const isPreOrderOrNotIndependentValuedProperty = isPreorderOrComingSoonProperty || !property.financials.isIndependentValued;
    const brickValuationDate = isPreOrderOrNotIndependentValuedProperty ? `(${(property.platformSettlementDate)::monthYear()})` : `(${(property.financials.latestValuationDate)::monthYear()})`;
    const brickValueTitle = isPreOrderOrNotIndependentValuedProperty ? `Initial Brick Price` : `Brick Valuation`;
    const brickValuePopoverText = isPreOrderOrNotIndependentValuedProperty ?
      Copy.helpText.initialBrickPrice :
      Copy.helpText.brickValuation;
    const brickValueText = property.financials.brickValue::brickPrice();

    return (
      <View
        {...{
          className,
          showIcon,
          showValuationDate,
          brickValueTitle,
          brickValuationDate,
          brickValueText,
          brickValuePopoverText,
        }}
      />
    );
  }
}
