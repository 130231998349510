import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { dayMonthCommaYearExtended } from 'scripts/utilities/formatters';
import { fetchMedia } from 'scripts/redux/actions/contents';
import { media as mediaPropType } from 'scripts/constants/PropTypes';
import { mediaSelector } from 'scripts/redux/selectors/contents';
import Loading from 'src/components/loading/Loading';
import MediaMeta from 'src/components/meta/MediaMeta';

const mapStateToProps = (state) => ({
  ...mediaSelector(state),
});

@connect(mapStateToProps, {
  fetchMedia,
})
export default class MediaPage extends Component {
  static propTypes = {
    contents: PropTypes.shape({
      media: PropTypes.arrayOf(mediaPropType)
    }),
    fetchMedia: PropTypes.func.isRequired,
    params: PropTypes.object
  };

  componentDidMount() {
    this.props.fetchMedia();
  }

  render() {
    const { contents: { media } } = this.props;
    if (_.isEmpty(media)) {
      return <Loading />;
    }
    const mediaToShow = _.take(media, 20);
    return (
      <div id="media">
        <MediaMeta/>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>BrickX in the Media</h1>
            </div>
            <div className="col-md-12">
              {mediaToShow.map((m, index) => (
                <div id={m.slug} className="media" key={index}>
                  <div className="lhs">
                    <a href={m.url} target="_blank" className="img" style={{ backgroundImage: `url(${m.imageUrl})` }} />
                  </div>
                  <div className="rhs">
                    <div className="date">{m.date::dayMonthCommaYearExtended()}</div>
                    <a href={m.url} target="_blank" className="title">{m.title}</a>
                    <ul><li><a href={m.url} target="_blank" className="source-name">{m.sourceName}</a></li><li className="category">{m.category}</li></ul>
                    <div className="description">{m.content}</div>
                    <a href={m.url} target="_blank" className="read-more">Read More</a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
