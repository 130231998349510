import classNames from 'classnames';
import { renderCode } from 'src/settings/properties';

const rootStyleClass = {
  PRE_LAUNCH: 'property-card-header--pre-launch',
  MARKET_OPEN: 'property-card-header--market-open',
  COMING_SOON: 'property-card-header--coming-soon',
};

const flagStyleClass = {
  PRE_LAUNCH: 'property-card-header__flag-container--pre-launch',
  MARKET_OPEN: 'property-card-header__flag-container--market-open',
  COMING_SOON: 'property-card-header__flag-container--coming-soon',
};

const styles = {
  PRE_LAUNCH: 'PRE_LAUNCH',
  MARKET_OPEN: 'MARKET_OPEN',
  COMING_SOON: 'COMING_SOON',
};

const PropertyCardHeader = ({ property, flag, style = styles.MARKET_OPEN }) => (
  <div className={classNames('property-card-header', rootStyleClass[style])}>
    <div className="property-card-header__left-content">
      <div
        className="property-card-header__property-code"
        data-test-reference="property-code"
      >
        {' '}
        {renderCode(property.propertyCode)}
      </div>
      <div
        className="property-card-header__location"
        data-test-reference="location"
      >
        <span>{property.suburb} </span>
        <span>{property.state}</span>
      </div>
    </div>
    {flag && (
      <div
        className={classNames(
          'property-card-header__flag-container',
          flagStyleClass[style]
        )}
      >
        {flag}
      </div>
    )}
  </div>
);

PropertyCardHeader.styles = styles;

export default PropertyCardHeader;
