import {
  TOGGLE_MODAL,
  CLOSE_MODAL,
} from 'src/store/loginOrSignupModalActions';

const defaultState = {
  modalOpen: false,
  trackingProps: null,
  onSubmit: null
};

export const modalOpenSelector = (state) => ({ modalOpen: state.loginOrSignupModal.modalOpen });
export const modalTrackingPropsSelector = (state) => ({ trackingProps: state.loginOrSignupModal.trackingProps });
export const modalOnSubmitSelector = (state) => ({ onSubmit: state.loginOrSignupModal.onSubmit });

const reducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case TOGGLE_MODAL: return {
      ...state,
      modalOpen: !state.modalOpen,
      ...(payload ? {
        trackingProps: payload.trackingProps,
        onSubmit: payload.onSubmit,
      } : {}),
    };
    case CLOSE_MODAL: return { ...state, ...defaultState };
    default: return state;
  }
};

export default reducer;
