
import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { property as propertyPropType } from 'scripts/constants/PropTypes';

import { priceWithNoDecimalsRounded } from 'scripts/utilities/formatters';
import { isAPreOrderOrComingSoonProperty } from 'scripts/utilities/propertyHelper';


export default class InitialBrickPriceBox extends Component {
  static propTypes = {
    property: propertyPropType.isRequired,
    shownInLargerText: PropTypes.bool.isRequired,
    className: PropTypes.string,
  };

  render() {
    const { property, shownInLargerText, className } = this.props;
    const isPreorderOrComingSoonProperty = isAPreOrderOrComingSoonProperty(property);

    if (!isPreorderOrComingSoonProperty) {
      return null;
    }

    return (
      <div className={classNames('lowest-available-price-box lowest-available-price-box__light-blue-bg', className)}>
        <img src="/static/img/bx-logo-x-only.svg"/>
        Initial Brick Price
        <div className={classNames(shownInLargerText ? 'value larger-text' : 'value')}>
          <div data-test-reference="initial-brick-price">
            {property.financials.initialBrickPrice::priceWithNoDecimalsRounded()}
          </div>
        </div>
      </div>
    );
  }
}
