import { renderCode } from 'src/settings/properties';

export const TILES_META = {
  title: 'BrickX Properties | Property Investment | BrickX',
  description:
    'Develop and diversify your property portfolio with our range of residential investment properties, with prices starting from under $100. Invest Today.',
  meta: {
    charSet: 'utf-8',
    name: {
      keywords: 'property,properties,invest',
    },
  },
};

export const DISTRIBUTIONS_META = (property) => {
  return {
    title: `${renderCode(
      property.propertyCode
    )} Monthly Distributions | BrickX`,
    description: `Find details regarding the historical monthly rental income and distributions for our ${property.address} property, as well as forecast Cashflows and yields.`,
    meta: {
      charSet: 'utf-8',
      name: {
        keywords: 'property,properties,invest',
      },
    },
  };
};

export const RETURNS_META = (property) => {
  return {
    title: `${renderCode(property.propertyCode)} Capital Returns | BrickX`,
    description: `View details regarding Capital Returns for our ${property.address} property, and find historical Brick values, property valuation info and suburb data.`,
    meta: {
      charSet: 'utf-8',
      name: {
        keywords: 'property,properties,invest',
      },
    },
  };
};

export const DETAILS_META = (property) => {
  return {
    title: `${renderCode(property.propertyCode)} Property Details | BrickX`,
    description: `Invest today. Explore our ${property.address} property and read the investment case to see for yourself why our Property team chose this prime location.`,
    meta: {
      charSet: 'utf-8',
      name: {
        keywords: 'property,properties,invest',
      },
    },
  };
};
