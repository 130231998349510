import { useState } from 'react';
import {
  OnboardingScreenHeader,
  OnboardingScreenVerticalCentered,
} from 'src/components/oldOnboarding/common/onboardingScreen/OnboardingScreen';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import Heading from 'src/design/components/heading/Heading';
import {
  BsFillPinMapFill,
  BsFillJournalBookmarkFill,
  BsFillEnvelopeFill,
  BsCreditCard,
} from 'react-icons/bs';
import { IconCheckbox } from 'src/bxDesign/form/IconCheckbox';
import UpdateAddressComponent from 'scripts/components/account/settings/DetailsUpdate/UpdateAddress';
import UpdateMobileNumberComponent from 'scripts/components/account/settings/DetailsUpdate/UpdateMobileNumber';
import UpdateBankDetailsComponent from 'scripts/components/account/settings/DetailsUpdate/UpdateBankDetails';
import UpdateEmailComponent from 'scripts/components/account/settings/DetailsUpdate/UpdateEmail';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import { settingsUrl } from 'src/utils/pageUrls';
import UpdateDetailsMeta from 'src/components/meta/UpdateDetailsMeta';
import styled from 'styled-components';
import _ from 'lodash';

const Layout = styled.div`
  .inner {
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;
    padding: 80px 20px 20px 20px;
    .content {
    }
  }
`;

const DetailsUpdate = ({ location }) => {
  const defaultMode = () => {
    if (location.query.mode) {
      if (_.includes(['0', '1', '2', '3'], location.query.mode)) {
        return Number.parseInt(location.query.mode);
      }
      return 0;
    }
  };

  const defaultStep = () => {
    if (location.query.mode) {
      if (_.includes(['0', '1', '2', '3'], location.query.mode)) {
        return 1;
      }
      return 0;
    }
  };

  const [mode, setMode] = useState(defaultMode());
  const [step, setStep] = useState(defaultStep());

  const MODES = [
    { label: 'Update My Address', icon: <BsFillPinMapFill />, mode: 0 },
    {
      label: 'Update My Mobile Number',
      icon: <BsFillJournalBookmarkFill />,
      mode: 1,
    },
    { label: 'Update My Email', icon: <BsFillEnvelopeFill />, mode: 2 },
    { label: 'Update My Bank Details', icon: <BsCreditCard />, mode: 3 },
  ];

  const onSelectMode = (mode) => {
    setMode(mode);
    setStep(1);
  };

  const onReturnAction = () => setStep(0);

  if (step === 1) {
    if (mode === 0) {
      return (
        <Layout>
          <UpdateAddressComponent onClickBack={onReturnAction} />
        </Layout>
      );
    }
    if (mode === 1) {
      return (
        <Layout>
          <UpdateMobileNumberComponent onClickBack={onReturnAction} />
        </Layout>
      );
    }
    if (mode === 2) {
      return (
        <Layout>
          <UpdateEmailComponent onClickBack={onReturnAction} />
        </Layout>
      );
    }
    if (mode === 3) {
      return (
        <Layout>
          <UpdateBankDetailsComponent onClickBack={onReturnAction} />
        </Layout>
      );
    }
    return (
      <OnboardingScreenVerticalCentered>
        <OnboardingScreenHeader>
          <Spacing bottom="x-large">
            <Heading>Error</Heading>
          </Spacing>
        </OnboardingScreenHeader>
      </OnboardingScreenVerticalCentered>
    );
  }

  return (
    <Layout>
      <UpdateDetailsMeta />
      <div className="inner">
        <div className="title">
          <Spacing bottom="x-large">
            <Heading>Update My Details</Heading>
          </Spacing>
        </div>
        <div className="content">
          <Spacing bottom="normal">
            <div>
              <div className="row">
                {MODES.map((item, idx) => (
                  <Spacing bottom="large" className="col-md-6">
                    <div>
                      <IconCheckbox
                        key={idx}
                        icon={item.icon}
                        text={item.label}
                        isSelected
                        onClick={() => onSelectMode(item.mode)}
                      />
                    </div>
                  </Spacing>
                ))}
              </div>
            </div>
          </Spacing>
          <PrimaryButton fullWidth link={settingsUrl()}>
            CANCEL
          </PrimaryButton>
        </div>
      </div>
    </Layout>
  );
};

export default DetailsUpdate;
