import classNames from 'classnames';
import BulletList from 'src/design/components/bulletList/BulletList';

import styles from 'src/design/components/bulletList/BulletList.mscss';


const TickedBulletList = ({
  className,
  listItemClassName,
  ...props,
}) => {
  return (
    <BulletList
      {...props}
      className={classNames(className, styles.TickedBulletList)}
      listItemClassName={classNames(listItemClassName, styles.TickedBulletListItem)}
    />
  );
};

TickedBulletList.columns = BulletList.columns;

export default TickedBulletList;
