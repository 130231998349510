/* eslint-disable react/sort-comp */

export class ServerError extends Error {

  constructor(body) {
    super();
    this._body = body;
  }

  name = 'ServerError'

  get message() {
    return this._body;
  }
}
