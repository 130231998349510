
import classNames from 'classnames';

const FinancialsBox = ({ iconName, title, value, description, className, descriptionIcon, valueTestRef, descriptionTestRef }) => {
  return (
    <div className={classNames('box-financials', className)}>
      <div className="gray-box">
        <div className="box-financials-title">{ iconName && <span className={classNames('icn icn-small', iconName)}/>}{title}</div>
        <div className="box-financials-value" data-test-reference={valueTestRef}>{value}</div>
        <div className="box-financials-description" data-test-reference={descriptionTestRef}>
          {descriptionIcon && <span className={descriptionIcon}>&nbsp;</span>}
          {description}
        </div>
      </div>
    </div>
  );
};

export default FinancialsBox;
