import styled from 'styled-components';
import { SubHeading } from 'src/design/styleguide/Headings';
import { WelcomePageWidgetPanel } from 'src/components/dashboard/home/commonComponents';
import { breakpointSmall } from 'src/design/styleguide/common/breakpoints';
import { defineGridArea } from 'src/design/styleguide/common/styledComponentsUtils';
import { gridUnit } from 'src/design/styleguide/common/measurements';
import { onboardingSmartInvestDetailsUrl, smartInvestUrl } from 'src/utils/pageUrls';
import Image from 'src/design/components/image/Image';
import Paragraph from 'src/design/styleguide/Paragraph';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import Styledlink from 'src/design/components/hyperlink/Styledlink';

const Container = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: ${gridUnit * 6}px;
  justify-items: center;

  ${breakpointSmall(`
    grid-template-columns: minmax(0, 1fr) auto;
    justify-items: start;
    align-items: start;
    ${defineGridArea('icon-area')}
    ${defineGridArea('button-area')}
    ${defineGridArea('text-area')}
    grid-template-areas:
      'icon-area button-area'
      'text-area text-area'
  `)}
`;

const StyledImage = styled(Image)`
  width: 50px;
`;

const PromoTextArea = styled.div`
  max-width: 500px;

  ${breakpointSmall(`
    max-width: 250px;
  `)}
`;

const PromoTitle = styled(SubHeading).attrs({
  as: 'h3',
  fontSize: '2x-small',
  fontWeight: 'bold',
})`
  ${breakpointSmall(`
    text-align: left;
  `)}
`;

const PromoText = styled(Paragraph).attrs({
  fontSize: '3x-small',
  align: 'center',
})`

  ${breakpointSmall(`
    text-align: left;
  `)}
`;

const SmartInvestPromoPanel = ({ className }) => (
  <WelcomePageWidgetPanel className={className} title="Smart Invest" testRef="smart-invest-promo-panel">
    <Container>
      <StyledImage className="icon-area" src="/static/img/icon-enroll-in-smart-invest.svg" alt="enrol in smart invest icon"/>
      <PromoTextArea className="text-area">
        <PromoTitle>Invest smarter with<br/>Smart Invest</PromoTitle>
        <Spacing top="x-small">
          <PromoText>Set up a monthly direct debit amount and we'll automatically invest your funds into BrickX properties included in the Smart Invest strategy.</PromoText>
        </Spacing>
        <PromoText><Styledlink href={smartInvestUrl()}>Learn more</Styledlink></PromoText>
      </PromoTextArea>
      <PrimaryButton
        className="button-area"
        color={PrimaryButton.colors.SECONDARY}
        size={PrimaryButton.sizes.SMALL}
        link={onboardingSmartInvestDetailsUrl()}
        testRef="smart-invest-promo-panel-cta"
      >
        Set Up Now
      </PrimaryButton>
    </Container>
  </WelcomePageWidgetPanel>
);

export default SmartInvestPromoPanel;
