import request from 'scripts/services/request';
import { maintenanceStatus } from 'scripts/services/api';


class SystemsService {
  getMaintenanceStatus() {
    return request(maintenanceStatus);
  }
}

export default new SystemsService();
