import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';

import { logout } from 'scripts/redux/actions/user';

import Configuration from 'scripts/constants/Configuration';

@connect(null, { logout, replace })
export default class LogoutAndSignupSmsf extends Component {
  static propTypes = {
    logout: PropTypes.func,
    replace: PropTypes.func,
  };

  UNSAFE_componentWillMount() {
    if (CLIENT) {
      this.props.logout();
      this.props.replace(Configuration.PAGE_URLS.ONBOARDING.SIGNUPSMSF);
    }
  }

  render() {
    return null;
  }
}
