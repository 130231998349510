import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Heading, HeadingSmall } from 'src/design/components/heading/Headings';
import { showModal as showModalAction } from 'src/components/modals/modalActions';
import { sqmReportDirectUrl } from 'src/utils/pageUrls';
import { trackButtonClick } from 'src/components/landingPages/smsf/tracking';
import FinePrint from 'src/design/components/bodyText/FinePrint';
import FormCheckbox from 'src/design/components/formCheckbox/FormCheckbox';
import PageMarginContainer from 'src/design/components/pageMarginContainer/PageMarginContainer';
import Paragraph from 'src/design/components/bodyText/Paragraph';
import PinkAllCapsSubTitle from 'src/components/landingPages/common/pinkAllCapsSubtitle/PinkAllCapsSubTitle';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import StyledBodylink from 'src/design/components/hyperlink/StyledBodylink';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import styles from 'src/components/landingPages/smsf/sqmResearchRatingSection/SqmResearchRatingSection.mscss';
import withState from 'src/decorators/withState';

const ModalContent = withState({
  Component: ({ state, setState, closeThisModal }) => {
    const { buttonClickedOnce, userIsFinancialAdviser } = state;

    const onFinancialAdviserCheckboxChange = () => {
      setState({
        userIsFinancialAdviser: !userIsFinancialAdviser,
        buttonClickedOnce: true,
      });
    };

    const onDownloadReportDisabledButtonClick = () => {
      setState({ buttonClickedOnce: true });
    };

    return (
      <div
        className={styles['sqmResearchRatingSection--modal']}
        data-test-reference="sqm-research-modal-content"
      >
        <HeadingSmall align="center"><h3>Download SQM Research on BrickX Trusts*</h3></HeadingSmall>
        <Spacing top="large" bottom="normal">
          <Paragraph align="center">
            Fund Rating Disclaimer: any rating is only one factor to consider when deciding to invest.
          </Paragraph>
        </Spacing>
        <FinePrint align="center">*SQM Research is only available to professional financial advisers.</FinePrint>

        <Spacing top="x-large">
          <div className={styles['sqmResearchRatingSection--modal--greyArea']}>
            <img
              src="/static/img/logo-sqm.gif"
              className={styles['sqmResearchRatingSection--modal--sqmLogo']}
            />

            <div className={styles['sqmResearchRatingSection--modal--greyArea--content']}>
              {buttonClickedOnce && !userIsFinancialAdviser
                ? (
                  <Spacing bottom="normal">
                    <Paragraph
                      className={styles['sqmResearchRatingSection--modal--notFinancialAdviserErrorText']}
                      testRef="not-an-adviser-warning"
                    >
                      If you are not a Financial Adviser you are not eligible to download this report
                    </Paragraph>
                  </Spacing>
                )
                : null}

              <FormCheckbox
                onChange={onFinancialAdviserCheckboxChange}
                testRef="financial-adviser-checkbox"
              >
                I'm a Financial Adviser
              </FormCheckbox>
            </div>
          </div>
        </Spacing>

        <Spacing top="x-large" bottom="large">
          <PrimaryButton
            testRef="download-sqm-report-button"
            link={sqmReportDirectUrl()}
            target="_blank"
            disabled={!userIsFinancialAdviser}
            onClick={() => {
              if (userIsFinancialAdviser) {
                onDownloadReportDisabledButtonClick();
                trackButtonClick({ from: 'SQM Research Section Modal', button: 'Download SQM Report' });
              }
            }}
            color={PrimaryButton.colors.SECONDARY}
          >
            Download Report
          </PrimaryButton>
        </Spacing>
        <StyledBodylink
          charcoal
          fullWidth
          onClick={() => closeThisModal()}
        >Cancel</StyledBodylink>
      </div>
    );
  },
  mapPropsToInitialState: () => ({
    userIsFinancialAdviser: false,
    buttonClickedOnce: false,
  }),
});


@connect(null, {
  showModal: showModalAction,
})
export default class SqmResearchRating extends Component {
  static propTypes = {
    showModal: PropTypes.func,
  };

  onDownloadReportClick = () => {
    this.props.showModal({
      content: <ModalContent />,
    });
  }

  render() {
    return (
      <PageMarginContainer
        className={styles.sqmResearchRatingSection}
        innerContainerClassName={styles['sqmResearchRatingSection--inner']}
        size="normal"
      >
        <img
          className={classNames(styles['sqmResearchRatingSection--img'])}
          src="/static/img/logo-sqm.gif"
          alt="SQM research Superior rating badge"
        />

        <div className={styles['sqmResearchRatingSection--textContent']}>
          <PinkAllCapsSubTitle>SQM RESEARCH</PinkAllCapsSubTitle>
          <Spacing top="2x-small" bottom="large">
            <Heading
              className={styles['sqmResearchRatingSection--textContent--title']}
            >
              <h2>4 Star Superior Rating* <em>for BrickX Trusts</em></h2>
            </Heading>
          </Spacing>

          <FinePrint
            className={styles['sqmResearchRatingSection--textContent--finePrint']}
          >*Superior Rating: 4 stars to 4 1/2 stars indicates suitable for inclusion on most APLs. The fund outperforms (or is likely to) its peers and benchmark the majority of the time. The fund most of the time has been operating within its mandate and PDS. There are very little to no corporate governance concerns. Management is of a very high calibre.</FinePrint>

          <FinePrint
            className={styles['sqmResearchRatingSection--textContent--finePrint']}
          >The full report is only available for download by financial advisers.</FinePrint>

          <Spacing top="large" bottom="x-large">
            <PrimaryButton
              onClick={this.onDownloadReportClick}
              testRef="download-report-button"
              color={PrimaryButton.colors.BLUE}
            >Read the Report</PrimaryButton>
          </Spacing>
        </div>

        <FinePrint className={styles['sqmResearchRatingSection--disclaimer']}>
          The rating contained in this document is issued by SQM Research Pty Ltd ABN 93 122 592 036. SQM Research is an investment research firm that undertakes research on investment products exclusively for its wholesale clients, utilising a proprietary review and star rating system. The SQM Research star rating system is of a general nature and does not take into account the particular circumstances or needs of any specific person. The rating may be subject to change at any time. Only licensed financial advisers may use the SQM Research star rating system in determining whether an investment is appropriate to a person’s particular circumstances or needs. You should read the product disclosure statement and consult a licensed financial adviser before making an investment decision in relation to this investment product. SQM Research receives a fee from the Fund Manager for the research and rating of the managed investment scheme.
        </FinePrint>
      </PageMarginContainer>
    );
  }
}
