import { Fragment } from 'react';
import styled from 'styled-components';
import { TOTAL_ROI_POPOVER_TEXT } from 'src/components/dashboard/portfolio/helpText';
import { TOTAL_VALUE_HELP_TEXT } from 'src/components/dashboard/home/helpText';
import {
  breakpointMedium,
  breakpointSmall,
  breakpointLarge,
} from 'src/design/styleguide/common/breakpoints';
import { dollarDecimal, floatToPercentDecimal } from 'src/utils/formats';
import { getThemeColor } from 'src/themes/themeUtils';
import {
  gridGap,
  specialGradient,
} from 'src/components/dashboard/common/styles';
import { gridUnit } from 'src/design/styleguide/common/measurements';
import Popover from 'scripts/components/shared/Popover';
import Text from 'src/design/styleguide/text/Text';
import UpDownNumber from 'src/components/upDownNumber/UpDownNumber';
import {
  isMinorAccount,
  isSMSForTrustAccount,
} from 'scripts/utilities/userAccountHelper';

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: ${gridGap};

  ${breakpointSmall(`
    grid-template-columns: repeat(1, 1fr);
  `)}

  ${breakpointMedium(`
    grid-template-columns: repeat(2, 1fr);
  `)}

  ${breakpointLarge(`
    grid-template-columns: repeat(3, 1fr);
  `)}
`;

const StatBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${gridUnit * 4}px;
  border-radius: var(--bx-ms---widget-border-radius);
  background-color: var(--bx-color---surface);
  box-shadow: var(--bx-emb-shadow);

  ${breakpointSmall(`
    justify-content: space-between;
  `)}
`;

const Title = styled(Text).attrs({
  fontSize: '2x-small',
  fontWeight: 'medium',
})`
  text-align: center;

  ${breakpointSmall(`
    text-align: left;
  `)}
`;

const Value = styled(Text).attrs({
  fontSize: 'large',
  fontWeight: 'bold',
})`
  display: flex;
  justify-content: center;
  word-break: break-all;

  ${breakpointSmall(`
    justify-content: flex-start;
  `)}
`;

const StatBoxLayout = ({
  className,
  title,
  value,
  titleTestRef,
  valueTestRef,
}) => (
  <StatBoxContainer className={className}>
    <Title testRef={titleTestRef}>{title}</Title>
    <Value testRef={valueTestRef}>{value}</Value>
  </StatBoxContainer>
);

const PrimaryStatBoxLayout = styled(StatBoxLayout)`
  background: ${specialGradient};
  color: ${getThemeColor('onDarkSurfaceContrast')};

  ${breakpointSmall(`
    grid-column-end: span 2;
  `)}

  ${breakpointMedium(`
    grid-column-end: span 1;
  `)}
`;

const PrimaryStatBoxLayoutForMinor = styled(PrimaryStatBoxLayout)`
  background: var(--bx-color---gradient-yolk);
`;

const PrimaryStatBoxLayoutForSmsfAndTrust = styled(PrimaryStatBoxLayout)`
  background: var(--bx-color---gradient-blue);
`;

const renderCustomStatBox = (user, totalAccountsValue) => {
  if (isMinorAccount(user)) {
    return (
      <PrimaryStatBoxLayoutForMinor
        title={
          <Fragment>
            Total Value{' '}
            <Popover
              color="onDarkSurfaceContrast"
              placement="top"
              content={TOTAL_VALUE_HELP_TEXT}
            />
          </Fragment>
        }
        value={dollarDecimal(totalAccountsValue)}
        valueTestRef="total-value"
      />
    );
  }
  if (isSMSForTrustAccount(user)) {
    return (
      <PrimaryStatBoxLayoutForSmsfAndTrust
        title={
          <Fragment>
            Total Value{' '}
            <Popover
              color="onDarkSurfaceContrast"
              placement="top"
              content={TOTAL_VALUE_HELP_TEXT}
            />
          </Fragment>
        }
        value={dollarDecimal(totalAccountsValue)}
        valueTestRef="total-value"
      />
    );
  }
  return (
    <PrimaryStatBoxLayout
      title={
        <Fragment>
          Total Value{' '}
          <Popover
            color="onDarkSurfaceContrast"
            placement="top"
            content={TOTAL_VALUE_HELP_TEXT}
          />
        </Fragment>
      }
      value={dollarDecimal(totalAccountsValue)}
      valueTestRef="total-value"
    />
  );
};

const AccountSummary = ({
  className,
  account: {
    totalAccountsValue,
    totalBricksOwned,
    aggregatedPortfolio: { totalRoi },
  },
  user,
}) => (
  <Container className={className}>
    {renderCustomStatBox(user, totalAccountsValue)}
    <StatBoxLayout
      title="Total Number of Bricks"
      value={totalBricksOwned}
      valueTestRef="total-bricks"
    />
    <StatBoxLayout
      title={
        <Fragment>
          Total ROI <Popover placement="top" content={TOTAL_ROI_POPOVER_TEXT} />
        </Fragment>
      }
      value={
        <UpDownNumber value={totalRoi} noSymbolZero>
          {floatToPercentDecimal(totalRoi)}
        </UpDownNumber>
      }
      valueTestRef="total-roi"
    />
  </Container>
);

export default AccountSummary;
