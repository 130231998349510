import React, { Component } from 'react';
import PropTypes from 'prop-types';

import PropertyReturnsCalculatorReturnsEstimation from 'scripts/components/property/calculator/PropertyReturnsCalculatorReturnsEstimation';

import { percentDecimal, dollar } from 'scripts/utilities/formatters';


export default class PropertyReturnsCalculatorResultOverview extends Component {
  static propTypes = {
    investmentAmount: PropTypes.number.isRequired,
    investmentPeriod: PropTypes.number.isRequired,
    annualGrowthRate: PropTypes.number.isRequired,
    debtRate: PropTypes.number.isRequired,
    netRentalYieldRate: PropTypes.number.isRequired,
    initialPercentOfPropertyAsset: PropTypes.number.isRequired,
    initialPercentOfAcquisitionCost: PropTypes.number.isRequired,
    initialPercentOfCashReserve: PropTypes.number.isRequired,
    remainingMonthsOfAmortising: PropTypes.number,
  };

  render() {
    const {
      investmentPeriod,
      investmentAmount,
      annualGrowthRate,
      debtRate,
      netRentalYieldRate,
      initialPercentOfPropertyAsset,
      initialPercentOfAcquisitionCost,
      initialPercentOfCashReserve,
      remainingMonthsOfAmortising
    } = this.props;

    return (
      <div className="return-calculator-results-overview">
        <div className="return-calculator-results-overview__left">
          <div className="return-calculator-results-overview__main-title">Your Inputs</div>
          <div className="return-calculator-results-overview__capital-return-rate">
            <div className="return-calculator-results-overview__title">Change in Property Value p.a.:</div>
            <div className="return-calculator-results-overview__value">{annualGrowthRate::percentDecimal()}</div>
          </div>
          <div className="return-calculator-results-overview__investment-amount">
            <div className="return-calculator-results-overview__title">I would like to invest:</div>
            <div className="return-calculator-results-overview__value">{investmentAmount::dollar()}</div>
          </div>
          <div className="return-calculator-results-overview__investment-period">
            <div className="return-calculator-results-overview__title">Holding Period:</div>
            <div className="return-calculator-results-overview__value">{investmentPeriod} {investmentPeriod > 1 ? 'Years' : 'Year'}</div>
          </div>
        </div>
        <div className="return-calculator-results-overview__right">
          <PropertyReturnsCalculatorReturnsEstimation
            investmentPeriod={investmentPeriod}
            investmentAmount={investmentAmount}
            annualGrowthRate={annualGrowthRate}
            debtRate={debtRate}
            netRentalYieldRate={netRentalYieldRate}
            initialPercentOfPropertyAsset={initialPercentOfPropertyAsset}
            initialPercentOfAcquisitionCost={initialPercentOfAcquisitionCost}
            initialPercentOfCashReserve={initialPercentOfCashReserve}
            remainingMonthsOfAmortising={remainingMonthsOfAmortising}
          />
        </div>
      </div>
    );
  }
}
