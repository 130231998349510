import { Children, cloneElement, isValidElement } from 'react';
import classNames from 'classnames';

const ClonedChild = ({ className, children, ...props }) =>
  isValidElement(children)
  ? (
    cloneElement(Children.only(children), {
      className: classNames(children.props.className, className),
      ...props,
    })
  )
  : null;

export default ClonedChild;
