import { trackDirectDebitDayOfMonthSelected } from 'src/tracking/onboarding';
import DayOfMonthSelect from 'src/design/components/formSelect/DayOfMonthSelect';
import FormLabel from 'src/design/components/formLabel/FormLabel';
import Heading from 'src/design/components/heading/Heading';
import HelpDisclaimer from 'src/design/components/helpDisclaimer/HelpDisclaimer';
import OnboardingBackButton from 'src/components/oldOnboarding/common/onboardingBackButton/OnboardingBackButton';
import OnboardingNextButton from 'src/components/oldOnboarding/common/onboardingNextButton/OnboardingNextButton';
import OnboardingScreen, {
  OnboardingScreenHeader,
  OnboardingScreenContent,
} from 'src/components/oldOnboarding/common/onboardingScreen/OnboardingScreen';
import Paragraph from 'src/design/components/bodyText/Paragraph';
import Spacing from 'src/design/styleguide/spacing/Spacing';

const SmartInvestDayOfMonthInput = ({ onChange, value, onNext, onBack }) => (
  <OnboardingScreen>
    <OnboardingScreenHeader>
      <Spacing bottom="large">
        <Heading>What day of the month works best for you?</Heading>
      </Spacing>
      <Spacing bottom="large">
        <Paragraph className="onboarding-screen__form-element--margin-24">This will be the day that BrickX will direct debit your account.</Paragraph>
      </Spacing>
    </OnboardingScreenHeader>
    <OnboardingScreenContent>
      <FormLabel>Day of month to direct debit</FormLabel>
      <DayOfMonthSelect
        className="onboarding-screen__form-element--margin-8"
        onChange={onChange}
        value={value}
        fullWidth
        defaultValueText="Please choose"
        testRef="day-of-month-input"
      />
      <Spacing top="normal">
        <HelpDisclaimer>If the selected date does not exist, falls on a weekend or public holiday, it will be debited on the next business day.</HelpDisclaimer>
      </Spacing>
      <OnboardingNextButton onClick={() => {
        trackDirectDebitDayOfMonthSelected(value);
        onNext();
      }} disabled={!value}/>
      <OnboardingBackButton onClick={onBack}/>
    </OnboardingScreenContent>
  </OnboardingScreen>
);

export default SmartInvestDayOfMonthInput;
