import styled from 'styled-components';
import Paragraph from 'src/design/styleguide/Paragraph';
import RawHtml from 'src/components/rawHtml/RawHtml';
import { BsInfoCircle } from 'react-icons/bs';
import theme from 'src/themes/brickx/brickxTheme';

const Container = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 24px;
  background-color: ${theme.colors.blue50};
  border: 2px solid ${theme.colors.blue500};
  border-radius: var(--bx-radius);
  margin-bottom: 12px;
  box-shadow: var(--bx-shadow);
  padding: 24px;
  .icon {
    color: ${theme.colors.blue500};
    font-size: 3rem;
    min-width: 30px;
  }
`;

const DashboardAlertPanel = ({ className, dashboardAlertText }) => {
  const alertSections = dashboardAlertText.split('<-- sep -->');
  return (
    <div className={className} data-test-reference="dashboard-alert-panel">
      {alertSections.map((sec, idx) => (
        <Container key={idx}>
          <BsInfoCircle className="icon" />
          <Paragraph fontSize="3x-small">
            <RawHtml>{sec}</RawHtml>
          </Paragraph>
        </Container>
      ))}
    </div>
  );
};

export default DashboardAlertPanel;
