import styled from 'styled-components';
import Image from 'src/design/components/image/Image';
import Paragraph from 'src/design/styleguide/Paragraph';
import Spacing from 'src/design/styleguide/spacing/Spacing';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Illustration = styled(Image)`
  max-width: 275px;
`;

export default () => (
  <Container>
    <Spacing top="5x-large">
      <Illustration src="/static/img/illustration-girl-holding-magnifying-glass-on-paper.svg"/>
    </Spacing>
    <Spacing top="x-large" bottom="5x-large">
      <Paragraph>You do not have any transactions yet.</Paragraph>
    </Spacing>
  </Container>
  );
