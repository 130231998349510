import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { user as userPropType } from 'scripts/constants/PropTypes';
import { sendPopupLoginSubmitEvent } from 'scripts/redux/actions/segment/events/loginOrSignupPopupEvents';
import LoginForm from 'scripts/components/auth/LoginForm';
import ReactModal from 'scripts/components/shared/ReactModal/ReactModal';
import {
  modalOpenSelector,
  modalTrackingPropsSelector,
  modalOnSubmitSelector,
} from 'src/store/loginOrSignupModalReducer';
import { closeModal } from 'src/store/loginOrSignupModalActions';
import { isUserLoggedIn } from 'scripts/utilities/userAccountHelper';
import history from 'src/browser/history';
import { signupUrl } from 'src/utils/pageUrls';
import styled from 'styled-components';
import { BrickxLogoDark } from 'src/components/brickxLogo/BrickxLogo';
import { MdClose } from 'react-icons/md';

const mapStateToProps = (state) => ({
  ...modalOpenSelector(state),
  ...modalTrackingPropsSelector(state),
  ...modalOnSubmitSelector(state),
});

const Layout = styled.div`
  display: flex;
  justify-content: center;
  animation: moveUpAndFadeIn 0.2s;

  .onboarding-popup-content {
    color: #333;
    background-color: #fff;
    border-radius: var(--bx-radius-lg);
    box-shadow: var(--bx-emb-shadow-deep);
    overflow: hidden;
    padding: 35px 50px 35px;
    min-width: 425px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 425px) {
      padding: 35px 30px !important;
      min-width: 100%;
    }

    form#login-form {
      width: 100%;
    }

    button {
      width: 100%;
      margin-bottom: 20px;
      text-transform: uppercase;
    }

    .forgot-link {
      text-align: center;
      margin-bottom: 10px;
    }

    .title {
      width: 100%;
      margin: 40px 0 0;
      h2 {
        color: #444;
      }
    }

    a {
      text-decoration: underline;
    }

    .switch {
      text-align: center;
      a {
        cursor: pointer;
      }
    }

    @media (max-width: $mobile-large) {
      padding: 20px 0;
    }

    .benefit {
      position: relative;
      padding-left: 22px;
      font-size: 12px;
      margin-top: 5px;
    }

    .signup-form {
      padding: 0;
      margin-top: 20px;
    }

    .close-button {
      margin-top: 30px;
    }
  }
`;

@connect(mapStateToProps, {
  closeModal,
})
export default class LoginOrSignupPopup extends Component {
  static propTypes = {
    user: userPropType,
    modalOpen: PropTypes.bool,
    trackingProps: PropTypes.object,
    closeModal: PropTypes.func,
    onSubmit: PropTypes.func,
  };

  render() {
    const { user, modalOpen, trackingProps } = this.props;
    if (isUserLoggedIn(user)) {
      this.closeModal();
    }
    return (
      <ReactModal isOpen={modalOpen} lightBackground hideCloseBtn>
        <Layout data-test-reference="signup-or-login-popup">
          <div className="onboarding-popup-content">
            <BrickxLogoDark forceExternal />
            <div className="title"><h3>Login</h3></div>
            <LoginForm onLoginSuccess={() => { sendPopupLoginSubmitEvent(trackingProps); this.closeModal(); }} />
            <div className="switch">
              Don't have an Account? <a data-test-reference="toggle-form-to-signup" onClick={this.onClickSignup}>Join now</a>
            </div>
            <div className="close-button circle-button" onClick={this.closeModal}> <MdClose /> </div>
          </div>
        </Layout>
      </ReactModal>
    );
  }

  closeModal = () => this.props.closeModal();

  onClickSignup = () => {
    // NOTE: Update from Claude Chen <claude@brickx.com> 27 Oct 2021
    //       We now redirect all signup event to /signup page
    history.push(signupUrl());
    this.closeModal();
  };
}
