import { Component } from 'react';
import { connect } from 'react-redux';
import DocumentMeta from 'react-document-meta';
import PropTypes from 'prop-types';
import { DOCUMENTS_META } from 'scripts/metadata/account';
import { detailedTransactionsSelector } from 'scripts/redux/selectors/transactions';
import { fetchDetailedTransactions as fetchDetailedTransactionsAction } from 'scripts/redux/actions/transactions';
import { userSelector } from 'scripts/redux/selectors/user';
import { getAllAnnualStatementLinkDetails } from 'src/utils/transactions';
import { openUrlInNewWindow } from 'scripts/utilities/browser';
import {
  distributionStatementUrl,
  tradeConfirmationDocumentsUrl,
} from 'src/utils/pageUrls';
import Loading from 'src/components/loading/Loading';
import WidgetPanel from 'src/components/dashboard/common/WidgetPanel';
import withDdrAgreementUrl from 'src/decorators/withDdrAgreementUrl';
import styled from 'styled-components';
import { BsArchive, BsChevronRight, BsFileEarmarkText } from 'react-icons/bs';
import { portfolioStatementDocument } from 'scripts/services/api';
import history from 'src/browser/history';
import Colors from 'src/themes/brickx/colors';
import {
  TopDocumentContainer,
  TopDocumentsContainer,
} from 'scripts/components/account/documents/Shared';

const DocumentRowContainer = styled.div`
  padding: var(--bx-ms---grid-unit-5);
  margin-top: var(--bx-ms---grid-unit-3);
  border-radius: var(--bx-radius);
  border: 2px solid ${Colors.grey400};
  &:hover {
    background-color: ${Colors.backgroundLightGrey};
    cursor: pointer;
  }
  .title {
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 2rem;
  }
  .flexBox {
    display: flex;
    align-items: center;
    grid-gap: var(--bx-ms---grid-unit-4);
  }
  .icon {
    font-size: 2.5rem;
    color: var(--bx-color---primary);
    transform: translateY(4px);
  }
`;

const DocumentRow = ({ title, subTitle, link }) => {
  return (
    <DocumentRowContainer onClick={() => history.push(link)}>
      <div className="flexBox">
        <div className="icon">
          <BsArchive />
        </div>
        <div>
          <div className="title">{title}</div>
          <div className="subTitle">{subTitle}</div>
        </div>
      </div>
    </DocumentRowContainer>
  );
};

const DddrAgreement = withDdrAgreementUrl(({ ddrAgreementUrl }) =>
  !ddrAgreementUrl ? null : (
    <TopDocumentContainer
      title="Direct Debit Agreement"
      subTitle="View a copy of your direct debit agreement including bank details and the direct debit amount."
      onClick={() => openUrlInNewWindow(ddrAgreementUrl)}
      onClickTestRef="direct-debit-agreement-link"
      icon={<BsFileEarmarkText />}
      hoverIcon
    />
  )
);

const MyTradeConfirmation = () => (
  <TopDocumentContainer
    title="My Transaction Confirmations"
    subTitle="View all of your transactions, both buy and sell. Download your
Transaction Confirmation certificates as a PDF."
    onClick={() => openUrlInNewWindow(tradeConfirmationDocumentsUrl())}
    icon={<BsFileEarmarkText />}
    hoverIcon={<BsChevronRight />}
  />
);

const PortfolioStatement = ({ userId }) => (
  <TopDocumentContainer
    title="Portfolio Statement"
    subTitle="The portfolio statement includes a total account summary along with Build My Own and Smart Invest portfolio breakdowns."
    onClick={() => openUrlInNewWindow(portfolioStatementDocument(userId))}
    icon={<BsFileEarmarkText />}
    isNew
    hoverIcon
  />
);

const DistributionReport = () => (
  <TopDocumentContainer
    title="Distribution Statement"
    subTitle="This statement includes all rental income earned on your brick
    holdings, including the distribution amount, payment date and
    withholding tax amount."
    onClick={() => openUrlInNewWindow(distributionStatementUrl())}
    icon={<BsFileEarmarkText />}
    isNew
    hoverIcon={<BsChevronRight />}
  />
);

const mapStateToProps = (state) => ({
  ...userSelector(state),
  ...detailedTransactionsSelector(state),
});
@connect(mapStateToProps, {
  fetchDetailedTransactions: fetchDetailedTransactionsAction,
})
export default class MyDocuments extends Component {
  static propTypes = {
    detailedTransactions: PropTypes.array,
    fetchDetailedTransactions: PropTypes.func,
  };

  componentDidMount() {
    this.props.fetchDetailedTransactions();
  }

  render() {
    const { detailedTransactions, user } = this.props;

    if (!detailedTransactions) return <Loading />;

    return (
      <WidgetPanel title="Documents">
        <DocumentMeta {...DOCUMENTS_META} />
        <TopDocumentsContainer>
          <MyTradeConfirmation />
          <PortfolioStatement userId={user.id} />
          <DistributionReport />
          <DddrAgreement />
        </TopDocumentsContainer>

        {getAllAnnualStatementLinkDetails(detailedTransactions).map(
          ({ title, link, description }) => (
            <DocumentRow
              key={title}
              title={title}
              subTitle={description}
              link={link}
            />
          )
        )}
      </WidgetPanel>
    );
  }
}
