
import { Component } from 'react';

import { account as accountPropType } from 'scripts/constants/PropTypes';

import { dollarDecimal } from 'scripts/utilities/formatters';


export default class FundsAvailableBar extends Component {
  static propTypes = {
    account: accountPropType,
  };

  render() {
    const { account } = this.props;

    return (
      <div className="funds-available-bar wrap-the-col">
        <div className="col-md-7 col-xs-7 col-no-padding funds-available-title">
          <div className="left-side-icon">
            <img src="/static/images/icons/icn_note.png"/>
            <b>Funds available</b>
          </div>
        </div>
        <div className="col-md-5 col-xs-5 col-no-padding funds-available-value">
          {account ? account.availableToTradeBalance::dollarDecimal() : '$__.00'}
        </div>
        <div className="visible-xs">
          <br/>
        </div>
      </div>
    );
  }
}
