import styled from 'styled-components';
import { getThemeColor } from 'src/themes/themeUtils';
import { gridUnit } from 'src/design/styleguide/common/measurements';
import { testRefToDataTestReference } from 'src/design/styleguide/common/styledComponentsUtils';
import Icon from 'src/design/components/icon/Icon';

const Container = styled.div.attrs(testRefToDataTestReference)`
  display: inline-block;
  position: relative;
`;

const StyledSelect = styled.select.attrs(testRefToDataTestReference)`
  height: auto;
  width: 100%;
  min-width: 65px;
  border: 1px solid ${getThemeColor('concrete')};
  border-radius: var(--bx-radius);
  padding: 0.25em 1em 0.25em 0.5em;
  font-weight: inherit;
  background: ${getThemeColor('white')};

  &:disabled {
    color: ${getThemeColor('grey400')};
  }
`;

const ArrowIconContainer = styled.div.attrs(testRefToDataTestReference)`
  color: ${getThemeColor('grey500')};
  display: flex;
  font-size: 20px;
  height: 100%;
  padding-right: ${gridUnit}px;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
`;

const StyledIcon = styled(Icon)`
  margin: auto;
`;

const Select = ({
  value,
  onChange,
  children,
  className,
  arrowClassName,
  selectClassName,
  testRef,
  ...otherProps
}) => (
  <Container className={className} testRef="select-container">
    <StyledSelect
      className={selectClassName}
      value={value}
      onChange={onChange}
      testRef={testRef}
      {...otherProps}
    >
      {children}
    </StyledSelect>
    <ArrowIconContainer className={arrowClassName} testRef="arrow">
      <StyledIcon type={Icon.types.caret_down} />
    </ArrowIconContainer>
  </Container>
);

export default Select;
