import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchLatestStats as fetchLatestStatsAction } from 'scripts/redux/actions/statistics';
import { latestStatsSelector } from 'scripts/redux/selectors/statistics';
// import { millSecondsToMinutesAndHours } from "scripts/utilities/formatters";

@connect(latestStatsSelector, { fetchLatestStats: fetchLatestStatsAction })
class MedianBrickSaleTime extends Component {
  static propTypes = {
    fetchLatestStats: PropTypes.func,
    latestStats: PropTypes.shape({
      medianSaleTimeMills: PropTypes.number,
    }),
  };

  componentDidMount() {
    this.props.fetchLatestStats();
  }

  render() {
    // const { latestStats } = this.props;
    // if (!latestStats) return <span>-h -m</span>;
    // return <span>{latestStats.medianSaleTimeMills::millSecondsToMinutesAndHours()}</span>;
    return <span>25h 02m</span>;
  }
}

export default MedianBrickSaleTime;
