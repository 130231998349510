import { slugToTitle } from 'src/utils/formats';
import FormSelect from 'src/design/components/formSelect/FormSelect';
import RadioSelect from 'src/design/components/radioSelect/RadioSelect';
import TextInput from 'src/design/components/textInput/TextInput';
import generateFlowFieldErrorText from 'src/components/flows/common/generateFlowFieldErrorText';
import generateTextFieldValidationProps from
  'src/components/flows/common/generateTextFieldValidationProps';
import withError from 'src/design/components/textInput/withError';
import withLabel from 'src/design/components/textInput/withLabel';

const FormTextField = withLabel(withError(({ validations, ...props }) => {
  return (
    <TextInput
      {...props}
      {...generateTextFieldValidationProps(validations, props)}
    />
  );
}));

const FormPasswordField = ({ ...props }) => <FormTextField {...props} type="password"/>;

const FormNumberField = ({ onChange, ...props }) => (
  <FormTextField
    type="number"
    onChange={(value) => onChange(parseInt(value, 10))}
    isCharacterValid={TextInput.validation.numbersOnly}
    {...props}
  />
);

const FormBooleanRadioSelect = withLabel(withError(({ onChange, ...props }) => (
  <RadioSelect
    onChange={(val) => onChange(val === 'true')}
    options={[
      { value: true, labelText: 'Yes' },
      { value: false, labelText: 'No' },
    ]}
    {...props}
  />
)));

const FormSelectWithLabelAndError = withLabel(withError(FormSelect));

const FormEnumSelect = ({ validations, ...props }) => (
  <FormSelectWithLabelAndError {...props}>
    <option value="">Please Select</option>
    {
      validations
      .find(({ rule }) => rule === 'value-in')
      .in.map((value) => (
        <option key={value} value={value}>{value}</option>
      ))
    }
  </FormSelectWithLabelAndError>
);

const getInputComponentForFieldType = (fieldType) => {
  switch (fieldType) {
    case 'string': return FormTextField;
    case 'password': return FormPasswordField;
    case 'boolean': return FormBooleanRadioSelect;
    case 'int': return FormNumberField;
    case 'enum': return FormEnumSelect;
    default: return FormTextField;
  }
};

const FormInput = ({ field, label, placeholderLabel, ...props }) => {
  const InputComponent = getInputComponentForFieldType(field.type);

  const labelText = label || slugToTitle(field.id);

  const error = field.error || (
    field.serverErrors.length
    ? generateFlowFieldErrorText(field.serverErrors)
    : false
  );

  return (
    <InputComponent
      name={field.id}
      validations={field.validations}
      error={error}
      value={field.value}
      label={labelText}
      placeholderLabel={placeholderLabel}
      testRef={`${field.id}-field`}
      labelTestRef={`${field.id}-label`}
      errorTestRef={`${field.id}-error`}
      {...props}
    />
  );
};

export default FormInput;
