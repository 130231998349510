import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from 'src/design/components/selectList/SelectList.mscss';
import textInputStyles from 'src/design/components/textInput/TextInput.mscss';

const SelectListItem = React.forwardRef(({ onClick, value, highlighted, testRef, cypressTestRef }, ref) => (
  <li
    className={classNames(styles['selectList--item'],
      textInputStyles.textInput__font,
      textInputStyles.textInput__colors,
      textInputStyles.textInput__spacing,
      { [styles['selectList--item-highlighted']]: highlighted },
    )}
    onClick={onClick}
    ref={ref}
    data-test-reference={testRef}
    data-cypress-test-reference={cypressTestRef}
  >
    {value}
  </li>
));

export default class SelectList extends Component {
  static propTypes = {
    onItemSelect: PropTypes.func,
    items: PropTypes.array,
    testRef: PropTypes.string,
    itemTestRef: PropTypes.string,
    customFirstItemTestRef: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.itemElements = [];
  }

  state = {
    keyboardHighlightedItem: -1,
  };

  componentDidUpdate() {
    this.scrollSelectedItemIntoViewIfNecessary();
  }

  scrollSelectedItemIntoViewIfNecessary = () => {
    const element = this.itemElements[this.state.keyboardHighlightedItem];
    const list = this.listContainerElement;
    if (!element || !list) return;

    const topOfScroll = list.scrollTop;
    const bottomOfScroll = list.scrollTop + list.offsetHeight;

    const topOfElement = element.offsetTop;
    const bottomOfElement = element.offsetTop + element.offsetHeight;

    if (topOfElement < topOfScroll) list.scrollTop = element.offsetTop;
    if (bottomOfElement > bottomOfScroll) list.scrollTop = element.offsetTop + element.offsetHeight - list.offsetHeight;
  }

  handleUpDownNavigation = (e) => {
    const { onItemSelect } = this.props;
    const { keyboardHighlightedItem } = this.state;

    if (['ArrowUp', 'ArrowDown', 'Enter', ' '].includes(e.key)) { e.preventDefault(); }

    switch (e.key) {
      case 'ArrowUp': return this.highlightPreviousItem();
      case 'ArrowDown': return this.highlightNextItem();
      case 'Enter':
      case ' ' :
        return onItemSelect(keyboardHighlightedItem);
      default:
    }
  }

  highlightFirstItem = () => this.setState({ keyboardHighlightedItem: 0 });

  highlightPreviousItem = () => this.state.keyboardHighlightedItem > 0 && this.setState({ keyboardHighlightedItem: this.state.keyboardHighlightedItem - 1 });

  highlightNextItem = () => this.state.keyboardHighlightedItem < (this.props.items && this.props.items.length - 1) && this.setState({ keyboardHighlightedItem: this.state.keyboardHighlightedItem + 1 });

  unHighlightAllItems = () => this.setState({ keyboardHighlightedItem: -1 });

  render() {
    const { onItemSelect, items, testRef, itemTestRef, customFirstItemTestRef } = this.props;
    const { keyboardHighlightedItem } = this.state;

    return (
      <ul
        tabIndex="0"
        className={styles.selectList}
        onBlur={this.unHighlightAllItems}
        onKeyDown={this.handleUpDownNavigation}
        ref={(element) => this.listContainerElement = element || undefined}
        data-test-reference={testRef}
      >
        {items.map((item, index) => (
          <SelectListItem
            onClick={() => onItemSelect(index)}
            value={item}
            highlighted={index === keyboardHighlightedItem}
            key={item}
            ref={(element) => this.itemElements[index] = element || undefined}
            testRef={itemTestRef}
            cypressTestRef={customFirstItemTestRef && index === 0 ? customFirstItemTestRef : null}
          />
        ))}
      </ul>
    );
  }
}
