import SegmentEventTypes from 'scripts/redux/actions/segment/SegmentEventTypes';
import { track } from 'src/tracking/analytics';


export function sendPropertyTabLoad({ tabName, propertyCode }) {
  return track(SegmentEventTypes.LOAD_PAGE, { 'title': `${propertyCode} | ${tabName}`, 'Property Code': propertyCode, 'Tab Name': tabName });
}

export function sendCalculatorPerPropertyEngagementEvent(title, propertyCode, growthRate, investmentAmount, investmentPeriod) {
  return track(SegmentEventTypes.CALCULATOR.PROPERTY_CALCULATOR_ENGAGEMENT, {
    'Property Code': propertyCode,
    'Title': title,
    'Annual Growth': growthRate,
    'Amount Invested': investmentAmount,
    'Holding Period': investmentPeriod
  });
}

export function sendCalculatorPerPropertyClickOnBreakdownEngagementEvent(propertyCode, growthRate, investmentAmount, investmentPeriod) {
  return track(SegmentEventTypes.CALCULATOR.PROPERTY_CALCULATOR_CLICK_ON_BREAKDOWN_ENGAGEMENT, {
    'Property Code': propertyCode,
    'Annual Growth': growthRate,
    'Amount Invested': investmentAmount,
    'Holding Period': investmentPeriod
  });
}

export function sendHistoricalSuburbGraphEngagementEvent(propertyCode, propertyTab, selectedPeriod) {
  return track(SegmentEventTypes.HISTORICAL_SUBURB_GROWTH.GRAPH_ENGAGEMENT, {
    'Property Code': propertyCode,
    'Property Tab': propertyTab,
    'Time Period': selectedPeriod
  });
}
