import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import DocumentMeta from 'react-document-meta';
import PropTypes from 'prop-types';
import { DOCUMENTS_META } from 'scripts/metadata/account';
import { fetchIndividualTaxReportUrl } from 'scripts/redux/actions/reports';
import { individualTaxReportUrlSelector } from 'scripts/redux/selectors/reports';
import {
  individualTaxReportUrl as individualTaxReportUrlType,
  year as yearPropType,
} from 'scripts/constants/PropTypes';
import { TAX_STATEMENT_AVAILABLE_TO } from 'src/settings/dates';
import { userSelector } from 'scripts/redux/actions/user';
import {
  TopDocumentContainer,
  TopDocumentsContainer,
} from 'scripts/components/account/documents/Shared';
import {
  annualIndividualStatement,
  annualIndividualStatementCsv,
} from 'scripts/services/api';
import {
  BsFileEarmarkSpreadsheet,
  BsFileEarmarkText,
  BsFilePdf,
} from 'react-icons/bs';
import { openUrlInNewWindow } from 'scripts/utilities/browser';
import Styledlink from 'src/design/components/hyperlink/Styledlink';
import { documentsUrl } from 'src/utils/pageUrls';
import { TAX_STATEMENT_AVAILABLE_DATES } from 'src/settings/dates';
import _ from 'lodash';
import Text from 'src/design/styleguide/text/Text';

const mapStateToProps = (state) => ({
  ...individualTaxReportUrlSelector(state),
  ...userSelector(state),
});

@connect(mapStateToProps, {
  fetchIndividualTaxReportUrl,
})
export default class AnnualStatements extends Component {
  static propTypes = {
    reports: individualTaxReportUrlType,
    params: yearPropType.isRequired,
    fetchIndividualTaxReportUrl: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { fetchIndividualTaxReportUrl, params } = this.props;
    fetchIndividualTaxReportUrl(params.year);
  }

  render() {
    const { params, reports, user } = this.props;
    const { year } = params;
    const yearDescription = `FY ${year - 2001}-${year - 2000}`;
    const fyLongText = `FY${year - 2000}: July 1, ${
      year - 1
    } - June 30, ${year}`;
    const showTaxStatement =
      reports.tax && parseInt(params.year, 10) <= TAX_STATEMENT_AVAILABLE_TO;

    return (
      <div className="my-documents">
        <DocumentMeta {...DOCUMENTS_META} />
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {!_.includes(TAX_STATEMENT_AVAILABLE_DATES, parseInt(year)) ? (
                <div
                  style={{
                    margin: '60px 30px',
                    textAlign: 'center',
                  }}
                >
                  <Text align="center" fontSize="large" color={'grey400'}>
                    Not Available
                  </Text>
                </div>
              ) : (
                <Fragment>
                  <h1>
                    <strong>Annual Statements</strong>
                    <br />
                    <small data-test-reference="fy-text">{fyLongText}</small>
                  </h1>
                  <TopDocumentsContainer>
                    {showTaxStatement && (
                      <TopDocumentContainer
                        title="Individual Tax Statement"
                        subTitle={
                          <p>
                            View summary tax statements which detail your Brick
                            positions, distributions paid and depreciation
                            deferred benefits at the end of the financial
                            period. Download a copy to aid you in completing
                            your annual tax returns.
                          </p>
                        }
                        onClick={() => this._donwloadIndividualTaxReport()}
                        icon={<BsFileEarmarkText />}
                        hoverIcon
                        data-test-reference="individual-tax-statement-box"
                      />
                    )}
                    <TopDocumentContainer
                      title="Annual Individual Statement (PDF)"
                      subTitle={
                        <p>
                          View all monetary transactions that occurred in your
                          BrickX Account for the last Fiscal Year, including
                          deposits, distributions, trades, etc. Download a copy
                          of your Annual Individual Statement, {yearDescription}{' '}
                          for your records.
                        </p>
                      }
                      onClick={() =>
                        openUrlInNewWindow(
                          annualIndividualStatement(user.id, year)
                        )
                      }
                      icon={<BsFilePdf />}
                      hoverIcon
                      data-test-reference="annual-individual-satement-pdf-box"
                    />
                    <TopDocumentContainer
                      title="Annual Individual Statement (CSV)"
                      subTitle={
                        <p>
                          View all monetary transactions that occurred in your
                          BrickX Account for the last Fiscal Year, including
                          deposits, distributions, trades, etc. Download a copy
                          of your Annual Individual Statement, {yearDescription}{' '}
                          for your records.
                        </p>
                      }
                      onClick={() =>
                        openUrlInNewWindow(
                          annualIndividualStatementCsv(user.id, year)
                        )
                      }
                      icon={<BsFileEarmarkSpreadsheet />}
                      hoverIcon
                      data-test-reference="annual-individual-satement-csv-box"
                    />
                  </TopDocumentsContainer>
                </Fragment>
              )}

              <div style={{ textAlign: 'center' }}>
                <Styledlink href={documentsUrl()}>
                  Return to My Documents
                </Styledlink>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  _donwloadIndividualTaxReport() {
    const { reports } = this.props;
    window.open(reports.tax.url);
  }
}
