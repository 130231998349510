import classNames from 'classnames';
import { Heading } from 'src/design/components/heading/Headings';
import styles from 'src/components/landingPages/howItWorks/common/sectionHeading/SectionHeading.mscss';

const SectionHeading = ({ className, children, ...props }) => (
  <Heading
    className={classNames(styles.sectionHeading, className)}
    align="center"
    {...props}
  >
    <h2>{children}</h2>
  </Heading>
);

export default SectionHeading;
