import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import {
  accountSelectorV2,
  fetchAccountInfoV2,
  fetchAccountWholesale,
  fetchSmartRenterDetails,
  smartRenterDetailsSelector,
  wholesaleInfoSelector,
} from 'scripts/redux/actions/account';
import { baseBreakpointGutter } from 'src/components/dashboard/home/commonStyles';
import {
  breakpointMedium,
  breakpointSmall,
} from 'src/design/styleguide/common/breakpoints';
import { dashboardAlertSelector } from 'scripts/redux/selectors/dashboardAlert';
import {
  defineGridArea,
  testRefToDataTestReference,
} from 'src/design/styleguide/common/styledComponentsUtils';
import { fetchDashboardAlert as fetchDashboardAlertAction } from 'scripts/redux/actions/dashboardAlert';
import {
  getSmartInvestPlan,
  hasFinishedOnboarding,
  planWasNeverSignedUpTo,
} from 'src/utils/account';
import { layoutStyles } from 'src/components/dashboard/common/styles';
import { load, save } from 'src/browser/cookies';
import { showModal as showModalAction } from 'src/components/modals/modalActions';
import { userSelector } from 'scripts/redux/actions/user';
import AccountSettingsPanel from 'src/components/dashboard/home/AccountSettingsPanel';
import AccountSummaryPanel from 'src/components/dashboard/home/AccountSummaryPanel';
import DashboardAlertPanel from 'src/components/dashboard/home/DashboardAlertPanel';
import Divider from 'src/design/components/divider/Divider';
import DocumentsPanel from 'src/components/dashboard/home/DocumentsPanel';
import GreetingPanel from 'src/components/dashboard/home/GreetingPanel';
import Loading from 'src/components/loading/Loading';
import PendingOrdersPanel from 'src/components/dashboard/home/PendingOrdersPanel';
import PortfolioPanel from 'src/components/dashboard/home/PortfolioPanel';
import RefereeBuyBricksPanel from 'src/components/dashboard/home/RefereeBuyBricksPanel';
import SmartInvestPromoPanel from 'src/components/dashboard/home/SmartInvestPromoPanel';
import SmartInvestSettingPanel from 'src/components/dashboard/home/SmartInvestSettingPanel';
import MinorAccountPromoPanel from 'src/components/dashboard/home/MinorAccountPromoPanel';
import WholesalePanel from 'src/components/dashboard/home/WholesalePanel';
import SmartRenterPanel from 'src/components/dashboard/home/SmartRenterPanel';
import Text from 'src/design/styleguide/text/Text';
import TransactionsPanel from 'src/components/dashboard/home/TransactionsPanel';
import WalletPanel from 'src/components/dashboard/home/WalletPanel';
import WelcomeBack from 'src/components/oldOnboarding/welcomeBack/WelcomeBack';
import WelcomeScreens from 'src/components/oldDashboard/welcomeWidgets/WelcomeScreens';
import WidgetPanel from 'src/components/dashboard/common/WidgetPanel';
import withEligibleReferredStatus from 'src/decorators/withEligibleReferredStatus';
import withState from 'src/decorators/withState';
import {
  canApplySmartRenter,
  canSetupMinorAccount,
  isMinorAccount,
  isSMSForTrustAccount,
} from 'scripts/utilities/userAccountHelper';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import {
  themeForBrickXMinorLandingPage,
  themeForSmsfAccount,
} from 'src/themes/landingPageThemes';
import { getThemeColor } from 'src/themes/themeUtils';
import HideComponent from 'src/components/common/HideComponent';

const Layout = styled.div.attrs(testRefToDataTestReference)`
  ${layoutStyles}
  grid-template-columns: minmax(0, 1fr);
  ${defineGridArea('greeting')}
  ${defineGridArea('dashboard-alert')}
  ${defineGridArea('referee-buy-bricks')}
  ${defineGridArea('accountDivider')}
  ${defineGridArea('summary')}
  ${defineGridArea('dashboardDivider')}
  ${defineGridArea('portfolio')}
  ${defineGridArea('pendingOrders')}
  ${defineGridArea('transactions')}
  ${defineGridArea('settings')}
  ${defineGridArea('wallet')}
  ${defineGridArea('documents')}
  ${defineGridArea('transactions')}
  ${defineGridArea('promo')}

  grid-template-areas:
    ${(props) => props.dashboardAlertPresent && "'dashboard-alert'"}
    'greeting'
    ${(props) => props.referralPresent && "'referee-buy-bricks'"}
    'accountDivider'
    'summary'
    'dashboardDivider'
    'portfolio'
    'wallet'
    'pendingOrders'
    'transactions'
    'documents'
    'settings'
    'promo';

  padding: 0 ${baseBreakpointGutter}px;

  ${breakpointSmall(`padding: 0;`)}

  ${breakpointMedium(css`
    grid-template-columns: repeat(2, minmax(0, 1fr));

    grid-template-areas:
      ${(props) =>
        props.dashboardAlertPresent && "'dashboard-alert dashboard-alert'"}
      'greeting greeting'
      ${(props) =>
        props.referralPresent && "'referee-buy-bricks referee-buy-bricks'"}
      'accountDivider accountDivider'
      'summary summary'
      'dashboardDivider dashboardDivider'
      'portfolio wallet'
      'pendingOrders documents'
      'transactions transactions'
      ${(props) =>
        props.siPromoPresent ? "'settings promo'" : "'settings .'"};
  `)}
`;

const DividerText = styled(Text).attrs({
  fontSize: '4x-small',
  color: 'onSurfaceContrast500',
})``;

const SectionDivider = ({ className, children }) => (
  <Divider className={className} top="none" bottom="none">
    <DividerText>{children}</DividerText>
  </Divider>
);

const WelcomeModal = ({ closeThisModal }) => (
  <WelcomeScreens
    onFinish={() => {
      save('dashboard-not-yet-seen-after-completing-onboarding', '');
      closeThisModal();
    }}
  />
);

export const getCustomThemeColor = (user) => {
  if (isMinorAccount(user)) {
    return themeForBrickXMinorLandingPage.colors.primary;
  }
  if (isSMSForTrustAccount(user)) {
    return themeForSmsfAccount.colors.primary;
  }
  return getThemeColor('primary');
};

export const getCustomButtonColor = (user) => {
  if (isMinorAccount(user)) {
    return PrimaryButton.colors.YOLK;
  }
  if (isSMSForTrustAccount(user)) {
    return PrimaryButton.colors.BLUE;
  }
  return PrimaryButton.colors.SECONDARY;
};

const DashboardHome = withEligibleReferredStatus(
  connect(
    (state) => ({
      ...userSelector(state),
      ...accountSelectorV2(state),
      ...wholesaleInfoSelector(state),
      ...smartRenterDetailsSelector(state),
      ...dashboardAlertSelector(state),
    }),
    {
      fetchAccountInfo: fetchAccountInfoV2,
      fetchDashboardAlert: fetchDashboardAlertAction,
      fetchAccountWholesale: fetchAccountWholesale,
      fetchSmartRenterDetails: fetchSmartRenterDetails,
      showModal: showModalAction,
    }
  )(
    withState({
      name: 'DashboardHome',
      componentDidMount: (props) => {
        if (load('dashboard-not-yet-seen-after-completing-onboarding')) {
          props.showModal({ content: <WelcomeModal />, noCloseButton: true });
        }
        props.fetchAccountInfo();
        props.fetchDashboardAlert();
        props.fetchAccountWholesale();
        props.fetchSmartRenterDetails();
      },
      Component: ({
        user,
        account,
        dashboardAlert,
        isEligible,
        wholesaleInfo,
        smartRenterDetails,
      }) => {
        if (!account) return <Loading />;
        if (!hasFinishedOnboarding(account)) {
          return (
            <WidgetPanel>
              <WelcomeBack user={user} />
            </WidgetPanel>
          );
        }
        const dashboardAlertText = dashboardAlert && dashboardAlert.text;
        const canSetupMinorAccountResult = canSetupMinorAccount(user);
        const canApplySmartRenterResult = canApplySmartRenter(user);
        const canSetupSIResult = planWasNeverSignedUpTo(
          getSmartInvestPlan(account)
        );

        return (
          <Layout
            dashboardAlertPresent={!!dashboardAlertText}
            referralPresent={isEligible}
            minorPromoPresent={canSetupMinorAccountResult}
            siPromoPresent={canSetupSIResult}
            testRef="welcome-page"
          >
            {dashboardAlertText && (
              <DashboardAlertPanel
                className="dashboard-alert"
                dashboardAlertText={dashboardAlertText}
              />
            )}
            {isEligible && (
              <RefereeBuyBricksPanel className="referee-buy-bricks" />
            )}
            <GreetingPanel className="greeting" />
            <SectionDivider className="accountDivider">
              Account Summary
            </SectionDivider>
            <AccountSummaryPanel
              className="summary"
              account={account}
              user={user}
            />
            <SectionDivider className="dashboardDivider">
              Dashboard Summary
            </SectionDivider>
            <PortfolioPanel
              className="portfolio"
              account={account}
              user={user}
            />
            <WalletPanel className="wallet" account={account} user={user} />
            <PendingOrdersPanel className="pendingOrders" />
            <DocumentsPanel className="documents" />
            <TransactionsPanel className="transactions" />
            <AccountSettingsPanel className="settings" account={account} />
            {canSetupSIResult ? (
              <SmartInvestPromoPanel className="promo" />
            ) : (
              <SmartInvestSettingPanel account={account} />
            )}
            <HideComponent>
              {canApplySmartRenterResult && (
                <SmartRenterPanel
                  user={user}
                  smartRenterDetails={smartRenterDetails}
                />
              )}
            </HideComponent>
            <WholesalePanel user={user} wholesaleInfo={wholesaleInfo} />
            {canSetupMinorAccountResult && (
              <MinorAccountPromoPanel user={user} />
            )}
          </Layout>
        );
      },
    })
  )
);

export default DashboardHome;
