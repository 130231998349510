import { Fragment } from 'react';

const generateFlowFieldErrorText = (errors) => (
  <Fragment>
    {errors.map((errorText, index) => (
      <Fragment key={errorText}>
        {index > 0 && <br/>}
        {errorText}
      </Fragment>
    ))}
  </Fragment>
);

export default generateFlowFieldErrorText;
