import styled, { css } from 'styled-components';
import { breakpointSmall } from 'src/design/styleguide/common/breakpoints';
import { getThemeColor } from 'src/themes/themeUtils';
import { gridUnit } from 'src/design/styleguide/common/measurements';
import { testRefToDataTestReference } from 'src/design/styleguide/common/styledComponentsUtils';

const TotalValueContainer = styled.div.attrs(testRefToDataTestReference)`
  text-align: center;
  padding: ${gridUnit * 8}px 0;
  width: 100%;
  background-color: ${getThemeColor('grey50')};

  ${breakpointSmall(css`
    border-left: 4px solid ${props => props.highlightColor || getThemeColor('primary')};
    padding: 0 0 0 ${gridUnit * 5}px;
    background-color: transparent;
    text-align: left;
  `)}
`;

export default TotalValueContainer;
