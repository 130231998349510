import PropertyFeaturesBar from 'src/components/propertyCards/common/propertyFeaturesBar/PropertyFeaturesBar';

const PropertyCardHero = ({ property, withGreyout }) => (
  <div className="property-card-feature">
    <div className="property-card-feature__image-container">
      <div
        className="property-card-feature__image"
        style={{
          backgroundImage: `url("${property.propertyImages[0].resized595Url}")`,
          filter: withGreyout && 'grayscale(1)',
        }}
        data-test-reference="property-card-feature-image"
      ></div>
    </div>
    <PropertyFeaturesBar property={property} />
  </div>
);

export default PropertyCardHero;
