import classNames from 'classnames';
import LazyLoad from 'react-lazyload';

import { Heading } from 'src/design/components/heading/Headings';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import TextInput from 'src/design/components/textInput/TextInput';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import withState from 'src/decorators/withState';
import { signupUrl } from 'src/utils/pageUrls';

import styles from 'src/components/subFooter/SubFooter.mscss';


const SubFooter = ({ className, setState, state: { email } }) =>
  <div className={classNames(styles.subFooter, className)}>
    <div className={styles.innerContainer}>
      <LazyLoad>
        <img
          className={styles.backgroundImage}
          src="/static/img/photo-young-family.png"
          alt="Photograph of a young Father and Mother, holding a baby, everyone is smiling"
        />
      </LazyLoad>

      <div className={styles.ctaBoxedArea}>
        <Spacing bottom="2x-large">
          <Heading className={styles.ctaTitle}><h2>Create your portfolio with BrickX today</h2></Heading>
        </Spacing>
        <div className={styles.inputsRow}>
          <TextInput className={styles.emailInput} onChange={(value) => setState({ email: value })} placeholder="Email Address" data-test-reference="email-input"/>
          <PrimaryButton
            className={styles.getStartedButton}
            link={{
              pathname: signupUrl(),
              ...(email ? { state: { email } } : {}),
            }}
            color={PrimaryButton.colors.SECONDARY}
            testRef="get-started-button"
          >Sign Up</PrimaryButton>
          </div>
      </div>
    </div>
  </div>;

export default withState({ Component: SubFooter, mapPropsToInitialState: () => ({ email: '' }) });
