import moment from 'moment';
import { save, load, remove, isCookieSet } from 'src/browser/cookies';
import authStore from 'scripts/auth/authStore';
import Configuration from 'scripts/constants/Configuration';


const TIMEOUT_WARNING_SHOWN_KEY = Configuration.COOKIES.TIMEOUT_WARNING_SHOWN;
const AUTHORIZATION_KEY = Configuration.COOKIES.AUTHORIZATION;
const CLOSED_TESTING_KEY = Configuration.COOKIES.CLOSED_TESTING;

const clearSegmentCookies = () => {
  remove(Configuration.COOKIES.SEGMENT_COOKIES.ANONYMOUS_ID_COOKIE);
  remove(Configuration.COOKIES.SEGMENT_COOKIES.GROUP_ID_COOKIE);
  remove(Configuration.COOKIES.SEGMENT_COOKIES.MIXPANEL_COUNT_COOKIE);
  remove(Configuration.COOKIES.SEGMENT_COOKIES.USER_ID_COOKIE);
};

export const beginSession = (userId, authKey, email = '') => {
  const userIsClosedTester = email.endsWith('tester@brickx.com');
  save(AUTHORIZATION_KEY, authKey);
  userIsClosedTester && save(CLOSED_TESTING_KEY, true);
  authStore.setAuthToken(authKey);
  save(TIMEOUT_WARNING_SHOWN_KEY, false);
};

export const clearSession = () => {
  remove(TIMEOUT_WARNING_SHOWN_KEY);
  remove(AUTHORIZATION_KEY);
  authStore.clear();
  clearSegmentCookies();
};

export const getCurrentUserId = () => authStore.getUserId();

export const isTimedOut = () => {
  const expiry = authStore.getExpiry();
  const isLoggedIn = !!expiry;
  const isTokenExpired = moment(expiry).isSameOrBefore(moment());
  return isLoggedIn && isTokenExpired;
};

export const isRefreshRequired = () => {
  const expiry = authStore.getExpiry();
  const isFiveMinuteOrLessBeforeExpiry = moment(expiry).subtract(5, 'minutes').isSameOrBefore(moment());

  return !isTimedOut() && isFiveMinuteOrLessBeforeExpiry;
};

export const timeoutWarningWasShown = () => {
  if (!isCookieSet(TIMEOUT_WARNING_SHOWN_KEY)) { return; }
  save(TIMEOUT_WARNING_SHOWN_KEY, true);
};

export const hasTimeoutWarningShown = () => !!load(TIMEOUT_WARNING_SHOWN_KEY);
