import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { tradeSucceed as tradeSucceedPropType } from 'scripts/constants/PropTypes';
import { tradeSucceedSelector } from 'scripts/redux/selectors/market';
import { showModal as showModalAction } from 'src/components/modals/modalActions';
import { trackPreOrderSuccess } from 'src/tracking/tradeSuccess';
import Loading from 'src/components/loading/Loading';
import PreOrderSuccessMeta from 'src/components/meta/PreOrderSuccessMeta';
import { brick } from 'scripts/utilities/formatters';
import TradeSuccess from 'src/components/trades/TradeSuccess';
import PreOrderNotFullyTransactedModalContent from 'src/components/trades/PreOrderNotFullyTransactedModalContent';
import { renderCode } from 'src/settings/properties';

@connect(
  (state) => ({
    ...tradeSucceedSelector(state),
  }),
  {
    showModal: showModalAction,
  }
)
export default class PreOrderSuccess extends Component {
  static propTypes = {
    tradeSucceed: tradeSucceedPropType,
    showModal: PropTypes.func,
  };

  componentDidMount() {
    const { tradeSucceed, showModal } = this.props;
    if (!tradeSucceed) return;

    const { data } = tradeSucceed;

    trackPreOrderSuccess({
      propertyCode: data.propertyCode,
      quantity: data.quantityTransacted,
      brickPrice: data.basisPrice,
      tradeAmount: data.valueOfBricks,
      commissionAmount: data.transactionFee,
      total: data.total,
    });

    if (data.quantityProposed !== data.quantityTransacted) {
      showModal({
        content: (
          <PreOrderNotFullyTransactedModalContent
            propertyCode={data.propertyCode}
            numBricksTransacted={data.quantityTransacted}
          />
        ),
      });
    }
  }

  render() {
    const { tradeSucceed } = this.props;

    if (!tradeSucceed) {
      return (
        <Fragment>
          <PreOrderSuccessMeta />
          <Loading />
        </Fragment>
      );
    }

    const {
      data: { propertyCode, quantityTransacted },
    } = tradeSucceed;

    const greeting = `Congratulations, you have pre-ordered ${quantityTransacted::brick()} in ${renderCode(
      propertyCode
    )}.`;

    return (
      <Fragment>
        <PreOrderSuccessMeta />
        <TradeSuccess propertyCode={propertyCode} greeting={greeting} />
      </Fragment>
    );
  }
}
