import Configuration from 'scripts/constants/Configuration';
import Meta from 'src/components/meta/Meta';

const DefaultMeta = () => <Meta
  image="https://assets.brickx.com/images/facebook-share-image.png"
  otherTags={[
    <meta property="og:type" content="website"/>,
    <meta property="article:author" content="BrickX"/>,
    <meta property="article:publisher" content="BrickX"/>,
    <meta property="fb:app_id" content={Configuration.FACEBOOK_APP_ID}/>,
    <meta name="twitter:site" content="@brickx"/>,
    <meta name="twitter:creator" content="@brickx"/>,
    <meta name="twitter:card" content="summary"/>,
  ]}
/>;

export default DefaultMeta;
