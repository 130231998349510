import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import {
  myOrder as myOrderPropType,
  property as propertyPropType,
} from 'scripts/constants/PropTypes';

import { number, brickPrice, date, uuid } from 'scripts/utilities/formatters';
import { getByPropertyCode } from 'scripts/utilities/helpers';
import { renderCode } from 'src/settings/properties';

export default class MyPreOrderRow extends Component {
  static propTypes = {
    myOrder: myOrderPropType,
    properties: PropTypes.arrayOf(propertyPropType),
    action: PropTypes.func,
  };

  render() {
    const { myOrder, action } = this.props;

    return (
      <tr key={myOrder.orderId} id={myOrder.orderId}>
        <td className="text-left">
          <Link to={`/properties/${myOrder.propertyCode}`}>
            {renderCode(myOrder.propertyCode)} -{' '}
            {this._getPropertySuburb(myOrder.propertyCode)}
          </Link>
        </td>
        <td className="hidden-xs hidden-sm">{myOrder.date::date()}</td>
        <td className="text-center hidden-xs hidden-sm">
          {this._getSettlementDate(myOrder.propertyCode)}
        </td>
        <td className="text-center">{myOrder.quantity::number()}</td>
        <td className="text-center">{myOrder.price::brickPrice()}</td>
        <td className="text-center">
          {myOrder.totalPriceInclFees::brickPrice()}
        </td>
        <td className="text-center hidden-xs hidden-sm">
          {myOrder.orderId::uuid()}
        </td>
        <td className="text-center">{action && action(myOrder)}</td>
      </tr>
    );
  }

  _getPropertySuburb = (propertyCode) => {
    const property = this._getProperty(propertyCode);
    return property ? property.suburb : '';
  };

  _getSettlementDate = (propertyCode) => {
    const property = this._getProperty(propertyCode);
    return property ? property.platformSettlementDate::date() : 'UNKNOWN';
  };

  _getProperty = (propertyCode) => {
    const { properties } = this.props;
    const property = getByPropertyCode(properties, propertyCode);
    return property;
  };
}
