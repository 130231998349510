import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import { fetchOrders } from 'scripts/redux/actions/market';
import { ordersSellSelector } from 'scripts/redux/selectors/market';
import {
  property as propertyPropType,
  account as accountPropType,
  orderAggregated as orderPropType,
  myOrders as myOrdersPropType,
  user as userPropType,
} from 'scripts/constants/PropTypes';
import {
  sendRecentTransactionsTabClickEvent,
  sendBricksAvailableTabClickEvent,
  sendPlaceOrderButtonClickEvent,
} from 'scripts/redux/actions/segment/events/tradeEvents';
import OrderBookTrade from 'scripts/components/property/trade/OrderBookTrade';
import RecentlySold from 'scripts/components/property/RecentlySold';
import Trade from 'scripts/constants/Trade';
import TradePanelBanner from 'src/components/promos/placements/TradePanelBanner';
import TradeProposalFormSell from 'scripts/components/property/trade/TradeProposalFormSell';
import withLegacyAccount from 'src/decorators/withLegacyAccount';
import { renderCode } from 'src/settings/properties';

const mapStateToProps = (state) => ({
  ...ordersSellSelector(state),
});

@withLegacyAccount
@connect(mapStateToProps, {
  fetchOrders,
})
export default class TradePanelSell extends Component {
  static propTypes = {
    user: userPropType.isRequired,
    account: accountPropType,
    property: propertyPropType.isRequired,
    ordersSell: PropTypes.arrayOf(orderPropType),
    myOrders: myOrdersPropType.isRequired,
    getTotalBricksOwned: PropTypes.func.isRequired,
    fetchMyOrdersInfo: PropTypes.func.isRequired,
    fetchOrders: PropTypes.func.isRequired,
    handleClosePanelClick: PropTypes.func.isRequired,
    redirectTo: PropTypes.func.isRequired,
  };

  state = {
    sellForm: {
      quantity: '',
      price: '',
      valueOfBricks: 0,
      transactionFee: 0,
      total: 0,
      hasQuantityError: false,
      hasPriceError: false,
      priceErrorMsg: '',
      quantityErrorMsg: '',
      callback: (updatedSellForm) => {
        this.setState({ sellForm: updatedSellForm });
      },
    },
    mobileTabsArr: [true, false, false],
  };

  componentDidMount() {
    const { user, property, fetchMyOrdersInfo, fetchOrders } = this.props;
    fetchMyOrdersInfo(user);
    fetchOrders(property.propertyCode);
  }

  render() {
    const {
      user,
      account,
      property,
      ordersSell,
      myOrders,
      handleClosePanelClick,
      getTotalBricksOwned,
      redirectTo,
    } = this.props;
    const { mobileTabsArr, sellForm } = this.state;

    return (
      <div className="panel-financials-invest-shadow">
        <div className="panel-financials-light-blue-header panel-financials-buy panel-financials-table panel-financials-small">
          <div className="header">
            <div className="row">
              <div className="panel-financials-title col-md-8 col-xs-8">
                {`Sell Bricks in ${renderCode(property.propertyCode)}`}
              </div>
              <div className="link col-xs-4 col-md-4 text-right">
                <span className="close-panel" onClick={handleClosePanelClick}>
                  <span>
                    <i className="fa fa-times-circle"></i>
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div className="buy-mobile-tabs visible-xs">
            <a
              className={classNames('col-xs-4 tab-button', {
                'active with-arrow-down': mobileTabsArr[0],
              })}
              onClick={() => this._onTabButtonClick(0)}
            >
              Bricks
              <br />
              Available to Buy
            </a>
            <a
              className={classNames('col-xs-4 tab-button', {
                'active with-arrow-down': mobileTabsArr[1],
              })}
              onClick={() => this._onTabButtonClick(1)}
            >
              Recent
              <br />
              Transactions
            </a>
            <a
              className={classNames('col-xs-4 tab-button', {
                'active with-arrow-down': mobileTabsArr[2],
              })}
              onClick={() => this._onTabButtonClick(2)}
            >
              Place
              <br />
              Order
            </a>
          </div>

          <div className="visible-xs">
            <TradePanelBanner />
          </div>

          <div className="body wrap-the-col">
            <div
              className={classNames('col-md-4 tab buy-panel-mobile-tab', {
                active: mobileTabsArr[0],
              })}
            >
              <OrderBookTrade
                account={account}
                property={property}
                tradeType={Trade.TRADE_TYPE.SELL}
                ordersSell={ordersSell}
              />
              <button
                className="button right-arrow-button orange-button visible-xs action-full-button"
                onClick={() => this._onTabButtonClick(2)}
              >
                PLACE ORDER
              </button>
            </div>
            <div
              className={classNames(
                'col-xs-12 tab buy-panel-mobile-tab recently-sold-tab',
                { active: mobileTabsArr[1] }
              )}
            >
              <div className="table-orders">
                <RecentlySold
                  property={property}
                  isPreOrderProperty={false}
                  tradeType={Trade.TRADE_TYPE.SELL}
                  account={account}
                />
                <button
                  className="button right-arrow-button orange-button visible-xs action-full-button"
                  onClick={() => this._onTabButtonClick(2)}
                >
                  PLACE ORDER
                </button>
              </div>
            </div>
            <div
              className={classNames('col-md-8 tab buy-panel-mobile-tab', {
                active: mobileTabsArr[2],
              })}
            >
              <TradeProposalFormSell
                user={user}
                property={property}
                account={account}
                myOrders={myOrders}
                getTotalBricksOwned={getTotalBricksOwned}
                sellForm={sellForm}
                redirectTo={redirectTo}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  _resetMobileTabs() {
    this.setState({
      mobileTabsArr: [false, false],
    });
  }

  _onTabButtonClick(index) {
    this._resetMobileTabs();
    const { mobileTabsArr } = this.state;
    this.setState({
      mobileTabsArr: _.map(mobileTabsArr, function (n, i) {
        return i === index ? true : false;
      }),
    });

    const { account, property } = this.props;
    if (index === 0) {
      sendBricksAvailableTabClickEvent(
        Trade.TRADE_TYPE.SELL,
        property.propertyCode,
        account
      );
    } else if (index === 1) {
      sendRecentTransactionsTabClickEvent(
        Trade.TRADE_TYPE.SELL,
        property.propertyCode,
        account
      );
    } else if (index === 2) {
      sendPlaceOrderButtonClickEvent(
        Trade.TRADE_TYPE.BUY,
        property.propertyCode,
        account
      );
    }
  }
}
