import { connect } from 'react-redux';
import { BrickxLogoDark } from 'src/components/brickxLogo/BrickxLogo';
import { menuOpenSelector } from 'src/store/headerMenuReducer';
import {
  onboardingTmdUrl,
  onboardingQuestionsUrl,
  onboardingProductSelectionUrl,
  onboardingSmartInvestDetailsUrl,
  acceptPdsUrl,
  identityVerificationUrl,
  vedaFailUrl,
  emailVerificationUrl,
  signupUrl,
} from 'src/utils/pageUrls';
import {
  toggleMenu as toggleMenuAction,
  closeMenu as closeMenuAction,
} from 'src/store/headerMenuActions';
import Header from 'src/design/components/header/Header';
import MenuItems from 'src/components/header/menu/MenuItems';

const isOnboardingScreen = (path) =>
  path.match(onboardingTmdUrl())
    || path.match(onboardingQuestionsUrl())
    || path.match(onboardingProductSelectionUrl())
    || path.match(onboardingSmartInvestDetailsUrl())
    || path.match(signupUrl())
    || path.match(acceptPdsUrl())
    || path.match(identityVerificationUrl())
    || path.match(vedaFailUrl())
    || path.match(emailVerificationUrl());

const BrickxHeader = ({ menuOpen, toggleMenu, closeMenu, currentPath }) => {
  const shouldShowMenu = !isOnboardingScreen(currentPath);
  return (
    <Header
      logo={<BrickxLogoDark/>}
      mobileMenuOpen={menuOpen}
      onLogoClick={closeMenu}
      onHamburgerIconClick={toggleMenu}
      hidden={!shouldShowMenu}
    >
      <MenuItems/>
    </Header>
  );
};

export default connect(menuOpenSelector, {
  toggleMenu: toggleMenuAction,
  closeMenu: closeMenuAction,
})(BrickxHeader);
