import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';


function prefix(str) {
  return `${str}-${this}`;
}

const Icon = ({ type, name, className }) => {
  const iconName = _.isObject(name) ? _.mapKeys(name, (v, key) => key::prefix(type)) : (name && name::prefix(type));
  return (
    <i className={classNames(type, iconName, className)}/>
  );
};

Icon.propTypes = {
  type: PropTypes.oneOf(['fa', 'icon', 'icon-settings']).isRequired,
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(PropTypes.bool)
  ]).isRequired,
};

export default Icon;
