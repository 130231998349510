import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import DocumentMeta from 'react-document-meta';

import { withdrawal as withdrawalPropType } from 'scripts/constants/PropTypes';

import { withdrawalSelector } from 'scripts/redux/selectors/wallet';

import { cleanWithdrawInfo } from 'scripts/redux/actions/wallet';

import Loading from 'src/components/loading/Loading';

import Wallet from 'scripts/constants/Wallet';
import { WITHDRAW_REQUESTED_META } from 'scripts/metadata/account';

const mapStateToProps = (state) => ({
  ...withdrawalSelector(state),
});

@connect(mapStateToProps, {
  cleanWithdrawInfo,
})
export default class WithdrawalRequested extends Component {
  static propTypes = {
    withdrawal: withdrawalPropType.isRequired,
    cleanWithdrawInfo: PropTypes.func,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this.props.cleanWithdrawInfo();
  }

  render() {
    const { withdrawal } = this.props;

    const withdrawalRequested =
      withdrawal && withdrawal.type === Wallet.WITHDRAWAL.REQUESTED;
    if (!withdrawalRequested) {
      return <Loading />;
    }

    return (
      <div className="withdraw-success">
        <DocumentMeta {...WITHDRAW_REQUESTED_META} />
        <div className="container">
          <h1 className="title">Thank you, your Withdraw is processing.</h1>
          <div className="row">
            <div className="col-md-10 col-md-offset-1">
              <div className="white-box">
                <h3 className="text-center title sub-title">
                  Please allow 1-3 business days for the funds transfer of{' '}
                  <span className="amount">${withdrawal.data.amount}</span> to
                  arrive in your nominated bank account.
                </h3>
                <div className="text-center">
                  <a
                    href="/account"
                    className="button orange-button right-arrow-button text-center"
                  >
                    My Dashboard
                  </a>
                </div>
                <div className="text-center info-copy">
                  We'll notify you via email when your funds are on their way.
                  You can monitor the status of your Withdrawal&nbsp;
                  <Link to="/account/transactions">My Transactions</Link>.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
