import styled, { css } from 'styled-components';
import { getThemeColor } from 'src/themes/themeUtils';
import { gridUnit } from 'src/design/styleguide/common/measurements';
import {
  testRefToDataTestReference,
} from 'src/design/styleguide/common/styledComponentsUtils';
import Spacing from 'src/design/styleguide/spacing/Spacing';

const defaultSize = 'x-large';

const beforeAfterStyles = css`
  content: ' ';
  flex: 1 0 0px;
  height: 1px;
  background-color: ${props => props.onDarkBg
    ? 'rgba(255,255,255,0.1)'
    : getThemeColor('shale')
  };
`;

const StyledDivider = styled.div.attrs(testRefToDataTestReference)`
  display: flex;
  align-items: center;

  &::before {
    ${beforeAfterStyles}
    ${props => props.children && `margin-right: ${gridUnit * 2}px;`}
    ${props => props.align === 'left' && `
      flex: 0 0 ${gridUnit * 4}px;
    `}
  }

  &::after {
    ${beforeAfterStyles}
    ${props => props.children && `margin-left: ${gridUnit * 2}px;`}
    ${props => props.align === 'right' && `
      flex: 0 0 ${gridUnit * 4}px;
    `}
  }
`;

const Divider = ({ className, bottom, top, onDarkBg, align, children, testRef = 'divider' }) =>
  <Spacing bottom={bottom || defaultSize} top={top || defaultSize}>
    <StyledDivider className={className} onDarkBg={onDarkBg} align={align} testRef={testRef}>
      {children}
    </StyledDivider>
  </Spacing>;

export default Divider;
