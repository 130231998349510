import styled from 'styled-components';
import { gridUnit } from 'src/design/styleguide/common/measurements';
import {
  testRefToDataTestReference,
} from 'src/design/styleguide/common/styledComponentsUtils';
import generateResponsiveStyles from
  'src/design/styleguide/common/generateResponsiveStyles';

const maxWidths = {
  small: '750px',
  medium: '960px',
  large: '1200px',
};

const paddingSizes = {
  'none': false,
  'x-small': `${gridUnit * 10}px`,
  'small': `${gridUnit * 12}px`,
  'medium': `${gridUnit * 16}px`,
  'large': `${gridUnit * 20}px`,
  'x-large': `${gridUnit * 24}px`,
  '2x-large': `${gridUnit * 30}px`,
};

const getWidths = (width) => maxWidths[width] || maxWidths.large;
const getPadding = (paddingSize) => paddingSizes[paddingSize];
const generatePaddingStyle = (direction, value) => getPadding(value) ? `padding-${direction}: ${getPadding(value)};` : '';

const StyledContentContainer = styled.div.attrs(testRefToDataTestReference)`
  width: 100%;
  max-width: ${props => getWidths(props.width)};
  ${props => props.verticallyExpanded && `
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
  `}
  ${props => props.verticallyCentered ? 'margin: auto;' : 'margin: 0 auto;'}
  ${props => props.topPadding && generateResponsiveStyles(props.topPadding, (value) => generatePaddingStyle('top', value))}
  ${props => props.bottomPadding && generateResponsiveStyles(props.bottomPadding, (value) => generatePaddingStyle('bottom', value))}
`;

const ContentContainer = ({ children, ...props }) => (
  <StyledContentContainer {...props}>
    {children}
  </StyledContentContainer>
);

export default ContentContainer;
