import { createAction, handleActions } from 'redux-actions';
import UserService from 'scripts/services/UserService';

const DASHBOARD_ALERT = 'scripts/account/DASHBOARD_ALERT';

export const dashboardAlert = createAction(DASHBOARD_ALERT);

export function fetchDashboardAlert() {
  return async function (dispatch, getState) {
    try {
      const { user } = getState();
      const payload = await UserService.getDashboardAlert(user.id);
      return dispatch(dashboardAlert(payload));
    } catch (e) {
      console.log(e.message); // eslint-disable-line no-console
    }
  };
}
const reducer = handleActions(
  {
    [DASHBOARD_ALERT]: (state, action) => action.payload || state,
  },
  null
);

export default reducer;
