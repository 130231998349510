import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DocumentMeta from 'react-document-meta';
import { user as userPropType, registration as registrationPropType } from 'scripts/constants/PropTypes';
import { userSelector } from 'scripts/redux/selectors/user';
import { registrationSelector } from 'scripts/redux/selectors/registration';
import { acceptPds } from 'scripts/redux/actions/registration';
import PdsAgreement from 'src/components/oldOnboarding/pdsAgreement/PdsAgreement';
import RegistrationStep from 'scripts/constants/RegistrationStep';
import { PDS_META } from 'scripts/metadata/statics';
import { identityVerificationUrl } from 'src/utils/pageUrls';


const mapStateToProps = (state) => ({
  ...userSelector(state),
  ...registrationSelector(state)
});

@connect(mapStateToProps, {
  acceptPds,
})
export default class PdsPanel extends Component {
  static propTypes = {
    user: userPropType.isRequired,
    acceptPds: PropTypes.func.isRequired,
    registration: registrationPropType.isRequired,
  };

  render() {
    const { user, acceptPds, registration } = this.props;
    if (registration.type === RegistrationStep.SETUP) {
      window.location.href = identityVerificationUrl();
    }

    return (
      <div>
        <DocumentMeta {...PDS_META} />
        <PdsAgreement user={user} acceptPds={acceptPds}/>
      </div>
    );
  }
}
