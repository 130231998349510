import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactPlaceholder from 'react-placeholder';

import { lastNTradesSelector } from 'scripts/redux/selectors/market';

import { fetchLastNTrades } from 'scripts/redux/actions/market';
import { sendSeeMoreTransactionsLinkClickEvent, sendSeeLessTransactionsLinkClickEvent } from 'scripts/redux/actions/segment/events/tradeEvents';

import OrderBookTableBodyPlaceholder from 'scripts/components/placeholders/trade/OrderBookTableBodyPlaceholder';

import { account as accountPropType, property as propertyPropType, trade as tradePropType } from 'scripts/constants/PropTypes';
import Configuration from 'scripts/constants/Configuration';
import { brickPrice, number, date } from 'scripts/utilities/formatters';
import { scrollTo } from 'scripts/utilities/helpers';

import _ from 'lodash';


const mapStateToProps = (state) => ({
  ...lastNTradesSelector(state)
});

@connect(mapStateToProps, {
  fetchLastNTrades,
})
export default class RecentlySold extends Component {
  static propTypes = {
    account: accountPropType,
    lastNTrades: PropTypes.arrayOf(tradePropType),
    property: propertyPropType.isRequired,
    tradeType: PropTypes.string.isRequired,
    isPreOrderProperty: PropTypes.bool.isRequired,
    fetchLastNTrades: PropTypes.func.isRequired
  };

  state = {
    showAllOrders: false,
  };

  componentDidMount() {
    const { property, fetchLastNTrades } = this.props;
    fetchLastNTrades(property.propertyCode);
  }

  render() {
    const { isPreOrderProperty, lastNTrades, account } = this.props;
    const hasTradesLoaded = !_.isNil(lastNTrades);
    const numOfTrades = hasTradesLoaded ? lastNTrades.length : 0;

    return (
      <table className="table table-bordered table-striped table-hover recently-sold">
        <thead>
          <tr>
            <th>Quantity</th>
            <th>Price</th>
            <th>Date</th>
          </tr>
        </thead>
        <ReactPlaceholder ready={!_.isNil(lastNTrades) && !_.isNil(account)} customPlaceholder={<OrderBookTableBodyPlaceholder/>}>
          <tbody>
            {this.state.showAllOrders
              ?
              hasTradesLoaded && (lastNTrades.slice(0, Configuration.MAXIMUM_NUM_TRANSACTIONS_SHOWN)).map((_order, index) => {
                return (
                  <tr key={index}>
                    <td className={`text-left trade-${index}-quantity`}>{_order.quantity::number()}</td>
                    <td className={`text-left trade-${index}-brick-price`}>{(_order.price)::brickPrice()}</td>
                    <td className={`text-left trade-${index}-brick-date`}>{(_order.date)::date()}</td>
                  </tr>
                );
              })
              :
              hasTradesLoaded && (lastNTrades).map((_order, index) => {
                if (index < Configuration.DEFAULT_NUM_TRANSACTIONS_SHOWN) {
                  return (
                    <tr key={index}>
                      <td className={`text-left trade-${index}-quantity`}>{_order.quantity::number()}</td>
                      <td className={`text-left trade-${index}-brick-price`}><span>{(_order.price)::brickPrice()}</span></td>
                      <td className={`text-left trade-${index}-brick-date`}>{(_order.date)::date()}</td>
                    </tr>
                  );
                }
              })
            }

            {isPreOrderProperty &&
              <tr>
                <td colSpan="3" className="text-center">Transactions occur on Settlement day</td>
              </tr>
            }

            {numOfTrades > Configuration.DEFAULT_NUM_TRANSACTIONS_SHOWN &&
              <tr key={numOfTrades + 1}>
                <td className="show-button" colSpan="3" onClick={::this._expand}>{this.state.showAllOrders ? '- Show Less' : '+ Show More'}</td>
              </tr>
            }
          </tbody>
        </ReactPlaceholder>
      </table>
    );
  }

  _expand(e) {
    e.preventDefault();
    const { account, property, tradeType } = this.props;
    if (this.state.showAllOrders) {
      scrollTo('.property-nav');
      sendSeeLessTransactionsLinkClickEvent(tradeType, property.propertyCode, account);
    } else {
      sendSeeMoreTransactionsLinkClickEvent(tradeType, property.propertyCode, account);
    }
    this.setState({
      showAllOrders: !this.state.showAllOrders
    });
  }
}
