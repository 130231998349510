import Spacing from 'src/design/styleguide/spacing/Spacing';
import PageMarginContainer from 'src/design/components/pageMarginContainer/PageMarginContainer';
import SectionHeading from 'src/components/landingPages/howItWorks/common/sectionHeading/SectionHeading';
import SubSectionHeading from 'src/components/landingPages/howItWorks/common/subSectionHeading/SubSectionHeading';
import Paragraph from 'src/design/components/bodyText/Paragraph';
import StyledBodylink from 'src/design/components/hyperlink/StyledBodylink';
import Modal from 'scripts/components/helpers/Modal';
import MedianBrickSaleTime from 'src/components/landingPages/howItWorks/howDoIExitSection/MedianBrickSaleTime';
import styles from 'src/components/landingPages/howItWorks/howDoIExitSection/HowDoIExitSection.mscss';

const HowDoIExitSection = ({ className, darkBackground }) => {
  const alignText = className === 'variant' ? 'center' : 'left';
  return (
    <PageMarginContainer
      backgroundColor={darkBackground && PageMarginContainer.colors.GREY}
      size={PageMarginContainer.sizes.THIN}
      className={className}
    >
      <section className={styles.root}>
        <img
          className={styles.headingImage}
          src="/static/img/icon-how-do-i-exit.svg"
          alt="graphic"
        />
        <SectionHeading className={styles.headingSpaceBelow}>
          How do I exit & sell my Bricks?
        </SectionHeading>

        <div className={styles.subHeadingAndContentContainer}>
          <div className={styles.subHeadingAndContentItem}>
            <Spacing bottom="normal">
              <SubSectionHeading align={alignText}>
                Freedom to sell
              </SubSectionHeading>
            </Spacing>
            <Paragraph align={alignText}>
              You can list your Bricks for sale at anytime with BrickX. Simply
              set the sale price and the number of Bricks you’d like to sell.
              Other investors can then buy them.{' '}
              <StyledBodylink
                href=""
                data-toggle="modal"
                className={styles.linkBreakToNewLine}
                data-target="#other-ways-to-exit"
              >
                Learn other ways to exit
              </StyledBodylink>
            </Paragraph>
          </div>

          <div className={styles.subHeadingAndContentItem}>
            <Spacing bottom="normal">
              <SubSectionHeading align={alignText}>
                How fast do Bricks sell?
              </SubSectionHeading>
            </Spacing>

            <Paragraph align={alignText}>
              As at 1 Jun 2024, the median time to sell Bricks was{' '}
              <strong data-test-reference="median-sale-time">
                <MedianBrickSaleTime />
              </strong>
              , based on sell orders placed at or below the last traded Brick
              price.{' '}
              <StyledBodylink
                className={styles.linkBreakToNewLine}
                href="//help.brickx.com/support/solutions/articles/17000076851-liquidity-transparency-the-two-places-to-look"
              >
                Learn more
              </StyledBodylink>
            </Paragraph>
          </div>
        </div>
      </section>
      <Modal
        id="other-ways-to-exit"
        title="Other Ways to Exit"
        body={
          <div className="row">
            <div className="col-sm-12">
              <div className="text">
                <p>
                  The easiest way to exit is by selling your Bricks on the
                  BrickX Platform.
                </p>
                Additionally, every 5 years, all Brick Holders of a particular
                property investment will be given the opportunity to vote to
                continue the BrickX property investment, or sell the property.
                Should the property be sold, Brick Holders receive their share
                of any net proceeds. Read more in our Education Centre:{' '}
                <StyledBodylink
                  href="//help.brickx.com/support/solutions/articles/17000076833-selling-bricks-and-realising-returns-"
                  target="_blank"
                >
                  <u>Selling Bricks and Realising Capital Returns</u>
                </StyledBodylink>
                .
              </div>
            </div>
          </div>
        }
      />
    </PageMarginContainer>
  );
};

export default HowDoIExitSection;
