import PageMarginContainer from 'src/design/components/pageMarginContainer/PageMarginContainer';
import { HeadingSmall } from 'src/design/components/heading/Headings';
import Paragraph from 'src/design/components/bodyText/Paragraph';
import FinePrint from 'src/design/components/bodyText/FinePrint';
import StyledBodylink from 'src/design/components/hyperlink/StyledBodylink';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import PinkAllCapsSubTitle from 'src/components/landingPages/common/pinkAllCapsSubtitle/PinkAllCapsSubTitle';

import styles from 'src/components/landingPages/smartInvest/announcements/Announcements.mscss';


const Announcements = ({ }) => {
  return (
    <PageMarginContainer>
      <section className={styles.announcementsContainer}>
        <img src="//news.brickx.com/wp-content/uploads/2018/07/Mark_Colour-1-768x512.jpg" alt="placeholder imagery" className={styles.image} />
        <div className={styles.content}>
          <PinkAllCapsSubTitle>ANNOUNCEMENTS</PinkAllCapsSubTitle>
          <Spacing top="normal" bottom="normal">
            <HeadingSmall><h3>Five minutes with a real BrickX investor: Marc Burman</h3></HeadingSmall>
          </Spacing>
          <FinePrint>By BrickX - 18 Jul 2018</FinePrint>
          <Spacing top="large" bottom="large">
            <Paragraph>Local Sydney Eastern Suburbs resident, Marc Burman, is a 20-year-old BrickX investor with a plan. He offered to spend a few minutes with us to share his story with others who may be considering...</Paragraph>
          </Spacing>
          <StyledBodylink
            withArrow
            href="//news.brickx.com/investor-stories/five-minutes-with-a-real-brickx-investor-marc-burman/"
            className={styles.readMore}
          >Read more</StyledBodylink>
        </div>
      </section>
  </PageMarginContainer>
  );
};

export default Announcements;
