import InfoBox from 'src/design/components/infoBox/InfoBox';
import StyledBodylink from 'src/design/components/hyperlink/StyledBodylink';

const ChangeBankDetailsInfoBox = ({ className }) => (
  <InfoBox className={className}>
    To change these details, please email us at <StyledBodylink href="/contact" testRef="contact-link">info@brickx.com</StyledBodylink>
  </InfoBox>
);

export default ChangeBankDetailsInfoBox;
