import { connect } from 'react-redux';
import withState from 'src/decorators/withState';

import { updatePassword as updatePasswordAction } from 'scripts/redux/actions/user';

import DocumentMeta from 'react-document-meta';
import { SETTINGS_META } from 'scripts/metadata/account';
import PageContentBlock from 'src/design/components/pageContentBlock/PageContentBlock';
import Paragraph from 'src/design/components/bodyText/Paragraph';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import Backlink from 'src/design/components/hyperlink/Backlink';
import PageHeading from 'src/design/components/heading/PageHeading';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import TextInputWithError from 'src/design/components/textInput/TextInputWithError';

import styles from 'src/components/settings/Settings.mscss';


const isNewPasswordInvalid = (password) => {
  let msg = false;
  if (!password || password.length < 8) {
    msg = 'Your new password is not long enough';
  } else if (!/[A-Za-z]/.test(password)) {
    msg = 'Your new password does not have any letters';
  } else if (!/[0-9]+/.test(password)) {
    msg = 'Your new password does not have any digits';
  }
  return msg;
};

const isNewPasswordConfirmInvalid = (a, b) => a === b ? false : 'Your new passwords do not match';

const ChangePassword = ({ state, setState, updatePassword }) => {
  const { currentPassword, newPassword, newPasswordConfirm, submitting, showErrors } = state;

  const onSubmit = () => {
    if (isNewPasswordConfirmInvalid(newPassword, newPasswordConfirm) || isNewPasswordInvalid(newPassword)) {
      setState({ showErrors: true });
    } else {
      setState({ submitting: true });
      updatePassword({ currentPassword, newPassword, newPasswordConfirm });
    }
  };

  const submitDisabled = !currentPassword
    || !newPassword
    || !newPasswordConfirm;

  return (
    <div
      className={styles.settings}
      data-test-reference="enable-smart-invest-page"
    >
      <DocumentMeta {...SETTINGS_META} />
      <PageHeading>Change Password</PageHeading>

      <PageContentBlock>
        <Spacing bottom="x-large" top="normal">
          <Paragraph>
            Your password should be at least 8 characters long, and contain at least 1 letter and 1 digit.
          </Paragraph>
        </Spacing>

        <TextInputWithError
          type="password"
          name="current-password"
          value={currentPassword}
          onChange={(value) => setState({ currentPassword: value })}
          placeholder="confirm current password"
          error={showErrors && !currentPassword && 'Please enter a password'}
          testRef="current-password"
          errorTestRef="current-password-error"
        />

        <Spacing top="small" bottom="small">
          <TextInputWithError
            type="password"
            name="new-password"
            value={newPassword}
            onChange={(value) => setState({ newPassword: value })}
            placeholder="enter new password"
            error={showErrors && isNewPasswordInvalid(newPassword)}
            testRef="new-password"
            errorTestRef="new-password-error"
          />
        </Spacing>

        <TextInputWithError
          type="password"
          name="new-password-confirm"
          value={newPasswordConfirm}
          onChange={(value) => setState({ newPasswordConfirm: value })}
          placeholder="confirm new password"
          error={showErrors && isNewPasswordConfirmInvalid(newPassword, newPasswordConfirm)}
          testRef="new-password-confirm"
          errorTestRef="new-password-confirm-error"
        />

        <Spacing top="2x-large" bottom="2x-large">
          <PrimaryButton
            fullWidth
            color={PrimaryButton.colors.SECONDARY}
            disabled={submitDisabled || submitting}
            onClick={onSubmit}
            testRef="submit-button"
          >Submit</PrimaryButton>
        </Spacing>
        <Backlink fullWidth grey testRef="go-back-button">Go Back</Backlink>
      </PageContentBlock>
    </div>
  );
};

export default connect(null, {
  updatePassword: updatePasswordAction,
})(withState({
  Component: ChangePassword,
  mapPropsToInitialState: () => ({
    currentPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
  }),
}));
