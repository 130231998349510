import withState from 'src/decorators/withState';
import PartnershipReferralConfirmation from 'src/components/partnerships/PartnershipReferralConfirmation';
import ReferralMetaTags from 'scripts/components/metatags/ReferralMetaTags';
import { sendHomePageReferralLoaded } from 'scripts/redux/actions/segment/events/referralEvents';

const Referrals = ({ tokens } = {}) => {
  const referralsToken = tokens.r;
  const partnershipProgrammeToken = tokens.p;
  return referralsToken
    ? <ReferralMetaTags referralLink={`https://www.brickx.com/?r=${referralsToken}`}/>
    : partnershipProgrammeToken
    ? <PartnershipReferralConfirmation token={partnershipProgrammeToken}/>
    : null;
};

export default withState({
  Component: Referrals,
  componentDidMount: props => props.tokens.r && sendHomePageReferralLoaded(),
});
