import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import PropertyReturnsCalculatorResultOverview from 'scripts/components/property/calculator/PropertyReturnsCalculatorResultOverview';
import ReturnsCalculatorReturnDetails from 'scripts/components/calculators/ReturnsCalculatorReturnDetails';
import ReturnsCalculatorAccountValueGraph from 'scripts/components/calculators/ReturnsCalculatorAccountValueGraph';


export default class PropertyReturnsCalculatorBreakdown extends Component {
  static propTypes = {
    investmentAmount: PropTypes.number.isRequired,
    investmentPeriod: PropTypes.number.isRequired,
    annualGrowthRate: PropTypes.number.isRequired,
    debtRate: PropTypes.number.isRequired,
    netRentalYieldRate: PropTypes.number.isRequired,
    initialPercentOfPropertyAsset: PropTypes.number.isRequired,
    initialPercentOfAcquisitionCost: PropTypes.number.isRequired,
    initialPercentOfCashReserve: PropTypes.number.isRequired,
    remainingMonthsOfAmortising: PropTypes.number,
  };

  render() {
    const {
      investmentPeriod,
      investmentAmount,
      annualGrowthRate,
      debtRate,
      netRentalYieldRate,
      initialPercentOfPropertyAsset,
      initialPercentOfAcquisitionCost,
      initialPercentOfCashReserve,
      remainingMonthsOfAmortising
    } = this.props;

    return (
      <div className="return-calculator-results">
        <PropertyReturnsCalculatorResultOverview
          investmentPeriod={investmentPeriod}
          investmentAmount={investmentAmount}
          annualGrowthRate={annualGrowthRate}
          debtRate={debtRate}
          netRentalYieldRate={netRentalYieldRate}
          initialPercentOfPropertyAsset={initialPercentOfPropertyAsset}
          initialPercentOfAcquisitionCost={initialPercentOfAcquisitionCost}
          initialPercentOfCashReserve={initialPercentOfCashReserve}
          remainingMonthsOfAmortising={remainingMonthsOfAmortising}
        />
        <div className="return-calculator-results-details">
          <div className="return-calculator-results-details__graph">
            <ReturnsCalculatorAccountValueGraph
              className="filters"
              showGraphTitleOnTop
              investmentPeriod={investmentPeriod}
              investmentAmount={investmentAmount}
              annualGrowthRate={annualGrowthRate}
              debtRate={debtRate}
              netRentalYieldRate={netRentalYieldRate}
              initialPercentOfPropertyAsset={initialPercentOfPropertyAsset}
              initialPercentOfAcquisitionCost={initialPercentOfAcquisitionCost}
              initialPercentOfCashReserve={initialPercentOfCashReserve}
              remainingMonthsOfAmortising={remainingMonthsOfAmortising}
            />
          </div>
          <div className="return-calculator-results-details__info">
            <ReturnsCalculatorReturnDetails
              hideEstimatedReturn
              investmentPeriod={investmentPeriod}
              investmentAmount={investmentAmount}
              annualGrowthRate={annualGrowthRate}
              debtRate={debtRate}
              netRentalYieldRate={netRentalYieldRate}
              initialPercentOfPropertyAsset={initialPercentOfPropertyAsset}
              initialPercentOfAcquisitionCost={initialPercentOfAcquisitionCost}
              initialPercentOfCashReserve={initialPercentOfCashReserve}
              remainingMonthsOfAmortising={remainingMonthsOfAmortising}
            />
          </div>
        </div>
        <div className="return-calculator-results__disclaimer">
          <small className="asterisk-info">
            Note: The BrickX Calculator is for illustrative purposes only and has been prepared without taking account of your objectives, financial situation or needs. You should consider the&nbsp;
            <Link to="/terms/property-returns-calculator-disclosures" target="_blank">
              Assumptions and Qualifications
            </Link>
            &nbsp;to understand how the calculator works and how the results can differ from actual investment returns. See&nbsp;
            <Link to="/pds" target="_blank">
              PDS
            </Link>
            &nbsp;to consider if BrickX is right for you.
          </small>
        </div>
      </div>
    );
  }
}
