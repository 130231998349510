import styles from 'src/components/settings/Settings.mscss';
import BodyText from 'src/design/components/bodyText/BodyText';

const TermsList = ({ keyPairs, testRef }) => (
  <ul
    className={styles.termsList}
    data-test-reference={testRef}
  >
    {keyPairs.map((item, index) => (
      <li key={index} className={styles.termItem}>
        <BodyText className={styles.termName}>{item.name}</BodyText>
        <BodyText className={styles.termValue} testRef={item.testRef}>{item.value}</BodyText>
      </li>
    ))}
  </ul>
);

export default TermsList;
