import request from 'scripts/services/request';

const post = (url, payload) => request(url, {
  headers: {
    'Content-Type': 'application/json'
  },
  method: 'POST',
  ...(payload ? { body: JSON.stringify(payload) } : {}),
});

export default post;
