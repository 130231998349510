import { createAction, handleActions } from 'redux-actions';

import PropertyService from 'scripts/services/PropertyService';
import update from 'immutability-helper';

const MONTHLY_UPDATES = 'scripts/property/MONTHLY_UPDATES';

const monthlyUpdates = createAction(MONTHLY_UPDATES);


export function fetchMonthlyUpdates(propertyCode) {
  return async function (dispatch) {
    const payload = await PropertyService.getMonthlyUpdates(propertyCode);
    return dispatch(monthlyUpdates(payload));
  };
}

const reducer = handleActions({
  [MONTHLY_UPDATES]: (state, action) => update(state, {
    $set: action.payload
  }),
}, null);

export default reducer;
