import { connect } from 'react-redux';
import { getStoredDirectDebitRequestUrl as getStoredDirectDebitRequestUrlAction } from 'scripts/redux/actions/smartInvest';
import withState from 'src/decorators/withState';

const withDdrAgreementUrl = (WrappedComponent) =>
  connect(null, {
    getStoredDirectDebitRequestUrl: getStoredDirectDebitRequestUrlAction,
  })(
    withState({
      componentDidMount: (props, state, setState) =>
        props
          .getStoredDirectDebitRequestUrl()
          .then(({ url }) =>
            setState({
              ddrAgreementUrl: url,
              ddrAgreementUrlFinishedFetching: true,
            })
          )
          .catch(() => setState({ ddrAgreementUrlFinishedFetching: true })),
      Component: ({ state, ...props }) => (
        <WrappedComponent
          {...props}
          ddrAgreementUrl={state.ddrAgreementUrl}
          ddrAgreementUrlFinishedFetching={
            state.ddrAgreementUrlFinishedFetching
          }
        />
      ),
    })
  );

export default withDdrAgreementUrl;
