import { connect } from 'react-redux';
import { showDirectDebitRequestServiceAgreement as showDirectDebitRequestServiceAgreementAction } from 'scripts/redux/actions/smartInvest';
import OnClicklink from 'src/design/components/hyperlink/OnClicklink';


const DirectDebitRequestServiceAgreementLink = ({ showDirectDebitRequestServiceAgreement, values, disabled, className, children, testRef }) => (
  <OnClicklink
    className={className}
    onClick={() => showDirectDebitRequestServiceAgreement(values)}
    disabled={disabled}
    testRef={testRef}
  >
    {children}
  </OnClicklink>
);

export default connect(null, {
  showDirectDebitRequestServiceAgreement: showDirectDebitRequestServiceAgreementAction
})(DirectDebitRequestServiceAgreementLink);
