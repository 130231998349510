import styled from 'styled-components';

const Layout = styled.div`
  display: inline;
  background-color: ${(props) => props.color || 'var(--bx-color---primary)'};
  color: #fff;
  border-radius: 12px;
  padding: 3px 8px;
  font-size: 1.2rem;
  font-weight: bold;
`;

const SmallBadge = ({ text, color }) => {
  return <Layout color={color}>{text}</Layout>;
};

export default SmallBadge;
