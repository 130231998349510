export default {
  COMING_SOON: 'coming_soon',
  PRE_ORDER: 'pre_order',
  NEW: 'new',
  MARKET_OPEN: 'market_open',
  MARKET_CLOSED: 'market_closed',
  VOTE_OPEN: 'vote_open',
  VOTE_CLOSED: 'vote_closed',
  OFF_MARKET: 'off_market',
};
