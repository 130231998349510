// import styled from 'styled-components';
// import {
//   getReferralCookie,
//   getReferralPreActivationCookie
// } from 'src/utils/referrals';
// import { getThemeColor } from 'src/themes/themeUtils';
// import {
//   gridUnit,
//   standardBorderRadius
// } from 'src/design/styleguide/common/measurements';
// import Paragraph from 'src/design/styleguide/Paragraph';

// const TextContainer = styled.div`
//   background-color: ${getThemeColor('lightBackgroundPrimary')};
//   padding: ${gridUnit * 4}px;
//   border-radius: ${standardBorderRadius};
// `;

// const getReferrerName = () => {
//   const { referrerName: referralCookieName } = getReferralCookie();
//   const { referrerName: referralPreActivationCookieName } = getReferralPreActivationCookie();
//   if (referralCookieName !== referralPreActivationCookieName) return null;

//   return referralPreActivationCookieName;
// };

const ReferralActivationFeedbackWidget = ({ className }) => {
  let temp = className;
  temp = null;
  return temp;

  // const referrerName = getReferrerName();
  // if (!referrerName) return null;

  // return (
  //   <TextContainer className={className}>
  //     <Paragraph
  //       fontSize="3x-small"
  //       align="center"
  //     >
  //       Woohoo! Your mate, <strong>{referrerName}</strong> referred you!
  //     </Paragraph>
  //   </TextContainer>
  // );
};

export default ReferralActivationFeedbackWidget;
