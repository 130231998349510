import { Component } from 'react';
import PropTypes from 'prop-types';
import Slider, { createSliderWithTooltip } from 'rc-slider';
import DynamicNumber from 'react-dynamic-number';
import classNames from 'classnames';

import Popover from 'scripts/components/shared/Popover';

import { percent } from 'scripts/utilities/formatters';
import Constants from 'scripts/constants/Constants';
import Numbers from 'scripts/constants/Numbers';

const SliderWithTooltip = createSliderWithTooltip(Slider);

const INTEGERS = Numbers.TEN;
const FRACTION = 2;

const SLIDER_MIN = 0;
const SLIDER_MAX = 5.00;
const SLIDER_STEP = 0.1;
const SLIDER_MARKS = {};

export default class SliderComponent extends Component {
  static propTypes = {
    title: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    minValue: PropTypes.number.isRequired,
    maxValue: PropTypes.number.isRequired,
    step: PropTypes.number.isRequired,
    marks: PropTypes.object,
    popover: PropTypes.string,
    icon: PropTypes.string,
    updateSliderValueCallBack: PropTypes.func.isRequired,
    hideValueInput: PropTypes.bool,
    showTitle: PropTypes.bool,
    textInputTestRef: PropTypes.string,
  };

  static defaultProps = {
    minValue: SLIDER_MIN,
    maxValue: SLIDER_MAX,
    step: SLIDER_STEP,
    marks: SLIDER_MARKS
  };

  state = {
    sliderValue: this.props.value ? this.props.value : 0,
    inputValue: this.props.value ? this.props.value : 0,
    validation: {
      error: false,
      errorMsg: ''
    }
  };

  onInputValueChange = (event) => this._updateInputValue(event.target.value);

  onSliderValueChange = (value) => this._updateInputValue(value);

  _updateInputValue = (value) => {
    this.setState({
      inputValue: value,
    });

    const numberParsedValue = parseFloat(value, 10);
    if (isNaN(numberParsedValue)) return;

    if (this._validateInputValue(numberParsedValue)) {
      this._updateSliderValue(numberParsedValue);
    }
  };

  _isEnteringNegativeValue = (inputValue) => {
    return inputValue === '-' || inputValue === '-0' || inputValue === '-0.';
  };

  _validateInputValue = (value) => {
    var isValid = true;
    const { minValue, maxValue } = this.props;
    if (value > maxValue || value < minValue) {
      isValid = false;
    }

    this._updateValidation(!isValid);
    return isValid && !this._isEnteringNegativeValue(value);
  };

  _updateValidation = (error = false) => {
    const { minValue, maxValue } = this.props;
    this.setState({
      validation: {
        error: error,
        errorMsg: `Please enter a value between ${minValue} and ${maxValue}`
      },
    });
  };

  _updateSliderValue = (value) => {
    this._updateValidation();
    this.setState({
      sliderValue: value,
    });
    this.props.updateSliderValueCallBack(value);
  };

  _percentFormatter = (v) => {
    return `${v} %`;
  };

  render() {
    const {
      title,
      name,
      minValue,
      maxValue,
      step,
      marks,
      popover,
      icon,
      hideValueInput,
      showTitle,
      textInputTestRef,
    } = this.props;
    const { sliderValue, validation, inputValue } = this.state;
    const inputFieldName = `input-${name}`;
    const sliderFieldName = `slider-${name}`;

    return (
      <div className={classNames('slider-group', `${name}-slider-group`)}>
        {showTitle &&
          <div>
            {icon && <img src={icon} alt=""/>}
            {title}
            <span className="slider-label">
              {popover && <Popover content={popover} placement="top"/>}
            </span>
          </div>
        }
        {!showTitle &&
          <div className="popover-container"/>
        }
        {!hideValueInput &&
          <div className="percent-input">
            <DynamicNumber
              id={inputFieldName}
              name={inputFieldName}
              value={inputValue}
              positive={Constants.TRUE}
              negative={Constants.TRUE}
              separator={'.'}
              integer={INTEGERS} fraction={FRACTION}
              onChange={this.onInputValueChange}
              data-test-reference={textInputTestRef}
            />
          </div>
        }
        <div className="slide-select">
          <div>
            <div className="col-xs-1 col-no-padding">{(minValue / Numbers.ONE_HUNDRED)::percent()}</div>
            <div className="col-xs-10">
              {hideValueInput
                ?
                <SliderWithTooltip tipFormatter={this._percentFormatter(sliderValue)} className={sliderFieldName} step={step} min={minValue} max={maxValue} defaultValue={sliderValue} value={sliderValue} marks={marks} onChange={this.onSliderValueChange}/>
                :
                <Slider tipFormatter={null} className={sliderFieldName} step={step} min={minValue} max={maxValue} defaultValue={sliderValue} value={sliderValue} marks={marks} onChange={this.onSliderValueChange}/>
              }
              </div>
            <div className="col-xs-1 col-no-padding text-right">{(maxValue / Numbers.ONE_HUNDRED)::percent()}</div>
          </div>
          {validation.error &&
            <div className="error error-invalid-value">
              <span>{validation.errorMsg}</span>
            </div>
          }
        </div>
      </div>
    );
  }
}
