import DocumentMeta from 'react-document-meta';
import { SETTINGS_META } from 'scripts/metadata/account';
import PageHeading from 'src/design/components/heading/PageHeading';
import PageContentBlock from 'src/design/components/pageContentBlock/PageContentBlock';
import Paragraph from 'src/design/components/bodyText/Paragraph';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import SmartInvestSettingsForm from 'src/components/settings/SmartInvestSettingsForm';
import Backlink from 'src/design/components/hyperlink/Backlink';
import Divider from 'src/design/components/divider/Divider';
import styles from 'src/components/settings/Settings.mscss';


const EnableSmartInvest = () => (
  <div
    className={styles.settings}
    data-test-reference="enable-smart-invest-page"
  >
    <DocumentMeta {...SETTINGS_META} />
    <PageHeading>Enable Smart Invest</PageHeading>

    <PageContentBlock verticallyExpanded>
      <Spacing bottom="x-small">
        <Paragraph textSize="large" align="center">Would you like to resume with the same details as before? If not, please review and change accordingly.</Paragraph>
      </Spacing>

      <Spacing top="2x-large">
        <SmartInvestSettingsForm submitButtonText="Enable Now"/>
      </Spacing>

      <Divider />

      <Spacing top="2x-large">
        <Backlink fullWidth testRef="back-link">Cancel</Backlink>
      </Spacing>
    </PageContentBlock>
  </div>
);

export default EnableSmartInvest;
