import { Component } from 'react';

import PropertyStats from 'scripts/components/property/PropertyStats';
import PropertyFlag from 'scripts/components/property/PropertyFlag';
import ExpandingCarousel from 'scripts/components/shared/Carousel/ExpandingCarousel';
import BackgroundImageSlide from 'scripts/components/shared/Carousel/BackgroundImageSlide';
import ImageSlide from 'scripts/components/shared/Carousel/ImageSlide';

import { property as propertyPropType } from 'scripts/constants/PropTypes';


const ExpandingCarouselCallToAction = ({ numberOfItems }) => (
  <div className="property-hero-expand-carousel-cta__container">
    <div className="property-hero-expand-carousel-cta">
      <div className="icn icn-camera property-hero-expand-carousel-cta__icon-camera"></div>
      <div className="property-hero-expand-carousel-cta__number-of-items">{numberOfItems}</div>
      <div className="icn icn-open property-hero-expand-carousel-cta__icon-open"></div>
    </div>
  </div>
);

export default class PropertyHero extends Component {
  static propTypes = {
    property: propertyPropType.isRequired,
  };

  render() {
    const { property } = this.props;

    const slides = property.propertyImages.map((image) => (
      <BackgroundImageSlide url={image.resized595Url} key={image.resized595Url}/>
    ));

    const expandedSlides = property.propertyImages.map((image) => (
      <ImageSlide key={image.name} imageSet={image} name={image.name}/>
    ));


    return (
      <div className="property-hero">
        <div className="container flag-container">
          <PropertyFlag propertyCode={property.propertyCode} propertyStatus={property.propertyStatus}/>
        </div>
        <ExpandingCarousel
          items={slides}
          expandItems={expandedSlides}
        />
        <div className="property-hero__property-details">
          <div className="container property-hero__property-stats-container">
            <PropertyStats property={property}/>
            <ExpandingCarouselCallToAction numberOfItems={slides.length}/>
          </div>
        </div>
      </div>
    );
  }
}
