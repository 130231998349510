import {
  TOGGLE_MENU,
  CLOSE_MENU,
  TOGGLE_SUB_MENU,
} from 'src/store/headerMenuActions';

const defaultState = {
  menuOpen: false,
  openSubMenus: {},
};

export const menuOpenSelector = (state) => ({ menuOpen: state.headerMenu.menuOpen });
export const openSubMenusSelector = (state) => ({ openSubMenus: state.headerMenu.openSubMenus });

const reducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case TOGGLE_MENU: return { ...state, menuOpen: !state.menuOpen };
    case CLOSE_MENU: return { ...state, openSubMenus: {}, menuOpen: false };
    case TOGGLE_SUB_MENU: return {
      ...state,
      openSubMenus: { [payload]: !state.openSubMenus[payload] }
    };
    default: return state;
  }
};

export default reducer;
