import React, { Component } from 'react';
import PrivacyPolicyMeta from 'src/components/meta/PrivacyPolicyMeta';


export default class Privacy extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="container">
        <PrivacyPolicyMeta/>
        <div className="row">
          <div className="col-lg-12 terms-page">
            <h1>Privacy Policy</h1>

            <h2>We are committed to protecting your privacy</h2>

            <p>
              We respect the confidentiality of your information and take your privacy very seriously,
              abiding by the Australian
              Privacy Principles (APPs) contained in the Privacy Act 1988 (Cth) (Privacy Act).
            </p>

            <p>
              The purpose of this document is to describe how The Brick Exchange Pty Limited ACN 600 762
              749 and all its related entities (“BrickX”, “we”, “us” or “our”) manages any Personal
              Information that we collect in connection with your use of The BrickX Platform (ARSN 613 156
              433), including its website (BrickX Platform).
            </p>

            <p>
              For the purposes of this Privacy Policy, Personal Information means information or an
              opinion, whether true or not and whether recorded
              in a material form or not, about an identified individual, or an individual who is
              reasonably identifiable.
            </p>

            <p>
              This Privacy Policy applies to Personal Information collected from Members and potential
              Members. Please see the Product Disclosure
              Statement for more information about being a Member.
            </p>

            <p>
              This document together with the BrickX Product Disclosure Statement, Terms and Conditions
              and the BrickX Platform Constitution and any
              other terms and conditions adopted by us from time to time and available on the BrickX
              Platform, constitute the legal relationship between you and us in respect of your use of the
              BrickX Platform.
            </p>

            <p>
              Unless you notify us otherwise, we will assume that you have consented to the collection of
              all information which is provided to us for
              use in accordance with this Privacy Policy.
            </p>

            <p>
              Capitalised terms not otherwise defined in this Privacy Policy have the meaning given in the
              Product Disclosure Statement relating to
              the BrickX Platform.
            </p>


            <h2>Management of Personal Information</h2>
            <h4>The Type Of Information BrickX Collects</h4>

            <p>
              1. In order to provide Members with the products and services that they request we will need
              to collect Personal
              Information from Members and other individuals (for example business contacts service
              providers and prospective Members).
            </p>
            <div className="indented">

              <p>
                1.1. The type of Personal Information that we may collect from you will depend on our
                relationship with you and the circumstances of collection. Information we collect may
                include, but is not limited to, the following:
                <ul>
                  <li>Name, age, date of birth and place of birth.</li>
                  <li>Residential address, business address, email address
                    and contact telephone numbers.
                  </li>
                  <li>Information that is required or authorised by law.</li>
                  <li>Opinions about our products, services and/or staff.</li>
                  <li>Bank account details and credit / debit card details.</li>
                  <li>Details about BrickX Platform users through the use of cookies.</li>
                </ul>
              </p>

              <p>
                1.2. BrickX may collect sensitive information in order to comply with the Anti-Money
                Laundering
                and Counter-Terrorism Financing Act 2006 (Cth) (AML/CTF Act) provided that the
                requirements in the
                section of this Privacy Policy relating to Sensitive Information are satisfied before
                the information
                is collected. This sensitive information may include government identifiers such as your
                tax file
                number.
              </p>

              <p>
                1.3. We may also collect copies of identification documents from you, for example
                drivers licences,
                birth certificates and passports.
              </p>

              <p>
                1.4. If you do not provide us with the information we request, we may not be able to
                fulfil the applicable
                purpose of collection, such as to supply products or services to you.
              </p>

              <p>
                1.5. When you access and use the BrickX Platform we will record and log for statistical
                purposes certain
                information about such access and use, including your IP address, the date and time of
                your visit,
                the parts of the BrickX Platform you access during your visit, your actions on the
                BrickX Platform,
                and the browser you are using.
              </p>
            </div>

            <h4>Collection</h4>

            <p>
              2. Information provided on registration to BrickX is collected for the primary purpose of
              acquiring an Interest
              and ultimately transacting Bricks. BrickX only collects Personal Information that is
              reasonably necessary for one
              or more of the functions or activities described in this Privacy Policy.
            </p>

            <div className="indented">
              <p>
                2.1. The information will also be used to forward to you periodic information relating
                to your investment
                in BrickX and from time to time to provide to you with information of a generic or
                marketing nature
                relating to BrickX.
              </p>
              <p>
                2.2. By completing and submitting an online application, you provide your consent to
                BrickX disclosing
                your information to Service Providers and to use your information for the purposes
                referred to
                described in this Privacy Policy.
              </p>
              <p>
                2.3. To access or update your Personal Information, please visit <a href="/login">www.brickx.com/login</a>,
                click on
                ‘My Account’ and then ‘Settings’ or contact BrickX at info@brickx.com.
              </p>
              <p>
                2.4. Before we collect your Personal Information, or as soon as practicable after, we
                will take steps
                to notify you of the collection of your Personal Information and to remind you to review
                this
                Privacy Policy for details about the purpose for which such information is collected,
                how such
                information is used (including disclosure) and how you can access and correct such
                information.
              </p>
              <p>
                2.5. If we are legally compelled to collect or disclose your Personal Information to a
                third
                party we will notify you of this fact.
              </p>
              <p>
                2.6. Where we no longer need your Personal Information, and where we are not required by
                or
                under an Australian law or a court/tribunal order to retain such information, we will
                take reasonable
                steps to destroy or de-identify such information.
              </p>
            </div>

            <h4>Electronic Disclosure</h4>

            <p>
              3. Participation on the BrickX Platform is designed to be effected and managed online via
              the BrickX Platform and email.
            </p>

            <div className="indented">
              <p>3.1. You must nominate an email address when you apply and register via the Platform. The
                email address you nominate
                at registration will be the address to which we will send you information about BrickX
                and any investments you make
                via the platform. By nominating an email address, you consent to receiving the following
                documents by email to that
                address (or to any other email address nominated by you in your BrickX Account):</p>
              <ul>
                <li>Your initial membership confirmation.</li>
                <li>Notices of material changes or significant events.</li>
                <li>Product related promotional and offer material.</li>
                <li>Education Centre content</li>
                <li>Financial and operational content.</li>
                <li>Supplementary disclosure documents</li>
              </ul>
              <p>3.2. As a BrickX Member you will receive financial and operational communications related
                to your BrickX Account.</p>
            </div>

            <h2>Use and Disclosure</h2>
            <h4>Use of personal information</h4>

            <p>
              4. BrickX collects Personal Information for the purpose of conducting our business and
              providing
              products and services requested by you. Our policy is to use or disclose Personal
              Information
              for the primary purpose for which it was collected or for a secondary related purpose where
              you
              would reasonably expect us to use or disclose the Personal Information for that secondary
              purpose,
              or where such use or disclosure is required or authorised by or under an Australia law or
              court/tribunal order. We will only disclose sensitive information for a secondary purpose
              where
              the secondary purpose is directly related to the primary purpose, or where we are legally
              compelled
              to disclose such information.
            </p>

            <div className="indented">
              <p>4.1. In general, our uses of Personal Information include, but are not limited to:
                <ul>
                  <li>establishing your identity;</li>
                  <li>managing your investment/s and our relationship with you;</li>
                  <li>providing you with the products or services you have requested;</li>
                  <li>providing you with updates in relation to your investment/s;</li>
                  <li>communicating with you about the products and services that we offer (including
                    calling and emailing);
                  </li>
                  <li>managing relations with third parties;</li>
                  <li>conducting and improving our business to help us manage and enhance our products
                    and
                    services and improve the investor experience;
                  </li>
                  <li>human resources, compliance, quality assurance and staff training purposes;</li>
                  <li>managing our security and information control systems;</li>
                  <li>complying with our legal obligations, and assisting government and law
                    enforcement agencies
                    and/or regulators; and
                  </li>
                  <li>identifying other products and services that we think may be of interest to
                    you.
                  </li>
                </ul>
              </p>
            </div>

            <h4>Disclosure to Third Parties</h4>

            <p>
              5. We take your privacy very seriously and take reasonable steps to confirm that third
              parties, with whom we deal,
              are also compliant with the Privacy Act. In executing services, activities and functions,
              where necessary,
              Personal Information may be disclosed to third parties and/or related bodies corporate
              currently located
              in Australia.
            </p>
            <div className="indented">
              <p>
                5.1. Your Personal Information will not be made available to any third party, other than
                as
                required by law and to service providers for permitted related purposes (for example,
                auditors,
                consultants and advisers) for the purpose of administering the investment.
              </p>
              <p>
                5.2. Under no circumstances will we sell Personal Information. We only use or disclose
                Personal
                Information where it is reasonably necessary for, or directly related to, one or more of
                our
                functions or activities; or because it is required under relevant law or regulation .
                Personal
                Information will only be given to other organisations where one or more of the following
                situations
                applies:
              </p>
              <p>
                <ul>
                  <li>We have consent from the individual.</li>
                  <li>The individual would reasonably expect,
                    or has been advised that Personal Information
                    may be used or disclosed.
                  </li>
                  <li>If the use or disclosure is required by
                    lawful authority.
                  </li>
                  <li>If the use or disclosure relates to one
                    or more Permitted General Situations under
                    the Privacy Act including but not limited to)
                    a serious and imminent threat to somebody’s life or
                    health, or if it is reasonably necessary for law
                    enforcement.
                  </li>
                </ul>
              </p>
            </div>

            <h4>Cross Border Disclosure </h4>
            <p>6. BrickX does not intend to send or disclose your Personal Information to overseas
              recipients, but may transfer your Personal Information to a foreign recipient (including
              when an overseas entity accesses the information in Australia), if we reasonably believe
              that the below are true:

              <ul>
                <li>The recipient is subject to law, or a binding scheme,
                  that has the effect of protecting your Personal
                  Information in a way that, overall, is at least
                  substantially similar to the APPs.
                </li>
                <li>The disclosure is required or authorised by or
                  under an Australian law or a court/tribunal order.
                </li>
                <li> The transfer is necessary for the performance of a
                  contract with you (from which the information was
                  collected).
                </li>
                <li>The transfer is for your benefit (and all other APP
                  requirements are met).
                </li>
              </ul>
              <p>

                <p>
                  7. In the conduct of our business, we may transfer to, hold or access Personal
                  Information from various countries including Australia, the United States of
                  America, Canada and the United Kingdom. The privacy laws of those countries may not
                  provide the same level of protection as the privacy laws of the country from which
                  the personal information was collected. However, this does not change our
                  commitments to safeguard your privacy and we will comply with all applicable laws
                  relating to the cross-border data disclosure. Where disclosure is to be made to an
                  overseas entity, we will assess the privacy laws of the country where information
                  will be disclosed to determine whether the overseas recipient is required to comply
                  with privacy laws that are at least as stringent as the APP requirements in relation
                  to information. We may enter into a written contract with the overseas recipient to
                  enable us to enforce protection of the Personal Information that we provide to the
                  overseas recipient and ensure that the overseas entity does not breach the APPs.
                </p>

                <h2> Sensitive Information</h2>
                <p> 8. We only collect sensitive information reasonably necessary for one or more of the
                  uses
                  specified in this Privacy Policy if we have the consent of the individuals to whom
                  the sensitive
                  information relates, or if the collection is reasonably necessary for one or more of
                  our
                  functions or activities.
                </p>

                <p> 9. We may also collect sensitive information in circumstances prescribed by the
                  Privacy
                  Act and APPs, including if required or authorised by or under an Australian law or a
                  court/tribunal.
                </p>

                <h2>Unsolicited Information </h2>
                <p>10. Sometimes we may be provided with your Personal Information without having sought
                  it
                  through our normal means of collection. We refer to this as “unsolicited
                  information”.
                </p>

                <div className="indented">
                  <p>10.1.
                    Where we collect unsolicited information we will only hold, use and or
                    disclose that information if we could otherwise do so had we collected
                    it by means permitted by the APPs and this Privacy Policy. If that unsolicited
                    information could not have been collected by a permitted means then we
                    will destroy, permanently delete or de-identify the information as appropriate.
                  </p>
                </div>


                <h2>Direct Marketing</h2>
                <p>11. We may also use and disclose your Personal Information to keep you informed about
                  products
                  and services offered by BrickX.
                </p>
                <div className="indented">

                  <p>
                    11.1. Where permitted under the APPs and other Australian laws, we may from time
                    to time use your Personal Information to send you:
                    <ul>
                      <li>Offers, updates, events, articles, newsletters or other
                        information about our products and services that we believe
                        will be of interest to you.
                      </li>
                      <li>Offers to subscribe to our blog, social media or
                        announcements/updates and promotional offers.
                      </li>
                    </ul>
                  </p>
                  <p>
                    11.2. You can opt out of receiving direct marketing information from us
                    at any time you can do this by using the unsubscribe facility in your
                    BrickX Account:
                  </p>
                  <p>
                    11.3. If you elect to subscribe to any of our publications or for any
                    notifications and later decide that you no longer wish to receive monthly
                    updates,
                    social media, updates or promotional material from us, you may unsubscribe by
                    following
                    the instructions contained in the relevant electronic marketing message.
                  </p>
                </div>

                <h2>How Your Information is Stored and Protected by BrickX </h2>
                <p>
                  12. We strive to maintain relevant, reliable, accurate, complete and current
                  Personal Information and to protect its privacy and security.
                </p>
                <div className="indented">

                  <p>12.1. BrickX will take reasonable steps to protect your Personal Information
                    from misuse, interference and loss, as well as unauthorised access,
                    modification or disclosure. We hold the Personal Information we collect
                    on secure servers or in physical storage located in controlled environments
                    and protect it by using encrypted and up-to-date industry standard software
                    protection programs and locks.
                  </p>
                  <p>12.2.
                    Personal Information is only accessible by officers, agents, service providers,
                    contractors and employees of BrickX on a need to know basis, unless it is
                    disclosed to another party in accordance with this Privacy Policy. All
                    employees are required to maintain the confidentiality of any Personal
                    Information they handle.
                  </p>
                  <p>
                    12.3. If other parties provide support services to BrickX, we require
                    them to implement appropriate safeguards to protect the privacy of
                    the Personal Information we provide to them. This may include requiring
                    the execution of non-disclosure agreements to ensure the confidentiality
                    of Personal Information. Where the Personal Information is no longer
                    needed or required to be retained we take such steps as are reasonable
                    in the circumstances to destroy the information or to ensure that the
                    information is de-identified.
                  </p>
                </div>
              </p>

              <h2>How You can Access Your Personal Information</h2>
              <h4>Contact Details</h4>
              <p>
                13. You are welcome to contact BrickX to request further information about this
                Privacy Policy, request access to your Personal Information or make a request that
                your Personal Information be corrected and/or updated.
              </p>

              <div className="indented">
                <p>13.1. BrickX is permitted to refuse access to Personal Information in
                  certain situations. Examples include where:
                  <ul>
                    <li>Giving access would have an unreasonable impact
                      on the privacy of other individuals.
                    </li>
                    <li>Giving access would be unlawful, or where denying
                      access is required or authorised by an Australian law
                      or a court order.
                    </li>
                    <li>Both of the following apply:</li>
                    <ul>
                      <li>We have reason to suspect that unlawful
                        activity, or misconduct of a serious nature that
                        relates to our functions or activities has been,
                        is being or may be engaged in.
                      </li>
                      <li>Giving access would be likely to prejudice
                        the taking of appropriate action in relation to the matter.
                      </li>
                    </ul>
                    <li>Giving access is likely to interfere with law enforcement activities.</li>
                  </ul>
                </p>
                <p>
                  13.2. BrickX will take reasonable steps to provide a copy of this Policy
                  to anyone who requests it, free of charge. We do not charge for receiving
                  a request to access or correct Personal Information, although we may charge
                  a reasonable amount to cover the cost of processing such a request.
                  In this situation, BrickX will provide an estimate of how much the service
                  will cost and request an agreement to the cost before proceeding.
                </p>
                <p>
                  13.3. Further information can be requested using the contact details set out below.
                  <ul>
                    <li>The Brick Exchange Pty Limited</li>
                    <li>Level 27, 101 Collins Street, Melbourne VIC 3000 Australia</li>
                    <li>Email: info@brickx.com</li>
                  </ul>
                </p>
              </div>


              <h2>Request for Correction of Information </h2>

              <p>
                14. We strongly encourage you to ask us to correct any Personal Information we hold that
                is out-of-date,
                incorrect, incomplete or misleading.
              </p>

              <div className="indented">
                <p>14.1. BrickX will respond to a request for correction of Personal Information within
                  a
                  reasonable period after the request is made. We may refuse access to your
                  information
                  or refuse a request for correction in limited cases, including where there are
                  legal,
                  regulatory or administrative reasons to deny access.
                </p>
                <p>14.2. If BrickX refuses to correct the Personal Information as requested by you,
                  BrickX will provide you with a written notice that sets out:
                  <ul>
                    <li>The reasons for the refusal except to the extent that it would be
                      unreasonable to do so.
                    </li>
                    <li>The mechanisms available to complain about the refusal.</li>
                  </ul>
                </p>
                <p>14.3. If BrickX refuses to correct the Personal Information, it will keep with the
                  record
                  an indication that you have requested that the information be corrected.
                </p>
              </div>


              <p>
                15. To request a correction of your Personal Information, please contact Customer
                Services at info@brickx.com.
              </p>

              <h2>Platform Analytics and Cookies</h2>

              <p>
                16. Platform analytics measurement software is used to assist in tracking traffic
                patterns to and from
                the BrickX Platform to survey users of the sites. The information gathered is not
                personally identifiable
                and is used to improve the BrickX Platform.
              </p>

              <p>
                17. Cookies are small pieces of text stored on your computer to help us determine the
                type of browser
                and settings you are using, where you have been on the BrickX Platform, when you return
                to the BrickX
                Platform, where you came from, and to ensure your information is secure. The purpose of
                this
                information is to provide you with a more relevant and effective experience on the
                BrickX Platform,
                including presenting web pages according to your needs or preferences.
              </p>

              <p>
                18. BrickX may also use external service providers to track the traffic and usage on the
                BrickX Platform.
                We use this information to help us refine our marketing and messaging through online
                advertising.
              </p>

              <p>
                19. Cookies are frequently used on many platforms on the internet and you can choose if
                and how a cookie
                will be accepted by changing your preferences and options in your browser. You may not
                be able to access
                some parts of the BrickX Platform if you choose to disable the Cookie acceptance in your
                browser, particularly
                the secure parts of the BrickX Platform. We therefore recommend you enable cookie
                acceptance to benefit
                from all the services on the BrickX Platform.
              </p>

              <h2> AML/CTF ACT</h2>

              <p>
                20. Before we provide a product or service to you, we may be required to collect your
                Personal Information
                to comply with our customer identification obligations under the AML/CTF Act.
              </p>

              <p>
                21. The Personal Information we collect for this purpose typically includes
                identification information,
                such as name, residential address and date of birth. In some cases, we may need to
                collect information about
                the political activities and opinions of individuals to determine whether they are
                politically exposed
                persons for the purposes of the AML/CTF Act. If we ask for this information, and you
                provide it to us, we will
                infer your consent to use and disclose the information for this purpose.
              </p>

              <p>
                22. In certain circumstances, we may need to clarify or update the Personal Information
                we previously
                collected for identity verification purposes, or collect further information, including
                financial information
                about individuals. Where this is necessary, we will collect the information directly
                from you or from third
                party sources.
              </p>

              <p>
                23. See the Terms and Conditions for further details about the information about our
                obligations and your rights
                in connection with a disclosure to a credit reporting agency.
              </p>

              <p>
                24. You have the right to let us know by email at info@brickx.com if you do not want us
                to disclose your personal
                information to a credit reporting agency for this purpose and opt for an alternative
                means of verifying your
                identity for the purposes of the AML/CTF Act.
              </p>

              <h2>About this Privacy Policy</h2>

              <p>
                25. The Privacy Policy is reviewed from time to time to take account of new laws and
                technology, changes
                to our operations and practices, and to make sure it remains appropriate to the changing
                environment.
              </p>

              <p>
                26. This Privacy Policy is publicly available on our Platform at <a
                href="www.brickx.com/privacy">www.brickx.com/privacy</a>
              </p>

              <p>
                27. This Privacy Policy was last updated on 16 October 2020.
              </p>

              <h2>If you have an enquiry or complaint about this Privacy Policy</h2>
              <p>
                28. If you wish to make an enquiry about, or request access to, your Personal
                Information or if you have any
                complaint in relation to the manner in which your information has been handled, or about
                a breach of the
                APPs or a registered APP code (if any) that binds BrickX, please contact Customer
                Services at info@brickx.com.
              </p>

              <p>
                29. We endeavour to respond to complaints within a reasonable period (usually 30 days).
              </p>

              <p>
                30. If you are not satisfied with our response, you may make a complaint to the Office
                of the Australian
                Information Commissioner by phoning 1300 363 992 or by email at enquiries@oaic.gov.au.
              </p>
            </p>

          </div>
        </div>
      </div>
    );
  }
}
