import { Component } from 'react';
import { Router } from 'react-router';
import PropTypes from 'prop-types';
import { getRoutes } from 'scripts/routes/routes';
import { hot } from 'react-hot-loader/root';

class Root extends Component {
  static propTypes = {
    history: PropTypes.object,
    store: PropTypes.object,
  };

  render() {
    return (
      <Router history={this.props.history}>
        {getRoutes(this.props.store)}
      </Router>
    );
  }
}

export default hot(Root);
