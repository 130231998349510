import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import {
  property as propertyPropType,
  user as userPropType,
  tradeSucceed as tradeSucceedPropType,
} from 'scripts/constants/PropTypes';
import { tradeSucceedSelector } from 'scripts/redux/selectors/market';
import { sendTradeSuccessEvent } from 'scripts/redux/actions/segment/events/tradeEvents';

import TfnCompactLayout from 'scripts/components/account/settings/TfnCompactLayout';
import Modal from 'scripts/components/helpers/Modal';
import Hyperlink from 'scripts/components/shared/Hyperlink';
import Loading from 'src/components/loading/Loading';

import { MOBILE_LARGE } from 'scripts/constants/WindowSize';
import Trade from 'scripts/constants/Trade';
import { hasTfnProvided } from 'scripts/utilities/userAccountHelper';
import { brick } from 'scripts/utilities/formatters';
import { renderCode } from 'src/settings/properties';

@connect(tradeSucceedSelector)
export default class TradeSucceed extends Component {
  static propTypes = {
    user: userPropType,
    property: propertyPropType,
    tradeSucceed: tradeSucceedPropType,
  };

  state = {
    tradeSucceedEventSent: false,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    if (window.innerWidth <= MOBILE_LARGE) {
      const body = document.querySelector('body');
      const modalBackdrop = document.querySelector('.modal-backdrop');
      if (body) {
        body.classList.remove('modal-open');
      }
      if (modalBackdrop) {
        modalBackdrop.parentNode.removeChild(modalBackdrop);
      }
    }

    const { tradeSucceed } = this.props;
    if (!tradeSucceed) {
      return;
    }

    this._sendTradeSucceedEvent();

    if (
      tradeSucceed.data.quantityTransacted !==
      tradeSucceed.data.quantityProposed
    ) {
      this.partialTradeTransactedAlertModal.open();
    }
  }

  render() {
    const { user, property, tradeSucceed } = this.props;
    if (!property || !tradeSucceed) {
      return <Loading />;
    }

    const propertyPictureUrl = property.propertyImages[0]
      ? property.propertyImages[0].resized595Url
      : '';
    const bg = { backgroundImage: `url(${propertyPictureUrl})` };

    var greeting = 'Congratulations, you now own';
    if (tradeSucceed.type === Trade.TRADE_TYPE.PRE_ORDER) {
      greeting = 'Congratulations, you have Pre-Ordered';
    } else if (tradeSucceed.type === Trade.TRADE_TYPE.SELL) {
      greeting = 'You have placed a Sell Order for';
    }

    const quantityTransacted = tradeSucceed
      ? tradeSucceed.data.quantityTransacted
      : 0;

    return (
      <div id="trade-succeed" data-test-reference="trade-success">
        <div className="congratulation-bar hidden-xs">
          <div className="property-image" style={bg}></div>
          <div className="container">
            <div className="row">
              <img
                src="https://sasinator.realestate.com.au/rea/count/advid=11810/actname=Ad-Complete"
                height="1"
                width="1"
              />
              <div className="col-md-12">
                <img
                  src="/static/images/icons/transaction-confirmation-pink(500x500).png"
                  className="trade-confirmation"
                />
                <div className="congratulations">
                  <span className="congratulations-text">
                    {greeting}
                    <br />
                    <span className="text-orange">
                      {quantityTransacted::brick()}
                    </span>{' '}
                    in {renderCode(property.propertyCode)}.
                    <span className="confirmation-email-text">
                      A confirmation email has been sent.
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="congratulation-bar visible-xs">
          <div className="container">
            <div className="col-xs-12">
              <img
                src="/static/images/icons/icn_confirm2.png"
                className="trade-confirmation"
              />
            </div>
            <div className="col-xs-12">
              <div className="congratulations">
                <span
                  className={classNames(
                    'congratulations-text',
                    tradeSucceed.type
                  )}
                >
                  {greeting}
                  <br />
                  <span className="text-orange">
                    {quantityTransacted::brick()}
                  </span>{' '}
                  in {renderCode(property.propertyCode)}
                  <span className="confirmation-email-text">
                    A confirmation email has been sent
                  </span>
                </span>
              </div>
            </div>
            <div className="col-xs-12">
              <div className="property-image" style={bg}></div>
            </div>
          </div>
        </div>

        <div className="hidden-xs">
          <div className="container">
            <div className="row actions">
              <div className="col-md-6 text-right">
                <Hyperlink
                  linkTo="/properties"
                  className="button orange-button right-arrow-button"
                >
                  All properties
                </Hyperlink>
              </div>
              <div className="col-md-6 text-left">
                <Hyperlink
                  linkTo={`/properties/${tradeSucceed.data.propertyCode}/summary`}
                  className="button orange-button right-arrow-button"
                >
                  Back to {renderCode(tradeSucceed.data.propertyCode)}
                </Hyperlink>
              </div>
            </div>
          </div>

          {!hasTfnProvided(user) && (
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="tfn-box">
                    <TfnCompactLayout />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <Modal
          ref={(el) => (this.partialTradeTransactedAlertModal = el)}
          title={
            <span className="text-orange">
              <span className="fa fa-exclamation-triangle" /> ATTENTION:
              Transaction Notice
            </span>
          }
          id="partial-trade-transacted-alert-modal"
          sizeClass="modal-md"
          body={
            <div className="transaction-notice">
              <p>
                There were not enough Bricks remaining to process your entire
                Pre-Order for {renderCode(property.propertyCode)}. You have
                successfully transacted the very last{' '}
                <span className="text-orange">
                  {quantityTransacted::brick()}
                </span>{' '}
                available for Pre-Order in the{' '}
                {renderCode(property.propertyCode)} property, there are now 0
                Bricks remaining.
              </p>
            </div>
          }
          footer={
            <div>
              <button
                className="button orange-button right-arrow-button acknowledge-transaction-notice"
                onClick={() => {
                  this.partialTradeTransactedAlertModal.close();
                }}
              >
                <span>I ACKNOWLEDGE</span>
              </button>
            </div>
          }
        />
      </div>
    );
  }

  _sendTradeSucceedEvent = () => {
    const {
      tradeSucceed: { type, data },
    } = this.props;
    const { tradeSucceedEventSent } = this.state;
    if (!tradeSucceedEventSent) {
      sendTradeSuccessEvent(type, {
        propertyCode: data.propertyCode,
        quantity: data.quantityTransacted,
        brickPrice: data.basisPrice,
        tradeAmount: data.valueOfBricks,
        commissionAmount: data.transactionFee,
        total: data.total,
      });
      this.setState({ tradeSucceedEventSent: true });
    }
  };
}
