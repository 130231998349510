import { createAction, handleActions } from 'redux-actions';
import update from 'immutability-helper';
import AlertType from 'scripts/constants/AlertType';

const ALERT = 'scripts/alerts/ALERT';
const CLOSE = 'scripts/alerts/CLOSE';

const alert = createAction(ALERT);
const close = createAction(CLOSE);

export const successAlert = (message) => {
  let payload = message;
  if (!message) {
    payload = 'Your request has been successfully processed.';
  }
  return alert({
    type: AlertType.SUCCESS,
    message: payload
  });
};

export const errorAlert = (message) => {
  let payload = message;
  if (!message) {
    payload = 'Unable to processed your request.';
  }
  return alert({
    type: AlertType.ERROR,
    message: payload
  });
};

export const closeAlert = (index) => close(index);

const reducer = handleActions({
  [ALERT]: (state, action) => update(state, {
    $push: [
      action.payload
    ]
  }),
  [CLOSE]: (state, action) => update(state, {
    $splice: [
      [action.payload, 1]
    ]
  })
}, []);

export default reducer;
