import { Component } from 'react';

import {
  property as propertyPropType,
  user as userPropType,
} from 'scripts/constants/PropTypes';

import FinancialsPanel from 'scripts/components/shared/FinancialsPanel';
import ComingSoonInfoBox from 'scripts/components/property/trade/ComingSoonInfoBox';
import { renderCode } from 'src/settings/properties';

export default class InvestPanelComingSoon extends Component {
  static propTypes = {
    user: userPropType.isRequired,
    property: propertyPropType.isRequired,
  };

  render() {
    const { property } = this.props;
    return (
      <div className="coming-soon-panel">
        <div className="mobile visible-xs">
          <ComingSoonInfoBox property={property} />
        </div>
        <FinancialsPanel
          className="panel-financials-blue-header panel-financials-invest panel-pre-order hidden-xs"
          title={`Pre-order ${renderCode(property.propertyCode)}`}
        >
          <ComingSoonInfoBox property={property} />
        </FinancialsPanel>
      </div>
    );
  }
}
