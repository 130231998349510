import { Fragment } from 'react';
import {
  PanelValueHeading,
  SeeMoreLink,
  WelcomePageWidgetPanel
} from 'src/components/dashboard/home/commonComponents';
import { detailedTransactionsSelector } from 'scripts/redux/selectors/transactions';
import { documentsUrl, tradeConfirmationDocumentsUrl } from 'src/utils/pageUrls';
import { fetchDetailedTransactions } from 'scripts/redux/actions/transactions';
import { fetchMyTrades } from 'scripts/redux/actions/market';
import { getAllAnnualStatementLinkDetails } from 'src/utils/transactions';
import { myTradesSelector } from 'scripts/redux/selectors/market';
import Loading from 'src/components/loading/Loading';
import Paragraph from 'src/design/styleguide/Paragraph';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import Styledlink from 'src/design/components/hyperlink/Styledlink';
import withDdrAgreementUrl from 'src/decorators/withDdrAgreementUrl';
import withFetching from 'src/decorators/withFetching';

const getKeyDocumentLinkProps = (annualStatements, trades, ddrAgreementUrl) => {
  if (annualStatements.length) return { children: annualStatements[0].title, href: annualStatements[0].link };
  if (trades.length) return { children: 'Transaction Confirmations', href: tradeConfirmationDocumentsUrl() };
  if (ddrAgreementUrl) return { children: 'Direct Debit Agreement', href: ddrAgreementUrl, target: '_blank' };
};

const DocumentsPanelContent = withFetching([
  detailedTransactionsSelector,
  myTradesSelector,
], [
  fetchDetailedTransactions,
  fetchMyTrades,
])(withDdrAgreementUrl(({ detailedTransactions, ddrAgreementUrl, ddrAgreementUrlFinishedFetching, myTrades }) => {
  if (!detailedTransactions || !myTrades.list || !ddrAgreementUrlFinishedFetching) return <Loading notFullHeight/>;

  const keyDocumentLinkProps = getKeyDocumentLinkProps(
    getAllAnnualStatementLinkDetails(detailedTransactions),
    myTrades.list,
    ddrAgreementUrl
  );

  return keyDocumentLinkProps
    ? (
      <Fragment>
        <PanelValueHeading>Featured Document</PanelValueHeading>
        <Spacing top="2x-small">
          <Paragraph>
            <Styledlink
              {...keyDocumentLinkProps}
              testRef="documents-panel-key-document-link"
            />
          </Paragraph>
        </Spacing>
      </Fragment>
    )
    : <Paragraph>You do not have any documents yet.</Paragraph>;
}));

const DocumentsPanel = ({ className }) => (
  <WelcomePageWidgetPanel
    className={className}
    title="Documents"
    action={<SeeMoreLink href={documentsUrl()}/>}
    testRef="documents-panel"
  >
    <DocumentsPanelContent/>
  </WelcomePageWidgetPanel>
);

export default DocumentsPanel;
