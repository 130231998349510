import { getGaData } from 'src/browser/ga';

const getExperimentsFrom = (gaProperty, experiments) => experiments
  ? Object.entries(experiments).map(([experimentId, variant]) => ({ experimentId, gaProperty, variant }))
  : [];

export const extractAllExperimentsFromGaData = () =>
  Object.entries(getGaData() || {}).reduce((allExperiments, [gaProperty, { experiments }]) => [
    ...allExperiments,
    ...getExperimentsFrom(gaProperty, experiments),
  ], []);
