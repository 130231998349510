import authStore from 'scripts/auth/authStore';

const uploadFile = (url, payload, { onProgress } = {}) => new Promise((resolve, reject) => {
  const formData = new FormData();
  formData.append('filename', payload.filename);
  formData.append('filebase64', payload.filebase64);
  const request = new XMLHttpRequest();
  request.onload = (e) => e.target.status >= 400 ? reject(e) : resolve(e);
  request.onerror = (e) => reject(e);
  if (onProgress) request.upload.onprogress = onProgress;
  request.open('POST', url);
  request.setRequestHeader('Authorization', authStore.getAuthToken());
  request.send(formData);
});

export default uploadFile;
