const monthlyDistributionsGetter = (state) => state.property.monthlyDistributions;
const monthlyPaidDistributionsGetter = (state) => state.property.monthlyPaidDistributions;
const allMonthlyDistributionsGetter = (state) => state.property.allMonthlyDistributions;

export const monthlyDistributionsSelector = (state) => ({
  monthlyDistributions: monthlyDistributionsGetter(state)
});

export const monthlyPaidDistributionsSelector = (state) => ({
  monthlyPaidDistributions: monthlyPaidDistributionsGetter(state)
});

export const allMonthlyDistributionsSelector = (state) => ({
  allMonthlyDistributions: allMonthlyDistributionsGetter(state)
});
