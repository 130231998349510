import { Fragment } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { HeadingXSmall } from 'src/design/components/heading/Headings';
import { gridUnit } from 'src/design/styleguide/common/measurements';
import { pdsUrl } from 'src/utils/pageUrls';
import FinePrint from 'src/design/components/bodyText/FinePrint';
import Image from 'src/design/components/image/Image';
import ListItemNumber from 'src/components/landingPages/common/listItemNumber/ListItemNumber';
import PageMarginContainer from 'src/design/components/pageMarginContainer/PageMarginContainer';
import Paragraph from 'src/design/components/bodyText/Paragraph';
import SectionHeading from 'src/components/landingPages/howItWorks/common/sectionHeading/SectionHeading';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import Styledlink from 'src/design/components/hyperlink/Styledlink';
import styles from 'src/components/landingPages/common/howItWorksSection/HowItWorksSection.mscss';

const IllustratedPointContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  padding-bottom: ${gridUnit * 16}px;
`;

const IconContainer = styled.div`
  max-width: ${gridUnit * 16}px;
  margin-top: ${gridUnit * 4}px;
  margin-bottom: ${gridUnit * 4}px;
`;

const IllustratedPoint = ({ className, src, children }) =>
  <IllustratedPointContainer className={className}>
    <ListItemNumber color="salmon"/>
    <IconContainer>
      <Image src={src} alt="illustration"/>
    </IconContainer>
    <Paragraph className={styles.illustratedPoints__item_text}>{children}</Paragraph>
  </IllustratedPointContainer>;

const ExtendedIllustratedPoints = () => (
  <Fragment>
    <IllustratedPoint src="/static/img/icon-house-being-pointed-at.svg">
      We hand-pick quality properties and divide them into 10,000 Bricks
    </IllustratedPoint>

    <IllustratedPoint src="/static/img/icon-dollar-coin-being-pointed-at.svg">
      Choose the Bricks you want to buy or let us choose for you with Smart Invest
    </IllustratedPoint>

    <IllustratedPoint src="/static/img/icon-grow-portfolio.svg">
      Invest and monitor your portfolio
    </IllustratedPoint>

    <IllustratedPoint src="/static/img/icon-rental-income.svg">
      Earn any net rental income in proportion to how many Bricks you own
    </IllustratedPoint>

    <IllustratedPoint src="/static/img/icon-capital-returns.svg">
      Earn any capital returns in line with property price changes when you sell your Bricks
    </IllustratedPoint>
  </Fragment>
);

const CondensedIllustratedPoints = () => (
  <Fragment>
    <IllustratedPoint
      src="/static/img/icon-home-with-a-dollar-coin-with-circular-refresh-arrows.svg"
    >
      Choose your property, or use Smart Invest
    </IllustratedPoint>

    <IllustratedPoint
      src="/static/img/icon-finger-pointing-at-a-choice-of-3-dots.svg"
    >
      Buy Bricks
    </IllustratedPoint>

    <IllustratedPoint
      src="/static/img/icon-selection-indicator-with-dollar-coin.svg"
    >
      Earn net rental income per Brick
    </IllustratedPoint>

    <IllustratedPoint
      src="/static/img/icon-dollar-bill-with-backward-and-forward-arrows.svg"
    >
      Sell your Bricks to earn any capital returns
    </IllustratedPoint>
  </Fragment>
);

const HowItWorksSection = ({ scrollToId, cta, className, backgroundColor, children }) => (
  <PageMarginContainer
    className={className}
    backgroundColor={backgroundColor}
  >
    <section className={styles.root} id={scrollToId}>
      <Spacing bottom="normal">
        <SectionHeading>How it works</SectionHeading>
      </Spacing>
      <Spacing bottom="2x-large">
        <HeadingXSmall className={styles.brandedHeading}>
          <h4>A Brick represents a fraction of a property</h4>
        </HeadingXSmall>
      </Spacing>
      <div className={classNames(styles.illustratedPoints__container, ListItemNumber.counterResetClass)}>
        {children}
      </div>
      {cta
        ? (
          <Spacing bottom="large">
            {cta}
          </Spacing>
        ) : ''
      }
      <FinePrint>Consider whether investing in Bricks is right for you by reading the <Styledlink target="_blank" href={pdsUrl()}>PDS</Styledlink></FinePrint>
    </section>
  </PageMarginContainer>
);

const CondensedHowItWorksSection = ({ ...props }) => (
  <HowItWorksSection {...props}>
    <CondensedIllustratedPoints />
  </HowItWorksSection>
);

export const ExtendedHowItWorksSection = ({ ...props }) => (
  <HowItWorksSection {...props}>
    <ExtendedIllustratedPoints />
  </HowItWorksSection>
);

export default CondensedHowItWorksSection;
