import Configuration from 'scripts/constants/Configuration';
import { _getCurrentUser } from 'scripts/interceptors/interceptorHelper';
import { isUserLoggedIn } from 'scripts/utilities/userAccountHelper';

export const onboardingStepsInterceptor = (store, nextState, replaceState) => {
  const user = _getCurrentUser(store);
  const loggedIn = isUserLoggedIn(user);
  if (loggedIn) {
    if (!nextState.location.pathname.endsWith(user.nextRegistrationStep)) {
      replaceState({ pathname: Configuration.PAGE_URLS.ACCOUNT.DASHBOARD, state: { nextPathname: nextState.location.pathname } });
    }
  } else {
    replaceState({ pathname: Configuration.PAGE_URLS.ONBOARDING.LOGIN, state: { nextPathname: nextState.location.pathname } });
  }
};
