import classNames from 'classnames';
import PrimaryButton from 'src/design/components/button/PrimaryButton';

import styles from 'src/components/oldOnboarding/common/onboardingNextButton/OnboardingNextButton.mscss';

const OnboardingNextButton = ({ className, customText, ...props }) => (
  <PrimaryButton
    className={classNames(styles['onboarding-next-button'], className)}
    color={PrimaryButton.colors.SECONDARY}
    fullWidth
    arrow
    testRef="onboarding-next-button"
    {...props}
  >
    {customText || 'NEXT'}
  </PrimaryButton>
);

export const OnboardingFinishButton = (props) =>
  <OnboardingNextButton {...{
    ...props,
    customText: 'FINISH',
    arrow: false,
    testRef: 'onboarding-finish-button'
  }}/>;

export default OnboardingNextButton;
