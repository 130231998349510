import styled from 'styled-components';
import { getThemeColor } from 'src/themes/themeUtils';
import {
  gridUnit,
  sideGutter
} from 'src/design/styleguide/common/measurements';
import {
  standardDuration,
  standardFunction,
} from 'src/design/styleguide/common/animations';
import {
  testRefToDataTestReference,
} from 'src/design/styleguide/common/styledComponentsUtils';
import BodyText from 'src/design/components/bodyText/BodyText';

const radioButtonSize = gridUnit * 6;
const radioButtonInnerSize = gridUnit * 4;

const RadioSelectContainer = styled.div.attrs(testRefToDataTestReference)`
  display: flex;
`;

const RadioButtonContainer = styled.div`
  align-items: center;
  display: flex;
  margin-right: ${sideGutter};
`;

const StyleCleanedLabel = styled.label`
  font-weight: inherit;
  margin: 0;
`;

const RadioButtonSubstitute = styled(StyleCleanedLabel)`
  align-items: center;
  border: 1px solid ${getThemeColor('grey500')};
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: ${radioButtonSize}px;
  justify-content: center;
  margin-right: ${gridUnit * 2}px;
  transition: border-color ${standardDuration} ${standardFunction};
  width: ${radioButtonSize}px;

  &::after {
    background-color: ${getThemeColor('primary')};
    border-radius: 50%;
    content: '';
    display: block;
    height: ${radioButtonInnerSize}px;
    transform: scale(0);
    transition: transform ${standardDuration} ${standardFunction};
    width: ${radioButtonInnerSize}px;
  }
`;

const RadioButton = styled.input`
  height: 0;
  opacity: 0;
  width: 0;

  &:checked + ${RadioButtonSubstitute} {
    border-color: ${getThemeColor('primary')};
  }

  &:focus + ${RadioButtonSubstitute} {
    border-color: ${getThemeColor('yolk')};
  }

  &:checked + ${RadioButtonSubstitute}::after {
    transform: scale(1);
  }
`;

const RadioSelect = ({ className, name = Math.random(), value, onChange, options, testRef }) => {
  return (
    <RadioSelectContainer className={className} testRef={testRef}>
      {options.map(({ value: optionValue, labelText }) => {
        const id = `${name}-${optionValue}`;

        return (
          <RadioButtonContainer key={id}>
            <RadioButton
              type="radio"
              value={`${optionValue}`}
              checked={value === optionValue}
              onChange={(e) => onChange(e.target.value)}
              name={name}
              id={id}
            />
            <RadioButtonSubstitute htmlFor={id}/>
            <BodyText>
              <StyleCleanedLabel htmlFor={id}>{labelText}</StyleCleanedLabel>
            </BodyText>
          </RadioButtonContainer>
        );
      })}
    </RadioSelectContainer>
  );
};

export default RadioSelect;
