import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { SubHeading } from 'src/design/styleguide/Headings';
import { baseBreakpointGutter } from 'src/components/dashboard/home/commonStyles';
import { breakpointSmall } from 'src/design/styleguide/common/breakpoints';
import { sidePanelPaddingBreakpointSmall } from 'src/components/dashboard/common/styles';
import { testRefToDataTestReference } from 'src/design/styleguide/common/styledComponentsUtils';
import { userSelector } from 'scripts/redux/actions/user';
import Paragraph from 'src/design/styleguide/Paragraph';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import {
  hasMinorDetails,
  isMinorAccount,
  isSMSForTrustAccount,
} from 'scripts/utilities/userAccountHelper';

const textZIndex = 2;
const imageCoverZIndex = 1;
const imageZIndex = 0;

const GreetingContainer = styled.div.attrs(testRefToDataTestReference)`
  position: relative;
  border-radius: var(--bx-ms---widget-border-radius);
  background-color: var(--bx-color---surface);
  box-shadow: var(--bx-emb-shadow);
  overflow: hidden;

  ${breakpointSmall(css`
    display: flex;
    padding: ${sidePanelPaddingBreakpointSmall};
  `)}
`;

const TextContainer = styled.div`
  max-width: 350px;
  padding: ${sidePanelPaddingBreakpointSmall};

  ${breakpointSmall(`
    z-index: ${textZIndex};
    max-width: 50%;
  `)}
`;

const absolutePositionImageStyles = `
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
`;

const GreetingImageCover = styled.div`
  display: none;
  ${breakpointSmall(`
    display: block;
    ${absolutePositionImageStyles};
    z-index: ${imageCoverZIndex};
    background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 50%);
  `)}
`;

const ImageContainer = styled.div`
  margin-left: -${baseBreakpointGutter}px;
  margin-right: -${baseBreakpointGutter}px;

  ${breakpointSmall(`
    ${absolutePositionImageStyles};
    margin-left: 0;
    margin-right: 0;
  `)}
`;

const GreetingImage = styled.div`
  background-image: url(/static/img/illustration-dashboard-greeting.svg);
  background-repeat: no-repeat;
  background-position: right center;
  background-size: cover;
  height: 140px;
  ${breakpointSmall(`
    z-index: ${imageZIndex};
    height: 100%;
    opacity: 0;
    transform: translateX(40px) scale(1.1);
    @keyframes bgFadeIn {
      to {
        transform: translateX(0px) scale(1);
        opacity: 1; }
    }
    animation: bgFadeIn 0.5s ease;
    animation-delay: 0.3s;
    animation-fill-mode: forwards;
  `)}
`;

const GreetingImageForMinor = styled(GreetingImage)`
  background-image: url('/static/img/brickx-minors/BrickX Minors - Banner FA.png');
`;

const GreetingImageForSmsfAndTrust = styled(GreetingImage)`
  background-image: url('/static/img/brickx-smsf/dashboard-background.svg');
`;

const renderCustomGreetingImage = (user) => {
  if (isMinorAccount(user)) {
    return <GreetingImageForMinor />;
  }
  if (isSMSForTrustAccount(user)) {
    return <GreetingImageForSmsfAndTrust />;
  }
  return <GreetingImage />;
};

const Greeting = connect(userSelector)(({ className, user }) => {
  const hasMinorDetailsResult = hasMinorDetails(user);
  return (
    <GreetingContainer className={className} testRef="greeting-panel">
      <TextContainer>
        <Spacing bottom="normal">
          <SubHeading fontWeight="bold" fontSize="x-small" align="left">
            Welcome {user.givenName},
          </SubHeading>
        </Spacing>
        {hasMinorDetailsResult && (
          <Spacing bottom="normal">
            <SubHeading fontSize="2x-small" align="left">
              You are investing on behalf of {user.minorDetails.firstName}
            </SubHeading>
          </Spacing>
        )}
        <Paragraph fontSize="3x-small">
          The Dashboard provides a snapshot of your BrickX Account including
          quick links to Buy and Sell Bricks. Happy investing!
        </Paragraph>
      </TextContainer>
      <GreetingImageCover />
      <Spacing top={{ base: 'large', breakpointSmall: 'none' }}>
        <ImageContainer>{renderCustomGreetingImage(user)}</ImageContainer>
      </Spacing>
    </GreetingContainer>
  );
});

export default Greeting;
