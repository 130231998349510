import { track } from 'src/tracking/analytics';
import { LoginOrSignupPopup } from 'scripts/redux/actions/segment/SegmentEventTypes';

export function sendPopupSwitchedToSignupEvent(trackingProps) {
  track(LoginOrSignupPopup.LoginPopup, {
    action: LoginOrSignupPopup.Action.SwitchedToSignup,
    ...trackingProps
  });
}

export function sendPopupSwitchedToLoginEvent(trackingProps) {
  track(LoginOrSignupPopup.SignupPopup, {
    action: LoginOrSignupPopup.Action.SwitchedToLogin,
    ...trackingProps,
  });
}

export function sendPopupLoginSubmitEvent(trackingProps) {
  track(LoginOrSignupPopup.LoginPopup, {
    action: LoginOrSignupPopup.Action.Submit,
    ...trackingProps,
  });
}

export function sendPopupSignupSubmitEvent(trackingProps) {
  track(LoginOrSignupPopup.SignupPopup, {
    action: LoginOrSignupPopup.Action.Submit,
    ...trackingProps,
  });
}

export function sendPopupLoginCancelEvent(trackingProps) {
  track(LoginOrSignupPopup.LoginPopup, {
    action: LoginOrSignupPopup.Action.Cancel,
    ...trackingProps,
  });
}

export function sendPopupSignupCancelEvent(trackingProps) {
  track(LoginOrSignupPopup.SignupPopup, {
    action: LoginOrSignupPopup.Action.Cancel,
    ...trackingProps,
  });
}
