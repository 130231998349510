import FormSelect from 'src/design/components/formSelect/FormSelect';

const NumberSelect = ({ min = 0, max = 9999, defaultValueText, reversed, ...props }) => {
  const options = Array(max - min + 1).fill().map((v, index) => {
    const numberToShow = index + min;
    return <option value={numberToShow} key={numberToShow} data-test-reference="option">{numberToShow}</option>;
  });

  return (
    <FormSelect
      required
      {...props}
    >
      {defaultValueText
        ? <option
            value=""
            key="select-a-month"
            hidden
            defaultValue
            data-test-reference="default-option"
          >{defaultValueText}</option>
        : null
      }
      {reversed ? options.reverse() : options}
    </FormSelect>
  );
};

export default NumberSelect;
