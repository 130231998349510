import { Component } from 'react';
import PropTypes from 'prop-types';
import { dollarDecimal } from 'scripts/utilities/formatters';
import {
  user as userPropType,
  account as accountPropType,
} from 'scripts/constants/PropTypes';
import Paragraph from 'src/design/styleguide/Paragraph';
import Regex from 'scripts/utilities/regex';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import Wallet from 'scripts/constants/Wallet';

const OTP_RESEND_TIMEOUT = 30000;

const getBankDetailsFromUser = (user) => ({
  accountName: user.financialDetails.bankAccountDetails.bankAccountName,
  bsb: user.financialDetails.bankAccountDetails.BSB,
  accNumber: user.financialDetails.bankAccountDetails.bankAccountNumber,
});

export default class WithdrawVerify extends Component {
  static propTypes = {
    user: userPropType.isRequired,
    account: accountPropType.isRequired,
    amount: PropTypes.number,
    withdrawFunds: PropTypes.func.isRequired,
    sendOtp: PropTypes.func.isRequired,
  };

  state = {
    amount: '',
    otpValue: '',
    hasError: false,
    errorMsg: '',
    loading: false,
    resendLinkEnabled: false,
    otpInvalid: false,
  };

  componentDidMount() {
    setTimeout(
      function () {
        this.setState({ resendLinkEnabled: true });
      }.bind(this),
      OTP_RESEND_TIMEOUT
    );
  }

  render() {
    const { amount, user } = this.props;
    const { loading, hasError, otpValue, otpInvalid } = this.state;

    const nominatedBankDetails = getBankDetailsFromUser(user);

    return (
      <div className="row">
        <div className="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2">
          <div className="panel-withdraw">
            <div className="funds-available">
              <div className="title">Withdrawal Amount</div>
              <div className="amount">
                <strong>{amount::dollarDecimal()}</strong>
              </div>
            </div>
            <form onSubmit={this._onSubmit}>
              <div className="row">
                <div className="col-xs-12">
                  <div className="input">
                    <span className="title">Account Name</span>
                    <span className="value">
                      {nominatedBankDetails.accountName}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <div className="input">
                    <span className="title">BSB</span>
                    <span className="value">{nominatedBankDetails.bsb}</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <div className="input">
                    <span className="title">Account Number</span>
                    <span className="value">
                      {nominatedBankDetails.accNumber}
                    </span>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-xs-12">
                  <Spacing top="large">
                    <Paragraph>
                      Please ensure that you are happy to withdraw your money
                      into the bank account above.
                    </Paragraph>
                  </Spacing>
                </div>
              </div>

              <div className="row">
                <div className="col-xs-12">
                  <Spacing top="large">
                    <Paragraph>
                      You should receive an SMS to your mobile number within 30
                      seconds to confirm your Withdrawal. No PIN? Click{' '}
                      <a onClick={this._resendOtp}>here</a> to resend.
                    </Paragraph>
                  </Spacing>
                  <input
                    type="text"
                    id="id_otp"
                    value={otpValue}
                    placeholder="SMS PIN"
                    maxLength={Wallet.SMS_PIN_LENGTH}
                    onChange={this._onOtpChange}
                  />
                  {otpInvalid && (
                    <div className="error">
                      Please enter your 6 digit SMS PIN
                    </div>
                  )}
                </div>
              </div>
              <button
                disabled={
                  loading ||
                  hasError ||
                  this.state.otpValue.length !== Wallet.SMS_PIN_LENGTH
                }
                id="finish-withdraw-button"
                className="button orange-button right-arrow-button"
              >
                WITHDRAW NOW
              </button>
              <Spacing top="normal">
                <Paragraph>
                  Need support? Contact us at{' '}
                  <a href="mailto:info@brickx.com">info@brickx.com</a>{' '}
                </Paragraph>
              </Spacing>
            </form>
          </div>
        </div>
      </div>
    );
  }

  _onSubmit = (e) => {
    e.preventDefault();
    const formInvalid = this._validateForm();

    if (this.state.hasError || formInvalid) {
      return;
    }

    this._withDraw(this.props.amount, this.state.otpValue);
  };

  _validateForm = () => {
    const failsRegex = !this.state.otpValue.trim().match(Regex.otp);
    this.setState({ otpInvalid: failsRegex, hasError: failsRegex });
    return failsRegex;
  };

  _resendOtp = (e) => {
    e.preventDefault();
    if (this.state.resendLinkEnabled) {
      this.props.sendOtp(true);
    }
  };

  _onOtpChange = (e) => {
    this.setState({ otpValue: e.target.value, hasError: false });
  };

  async _withDraw(amount, otpValue) {
    this.setState({
      loading: true,
    });

    await this.props.withdrawFunds({ amount: amount, otpValue: otpValue });

    this.setState({
      loading: false,
    });
  }
}
