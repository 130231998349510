import { Heading } from 'src/design/components/heading/Headings';
import Paragraph from 'src/design/components/bodyText/Paragraph';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import { brick } from 'scripts/utilities/formatters';
import styles from 'src/components/trades/PreOrderNotFullyTransactedModalContent.mscss';
import { renderCode } from 'src/settings/properties';

const PreOrderNotFullyTransactedModalContent = ({
  propertyCode,
  numBricksTransacted,
  closeThisModal,
}) => (
  <div
    className={styles.content}
    data-test-reference="pre-order-not-fully-transacted-modal"
  >
    <Heading>
      <h1>We're sorry, but...</h1>
    </Heading>
    <Spacing top="x-large">
      <Paragraph align="center">
        There were not enough Bricks remaining to process your{' '}
        <strong>entire</strong> pre-order for {renderCode(propertyCode)}.
        However, we were able to get you the very last{' '}
        <strong>{numBricksTransacted::brick()}</strong>.
      </Paragraph>
    </Spacing>
    <Spacing top="3x-large">
      <PrimaryButton
        className={styles.okButton}
        color={PrimaryButton.colors.SECONDARY}
        onClick={closeThisModal}
        fullWidth
        testRef="ok-button"
      >
        OK
      </PrimaryButton>
    </Spacing>
  </div>
);

export default PreOrderNotFullyTransactedModalContent;
