import { connect } from 'react-redux';
import { toastsSelector } from 'src/components/toasts/toastsReducer';

import { SUCCESS, WARNING, ERROR } from 'src/components/toasts/toastTypes';
import { SuccessToast, ErrorToast, WarningToast } from 'src/components/toasts/Toast';

import styles from 'src/components/toasts/Toasts.mscss';

const Toasts = ({ toasts }) => {
  return toasts.length > 0
    ? (
      <ul
        className={styles.toasts}
        data-test-reference="toasts-listing"
      >
        {toasts.map(toast => {
          switch (toast.type) {
            case SUCCESS: return <SuccessToast key={toast.id} {...toast} />;
            case ERROR: return <ErrorToast key={toast.id} {...toast} />;
            case WARNING:
            default:
              return <WarningToast key={toast.id} {...toast} />;
          }
        })}
      </ul>
    )
    : null;
};

export default connect(toastsSelector)(Toasts);
