import { HeadingXSmall } from 'src/design/components/heading/Headings';
import {
  annualIndividualStatement,
  annualIndividualStatementCsv,
} from 'scripts/services/api';
import { userSelector } from 'scripts/redux/actions/user';
import Icon from 'src/design/components/icon/Icon';
import PageMarginContainer from 'src/design/components/pageMarginContainer/PageMarginContainer';
import SecondaryButton from 'src/design/components/button/SecondaryButton';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import styles from 'scripts/components/account/documents/AnnualIndividualStatement.mscss';
import withFetching from 'src/decorators/withFetching';

const AnnualIndividualStatement = ({ user, params: { year } }) => (
  <PageMarginContainer>
    <h1>
      <strong>Annual Individual Statement</strong>
    </h1>
    <HeadingXSmall align="center">
      <h5>
        FY: July 1, {year - 1} to June 30, {year}
      </h5>
    </HeadingXSmall>
    <Spacing top="3x-large">
      <div className={styles.buttonContainer}>
        <SecondaryButton
          className={styles.pdsButton}
          link={annualIndividualStatement(user.id, year)}
          target="_blank"
        >
          Download PDF <Icon type="folder" />
        </SecondaryButton>
        <SecondaryButton
          link={annualIndividualStatementCsv(user.id, year)}
          target="_blank"
        >
          Download CSV <Icon type="folder" />
        </SecondaryButton>
      </div>
    </Spacing>
  </PageMarginContainer>
);

export default withFetching(
  [userSelector],
  [],
  'AnnualIndividualStatement'
)(AnnualIndividualStatement);
