import {
  TOGGLE_HEADER_BAR,
  HIDE_HEADER_BAR,
  SHOW_HEADER_BAR,
} from 'src/store/headerBarActions';

const defaultState = {
  headerBarVisible: true,
};

export const headerBarVisibleSelector = (state) => ({ headerBarVisible: state.headerBar.headerBarVisible });

const reducer = (state = defaultState, { type }) => {
  switch (type) {
    case TOGGLE_HEADER_BAR: return { ...state, headerBarVisible: !state.headerBarVisible };
    case SHOW_HEADER_BAR: return { ...state, headerBarVisible: true };
    case HIDE_HEADER_BAR: return { ...state, headerBarVisible: false };
    default: return state;
  }
};

export default reducer;
