// this DOES belong in constants, keep it here, maybe rename to HistoricalGrowthTimeScales
const years = {
  TWENTY: '20yr',
  FIFTEEN: '15yr',
  TEN: '10yr',
  FIVE: '5yr',
  ONE: '1yr',
};

const yearsOptions = [
    years.TWENTY,
    years.FIFTEEN,
    years.TEN,
    years.FIVE,
    years.ONE,
];

const dynamicOptions = (totalYears) => {
  if (totalYears >= 20) {
    return [
      years.TWENTY,
      years.FIFTEEN,
      years.TEN,
      years.FIVE,
      years.ONE
    ];
  } else if (totalYears >= 15 && totalYears < 20) {
    return [
      years.FIFTEEN,
      years.TEN,
      years.FIVE,
      years.ONE
    ];
  } else if (totalYears >= 10 && totalYears < 15) {
    return [
      years.TEN,
      years.FIVE,
      years.ONE
    ];
  } else if (totalYears >= 5 && totalYears < 10) {
    return [
      years.FIVE,
      years.ONE
    ];
  }
  return [ years.ONE ];
};

// TODO: does not belong in constants, move it to config
export default {
  years,
  yearsOptions,
  dynamicOptions
};
