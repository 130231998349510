import request from 'scripts/services/request';


const postWithAuth = (url, payload) => request(url, {
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json'
  },
  method: 'POST',
  ...(payload ? { body: JSON.stringify(payload) } : {}),
});

export default postWithAuth;
