import styled, { css } from 'styled-components';
import { getThemeColor } from 'src/themes/themeUtils';
import { gridUnit } from 'src/design/styleguide/common/measurements';
import {
  testRefToDataTestReference,
} from 'src/design/styleguide/common/styledComponentsUtils';
import Text from 'src/design/styleguide/text/Text';

export const FinePrintStyles = css`
  font-size: ${gridUnit * 4}px;
  color: ${props => props.dark ? getThemeColor('grey600')(props) : getThemeColor('grey500')(props)};
`;

const FinePrint = styled(Text)
  .attrs(testRefToDataTestReference)`${FinePrintStyles}`;

export default FinePrint;
