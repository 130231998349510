import { accountSelector } from 'scripts/redux/selectors/account';
import { fetchAccountInfo } from 'scripts/redux/actions/account';
import { userSelector } from 'scripts/redux/selectors/user';
import Loading from 'src/components/loading/Loading';
import withFetching from 'src/decorators/withFetching';

const withLegacyAccount = (WrappedComponent) => withFetching([accountSelector, userSelector], [fetchAccountInfo])(
  ({ account, ...props }) => !account ? <Loading/> : (
    <WrappedComponent account={account} {...props}/>
  )
);

export default withLegacyAccount;
