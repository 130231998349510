import { identityCheckConsentCheckboxText } from 'src/settings/text';
import DateOfBirth from 'src/design/components/dateOfBirth/DateOfBirth';
import Divider from 'src/design/components/divider/Divider';
import FormCheckbox from 'src/design/components/formCheckbox/FormCheckbox';
import Heading from 'src/design/components/heading/Heading';
import HelpDisclaimer from 'src/design/components/helpDisclaimer/HelpDisclaimer';
import InfoBox from 'src/design/components/infoBox/InfoBox';
import InputError from 'src/design/components/inputError/InputError';
import OnboardingNextButton from 'src/components/oldOnboarding/common/onboardingNextButton/OnboardingNextButton';
import OnboardingScreen, {
  OnboardingScreenHeader,
  OnboardingScreenContent,
  OnboardingFormLabel,
} from 'src/components/oldOnboarding/common/onboardingScreen/OnboardingScreen';
import Paragraph from 'src/design/styleguide/Paragraph';
import RadioSelect from 'src/design/components/radioSelect/RadioSelect';
import ResidentialAddress from 'src/components/oldOnboarding/identityVerification/ResidentialAddress';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import TextInputWithError from 'src/design/components/textInput/TextInputWithError';
import { validateTfn } from 'src/utils/validation';
import { Fragment } from 'react';
import FileUpload from 'src/design/components/fileUpload/FileUpload';
import Styledlink from 'src/design/components/hyperlink/Styledlink';
import { wholesaleLandingPageUrl } from 'src/utils/pageUrls';

const usCitizenFormValueToFieldValue = (formValue) => {
  if (formValue === true) return 'yes';
  if (formValue === false) return 'no';
  return '';
};

const usCitizenFieldValueToFormValue = (fieldValue) => {
  if (fieldValue === 'yes') return true;
  if (fieldValue === 'no') return false;
};

const submitAllowed = (values) =>
  values.givenName &&
  values.familyName &&
  values.address &&
  values.mobilePhone &&
  values.dayOfBirth &&
  values.monthOfBirth &&
  values.yearOfBirth &&
  values.tfn &&
  validateTfn(values.tfn) === false &&
  usCitizenFormValueToFieldValue(values.usCitizen) &&
  values.tsAndCsAgreed &&
  (!values.isWholesale ||
    (values.isWholesale &&
      values.certificate &&
      values.certificateLabel &&
      values.wholesaleConfirm));

const EnterDetailsAsTheyAppear = (props) => (
  <InfoBox {...props}>
    <Paragraph>
      Please enter details exactly as they appear on your ID.
    </Paragraph>
  </InfoBox>
);

const IdentityVerificationFormIndividual = ({
  onSubmit,
  onChange,
  values,
  identityVerificationFormErrors,
  identityVerificationFormErrorsCleared,
  submitting,
}) => {
  return (
    <OnboardingScreen testRef="identity-verification-screen">
      <OnboardingScreenHeader>
        <Spacing bottom="large">
          <Heading>Please tell us a bit about yourself</Heading>
        </Spacing>
        <Spacing bottom="large">
          <Paragraph>
            We are required by Australian law to verify all BrickX investors
          </Paragraph>
        </Spacing>
      </OnboardingScreenHeader>
      <OnboardingScreenContent>
        <form>
          <Spacing bottom="large">
            <EnterDetailsAsTheyAppear />
          </Spacing>
          <OnboardingFormLabel>First Name</OnboardingFormLabel>
          <Spacing bottom="normal">
            <TextInputWithError
              value={values.givenName}
              onChange={(value) => {
                identityVerificationFormErrorsCleared('givenName');
                onChange({ givenName: value });
              }}
              placeholder="John"
              error={
                identityVerificationFormErrors.givenName &&
                'Please enter a valid first name (alphabets only)'
              }
              testRef="given-name"
              errorTestRef="given-name-error"
            />
          </Spacing>
          <OnboardingFormLabel>Middle Name (Optional)</OnboardingFormLabel>
          <Spacing bottom="normal">
            <TextInputWithError
              value={values.middleName}
              onChange={(value) => {
                identityVerificationFormErrorsCleared('middleName');
                onChange({ middleName: value });
              }}
              placeholder="William"
              error={
                identityVerificationFormErrors.middleName &&
                'Please enter a valid first name (alphabets only)'
              }
              testRef="middle-name"
              errorTestRef="middle-name-error"
            />
          </Spacing>
          <OnboardingFormLabel>Last Name</OnboardingFormLabel>
          <Spacing bottom="normal">
            <TextInputWithError
              value={values.familyName}
              onChange={(value) => {
                identityVerificationFormErrorsCleared('familyName');
                onChange({ familyName: value });
              }}
              placeholder="Smith"
              error={
                identityVerificationFormErrors.familyName &&
                'Please enter a valid last name (alphabets only)'
              }
              testRef="family-name"
              errorTestRef="family-name-error"
            />
          </Spacing>
          <ResidentialAddress
            value={values.address}
            harmonyValue={values.harmonyAddressObject}
            onChange={(value) => {
              identityVerificationFormErrorsCleared('address');
              onChange({
                address: value,
                harmonyAddressObject: undefined,
              });
            }}
            onSuggestionSelect={(harmonyAddressObject) => {
              onChange({
                address: harmonyAddressObject.fullAddress,
                harmonyAddressObject,
              });
            }}
            showError={identityVerificationFormErrors.address}
          />
          <OnboardingFormLabel>Mobile</OnboardingFormLabel>
          <Spacing bottom="normal">
            <TextInputWithError
              value={values.mobilePhone}
              onChange={(value) => {
                identityVerificationFormErrorsCleared('mobilePhone');
                onChange({ mobilePhone: value });
              }}
              placeholder="e.g. 04xxxxxxxx"
              error={
                identityVerificationFormErrors.mobilePhone &&
                'Please enter a valid Australian mobile number'
              }
              testRef="phone-number"
              errorTestRef="phone-number-error"
            />
          </Spacing>
          <OnboardingFormLabel>Date of Birth</OnboardingFormLabel>
          <Spacing bottom="normal">
            <DateOfBirth
              day={{
                value: values.dayOfBirth,
                onChange: (value) => {
                  identityVerificationFormErrorsCleared('dateOfBirth');
                  onChange({ dayOfBirth: value });
                },
                testRef: 'day-of-birth-select',
              }}
              month={{
                value: values.monthOfBirth,
                onChange: (value) => {
                  identityVerificationFormErrorsCleared('dateOfBirth');
                  onChange({ monthOfBirth: value });
                },
                testRef: 'month-of-birth-select',
              }}
              year={{
                value: values.yearOfBirth,
                onChange: (value) => {
                  identityVerificationFormErrorsCleared('dateOfBirth');
                  onChange({ yearOfBirth: value });
                },
                testRef: 'year-of-birth-select',
              }}
              error={
                identityVerificationFormErrors.dateOfBirth &&
                'You must be at least 18 years old to register'
              }
              errorTestRef="date-of-birth-error"
            />
          </Spacing>
          <Divider top="normal" bottom="normal" />
          <OnboardingFormLabel>Australian Tax File Number</OnboardingFormLabel>
          <Spacing bottom="normal">
            <TextInputWithError
              key={identityVerificationFormErrors.tfn === false}
              value={values.tfn}
              onChange={(value) => {
                const checkTfn = validateTfn(value);
                if (value.length === 8 || value.length === 9) {
                  identityVerificationFormErrors.tfn = checkTfn;
                }
                if (checkTfn === false) {
                  identityVerificationFormErrorsCleared('tfn');
                }
                if (value.length !== 8 && value.length !== 9) {
                  identityVerificationFormErrorsCleared('tfn');
                }
                onChange({ tfn: value });
              }}
              placeholder="Enter a valid 8 or 9 digit tax file number."
              error={identityVerificationFormErrors.tfn}
              testRef="tfn"
              errorTestRef="tfn-error"
            />
          </Spacing>
          <Divider top="normal" bottom="normal" />
          <OnboardingFormLabel>Are you a US citizen?</OnboardingFormLabel>
          <Spacing bottom="x-small">
            <RadioSelect
              name="us-citizen"
              value={usCitizenFormValueToFieldValue(values.usCitizen)}
              onChange={(value) =>
                onChange({ usCitizen: usCitizenFieldValueToFormValue(value) })
              }
              options={[
                { value: 'yes', labelText: 'Yes' },
                { value: 'no', labelText: 'No' },
              ]}
              testRef="us-citizen"
            />
          </Spacing>
          <HelpDisclaimer>
            Your answer does not affect your eligibility to invest, and is for
            reporting purposes only.
          </HelpDisclaimer>
          <Divider top="normal" bottom="normal" />
          <OnboardingFormLabel>
            Are you a <strong>Professional or Sophisticated Investor</strong>?{' '}
            <Styledlink href={wholesaleLandingPageUrl()} forceExternal>
              Learn More
            </Styledlink>
          </OnboardingFormLabel>
          <Spacing bottom="large">
            <RadioSelect
              name="is-wholesale"
              value={values.isWholesale}
              onChange={(value) => {
                onChange({ isWholesale: value === 'true' });
              }}
              options={[
                { value: true, labelText: 'Yes' },
                { value: false, labelText: 'No' },
              ]}
              testRef="is-wholesale"
            />
          </Spacing>
          {values.isWholesale && (
            <Fragment>
              <Spacing bottom="large">
                <Paragraph color="onSurfaceContrast500">
                  Note: To qualify as a professional or sophisticated investor,
                  your accountant must be able to verify that you as the
                  investor (including the net assets or gross income or any
                  companies or trusts controlled by you the Investor as defined
                  in section 50AA of the Corporations Act) has either:
                  <br />
                  <strong>
                    <br />
                    (i) Net assets of at least AUD$2,500,000; OR
                    <br />
                    (ii) Gross income for each of the last 2 financial years of
                    at least AUD$250,000 a year.
                  </strong>
                  <br />
                  <br />
                  To register on the BrickX Platform as a professional or
                  sophisticated investor, please upload a Qualified Accounts
                  Certification.
                  <br />
                  <br />
                  <i>
                    You can{' '}
                    <Styledlink
                      forceExternal
                      href="https://assets.brickx.com/downloads/qualified_accountants_certificate.pdf"
                    >
                      click here
                    </Styledlink>{' '}
                    to download a Qualified Accountant’s Certificate template
                  </i>
                </Paragraph>
              </Spacing>
              <Spacing bottom="normal">
                <FileUpload
                  ctaText="Select File"
                  realTestRef={'wholesale-fileupload'}
                  formDataFieldName="wholesale_certificate"
                  onChange={(file) => {
                    if (file) {
                      const r = new FileReader();
                      r.readAsDataURL(file);
                      r.onloadend = (e) => {
                        const base64Str = e.target.result.split(',')[1];
                        const certificate = {
                          certificateLabel: file.name,
                          certificate: base64Str,
                        };
                        onChange(certificate);
                      };
                    }
                  }}
                  accept=".png, .gif, .jpeg, .jpg, .pdf"
                />
              </Spacing>
              <Spacing>
                <Paragraph>
                  By registering as a professional and sophisticated Investor
                  with BrickX, I consent to being offered exclusive professional
                  and sophisticated investor financial products and services
                  from BrickX and it’s related parties.
                </Paragraph>
              </Spacing>
              <Spacing bottom="x-samll">
                <RadioSelect
                  name="wholesale-confirm"
                  value={values.wholesaleConfirm}
                  onChange={(value) => {
                    onChange({ wholesaleConfirm: value === 'true' });
                  }}
                  options={[
                    { value: true, labelText: 'Yes' },
                    { value: false, labelText: 'No' },
                  ]}
                  testRef="wholesale-confirm"
                />
              </Spacing>
            </Fragment>
          )}
          <Divider top="normal" bottom="normal" />
          <FormCheckbox
            value={values.tsAndCsAgreed}
            onChange={(value) => onChange({ tsAndCsAgreed: value })}
            testRef="ts-and-cs-checkbox"
            labelTestRef="ts-and-cs-checkbox-label"
          >
            {identityCheckConsentCheckboxText()}
          </FormCheckbox>
          <OnboardingNextButton
            testRef="next-button"
            disabled={!submitAllowed(values) || submitting}
            onClick={(e) => {
              e.preventDefault();
              onSubmit();
            }}
          />
          {identityVerificationFormErrors.requestError ? (
            <Spacing top="x-small">
              <InputError error="We are having some trouble contacting our verification service at the moment. Please try again later." />
            </Spacing>
          ) : null}
        </form>
      </OnboardingScreenContent>
    </OnboardingScreen>
  );
};

export default IdentityVerificationFormIndividual;
