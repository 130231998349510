const indexContentsGetter = (state) => state.contents.index;
const mediaGetter = (state) => state.contents.media;
const webinarGetter = (state) => state.contents.webinar;
const keyDatesGetter = (state) => state.contents.keyDates;
const helpfulLinksGetter = (state) => state.contents.helpfulLinks;
const webinarAndKeyDatesGetter = (state) => [state.contents.webinar, state.contents.keyDates];
const propertiesRg46Getter = (state) => state.contents.propertiesRg46;

export const indexContentsSelector = (state) => ({
  index: indexContentsGetter(state)
});

export const mediaSelector = (state) => ({
  contents: {
    media: mediaGetter(state),
  }
});

export const webinarSelector = (state) => ({
  contents: {
    webinar: webinarGetter(state),
  }
});

export const keyDatesSelector = (state) => ({
  contents: {
    keyDates: keyDatesGetter(state),
  }
});

export const helpfulLinksSelector = (state) => ({
  contents: {
    helpfulLinks: helpfulLinksGetter(state),
  }
});

export const webinarAndKeyDatesSelector = (state) => ({
  contents: {
    webinar: webinarAndKeyDatesGetter(state)[0],
    keyDates: webinarAndKeyDatesGetter(state)[1],
  }
});

export const propertiesRg46Selector = (state) => ({
  contents: {
    propertiesRg46: propertiesRg46Getter(state),
  }
});
