import _ from 'lodash';


export default class User {
  constructor(data) {
    this.id = undefined;
    if (!_.isNil(data)) {
      _.extend(this, data);
    }
  }

  get loggedIn() {
    return this.id !== void 0;
  }
}
