import { Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  BodyTableRow,
  ColumnHeading,
  FooterTableData,
  SecondColumn,
  StickyHeader,
  Table,
  TableData,
} from 'src/design/components/table/Table';
import { Heading } from 'src/design/styleguide/Headings';
import { LOWEST_BRICK_PRICE_POPOVER_TEXT } from 'src/components/dashboard/portfolio/helpText';
import { cancelOrder as cancelOrderAction } from 'src/components/dashboard/pendingOrders/pendingOrdersActions';
import { daysToNow } from 'src/utils/time';
import {
  dollarDecimal,
  getPlural,
  longDate,
  shortReference,
} from 'src/utils/formats';
import { feesSelector, myOrdersSelector } from 'scripts/redux/selectors/market';
import { fetchFees, fetchMyOrders } from 'scripts/redux/actions/market';
import { fetchProperties } from 'scripts/redux/actions/properties';
import {
  getTotalPreOrders,
  getTotalQuantityOfBricks,
  getTotalSellOrders,
  getAmountYouReceiveOnSale,
} from 'src/utils/orders';
import { propertiesSelector } from 'scripts/redux/selectors/properties';
import { showModal as showModalAction } from 'src/components/modals/modalActions';
import Image from 'src/design/components/image/Image';
import Loading from 'src/components/loading/Loading';
import PageLayout from 'src/components/dashboard/common/PageLayout';
import Paragraph from 'src/design/styleguide/Paragraph';
import Popover from 'scripts/components/shared/Popover';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import SecondaryButton from 'src/design/components/button/SecondaryButton';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import WidgetPanel from 'src/components/dashboard/common/WidgetPanel';
import withFetching from 'src/decorators/withFetching';
import { renderCode } from 'src/settings/properties';

const getPropertyFromOrder = (properties, order) =>
  properties.find(({ propertyCode }) => order.propertyCode === propertyCode);

const CancelConfirmationModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CancelConfirmationButtonsContainer = styled.div`
  display: flex;
`;

const CancelConfirmationModal = connect(null, {
  cancelOrder: cancelOrderAction,
})(({ closeThisModal, cancelOrder, order, orderTypeText }) => (
  <CancelConfirmationModalContainer>
    <Heading as="p">
      Are you sure you
      <br />
      want to cancel this order?
    </Heading>
    <Spacing top="x-large">
      <Paragraph align="center">
        {orderTypeText} {order.quantity}{' '}
        {getPlural({
          number: order.quantity,
          singular: 'Brick',
          plural: 'Bricks',
        })}{' '}
        in {renderCode(order.propertyCode)} at {dollarDecimal(order.price)} each
        <br />
        Reference: {shortReference(order.orderId)}
      </Paragraph>
    </Spacing>
    <Spacing top="5x-large">
      <CancelConfirmationButtonsContainer>
        <SecondaryButton
          color={SecondaryButton.colors.PRIMARY}
          onClick={closeThisModal}
          testRef="decline-cancel-button"
        >
          No
        </SecondaryButton>
        <Spacing left="x-small">
          <PrimaryButton
            color={PrimaryButton.colors.SECONDARY}
            onClick={() => {
              cancelOrder(order.propertyCode, order.orderId).then(
                closeThisModal
              );
            }}
            testRef="confirm-cancel-button"
          >
            Yes, I am sure
          </PrimaryButton>
        </Spacing>
      </CancelConfirmationButtonsContainer>
    </Spacing>
  </CancelConfirmationModalContainer>
));

const CancelButton = connect(null, { showModal: showModalAction })(
  ({ showModal, order, orderTypeText, ...props }) => {
    const modalContent = (
      <CancelConfirmationModal order={order} orderTypeText={orderTypeText} />
    );
    return (
      <SecondaryButton
        color={SecondaryButton.colors.PRIMARY}
        size={SecondaryButton.sizes.EXTRA_SMALL}
        onClick={() =>
          showModal({
            content: modalContent,
            testRef: 'cancel-confirmation-modal',
          })
        }
        testRef="cancel-order-button"
        {...props}
      >
        Cancel
      </SecondaryButton>
    );
  }
);

const CancelButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const NoPendingOrdersIllustration = styled(Image).attrs({
  src: '/static/img/illustration-no-pending-orders-yet.svg',
})`
  max-width: ${(props) => (props.small ? '150px' : '300px')};
`;

const NoOrdersContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NoOrdersContent = ({ message, illustration }) => (
  <NoOrdersContentContainer>
    {illustration}
    <Spacing top="normal">
      <Paragraph color="onSurfaceContrast500" align="center">
        {message}
      </Paragraph>
    </Spacing>
  </NoOrdersContentContainer>
);

const NoOrdersAtAll = () => (
  <NoOrdersContent
    message="No pending orders at the moment."
    illustration={<NoPendingOrdersIllustration />}
  />
);
const NoPreOrders = () => (
  <NoOrdersContent
    message="No pending pre orders at the moment."
    illustration={<NoPendingOrdersIllustration small />}
  />
);
const NoSellOrders = () => (
  <NoOrdersContent
    message="No pending sell orders at the moment."
    illustration={<NoPendingOrdersIllustration small />}
  />
);

const NoOrdersPanel = () => (
  <WidgetPanel title="Pending Orders" testRef="no-orders-panel">
    <NoOrdersAtAll />
  </WidgetPanel>
);

const PreOrderPanel = ({ preOrders, properties }) =>
  !properties.length ? (
    <Loading />
  ) : (
    <WidgetPanel title="Pending Pre Orders" testRef="pre-orders-panel">
      {preOrders.length ? (
        <Table withStickyHeader centeredRows>
          <thead>
            <tr>
              <StickyHeader>
                <ColumnHeading>Property</ColumnHeading>
              </StickyHeader>
              <SecondColumn>
                <ColumnHeading>
                  Date
                  <br />
                  Ordered
                </ColumnHeading>
              </SecondColumn>
              <ColumnHeading>
                Settlement
                <br />
                Date
              </ColumnHeading>
              <ColumnHeading>
                Quantity
                <br />
                of Bricks
              </ColumnHeading>
              <ColumnHeading>
                Initial
                <br />
                Brick Price
              </ColumnHeading>
              <ColumnHeading>
                Order
                <br />
                Total{' '}
              </ColumnHeading>
              <ColumnHeading>Reference</ColumnHeading>
              <ColumnHeading />
            </tr>
          </thead>
          <tbody>
            {preOrders.map((order) => (
              <BodyTableRow key={order.orderId} testRef="row">
                <StickyHeader>
                  <TableData testRef="property-code-cell">
                    {renderCode(order.propertyCode)}
                  </TableData>
                </StickyHeader>
                <SecondColumn>
                  <TableData testRef="date-cell">
                    {longDate(order.date)}
                  </TableData>
                </SecondColumn>
                <TableData testRef="settlement-date-cell">
                  {longDate(
                    getPropertyFromOrder(properties, order)
                      .platformSettlementDate
                  )}
                </TableData>
                <TableData testRef="quantity-of-bricks-cell">
                  {order.quantity}
                </TableData>
                <TableData testRef="initial-brick-price-cell">
                  {dollarDecimal(order.price)}
                </TableData>
                <TableData testRef="order-total-cell">
                  {dollarDecimal(order.totalPriceInclFees)}
                </TableData>
                <TableData testRef="reference-cell">
                  {shortReference(order.orderId)}
                </TableData>
                <TableData>
                  <CancelButtonContainer>
                    <CancelButton order={order} orderTypeText="Pre Order" />
                  </CancelButtonContainer>
                </TableData>
              </BodyTableRow>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <StickyHeader>
                <FooterTableData>Total</FooterTableData>
              </StickyHeader>
              <SecondColumn>
                <FooterTableData>-</FooterTableData>
              </SecondColumn>
              <FooterTableData>-</FooterTableData>
              <FooterTableData testRef="total-quantity-of-bricks-cell">
                {getTotalQuantityOfBricks(preOrders)}
              </FooterTableData>
              <FooterTableData>-</FooterTableData>
              <FooterTableData testRef="total-cell">
                {dollarDecimal(getTotalPreOrders(preOrders))}
              </FooterTableData>
              <FooterTableData>-</FooterTableData>
              <FooterTableData>-</FooterTableData>
            </tr>
          </tfoot>
        </Table>
      ) : (
        <NoPreOrders />
      )}
    </WidgetPanel>
  );

const youReceivePopoverText = (fees) =>
  `The total amount you will receive from this sell order, after subtracting the ${fees}% selling fee.`;

const SellOrderPanel = withFetching(
  [feesSelector],
  [fetchFees]
)(({ sellOrders, properties, fees }) =>
  !properties.length ? (
    <Loading />
  ) : (
    <WidgetPanel title="Pending Sell Orders" testRef="sell-orders-panel">
      {sellOrders.length ? (
        <Table withStickyHeader centeredRows>
          <thead>
            <tr>
              <StickyHeader>
                <ColumnHeading>Property</ColumnHeading>
              </StickyHeader>
              <SecondColumn>
                <ColumnHeading>
                  Date
                  <br />
                  Placed
                </ColumnHeading>
              </SecondColumn>
              <ColumnHeading>
                Days
                <br />
                Listed
              </ColumnHeading>
              <ColumnHeading>
                Quantity
                <br />
                of Bricks
              </ColumnHeading>
              <ColumnHeading>
                Brick
                <br />
                Sell Price
              </ColumnHeading>
              <ColumnHeading>
                You
                <br />
                Receive{' '}
                <Popover
                  placement="top"
                  content={youReceivePopoverText(fees.sellFee)}
                  data-test-reference="you-receive-amount-popover"
                />
              </ColumnHeading>
              <ColumnHeading>
                Lowest Available
                <br />
                Brick Price{' '}
                <Popover
                  placement="top"
                  content={LOWEST_BRICK_PRICE_POPOVER_TEXT}
                  testRef="you-receive-amount-popover"
                />
              </ColumnHeading>
              <ColumnHeading>Reference</ColumnHeading>
              <ColumnHeading />
            </tr>
          </thead>
          <tbody>
            {sellOrders.map((order) => (
              <BodyTableRow key={order.orderId} testRef="row">
                <StickyHeader>
                  <TableData testRef="property-code-cell">
                    {renderCode(order.propertyCode)}
                  </TableData>
                </StickyHeader>
                <SecondColumn>
                  <TableData testRef="date-cell">
                    {longDate(order.date)}
                  </TableData>
                </SecondColumn>
                <TableData testRef="days-listed-cell">
                  {daysToNow(order.date)}
                </TableData>
                <TableData testRef="quantity-of-bricks-cell">
                  {order.quantity}
                </TableData>
                <TableData testRef="brick-sell-price-cell">
                  {dollarDecimal(order.price)}
                </TableData>
                <TableData testRef="you-receive-amount-cell">
                  {dollarDecimal(getAmountYouReceiveOnSale(order))}
                </TableData>
                <TableData testRef="lowest-available-brick-price-cell">
                  {dollarDecimal(
                    getPropertyFromOrder(properties, order).financials
                      .lowestAvailableBrickPrice
                  )}
                </TableData>
                <TableData testRef="reference-cell">
                  {shortReference(order.orderId)}
                </TableData>
                <TableData>
                  <CancelButtonContainer>
                    <CancelButton order={order} orderTypeText="Sell" />
                  </CancelButtonContainer>
                </TableData>
              </BodyTableRow>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <StickyHeader>
                <FooterTableData>Total</FooterTableData>
              </StickyHeader>
              <SecondColumn>
                <FooterTableData>-</FooterTableData>
              </SecondColumn>
              <FooterTableData>-</FooterTableData>
              <FooterTableData testRef="total-quantity-of-bricks-cell">
                {getTotalQuantityOfBricks(sellOrders)}
              </FooterTableData>
              <FooterTableData>-</FooterTableData>
              <FooterTableData testRef="total-cell">
                {dollarDecimal(getTotalSellOrders(sellOrders))}
              </FooterTableData>
              <FooterTableData>-</FooterTableData>
              <FooterTableData>-</FooterTableData>
              <FooterTableData>-</FooterTableData>
            </tr>
          </tfoot>
        </Table>
      ) : (
        <NoSellOrders />
      )}
    </WidgetPanel>
  )
);

const selectors = [myOrdersSelector, propertiesSelector];
const fetchers = [fetchMyOrders, fetchProperties];

const PendingOrders = withFetching(
  selectors,
  fetchers
)(({ myOrders, properties }) => (
  <PageLayout testRef="pending-orders">
    {myOrders.list && myOrders.list.length ? (
      <Fragment>
        <PreOrderPanel
          preOrders={myOrders.pendingPre}
          properties={properties}
        />
        <SellOrderPanel
          sellOrders={myOrders.pendingSell}
          properties={properties}
        />
      </Fragment>
    ) : (
      <NoOrdersPanel />
    )}
  </PageLayout>
));

export default PendingOrders;
