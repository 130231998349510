import classNames from 'classnames';
import BodyText from 'src/design/components/bodyText/BodyText';
import styles from 'src/design/components/bodyText/BodyText.mscss';

const FinePrint = ({ children, className, ...props }) => (
  <BodyText
    className={classNames(styles.finePrint, className)}
    {...props}
  >{children}</BodyText>
);

export default FinePrint;
