
import { Heading } from 'src/design/components/heading/Headings';
import Paragraph from 'src/design/components/bodyText/Paragraph';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import SecondaryButton from 'src/design/components/button/SecondaryButton';
import Spacing from 'src/design/styleguide/spacing/Spacing';

import { depositUrl } from 'src/utils/pageUrls';
import { trackDepositDriverCtaClick } from 'src/tracking/onboarding';

import styles from 'src/components/oldDashboard/welcomeWidgets/WelcomeWidgets.mscss';

const DepositDriver = ({ onNext }) => {
  const onClick = (trackerDetails) => {
    trackDepositDriverCtaClick(trackerDetails);
    onNext();
  };

  return (
    <div
      className={styles.welcomeWidget}
      data-test-reference="welcome-deposit-funds-screen"
    >
      <div className={styles.innerContainer}>
        <img
          className={styles['welcomeWidget--header-image']}
          src="/static/images/icons/icon-product-selection-smart-invest.svg"
          alt="Smart Invest illustration graphic"
        />
        <Heading><h2>Start Smart Invest now</h2></Heading>
        <Spacing top="large" bottom="large">
          <Paragraph textSize="large">Don't want to wait until your first direct debit? Make an initial deposit now to get started today.</Paragraph>
        </Spacing>
        <div className={styles['welcomeWidget--ctasRow']}>
          <PrimaryButton
            fullWidth
            color={PrimaryButton.colors.SECONDARY}
            link={depositUrl()}
            onClick={() => onClick('Deposit Now')}
            testRef="deposit-driver-deposit-now-button"
          >Deposit Now</PrimaryButton>
          <SecondaryButton
            fullWidth
            onClick={() => onClick('No Thanks')}
            testRef="deposit-driver-no-thanks-button"
          >No Thanks</SecondaryButton>
        </div>
      </div>
    </div>
  );
};

export default DepositDriver;
