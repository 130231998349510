import styled, { css } from 'styled-components';
import {
  animationEggTimerSpin,
  animationGentleScaleBulge,
  animationMoveUpAndFadeIn,
  animationWipeDownwards
} from 'src/design/styleguide/common/animations';
import { getThemeColor } from 'src/themes/themeUtils';
import { gridUnit, headerHeight } from 'src/design/styleguide/common/measurements';
import { testRefToDataTestReference } from 'src/design/styleguide/common/styledComponentsUtils';
import Icon from 'src/design/components/icon/Icon';

const LoadingContainer = styled.div.attrs(testRefToDataTestReference)`
  color: ${getThemeColor('grey700')};
  display: flex;
  justify-content: center;
  margin: auto;
  min-height: calc(100vh - ${headerHeight});
  ${animationMoveUpAndFadeIn()}

  ${props => props.notFullHeight && 'min-height: 0;'}
`;

const LoadingIconsContainer = styled.div`
  margin: auto;
  position: relative;
`;

const CornerIcon = styled(Icon)`
  position: absolute;
  color: ${getThemeColor('primary')};
  font-size: 20px;
  ${animationGentleScaleBulge()}

  ${props => props.type === 'right_corner' && 'bottom: 0; right: 0;'}
  ${props => props.type === 'left_corner' && 'top: 0; left: 0;'}
`;

const LoadingIcon = styled(Icon)`
  display: block;
  font-size: 40px;

  ${props => props.type === 'hourglass_full' && css`
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    ${animationWipeDownwards()}
  `}

  ${props => props.type === 'hourglass_empty' && `
    position: relative;
  `}
`;

const HourGlassesIconContainer = styled.div`
  margin: ${gridUnit * 3}px ${gridUnit * 3}px;
  ${animationEggTimerSpin()}
`;

const Loading = ({ className, testRef = 'loading-animation', notFullHeight } = {}) => (
  <LoadingContainer
    className={className}
    notFullHeight={notFullHeight}
    testRef={testRef}
  >
    <LoadingIconsContainer>
      <CornerIcon type="right_corner"/>
      <CornerIcon type="left_corner"/>
      <HourGlassesIconContainer>
        <LoadingIcon type="hourglass_full"/>
        <LoadingIcon type="hourglass_empty"/>
      </HourGlassesIconContainer>
    </LoadingIconsContainer>
  </LoadingContainer>
);

export default Loading;
