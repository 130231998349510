const HARMONY_OBJECT_SELECTED = 'HARMONY_OBJECT_SELECTED';
const HARMONY_OBJECT_CLEARED = 'HARMONY_OBJECT_CLEARED';

export const setHarmonyObject = (payload) => ({
  type: HARMONY_OBJECT_SELECTED,
  payload,
});

export const clearHarmonyObject = () => ({
  type: HARMONY_OBJECT_CLEARED,
});

export const harmonyAddressObjectSelector = (state) => ({
  harmonyAddressObject: state.rentToBuyExtraInfo.harmonyAddressObject,
});

const defaultState = {
  harmonyAddressObject: null,
};

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case HARMONY_OBJECT_SELECTED:
      return {
        ...state,
        harmonyAddressObject: payload,
      };
    case HARMONY_OBJECT_CLEARED:
      return {
        ...state,
        harmonyAddressObject: null,
      };
    default:
      return state;
  }
};
