import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { animationMoveUpAndFadeIn } from 'src/design/styleguide/common/animations';
import { breakpointSmall } from 'src/design/styleguide/common/breakpoints';
import { getThemeColor } from 'src/themes/themeUtils';
import { gridUnit } from 'src/design/styleguide/common/measurements';
import BodyText from 'src/design/components/bodyText/BodyText';
import Hyperlink from 'src/design/components/hyperlink/Hyperlink';

const activeBorderSize = 3;

const LabelsContainer = styled.div`
  display: flex;
  margin: 0 auto;
  box-shadow: inset 0 -${activeBorderSize + 1}px ${getThemeColor('shale')};
  cursor: pointer;
  max-width: 560px;
  ${props => props.centeredLabels && `
    justify-content: center;
    max-width: 100%;
  `}
`;

const Label = styled(Hyperlink)`
  color: ${getThemeColor('grey700')};
  flex: 1 1 0px;
  padding: ${gridUnit * 5}px;
  text-align: center;

  &&:hover {
    text-decoration: underline;
  }

  ${props => props.centeredLabels && css`
    flex: 0 1 auto;

    ${breakpointSmall(`
      padding-left: ${gridUnit * 14}px;
      padding-right: ${gridUnit * 14}px;
    `)}
  `}

  ${props => props.active && css`
    && {
      border-bottom: ${activeBorderSize}px solid ${getThemeColor('primary')};
      color: ${getThemeColor('primary')};
      font-weight: 500;
      position: relative;
      text-decoration: none;

      ${'' /* Disable hyperlink's hover and active styles for the tab buttons. */}
      &:hover,
      &:active {
        color: ${getThemeColor('primary')};
        text-decoration: none;
      }

      ${'' /* Little white notches on either side of the selected tab's underline */}
      &:after,
      &:before {
        background: white;
        bottom: -${activeBorderSize}px;
        content: "";
        height: ${activeBorderSize}px;
        position: absolute;
        width: ${activeBorderSize}px;
      }

      &:before {
        left: -${activeBorderSize}px;
      }
      &:after {
        right: -${activeBorderSize}px;
      }
    }
  `}
`;

const Panel = styled.div`
  display: block;
  margin: 0;

  ${animationMoveUpAndFadeIn()}
`;

export default class Tabs extends Component {
  static propTypes = {
    className: PropTypes.string,
    panels: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.required,
      panel: PropTypes.node.required,
      labelTestRef: PropTypes.string,
    })),
    onTabChange: PropTypes.func,
    startFromPanel: PropTypes.number,
    testRef: PropTypes.string,
    centeredLabels: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    const { startFromPanel, panels } = props;
    if (typeof startFromPanel === 'number') {
      this.state.activePanelId = panels[startFromPanel]
        ? startFromPanel
        : 0;
    }
  }

  state = { activePanelId: 0 };

  changeActivePanel(e, index) {
    e.preventDefault();
    this.setState({ activePanelId: index });
    this.props.onTabChange && this.props.onTabChange({ index });
  }

  gotoTabIndex({ index }) {
    this.setState({ activePanelId: index });
  }

  render() {
    const { className, panels, centeredLabels, testRef } = this.props;

    if (!panels) return null;

    const { activePanelId } = this.state;
    const activePanel = panels[activePanelId];

    return (
      <div
        className={className}
        data-test-reference={testRef}
      >
        <LabelsContainer
          centeredLabels={centeredLabels}
          data-test-reference="labels-container"
        >
          {panels.map((panel, i) => {
            return (
              <BodyText key={i}>
                <Label
                  onClick={e => this.changeActivePanel(e, i)}
                  active={i === activePanelId}
                  centeredLabels={centeredLabels}
                  testRef={panel.labelTestRef}
                >
                  {panel.label}
                </Label>
              </BodyText>
            );
          })}
        </LabelsContainer>

        <Panel key={activePanelId}>
          {activePanel.panel}
        </Panel>
      </div>
    );
  }
}
