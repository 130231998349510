import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';


const ReferralMetaTags = ({ referralLink }) => {
  const socialShare = {
    name: `Interested in BrickX? We could both receive $25* if you use my Referral Link.`,
    description: 'Read the PDS and consider whether BrickX is right for you at brickx.com/pds. *Terms and conditions apply.',
    pictureUrl: `https://assets.brickx.com/images/social_share_referral_line.gif`,
  };
  return (
    <div className="meta-tags-referrals">
      <Helmet>
        <title>{socialShare.name}</title>
        <meta name="description" content={socialShare.description} />
        <meta name="keywords" content="referral, refer a friend share $25 credit" />
        <meta property="og:title" content={socialShare.name} />
        <meta property="og:description" content={socialShare.description} />
        <meta property="og:image" content={socialShare.pictureUrl} />
        <meta property="og:image:url" content={socialShare.pictureUrl} />
        <meta property="og:image:secure_url" content={socialShare.pictureUrl} />
        <meta property="og:url" content={referralLink} />
        <meta property="og:image:alt" content="referral" />
        <meta name="twitter:description" content={socialShare.description} />
        <meta name="twitter:url" content={referralLink} />
        <meta name="twitter:title" content={socialShare.name} />
        <meta name="twitter:image:src" content={socialShare.picture} />
        <meta name="robots" content="noindex"/>
      </Helmet>
    </div>
  );
};

ReferralMetaTags.prototype = {
  referralLink: PropTypes.string.isRequired
};

export default ReferralMetaTags;
