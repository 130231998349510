import { Heading } from 'src/design/components/heading/Headings';
import PrimaryButton from 'src/design/components/button/PrimaryButton';

import { signupUrl } from 'src/utils/pageUrls';

import styles from 'src/components/landingPages/smartInvest/pinkSpacerBubble/PinkSpacerBubble.mscss';


const PinkSpacerBubble = ({ }) => {
  return (
    <section className={styles.pinkSpacerBubbleConainer}>
      <div className={styles.pinkBubble}>
        <Heading className={styles.heading}><h3>Start building your BrickX portfolio today</h3></Heading>
        <PrimaryButton
          className={styles.getStartedButton}
          link={signupUrl()}
          color={PrimaryButton.colors.PRIMARY}
        >
          Sign Up
        </PrimaryButton>
      </div>
    </section>
  );
};

export default PinkSpacerBubble;
