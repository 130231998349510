import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import $ from 'jquery';

import { resetPassword as resetPasswordPropType } from 'scripts/constants/PropTypes';
import { resetPasswordSelector } from 'scripts/redux/selectors/resetPassword';
import { validateResetPasswordToken, processResetPassword } from 'scripts/redux/actions/resetPassword';
import { errorAlert } from 'scripts/redux/actions/alerts';


import ResetPasswordActionType from 'scripts/constants/ResetPasswordActionType';


const mapStateToProps = (state) => ({
  ...resetPasswordSelector(state),
});

@connect(mapStateToProps, {
  validateResetPasswordToken,
  processResetPassword,
  errorAlert
})
export default class ResetPasswordToken extends Component {
  static propTypes = {
    params: PropTypes.shape({
      token: PropTypes.string.isRequired
    }),
    resetPassword: resetPasswordPropType,
    validateResetPasswordToken: PropTypes.func.isRequired,
    processResetPassword: PropTypes.func.isRequired,
    errorAlert: PropTypes.func.isRequired,
  };

  state = {
    password: '',
    confirmPassword: '',
  };

  componentDidMount() {
    const { validateResetPasswordToken, params: { token } } = this.props;
    validateResetPasswordToken(token);
    $('[data-toggle]').popover();
  }

  render() {
    const { resetPassword } = this.props;
    if (!resetPassword) {
      return null;
    }
    const resetPasswordProcessed = resetPassword && resetPassword.action === ResetPasswordActionType.PROCESS && resetPassword.success;

    return (
      <div className="reset-password">

        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h1>Reset your password</h1>
            </div>
            <div className="col-md-6 col-md-offset-3">
              <div className="white-box">
                { !resetPasswordProcessed &&
                  <div>
                    <p>Enter the new password.</p>
                    <form className="reset-password-form" onSubmit={::this._onResetPasswordSubmit}>
                      <div className="input-box">
                        <input className="reset-password-form__password" type="password" placeholder="New password" required onChange={::this._onPasswordChange} data-trigger="focus" data-toggle="popover" data-placement="top" data-html="true" data-content="Your password should contain:<ul><li>at least 8 characters;</li><li>at least 1 letter and 1 digit.</li></ul>"/>
                      </div>
                      <div className="input-box">
                        <input className="reset-password-form__confirm-password" type="password" placeholder="Confirm new password" required onChange={::this._onConfirmPasswordChange} data-trigger="focus" data-toggle="popover" data-placement="top" data-html="true" data-content="Your password should contain:<ul><li>at least 8 characters;</li><li>at least 1 letter and 1 digit.</li></ul>"/>
                      </div>
                      <button type="submit" className="button orange-button right-arrow-button reset-password-form__button">RESET PASSWORD</button>
                    </form>
                  </div>
                }
                <Link to="/login">Return to login</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  _onPasswordChange(e) {
    this.setState({ password: e.target.value });
  }

  _onConfirmPasswordChange(e) {
    this.setState({ confirmPassword: e.target.value });
  }

  _onResetPasswordSubmit(e) {
    e.preventDefault();
    const { processResetPassword, params: { token } } = this.props;
    processResetPassword(this.state, token);
  }
}
