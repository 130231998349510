import styled from 'styled-components';
import { getThemeColor } from 'src/themes/themeUtils';
import { gridUnit } from 'src/design/styleguide/common/measurements';
import Paragraph from 'src/design/styleguide/Paragraph';
import Spacing from 'src/design/styleguide/spacing/Spacing';

const LINE_WIDTH = 2;

const StyledEvent = styled.div`
  display: flex;
  justify-content: center;
`;

const EventMarker = styled.img.attrs((props) => ({
  src: props.iconSrc,
}))`
  position: absolute;
  height: ${(props) => props.eventMarkerSize}px;
  width: ${(props) => props.eventMarkerSize}px;
  color: ${getThemeColor('primary')};
  background-color: white;
  left: 0;
  ${props => props.eventMarkerBottomAligned
    ? 'bottom: 0;'
    : 'top: 50%;'
  }
  margin-top: -${(props) => props.eventMarkerSize / 2}px;
  z-index: 1;
`;

const Line = styled.div`
  position: absolute;
  left: ${(props) => (props.eventMarkerSize / 2) - (LINE_WIDTH / 2)}px;
  top: 0;
  height: 100%;
  width: ${LINE_WIDTH}px;
  background-color: ${getThemeColor('hollandaise')};

  ${props => props.lineBottomOnly ? `
    top: 50%;
    height: 50%;
  ` : ''}

  ${props => props.lineTopOnly ? `
    bottom: 50%;
    height: 50%;
  ` : ''}
`;

const StyledEventMarkerLine = styled.div`
  position: relative;
  width: ${(props) => props.eventMarkerSize}px;
  min-height: ${props => props.eventMarkerSize}px;
`;

const EventMarkerLine = ({
  className,
  iconSrc,
  eventMarkerSize,
  lineTopOnly,
  lineBottomOnly,
  eventMarkerBottomAligned,
}) => (
  <StyledEventMarkerLine
    className={className}
    eventMarkerSize={eventMarkerSize}
  >
    <EventMarker
      iconSrc={iconSrc}
      eventMarkerSize={eventMarkerSize}
      eventMarkerBottomAligned={eventMarkerBottomAligned}
    />
    <Line
      eventMarkerSize={eventMarkerSize}
      lineTopOnly={lineTopOnly}
      lineBottomOnly={lineBottomOnly}
    />
  </StyledEventMarkerLine>
);

const EventTextPlacement = styled.div`
  flex: 1 0 0px;
  ${props => props.left ? 'text-align: right;' : ''}
  ${props => props.left ? `margin-right: ${gridUnit * 4}px;` : `margin-left: ${gridUnit * 4}px;`}
  padding: 32px 0;
`;

const DateText = styled(Paragraph)`
  color: ${getThemeColor('primary')};
  font-weight: 300;
`;

const Event = ({
  date,
  text,
  left,
  first,
  eventMarkerIconSrc,
  eventMarkerSize,
}) => {
  const eventText = (
    <div>
      <Paragraph>{text}</Paragraph>
      <Spacing top="2x-small">
        <DateText>{date}</DateText>
      </Spacing>
    </div>
  );

  return (
    <StyledEvent>
      <EventTextPlacement
        eventMarkerSize={eventMarkerSize}
        left
      >
        {left ? eventText : ''}
      </EventTextPlacement>

      <EventMarkerLine
        iconSrc={eventMarkerIconSrc}
        eventMarkerSize={eventMarkerSize}
        lineBottomOnly={first}
      />

      <EventTextPlacement
        eventMarkerSize={eventMarkerSize}
      >
        {!left ? eventText : ''}
      </EventTextPlacement>
    </StyledEvent>
  );
};

const LastEventMarkerLine = styled(EventMarkerLine).attrs({
  eventMarkerBottomAligned: true,
})`
  min-height: ${props => props.eventMarkerSize + (gridUnit * 16)}px;
`;

const LastEvent = ({ date, text }) => (
  <div>
    <StyledEvent>
      <LastEventMarkerLine
        iconSrc="static/img/icon-pink-house.svg"
        eventMarkerSize={gridUnit * 24}
        lineTopOnly
      />
    </StyledEvent>
    <div>
      <Paragraph align="center">{text}</Paragraph>
      <Spacing top="2x-small">
        <DateText align="center">{date}</DateText>
      </Spacing>
    </div>
  </div>
);

const Timeline = ({ className, items }) => (
  <div className={className}>
    {items.map(({ date, text }, index) => {
      const isOddIndex = !(index % 2);
      const isLastIndex = index >= items.length - 1;
      const isFirstIndex = index === 0;

      return isLastIndex
        ? (
          <LastEvent
            key={`${date} - ${text}`}
            date={date}
            text={text}
          />
        )
        : (
          <Event
            key={`${date} - ${text}`}
            date={date}
            text={text}
            left={isOddIndex}
            eventMarkerIconSrc="static/img/icon-brick.svg"
            eventMarkerSize={gridUnit * 9}
            first={isFirstIndex}
          />
        );
    })}
  </div>
);

export default Timeline;
