import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getUserBankAccountDetails } from 'src/utils/user';
import { hasNominatedBankAccount } from 'scripts/utilities/userAccountHelper';
import {
  smartInvestSelector,
  addSmartInvestDetails as addSmartInvestDetailsAction
} from 'scripts/redux/actions/smartInvest';
import { trackDirectDebitBankDetailsEntered } from 'src/tracking/onboarding';
import { userSelector } from 'scripts/redux/actions/user';
import { user as userType } from 'scripts/constants/PropTypes';
import BankDetailsInput from 'src/components/oldOnboarding/smartInvestDetails/bankDetailsInput/BankDetailsInput';
import SmartInvestAmountInput from 'src/components/oldOnboarding/smartInvestDetails/smartInvestAmountInput/SmartInvestAmountInput';
import SmartInvestDayOfMonthInput from 'src/components/oldOnboarding/smartInvestDetails/smartInvestDayOfMonthInput/SmartInvestDayOfMonthInput';


const screens = {
  SmartInvestAmountInput: { next: 'SmartInvestDayOfMonthInput' },
  SmartInvestDayOfMonthInput: { next: 'BankDetailsInput', previous: 'SmartInvestAmountInput' },
  BankDetailsInput: { previous: 'SmartInvestDayOfMonthInput' },
};

@connect((state) => ({
  ...userSelector(state),
  ...smartInvestSelector(state),
}), {
  addSmartInvestDetails: addSmartInvestDetailsAction,
})
class SmartInvestDetails extends Component {
  static propTypes = {
    user: userType,
    addSmartInvestDetails: PropTypes.func,
    smartInvest: PropTypes.shape({
      saving: PropTypes.bool,
    }),
  }

  state = {
    smartInvestAmount: '',
    smartInvestDayOfMonth: '',
    bankAccountName: getUserBankAccountDetails(this.props.user).bankAccountName,
    bankAccountBsb: getUserBankAccountDetails(this.props.user).BSB,
    bankAccountNumber: getUserBankAccountDetails(this.props.user).bankAccountNumber,
    bankName: '',
    agreeToDirectDebitRequest: false,
    agreeToDirectDebitRequestServiceAgreement: false,
    bankStatementScreenshot: false,
    screenToShow: 'SmartInvestAmountInput',
  };

  goToNextScreen = () => {
    this.setState({ screenToShow: screens[this.state.screenToShow].next });
  }

  goToPreviousScreen = () => {
    this.setState({ screenToShow: screens[this.state.screenToShow].previous });
  }

  save = () => {
    const { addSmartInvestDetails } = this.props;

    trackDirectDebitBankDetailsEntered({
      amount: this.state.smartInvestAmount,
      dayOfMonth: this.state.smartInvestDayOfMonth,
      bankName: this.state.bankName
    });

    addSmartInvestDetails({
      amount: this.state.smartInvestAmount,
      dayOfMonth: this.state.smartInvestDayOfMonth,
      bankAccountName: this.state.bankAccountName,
      bsb: this.state.bankAccountBsb,
      bankAccountNumber: this.state.bankAccountNumber,
    });
  }

  render() {
    const renderedScreens = {
      'SmartInvestAmountInput': (
        <SmartInvestAmountInput
          onChange={(amount) => this.setState({ smartInvestAmount: amount })}
          value={this.state.smartInvestAmount}
          onNext={this.goToNextScreen}
        />
      ),
      'SmartInvestDayOfMonthInput': (
        <SmartInvestDayOfMonthInput
          onChange={(dayOfMonth) => this.setState({ smartInvestDayOfMonth: dayOfMonth })}
          value={this.state.smartInvestDayOfMonth}
          onNext={this.goToNextScreen}
          onBack={this.goToPreviousScreen}
        />
      ),
      'BankDetailsInput': (
        <Fragment>
          <BankDetailsInput
            onBankAccountNameChange={(value) => this.setState({ bankAccountName: value })}
            onBankAccountBsbChange={(value) => this.setState({ bankAccountBsb: value })}
            onBankAccountNumberChange={(value) => this.setState({ bankAccountNumber: value })}
            onBankNameChange={(value) => this.setState({ bankName: value })}
            onDirectDebitRequestChange={(checked) => this.setState({ agreeToDirectDebitRequest: checked })}
            onDirectDebitRequestServiceAgreementChange={(checked) => this.setState({ agreeToDirectDebitRequestServiceAgreement: checked })}
            onBankStatementScreenshotSelected={(file) => this.setState({ bankStatementScreenshot: file })}
            values={{
              smartInvestAmount: this.state.smartInvestAmount,
              smartInvestDayOfMonth: this.state.smartInvestDayOfMonth,
              bankAccountName: this.state.bankAccountName,
              bankAccountBsb: this.state.bankAccountBsb,
              bankAccountNumber: this.state.bankAccountNumber,
              bankName: this.state.bankName,
              agreeToDirectDebitRequest: this.state.agreeToDirectDebitRequest,
              agreeToDirectDebitRequestServiceAgreement: this.state.agreeToDirectDebitRequestServiceAgreement,
              bankStatementScreenshot: this.state.bankStatementScreenshot,
            }}
            hasBankDetailsAlreadySet={hasNominatedBankAccount(this.props.user)}
            saving={this.props.smartInvest.saving}
            onNext={this.save}
            onBack={this.goToPreviousScreen}
          />
        </Fragment>
      )
    };

    return renderedScreens[this.state.screenToShow];
  }
}

export default SmartInvestDetails;
