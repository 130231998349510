import classNames from 'classnames';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import { BrickxLogoDark } from 'src/components/brickxLogo/BrickxLogo';
import { HeadingSmall, HeadingXSmall } from 'src/design/components/heading/Headings';
import Paragraph from 'src/design/components/bodyText/Paragraph';
import ListItemNumber from 'src/components/landingPages/common/listItemNumber/ListItemNumber';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import SecondaryButton from 'src/design/components/button/SecondaryButton';
import FinePrint from 'src/design/components/bodyText/FinePrint';
import Styledlink from 'src/design/components/hyperlink/Styledlink';
import {
  signupUrl,
  raizReferralTermsUrl,
  pdsUrl,
  howItWorksUrl,
} from 'src/utils/pageUrls';
import { clickedPartnerReferralConfirmation } from 'scripts/redux/actions/segment/events/referralEvents';
import styles from 'src/components/partnerships/PartnershipModalContent.mscss';

const onSignupLinkClick = (partnerName) => clickedPartnerReferralConfirmation({ from: 'Partner Referral Popup', partner: partnerName, title: 'Sign Up Now' });
const onLearnMoreLinkClick = (partnerName) => clickedPartnerReferralConfirmation({ from: 'Partner Referral Popup', partner: partnerName, title: 'Check Out BrickX' });
const onTsAndCsClick = (partnerName) => clickedPartnerReferralConfirmation({ from: 'Partner Referral Popup', partner: partnerName, title: 'See Full T&Cs' });
const onPdsLinkClick = (partnerName) => clickedPartnerReferralConfirmation({ from: 'Partner Referral Popup', partner: partnerName, title: 'www.brickx.com/PDS' });

const PartnershipModalContent = ({ partnerName }) => (
  <div className={styles.container}>
    <div className={classNames(styles.contentContainerWrapper, styles.reactModalOverrides)}>
      <div className={styles.contentContainer}>
        <Spacing bottom="normal">
          <BrickxLogoDark/>
        </Spacing>

        <Spacing bottom="x-small">
          <HeadingSmall><h1>Want $15 in your {partnerName} account?</h1></HeadingSmall>
        </Spacing>

        <Spacing bottom="large">
          <Paragraph textSize="small">Buy 1 or more Bricks within 30 days of signup*</Paragraph>
        </Spacing>

        <Spacing bottom="normal">
          <div className={styles.howToClaimListContainer}>
            <Spacing bottom="normal">
              <HeadingXSmall><h2>How can I get the $15?</h2></HeadingXSmall>
            </Spacing>

            <ol className={classNames(styles.howToClaimList, ListItemNumber.counterResetClass)}>
              <li className={styles.howToClaimList__item}><ListItemNumber className={styles.howToClaimList__number} color="salmon"/>Learn more about BrickX through our website and consider whether it is the right type of investment for you.</li>
              <li className={styles.howToClaimList__item}><ListItemNumber className={styles.howToClaimList__number} color="salmon"/>Sign up to BrickX and buy 1 or more Bricks within 30 days.</li>
              <li className={styles.howToClaimList__item}><ListItemNumber className={styles.howToClaimList__number} color="salmon"/>BrickX will deposit $15 directly into your {partnerName} account.</li>
            </ol>
          </div>
        </Spacing>

        <Spacing bottom="x-small">
          <PrimaryButton
            link={signupUrl()}
            onClick={() => onSignupLinkClick(partnerName)}
            color={PrimaryButton.colors.SECONDARY}
            fullWidth
            testRef="sign-up-button"
          >
            Sign Up Now
          </PrimaryButton>
        </Spacing>

        <Spacing bottom="large">
          <SecondaryButton
            link={howItWorksUrl()}
            onClick={() => onLearnMoreLinkClick(partnerName)}
            color={SecondaryButton.colors.PRIMARY}
            fullWidth
            testRef="learn-more-button"
          >
            Check Out BrickX
          </SecondaryButton>
        </Spacing>

        <FinePrint>
          <Styledlink href={raizReferralTermsUrl()} target="_blank" onClick={() => onTsAndCsClick(partnerName)} testRef="terms-link">*See Full T&Cs</Styledlink>. Available only to eligible {partnerName} investors. Read the PDS to consider if BrickX is right for you at <Styledlink href={pdsUrl()} onClick={() => onPdsLinkClick(partnerName)} target="_blank" testRef="pds-link">www.brickx.com/PDS</Styledlink>.
        </FinePrint>
      </div>
    </div>
    <div className={styles.imageContainer}>
      <img
        className={styles.image}
        srcSet="static/img/photo-hand-holding-property-cards.jpg 550w,
          static/img/photo-hand-holding-property-cards@2x.jpg 1100w,
          static/img/photo-hand-holding-property-cards@3x.jpg 1650w"
        alt="hand holding cards with images of properties with adelaide, sydney and ballarat written on them"
      />
    </div>
  </div>
);

export default PartnershipModalContent;
