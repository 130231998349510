import classNames from 'classnames';
import PropTypes from 'prop-types';
import PageMarginContainer from 'src/design/components/pageMarginContainer/PageMarginContainer';
import styles from 'src/design/components/pageContentBlock/PageContentBlock.mscss';


const colors = {
  ORANGE: 'ORANGE',
  DARK_BLUE: 'DARK_BLUE',
  GREY: 'GREY',
};

const PageContentBlock = ({ id, children, color, className, ...otherProps }) => {
  const colorClassMap = {
    'orange-background': color === colors.ORANGE,
    'dark-blue-background': color === colors.DARK_BLUE,
    'grey-background': color === colors.GREY,
  };

  return (
    <PageMarginContainer
      {...(id ? { id } : {})}
      className={classNames(styles.root, colorClassMap, className)}
      {...otherProps}
    >
      {children}
    </PageMarginContainer>
  );
};

PageContentBlock.colors = colors;

PageContentBlock.propTypes = {
  id: PropTypes.string,
  color: PropTypes.string,
};

export default PageContentBlock;
