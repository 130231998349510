import { Component } from 'react';
import PropTypes from 'prop-types';
import analytics from 'src/tracking/analytics';
import getYoutubeApi from 'src/third-party/getYoutubeApi';

export default class BindYoutubeTrackingToIframe extends Component {
  static propTypes = {
    iframeId: PropTypes.string,
    onPlay: PropTypes.func,
    onFinish: PropTypes.func,
    onReady: PropTypes.func,
  };

  static defaultProps = {
    onPlay: () => {},
    onFinish: () => {},
    onReady: () => {},
  };

  componentDidMount = () => getYoutubeApi().then((YT) => {
    const player = new YT.Player(this.props.iframeId, {
      events: {
        onReady: this.props.onReady,
        onStateChange: (event) => {
          switch (event.data) {
            case YT.PlayerState.PLAYING:
              this.props.onPlay();
              analytics.track('Video Played', {
                video: player.getVideoData().title,
                id: player.getVideoData().video_id,
                duration: player.getDuration()
              });
              break;
            case YT.PlayerState.PAUSED:
              analytics.track('Video Paused', {
                video: player.getVideoData().title,
                id: player.getVideoData().video_id,
                pausedAt: player.getCurrentTime()
              });
              break;
            case YT.PlayerState.ENDED:
              this.props.onFinish();
              analytics.track('Video Finished', {
                video: player.getVideoData().title,
                id: player.getVideoData().video_id,
                duration: player.getDuration()
              });
              break;
            default: return;
          }
        }
      }
    });
  });

  render = () => null;
}
