import { connect } from 'react-redux';
import withState from 'src/decorators/withState';

const withSubmittableForm = ({
  Form,
  onSubmit,
  mapDispatchToProps,
  mapStateToProps,
  mapPropsToInitialFormValues,
  componentDidMount,
  componentWillUnmount,
}) => {
  const SubmittableForm = ({ setState, state, ...props }) => (
    <Form
      values={state}
      onChange={setState}
      onSubmit={() => onSubmit({ props, formValues: state, onChange: setState })}
      {...props}
    />
  );

  return connect(mapStateToProps, mapDispatchToProps)(withState({
    Component: SubmittableForm,
    mapPropsToInitialState: mapPropsToInitialFormValues,
    componentDidMount,
    componentWillUnmount,
  }));
};

export default withSubmittableForm;
