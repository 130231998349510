import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import cookie from 'react-cookie';
import isFunction from 'lodash.isfunction';
import { UserStatus } from 'scripts/constants/Cookie';
import { auth as authPropType } from 'scripts/constants/PropTypes';
import { authSelector } from 'scripts/redux/selectors/auth';
import { getReferral } from 'scripts/utilities/referralHelper';
import { getReferralCookie } from 'src/utils/referrals';
import { isInvestor, isMember } from 'scripts/utilities/userAccountHelper';
import { login as loginAction } from 'scripts/redux/actions/user';
import {
  remove as removeCookie,
  load as loadCookie,
} from 'src/browser/cookies';
import { routingSelector } from 'scripts/redux/selectors/routing';
import { updateLocationHref } from 'src/browser/window';
import { userSelector } from 'scripts/redux/selectors/user';
import Authentication from 'scripts/constants/Authentication';
import Configuration from 'scripts/constants/Configuration';
import Regex from 'scripts/utilities/regex';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import Hyperlink from 'scripts/components/shared/Hyperlink';
import { resetPasswordUrl } from 'src/utils/pageUrls';
import Spacing from 'src/design/styleguide/spacing/Spacing';

const mapStateToProps = (state) => ({
  ...authSelector(state),
  ...userSelector(state),
  ...routingSelector(state),
});

@connect(mapStateToProps, {
  login: loginAction,
})
export default class LoginForm extends Component {
  static propTypes = {
    login: PropTypes.func.isRequired,
    onLoginSuccess: PropTypes.func,
    nextPathname: PropTypes.string,
    auth: authPropType,
    doRedirect: PropTypes.bool,
    routing: PropTypes.object,
    email: PropTypes.string,
    disableEmailEdit: PropTypes.bool,
  };

  state = {
    email: {
      value: this.props.email || '',
      invalid: false,
    },
    password: {
      value: '',
      invalid: false,
    },
    javascriptReady: false,
  };

  componentDidMount() {
    this.setState({
      // eslint-disable-line react/no-did-mount-set-state
      javascriptReady: true,
      nextUrl: loadCookie('nextPathname') || this.props.nextPathname,
    });
    removeCookie('nextPathname');
  }

  componentDidUpdate() {
    const { auth } = this.props;
    const loggedIn =
      auth && auth.action === Authentication.LOGIN && auth.success;
    if (loggedIn) {
      this._onLoginSuccess();
    }
  }

  render() {
    const { className, disableEmailEdit } = this.props;
    const { email, password } = this.state;
    return (
      <form
        id="login-form"
        className={classNames('login-form', className)}
        role="form"
        onSubmit={::this._onSubmit}
        data-test-reference="login-form-to-submit"
      >
        <div className="form-group">
          <input
            type="text"
            className="login-form__field login-form__email large"
            name="email"
            placeholder="Email"
            value={email.value}
            onChange={::this._onEmailChange}
            disabled={disableEmailEdit || !this.state.javascriptReady}
            data-test-reference="email-text-input"
          />
          <div className="form-message__item error-email">
            {email.invalid && 'Please enter your valid email address'}
          </div>
        </div>
        <div className="form-group">
          <input
            type="password"
            className="login-form__field login-form__password large"
            name="password"
            placeholder="Password"
            value={password.value}
            onChange={::this._onPasswordChange}
            disabled={!this.state.javascriptReady}
            data-test-reference="password-text-input"
          />
          <div className="form-message__item error-password">
            {password.invalid && 'Please enter your password'}
          </div>
        </div>
        <Spacing bottom="normal">
          <PrimaryButton
            color={PrimaryButton.colors.SECONDARY}
            type="submit"
            fullWidth
            arrow
          >
            LOGIN
          </PrimaryButton>
        </Spacing>
        <Hyperlink className="forgot-link" linkTo={resetPasswordUrl()}>
          Forgot your password?
        </Hyperlink>
      </form>
    );
  }

  _onEmailChange(e) {
    this.setState({ email: { value: e.target.value } });
  }

  _onPasswordChange(e) {
    this.setState({ password: { value: e.target.value } });
  }

  _validateEmail = () => {
    const email = this.state.email;
    email.invalid =
      !email.value.trim().match(Regex.email) || email.value.trim() === '';
    this.setState({ email: email });
  };

  _validatePassword = () => {
    const password = this.state.password;
    password.invalid = password.value === '';
    this.setState({ password: password });
  };

  _validateForm = () => {
    this._validateEmail();
    this._validatePassword();
  };

  _onSubmit(e) {
    e.preventDefault();
    this._validateForm();

    const { email, password } = this.state;
    if (email.invalid || password.invalid) {
      return;
    }

    const { login } = this.props;
    const { partnerReferralId } = getReferral();
    const { token: customerReferralId } = getReferralCookie();

    login({
      email: email.value.trim(),
      password: password.value,
      customerReferralId,
      partnerReferralId,
    });
  }

  _setMembershipCookieTo = (userStatus) => {
    cookie.save(Configuration.COOKIES.MEMBERSHIP_STATUS, userStatus, {
      path: Configuration.PAGE_URLS.HOME_PAGE,
      maxAge: 1000 * 60 * 60 * 24 * 90,
    }); // eslint-disable-line no-magic-numbers
  };

  _onLoginSuccess = () => {
    const { auth, doRedirect, onLoginSuccess } = this.props;
    const { isComponentUpdated, nextUrl } = this.state;

    if (!isComponentUpdated) {
      removeCookie('referral_pre_activation');

      const loggedInUser = auth.user;
      if (isInvestor(loggedInUser)) {
        this._setMembershipCookieTo(UserStatus.Investor);
      } else if (isMember(loggedInUser)) {
        this._setMembershipCookieTo(UserStatus.Member);
      } else if (loggedInUser) {
        this._setMembershipCookieTo(UserStatus.Signup);
      }
      if (isFunction(onLoginSuccess)) {
        onLoginSuccess();
      }
      if (doRedirect) {
        if (!nextUrl) {
          updateLocationHref(Configuration.PAGE_URLS.ACCOUNT.DASHBOARD);
        } else {
          updateLocationHref(nextUrl);
        }
      }
      this.setState({ isComponentUpdated: true });
    }
  };
}
