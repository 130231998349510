import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { user as userPropType } from 'scripts/constants/PropTypes';
import { userSelector } from 'scripts/redux/selectors/user';

import { hasTfnProvided } from 'scripts/utilities/userAccountHelper';


const mapStateToProps = (state) => ({
  ...userSelector(state),
});

@connect(mapStateToProps)
export default class Tfn extends Component {
  static propTypes = {
    user: userPropType.isRequired,
    children: PropTypes.node.isRequired,
  };

  render() {
    const { user, children } = this.props;
    return (
      <div id="tfn-panel">
        { !hasTfnProvided(user) &&
          children
        }
      </div>
    );
  }

}
