import update from 'immutability-helper';
import { createAction, handleActions } from 'redux-actions';

import WalletService from 'scripts/services/WalletService';

import { openModal } from 'scripts/redux/actions/modals';
import { errorAlert } from 'scripts/redux/actions/alerts';
import { sendDepositFailedSegmentEvents } from 'scripts/redux/actions/segment/events/depositEvents';
import { fetchAccountInfo } from 'scripts/redux/actions/account';

import ModalType from 'scripts/constants/ModalType';
import Wallet from 'scripts/constants/Wallet';

const WITHDRAW_REQUEST = 'scripts/market/WITHDRAW_REQUEST';
const withdrawRequest = createAction(WITHDRAW_REQUEST);

const WITHDRAW_INITIALISED = 'scripts/market/WITHDRAW_INITIALISED';
const withdrawPend = createAction(WITHDRAW_INITIALISED);

export const withdrawRequested = (data) => withdrawRequest({
  type: Wallet.WITHDRAWAL.REQUESTED,
  data
});

export const withdrawPending = (data) => withdrawPend({
  type: Wallet.WITHDRAWAL.PENDING,
  data
});

const cleanWithdraw = () => withdrawRequest({
  type: Wallet.WITHDRAWAL.NOT_DEFINED,
  data: null
});

export function depositFunds({ amount }) {
  return async function (dispatch, getState) {
    const { user } = getState();
    try {
      const { url } = await WalletService.deposit(user.id, { amount });
      dispatch(openModal(ModalType.DEPOSIT, { url: url }));
    } catch (e) {
      dispatch(errorAlert(e.message));
      sendDepositFailedSegmentEvents(user);
    }
  };
}

export function cleanWithdrawInfo() {
  return async function (dispatch) {
    dispatch(cleanWithdraw());
  };
}

export function withdrawFunds({ amount, otpValue }) {
  return async function (dispatch, getState) {
    const { user } = getState();
    try {
      await WalletService.withdraw(user.id, { amount, otpValue });
      dispatch(fetchAccountInfo());
      dispatch(withdrawRequested({ amount: amount }));
    } catch (e) {
      dispatch(errorAlert(e.message));
    }
  };
}

const reducer = handleActions({
  [WITHDRAW_REQUEST]: (state, action) => update(state, {
    withdrawal: {
      $set: action.payload
    }
  }),
}, {
  withdrawal: null,
});

export default reducer;
