import generateId from 'nanoid';
import update from 'immutability-helper';

import { findIndexOfItemToRemove } from 'src/utils/collections';

import {
  TOGGLE_APP_BACKGROUND_BLUR,
  SHOW_MODAL,
  DISMISS_MODAL,
  PRE_DISMISS_MODAL,
  DISMISS_ALL_MODALS,
  SAVE_SCROLL_POSITION,
} from 'src/components/modals/modalActions';

const defaultState = {
  modalList: [],
  blurAppBackground: false,
  savedScrollPosition: 0,
};

export const modalsSelector = (state) => ({ modals: state.newModals.modalList });
export const savedScrollPositionSelector = (state) => ({ savedScrollPosition: state.newModals.savedScrollPosition });
export const blurAppBackgroundSelector = (state) => ({
  blurAppBackground: state.newModals.blurAppBackground,
});

const reducer = (state = defaultState, { type, payload }) => {
  const { modalList } = state;
  switch (type) {
    case DISMISS_MODAL:
      return update(state, {
        modalList: {
          $splice: [[
            findIndexOfItemToRemove({ items: modalList, id: payload }), 1
          ]],
        },
      });
    case PRE_DISMISS_MODAL:
      const toastIndex = findIndexOfItemToRemove({ items: modalList, id: payload });
      return toastIndex !== -1
        ? update(state, {
          modalList: {
            [toastIndex]: {
              $merge: {
                exitAnimation: true,
              },
            },
          },
        })
        : state;
    case SHOW_MODAL: return update(state, {
      modalList: {
        $push: [{ ...payload, id: generateId(5) }],
      },
    });
    case SAVE_SCROLL_POSITION: return { ...state, savedScrollPosition: payload };
    case TOGGLE_APP_BACKGROUND_BLUR: return { ...state, blurAppBackground: payload };
    case DISMISS_ALL_MODALS: return defaultState;
    default: return state;
  }
};


export default reducer;
