import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  property as propertyPropType,
  user as userPropType,
} from 'scripts/constants/PropTypes';

import FinancialsPanel from 'scripts/components/shared/FinancialsPanel';
import InitialBrickPriceBox from 'scripts/components/property/trade/InitialBrickPriceBox';
import ViewsOrSelldown from 'src/components/propertyCards/propertyCardPreOrder/viewsOrSelldown/ViewsOrSelldown';

import { settlementInfoValueToShow } from 'scripts/utilities/propertyHelper';

import { toggleModal as toggleLoginOrSignupModalAction } from 'src/store/loginOrSignupModalActions';

import { isUserLoggedIn } from 'scripts/utilities/userAccountHelper';

import _ from 'lodash';
import { renderCode } from 'src/settings/properties';

const SettlementText = ({ property }) => (
  <div className="text-center" style={{ margin: '10px' }}>
    Settlement Date: {settlementInfoValueToShow(property, false)}
  </div>
);

@connect(null, {
  toggleLoginOrSignupModal: toggleLoginOrSignupModalAction,
})
export default class InvestPanelPreOrder extends Component {
  static propTypes = {
    user: userPropType.isRequired,
    property: propertyPropType.isRequired,
    handleBuyButtonClick: PropTypes.func.isRequired,
    toggleLoginOrSignupModal: PropTypes.func.isRequired,
  };

  state = {
    preorderError: null,
    preOrderButtonClickedWhileUserLoggedOut: false,
  };

  componentDidUpdate() {
    this._prformActionAfterLoginPopup();
  }

  render() {
    const { property } = this.props;

    const viewsOrSelldown = (
      <div className="invest-panel-pre-order__views-selldown-container">
        <ViewsOrSelldown property={property} />
      </div>
    );

    return (
      <div className="pre-order-panel">
        <div className="mobile visible-xs">
          <InitialBrickPriceBox property={property} shownInLargerText />
          <SettlementText property={property} />
          <div className="grey-line"></div>
          {viewsOrSelldown}
          <button
            className="button blue-button trade-button buy-button right-arrow-button"
            onClick={this._handlePreOrderButtonClick}
          >
            PRE-ORDER BRICKS
          </button>
          <div className="trade-error">
            {this.state.preorderError || <div>&nbsp;</div>}
          </div>
        </div>
        <FinancialsPanel
          className="panel-financials-blue-header panel-financials-invest panel-pre-order hidden-xs"
          title={`Pre-order ${renderCode(property.propertyCode)}`}
        >
          <InitialBrickPriceBox property={property} shownInLargerText />
          <SettlementText property={property} />
          <div className="grey-line"></div>
          {viewsOrSelldown}
          <button
            className="button blue-button trade-button buy-button right-arrow-button"
            onClick={this._handlePreOrderButtonClick}
          >
            PRE-ORDER BRICKS
          </button>
          <div className="trade-error">
            {this.state.preorderError || <div>&nbsp;</div>}
          </div>
        </FinancialsPanel>
      </div>
    );
  }

  _handlePreOrderButtonClick = () => {
    const { user, property, handleBuyButtonClick, toggleLoginOrSignupModal } =
      this.props;
    if (isUserLoggedIn(user)) {
      if (property.investmentMetrics.remainingBricks <= 0) {
        this.setState({
          preorderError: `Sorry, all Bricks reserved. Check back later.`,
        });
      } else {
        handleBuyButtonClick();
      }
    } else {
      this.setState({ preOrderButtonClickedWhileUserLoggedOut: true });
      toggleLoginOrSignupModal();
    }
  };

  _prformActionAfterLoginPopup = () => {
    const { user } = this.props;
    const { preOrderButtonClickedWhileUserLoggedOut } = this.state;
    const isUserLoggedIn = !_.isNil(user.id);

    if (isUserLoggedIn && preOrderButtonClickedWhileUserLoggedOut) {
      this._handlePreOrderButtonClick();
      this.setState({ preOrderButtonClickedWhileUserLoggedOut: false });
    }
  };
}
