import styled, { css } from 'styled-components';
import { getThemeColor } from 'src/themes/themeUtils';
import { gridUnit } from 'src/design/styleguide/common/measurements';
import { standardTransition } from 'src/design/styleguide/common/animations';
import {
  testRefToDataTestReference,
} from 'src/design/styleguide/common/styledComponentsUtils';
import Paragraph from 'src/design/styleguide/Paragraph';

const checkboxSize = gridUnit * 6;

const disabledCursorStyle = css`
  ${props => props.disabled && 'cursor: not-allowed;'}
`;

const Root = styled.div`
  display: flex;
  ${props => props.verticallyCenteredLabel && 'align-items: center;'}
  padding-left: ${checkboxSize + (gridUnit * 4)}px;
  position: relative;
`;

const Checkbox = styled.input.attrs(testRefToDataTestReference)`
  height: 0;
  opacity: 0;
  width: 0;
`;

const disabledColorStyles = css`
  && {
    ${props => props.disabledStyleType === 'colored-checkbox'
    ? ''
    : `
    ${props.disabled && `border-color: ${getThemeColor('shale')(props)};`}
    ${Checkbox}:checked + & {
      ${props.disabled && `background-color: ${getThemeColor('concrete')(props)};`}
    }
    `
  }
  }
`;

const SubstituteCheckbox = styled.label`
  position: absolute;
  left: 0;
  height: ${checkboxSize}px;
  width: ${checkboxSize}px;
  margin: 0;
  border: 1px solid ${getThemeColor('grey500')};
  border-radius: 2px;
  cursor: pointer;
  transition: background-color ${standardTransition};
  ${disabledCursorStyle}
  ${disabledColorStyles}

  &::after {
    border: 2px solid white;
    border-radius: 2px;
    border-right: none;
    border-top: none;
    height: 35%;
    left: 30%;
    position: absolute;
    top: 45%;
    transform: rotate(-45deg);
    transform-origin: left;
    width: 60%;
  }

  ${Checkbox}:checked + & {
    background-color: ${props => props.customColor || getThemeColor('primary')};
    border: 1px solid transparent;
  }

  ${Checkbox}:checked + &::after {
    content: '';
  }

  ${Checkbox}:focus + & {
    border: 1px solid ${getThemeColor('inputBorderFocus')};
  }
`;

const Label = styled(Paragraph).attrs((props) => ({
  ...testRefToDataTestReference(props),
  as: 'label',
  ...props.labelProps,
}))`
  color:  ${getThemeColor('grey700')};
  ${disabledCursorStyle}
`;

const FormCheckbox = ({
  className,
  labelClassName,
  checkboxClassName,
  onChange,
  checked,
  labelProps,
  verticallyCenteredLabel,
  children,
  disabled,
  disabledStyleType,
  customColor,
  testRef,
  labelTestRef,
}) => {
  const checkboxId = `${Math.round(Math.random() * 1000000)}`;
  return (
    <Root className={className} verticallyCenteredLabel={verticallyCenteredLabel}>
      <Checkbox
        id={checkboxId}
        type="checkbox"
        onChange={(e) => onChange && onChange(e.target.checked)}
        checked={checked}
        disabled={disabled}
        testRef={testRef}
      />
      <SubstituteCheckbox
        htmlFor={checkboxId}
        className={checkboxClassName}
        disabled={disabled}
        disabledStyleType={disabledStyleType}
        customColor={customColor}
      />
      <Label
        htmlFor={checkboxId}
        className={labelClassName}
        labelProps={labelProps}
        disabled={disabled}
        testRef={labelTestRef}
      >
        {children}
      </Label>
    </Root>
  );
};

export default FormCheckbox;
