import styled from 'styled-components';
import informWhenJsLoaded from 'src/decorators/informWhenJsLoaded';
import {
  testRefToDataTestReference,
} from 'src/design/styleguide/common/styledComponentsUtils';

const StyledI = styled.i.attrs(testRefToDataTestReference)`
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'brickicons', blank !important;
  font-feature-settings: "liga" 1;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: text-bottom;
  letter-spacing: 0;
  line-height: inherit;

  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  opacity: ${props => props.loaded ? 1 : 0};
`;

const Icon = informWhenJsLoaded(({ type, jsLoaded, ...props }) => (
  <StyledI
    loaded={jsLoaded}
    {...props}
  >
    {type}
  </StyledI>
));

Object.defineProperty(Icon, 'name', { value: 'Icon' });

Icon.types = {
  control_panel_outline: 'control_panel_outline',
  double_arrows_outline: 'double_arrows_outline',
  exit_outline: 'exit_outline',
  house_outline: 'house_outline',
  pie_chart_outline: 'pie_chart_outline',
  thumbs_up_outline: 'thumbs_up_outline',
  play_triangle_rounded: 'play_triangle_rounded',
  linked_in: 'linked_in',
  twitter: 'twitter',
  facebook: 'facebook',
  speed_up: 'speed_up',
  clock_progress: 'clock_progress',
  clock_refresh: 'clock_refresh',
  smiley_face: 'smiley_face',
  error_alert: 'error_alert',
  horizontal_progress: 'horizontal_progress',
  hourglass_full: 'hourglass_full',
  hourglass_empty: 'hourglass_empty',
  hourglass_half_empty: 'hourglass_half_empty',
  crossed_out_dollar: 'crossed_out_dollar',
  graph: 'graph',
  chart: 'chart',
  bx_logo: 'bx_logo',
  house: 'house',
  auto_mobile: 'auto_mobile',
  car_parks: 'car_parks',
  bed_rooms: 'bed_rooms',
  person_in_bed: 'person_in_bed',
  bath_rooms: 'bath_rooms',
  bath_tub: 'bath_tub',
  tick: 'tick',
  check: 'check',
  left_corner: 'left_corner',
  right_corner: 'right_corner',
  play_triangle: 'play_triangle',
  info_outline: 'info_outline',
  info: 'info',
  chevron_down: 'chevron_down',
  chevron_up: 'chevron_up',
  coins: 'coins',
  percent_returns: 'percent_returns',
  schedule: 'schedule',
  caret_down: 'caret_down',
  caret_up: 'caret_up',
  chevron_left: 'chevron_left',
  chevron_right: 'chevron_right',
  arrow_forward: 'arrow_forward',
  arrow_back: 'arrow_back',
  close: 'close',
  clear: 'clear',
  menu: 'menu',
  vert_menu: 'vert_menu',
  more_vert: 'more_vert',
  exit: 'exit',
  folder: 'folder',
  help: 'help',
  flask: 'flask',
  strategy: 'strategy',
  acquisition_strategy: 'acquisition_strategy',
  adviser: 'adviser',
  speech_bubble: 'speech_bubble',
  property_purchase: 'property_purchase',
  buying: 'buying',
  investment_philosophy: 'investment_philosophy',
  investment: 'investment',
  video: 'video',
  different_bricks: 'different_bricks',
  hand_pointing_at_calendar: 'hand_pointing_at_calendar',
  funnel: 'funnel',
  transparent_glass: 'transparent_glass',
  hands_around_house: 'hands_around_house',
  piggy_bank: 'piggy_bank',
  house_ladder: 'house_ladder',
  home: 'home',
  dashboard: 'dashboard',
  hourglass: 'hourglass',
  pending_orders: 'pending_orders',
  wallet: 'wallet',
  briefcase: 'briefcase',
  portfolio: 'portfolio',
  calendar: 'calendar',
  transactions: 'transactions',
  settings: 'settings',
  'book_open': 'book_open',
  'documents': 'documents',
  'exit_left': 'exit_left',
  'logout': 'logout',
};

export default Icon;
