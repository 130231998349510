import { createTracker, EventTypes } from 'redux-segment';
import { extractAllExperimentsFromGaData } from 'src/tracking/optimize';

// redux-segment has out of the box support for react-router-redux and so sends a page track event on location change:
// https://github.com/rangle/redux-segment/blob/887e8bbac46aba7bbe3692634c35eebaa5ad6c04/src/event/configuration.js#L6

const trackPageWithExperiments = () => ({
  eventType: EventTypes.page,
  eventPayload: {
    properties: {
      experiments: extractAllExperimentsFromGaData(),
    },
  }
});

const customMapper = {
  mapper: {
    '@@router/INIT_PATH': trackPageWithExperiments,
    '@@router/UPDATE_PATH': trackPageWithExperiments,
    '@@router/LOCATION_CHANGE': trackPageWithExperiments,
  },
};

export const getTracker = () => createTracker(customMapper);
