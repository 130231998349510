import { DEPOSIT } from 'scripts/redux/actions/segment/SegmentEventTypes';
import SegmentEventTypes from 'scripts/redux/actions/segment/SegmentEventTypes';
import { track } from 'src/tracking/analytics';

export function sendDepositFailedSegmentEvents(user) {
  track(DEPOSIT.DEPOSIT_FAILED, { firstName: user.givenName, email: user.email });
}

export function sendDepositLinkClick({ from }) {
  return track(SegmentEventTypes.CLICK, { 'From': from, 'Title': 'Deposit' });
}

export function sendContinueDeposit({ method, amount }) {
  return track(DEPOSIT.CONTINUE_DEPOSIT, { 'Payment Method': method, 'Intended Deposit Amount': amount });
}

export function sendPoliCompletedEvent({ amount, bafmAmount }) {
  return track(DEPOSIT.DEPOSIT_SUCCEED_POLI, {
    'Status': 'Initiated',
    'Total Deposit Amount': amount,
    'Pending Funds': (amount - bafmAmount),
    'Funds Delivered to Customer Wallet': bafmAmount });
}
