// Stolen from https://developer.mozilla.org/en-US/docs/Web/API/Element/closest
// Needed for ExpandingCarousel's handleSlideClick method to be able to pick the slide that was clicked on.

if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
  Element.prototype.closest = function (s) {
    var el = this;
    if (!document.documentElement.contains(el)) {
      return null;
    }
    do {
      if (el.matches(s)) {
        return el;
      }
      el = el.parentElement;
    } while (el !== null);
    return null;
  };
}
