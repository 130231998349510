import { HeadingSmall } from 'src/design/components/heading/Headings';
import Paragraph from 'src/design/components/bodyText/Paragraph';
import FinePrint from 'src/design/components/bodyText/FinePrint';
import Styledlink from 'src/design/components/hyperlink/Styledlink';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import SecondaryButton from 'src/design/components/button/SecondaryButton';
import {
  pdsUrl,
  signupUrl,
  howItWorksSmartInvestTabUrl,
} from 'src/utils/pageUrls';
import Analytics from 'src/tracking/analytics';
import styles from 'src/components/propertySmartInvestSignupDriver/PropertySmartInvestSignupDriver.mscss';

const trackButtonClick = (button, from, propertyCode) => () => Analytics.track('SI Signup Driver Widget Interaction', {
  button,
  from,
  propertyCode,
});

const PropertySmartInvestSignupDriver = ({ trackingProps: { from = 'Not Specified', propertyCode = 'N/A' } = {} }) => (
  <div className={styles.propertySmartInvestSignupDriver__card}>
    <Spacing bottom="large">
      <HeadingSmall>
        <h2 className={styles.propertySmartInvestSignupDriver__heading}>Don't know which property to choose?</h2>
      </HeadingSmall>
    </Spacing>

    <div className={styles.propertySmartInvestSignupDriver__content}>
      <div className={styles.propertySmartInvestSignupDriver__text}>
        <Spacing bottom="normal">
          <Paragraph>
            Consider Smart Invest, a simple way to invest with BrickX.
          </Paragraph>
        </Spacing>

        <Spacing bottom="normal">
          <Paragraph>
            We’ll invest your money in Bricks in properties based on their growth potential, selected by our specialist property Team.
          </Paragraph>
        </Spacing>

        <FinePrint>
          Consider whether investing in Bricks is right for you by reading the <Styledlink target="_blank" href={pdsUrl()}>PDS</Styledlink>.
        </FinePrint>
      </div>

      <div className={styles.propertySmartInvestSignupDriver__ctas}>
        <PrimaryButton
          className={styles.propertySmartInvestSignupDriver__signupButton}
          color={PrimaryButton.colors.SECONDARY}
          link={signupUrl()}
          onClick={trackButtonClick('Sign Up', from, propertyCode)}
        >
          Sign Up Now
        </PrimaryButton>

        <SecondaryButton
          color={SecondaryButton.colors.PRIMARY}
          link={howItWorksSmartInvestTabUrl()}
          onClick={trackButtonClick('Find Out More', from, propertyCode)}
        >
          Find Out More
        </SecondaryButton>
      </div>
    </div>
  </div>
);

export default PropertySmartInvestSignupDriver;
