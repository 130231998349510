import { hot } from 'react-hot-loader';
import PageMarginContainer from 'src/design/components/pageMarginContainer/PageMarginContainer';
import Paragraph from 'src/design/components/bodyText/Paragraph';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import Meta from 'src/components/meta/Meta';

const CustomParagrah = ({ top, children }) => (
  <Spacing top={top || 'large'}>
    <Paragraph>{children}</Paragraph>
  </Spacing>
);

const ChangeOfAuditorPage = () => (
  <PageMarginContainer
    size="thin"
    testRef="change-of-auditors-results"
    withVerticalMargin
  >
    <Meta
      {...{
        title: 'Disclosure Notice | BrickX',
        description:
          'Disclosure Notice in Relation to the Resignation of Compliance Plan Auditor',
      }}
    />
    <Paragraph size="large">
      <h1>
        Disclosure Notice in Relation to the Resignation of Compliance Plan
        Auditor for the: <br />
        BrickX Financial Services Limited as Responsible Entity of the BrickX
        Platform Fund
      </h1>
    </Paragraph>
    <CustomParagrah>
      <div className="row">
        <div className="col-md-12">
          <strong>
            Details of Change of Compliance Plan Auditor – BrickX Platform Fund
            (ARSN:613 156 433)
          </strong>
        </div>
      </div>
    </CustomParagrah>
    <CustomParagrah>
      <div className="row">
        <div className="col-md-12">
          As per ASIC Regulatory Guide RG26 section RG 26.74 The Responsible
          Entity Notes the Following:
        </div>
      </div>
    </CustomParagrah>
    <CustomParagrah>
      <div className="row">
        <div className="col-md-12">
          Effective the date of this Notice being 13 August 2021 the following
          changes are noted:
        </div>
      </div>
    </CustomParagrah>
    <CustomParagrah top="2x-large">
      <div className="row">
        <div className="col-md-4">Outgoing Auditor Resignation:</div>
        <div className="col-md-8">Deloitte Touche Tohmatsu</div>
      </div>
    </CustomParagrah>
    <CustomParagrah top="small">
      <div className="row">
        <div className="col-md-4">Incoming Auditor:</div>
        <div className="col-md-8">Grant Thornton Audit Pty Ltd</div>
      </div>
    </CustomParagrah>
    <CustomParagrah top="small">
      <div className="row">
        <div className="col-md-4">Reason for Change:</div>
        <div className="col-md-8">
          Agreement in relation to the change of auditor has been made.
        </div>
      </div>
    </CustomParagrah>
  </PageMarginContainer>
);

export default hot(module)(ChangeOfAuditorPage);
