import PropTypes from 'prop-types';

const ImageSlide = ({ imageSet, name, testRef }) => (
  <img
    className="carousel-image-slide"
    data-test-reference={testRef}
    srcSet={`${imageSet.resized252Url} 252w,
      ${imageSet.resized595Url} 595w,
      ${imageSet.optimizedUrl} 900w`}
    sizes={`(max-width: 280px) 252px,
      (max-width: 620px) 595px,
      900px`}
    src={imageSet.resized595Url}
    alt={`Photograph of ${name}`}
  />
);

ImageSlide.propTypes = {
  imageSet: PropTypes.string,
  name: PropTypes.string,
  testRef: PropTypes.string,
};

export default ImageSlide;
