import styled from 'styled-components';
import { sizes, colors } from 'src/design/components/button/Button';
import Icon from 'src/design/components/icon/Icon';
import SecondaryButton from 'src/design/components/button/SecondaryButton';

const RIPPLE_SIZE = 85;

const RippleIconContainer = styled.span`
  display: inline-flex;
  position: relative;
`;

const Ripples = styled.span`
  position: absolute;
  top: -${RIPPLE_SIZE * 0.37}px;
  left: -${RIPPLE_SIZE * 0.38}px;
  height: ${RIPPLE_SIZE}px;
  width: ${RIPPLE_SIZE}px;
`;

const Ripple = styled.i`
  @keyframes wave {
    0% {
      opacity: 0.0;
      transform: scale(0);
    }
    25% {
      opacity: 0.0;
    }
    50% {
      opacity: 1.0;
    }
    75% {
      opacity: 1.0;
    }
    100% {
      opacity: 0.0;
      transform: scale(1);
    }
  }

  animation: wave 5s infinite linear;
  animation-fill-mode: both;
  border: 2px solid;
  border-color: inherit;
  border-radius: 50%;
  height: ${RIPPLE_SIZE}px;
  left: 0;
  position: absolute;
  width: ${RIPPLE_SIZE}px;

  &:nth-child(2) {
    animation-delay: 1.66s;
  }

  &:nth-child(3) {
    animation-delay: 3.33s;
  }
`;

const CenterIcon = styled(Icon)`
  color: inherit;
  line-height: 1;
`;

export const RippleIcon = ({ className, iconType }) => (
  <RippleIconContainer className={className}>
    <Ripples>
      <Ripple/>
      <Ripple/>
      <Ripple/>
    </Ripples>
    <CenterIcon type={iconType}/>
  </RippleIconContainer>
);

const StyledRippleIcon = styled(RippleIcon)`
  margin-right: ${RIPPLE_SIZE * 0.4}px;
`;

const StyledButton = styled(SecondaryButton)`
  && {
    border: none;

    &:hover, &:focus {
      box-shadow: none;
    }
  }
`;

const IconRippleButton = ({ className, iconType, children, ...props }) => (
  <StyledButton
    className={className}
    {...props}
  >
    <StyledRippleIcon iconType={iconType}/>
    {children}
  </StyledButton>
);

RippleIcon.colors = IconRippleButton.colors = colors;
RippleIcon.sizes = IconRippleButton.sizes = sizes;
RippleIcon.types = IconRippleButton.types = Icon.types;

export default IconRippleButton;
