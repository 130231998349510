import React, { Component } from 'react';
import DocumentMeta from 'react-document-meta';

import { SURVEY_TERMS_AND_CONDITIONS } from 'scripts/metadata/statics';

import Hyperlink from 'scripts/components/shared/Hyperlink';


export default class SurveyTermsAndConditions2017 extends Component {
  render() {
    return (
      <div className="survey-terms-and-conditions">
        <DocumentMeta {...SURVEY_TERMS_AND_CONDITIONS} />
          <div className="survey-terms-and-conditions-details">
            <span className="survey-terms-and-conditions-details__title">Survey Terms and Conditions</span>
            <ol className="survey-terms-and-conditions-details__items">
                <li className="survey-terms-and-conditions-details__item">To enter, participants are required to complete a BrickX survey. Employees of BrickX and its associated entities and their families are ineligible to enter.</li>
                <li className="survey-terms-and-conditions-details__item">The survey commences 30 October 2017, 09:00 AEDT and closes at 17 November 2017, 23:59 AEDT. The promoter may in its absolute discretion end the promotion prior to the conclusion time or date.</li>
                <li className="survey-terms-and-conditions-details__item">The winner will be selected at random by BrickX 18 November 2017 at 17-19 Bridge Street, Sydney NSW 2000. The winner will be notified by email.</li>
                <li className="survey-terms-and-conditions-details__item">The winner will be decided on at random in a draw, and skill plays no part in determining the winner.</li>
                <li className="survey-terms-and-conditions-details__item">The prize is $500 credit to a Members BrickX account. Prior to any prize being credited to an account, the winner must be or become a Member of the BrickX Platform. Alternatively, the winner may decide to opt for the $500 prize to be donated to a charity of their choice in their name.</li>
                <li className="survey-terms-and-conditions-details__item">The prize is not transferable or exchangeable.</li>
                <li className="survey-terms-and-conditions-details__item">The Promoter shall not be liable for any loss or damage whatsoever which is suffered (including but not limited to indirect or consequential loss) or for personal injury which is suffered or sustained, as a result of taking the prize, except for any liability which cannot be excluded by law. Certain legislation may imply warranties or conditions which cannot be excluded, restricted or modified except to a limited extent. These terms and conditions must be read subject to those statutory conditions.</li>
                <li className="survey-terms-and-conditions-details__item">The Promoter accepts no responsibility for any tax implications that may arise from taking the prize. Independent financial advice should be sought.</li>
                <li className="survey-terms-and-conditions-details__item">The Promoter collects entrants' personal information in order to conduct the promotion. If the information requested is not provided, the entrant may not participate in the promotion. For further details on Privacy, please see <Hyperlink linkTo="/privacy">https://www.brickx.com/privacy</Hyperlink>.</li>
                <li className="survey-terms-and-conditions-details__item">In the event of war, terrorism, state of emergency, disaster or (without limitation) any other event outside our reasonable control, the Promoter is entitled to cancel, terminate, modify or suspend the competition.</li>
                <li className="survey-terms-and-conditions-details__item">The Promoter is The Brick Exchange Pty Ltd, ABN 27 600 762 749 , 17-19 Bridge Street, Sydney NSW 2000.</li>
            </ol>
          </div>
          <div className="survey-terms-and-conditions-winner">
            <span className="survey-terms-and-conditions-winner__name">Winner: Chantal Hopwood</span>
            <span className="survey-terms-and-conditions-winner__date">Date: 18 November, 2017</span>
          </div>
      </div>
    );
  }
}
