import _ from 'lodash';
import OrderSide from 'scripts/constants/OrderSide';
import { priceDate as priceDateSort } from 'scripts/utilities/sorters';


function filterByOrderSide(orderSide) {
  return _.filter(this, { orderSide });
}

function sum(numbers) {
  return _.reduce(numbers, function (result, num) {
    return result + num;
  }, 0);
}

function aggregateList(array, groupBy, aggregateOn) {
  return _.chain(array)
    .groupBy(groupBy)
    .map(function (value, key) {
      return {
        price: parseInt(key, 10),
        quantity: sum(_.map(value, aggregateOn)),
        propertyCode: value.propertyCode
      };
    }).value();
}

const monthlyDistributionsGetter = (state) => state.market.monthlyDistributions;
const tradesGetter = (state) => state.market.trades;
const lastNTradesGetter = (state) => state.market.lastNTrades;
const orderBookStatusGetter = (state) => state.market.orderBookStatus;
const ordersGetter = (state) => state.market.orders;
const remainingBricksGetter = (state) => state.market.remainingBricks;
const bricksTransactedLastMonthGetter = (state) => state.market.bricksTransactedLastMonth;
const tradeProposalGetter = (state) => state.market.tradeProposal;
const tradeSucceedGetter = (state) => state.market.tradeSucceed;
const myOrdersGetter = (state) => state.market.myOrders;
const myTradesGetter = (state) => state.market.myTrades;
const transactionsGetter = (state) => state.market.transactions;
const returnsCalculatorStatusGetter = (state) => state.market.returnsCalculatorStatus;
const marketStatusGetter = (state) => state.market.marketStatus;
const promotionsGetter = (state) => state.market.promotions;
const feeFreePromotionStatusGetter = (state) => state.market.feeFreePromotionStatus;


export const monthlyDistributionsSelector = (state) => ({
  monthlyDistributions: monthlyDistributionsGetter(state)
});

export const tradesSelector = (state) => ({
  trades: tradesGetter(state)
});

export const lastNTradesSelector = (state) => ({
  lastNTrades: lastNTradesGetter(state)
});

export const remainingBricksSelector = (state) => ({
  remainingBricks: remainingBricksGetter(state)
});

export const bricksTransactedLastMonthSelector = (state) => ({
  bricksTransactedLastMonth: bricksTransactedLastMonthGetter(state)
});

export const tradeProposalSelector = (state) => ({
  tradeProposal: tradeProposalGetter(state)
});

export const tradeSucceedSelector = (state) => ({
  tradeSucceed: tradeSucceedGetter(state)
});

export const ordersBuySelector = (state) => ({
  ordersBuy: aggregateList(ordersGetter(state)::filterByOrderSide(OrderSide.BUY)::priceDateSort(true, true), 'price', 'quantity')
});

export const ordersSellSelector = (state) => ({
  ordersSell: ordersGetter(state) ? aggregateList(ordersGetter(state)::filterByOrderSide(OrderSide.SELL)::priceDateSort(true, true), 'price', 'quantity') : undefined
});

export const returnsCalculatorStatusSelector = (state) => ({
  returnsCalculatorStatus: returnsCalculatorStatusGetter(state)
});

export const marketStatusSelector = (state) => ({
  marketStatus: marketStatusGetter(state)
});

export const promotionsSelector = (state) => ({
  promotions: promotionsGetter(state)
});

export const feeFreePromotionStatusSelector = (state) => ({
  feeFreePromotionStatus: feeFreePromotionStatusGetter(state)
});

export const orderBookStatusSelector = (state) => ({
  orderBookStatus: orderBookStatusGetter(state)
});

const myOrdersPendingPreSelector = (state) => ({
  pendingPre: myOrdersGetter(state)::filterByOrderSide(OrderSide.BUY)
});

const myOrdersPendingSellSelector = (state) => ({
  pendingSell: myOrdersGetter(state)::filterByOrderSide(OrderSide.SELL)
});

export const feesSelector = (state) => ({
  fees: state.market.fees,
});

export const myOrdersSelector = (state) => ({
  myOrders: {
    list: myOrdersGetter(state),
    ...myOrdersPendingPreSelector(state),
    ...myOrdersPendingSellSelector(state),
  }
});

export const myTradesSelector = (state) => ({
  myTrades: {
    list: myTradesGetter(state)
  }
});

export const transactionsSelector = (state) => ({
  transactions: {
    list: transactionsGetter(state)
  }
});
