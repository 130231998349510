export const getBrickPriceStats = ({ property }) => {
  const date = property.financials.isIndependentValued
    ? property.financials.latestValuationDate
    : property.platformSettlementDate;
  const isIndependentlyValued = property.financials.isIndependentValued;
  const brickValueType = isIndependentlyValued
    ? 'Latest Brick Valuation'
    : 'Initial Brick Price';
  const value = isIndependentlyValued
    ? property.financials.brickValue
    : property.financials.initialBrickPrice;

  return { brickValueType, date, value };
};

export const isMarketOpenProperty = (property) => {
  if (property.propertyStatus === 'market_open') return true;
  if (property.propertyStatus === 'new') return true;
  return false;
};
