import React, { Component } from 'react';

import { property as propertyPropType } from 'scripts/constants/PropTypes';
import { account as accountPropType } from 'scripts/constants/PropTypes';

import { dollarDecimal } from 'scripts/utilities/formatters';

import InitialBrickPriceBox from 'scripts/components/property/trade/InitialBrickPriceBox';

import Constants from 'scripts/constants/Constants';


export default class TradeInfoBoxPreOrder extends Component {
  static propTypes = {
    property: propertyPropType.isRequired,
    account: accountPropType
  };

  render() {
    const { property, account } = this.props;

    return (
      <div className="pre-order-info-box">
        <InitialBrickPriceBox property={property} shownInLargerText={Constants.TRUE}/>
        <div className="pre-order-info-box__funds-available">
          <div>
            Funds Available
          </div>
          {/* TODO: Replace with number component when NAV changes are merged */}
          <div style={{ fontSize: '25px' }}>
            {
              account && account.availableToTradeBalance::dollarDecimal()
            }
          </div>
        </div>
      </div>
    );
  }
}
