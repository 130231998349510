import PrimaryButton from 'src/design/components/button/PrimaryButton';
import Spacing from 'src/design/styleguide/spacing/Spacing';

const FormSubmitButton = ({ smsfSubmitting, onSubmit, onChange, children }) => (
  <Spacing top="normal">
    <PrimaryButton
      fullWidth
      disabled={smsfSubmitting}
      onClick={e => {
        e.preventDefault();
        onChange({ showErrors: true });
        onSubmit();
      }}
      color={PrimaryButton.colors.SECONDARY}
      testRef="submit-button"
    >{children}</PrimaryButton>
  </Spacing>
);

export default FormSubmitButton;
