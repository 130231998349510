import DocumentMeta from 'react-document-meta';
import React, { Component } from 'react';
import { TERMS_META } from 'scripts/metadata/statics';
import { transactionFeeText } from 'src/settings/text';

export default class FeeFreeEofy2017 extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (

      <div className="container">
        <DocumentMeta {...TERMS_META} />
        <div className="row">
          <div className="col-lg-12 terms-page">
            <h1>EOFY Special Offer: No Fees to Buy Bricks*</h1>

            <p>
              <b>1.</b> This promotion is offered by The Brick Exchange Pty Limited (ABN 27 600 762 749)
              (BrickX) as the manager of the BrickX Platform (ARSN 613 156 433). Theta Asset Management
              Ltd (ABN 37 071 807 684, AFSL 230920) (Theta) is the product issuer for the BrickX Platform,
              but is not responsible for offering or administering this BrickX promotion.
            </p>

            <p>
              <b>2.</b> The transaction fee of {transactionFeeText()} on Brick purchases is being waived and will be fully
              funded by BrickX from its own funds and will not be sourced from the assets of the BrickX
              Platform or any BrickX Trust. The usual {transactionFeeText()} transaction fee will apply to sales of Bricks.
            </p>

            <p>
              <b>3.</b> To be eligible for the transaction fee free purchase you must be or become a
              Member of the BrickX Platform.
            </p>

            <p>
              <b>4.</b> This promotion opens at 00.00am AEST on Thursday 29th June 2017 and closes at
              11:59pm AEST on Friday, 30th June 2017.
            </p>

            <p>
              <b>5.</b> BrickX has not considered the tax implications of the promotion.
            </p>

            <p>
              <b>6.</b> This promotion is not open to BrickX or Theta employees or their immediate
              families.
            </p>

            <p>
              <b>7.</b> All questions relating to this promotion should be directed to BrickX at
              info@brickx.com
            </p>

            <p>
              <b>8.</b> Capitalised terms have the meaning given in the BrickX Platform product disclosure
              statement unless otherwise defined.
            </p>
            <p>
              <b>9.</b> Please note that BPAY payments can take up to 1-2 business days to be received and
              appear as cleared funds in your Digital Wallet. Payments can also be made through POLi to
              provide instant funds (up to $20,000).
            </p>
            <p>
              <b>10.</b> If using POLi to deposit funds, for the period your funds are pending, you are
              not eligible to list your Bricks for sale or make withdrawals. This only applies for POLi
              advanced funds, and these restrictions are lifted after the POLi transfer is received.
            </p>
            <p>
              *Thursday 29th June 2017 and Friday 30th June 2017 only
            </p>
          </div>
        </div>
      </div>
    );
  }
}
