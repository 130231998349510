import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'src/design/components/icon/Icon';
import { getOffsetHeight } from 'scripts/utilities/browser';
import styles from 'src/design/components/expandingInfoBox/ExpandingInfoBox.mscss';

class ExpandingInfoBox extends Component {
  static propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    testRef: PropTypes.string,
  };

  state = {
    expanded: false,
  };

  render() {
    const { children, title, className, testRef } = this.props;
    const { expanded } = this.state;

    return (
      <div
        className={classNames(styles.expandingBox, className)}
        data-test-reference={testRef}
      >
        <div
          className={styles.expandButton}
          onClick={() => this.setState({ expanded: !this.state.expanded })}
          data-test-reference="title-button"
        >
          {title}
          <Icon
            type={Icon.types.chevron_down}
            className={classNames(styles.caret, {
              [styles.flipped]: expanded
            })}
            testRef="chevron-icon"
          />
        </div>
        <div
          className={styles.content}
          style={{
            maxHeight: expanded
              ? `${getOffsetHeight(this.contentInner)}px`
              : '0px',
          }}
          data-test-reference="expandy-content"
        >
          <div
            className={styles.contentInner}
            ref={c => this.contentInner = c}
            data-test-reference="content-inner"
          >{children}</div>
        </div>
      </div>
    );
  }
}

export default ExpandingInfoBox;
