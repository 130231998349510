import _ from 'lodash';
import React from 'react';
import PropertyStatus from 'scripts/constants/PropertyStatus';

export const PropertyCardFlags = {
  [PropertyStatus.NEW]: () => (
    <div
      className="property-card-flag property-card-flag--new-property"
      data-test-reference="new-property-flag"
    >
      <div className="property-card-flag--emphasized-text">NEW</div>
    </div>
  ),
  [PropertyStatus.COMING_SOON]: () => (
    <div
      className="property-card-flag property-card-flag--coming-soon"
      data-test-reference="pre-order-flag"
    >
      <div>COMING</div>
      <div>SOON</div>
    </div>
  ),
  [PropertyStatus.PRE_ORDER]: () => (
    <div
      className="property-card-flag property-card-flag--pre-order"
      data-test-reference="pre-order-flag"
    >
      <div className="property-card-flag--emphasized-text">PRE</div>
      <div>ORDER</div>
    </div>
  ),
  [PropertyStatus.OFF_MARKET]: () => (
    <div className="property-card-flag property-card-flag--grey">
      <div className="property-card-flag--emphasized-text-md">SOLD</div>
    </div>
  ),
};

const PropertyCardFlag = ({ property }) => {
  if (_.includes(_.values(PropertyStatus), property.propertyStatus)) {
    return PropertyCardFlags[property.propertyStatus]();
  }
  return '';
};

export default PropertyCardFlag;
