import { Fragment } from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import {
  accountSelectorV2,
  fetchAccountInfoV2,
} from 'scripts/redux/actions/account';
import { breakpointSmall } from 'src/design/styleguide/common/breakpoints';
import { defineGridArea } from 'src/design/styleguide/common/styledComponentsUtils';
import {
  depositUrl,
  onboardingSmartInvestDetailsUrl,
  settingsEnableUrl,
  transactionsUrl,
  withdrawUrl,
} from 'src/utils/pageUrls';
import { dollarDecimal } from 'src/utils/formats';
import {
  getBuildYourOwnPlan,
  getPlanFunds,
  getSmartInvestPlan,
  planIsActive,
  planWasNeverSignedUpTo,
} from 'src/utils/account';
import { gridUnit } from 'src/design/styleguide/common/measurements';
import { minimumInternalTransfer } from 'src/settings/funds';
import { smartInvestSelector } from 'scripts/redux/actions/smartInvest.js';
import { topUp as topUpAction } from 'scripts/redux/actions/smartInvest';
import { withdrawFromSi as withdrawFromSiAction } from 'scripts/redux/actions/smartInvest';
import ClonedChild from 'src/design/styleguide/common/ClonedChild';
import Divider from 'src/design/components/divider/Divider';
import DollarInput from 'src/design/components/textInput/DollarInput';
import Hyperlink from 'src/design/components/hyperlink/Hyperlink';
import InputError from 'src/design/components/inputError/InputError';
import Loading from 'src/components/loading/Loading';
import PageLayout from 'src/components/dashboard/common/PageLayout';
import Paragraph from 'src/design/components/bodyText/Paragraph';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import SecondaryButton from 'src/design/components/button/SecondaryButton';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import Styledlink from 'src/design/components/hyperlink/Styledlink';
import Text from 'src/design/styleguide/text/Text';
import TotalValueContainer from 'src/components/dashboard/portfolio/TotalValueContainer';
import WidgetPanel from 'src/components/dashboard/common/WidgetPanel';
import withFetching from 'src/decorators/withFetching';
import withState from 'src/decorators/withState';
import { userSelector } from 'scripts/redux/actions/user';
import {
  getCustomButtonColor,
  getCustomThemeColor,
} from '../home/DashboardHome';
import { Box, Stack, Typography } from '@mui/material';
import Colors from 'src/themes/brickx/colors';

const maxButtonWidth = 350;

const PlanFundsContainer = styled.div`
  text-align: center;

  ${breakpointSmall(`
    text-align: left;
  `)}
`;

const PlanFunds = ({ className, title, value, valueTestRef }) => (
  <PlanFundsContainer className={className}>
    <Text as="p" fontSize="2x-small">
      Funds available for <strong>{title}</strong>
    </Text>
    <Text as="p" fontWeight="bold" fontSize="small" testRef={valueTestRef}>
      {dollarDecimal(value)}
    </Text>
  </PlanFundsContainer>
);

const CloserToTopDivider = styled(Divider).attrs({
  top: 'normal',
  bottom: { base: 'x-large', breakpointSmall: 'normal' },
})``;

const PlanDivider = styled(CloserToTopDivider)`
  ${breakpointSmall(`display: none;`)}
`;

const BmoButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  ${breakpointSmall(`
    align-items: flex-end;
  `)}
`;

const ActionButton = styled(ClonedChild)`
  max-width: ${maxButtonWidth}px;

  && {
    ${breakpointSmall(`
      max-width: 240px;
    `)}
  }
`;

const DepositFundsButton = ({ className, color }) => (
  <ActionButton>
    <PrimaryButton
      className={className}
      link={depositUrl()}
      color={color}
      fullWidth
      testRef="deposit-button"
    >
      Deposit Funds
    </PrimaryButton>
  </ActionButton>
);

const WithdrawFundsButton = ({ className }) => (
  <ActionButton>
    <SecondaryButton
      className={className}
      link={withdrawUrl()}
      color={PrimaryButton.colors.PRIMARY}
      fullWidth
      testRef="withdraw-button"
    >
      Withdraw Funds
    </SecondaryButton>
  </ActionButton>
);

const BmoButtons = ({ isSmartInvestActive, color }) => (
  <Spacing
    top={{
      base: 'x-large',
      breakpointSmall: isSmartInvestActive ? '5x-large' : 'none',
    }}
  >
    <BmoButtonsContainer className="bmo-buttons">
      <DepositFundsButton color={color} />
      <Spacing top="x-small">
        <WithdrawFundsButton />
      </Spacing>
    </BmoButtonsContainer>
  </Spacing>
);

const BmoFunds = ({ value }) => (
  <Spacing top={{ breakpointSmall: '5x-large' }}>
    <div className="bmo-funds">
      <PlanDivider />
      <PlanFunds title="Build My Own" value={value} valueTestRef="bmo-funds" />
    </div>
  </Spacing>
);

const TransferAvailable = connect(smartInvestSelector, { topUp: topUpAction })(
  withState({
    mapPropsToInitialState: () => ({ transferAmount: '' }),
    Component: ({
      availableFunds,
      topUp,
      closeTopupMenu,
      smartInvest: { transferring },
      state: { transferAmount },
      setState,
    }) => {
      const noAmountEntered = transferAmount === '';
      const notEnoughFunds = transferAmount > availableFunds;
      const belowMinimumAmount = transferAmount < minimumInternalTransfer;
      const transferButtonDisabled =
        notEnoughFunds || noAmountEntered || belowMinimumAmount || transferring;
      const errorMessage = (() => {
        if (noAmountEntered) return '';
        if (belowMinimumAmount) {
          return `Minimum amount that can be transferred is ${dollarDecimal(
            minimumInternalTransfer
          )}.`;
        }
        if (notEnoughFunds) {
          return 'Please deposit more funds before transferring.';
        }
        return '';
      })();

      return (
        <div>
          <Paragraph>
            Enter the amount you would like to transfer to Smart Invest.
          </Paragraph>

          <Spacing top="normal">
            <DollarInput
              value={transferAmount}
              onChange={(value) => setState({ transferAmount: value })}
              isStringValid={DollarInput.validation.numbersAndDecimalOnly}
              error={!!errorMessage}
              noDecimalSuffix
              testRef="top-up-transfer-amount-input"
            />
          </Spacing>
          {errorMessage && (
            <InputError
              error={errorMessage}
              errorTestRef="top-up-transfer-amount-error"
            />
          )}

          <Spacing top="normal">
            <PrimaryButton
              color={PrimaryButton.colors.SECONDARY}
              onClick={() => topUp(transferAmount).then(closeTopupMenu)}
              disabled={transferButtonDisabled}
              fullWidth
              testRef="top-up-transfer-button"
            >
              Transfer
            </PrimaryButton>
          </Spacing>

          <Spacing top="x-small">
            <SecondaryButton
              color={PrimaryButton.colors.PRIMARY}
              onClick={closeTopupMenu}
              fullWidth
              testRef="top-up-close-button"
            >
              Cancel
            </SecondaryButton>
          </Spacing>
        </div>
      );
    },
  })
);

const TransferUnavailableParagraph = styled(Paragraph)`
  text-align: center;

  ${breakpointSmall(`
    text-align: left;
  `)}
`;

const TransferUnavailable = ({ closeTopupMenu }) => (
  <Fragment>
    <TransferUnavailableParagraph>
      It looks like you have no funds in your wallet. Please{' '}
      <Styledlink href={depositUrl()} testRef="top-up-deposit-link">
        deposit funds
      </Styledlink>{' '}
      into your wallet before transferring to Smart Invest.
    </TransferUnavailableParagraph>
    <Spacing top="normal">
      <SecondaryButton
        color={PrimaryButton.colors.PRIMARY}
        onClick={closeTopupMenu}
        fullWidth
        testRef="top-up-close-button"
      >
        Ok
      </SecondaryButton>
    </Spacing>
  </Fragment>
);

const TopupMenuContainer = styled.div`
  max-width: ${maxButtonWidth}px;
`;

const TopupMenu = ({ className, closeTopupMenu, availableFunds }) => (
  <TopupMenuContainer className={className}>
    {availableFunds ? (
      <TransferAvailable
        closeTopupMenu={closeTopupMenu}
        availableFunds={availableFunds}
      />
    ) : (
      <TransferUnavailable closeTopupMenu={closeTopupMenu} />
    )}
  </TopupMenuContainer>
);

const SiTopupContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  ${breakpointSmall(`
    display: flex;
    justify-content: flex-end;
  `)}
`;

const TransferButtonBr = styled.br`
  display: none;
  ${breakpointSmall(`display: inline;`)}
`;

const SiTopup = withState({
  mapPropsToInitialState: () => ({ topUpMenuOpen: false }),
  Component: ({ className, bmoFunds, setState, state: { topUpMenuOpen } }) => {
    const openTopupMenu = () => setState({ topUpMenuOpen: true });
    const closeTopupMenu = () => setState({ topUpMenuOpen: false });

    return (
      <SiTopupContainer className={className}>
        {topUpMenuOpen ? (
          <TopupMenu
            availableFunds={bmoFunds}
            closeTopupMenu={closeTopupMenu}
          />
        ) : (
          <ActionButton>
            <SecondaryButton
              onClick={openTopupMenu}
              color={SecondaryButton.colors.PRIMARY}
              fullWidth
              testRef="top-up-button"
            >
              Transfer Funds from
              <TransferButtonBr /> Build My Own
            </SecondaryButton>
          </ActionButton>
        )}
      </SiTopupContainer>
    );
  },
});

const EnableSmartInvestLink = ({ className, hasPreviouslyEnabled }) => (
  <Paragraph className={className} align="center">
    <Hyperlink
      className="text-underline"
      href={
        hasPreviouslyEnabled
          ? settingsEnableUrl()
          : onboardingSmartInvestDetailsUrl()
      }
      testRef="enable-smart-invest-link"
    >
      Enable smart invest
    </Hyperlink>
  </Paragraph>
);

const SiSection = ({ siFunds, bmoFunds }) => (
  <Fragment>
    <PlanFunds
      className="si-funds"
      title="Smart Invest"
      value={siFunds}
      valueTestRef="si-funds"
    />
    <Spacing top={{ base: 'x-large', breakpointSmall: 'none' }}>
      <SiTopup className="si-topup" bmoFunds={bmoFunds} />
    </Spacing>
    <PlanDivider />
  </Fragment>
);

const WidgetPanelWithLayout = styled(WidgetPanel)`
  ${breakpointSmall(css`
    display: grid;
    grid-column-gap: ${gridUnit * 6}px;
    ${defineGridArea('total-funds')}
    ${defineGridArea('bmo-funds')}
    ${defineGridArea('bmo-buttons')}
    ${defineGridArea('si-funds')}
    ${defineGridArea('si-topup')}
    ${defineGridArea('divider')}
    ${defineGridArea('enable-si-link')}
    .bmo-buttons {
      justify-self: end;
    }
    .si-topup {
      justify-self: end;
    }
    .enable-si-link {
      justify-self: center;
    }

    grid-template-areas:
      'total-funds bmo-buttons'
      'si-withdraw si-withdraw'
      'divider divider'
      'enable-si-link enable-si-link';

    ${(props) =>
      props.isSmartInvestActive &&
      `
      grid-template-areas:
        'total-funds total-funds'
        'bmo-funds bmo-buttons'
        'divider divider'
        'si-funds si-topup';
    `}
  `)}
`;

const SIWalletWithdraw = connect(null, {
  withdrawFromSi: withdrawFromSiAction,
})(({ withdrawFromSi, siFunds }) => {
  return (
    <Stack
      borderRadius={2}
      my={2}
      p={2}
      bgcolor={Colors.grey100}
      gridArea={'si-withdraw'}
      spacing={2}
    >
      <Box>
        <Typography fontSize={20}>
          You have <strong>{dollarDecimal(siFunds)}</strong> in the SI wallet.
        </Typography>
        <Typography>
          This might due to recent distributions payment made into your SI
          wallet, <Styledlink href={transactionsUrl()}>Click here</Styledlink>{' '}
          to review your latest trasactions. You need to transfer them into your
          build-your-own wallet if you want to withdraw.
        </Typography>
      </Box>
      <PrimaryButton
        fullWidth
        color={PrimaryButton.colors.SECONDARY}
        onClick={() => withdrawFromSi()}
      >
        Transfer to Build-Your-Own Wallet
      </PrimaryButton>
    </Stack>
  );
});

const Wallet = withFetching(
  [accountSelectorV2, userSelector],
  [fetchAccountInfoV2],
  'Wallet'
)(({ account, user }) => {
  if (!account) return <Loading />;

  const buildYourOwnPlan = getBuildYourOwnPlan(account);
  const smartInvestPlan = getSmartInvestPlan(account);
  const bmoFunds = getPlanFunds(buildYourOwnPlan);
  const siFunds = getPlanFunds(smartInvestPlan);
  const totalFunds = dollarDecimal(bmoFunds + siFunds);
  const isSmartInvestActive = planIsActive(smartInvestPlan);

  const showSIWithdraw = !isSmartInvestActive && siFunds > 0;

  return (
    <PageLayout>
      <WidgetPanelWithLayout
        title="My Wallet"
        hideHeadingOnMobile
        isSmartInvestActive={isSmartInvestActive}
      >
        <TotalValueContainer
          highlightColor={getCustomThemeColor(user)}
          className="total-funds"
        >
          <Text as="p" fontWeight="regular" fontSize="4x-small">
            Total Funds
          </Text>

          <Text
            as="p"
            fontWeight="bold"
            fontSize="normal"
            testRef="total-funds"
          >
            {dollarDecimal(totalFunds)}
          </Text>
        </TotalValueContainer>

        {isSmartInvestActive && <BmoFunds value={bmoFunds} />}
        <BmoButtons
          color={getCustomButtonColor(user)}
          isSmartInvestActive={isSmartInvestActive}
        />

        {isSmartInvestActive ? (
          <CloserToTopDivider className="divider" />
        ) : (
          <Divider className="divider" />
        )}

        {isSmartInvestActive ? (
          <SiSection siFunds={siFunds} bmoFunds={bmoFunds} />
        ) : (
          <EnableSmartInvestLink
            className="enable-si-link"
            hasPreviouslyEnabled={!planWasNeverSignedUpTo(smartInvestPlan)}
          />
        )}
        {showSIWithdraw && (
          <SIWalletWithdraw testRef="si-wallet-withdraw" siFunds={siFunds} />
        )}
      </WidgetPanelWithLayout>
    </PageLayout>
  );
});

export default Wallet;
