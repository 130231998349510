import classNames from 'classnames';
import styles from 'src/design/components/inputError/InputError.mscss';


const InputError = ({ className, error, errorTestRef }) => (
  <div
    className={classNames(styles.errorText, className)}
    data-test-reference={errorTestRef}
  >
    {error}
  </div>
);

export default InputError;
