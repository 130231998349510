const InfoBox = ({ children, className }) => {
  return (
    <div className={`settings-blue-notification ${className}`}>
      {/* <div className="row"> */}
        <div className="col-info-icon">
          <span className="icn icn-info" />
        </div>
        <div className="col-info-description">
          <div className="inner">
            { children }
          </div>
        </div>
      {/* </div> */}
    </div>
  );
};


export default InfoBox;
