// TODO: move these to their specific domains
export default {
  TRUE: true,
  FALSE: false,
  MAX_DAYS: 31,
  MAX_MONTH: 12,
  NUMBER_DAYS_PER_WEEK: 7,
  MAX_AGE: 110,
  MIN_AGE: 17,
  NON_SO_FAR: 'None so far.',
  CASHFLOW_POSITIVE: 'Cashflow positive',
  CURRENTLY_TENANTED: 'Currently tenanted',
  ANNUALISED: 'Annualised',
  FORECASTED: 'Forecasted',
  PER_YEAR: 'Per year',
  CAR_NUMBER: '001000043'
};
