import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DynamicNumber from 'react-dynamic-number';

import {
  property as propertyPropType,
  account as accountPropType,
  user as userPropType,
  tradeProposal as tradeProposalPropType,
  purchaseProposalForm as purchaseProposalFormPropType,
  averageAnnualGrowthMetrics as averageAnnualGrowthMetricsPropType,
} from 'scripts/constants/PropTypes';
import {
  tradeProposalSelector,
  feesSelector,
} from 'scripts/redux/selectors/market';
import { userSelector } from 'scripts/redux/selectors/user';
import {
  fetchPurchaseProposal,
  confirmPurchase,
  purchaseDone,
  clearOrders as clearOrdersAction,
  fetchFees,
} from 'scripts/redux/actions/market';

import { sendDepositLinkClick } from 'scripts/redux/actions/segment/events/depositEvents';
import {
  sendBuyProposalEngagementEvent,
  sendBuyProposalSubmitEvent,
  sendSecondPremiumAlertEvent,
} from 'scripts/redux/actions/segment/events/tradeEvents';
import SegmentEventTypes from 'scripts/redux/actions/segment/SegmentEventTypes';
import { averageAnnualGrowthMetricsSelector } from 'scripts/redux/selectors/historicalGrowth';
import SoldOut from 'scripts/components/property/trade/SoldOut';
import DepositLink from 'scripts/components/shared/DepositLink';
import Modal from 'scripts/components/helpers/Modal';
import Trade from 'scripts/constants/Trade';
import Constants from 'scripts/constants/Constants';
import Numbers from 'scripts/constants/Numbers';
import { years as historicalGrowthYears } from 'scripts/constants/HistoricalGrowth';
import {
  brickPrice,
  dollarDecimal,
  percentDecimal,
  brick,
  percent,
  monthYear,
} from 'scripts/utilities/formatters';
import {
  getPriceDifferencePercentage,
  shouldSecondPremiumAlertBeTriggered,
  getBrickValuation,
  getNumOfYearsToRecover,
  getHistoricalGrowthMetricsForProperty,
} from 'scripts/utilities/propertyHelper';
import { validateTradeProposalQuantity } from 'scripts/utilities/tradeHelper';
import { checkUserWithTradePermission } from 'scripts/utilities/userAccountHelper';
import _ from 'lodash';
import { PROPERTIES_WITH_MAX_BUY_LIMIT_MAPPING } from 'src/settings/trading';
import FeePromoBanner from 'src/components/promos/Aug2022FeePromo/FeePromoBanner';

const isMaxNumOfBricksExempted = (propertyCode) =>
  !(propertyCode in PROPERTIES_WITH_MAX_BUY_LIMIT_MAPPING);
const maxNumOfBricksPerProperty = (propertyCode) =>
  PROPERTIES_WITH_MAX_BUY_LIMIT_MAPPING[propertyCode] || 10000;
const maxNumOfBricksPerPropertyRatio = (propertyCode) =>
  `${(PROPERTIES_WITH_MAX_BUY_LIMIT_MAPPING[propertyCode] || 10000) / 100}%`;
const timeOutInterval = Numbers.TWO_HUNDREDS;

const INTEGERS = Numbers.TEN;
const FRACTION = Numbers.ZERO;

const mapStateToProps = (state) => ({
  ...userSelector(state),
  ...tradeProposalSelector(state),
  ...averageAnnualGrowthMetricsSelector(state),
  ...feesSelector(state),
});

@connect(mapStateToProps, {
  fetchFees,
  fetchPurchaseProposal,
  confirmPurchase,
  purchaseDone,
  clearOrders: clearOrdersAction,
})
export default class TradeProposalFormBuy extends Component {
  static propTypes = {
    user: userPropType.isRequired,
    account: accountPropType,
    property: propertyPropType.isRequired,
    tradeProposal: tradeProposalPropType,
    isFeeFreePromotionEnabled: PropTypes.bool.isRequired,
    fetchPurchaseProposal: PropTypes.func.isRequired,
    clearOrders: PropTypes.func,
    confirmPurchase: PropTypes.func.isRequired,
    purchaseDone: PropTypes.func.isRequired,
    redirectTo: PropTypes.func.isRequired,
    proposalForm: purchaseProposalFormPropType.isRequired,
    totalBricksOwned: PropTypes.number.isRequired,
    numOfBricksAvailable: PropTypes.number,
    averageAnnualGrowthMetrics: averageAnnualGrowthMetricsPropType,
    fetchFees: PropTypes.func,
    fees: PropTypes.shape({
      feeFreeEnabled: PropTypes.bool,
      buyFeeFreeEnabled: PropTypes.bool,
      sellFeeFreeEnabled: PropTypes.bool,
      buyFee: PropTypes.number,
      preOrderFee: PropTypes.number,
      sellFee: PropTypes.number,
    }),
  };

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  state = {
    hasSufficientFund: true,
    loading: false,
    buyProposalParams: {
      quantity: '',
      hasError: false,
      errorMsg: '',
    },
    terms: {
      accepted: false,
      showErrorMessage: false,
    },
    checkboxHighPriceOn: false,
    checkboxHighPriceError: false,
  };

  componentDidMount() {
    this._fetchPurchaseProposal = _.debounce(
      this._fetchPurchaseProposal,
      timeOutInterval
    );
    const {
      fetchPurchaseProposal,
      property,
      proposalForm,
      clearOrders,
      fetchFees,
    } = this.props;

    fetchFees();
    fetchPurchaseProposal(property.propertyCode, 0);
    clearOrders();
    this._handleQuantityChange(proposalForm.quantity);
    this._buildBuyProposalParams(proposalForm);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.numOfBricksAvailable &&
      nextProps.numOfBricksAvailable !== this.props.numOfBricksAvailable
    ) {
      this._handleQuantityChange(
        this.state.buyProposalParams.quantity,
        nextProps.numOfBricksAvailable
      );
    }
  }

  componentDidUpdate() {
    const { property, tradeProposal, purchaseDone, redirectTo } = this.props;
    if (tradeProposal && tradeProposal.type === Trade.CONFIRMED) {
      redirectTo(`/properties/${property.propertyCode}/purchase-success`);
      purchaseDone(
        Trade.TRADE_TYPE.BUY,
        tradeProposal.data,
        this.state.buyProposalParams.quantity
      );
    }
  }

  render() {
    const {
      account,
      property,
      tradeProposal,
      proposalForm,
      averageAnnualGrowthMetrics,
      fees,
    } = this.props;
    const { hasSufficientFund, loading } = this.state;

    const isBuyFeeFreePromoEnabled = fees && fees.buyFeeFreeEnabled;

    var tradeProposalData = {
      proposedBuyOrders: [],
      propertyCode: property.propertyCode,
      quantity: proposalForm.quantity,
      quantityWillBeTransacted: proposalForm.quantity,
      basisPrice: 0,
      valueOfBricks: 0,
      transactionFee: 0,
      total: 0,
    };
    if (
      tradeProposal &&
      tradeProposal.data &&
      tradeProposal.data.propertyCode === property.propertyCode
    ) {
      tradeProposalData = tradeProposal.data;
    }

    let averageAnnualGrowthFor20yr = '--';
    if (averageAnnualGrowthMetrics) {
      averageAnnualGrowthFor20yr = _.get(
        getHistoricalGrowthMetricsForProperty(
          averageAnnualGrowthMetrics,
          property
        ),
        [historicalGrowthYears.TWENTY]
      );
    }
    const hasBeenSoldOut = this._hasBricksSoldOut();

    const priceDifferencePercentage = getPriceDifferencePercentage(property);
    const { brickValuation } = getBrickValuation(property);
    const yearsToRecover = getNumOfYearsToRecover(
      property,
      averageAnnualGrowthFor20yr
    );
    const submitButtonDisabled =
      !hasSufficientFund || hasBeenSoldOut || loading || proposalForm.hasError;

    return (
      <div className="place-order-panel">
        <div className="row">
          <div className="col-md-12">
            <div className="hidden-xs">
              <div className="col-md-6 title col-no-padding-left">
                Place your order
              </div>
              <div className="col-md-6 funds-available col-no-padding-right">
                Funds available:{' '}
                {account
                  ? account.availableToTradeBalance::dollarDecimal()
                  : '$__.00'}
              </div>
            </div>
          </div>
        </div>

        {isBuyFeeFreePromoEnabled && <FeePromoBanner />}

        <div className="white-boxes-container">
          <div className="col-inline col-inline-3 col-no-padding-left">
            <div className="white-box quantity">
              Quantity
              <DynamicNumber
                id="id_buy_quantity"
                className="form-control quantity-to-be-purchased"
                name="buy_quantity"
                value={this.state.buyProposalParams.quantity}
                integer={INTEGERS}
                fraction={FRACTION}
                positive={Constants.TRUE}
                thousand={Constants.FALSE}
                placeholder="0"
                onChange={this._onQuantityChange}
              />
            </div>
          </div>
          <div className="col-inline col-inline-3 hidden-xs">
            <div className="white-box">
              <div>Av. Brick Price</div>
              <div className="value basis-price">
                {tradeProposalData.basisPrice::brickPrice()}
              </div>
            </div>
          </div>
          <div className="col-inline col-inline-4 col-no-padding-right hidden-xs">
            <div className="white-box">
              <div>Order Total</div>
              <div className="value purchase-proposal-total">
                {tradeProposalData.total::brickPrice()}
              </div>
            </div>
          </div>
        </div>

        {hasBeenSoldOut && (
          <div className="row">
            <SoldOut
              propertyCode={property.propertyCode}
              isPreOrderProperty={false}
            />
          </div>
        )}

        {proposalForm.hasError && (
          <div className="row">
            <div className="col-xs-12 error error-proposal">
              {proposalForm.errorMsg}
            </div>
          </div>
        )}

        {tradeProposalData.proposedBuyOrders &&
        tradeProposalData.proposedBuyOrders.length > 0 ? (
          <div className="brick-price-summary">
            {tradeProposalData.proposedBuyOrders.map(
              (proposedBuyOrder, index) => {
                return (
                  <div className="row table-row" key={index}>
                    <div className="col-xs-7 col-no-padding-right col-left">
                      <i aria-hidden="true"></i>
                      {`${proposedBuyOrder.quantity::brick()} @ ${proposedBuyOrder.price::brickPrice()}`}
                    </div>
                    <div
                      className={`col-xs-5 col-no-padding-left col-right order-total-price-${index}`}
                    >
                      {proposedBuyOrder.totalPrice::dollarDecimal()}
                    </div>
                  </div>
                );
              }
            )}
          </div>
        ) : (
          <div></div>
        )}

        <div className="gray-line"></div>

        <div className="row table-row">
          <div className="col-xs-7 col-no-padding-right col-left">
            <i aria-hidden="true"></i>Cost of Bricks
          </div>
          <div className="col-xs-5 col-no-padding-left col-right value-of-bricks">
            {tradeProposalData.valueOfBricks::brickPrice()}
          </div>
        </div>

        <div className="gray-line"></div>

        <div className="row table-row">
          <div className="col-xs-7 col-no-padding-right col-left">
            <i aria-hidden="true"></i>Transaction fee{' '}
            <br className="visible-xs" />
            <span data-test-reference="transaction-fee">
              {tradeProposalData.transactionFeeRatio::percentDecimal()}
            </span>
          </div>
          <div className="col-xs-5 col-no-padding-left col-right transaction-fee">
            {tradeProposalData.transactionFee::brickPrice()}
          </div>
        </div>

        <div className="gray-line"></div>

        <div className="row table-row">
          <div className="col-xs-7 col-no-padding-right col-left">
            <i aria-hidden="true"></i>
            Order Total
          </div>
          <div className="col-xs-5 col-no-padding-left col-right purchase-proposal-total">
            {tradeProposalData.total::brickPrice()}
          </div>
        </div>

        <div className="gray-line"></div>
        <br />

        <div className="action-buttons">
          <div>
            <div>
              <input
                type="checkbox"
                checked={this.state.terms.accepted}
                id="consent"
                required
                onChange={this._onAcceptTermsChange}
              />
              <label htmlFor="consent">
                &nbsp;I confirm that I have accessed, read and understood the{' '}
                <a href="/pds" target="_blank">
                  Product Disclosure Statement, Financial Services Guide, Target
                  Market Determination and Additional Disclosure Document
                </a>{' '}
                and agree to this transaction
              </label>
              &nbsp;
            </div>
            {this.state.terms.showErrorMessage && (
              <span className="text-danger accept-terms-warning">
                Check this box before confirming trade.
              </span>
            )}
          </div>
          <div>
            <button
              className="button orange-button confirm-purchase-button action-full-button right-arrow-button"
              onClick={::this._confirmPurchase}
              disabled={submitButtonDisabled}
              data-test-reference="confirm-button"
            >
              CONFIRM PURCHASE
            </button>
          </div>
          {!hasSufficientFund && (
            <div className="error error-insufficient-funds">
              You have insufficient funds.&nbsp;
              <DepositLink
                id="deposit-link-purchase-panel"
                onClick={this._onDepositLinkClick}
              />
            </div>
          )}
        </div>

        <Modal
          ref={(el) => (this.aboveValuationSecondAlertModalRef = el)}
          title={
            <span className="text-orange">
              <span className="fa fa-exclamation-triangle" /> ATTENTION: Above
              Valuation Alert
            </span>
          }
          id="premium-price-second-alert-modal"
          sizeClass="modal-md"
          onClose={this._sendCancelledAboveValuationEvent}
          footer={
            <div>
              <button
                className="button cancel-button right-arrow-button"
                onClick={() => {
                  this.aboveValuationSecondAlertModalRef.close();
                  this._sendCancelledAboveValuationEvent();
                }}
              >
                <span>CANCEL</span>
              </button>
              <button
                className="button trade-button sell-button red-button confirm-high-price right-arrow-button"
                onClick={this._onHighPriceConfirmClick}
              >
                PROCEED WITH PURCHASE
              </button>
              <div>
                <br />
              </div>
              <div className="text-left">
                <small>
                  * Calculation excludes: amortisation of acquisition costs and
                  cash reserve. <br />
                  20 year Historical Suburb Growth is CoreLogic SA2 data. Past
                  performance is not an indicator of future performance.
                </small>
              </div>
            </div>
          }
          body={
            <div>
              <p>Please read the following.</p>
              <p>
                You are buying{' '}
                {Number.parseInt(proposalForm.quantity, 10)::brick()}, at an
                average price of{' '}
                <span className="text-orange">
                  {property.financials.lowestAvailableBrickPrice::brickPrice()}
                </span>
                . At the last valuation the Brick was valued at{' '}
                <span className="text-orange">
                  {brickValuation::brickPrice()}
                </span>{' '}
                ({property.financials.latestValuationDate::monthYear()}). The
                next valuation is due {property.nextValuationDate::monthYear()}.
              </p>
              <p>
                You are{' '}
                <span className="text-orange">
                  paying {priceDifferencePercentage::percent()} more
                </span>{' '}
                than the latest Brick Valuation (
                {property.financials.latestValuationDate::monthYear()})
              </p>
              <p>
                If the underlying property were to continue to appreciate in
                value at its Historical Suburb Growth rate of{' '}
                {averageAnnualGrowthFor20yr::percentDecimal()}, it would take{' '}
                {yearsToRecover} years for the Brick to be valued at the{' '}
                {property.financials.lowestAvailableBrickPrice::brickPrice()}.*
              </p>
              How would you like to proceed: <br />
              <div className="left-side-checkbox">
                <input
                  id="high-price-checkbox"
                  type="checkbox"
                  onClick={this._onHighPriceCheckboxChange}
                />
                <label htmlFor="high-price-checkbox">
                  I agree to{' '}
                  <span className="text-orange">
                    paying {priceDifferencePercentage::percent()} more
                  </span>{' '}
                  than the latest Brick Valuation (
                  {property.financials.latestValuationDate::monthYear()}) on
                  this transaction
                </label>
                {this.state.checkboxHighPriceError && (
                  <div className="error">Please agree to purchase</div>
                )}
              </div>
            </div>
          }
        />
      </div>
    );
  }

  _onHighPriceCheckboxChange = () => {
    this.setState({
      checkboxHighPriceOn: !this.state.checkboxHighPriceOn,
      checkboxHighPriceError: this.state.checkboxHighPriceOn,
    });
  };

  _onHighPriceConfirmClick = () => {
    if (this.state.checkboxHighPriceOn) {
      this.aboveValuationSecondAlertModalRef.close();
      this._sendProceedAboveValuationEvent();
      this._confirmPurchase(null, true);
      this.setState({
        checkboxHighPriceError: false,
      });
    } else {
      this.setState({
        checkboxHighPriceError: true,
      });
    }
  };

  _onDepositLinkClick = () => {
    sendDepositLinkClick({ from: 'Trade Proposal Panel' });
  };

  _onAcceptTermsChange = () => {
    this.setState({
      terms: { accepted: !this.state.terms.accepted, showErrorMessage: false },
    });
  };

  _onQuantityChange = (event) => {
    var updatedQuantity = event.target.value;
    this._handleQuantityChange(updatedQuantity);
  };

  _handleQuantityChange = (
    quantity,
    numOfBricksAvailable = this.props.numOfBricksAvailable
  ) => {
    if (this._validateQuantity(quantity, numOfBricksAvailable)) {
      this._fetchPurchaseProposal(quantity);
    }
  };

  _validateQuantity = (quantity, numOfBricksAvailable) => {
    console.log(quantity, numOfBricksAvailable);
    var isValid = false;

    const { proposalForm, totalBricksOwned } = this.props;
    var updatedProposalForm = proposalForm;

    if (validateTradeProposalQuantity(quantity)) {
      const updatedQuantityInt = Number.parseInt(quantity, 10);
      console.log(updatedQuantityInt);
      const hasBeenSoldOut = this._hasBricksSoldOut();
      const maxNumOfBricksCanBuy =
        this._calculateMaxNumOfBricksCanBuy(totalBricksOwned);

      if (isMaxNumOfBricksExempted(this.props.property.propertyCode)) {
        updatedProposalForm.hasError = false;
        updatedProposalForm.errorMsg = '';
        isValid = true;
      } else {
        if (maxNumOfBricksCanBuy === 0) {
          updatedProposalForm.hasError = true;
          updatedProposalForm.errorMsg = `You have reached the maximum number of Bricks you can buy in this property (${maxNumOfBricksPerProperty(
            this.props.property.propertyCode
          )}).`;
        } else if (
          updatedQuantityInt > numOfBricksAvailable &&
          !hasBeenSoldOut
        ) {
          updatedProposalForm.hasError = true;
          updatedProposalForm.errorMsg = `There ${
            numOfBricksAvailable === 1 ? 'is' : 'are'
          } only ${numOfBricksAvailable::brick()} available. Please enter ${numOfBricksAvailable::brick()} or less to continue your transaction.`;
        } else if (updatedQuantityInt > maxNumOfBricksCanBuy) {
          updatedProposalForm.hasError = true;
          updatedProposalForm.errorMsg = `You already own ${totalBricksOwned::brick()} in this property, and can only buy ${maxNumOfBricksPerProperty(
            this.props.property.propertyCode
          )} more to reach the maximum of ${maxNumOfBricksPerPropertyRatio(
            this.props.property.propertyCode
          )}, or ${maxNumOfBricksPerProperty(
            this.props.property.propertyCode
          )} Bricks per property. Please enter ${maxNumOfBricksPerProperty(
            this.props.property.propertyCode
          )} or less in Quantity of Bricks to continue your transaction.`;
        } else {
          updatedProposalForm.hasError = false;
          updatedProposalForm.errorMsg = '';
          isValid = true;
        }
      }
      updatedProposalForm.quantity = updatedQuantityInt;
    } else {
      updatedProposalForm.hasError = true;
      updatedProposalForm.errorMsg = `How many Bricks would you like to buy? Enter a Quantity to continue your transaction.`;
      updatedProposalForm.quantity = quantity;
    }
    this._updateForm(updatedProposalForm);

    return isValid;
  };

  _validateSufficientFunds = () => {
    const { account, tradeProposal } = this.props;
    const hasSufficientFund =
      tradeProposal.data && tradeProposal.data.total
        ? account.availableToTradeBalance >= tradeProposal.data.total
        : true;
    this.setState({ hasSufficientFund });
    return hasSufficientFund;
  };

  _isFormValid = () => {
    if (!this.state.terms.accepted) {
      this.setState({ terms: { showErrorMessage: true } });
    }

    const isValid =
      this.state.terms.accepted &&
      this.state.hasSufficientFund &&
      !this.state.buyProposalParams.hasError;
    return isValid;
  };

  async _fetchPurchaseProposal(quantity) {
    const quantityIntValue = Number.parseInt(quantity, 10);

    const { property, fetchPurchaseProposal, account, totalBricksOwned } =
      this.props;

    await fetchPurchaseProposal(property.propertyCode, quantityIntValue);

    this._validateSufficientFunds();

    const { tradeProposal } = this.props;
    sendBuyProposalEngagementEvent({
      tradeProposalData: tradeProposal.data,
      account: account,
      totalBricksOwned: totalBricksOwned,
    });
  }

  async _confirmPurchase(e) {
    if (e) e.preventDefault();

    const {
      user,
      property,
      tradeProposal,
      confirmPurchase,
      account,
      totalBricksOwned,
    } = this.props;
    if (checkUserWithTradePermission(user, this.context.router)) {
      if (this._isFormValid()) {
        if (
          shouldSecondPremiumAlertBeTriggered(property) &&
          !this.state.checkboxHighPriceOn
        ) {
          this.aboveValuationSecondAlertModalRef.open();
        } else {
          this.setState({ loading: true });
          await confirmPurchase(tradeProposal.data, false);
          sendBuyProposalSubmitEvent({
            tradeProposalData: tradeProposal.data,
            account: account,
            totalBricksOwned:
              totalBricksOwned + tradeProposal.quantityWillBeTransacted,
          });
          this.setState({ loading: false });
        }
      }
    }
  }

  _calculateMaxNumOfBricksCanBuy = (totalBricksOwned) => {
    return (
      maxNumOfBricksPerProperty(this.props.property.propertyCode) -
      totalBricksOwned
    );
  };

  _hasBricksSoldOut = () => {
    return this.props.numOfBricksAvailable <= Numbers.ZERO;
  };

  _buildBuyProposalParams = (updatedProposalForm) => {
    this.setState({
      buyProposalParams: {
        quantity: updatedProposalForm.quantity,
        hasError: updatedProposalForm.hasError,
        errorMsg: updatedProposalForm.errorMsg,
      },
    });
  };

  _updateForm = (updatedProposalForm) => {
    this._buildBuyProposalParams(updatedProposalForm);
    updatedProposalForm.callback(updatedProposalForm);
  };

  _sendProceedAboveValuationEvent = () => {
    sendSecondPremiumAlertEvent(
      SegmentEventTypes.TRADE.PREMIUM_PRICE_ALERTS_ENGAGEMENT.PROCEED
    );
  };

  _sendCancelledAboveValuationEvent = () => {
    sendSecondPremiumAlertEvent(
      SegmentEventTypes.TRADE.PREMIUM_PRICE_ALERTS_ENGAGEMENT.CANCELLED
    );
  };
}
