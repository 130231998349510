import { Heading } from 'src/design/components/heading/Headings';
import { isMemberFromCookie } from 'src/utils/user';
import { scrollToTop } from 'src/browser/window';
import { sendSignUpLinkClick } from 'scripts/redux/actions/segment/events/registrationEvents';
import { signupUrl } from 'src/utils/pageUrls';
import ReferralActivationFeedbackWidget from 'src/components/referrals/referralActivationFeedbackWidget/ReferralActivationFeedbackWidget';
import LoginForm from 'src/components/oldOnboarding/login/LoginForm';
import LoginMeta from 'src/components/meta/LoginMeta';
import OnboardingScreen, {
  OnboardingScreenHeader,
  OnboardingScreenContent,
} from 'src/components/oldOnboarding/common/onboardingScreen/OnboardingScreen';
import Paragraph from 'src/design/components/bodyText/Paragraph';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import StyledBodylink from 'src/design/components/hyperlink/StyledBodylink';
import styles from 'src/components/oldOnboarding/login/Login.mscss';
import withState from 'src/decorators/withState';
import styled from 'styled-components';
import Storage from 'scripts/utilities/Storage';

const storage = new Storage(localStorage);

const LoginBox = styled.div`
  min-width: 300px;
  border-radius: var(--bx-radius-lg);
  background: white;

  .login-header {
  }
  .signup-link,
  .signup-link a {
    font-size: 16px;
  }
`;

const Login = withState({
  Component: ({ state: {}, location }) => {
    const onSignUpLinkClick = () => sendSignUpLinkClick({ from: 'Login Page' });
    const currentUserTraits = storage.getCurrentUserTraits();
    const currentUserEmail = currentUserTraits && currentUserTraits.email;
    return (
      <OnboardingScreen
        id="login"
        testRef="login-form"
        className={styles.panel}
      >
        <LoginMeta />
        <LoginBox>
          <OnboardingScreenHeader>
            <Spacing bottom="2x-large">
              <Heading>
                <h1 className="login-header">Login</h1>
              </Heading>
            </Spacing>
          </OnboardingScreenHeader>
          <OnboardingScreenContent>
            <div style={{ textAlign: 'center' }}>
              <Spacing bottom="large">
                <ReferralActivationFeedbackWidget />
              </Spacing>
              <LoginForm
                email={currentUserEmail}
                doRedirect
                nextPathname={location.state && location.state.nextPathname}
              />
              <Spacing top="normal">
                <Paragraph align="center" className="signup-link">
                  Don't have an Account?{' '}
                  <StyledBodylink
                    href={signupUrl()}
                    onClick={onSignUpLinkClick}
                  >
                    Sign up now
                  </StyledBodylink>
                </Paragraph>
              </Spacing>
            </div>
          </OnboardingScreenContent>
        </LoginBox>
      </OnboardingScreen>
    );
  },
  componentDidMount: (props, state, setState) => {
    setState({ isMember: isMemberFromCookie() });
    scrollToTop();
  },
});

export default Login;
