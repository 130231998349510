import { useState } from 'react';
import OnboardingScreen, {
  OnboardingScreenHeader,
  OnboardingScreenContent,
} from 'src/components/oldOnboarding/common/onboardingScreen/OnboardingScreen';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import Heading from 'src/design/components/heading/Heading';
import { Formik } from 'formik';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import SmsVerify from './SmsVerify';
import UserService from 'scripts/services/UserService';
import { getCurrentUserId } from 'scripts/auth/session';
import { settingsUrl } from 'src/utils/pageUrls';
import Paragraph from 'src/design/styleguide/Paragraph';

const UpdateBankDetailsComponent = ({ onClickBack }) => {
  const [hasSentSms, setHasSentSms] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const onSubmit = async (values) => {
    setError(null);
    const userId = getCurrentUserId();
    try {
      await UserService.updateBankDetails(userId, values);
      setSuccess(true);
    } catch (e) {
      if (e._body) setError(e._body);
    }
  };

  if (success) {
    return (
      <OnboardingScreen>
        <OnboardingScreenHeader>
          <Spacing bottom="x-large">
            <Heading>Your Bank Details have been removed</Heading>
          </Spacing>
        </OnboardingScreenHeader>
        <OnboardingScreenContent>
          <PrimaryButton
            fullWidth
            color={PrimaryButton.colors.SECONDARY}
            link={settingsUrl()}
            forceExternal
          >
            RETURN TO DASHBOARD
          </PrimaryButton>
        </OnboardingScreenContent>
      </OnboardingScreen>
    );
  }

  return (
    <div className="inner">
      <div className="header">
        <Spacing bottom="x-large">
          <Heading>Update Bank Details</Heading>
        </Spacing>
        <Spacing bottom="large">
          <Paragraph>
            <Paragraph>
              To remove your existing bank details from your account, click
              Next. You can then enter your new bank details in your Account
              Settings.
            </Paragraph>
            <Paragraph>
              <small>
                <i>
                  Please note: if you are using Smart Invest, removing your bank
                  details will disable Smart Invest and cancel any upcoming
                  Direct Debits. You can re-activate Smart Invest at any time
                  under your Account Settings.
                </i>
              </small>
            </Paragraph>
          </Paragraph>
        </Spacing>
      </div>
      <div className="content">
        <Formik
          initialValues={{
            otpValue: '',
          }}
          onSubmit={async (values) => onSubmit(values)}
        >
          {(props) => {
            const { values, handleSubmit, setFieldValue } = props;
            return (
              <form onSubmit={handleSubmit}>
                {hasSentSms && (
                  <SmsVerify
                    onChangeCode={(v) => {
                      setError(null);
                      setFieldValue('otpValue', v);
                    }}
                  />
                )}
                {error && (
                  <Spacing>
                    <div className="text-red">{error}</div>
                  </Spacing>
                )}
                <Spacing top="normal">
                  {!hasSentSms ? (
                    <PrimaryButton
                      fullWidth
                      color={PrimaryButton.colors.SECONDARY}
                      onClick={() => setHasSentSms(true)}
                    >
                      NEXT
                    </PrimaryButton>
                  ) : (
                    <PrimaryButton
                      fullWidth
                      color={PrimaryButton.colors.SECONDARY}
                      disabled={values.otpValue.length !== 6}
                    >
                      SUBMIT
                    </PrimaryButton>
                  )}
                </Spacing>
                <Spacing top="normal">
                  <PrimaryButton
                    fullWidth
                    color={PrimaryButton.colors.PRIMARY}
                    onClick={onClickBack}
                  >
                    CANCEL
                  </PrimaryButton>
                </Spacing>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default UpdateBankDetailsComponent;
