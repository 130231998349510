import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Stack,
  Typography,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React, { useState } from 'react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { BsDownload } from 'react-icons/bs';
import moment from 'moment';
import UserService from 'scripts/services/UserService';
import { userSelector } from 'scripts/redux/actions/user';
import { connect } from 'react-redux';

const DistributionStatement = ({ user }) => {
  const [periodStart, setPeriodStart] = useState(null);
  const [periodEnd, setPeriodEnd] = useState(null);
  const [loading, setLoading] = useState(false);

  const userId = user.id;

  const actions = {
    onDatePickerChange: (item, newD) => {
      if (item === 'periodStart') {
        setPeriodStart(newD);
        return;
      }
      setPeriodEnd(newD);
    },
    onSelectPeriod: (item) => {
      if (item === 'today') {
        setPeriodStart(moment());
        setPeriodEnd(moment());
      } else if (item === 'yesterday') {
        setPeriodStart(moment().add(-1, 'day'));
        setPeriodEnd(moment().add(-1, 'day'));
      } else if (item === '1m') {
        setPeriodStart(moment().add(-1, 'month'));
        setPeriodEnd(moment());
      } else if (item === '6m') {
        setPeriodStart(moment().add(-6, 'month'));
        setPeriodEnd(moment());
      } else if (item === '1y') {
        setPeriodStart(moment().add(-1, 'year'));
        setPeriodEnd(moment());
      } else if (item === 'ytd') {
        setPeriodStart(moment().startOf('year'));
        setPeriodEnd(moment());
      } else if (item === 'ytd-fy') {
        setPeriodStart(moment().startOf('year').add(-6, 'month'));
        setPeriodEnd(moment());
      } else if (item === 'fy') {
        const currentM = moment().month() + 1;
        if (currentM >= 7) {
          setPeriodStart(moment().startOf('year').add(6, 'month'));
          setPeriodEnd(moment());
        } else {
          setPeriodStart(moment().startOf('year').add(-6, 'month'));
          setPeriodEnd(moment());
        }
      } else if (item === 'fy-prev') {
        const currentM = moment().month() + 1;
        if (currentM >= 7) {
          setPeriodStart(moment().startOf('year').add(-6, 'month'));
          setPeriodEnd(moment().startOf('year').add(6, 'month'));
        } else {
          setPeriodStart(moment().startOf('year').add(-18, 'month'));
          setPeriodEnd(moment().startOf('year').add(-6, 'month'));
        }
      }
    },
    onSubmit: async () => {
      const periodStartText = periodStart.format('YYYY-MM-DD');
      const periodEndText = periodEnd.add(1, 'days').format('YYYY-MM-DD');
      const periodValid = periodStart <= periodEnd;
      if (periodValid) {
        try {
          setLoading(true);
          const result = await UserService.getDistributionStatement({
            userId,
            startDate: periodStartText,
            endDate: periodEndText,
          });
          window.location.replace(result.url);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    },
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box my={5}>
        <Container maxWidth="md">
          <Typography variant="h3" textAlign={'center'} gutterBottom>
            Distribution Statement
          </Typography>
          <Typography variant="body1" textAlign={'center'}>
            This statement includes all rental income earned on your brick
            holdings, including the distribution amount, payment date and
            withholding tax amount.
          </Typography>
          <Box my={5}>
            <Stack
              mb={2}
              direction={'row'}
              spacing={2}
              justifyContent={'center'}
            >
              <ButtonGroup variant="outlined" size="small">
                <Button onClick={() => actions.onSelectPeriod('today')}>
                  Today
                </Button>
                <Button onClick={() => actions.onSelectPeriod('1m')}>
                  Last 1 month
                </Button>
                <Button onClick={() => actions.onSelectPeriod('1y')}>
                  Last 12 months
                </Button>
              </ButtonGroup>
            </Stack>
            <Stack
              mb={2}
              direction={'row'}
              spacing={2}
              justifyContent={'center'}
            >
              <ButtonGroup variant="outlined" size="small">
                <Button onClick={() => actions.onSelectPeriod('fy')}>
                  Current Financial Year
                </Button>
                <Button onClick={() => actions.onSelectPeriod('fy-prev')}>
                  Previous Financial Year
                </Button>
              </ButtonGroup>
            </Stack>
            <Stack direction={'row'} justifyContent={'center'} spacing={2}>
              <DatePicker
                value={periodStart}
                onChange={(newD) =>
                  actions.onDatePickerChange('periodStart', newD)
                }
                minDate={moment('2016-01-01')}
                maxDate={moment()}
                label="Period Start"
                format="DD/MM/YYYY"
                slotProps={{
                  textField: {
                    helperText: 'DD/MM/YYYY',
                  },
                }}
              />
              <DatePicker
                value={periodEnd}
                onChange={(newD) =>
                  actions.onDatePickerChange('periodEnd', newD)
                }
                minDate={moment('2016-01-01')}
                maxDate={moment()}
                label="Period End"
                format="DD/MM/YYYY"
                slotProps={{
                  textField: {
                    helperText: 'DD/MM/YYYY',
                  },
                }}
              />
            </Stack>
          </Box>
          <Box my={6} textAlign={'center'}>
            <Button
              variant="contained"
              size="large"
              endIcon={<BsDownload />}
              onClick={actions.onSubmit}
              disabled={!periodStart || !periodEnd}
            >
              {!loading ? 'Generate Statement' : 'Loading...'}
            </Button>
          </Box>
        </Container>
      </Box>
    </LocalizationProvider>
  );
};

const mapStateToProps = (state) => ({
  ...userSelector(state),
});

export default connect(mapStateToProps, {})(DistributionStatement);
