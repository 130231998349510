import styled from 'styled-components';
import { SubHeading } from 'src/design/styleguide/Headings';
import { WelcomePageWidgetPanel } from 'src/components/dashboard/home/commonComponents';
import { breakpointSmall } from 'src/design/styleguide/common/breakpoints';
import { defineGridArea } from 'src/design/styleguide/common/styledComponentsUtils';
import { gridUnit } from 'src/design/styleguide/common/measurements';
import Image from 'src/design/components/image/Image';
import Paragraph from 'src/design/styleguide/Paragraph';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import { useState } from 'react';
import FileUpload from 'src/design/components/fileUpload/FileUpload';
import RadioSelect from 'src/design/components/radioSelect/RadioSelect';
import Styledlink from 'src/design/components/hyperlink/Styledlink';
import Divider from 'src/design/components/divider/Divider';
import { Formik, Form } from 'formik';
import RegistrationService from 'scripts/services/RegistrationService';

const Container = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: ${gridUnit * 6}px;
  justify-items: center;

  ${breakpointSmall(`
    grid-template-columns: minmax(0, 1fr) auto;
    justify-items: start;
    align-items: start;
    ${defineGridArea('icon-area')}
    ${defineGridArea('button-area')}
    ${defineGridArea('text-area')}
    grid-template-areas:
      'icon-area button-area'
      'text-area text-area'
  `)}
`;

const StyledImage = styled(Image)`
  width: 70px;
`;

const PromoTextArea = styled.div`
  max-width: 500px;

  ${breakpointSmall(`
    max-width: 250px;
  `)}
`;

const PromoTitle = styled(SubHeading).attrs({
  as: 'h3',
  fontSize: '2x-small',
  fontWeight: 'bold',
})`
  ${breakpointSmall(`
    text-align: left;
  `)}
`;

const PromoText = styled(Paragraph).attrs({
  fontSize: '3x-small',
  align: 'center',
})`

  ${breakpointSmall(`
    text-align: left;
  `)}
`;

const FormContainer = styled.div`
.button-group {
  display: flex;
  button {
    margin-right: 10px;
  }
}
`;


const WholesalePanel = ({ className, user, wholesaleInfo }) => {
  const [edit, setEdit] = useState(false);
  const [hasRegistered, setRegistered] = useState(false);

  // if registered
  if (wholesaleInfo || hasRegistered) {
    const verified = wholesaleInfo ? wholesaleInfo.verified : false;
    return (
      <WelcomePageWidgetPanel className={className} title="BrickX - Professional and Sophisticated Investors" testRef="wholesale-promo-panel">
        <Container>
          <StyledImage className="icon-area" src="https://assets.brickx.com/images/skyscraper.png" alt="enrol in smart invest icon"/>
          <PromoTextArea>
            <PromoTitle>Thank you for your registration.</PromoTitle>
            <Spacing top="x-small">
              {
                verified
                ? <PromoText>We'll contact you about our latest deals.</PromoText>
                : <PromoText>Your qualification is being reviewed.</PromoText>
              }
            </Spacing>
          </PromoTextArea>
        </Container>
      </WelcomePageWidgetPanel>
    );
  }
  return (
    <WelcomePageWidgetPanel className={className} title="BrickX - Professional and Sophisticated Investors" testRef="wholesale-promo-panel">
      <Container>
        <StyledImage className="icon-area" src="https://assets.brickx.com/images/skyscraper.png" alt="enrol in smart invest icon"/>
        <PromoTextArea className="text-area">
          <PromoTitle>Are you a professional and sophisticated investor?</PromoTitle>
          <Spacing top="x-small">
            <PromoText>Sign Up for BrickX - Professional and Sophisticated Investors</PromoText>
          </Spacing>
        </PromoTextArea>
        {
          !edit &&
          <PrimaryButton
          className="button-area"
          color={PrimaryButton.colors.ACCENT}
          size={PrimaryButton.sizes.SMALL}
          onClick={() => setEdit(true)}
          testRef="wholesale-promo-panel-cta"
        >
          Sign Up Now
        </PrimaryButton>
        }
      </Container>
      {
        edit && (<FormContainer>
          <Divider />
          <Formik
            initialValues={{
              wholesaleConfirm: false,
              certificateLabel: null,
              certificate: null
            }}
            onSubmit={async (values) => {
              const result = await RegistrationService.signupWholesaleInvestor({
                id: user.id,
                certificate: values.certificate,
                certificateLabel: values.certificateLabel,
                certificateExpired: '01-01-2099',
                questionnaire: JSON.stringify({}),
              });
              if (result && result.result === 'ok') {
                setRegistered(true);
              }
            }}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <Spacing bottom="large">
                  <Paragraph color="onSurfaceContrast500">
                    Note: To qualify as a professional and sophisticated investor, your accountant must be able to verify that you as the investor (including the net assets or gross income or any companies or trusts controlled by you the Investor as defined in section 50AA of the Corporations Act) has either:
                    <br />
                    <strong>
                      <br />(i) Net assets of at least AUD$2,500,000; OR
                      <br />(ii) Gross income for each of the last 2 financial years of at least AUD$250,000 a year.
                    </strong>
                    <br />
                    <br />To register on the BrickX Platform as a professional and sophisticated investor, please upload a Qualified Accounts Certification.
                    <br />
                    <br /><i>You can <Styledlink forceExternal href="https://assets.brickx.com/downloads/qualified_accountants_certificate.pdf">click here</Styledlink> to download a Qualified Accountant’s Certificate template</i>
                  </Paragraph>
                </Spacing>
                <Spacing bottom="normal">
                  <FileUpload
                    ctaText="Select File"
                    realTestRef={'wholesale-fileupload'}
                    formDataFieldName="wholesale_certificate"
                    onChange={(file) => {
                      if (file) {
                        const r = new FileReader();
                        r.readAsDataURL(file);
                        r.onloadend = (e) => {
                          const base64Str = e.target.result.split(',')[1];
                          setFieldValue('certificateLabel', file.name);
                          setFieldValue('certificate', base64Str);
                        };
                      }
                    }}
                    accept=".png, .gif, .jpeg, .jpg, .pdf"
                  />
                </Spacing>
                <Spacing>
                  <Paragraph>
                    By registering as a Professional and Sophisticated Investor with BrickX, I consent to being offered exclusive professional and sophisticated investor financial products and services from BrickX and it’s related parties.
                  </Paragraph>
                </Spacing>
                <Spacing bottom="x-samll">
                  <RadioSelect
                  name="wholesale-confirm"
                  value={values.wholesaleConfirm}
                  onChange={(value) => {
                    setFieldValue('wholesaleConfirm', value === 'true');
                  }}
                  options={[
                    { value: true, labelText: 'Yes' },
                    { value: false, labelText: 'No' },
                  ]}
                  testRef="wholesale-confirm"
                />
                </Spacing>
                <Spacing top="x-large">
                  <div className="button-group">
                    <PrimaryButton
                      className="button-area"
                      color={PrimaryButton.colors.ACCENT}
                      size={PrimaryButton.sizes.SMALL}
                      // onClick={() => setEdit(false)}
                      disabled={!values.wholesaleConfirm || !values.certificate}
                      testRef="wholesale-promo-panel-cta"
                      type="submit"
                    >
                      Submit
                    </PrimaryButton>
                    <PrimaryButton
                      className="button-area"
                      color={PrimaryButton.colors.PRIMARY}
                      size={PrimaryButton.sizes.SMALL}
                      onClick={() => setEdit(false)}
                      testRef="wholesale-promo-panel-cta"
                    >
                      Cancel
                    </PrimaryButton>
                  </div>
                </Spacing>
              </Form>
            )}
            </Formik>
          </FormContainer>
        )
      }
    </WelcomePageWidgetPanel>
  );
};

export default WholesalePanel;
