import { elements as elementsPropType } from 'scripts/constants/PropTypes';

import classNames from 'classnames';

export const ratios = {
  '16:9': null,
};

const RatioContainer = ({ className, children, ratio = ratios['16:9'] }) => (
  <div className={classNames('ratio-container', ratio, className)}>
    {children}
  </div>
);

RatioContainer.propTypes = {
  children: elementsPropType,
};

export default RatioContainer;
