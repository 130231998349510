import PropTypes from 'prop-types';

import FinancialsPanel from 'scripts/components/shared/FinancialsPanel';

import { property as propertyPropType } from 'scripts/constants/PropTypes';


const PropertyVideo = ({ videoUrl, description, duration }) => {
  if (!videoUrl) {
    return null;
  }
  return (
    <FinancialsPanel className="panel-financial-video property-video" title="Investment Case Video">
      <iframe src={videoUrl} width="500" height="286" frameBorder="0" allowFullScreen></iframe>
      {description &&
        <p className="video-description">{description}</p>
      }
      {duration &&
        <p className="video-duration">{duration}</p>
      }
    </FinancialsPanel>
  );
};

PropertyVideo.prototype = {
  property: propertyPropType.isRequired,
  description: PropTypes.string,
  duration: PropTypes.string
};

export default PropertyVideo;
