import { extractAllExperimentsFromGaData } from 'src/tracking/optimize';
import Analytics from 'analytics';
import googleAnalytics from '@analytics/google-analytics';

const analytics = Analytics({
  app: 'brickx-web',
  plugins: [
    googleAnalytics({
      trackingId: 'G-SNFNWW6PB2'
    })
  ]
});

const getAnalytics = () => analytics || {
  track: () => {},
  identify: () => {},
  alias: () => {},
  reset: () => {},
};

export default {
  track: (event, properties) => getAnalytics().track(event, { ...properties, experiments: extractAllExperimentsFromGaData() }),
  identify: (id, user) => getAnalytics().identify(id, { ...user, experiments: extractAllExperimentsFromGaData() }),
  alias: () => {},
  reset: () => {},
};
