import moment from 'moment';
import { sortBy, toPairs } from 'lodash';
import Trading from 'scripts/constants/Trading';
import Numbers from 'scripts/constants/Numbers';
import PropertyStatus from 'scripts/constants/PropertyStatus';
import { isNumber } from 'src/utils/validation';

import Popover from 'scripts/components/shared/Popover';

import { SortBy } from 'scripts/constants/properties/SortBy';
import { years as historicalGrowthYears } from 'scripts/constants/HistoricalGrowth';

import {
  isInLastMonth,
  daysUntil,
  isToday,
  date,
  months,
  dayMonthAndShortYearNoDash,
  dayMonthYearExtended,
  percentDecimal,
} from 'scripts/utilities/formatters';
import _ from 'lodash';

export function getTenantStatusCopy({
  leaseAgreementSigned,
  leaseTerm,
  leaseStartDate,
  rentContractExpiryDate,
  leaseLength,
}) {
  if (!leaseAgreementSigned) {
    return {
      status: 'Seeking Tenants',
      detail: 'Currently marketing the property',
    };
  }

  const leaseStartsInTheFuture = moment(leaseStartDate).isAfter(moment.now());
  const status = leaseStartsInTheFuture ? 'Lease Signed' : 'Currently Tenanted';
  const fixedTermStartingOrEnding = leaseStartsInTheFuture
    ? `starting ${leaseStartDate::dayMonthYearExtended()}`
    : `ending ${rentContractExpiryDate::dayMonthYearExtended()}`;

  const leaseTermDetailMap = {
    fixed: `${leaseLength::months()} ${fixedTermStartingOrEnding}`,
    month_to_month: `Leased month-to-month starting ${leaseStartDate::dayMonthYearExtended()}`,
  };

  return {
    status: status,
    detail: leaseTermDetailMap[leaseTerm],
  };
}

export function getBrickPriceTitle(property) {
  const isPreorderOrComingSoonProperty =
    isAPreOrderOrComingSoonProperty(property);
  if (isPreorderOrComingSoonProperty) {
    return 'Initial Brick Price';
  }
  if (property.financials.lowestAvailableBrickPrice) {
    return 'Lowest Available Brick Price';
  }
  if (property.financials.isIndependentValued) {
    return 'Latest Brick Valuation';
  }
  return 'Initial Brick Price';
}

export function getBrickPrice(property) {
  const isPreorderOrComingSoonProperty =
    isAPreOrderOrComingSoonProperty(property);
  if (isPreorderOrComingSoonProperty) {
    return property.financials.initialBrickPrice;
  }
  if (property.financials.lowestAvailableBrickPrice) {
    return property.financials.lowestAvailableBrickPrice;
  }
  if (property.financials.isIndependentValued) {
    return property.financials.brickValue;
  }
  return property.financials.initialBrickPrice;
}

export function getBrickValuation(property) {
  return {
    brickValuation: property.financials.isIndependentValued
      ? property.financials.brickValue
      : property.financials.initialBrickPrice,
    brickValuationTitle: property.financials.isIndependentValued
      ? 'Brick Valuation'
      : 'Initial Brick Price',
  };
}

export function getPriceDifferencePercentage(property) {
  const { brickValuation } = getBrickValuation(property);
  return (
    (property.financials.lowestAvailableBrickPrice - brickValuation) /
    brickValuation
  );
}

export function getBricksSoldPercentage(property) {
  const bricksSold =
    Trading.TOTAL_BRICKS_PER_PROPERTY -
    property.investmentMetrics.remainingBricks;
  return Math.floor(
    (Numbers.ONE_HUNDRED * bricksSold) / Trading.TOTAL_BRICKS_PER_PROPERTY
  );
}

export function shouldFirstPremiumAlertBeTriggered(property) {
  return isBrickPriceHigherThanValuationByThreshold(
    property,
    Trading.PREMIUM_PRICE_FIRST_LIMIT
  );
}

export function shouldSecondPremiumAlertBeTriggered(property) {
  return isBrickPriceHigherThanValuationByThreshold(
    property,
    Trading.PREMIUM_PRICE_SECOND_LIMIT
  );
}

export function isBrickPriceHigherThanValuationByThreshold(
  property,
  threshold
) {
  const priceDifferencePercentage = getPriceDifferencePercentage(property);
  return priceDifferencePercentage >= threshold;
}

export function getNumOfYearsToRecover(
  property,
  twentyYHistoricalSuburbGrowth
) {
  const { brickValuation } = getBrickValuation(property);
  const gearingEffect = calculateGearingEffect(
    twentyYHistoricalSuburbGrowth,
    property.financials.lVR
  );
  return (
    Math.round(
      (Math.log10(
        property.financials.lowestAvailableBrickPrice / brickValuation
      ) /
        Math.log10(1 + gearingEffect + twentyYHistoricalSuburbGrowth)) *
        Numbers.TEN
    ) / Numbers.TEN
  );
}

export function isAComingSoonProperty(property) {
  return property.propertyStatus === PropertyStatus.COMING_SOON;
}

export function isAPreOrderProperty(property) {
  return property.propertyStatus === PropertyStatus.PRE_ORDER;
}

export function isANewProperty(property) {
  return property.propertyStatus === PropertyStatus.NEW;
}

export function isAMarketOpenProperty(property) {
  return property.propertyStatus === PropertyStatus.MARKET_OPEN;
}

export function isAMarketClosedProperty(property) {
  return property.propertyStatus === PropertyStatus.MARKET_CLOSED;
}

export function isAPreOrderOrComingSoonProperty(property) {
  return isAComingSoonProperty(property) || isAPreOrderProperty(property);
}

export function isAPostPreorderProperty(property) {
  return isAMarketOpenProperty(property) || isANewProperty(property);
}

export function isAPreMarketOpenProperty(property) {
  return (
    isAComingSoonProperty(property) ||
    isAPreOrderProperty(property) ||
    isANewProperty(property)
  );
}

export function isAOffMarketProperty(property) {
  return property.propertyStatus === PropertyStatus.OFF_MARKET;
}

export function hasNoBricksAvailable(property) {
  if (isAPreOrderOrComingSoonProperty(property)) {
    return false;
  }

  return property.financials.lowestAvailableBrickPrice <= 0;
}

export function filterByLastMonth() {
  return _.filter(this, function (data) {
    return data.date::isInLastMonth();
  });
}

export function settlementInfoTextToShow(property) {
  const settlementDate = property.platformSettlementDate;
  const daysUntilSettlement = settlementDate::daysUntil();
  return daysUntilSettlement > Numbers.NEGATIVE_INFINITY
    ? 'Settlement Date'
    : `${settlementDate::isToday() ? 'day' : 'days'} until settlement`;
}

export function settlementInfoValueToShow(property, shortened) {
  const settlementDate = property.platformSettlementDate;
  const daysUntilSettlement = settlementDate::daysUntil();
  const settlementDateFormatted = shortened
    ? settlementDate::dayMonthAndShortYearNoDash()
    : settlementDate::date();
  return daysUntilSettlement > Numbers.NEGATIVE_INFINITY
    ? settlementDateFormatted
    : settlementDate::daysUntil();
}

export function brickValuationTextToShow(property) {
  return isAPreOrderOrComingSoonProperty(property) ||
    !property.financials.isIndependentValued
    ? 'Initial Brick Price'
    : 'Brick Valuation';
}

export function bricksSoldProgressBarTextToShow(property, showPopover) {
  const textToShow = isANewProperty(property)
    ? 'Initial Bricks Sold'
    : 'Reserved';
  const popoverContent =
    'Percentage of Bricks sold by the Initial Brick Holder. There is a total of 10,000 Bricks in each Trust.';

  return (
    <span>
      <span className="text-blue">{getBricksSoldPercentage(property)}%</span>{' '}
      {textToShow}{' '}
      {showPopover && <Popover placement="top" content={popoverContent} />}
    </span>
  );
}

export function getHistoricalGrowthMetricsForProperty(
  historicalGrowthMetrics,
  property
) {
  if (historicalGrowthMetrics) {
    if (property.propertyType.toUpperCase() === 'MULTIUNIT') {
      return _.get(historicalGrowthMetrics, [
        property.state,
        property.historicalGrowthKey,
        'UNIT',
      ]);
    }
    if (property.propertyType.toUpperCase() === 'MULTIHOUSE') {
      return _.get(historicalGrowthMetrics, [
        property.state,
        property.historicalGrowthKey,
        'HOUSE',
      ]);
    }
    if (property.propertyType.toUpperCase() === 'DEVELOPMENT') {
      return _.get(historicalGrowthMetrics, [
        property.state,
        property.historicalGrowthKey,
        'HOUSE',
      ]);
    }
    if (property.propertyType.toUpperCase() === 'COMMERCIAL') {
      return _.get(historicalGrowthMetrics, [
        property.state,
        property.historicalGrowthKey,
        'HOUSE',
      ]);
    }
    return _.get(historicalGrowthMetrics, [
      property.state,
      property.historicalGrowthKey,
      property.propertyType.toUpperCase(),
    ]);
  }
  return null;
}

export const getSortedGrowthMetricsPairs = (historicalGrowthMetrics) => {
  return sortBy(
    toPairs(historicalGrowthMetrics).map(([years, growth]) => [
      parseInt(years.replace('yr', ''), 10),
      growth,
    ]),
    ([years]) => years
  ).map(([years, growth]) => [`${years}yr`, growth]);
};

const getHighestNumberOfYearsGrowthMetricsPair = (historicalGrowthMetrics) => {
  const sortedGrowthMetrics = getSortedGrowthMetricsPairs(
    historicalGrowthMetrics
  ).reverse();
  return sortedGrowthMetrics.find(([, growth]) => isNumber(growth));
};

export const getHistoricalGrowthMetricsHighestNumberOfYears = (
  historicalGrowthMetrics
) =>
  getHighestNumberOfYearsGrowthMetricsPair(historicalGrowthMetrics)[0].replace(
    'yr',
    ''
  );

export function getPropertyCardHistoricalGrowthLabelYears(
  historicalGrowthMetrics
) {
  const historicalGrowthStatLabelYear =
    historicalGrowthMetrics &&
    `${getHistoricalGrowthMetricsHighestNumberOfYears(
      historicalGrowthMetrics
    )}yr`;

  return historicalGrowthStatLabelYear
    ? `${historicalGrowthStatLabelYear}`
    : 'No';
}

export function getHistoricalGrowthMetricsHighestNumberOfYearsGrowthAmount(
  historicalGrowthMetrics
) {
  return (
    historicalGrowthMetrics &&
    getHighestNumberOfYearsGrowthMetricsPair(historicalGrowthMetrics)[1]
  );
}

export function getHistoricalGrowthMetricsHighestNumberOfYearsGrowth(
  historicalGrowthMetrics
) {
  const historicalGrowthStat =
    getHistoricalGrowthMetricsHighestNumberOfYearsGrowthAmount(
      historicalGrowthMetrics
    );

  if (isNumber(historicalGrowthStat)) {
    return historicalGrowthStat::percentDecimal();
  }
  return '---';
}

export function sortPreOrderComingSoonFirstThenSortBoth(
  properties,
  sortFunction
) {
  const [preOrderOrComingSoonProperties, marketOpenProperties] = _.partition(
    properties,
    isAPreOrderOrComingSoonProperty
  );

  return sortFunction(preOrderOrComingSoonProperties).concat(
    sortFunction(marketOpenProperties)
  );
}

export function sortByDiscountWithPreOrderAndComingSoonFirst(
  filteredProperties
) {
  const splitProperties = _.partition(filteredProperties, function (property) {
    return isAPreOrderOrComingSoonProperty(property);
  });
  const comingProperties = _.orderBy(
    splitProperties[0],
    SortBy.LaunchDateTime.propertyName,
    SortBy.LaunchDateTime.sortingWay
  );
  const openProperties = _.orderBy(
    splitProperties[1],
    SortBy.Discount.propertyName,
    SortBy.Discount.sortingWay
  );
  return comingProperties.concat(openProperties);
}

export function sortBy20YearHistoricalSuburbGrowth(
  filteredProperties,
  sortMethodSelected,
  averageAnnualGrowthMetrics
) {
  return _.orderBy(
    filteredProperties,
    (property) => {
      const propertyHistoricalGrowthMetrics =
        getHistoricalGrowthMetricsForProperty(
          averageAnnualGrowthMetrics,
          property
        );
      return _.get(propertyHistoricalGrowthMetrics, [
        historicalGrowthYears.TWENTY,
      ]);
    },
    sortMethodSelected.sortingWay
  );
}

export function calculateGearingEffect(growth, lvr) {
  return growth / (1 - lvr) - growth;
}
