import { connect } from 'react-redux';
import { resendEmail as resendEmailAction } from 'scripts/redux/actions/registration';
import Heading from 'src/design/components/heading/Heading';
import Paragraph from 'src/design/components/bodyText/Paragraph';
import OnboardingScreen, {
  OnboardingScreenHeader,
  OnboardingScreenContent,
} from 'src/components/oldOnboarding/common/onboardingScreen/OnboardingScreen';
import SecondaryButton from 'src/design/components/button/SecondaryButton';
import Spacing from 'src/design/styleguide/spacing/Spacing';


const VerifyEmail = ({ resendEmail, userId }) => (
  <OnboardingScreen testRef="verify-email-screen">
    <OnboardingScreenHeader>

      <Spacing bottom="2x-large">
        <img src="/static/images/icons/icon-envelope-exclamation.svg" alt="email envelope graphic"/>
      </Spacing>

      <Spacing bottom="large">
        <Heading>Let’s start by verifying your email</Heading>
      </Spacing>

      <Spacing bottom="2x-large">
        <Paragraph>Please check your inbox</Paragraph>
      </Spacing>

    </OnboardingScreenHeader>

    <OnboardingScreenContent>
      <SecondaryButton
        onClick={() => resendEmail({ userId })}
        color={SecondaryButton.colors.SECONDARY}
        fullWidth
        testRef="resend-email-button"
      >RESEND EMAIL</SecondaryButton>
    </OnboardingScreenContent>

  </OnboardingScreen>
);

export default connect(null, { resendEmail: resendEmailAction })(VerifyEmail);
