import styles from 'src/components/promos/placements/TradePanelBanner.mscss';

const TradePanelBannerContainer = ({ children }) => (
  <div className={styles.tradePanelPreOrderBanner}>
    {children}
  </div>
);

const TradePanelBanner = () => (
  <TradePanelBannerContainer>
    {null}
  </TradePanelBannerContainer>
);

export default TradePanelBanner;
