import _ from 'lodash';
import PropertyStatus from 'scripts/constants/PropertyStatus';
import { VOTE_CLOSED_PROPERTIES } from 'src/settings/trading';

const PropetyFlag = ({ propertyCode, propertyStatus }) => {
  return (
    <div>
      {propertyStatus === PropertyStatus.COMING_SOON && (
        <div className="flag coming-soon-flag">
          COMING<div>SOON</div>
          <div className="triangles-box">
            <span className="lx-triangle" />
            <span className="rx-triangle" />
          </div>
        </div>
      )}
      {propertyStatus === PropertyStatus.PRE_ORDER && (
        <div className="flag pre-order-flag">
          PRE<div>ORDER</div>
          <div className="triangles-box">
            <span className="lx-triangle" />
            <span className="rx-triangle" />
          </div>
        </div>
      )}
      {propertyStatus === PropertyStatus.NEW && (
        <div className="flag new-property-flag">
          <div>NEW</div>
          <div className="triangles-box">
            <span className="lx-triangle" />
            <span className="rx-triangle" />
          </div>
        </div>
      )}
      {propertyStatus === PropertyStatus.MARKET_CLOSED && (
        <div className="flag new-property-flag">
          VOTE
          <div>
            {_.includes(VOTE_CLOSED_PROPERTIES, propertyCode)
              ? 'CLOSED'
              : 'OPEN'}
          </div>
          <div className="triangles-box">
            <span className="lx-triangle" />
            <span className="rx-triangle" />
          </div>
        </div>
      )}
      {propertyStatus === PropertyStatus.VOTE_OPEN && (
        <div className="flag new-property-flag">
          VOTE <br />
          OPEN
          <div className="triangles-box">
            <span className="lx-triangle" />
            <span className="rx-triangle" />
          </div>
        </div>
      )}
      {propertyStatus === PropertyStatus.VOTE_CLOSED && (
        <div className="flag new-property-flag">
          VOTE <br />
          CLOSED
          <div className="triangles-box">
            <span className="lx-triangle" />
            <span className="rx-triangle" />
          </div>
        </div>
      )}
    </div>
  );
};

export default PropetyFlag;
