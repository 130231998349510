import TextInput from 'src/design/components/textInput/TextInput';

const getListOfValidationFunctions = (validations, ruleToValidationFunctionMapping) => validations
  .reduce((listOfFuncs, validation) => {
    const validationFunction =
      ruleToValidationFunctionMapping[validation.rule] &&
      ruleToValidationFunctionMapping[validation.rule](validation);
    return validationFunction
      ? [...listOfFuncs, validationFunction]
      : listOfFuncs;
  }, []);

const generateTextFieldValidationProps = (validations, props = {}) => {
  const isStringValidFuncs = getListOfValidationFunctions(validations, {
    'max-length': (validation) => TextInput.validation.maxLength(validation.max),
  });

  const isCharacterValidFuncs = getListOfValidationFunctions(validations, {
    'incremental-regex': (validation) => (value) => RegExp(validation.regex).test(value),
  });

  const isStringValidProp = typeof props.isStringValid === 'function' ? props.isStringValid : () => true;
  const isCharacterValidProp = typeof props.isCharacterValid === 'function' ? props.isCharacterValid : () => true;

  return {
    isStringValid: (string) => isStringValidFuncs.every((func) => func(string)) && isStringValidProp(string),
    isCharacterValid: (string) => isCharacterValidFuncs.every((func) => func(string)) && isCharacterValidProp(string),
  };
};

export default generateTextFieldValidationProps;
