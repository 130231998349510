import { connect } from 'react-redux';

import { Component } from 'react';
import PropTypes from 'prop-types';

import { myOrder as myOrderPropType, property as propertyPropType } from 'scripts/constants/PropTypes';
import { fetchFees as fetchFeesAction } from 'scripts/redux/actions/market';
import { feesSelector } from 'scripts/redux/selectors/market';

import MySellOrderRow from 'scripts/components/account/myOrders/MySellOrderRow';
import Popover from 'scripts/components/shared/Popover';

import { brickPrice } from 'scripts/utilities/formatters';
import { roundTransactionFee } from 'scripts/utilities/helpers';
import { date as dateSort } from 'scripts/utilities/sorters';

import BodyText from 'src/design/components/bodyText/BodyText';

import _ from 'lodash';

const LOWEST_BRICK_PRICE_POPOVER_TEXT = 'The lowest Brick price currently available in the market for this property.';

const mapStateToProps = (state) => ({
  ...feesSelector(state),
});

@connect(mapStateToProps, {
  fetchFees: fetchFeesAction,
})

export default class MySellOrdersTable extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    myOrders: PropTypes.arrayOf(myOrderPropType),
    properties: PropTypes.arrayOf(propertyPropType).isRequired,
    action: PropTypes.func,
    emptyMessage: PropTypes.element,
    fetchFees: PropTypes.func,
    fees: PropTypes.shape({
      feeFreeEnabled: PropTypes.bool,
      buyFee: PropTypes.number,
      preOrderFee: PropTypes.number,
      sellFee: PropTypes.number,
    }),
  };

  state = {
    expanded: {}
  };

  componentDidMount() {
    this.props.fetchFees();
  }

  render() {
    const { title, myOrders, properties, action, emptyMessage } = this.props;
    return (
      <div className="panel panel-default panel-orders">
        <div className="panel-heading">
          <h3 className="panel-title"><span className="icn icn-small icn-pending-white"></span>{title}</h3>
        </div>
        {_.isEmpty(myOrders)
          ?
            <div className="panel-body">
              {emptyMessage}
            </div>
          :
          <table className="table table-bordered table-hover pending-sell-orders-table">
            <thead>
              <tr>
                <th className="text-left"><BodyText>Property</BodyText></th>
                <th className="hidden-xs hidden-sm"><BodyText>Date placed</BodyText></th>
                <th className="hidden-xs hidden-sm">
                  <BodyText><span>Days<br/>listed</span></BodyText>
                </th>
                <th>
                  <BodyText>
                    <span className="hidden-xs hidden-sm">Qty of<br/>Bricks</span>
                    <span className="hidden-md hidden-lg">Qty</span>
                  </BodyText>
                </th>
                <th><BodyText>Brick<br/>Sell Price</BodyText></th>
                <th><BodyText>You Receive <Popover placement="top" content={`The total amount you will receive from this sell order, after subtracting the ${this.props.fees.sellFee}% selling fee.`}/></BodyText></th>
                <th className="hidden-xs hidden-sm"><BodyText>Lowest Available<br/>Brick Price <Popover placement="top" content={LOWEST_BRICK_PRICE_POPOVER_TEXT}/></BodyText></th>
                <th className="hidden-xs hidden-sm"><BodyText>Reference</BodyText></th>
                <th>{/* no actions */}</th>
              </tr>
            </thead>

            <tbody>
              {myOrders::dateSort(false).map((myOrder, index) =>
                <MySellOrderRow key={index} myOrder={myOrder} properties={properties} action={action}/>
              )}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="5" className="text-left hidden-xs hidden-sm">TOTAL</td>
                <td colSpan="3" className="text-left hidden-md hidden-lg">TOTAL</td>
                <td className="total-amount-you-received">{this._totalAmountYouReceived(myOrders)::brickPrice()}</td>
                <td colSpan="3" className="hidden-xs hidden-sm"></td>
              </tr>
            </tfoot>
          </table>
        }
      </div>
    );
  }

  _totalAmountYouReceived = (orders) => orders.reduce((sum, order) => {
    const transactionFee = order.price * order.quantity * order.commission;
    return (order.price * order.quantity) - transactionFee::roundTransactionFee() + sum;
  }, 0);
}
