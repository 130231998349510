import SystemsService from 'scripts/services/SystemsService';
import { createAction, handleActions } from 'redux-actions';
import update from 'immutability-helper';

const MAINTENANCE_STATUS = 'scripts/system/MAINTENANCE_STATUS';
const maintenanceStatusAction = createAction(MAINTENANCE_STATUS);

export function fetchMaintenanceStatus() {
  return async function (dispatch) {
    const payload = await SystemsService.getMaintenanceStatus();
    dispatch(maintenanceStatusAction(payload));
  };
}


const reducer = handleActions({
  [MAINTENANCE_STATUS]: (state, action) => update(state, {
    maintenanceStatus: {
      $set: action.payload
    }
  })
}, {
  maintenanceStatus: null,
});

export default reducer;
