import PropTypes from 'prop-types';
import Highchart from 'src/third-party/highchart/Highchart';
import FinancialsPanel from 'scripts/components/shared/FinancialsPanel';
import { monthYear, htmlEncoded, dollarDecimal } from 'scripts/utilities/formatters';
import Icon from 'scripts/components/shared/Icon';
import { monthlyDistributions as monthlyDistributionsPropType, property as propertyPropType } from 'scripts/constants/PropTypes';
import _ from 'lodash';

const LABEL_AMOUNT = 'Distribution Per Brick';

const ActualMonthlyDistributions = ({ monthlyDistributions, property }) => {
  return (
    <FinancialsPanel className="panel-monthly-distributions" title={`Historical BrickX Monthly Distributions`}>
      {!_.isEmpty(monthlyDistributions)
        ?
        <div className="table-responsive">
          <Highchart hotReload key={`chart-distributions-${property.propertyCode}`} config={{
            'credits': {
              enabled: false
            },
            colors: ['#FF8282', '#6EB4F0'],
            chart: {
              type: 'spline',
              backgroundColor: '#FFFFFF',
            },
            title: false,
            xAxis: {
              categories: monthlyDistributions.reverse().map((data) => data.distributionDate::monthYear()),
              crosshair: true
            },
            yAxis: [{
              // amount
              title: {
                text: LABEL_AMOUNT
              },
              labels: {
                formatter: function () {
                  return this.value::dollarDecimal();
                }
              }
            }],
            tooltip: {
              shared: true
            },
            series: [{
              name: LABEL_AMOUNT,
              type: 'column',
              data: monthlyDistributions.map((data) => data.amount),
              tooltip: {
                pointFormatter: function () {
                  return `<span style="color:${this.color}">\u25CF</span> ${this.series.name::htmlEncoded()}: <b>${this.y::dollarDecimal()}</b><br/>`;
                }
              },
              animation: false,
            }]
          }}/>
        <small className="disclaimer">Distributions displayed for up to last 12 months. Past performance is not a guide to future performance.</small>
        </div>
        :
        <div className="no-distributions">
          <Icon type="icon" name="office-12" />
        </div>
      }
    </FinancialsPanel>
  );
};

ActualMonthlyDistributions.propTypes = {
  monthlyDistributions: monthlyDistributionsPropType,
  property: propertyPropType.isRequired,
  isPreMarketOpenProperty: PropTypes.bool.isRequired
};

export default ActualMonthlyDistributions;
