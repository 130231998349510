import styled from 'styled-components';

const StyledImage = styled.img`
  width: 100%;
`;

const Image = ({ className, src, alt }) => (
  <StyledImage className={className} src={src} alt={alt}/>
);

export default Image;
