import { Helmet } from 'react-helmet';

// Add key to Helmet to stop it from calling shouldComponentUpdate and breaking in React 16 https://github.com/nfl/react-helmet/issues/373

const Meta = ({
  title,
  description,
  keywords,
  image,
  imageAlt,
  otherTags,
}) => {
  return (
		<Helmet key={Math.random()}>
      {title && [
        <title key={`${title}`}>{title}</title>,
        <meta key={`og:${title}`} property="og:title" content={title}/>,
        <meta key={`twitter:${title}`} name="twitter:title" content={title}/>,
      ]}
      {description && [
        <meta key={`${description}`} name="description" content={description}/>,
        <meta key={`og:${description}`} property="og:description" content={description}/>,
        <meta key={`twitter:${description}`} name="twitter:description" content={description}/>,
      ]}
			{keywords && <meta name="keywords" content={keywords}/>}
      {image && [
        <meta key={`og:${image}`} property="og:image" content={image}/>,
        <meta key={`og:${image}:url`} property="og:image:url" content={image}/>,
        <meta key={`og:${image}:secure_url`} property="og:image:secure_url" content={image}/>,
        <meta key={`twitter:${image}:src`} name="twitter:image:src" content={image}/>,
      ].concat(imageAlt ? [<meta key={`og:${imageAlt}`} property="og:image:alt" content={imageAlt}/>] : [])}
      {otherTags}
		</Helmet>
  );
};

export default Meta;
