/* eslint-disable */
import React, { Component } from 'react';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import styled from 'styled-components';
import { breakpointXSmall } from 'src/design/styleguide/common/breakpoints';
import FormInput from 'src/components/flows/common/FormInput';
import post from 'scripts/services/request/post';

const FirstSecondPlacements = styled.div`
  ${breakpointXSmall(`
    display: flex;
  `)}
`;

const FirstPlacement = styled.div`
  margin-bottom: 8px;

  ${breakpointXSmall(`
    flex: 1 0 0px;

    & + * {
      margin-left: 8px;
    }
  `)}
`;

const SecondPlacement = styled.div`
  margin-bottom: 8px;

  ${breakpointXSmall(`
    flex: 1 0 0px;
  `)}
`;

const ThirdPlacement = styled.div`
  margin-bottom: 8px;
`;

const ErrorBar = styled.div`
  text-align: center;
  color: #ffffff;
  background: #ff0000;
  border-radius: 10px;
  padding: 10px;
`;

const SuccessBar = styled.div`
  text-align: center;
  color: #00b828;
  padding: 10px;
`;

const TwoPlusOneFormLayout = ({ items }) => (
  <div>
    <FirstSecondPlacements>
      {items[0] && <FirstPlacement>{items[0]}</FirstPlacement>}
      {items[1] && <SecondPlacement>{items[1]}</SecondPlacement>}
    </FirstSecondPlacements>
    {items[2] && <ThirdPlacement>{items[2]}</ThirdPlacement>}
  </div>
);

class RegistrationForm extends Component {
  state = {
    form: {
      'first-name': '',
      'last-name': '',
      email: '',
    },
    isFormValid: false,
    errorMsg: null,
    isSent: false,
  };
  onSubmit = () => {
    const firstName = this.state.form['first-name'];
    const lastName = this.state.form['last-name'];
    const email = this.state.form['email'];
    const endpoint = `https://brickx.us8.list-manage.com/subscribe/post-json?u=c85d85c62b9a47be6dc88e065&id=0def2c9127&EMAIL=${email}&FNAME=${firstName}&LNAME=${lastName}&subscribe=Subscribe`;
    post(endpoint)
      .then((res) => res.json())
      .then((res) => {
        const result = res.result;
        if (result === 'error') {
          this.setState({ errorMsg: res.msg });
        } else {
          this.setState({ isSent: true, errorMsg: null });
        }
      });
  };
  onValidateForm = (form) => {
    const validateResult = !form
      .map(
        (ele) =>
          !ele.isRequired || (ele.isRequired && this.state.form[ele.id] !== '')
      )
      .includes(false);
    return validateResult;
  };
  render() {
    const formFields = [
      {
        id: 'first-name',
        type: 'string',
        validations: [
          { rule: 'min-length', min: 1 },
          { max: 30, rule: 'max-length' },
        ],
        serverErrors: [],
        isRequired: true,
      },
      {
        id: 'last-name',
        type: 'string',
        validations: [
          { rule: 'min-length', min: 1 },
          { max: 30, rule: 'max-length' },
        ],
        serverErrors: '',
      },
      {
        id: 'email',
        type: 'string',
        validations: [
          { rule: 'email' },
          {
            regex: '^[-a-zA-Z0-9_.+]+@[-a-zA-Z0-9]+\\.[-a-zA-Z0-9.]+$',
            hint: 'invalid-email',
            rule: 'regex',
          },
        ],
        serverErrors: '',
        isRequired: true,
      },
    ];
    const isFormValid = this.onValidateForm(formFields);
    return (
      <div>
        <TwoPlusOneFormLayout
          items={formFields.map((field) => (
            <FormInput
              key={field.id}
              field={field}
              value={this.state.form[field.id]}
              onChange={(val) => {
                const currentForm = this.state.form;
                currentForm[field.id] = val;
                this.setState({ form: currentForm });
              }}
              placeholderLabel
            />
          ))}
        />

        {this.state.isSent && (
          <SuccessBar>
            <div>Thanks for you interest!</div>
          </SuccessBar>
        )}
        {this.state.errorMsg && (
          <ErrorBar>
            <div>{this.state.error}</div>
          </ErrorBar>
        )}

        <PrimaryButton
          color={PrimaryButton.colors.SECONDARY}
          fullWidth
          testRef="submit-button"
          preventDefault
          disabled={!isFormValid}
          onClick={() => {
            this.onSubmit();
          }}
        >
          Register
        </PrimaryButton>
      </div>
    );
  }
}

export default RegistrationForm;
