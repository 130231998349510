import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import classNames from 'classnames';

import {
  property as propertyPropType,
  account as accountPropType,
  orderAggregated as orderPropType,
  user as userPropType,
  feeFreePromotionStatus as feeFreePromotionStatusPropType,
} from 'scripts/constants/PropTypes';

import { accountSelector } from 'scripts/redux/selectors/account';
import {
  feeFreePromotionStatusSelector,
  ordersSellSelector,
} from 'scripts/redux/selectors/market';
import {
  fetchFeeFreePromotionStatus,
  fetchOrders,
} from 'scripts/redux/actions/market';
import {
  sendRecentTransactionsTabClickEvent,
  sendBricksAvailableTabClickEvent,
  sendPlaceOrderButtonClickEvent,
} from 'scripts/redux/actions/segment/events/tradeEvents';

import OrderBookTrade from 'scripts/components/property/trade/OrderBookTrade';
import RecentlySold from 'scripts/components/property/RecentlySold';
import TradeProposalFormBuy from 'scripts/components/property/trade/TradeProposalFormBuy';
import BrickValuationBar from 'scripts/components/property/trade/BrickValuationBar';
import FundsAvailableBar from 'scripts/components/account/FundsAvailableBar';
import TradePanelBanner from 'src/components/promos/placements/TradePanelBanner';

import Trade from 'scripts/constants/Trade';
import Constants from 'scripts/constants/Constants';
import _ from 'lodash';
import { renderCode } from 'src/settings/properties';

const mapStateToProps = (state) => ({
  ...accountSelector(state),
  ...ordersSellSelector(state),
  ...feeFreePromotionStatusSelector(state),
});

@connect(mapStateToProps, {
  fetchFeeFreePromotionStatus,
  fetchOrders,
})
export default class TradePanelBuy extends Component {
  static propTypes = {
    user: userPropType.isRequired,
    account: accountPropType,
    property: propertyPropType.isRequired,
    ordersSell: PropTypes.arrayOf(orderPropType),
    getTotalBricksOwned: PropTypes.func.isRequired,
    fetchMyOrdersInfo: PropTypes.func.isRequired,
    handleClosePanelClick: PropTypes.func.isRequired,
    redirectTo: PropTypes.func.isRequired,
    feeFreePromotionStatus: feeFreePromotionStatusPropType,
    fetchFeeFreePromotionStatus: PropTypes.func.isRequired,
    fetchOrders: PropTypes.func.isRequired,
  };

  state = {
    mobileTabsArr: [true, false, false],
    numOfBricksAvailable: undefined,
    isFeeFreePromotionEnabled: true,

    proposalForm: {
      quantity: '',
      hasError: false,
      errorMsg: '',
      callback: (updatedProposalForm) => {
        this.setState({ proposalForm: updatedProposalForm });
      },
    },
  };

  componentDidMount() {
    const {
      property,
      user,
      fetchMyOrdersInfo,
      fetchOrders,
      fetchFeeFreePromotionStatus,
    } = this.props;
    fetchMyOrdersInfo(user);
    fetchFeeFreePromotionStatus();
    fetchOrders(property.propertyCode);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !_.isNil(nextProps.ordersSell) &&
      nextProps.ordersSell !== this.props.ordersSell
    ) {
      this._updateNumOfAvailableBricks(nextProps.ordersSell);
    }

    if (
      nextProps.feeFreePromotionStatus &&
      nextProps.feeFreePromotionStatus !== this.props.feeFreePromotionStatus
    ) {
      this._updateFeeFreePromotionStatus(nextProps.feeFreePromotionStatus);
    }
  }

  render() {
    const {
      user,
      account,
      property,
      ordersSell,
      getTotalBricksOwned,
      handleClosePanelClick,
      redirectTo,
    } = this.props;
    const {
      mobileTabsArr,
      numOfBricksAvailable,
      isFeeFreePromotionEnabled,
      proposalForm,
    } = this.state;

    const totalBricksOwned = getTotalBricksOwned(account);

    return (
      <div className="panel-financials-invest-shadow">
        <div
          className={classNames(
            'panel-financials-blue-header panel-financials-buy panel-financials-table'
          )}
        >
          <div className="header">
            <div className="row">
              <div className="panel-financials-title col-md-8 col-xs-8">{`Invest in ${renderCode(
                property.propertyCode
              )}`}</div>
              <div className="link col-xs-4 col-md-4 text-right">
                <span className="close-panel" onClick={handleClosePanelClick}>
                  <span>
                    <i className="fa fa-times-circle"></i>
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div className="buy-mobile-tabs visible-xs">
            <a
              className={classNames('col-xs-4 tab-button', {
                'active with-arrow-down': mobileTabsArr[0],
              })}
              onClick={() => this._onTabButtonClick(0)}
            >
              Bricks
              <br />
              Available to Buy
            </a>
            <a
              className={classNames('col-xs-4 tab-button', {
                'active with-arrow-down': mobileTabsArr[1],
              })}
              onClick={() => this._onTabButtonClick(1)}
            >
              Recent
              <br />
              Transactions
            </a>
            <a
              className={classNames('col-xs-4 tab-button', {
                'active with-arrow-down': mobileTabsArr[2],
              })}
              onClick={() => this._onTabButtonClick(2)}
            >
              Place
              <br />
              Order
            </a>
          </div>

          <div className="visible-xs">
            <TradePanelBanner />
          </div>

          <div className="body wrap-the-col">
            <div
              className={classNames('col-md-4 tab buy-panel-mobile-tab', {
                active: mobileTabsArr[0],
              })}
            >
              <OrderBookTrade
                account={account}
                property={property}
                ordersSell={ordersSell}
                tradeType={Trade.TRADE_TYPE.BUY}
              />

              {isFeeFreePromotionEnabled && (
                <div className="hidden-xs note fee-free-terms">
                  *See{' '}
                  <Link
                    target="_blank"
                    to="/terms/eofy2017"
                    className="text-underline"
                  >
                    Terms and Conditions
                  </Link>
                  .
                </div>
              )}
              <button
                className="button right-arrow-button orange-button visible-xs action-full-button"
                onClick={() => this._onTabButtonClick(2)}
              >
                PLACE ORDER
              </button>
            </div>

            <div
              className={classNames(
                'col-xs-12 tab buy-panel-mobile-tab recently-sold-tab',
                { active: mobileTabsArr[1] }
              )}
            >
              <div className="table-orders">
                <RecentlySold
                  property={property}
                  isPreOrderProperty={false}
                  tradeType={Trade.TRADE_TYPE.BUY}
                  account={account}
                />
                <button
                  className="button right-arrow-button orange-button visible-xs action-full-button"
                  onClick={() => this._onTabButtonClick(2)}
                >
                  PLACE ORDER
                </button>
              </div>
            </div>

            <div
              className={classNames('col-md-8 tab buy-panel-mobile-tab', {
                active: mobileTabsArr[2],
              })}
            >
              <div className="visible-xs">
                <FundsAvailableBar account={account} />
                <div className="col-md-12 visible-xs col-no-padding">
                  <p className="gray-line" />
                </div>
                <BrickValuationBar
                  property={property}
                  showIcon={Constants.TRUE}
                  showValuationDate={Constants.TRUE}
                />
              </div>

              <TradeProposalFormBuy
                user={user}
                property={property}
                account={account}
                numOfBricksAvailable={numOfBricksAvailable}
                totalBricksOwned={totalBricksOwned}
                proposalForm={proposalForm}
                redirectTo={redirectTo}
                isFeeFreePromotionEnabled={isFeeFreePromotionEnabled}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  _resetMobileTabs() {
    this.setState({
      mobileTabsArr: [false, false],
    });
  }

  _onTabButtonClick(index) {
    this._resetMobileTabs();
    const { mobileTabsArr } = this.state;
    this.setState({
      mobileTabsArr: _.map(mobileTabsArr, function (n, i) {
        return i === index ? true : false;
      }),
    });

    const { account, property } = this.props;
    if (index === 0) {
      sendBricksAvailableTabClickEvent(
        Trade.TRADE_TYPE.BUY,
        property.propertyCode,
        account
      );
    } else if (index === 1) {
      sendRecentTransactionsTabClickEvent(
        Trade.TRADE_TYPE.BUY,
        property.propertyCode,
        account
      );
    } else if (index === 2) {
      sendPlaceOrderButtonClickEvent(
        Trade.TRADE_TYPE.BUY,
        property.propertyCode,
        account
      );
    }
  }

  _updateNumOfAvailableBricks = (ordersSell) => {
    var numOfBricksAvailable = 0;
    for (var indexOfSell = 0; indexOfSell < ordersSell.length; indexOfSell++) {
      numOfBricksAvailable += ordersSell[indexOfSell].quantity;
    }

    this.setState({ numOfBricksAvailable: numOfBricksAvailable });
  };

  _updateFeeFreePromotionStatus = (feeFreePromotionStatus) => {
    this.setState({
      isFeeFreePromotionEnabled: _.isNil(feeFreePromotionStatus)
        ? false
        : feeFreePromotionStatus.enabled,
    });
  };
}
