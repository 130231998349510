import styled from 'styled-components';
import { SubHeading } from 'src/design/styleguide/Headings';
import { WelcomePageWidgetPanel } from 'src/components/dashboard/home/commonComponents';
import { breakpointSmall } from 'src/design/styleguide/common/breakpoints';
import { defineGridArea } from 'src/design/styleguide/common/styledComponentsUtils';
import { gridUnit } from 'src/design/styleguide/common/measurements';
import Image from 'src/design/components/image/Image';
import Paragraph from 'src/design/styleguide/Paragraph';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import { useState } from 'react';
import Styledlink from 'src/design/components/hyperlink/Styledlink';
import Divider from 'src/design/components/divider/Divider';
import { Formik, Form } from 'formik';
import RegistrationService from 'scripts/services/RegistrationService';
import AddressInput from 'src/design/components/addressInput/AddressInputV2';
import TextInput from 'src/design/components/textInput/TextInput';
import { rentToBuyUrl } from 'src/utils/pageUrls';
import _ from 'lodash';

const Container = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: ${gridUnit * 6}px;
  justify-items: center;

  ${breakpointSmall(`
    grid-template-columns: minmax(0, 1fr) auto;
    justify-items: start;
    align-items: start;
    ${defineGridArea('icon-area')}
    ${defineGridArea('button-area')}
    ${defineGridArea('text-area')}
    grid-template-areas:
      'icon-area button-area'
      'text-area text-area'
  `)}
`;

const StyledImageLg = styled(Image)`
  width: 100px;
`;

const PromoTextArea = styled.div`
  max-width: 500px;

  ${breakpointSmall(`
    max-width: 250px;
  `)}
`;

const PromoTitle = styled(SubHeading).attrs({
  as: 'h3',
  fontSize: '2x-small',
  fontWeight: 'bold',
})`
  ${breakpointSmall(`
    text-align: left;
  `)}
`;

const PromoText = styled(Paragraph).attrs({
  fontSize: '3x-small',
  align: 'center',
})`

  ${breakpointSmall(`
    text-align: left;
  `)}
`;

const FormContainer = styled.div`
.button-group {
  display: flex;
  button {
    margin-right: 10px;
  }
}
`;

/**
 *
 * Dashboard Panel :: Smart Renter Application
 *
 * @param {*} param0
 * @returns
 */
const SmartRenterPanel = ({ className, user, smartRenterDetails }) => {
  const [edit, setEdit] = useState(false);
  const [hasRegistered, setRegistered] = useState(false);

  if (!smartRenterDetails) {
    return (
      <WelcomePageWidgetPanel className={className} title="Smart Renter" testRef="smart-renter-panel">
        <Container>
          <StyledImageLg className="icon-area" src="https://assets.brickx.com/images/illustration-rent-to-buy-waitlist.svg" alt="illustration of a house on a sunny day"/>
          <PromoTextArea className="text-area">
            <Spacing bottom="small">
              <PromoTitle>Purchase your Home sooner!</PromoTitle>
            </Spacing>
            <Spacing bottom="small">
              <PromoText>BrickX Smart Renter helping you get a step closer to owning your own home!</PromoText>
            </Spacing>
            <PromoText><Styledlink href={rentToBuyUrl()} target="_blank">Learn more</Styledlink></PromoText>
          </PromoTextArea>
          <PrimaryButton
            className="button-area"
            color={PrimaryButton.colors.PURPLE}
            size={PrimaryButton.sizes.SMALL}
            link="http://eepurl.com/hXDcUf"
            target="_blank"
          >
            Register Interest
          </PrimaryButton>
        </Container>
      </WelcomePageWidgetPanel>
    );
  }

  if (smartRenterDetails.APP_STATUS || hasRegistered) {
    return (
      <WelcomePageWidgetPanel className={className} title="Smart Renter" testRef="smart-renter-panel">
        <Container>
          <StyledImageLg className="icon-area" src="https://assets.brickx.com/images/illustration-rent-to-buy-waitlist.svg" alt="illustration of a house on a sunny day"/>
          <PromoTextArea>
            { (smartRenterDetails.APP_STATUS === 'SUBMITTED' || hasRegistered) && <PromoText>Your property has been successfully submitted and is being reviewed by BrickX. We will be in touch with you soon.</PromoText> }
          </PromoTextArea>
        </Container>
      </WelcomePageWidgetPanel>
    );
  }

  const submittable = (values) => (
    values.propertyAddressStreet &&
    values.propertyAddressSuburb &&
    values.propertyAddressState &&
    values.propertyAddressPostcode &&
    values.propertyMaximumDeposit &&
    values.propertyMaximumPurchase &&
    values.propertyLink
  );

  return (
    <WelcomePageWidgetPanel className={className} title="Smart Renter" testRef="smart-renter-panel">
      <Container>
        <StyledImageLg className="icon-area" src="https://assets.brickx.com/images/illustration-rent-to-buy-waitlist.svg" alt="illustration of a house on a sunny day"/>
        <PromoTextArea className="text-area">
          <PromoTitle>Thanks for registering your interest!</PromoTitle>
          <Spacing top="x-small">
            <PromoText>If you are ready to start your BrickX Smart Renter journey, you can now propose a property for approval</PromoText>
          </Spacing>
        </PromoTextArea>
        {
          !edit &&
          <PrimaryButton
          className="button-area"
          color={PrimaryButton.colors.PURPLE}
          size={PrimaryButton.sizes.SMALL}
          onClick={() => setEdit(true)}
          testRef="wholesale-promo-panel-cta"
        >
          Propose a Property
        </PrimaryButton>
        }
      </Container>
      {
        edit && (<FormContainer>
          <Divider />
          <Spacing bottom="2x-large">
            <Paragraph fontSize="3x-small" >
              Please enter details of the property you would like to propose below:
            </Paragraph>
          </Spacing>
          <Formik
            initialValues={{
              'propertyAddressStreet': '',
              'propertyAddressSuburb': '',
              'propertyAddressState': '',
              'propertyAddressPostcode': '',
              'propertyMaximumDeposit': null,
              'propertyMaximumPurchase': null,
              'propertyLink': null
            }}
            onSubmit={async (values) => {
              const result = await RegistrationService.applySmartRenter({
                accountId: user.id,
                ...{
                  propertyAddressStreet: values.propertyAddressStreet,
                  propertyAddressSuburb: values.propertyAddressSuburb,
                  propertyAddressState: values.propertyAddressState,
                  propertyAddressPostcode: values.propertyAddressPostcode,
                  propertyMaximumDeposit: values.propertyMaximumDeposit,
                  propertyMaximumPurchase: values.propertyMaximumPurchase,
                  propertyLink: values.propertyLink
                }
              });
              if (result && result === 'ok') {
                setRegistered(true);
                setEdit(false);
              }
            }}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <Paragraph fontSize="3x-small" lineHeight="small">Property Address:</Paragraph>
                <Spacing bottom="x-samll">
                  <AddressInput
                    value={values.fullAddress}
                    onChange={(v) => {
                      setFieldValue('fullAddress', v);
                    }}
                    onSuggestionSelect={(v) => {
                      setFieldValue('propertyAddressStreet', _.first(v.fullAddress.split(',')) || '');
                      setFieldValue('propertyAddressSuburb', v.locality);
                      setFieldValue('propertyAddressState', v.state);
                      setFieldValue('propertyAddressPostcode', v.postcode);
                      setFieldValue('fullAddress', v.fullAddress);
                    }}
                  />
                </Spacing>
                <Spacing top="large"><Paragraph fontSize="3x-small">Property Listing Page:</Paragraph></Spacing>
                <Spacing bottom="x-small">
                  <TextInput
                    placeholder="https://"
                    value={values.propertyLink}
                    onChange={(v) => setFieldValue('propertyLink', v)}
                    fullWidth
                  />
                </Spacing>
                <Spacing top="large"><Paragraph fontSize="3x-small">Maximum Purchase Price:</Paragraph></Spacing>
                <Spacing bottom="x-small">
                  <TextInput
                    type="number"
                    placeholder="eg. 1000000"
                    value={values.propertyMaximumPurchase}
                    onChange={(v) => setFieldValue('propertyMaximumPurchase', v)}
                    fullWidth
                  />
                </Spacing>
                <Spacing top="large"><Paragraph fontSize="3x-small">Your Available Deposit:</Paragraph></Spacing>
                <Spacing bottom="x-small">
                  <TextInput
                    placeholder="eg. 100000"
                    value={values.propertyMaximumDeposit}
                    onChange={(v) => setFieldValue('propertyMaximumDeposit', v)}
                    fullWidth
                  />
                </Spacing>
                <Spacing top="x-large">
                  <div className="button-group">
                    <PrimaryButton
                      className="button-area"
                      color={PrimaryButton.colors.PURPLE}
                      size={PrimaryButton.sizes.SMALL}
                      testRef="wholesale-promo-panel-cta"
                      type="submit"
                      disabled={!submittable(values)}
                    >
                      Submit
                    </PrimaryButton>
                    <PrimaryButton
                      className="button-area"
                      color={PrimaryButton.colors.PURPLE}
                      size={PrimaryButton.sizes.SMALL}
                      onClick={() => setEdit(false)}
                      testRef="wholesale-promo-panel-cta"
                    >
                      Cancel
                    </PrimaryButton>
                  </div>
                </Spacing>
              </Form>
            )}
            </Formik>
          </FormContainer>
        )
      }
    </WelcomePageWidgetPanel>
  );
};

export default SmartRenterPanel;
