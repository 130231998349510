import { Component } from 'react';
import MediaEnquiriesMeta from 'src/components/meta/MediaEnquiriesMeta';

export default class MediaEnquiries extends Component {
  render() {
    return (
      <div id="media-enquiries">
        <MediaEnquiriesMeta/>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>Media Enquiries</h1>
            </div>
            <div className="col-md-6">
              <div className="me-image"></div>
            </div>
            <div className="col-md-6">
              <p>
                <b>Email</b>: media@brickx.com
              </p>
              <p>
                We're proud of what we are doing and we are always happy to spread the word!
              </p>
              <p>
                If you have a media related enquiry or thoughts on how our brands can work together, please contact us at media@brickx.com.
              </p>
              <p><a href="https://assets.brickx.com/media/BrickX+Media+Support+Pack.pdf" download="BrickX+Media+Support+Pack.pdf"><i className="fa fa-download"></i> Media Support Material</a></p>
              <p><a href="/media">See Media Coverage</a></p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
