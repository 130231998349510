import DocumentMeta from 'react-document-meta';
import { TERMS_META } from 'scripts/metadata/statics';


const AcornsCampaignTerms = () => (
  <div className="container">
    <DocumentMeta {...TERMS_META} />
    <div className="row">
      <div className="col-lg-12 terms-page">
        <h1>Bonus $15 offer terms and conditions for Acorns email</h1>
        <ol>
          <li><p>This promotion is offered by The Brick Exchange Pty Limited (ABN 27 600 762 749) (BrickX) as the manager of the BrickX Platform (ARSN 613 156 433). Theta Asset Management Ltd (ABN 37 071 807 684, AFSL 230920) (Theta) is the product issuer for the BrickX Platform, but is not responsible for offering or administering this BrickX promotion.</p></li>
          <li><p>The $15 bonus payment is being offered, and will be fully funded by BrickX from its own funds and will not be sourced from the assets of the BrickX Platform or any BrickX Trust.</p></li>
          <li><p>This promotion is only available to prospective members aged over 18, who are Australian residents who do not have an existing account with BrickX regardless of whether or not they hold an Interest in the BrickX Platform and who have an existing account with Acorns and have received the promotional Acorns link in the promotional email (‘eligible clients’).</p></li>

          <li><p>To receive the $15 bonus into their Acorns account, eligible clients must sign up to the BrickX website using the promotional Acorns link provided in the promotional email and become a Brick Holder within 30 days.</p></li>

          <li><p>The promotional Acorns link must not be forwarded.</p></li>

          <li><p>The $15 bonus will be paid into the Acorn accounts of eligible clients on the 31 March 2018. For queries relating to the payment of the $15 bonus please contact Acorns at support@acornsgrow.com.au. An Acorns account in the name of the eligible client must be open at the time the $15 bonus payment is made. There is no cash or cash equivalent.</p></li>

          <li><p>The $15 bonus payment is limited to one payment per individual and cannot be used in conjunction with any other promotion or offer.</p></li>

          <li><p>This promotion closes at 12pm AEDT 24 March 2018.</p></li>

          <li><p>Any first purchases of Bricks by eligible clients after 12pm AEDT 24 March 2018 will not qualify for the $15 bonus offer.</p></li>

          <li><p>BrickX has not considered the tax implications of the $15 bonus payment.</p></li>

          <li><p>BrickX reserves the right to withdraw this promotion at any time.</p></li>

          <li><p>This promotion is not open to BrickX, Acorns or Theta employees or their immediate families.</p></li>

          <li><p>All questions relating to this promotion should be directed to BrickX at info@brickx.com.</p></li>

          <li><p>Capitalised terms have the meaning given in the BrickX Platform product disclosure statement unless otherwise defined.</p></li>

          <li><p>Please note that BPAY payments can take up to 1-2 business days to be received and appear as cleared funds in your Digital Wallet. Payments can also be made through POLi to provide instant funds (up to $5000).</p></li>
        </ol>
      </div>
    </div>
  </div>
);

export default AcornsCampaignTerms;
