import { Link } from 'react-router';
import { connect } from 'react-redux';
import DocumentMeta from 'react-document-meta';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { DISCLOSURES_META } from 'scripts/metadata/statics';
import {
  averageAnnualGrowthMetricsSelector,
} from 'scripts/redux/selectors/historicalGrowth';
import {
  fetchHistoricalGrowthMetrics as fetchHistoricalGrowthMetricsAction,
} from 'scripts/redux/actions/historicalGrowth';
import { fetchProperties } from 'scripts/redux/actions/properties';
import { getAvgInvestmentMetrics } from 'scripts/utilities/calculator/calculators';
import { percentDecimal } from 'scripts/utilities/formatters';
import { propertiesSelector } from 'scripts/redux/selectors/properties';
import { property as propertyPropType } from 'scripts/constants/PropTypes';
import Loading from 'src/components/loading/Loading';


const mapStateToProps = (state) => ({
  ...propertiesSelector(state),
  ...averageAnnualGrowthMetricsSelector(state),
});
@connect(mapStateToProps, {
  fetchProperties,
  fetchHistoricalGrowthMetrics: fetchHistoricalGrowthMetricsAction,
})
export default class ReturnsCalculatorDisclosures extends Component {
  static propTypes = {
    properties: PropTypes.arrayOf(propertyPropType).isRequired,
    fetchProperties: PropTypes.func.isRequired,
    averageAnnualGrowthMetrics: PropTypes.object,
    fetchHistoricalGrowthMetrics: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.fetchProperties();
    this.props.fetchHistoricalGrowthMetrics();
  }

  render() {
    const { properties, averageAnnualGrowthMetrics } = this.props;
    if (_.isEmpty(properties) || !averageAnnualGrowthMetrics) {
      return <Loading/>;
    }

    const avgInvestmentMetrics = getAvgInvestmentMetrics(properties, averageAnnualGrowthMetrics);
    const avgNetRentalYield = avgInvestmentMetrics.rentalYield;
    const avgAnnualGrowth = avgInvestmentMetrics.growthRate;
    const avgDebt = avgInvestmentMetrics.debtRate;
    return (
      <div className="container">
        <DocumentMeta {...DISCLOSURES_META} />

        <div className="section banner">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <p className="text-center consider-pds">BrickX Calculator Disclaimer, Assumptions and Qualifications</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 terms-page">
            <h1>BrickX Calculator Disclaimer</h1>

            <p>
              The BrickX Calculator is for illustrative purposes only and actual performance of Bricks will differ. See the Help Centre to learn more about what factors can influence the price of Bricks.
            </p>

            <p>
              The BrickX Calculator has been prepared without taking into account your objectives, financial situation or needs (including tax circumstances). You should read the&nbsp;
              <Link className="clickable" to="/pds" target="_blank">
                product disclosure statement
              </Link>
              &nbsp;to consider if an investment in Bricks is right for you before making any investment decision.
            </p>
            <p>
              The results generated by the BrickX Calculator depend on the inputs you provide for the variables and the assumptions and qualifications set out below.
            </p>

            <p>
              The assumptions, and market conditions, can change without notice. The BrickX Calculator relies on historical property information and market trends in order to provide illustrative returns, however past performance is not a reliable indicator of future performance. Inflation may also reduce investment returns.
            </p>

            <p>
              Any distributions payable may form part of your assessable income for tax purposes and you may make a capital gain or capital loss on the disposal of your Bricks.
            </p>

            <p>
              The BrickX Calculator has been prepared, and is operated and updated by The Brick Exchange Pty Limited (ABN 27 600 762 749) (BrickX) as the manager of the BrickX Platform (ARSN 613 156 433) and authorised representative (No. 001000043) of BrickX Financial Services Limited. BrickX Financial Services Limited (ABN 67 616 118 088) (AFSL 494878) (BrickX Financial Services) is the product issuer of the BrickX Platform.
            </p>


            <h1>BrickX Calculator Assumptions and Qualifications</h1>

            <p>
              All figures are in Australian dollars and have not been adjusted for inflation.
            </p>

            <p>
              <b>1. Net Rental Yield</b> - the net rental yield is assumed as being constant, and assumes no rental increase, inflation or periods of no rental income. Actual net rental yield could fluctuate, could be outside the range shown in the calculator and, if outgoings exceed income, could be zero.  It is assumed that monthly distributions are not reinvested. The net rental yield assumes rental distributions are made after the deduction of all fees and expenses. See the Help Centre on&nbsp;
              <Link className="clickable" to="http://help.brickx.com/support/solutions/articles/17000074571-how-do-i-receive-distributions-" target="_blank">
              ‘How are distributions calculated and paid?’
              </Link>
            </p>

            <p>
              <b>2. Annual Capital Returns</b> - the BrickX Calculator compounds annual capital returns and assumes a constant level of capital return from year to year. Actual capital returns can be positive or negative, and could be outside of the range shown in the BrickX Calculator. The Historical Suburb Growth data is sourced monthly from CoreLogic. Annual capital returns for the individual BrickX properties will not necessarily correlate with overall suburb performance and therefore future annual capital returns of any property may not reflect the Historical Suburb Growth. Past performance is not a reliable indicator of future performance and growth could be negative.
            </p>

            <p>
              <b>3. Acquisition costs</b> – the BrickX Calculator assumes acquisition costs are 4% of initial investment (excluding any debt) and they are amortised over 5 years. See the Help Centre for further information on&nbsp;
              <Link className="clickable" to="http://help.brickx.com/support/solutions/articles/17000077280-what-are-the-property-acquisition-costs-and-the-cash-reserve-" target="_blank">
                Property Acquisition Costs
              </Link>
              .
            </p>

            <p>
              <b>4. Cash reserve</b> – the BrickX Calculator assumes a 1% cash reserve balance of initial investment (excluding any debt). See the Help Centre for further information on the&nbsp;
              <Link className="clickable" to="http://help.brickx.com/support/solutions/articles/17000077280-what-are-the-property-acquisition-costs-and-the-cash-reserve-" target="_blank">
                Cash Reserve
              </Link>
              .
            </p>

            <p>
              <b>5. % of Debt</b> - this is the amount of debt as a percentage of the initial investment amount. Over time, the debt to value ratio will change as the value of the property changes. For example, if the property value increases and the debt amount remains constant the debt to value ratio will decrease.  The BrickX Calculator assumes no changes in interest rates, loan terms or any repayment of principal over the full investment period. If interest rates and loan terms change during the investment period, this may cause corresponding fluctuations in returns.
            </p>

            <p>
              <b>6. Impact of debt on distributions</b> - properties that are partially debt funded will generally receive lower distributions than properties with no debt as the repayment of loan interest is made from the Gross Rental Income ahead of any distributions being paid. See the Help Centre on &nbsp;
              <Link className="clickable" to="http://help.brickx.com/support/solutions/articles/17000076832-what-is-a-geared-property-" target="_blank">
                ‘Debt and Gearing’
              </Link>
              &nbsp;for further information. If you choose to increase the amount of ‘% of Debt’ in the BrickX Calculator, the BrickX Calculator does not automatically reduce the ‘Net Rental Yield’. The ‘Net Rental Yield’ figure you may receive where debt funding is used may be less if you were to invest in Bricks.
            </p>

            <p>
              <b>7. Estimated Return</b> - the estimated return is expressed as a dollar figure and is a percentage of your original chosen investment amount. The estimate is annualised and based on compound growth.
            </p>

            <p>
              <b>8. Additional Returns from Debt</b> – this is the impact of debt on the Brick Price. Gearing a property amplifies the impact of the Brick Price movements both up and down. See the Help Centre on&nbsp;
              <Link className="clickable" to="http://help.brickx.com/support/solutions/articles/17000076832-what-is-a-geared-property-" target="_blank">
                ‘Debt and Gearing’
              </Link>
              &nbsp;for further details. The BrickX Calculator provides an average gearing effect over the chosen investment period, based on the assumptions about the change in the property value.
            </p>

            <p>
              <b>9. Estimated Investment Value (at exit)</b> - the BrickX Calculator assumes that you hold your Bricks for the full selected investment period and they are sold at the end of the investment period at the Brick Valuation. There may be a difference between Brick Price and the Brick Valuation.  Your expected returns will be lower if the Brick Price you receive is lower than the Brick Valuation. See the Help Centre on&nbsp;
              <Link className="clickable" to="http://help.brickx.com/support/solutions/articles/17000074565-what-determines-the-price-of-a-brick-" target="_blank">
                ‘Brick Prices and Valuation’
              </Link>
              &nbsp;for further details.
            </p>

            <p>
              <b>10.</b> As at {moment().format('DD-MM-YYYY HH:mm')}, the following averages apply where the “Load averages” option is used:
              <br/>
              <ul>
                <p>Net Brick Rental Yield, {avgNetRentalYield::percentDecimal()}</p>
                <p>Annual Capital Returns, {avgAnnualGrowth::percentDecimal()}</p>
                <p>Debt, {avgDebt::percentDecimal()}</p>
              </ul>
              Note these averages are subject to change based on current Brick Price and latest property valuations.
            </p>

            <p>
              <b>11.</b> The BrickX Calculator assumes you will be able to sell your Bricks at the end of the investment period at Brick Valuation. See the&nbsp;
              <Link className="clickable" to="/pds" target="_blank">
                PDS
              </Link>
              &nbsp;for further information about selling Bricks.
            </p>

          </div>
        </div>
      </div>
    );
  }
}
