import React from 'react';
import styled from 'styled-components';

const Layout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 303px;
  text-align: center;
  color: var(--bx-color---grey-700);
`;

const PropertyCardPromoBody = ({ body }) => {
  return <Layout>{body}</Layout>;
};

export default PropertyCardPromoBody;
