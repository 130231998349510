import { Fragment } from 'react';
import RatioContainer from 'scripts/components/shared/RatioContainer';

const VideoGuts = ({ videoId, autoplay }) => (<Fragment>
  <iframe
    id={`youtube-player-iframe-${videoId}`}
    style={{ display: 'block', background: 'transparent' }}
    src={`//www.youtube.com/embed/${videoId}?showinfo=0&enablejsapi=1&autoplay=${autoplay ? '1' : '0'}&modestbranding=1&playsinline=1&rel=0`}
    frameBorder="0"
    allow="autoplay; encrypted-media"
    allowFullScreen
    allowscriptaccess="always"
  />
</Fragment>);

const Video = ({ className, videoId, autoplay, onPlay, onFinish, unload }) => (
  <RatioContainer className={className}>
    {!unload && <VideoGuts videoId={videoId} autoplay={autoplay} onPlay={onPlay} onFinish={onFinish} />}
  </RatioContainer>
);

export default Video;
