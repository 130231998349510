import _ from 'lodash';
import Configuration from 'scripts/constants/Configuration';

const mockStorage = {
  map: {},
  setItem(key, value) {
    mockStorage.map[key] = value;
  },
  getItem(key) {
    return mockStorage.map[key];
  },
};

function fallback(storage) {
  const TEST_KEY = 'test';
  try {
    this.setItem(TEST_KEY, TEST_KEY);
    this.removeItem(TEST_KEY);
    return this;
  } catch (error) {
    return storage;
  }
}

export default class Storage {
  constructor(storage) {
    this.storage = storage::fallback(mockStorage);
  }

  /** ~~~~~~~~~~~~    B A S I C S    ~~~~~~~~~~~~ */

  setItem(key, value) {
    this.storage.setItem(key, JSON.stringify(value));
  }

  getItem(key, defaultValue = null) {
    const value = this.storage.getItem(key);
    if (_.isString(value) && value.length > 0) {
      return JSON.parse(value);
    }
    return defaultValue;
  }

  removeItem(key) {
    this.storage.removeItem(key);
  }

  /** ~~~~~~~~~~~    L O G I N    ~~~~~~~~~~~~ */

  /**
   * Get current login user trait
   *
   * @returns
   */
  getCurrentUserTraits() {
    return this.getItem(Configuration.LOCAL_STORAGE.CURRENT_USER);
  }

  /**
   * Get exising list of accounts
   *
   * @return {list} list of account details
   */
  getAccountDetails() {
    return this.getItem(Configuration.LOCAL_STORAGE.EXISTING_ACCOUNTS) || [];
  }

  /**
   * save user details to account list for multi-account login
   *
   * @param {object} user A JSON format contains user detais
   * @return null
   */
  saveAccountDetails(user) {
    if (user && user.email) {
      const accounts =
        this.getItem(Configuration.LOCAL_STORAGE.EXISTING_ACCOUNTS) || [];
      const details = {
        email: user.email,
        givenName: user.givenName,
        accountType: user.accountType,
      };
      if (
        !_.includes(
          accounts.map((e) => e.email),
          user.email
        )
      ) {
        this.setItem(Configuration.LOCAL_STORAGE.EXISTING_ACCOUNTS, [
          ...accounts,
          details,
        ]);
      }
    }
  }

  /**
   * Delete all added accounts
   */
  removeAllAccountDetails() {
    this.setItem(Configuration.LOCAL_STORAGE.EXISTING_ACCOUNTS, []);
  }
}
