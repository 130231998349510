import moment from 'moment';
import withSubmittableForm from 'src/decorators/withSubmittableForm';
import {
  submitIdentityVerificationForm as submitIdentityVerificationFormAction,
  submitMinorIdentityVerificationForm as submitMinorIdentityVerificationFormAction,
  submitSmsfIdentityVerificationForm as submitSmsfIdentityVerificationFormAction,
  identityVerificationFormErrorsCleared as identityVerificationFormErrorsClearedAction,
} from 'scripts/redux/actions/registration';
import { userSelector } from 'scripts/redux/actions/user';
import {
  identityVerificationFormErrorsSelector,
  identityVerificationFormSubmittingSelector,
} from 'src/store/identityVerificationFormReducer';
import IdentityVerificationForm from 'src/components/oldOnboarding/identityVerification/IdentityVerificationForm';
import { ACCOUNTTYPES } from 'src/settings/registration';

export default withSubmittableForm({
  Form: IdentityVerificationForm,
  mapStateToProps: (state) => ({
    ...userSelector(state),
    ...identityVerificationFormErrorsSelector(state),
    ...identityVerificationFormSubmittingSelector(state),
  }),
  mapDispatchToProps: {
    submitIdentityVerificationForm: submitIdentityVerificationFormAction,
    submitMinorIdentityVerificationForm:
      submitMinorIdentityVerificationFormAction,
    submitSmsfIdentityVerificationForm:
      submitSmsfIdentityVerificationFormAction,
    identityVerificationFormErrorsCleared:
      identityVerificationFormErrorsClearedAction,
  },
  mapPropsToInitialFormValues: ({ user }) => ({
    givenName: user.givenName,
    middleName: '',
    familyName: '',
    address: '',
    mobilePhone: '',
    usCitizen: '',
    tfn: '',
    isWholesale: false,
    certificateExpired: null,
    certificate: null,
    certificateLabel: null,
    questionnaire: JSON.stringify({}),
  }),
  onSubmit: ({ formValues, props }) => {
    const {
      submitIdentityVerificationForm,
      submitMinorIdentityVerificationForm,
      submitSmsfIdentityVerificationForm,
      user,
    } = props;
    const {
      dayOfBirth,
      monthOfBirth,
      yearOfBirth,
      certificateExpired,
      ...otherFormValues
    } = formValues;
    if (user.accountType === ACCOUNTTYPES.MINOR.toUpperCase()) {
      const formData = {
        storePayloadForVedaRetry: true,
        id: user.id,
        dateOfBirth: moment({
          year: yearOfBirth,
          month: monthOfBirth - 1,
          day: dayOfBirth,
        }).format('DD-MM-YYYY'),
        minorDateOfBirth: moment({
          year: formValues.minorYearOfBirth,
          month: formValues.minorMonthOfBirth - 1,
          day: formValues.minorDayOfBirth,
        }).format('DD-MM-YYYY'),
        australianTaxResidency: true,
        ...otherFormValues,
      };
      return submitMinorIdentityVerificationForm(formData);
    }
    if (
      user.accountType === ACCOUNTTYPES.SMSF.toUpperCase() ||
      user.accountType === ACCOUNTTYPES.TRUST.toUpperCase()
    ) {
      const formData = {
        storePayloadForVedaRetry: true,
        id: user.id,
        dateOfBirth: moment({
          year: yearOfBirth,
          month: monthOfBirth - 1,
          day: dayOfBirth,
        }).format('DD-MM-YYYY'),
        australianTaxResidency: true,
        ...otherFormValues,
      };
      return submitSmsfIdentityVerificationForm(formData);
    }
    const formData = {
      storePayloadForVedaRetry: true,
      id: user.id,
      dateOfBirth: moment({
        year: yearOfBirth,
        month: monthOfBirth - 1,
        day: dayOfBirth,
      }).format('DD-MM-YYYY'),
      australianTaxResidency: true,
      certificateExpired: '01-01-2099',
      ...otherFormValues,
    };
    return submitIdentityVerificationForm(formData);
  },
});
