import { connect } from 'react-redux';
import { flowsFieldsSelector } from 'src/components/flows/store/flowsReducer';
import { updateField as updateFieldAction } from 'src/components/flows/store/flowsActions';

const getField = (fields, fieldId) => fields.find(({ id }) => id === fieldId);

const flowInput = (WrappedComponent) => connect(
  flowsFieldsSelector,
  { updateField: updateFieldAction }
)(({ fieldId, fields, updateField, ...props }) => {
  const field = getField(fields, fieldId);
  return (
    <WrappedComponent
      field={field}
      onChange={(value) => updateField(field.id, value)}
      value={field.value}
      {...props}
    />
  );
});

export default flowInput;
