import { Component } from 'react';
import { connect } from 'react-redux';
import DocumentMeta from 'react-document-meta';
import PropTypes from 'prop-types';
import { SETTINGS_META } from 'scripts/metadata/account';
import { accountSelectorV2 } from 'scripts/redux/selectors/account';
import {
  fetchAccountInfoV2,
} from 'scripts/redux/actions/account';
import {
  fetchSmartInvestDetails as fetchSmartInvestDetailsAction,
  smartInvestSelector,
} from 'scripts/redux/actions/smartInvest';
import { getDirectDebitDetailsFromApiResponse } from 'src/utils/directDebit';
import {
  getSmartInvestPlan,
  planIsActive,
  planWasNeverSignedUpTo,
} from 'src/utils/account';
import { getUserBankAccountDetails } from 'src/utils/user';
import { longDate } from 'src/utils/formats';
import {
  onboardingSmartInvestDetailsUrl,
  settingsEnableUrl,
  settingsManageUrl,
  settingsSkipAMonthUrl,
  settingsDisableUrl,
} from 'src/utils/pageUrls';
import { user as userPropType } from 'scripts/constants/PropTypes';
import { userSelector } from 'scripts/redux/selectors/user';
import ChangeBankDetailsInfoBox from 'src/components/settings/ChangeBankDetailsInfoBox';
import Divider from 'src/design/components/divider/Divider';
import FinePrint from 'src/design/components/bodyText/FinePrint';
import OldGeneralSettingsPanel from 'src/components/settings/OldGeneralSettings';
import Paragraph from 'src/design/components/bodyText/Paragraph';
import SecondaryButton from 'src/design/components/button/SecondaryButton';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import StyledBodylink from 'src/design/components/hyperlink/StyledBodylink';
import SubHeading from 'src/design/components/heading/SubHeading';
import Tabs from 'src/design/components/tabs/Tabs';
import TermsList from 'src/components/settings/TermsList';
import WidgetPanel from 'src/components/dashboard/common/WidgetPanel';
import history from 'src/browser/history';
import styles from 'src/components/settings/Settings.mscss';


const EnableSmartInvestControl = ({ className, link }) => (
  <div className={className}>
    <Spacing bottom="normal" top="x-large">
      <SubHeading>Enable Smart Invest</SubHeading>
    </Spacing>
    <Spacing bottom="normal">
      <Paragraph>Easily start Smart Invest by enabling it below.</Paragraph>
    </Spacing>
    <SecondaryButton
      color={SecondaryButton.colors.PRIMARY}
      size={SecondaryButton.sizes.EXTRA_SMALL}
      link={link}
      testRef="enable-si-button"
    >Enable</SecondaryButton>
  </div>
);

const SmartInvestSettingsControls = ({ className, user, smartInvest }) => {
  const { bankAccountName, bankAccountNumber, BSB } = getUserBankAccountDetails(user);
  const {
    monthlyAmount,
    nextDebitDate,
  } = getDirectDebitDetailsFromApiResponse(smartInvest);

  return (
    <div className={className} data-test-reference="smart-invest-settings-tab">
      <Spacing bottom="normal" top="x-large">
        <SubHeading>Need to miss a month?</SubHeading>
      </Spacing>
      <Spacing bottom="normal">
        {smartInvest.skipEnabled
          ? <Paragraph>You requested to skip the next month.<br/>No direct debit will be taken on {longDate(smartInvest.nextDebitDate)}.</Paragraph>
          : <Paragraph>This will skip the next upcoming month (but we'll need 24 hours notice before the direct debit date).</Paragraph>}
      </Spacing>
      <SecondaryButton
        disabled={smartInvest.skipEnabled}
        color={SecondaryButton.colors.PRIMARY}
        size={SecondaryButton.sizes.EXTRA_SMALL}
        link={settingsSkipAMonthUrl()}
        testRef="skip-a-month-button"
      >Skip</SecondaryButton>

      <Divider />

      <Spacing bottom="normal">
        <SubHeading className={styles.subHeading}>Need to disable Smart Invest?</SubHeading>
      </Spacing>

      <Spacing bottom="normal">
        <Paragraph>This will stop your monthly direct debits and stop new Bricks from being purchased. <StyledBodylink href="http://help.brickx.com/support/solutions/articles/17000077278-how-do-i-disable-smart-invest-" testRef="learn-more-link">Learn more</StyledBodylink></Paragraph>
      </Spacing>

      <SecondaryButton
        color={SecondaryButton.colors.PRIMARY}
        size={SecondaryButton.sizes.EXTRA_SMALL}
        link={settingsDisableUrl()}
        testRef="disable-si-button"
      >Disable</SecondaryButton>

      <Divider />

      <Spacing bottom="normal">
        <SubHeading className={styles.subHeading}>Direct Debit Details</SubHeading>
      </Spacing>
      <TermsList keyPairs={[
        { name: 'Monthly amount', value: monthlyAmount, testRef: 'monthly-amount' },
        { name: 'Next debit', value: nextDebitDate, testRef: 'next-debit-date' },
      ]} />

      <SecondaryButton
        color={SecondaryButton.colors.PRIMARY}
        size={SecondaryButton.sizes.EXTRA_SMALL}
        link={settingsManageUrl()}
        testRef="manage-si-button"
      >Manage</SecondaryButton>

      <Divider />

      <Spacing bottom="normal">
        <SubHeading className={styles.subHeading}>Bank Account</SubHeading>
      </Spacing>
      <TermsList
        keyPairs={[
          { name: 'Account name', value: bankAccountName },
          { name: 'BSB', value: BSB },
          { name: 'Account number', value: bankAccountNumber },
        ]}
        testRef="bank-account-details-terms-list"
      />

      <Spacing bottom="large">
        <FinePrint>This account is also used for receiving withdrawals.</FinePrint>
      </Spacing>

      <ChangeBankDetailsInfoBox />

      <Divider />
    </div>
  );
};

const SmartInvestSettingsPanelPicker = (props) => {
  const smartInvestPlan = props.account && getSmartInvestPlan(props.account);

  const enableLink = planWasNeverSignedUpTo(smartInvestPlan)
    ? onboardingSmartInvestDetailsUrl()
    : settingsEnableUrl();

  return planIsActive(smartInvestPlan)
    ? <SmartInvestSettingsControls {...props} />
    : <EnableSmartInvestControl {...props} link={enableLink}/>;
};

const mapStateToProps = (state) => ({
  ...userSelector(state),
  ...smartInvestSelector(state),
  account: accountSelectorV2(state).account,
});

@connect(mapStateToProps, {
  fetchAccountInfoV2,
  fetchSmartInvestDetails: fetchSmartInvestDetailsAction,
})
export default class Settings extends Component {
  static propTypes = {
    user: userPropType,
    fetchAccountInfo: PropTypes.func,
    fetchAccountInfoV2: PropTypes.func,
    fetchSmartInvestDetails: PropTypes.func,
    smartInvest: PropTypes.object,
    account: PropTypes.object,
    location: PropTypes.shape({
      query: PropTypes.shape({
        activeTabId: PropTypes.string,
      }),
      pathname: PropTypes.string,
    }),
  };

  componentDidMount() {
    this.props.fetchAccountInfoV2();
    this.props.fetchSmartInvestDetails();
  }

  onTabChange = ({ index }) => {
    const { pathname } = this.props.location;
    history.replace(`${pathname}?activeTabId=${index}`);
  };

  render() {
    const { user, account, location, smartInvest } = this.props;

    if (!account) return null;

    const isSmartInvestActive = planIsActive(getSmartInvestPlan(account));
    let startFromPanel = isSmartInvestActive ? 0 : 1;

    // If query params are supplied - over-write the default starting tab:
    if (location.query && location.query.activeTabId) {
      startFromPanel = parseInt(location.query.activeTabId, 10);
    }

    return (
      <WidgetPanel title="Account Settings">
        <DocumentMeta {...SETTINGS_META} />
        <Tabs
          testRef="settings-tabs-control"
          startFromPanel={startFromPanel}
          onTabChange={this.onTabChange}
          panels={[{
            label: 'Smart Invest',
            panel: <SmartInvestSettingsPanelPicker
              className={styles.settings}
              user={user}
              account={account}
              smartInvest={smartInvest}
            />,
            labelTestRef: 'smart-invest-tabs-label',
          }, {
            label: 'General',
            panel: <OldGeneralSettingsPanel account={account} />,
            labelTestRef: 'general-tabs-label',
          }]}
        />
      </WidgetPanel>
    );
  }
}
