import { OnboardingFinishButton } from 'src/components/oldOnboarding/common/onboardingNextButton/OnboardingNextButton';
import { isBsbValid } from 'src/utils/user';
import BankDetailsScreenshotUpload from 'src/components/settings/bankDetailsScreenshotUpload/BankDetailsScreenshotUpload';
import BankNameProvider from 'src/decorators/BankNameProvider';
import DirectDebitExpandableAgreements from 'src/components/common/DirectDebitExpandableAgreements';
import DirectDebitRequestCheckbox from 'src/components/oldOnboarding/smartInvestDetails/bankDetailsInput/DirectDebitRequestCheckbox';
import DirectDebitRequestServiceAgreementCheckbox from 'src/components/oldOnboarding/smartInvestDetails/bankDetailsInput/DirectDebitRequestServiceAgreementCheckbox';
import FinePrint from 'src/design/components/bodyText/FinePrint';
import Heading from 'src/design/components/heading/Heading';
import InputError from 'src/design/components/inputError/InputError';
import OnboardingBackButton from 'src/components/oldOnboarding/common/onboardingBackButton/OnboardingBackButton';
import OnboardingScreen, {
  OnboardingScreenHeader,
  OnboardingScreenContent,
  OnboardingScreenContentWide,
  OnboardingFormFieldContainer,
  OnboardingFormLabel,
} from 'src/components/oldOnboarding/common/onboardingScreen/OnboardingScreen';
import Paragraph from 'src/design/components/bodyText/Paragraph';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import TextInput from 'src/design/components/textInput/TextInput';
import Styledlink from 'src/design/components/hyperlink/Styledlink';
import { bankDetailsUpdateUrl } from 'src/utils/pageUrls';


export const BankName = ({ bankName, error }) => {
  return error
    ? <InputError errorTestRef="bsb-input-error-msg" error="Error: BSB not recognised" />
    : <Spacing top="2x-small">
        <FinePrint testRef="bank-name">Bank: {bankName}</FinePrint>
      </Spacing>;
};

export const isBankNameValidCharacters = (value) => /^[a-zA-Z0-9\s\.&,-]*$/.test(value);

const BankDetailsInput = ({
  onBankAccountNameChange,
  onBankAccountBsbChange,
  onBankNameChange,
  onBankAccountNumberChange,
  onDirectDebitRequestChange,
  onDirectDebitRequestServiceAgreementChange,
  onBankStatementScreenshotSelected,
  values,
  saving,
  onNext,
  onBack,
  hasBankDetailsAlreadySet,
}) => {
  const {
    bankAccountName,
    bankAccountBsb,
    bankAccountNumber,
    bankName,
    agreeToDirectDebitRequest,
    agreeToDirectDebitRequestServiceAgreement,
    bankStatementScreenshot,
  } = values;
  const bankDetailsFilled = bankAccountName && bankAccountBsb && bankAccountNumber;
  const bsbRecognised = bankName || hasBankDetailsAlreadySet;
  const bankDetailsValid =
    bankAccountName.length >= 3
      && isBsbValid(bankAccountBsb)
      && bankAccountNumber.length >= 6
      && bsbRecognised;
  const checkBoxesTicked = agreeToDirectDebitRequest && agreeToDirectDebitRequestServiceAgreement;

  const disableBankDetailInputs = hasBankDetailsAlreadySet;
  const checkBoxesDisabled = !(bankDetailsFilled && bankDetailsValid);
  const saveDisabled = !checkBoxesTicked || checkBoxesDisabled || saving || !bankStatementScreenshot;
  const saveButtonLoading = !!saving;

  const title = hasBankDetailsAlreadySet
    ? 'Please confirm your bank details'
    : 'Which bank account would you like to use?';

  const subTitle = hasBankDetailsAlreadySet
    ? <span>To update your bank details, please <Styledlink href={bankDetailsUpdateUrl()}>click here</Styledlink>.</span>
    : 'You can edit your details anytime.';

  return (
    <OnboardingScreen>
      <OnboardingScreenHeader>
        <Spacing bottom="large">
          <Heading testRef="title">{title}</Heading>
        </Spacing>

        <Spacing bottom="large">
          <Paragraph testRef="subtitle">{subTitle}</Paragraph>
        </Spacing>
      </OnboardingScreenHeader>

      <OnboardingScreenContentWide>
        <OnboardingFormFieldContainer>
          <OnboardingFormLabel>Account Name</OnboardingFormLabel>
          <div className="onboarding-screen__form-field">
            <TextInput
              onChange={onBankAccountNameChange}
              value={bankAccountName}
              isCharacterValid={isBankNameValidCharacters}
              isStringValid={TextInput.validation.maxLength(32)}
              placeholder="John Smith"
              disabled={disableBankDetailInputs}
              testRef="bank-account-name-input"
            />
          </div>
        </OnboardingFormFieldContainer>

        <OnboardingFormFieldContainer>
          <OnboardingFormLabel>BSB</OnboardingFormLabel>
          <div className="onboarding-screen__form-field">
            <TextInput
              onChange={onBankAccountBsbChange}
              value={bankAccountBsb}
              isCharacterValid={TextInput.validation.numbersOnly}
              isStringValid={TextInput.validation.maxLength(6)}
              placeholder="XXXXXX (6 digits)"
              disabled={disableBankDetailInputs}
              testRef="bank-account-bsb-input"
            />
            <BankNameProvider bsb={bankAccountBsb} onChange={onBankNameChange}>
              <BankName/>
            </BankNameProvider>
          </div>
        </OnboardingFormFieldContainer>

        <OnboardingFormFieldContainer>
          <OnboardingFormLabel>Account Number</OnboardingFormLabel>
          <div className="onboarding-screen__form-field">
            <TextInput
              onChange={onBankAccountNumberChange}
              value={bankAccountNumber}
              isCharacterValid={TextInput.validation.numbersOnly}
              isStringValid={TextInput.validation.maxLength(9)}
              placeholder="XXXXXX (6 to 9 digits)"
              disabled={disableBankDetailInputs}
              testRef="bank-account-number-input"
            />
          </div>
        </OnboardingFormFieldContainer>

        <BankDetailsScreenshotUpload onChange={onBankStatementScreenshotSelected}/>

        <DirectDebitExpandableAgreements
          className="onboarding-screen__form-element--margin-16"
          testRef="bank-details-expandy-text"
        />

        <DirectDebitRequestCheckbox
          className="onboarding-screen__form-element--margin-16"
          onChange={onDirectDebitRequestChange}
          checked={agreeToDirectDebitRequest}
          disabled={checkBoxesDisabled}
          values={values}
          testRef="direct-debit-request-checkbox"
          labelTestRef="direct-debit-request-label"
        />

        <DirectDebitRequestServiceAgreementCheckbox
          className="onboarding-screen__form-element--margin-16"
          onChange={onDirectDebitRequestServiceAgreementChange}
          checked={agreeToDirectDebitRequestServiceAgreement}
          disabled={checkBoxesDisabled}
          testRef="direct-debit-request-service-agreement-checkbox"
          labelTestRef="direct-debit-request-service-agreement-label"
        />
      </OnboardingScreenContentWide>
      <OnboardingScreenContent>
        <OnboardingFinishButton onClick={onNext} disabled={saveDisabled} loading={saveButtonLoading} />
        <OnboardingBackButton onClick={onBack}/>
      </OnboardingScreenContent>
    </OnboardingScreen>
  );
};

export default BankDetailsInput;
