import { useState } from 'react';
import styled from 'styled-components';
import {
  AVERAGE_PRICE_POPOVER_TEXT,
  BRICK_VALUE_POPOVER_TEXT,
  CAPITAL_RETURNS_POPOVER_TEXT,
  DISTRIBUTION_PAID_POPOVER_TEXT,
  LOWEST_BRICK_PRICE_POPOVER_TEXT,
  TOTAL_ROI_POPOVER_TEXT,
  TOTAL_VALUE_POPOVER_TEXT
} from 'src/components/dashboard/portfolio/helpText';
import {
  BodyTableRow,
  ColumnHeading,
  FooterTableData,
  FooterTableHeading,
  MultiColumnHeadingText,
  SecondColumn,
  StickyHeader,
  Table,
  TableData,
  TableHeading,
} from 'src/design/components/table/Table';
import { dollarDecimal, floatToPercentDecimal } from 'src/utils/formats';
import { getThemeColor } from 'src/themes/themeUtils';
import { propertyUrl } from 'src/utils/pageUrls';
import Hyperlink from 'src/design/components/hyperlink/Hyperlink';
import Icon from 'src/design/components/icon/Icon';
import Popover from 'scripts/components/shared/Popover';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import StyledLink from 'src/design/components/hyperlink/Styledlink';
import UpDownNumber from 'src/components/upDownNumber/UpDownNumber';
import _ from 'lodash';
import { renderCode } from 'src/settings/properties';

const HouseIcon = styled(Icon).attrs({
  type: 'house',
})`
  color: ${getThemeColor('concrete')};
`;

const HouseIconLinkContainer = styled.span`
  display: flex;
  white-space: nowrap;
`;

const ColorInheritStyledLink = styled(StyledLink)`
  && {
    color: inherit;
  }
`;

const PropertyNameField = ({ propertyCode }) => (
  <HouseIconLinkContainer>
    <Spacing right="normal">
      <Hyperlink href={propertyUrl(propertyCode)}><HouseIcon/></Hyperlink>
    </Spacing>
    <ColorInheritStyledLink href={propertyUrl(propertyCode)}>
      {renderCode(propertyCode)}
    </ColorInheritStyledLink>
  </HouseIconLinkContainer>
);


const Layout = styled.div`

.headingBtn {
  padding: 10px 5px;
  transition: all .1s;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  &:hover {
    cursor: pointer;
    background-color: #f7f7f7;
  }
  i.fa-sort,
  i.fa-sort-up,
  i.fa-sort-down {
    margin-left: 10px;
    color: #999;
  }
}

`;

const PortfolioPropertiesTable = ({ portfolio, properties }) => {
  const [sortBy, setSortBy] = useState(null);
  const handleSort = (key) => {
    if (!sortBy) {
      setSortBy([key, 'asc']);
      return;
    }
    if (sortBy[0] === key) {
      if (sortBy[1] === 'asc') {
        setSortBy([key, 'desc']);
        return;
      }
      setSortBy(null);
      return;
    }
    setSortBy([key, 'asc']);
    return;
  };
  const renderSortIcon = (key) => {
    if (!sortBy) return <i className="fa fa-sort"></i>;
    if (sortBy[0] === key) {
      if (sortBy[1] === 'asc') return <i className="fa fa-sort-up"></i>;
      return <i className="fa fa-sort-down"></i>;
    }
    return <i className="fa fa-sort"></i>;
  };
  const rowsToShow = sortBy ? _.orderBy(portfolio.records, [sortBy[0]], [sortBy[1]]) : _.orderBy(portfolio.records, ['propertyCode']);
  return (
    <Layout>
      <Table withStickyHeader testRef="outer-container">
      <thead>
        <tr>
          <StickyHeader><td/></StickyHeader>
          <th colSpan="5" scope="colgroup"><MultiColumnHeadingText spaceRight>Holdings</MultiColumnHeadingText></th>
          <th colSpan="3" scope="colgroup"><MultiColumnHeadingText>Yields</MultiColumnHeadingText></th>
        </tr>
        <tr>
          <StickyHeader><ColumnHeading testRef="property-code">Property<br/>Code</ColumnHeading></StickyHeader>
          <SecondColumn><ColumnHeading className="headingBtn" onClick={() => handleSort('totalBricks')}>Quantity<br/>of Bricks{renderSortIcon('totalBricks')}</ColumnHeading></SecondColumn>
          <ColumnHeading className="headingBtn" testRef="average-price">Average Price<br/>Paid per Brick<br /><Popover placement="top" content={AVERAGE_PRICE_POPOVER_TEXT}/></ColumnHeading>
          <ColumnHeading className="headingBtn" testRef="lowest-price">Lowest Available<br/>Brick Price<br/><Popover placement="top" content={LOWEST_BRICK_PRICE_POPOVER_TEXT}/></ColumnHeading>
          <ColumnHeading className="headingBtn" testRef="brick-value" onClick={() => handleSort('brickValue')}>Brick<br/>Value{renderSortIcon('brickValue')}<br/><Popover placement="top" content={BRICK_VALUE_POPOVER_TEXT}/></ColumnHeading>
          <ColumnHeading className="headingBtn" testRef="total-value" onClick={() => handleSort('totalBrickValue')}>Total Value<br/>of Bricks{renderSortIcon('totalBrickValue')}<br/><Popover placement="top" content={TOTAL_VALUE_POPOVER_TEXT}/></ColumnHeading>
          <ColumnHeading className="headingBtn" testRef="capital-returns" onClick={() => handleSort('capitalReturns')}>Capital<br/>Returns{renderSortIcon('capitalReturns')}<br /><Popover placement="top" content={CAPITAL_RETURNS_POPOVER_TEXT}/></ColumnHeading>
          <ColumnHeading className="headingBtn" testRef="distributions" onClick={() => handleSort('distributionsPaid')}>Distributions<br/>Paid to Date{renderSortIcon('distributionsPaid')}<br/><Popover placement="top" content={DISTRIBUTION_PAID_POPOVER_TEXT}/></ColumnHeading>
          <ColumnHeading className="headingBtn" testRef="roi" onClick={() => handleSort('totalRoi')}>Total<br/>ROI{renderSortIcon('totalRoi')}<br /><Popover placement="top" content={TOTAL_ROI_POPOVER_TEXT}/></ColumnHeading>
        </tr>
      </thead>
      <tfoot>
        <tr>
          <StickyHeader><FooterTableHeading>Total</FooterTableHeading></StickyHeader>
          <SecondColumn><FooterTableData testRef="total-number-of-bricks-overall">{portfolio.totalBricksOwned}</FooterTableData></SecondColumn>
          <FooterTableData>-</FooterTableData>
          <FooterTableData>-</FooterTableData>
          <FooterTableData>-</FooterTableData>
          <FooterTableData testRef="total-value-of-bricks-overall">{dollarDecimal(portfolio.totalValue)}</FooterTableData>
          <FooterTableData testRef="total-capital-returns-overall"><UpDownNumber value={portfolio.capitalReturns}>{floatToPercentDecimal(portfolio.capitalReturns)}</UpDownNumber></FooterTableData>
          <FooterTableData testRef="total-distributions-overall">{dollarDecimal(portfolio.distributionsPaid)}</FooterTableData>
          <FooterTableData testRef="total-roi-overall"><UpDownNumber value={portfolio.totalRoi}>{floatToPercentDecimal(portfolio.totalRoi)}</UpDownNumber></FooterTableData>
        </tr>
      </tfoot>
      <tbody>
        {rowsToShow.map((record, index) => (
          <BodyTableRow key={index}>
            <StickyHeader><TableHeading testRef={`property-name-${record.propertyCode}`}><PropertyNameField propertyCode={record.propertyCode}/></TableHeading></StickyHeader>
            <SecondColumn><TableData testRef={`quantity-of-bricks-${record.propertyCode}`}>{record.totalBricks}</TableData></SecondColumn>
            <TableData testRef={`average-brick-price-paid-per-brick-${record.propertyCode}`}>{dollarDecimal(record.costBasis)}</TableData>
            <TableData testRef={`lowest-available-brick-price-${record.propertyCode}`}>{dollarDecimal(properties.find((p) => p.propertyCode === record.propertyCode) ? properties.find((p) => p.propertyCode === record.propertyCode).financials.lowestAvailableBrickPrice : '')}</TableData>
            <TableData testRef={`brick-value-${record.propertyCode}`}>{dollarDecimal(record.brickValue)}</TableData>
            <TableData testRef={`total-value-of-bricks-${record.propertyCode}`}>{dollarDecimal(record.totalBrickValue)}</TableData>
            <TableData testRef={`capital-returns-${record.propertyCode}`}><UpDownNumber value={record.capitalReturns}>{floatToPercentDecimal(record.capitalReturns)}</UpDownNumber></TableData>
            <TableData testRef={`distributions-paid-${record.propertyCode}`}>{dollarDecimal(record.distributionsPaid)}</TableData>
            <TableData testRef={`total-roi-for-property-${record.propertyCode}`}><UpDownNumber value={record.totalRoi}>{floatToPercentDecimal(record.totalRoi)}</UpDownNumber></TableData>
          </BodyTableRow>
        ))}
      </tbody>
    </Table>
  </Layout>);
};

export default PortfolioPropertiesTable;
