import styled, { css } from 'styled-components';
import { SubHeading } from 'src/design/styleguide/Headings';
import { breakpointSmall } from 'src/design/styleguide/common/breakpoints';
import { gridUnit } from 'src/design/styleguide/common/measurements';
import {
  sidePanelPadding,
  sidePanelPaddingBreakpointSmall,
  topBottomPanelPadding
} from 'src/components/dashboard/common/styles';
import { testRefToDataTestReference } from 'src/design/styleguide/common/styledComponentsUtils';

const Root = styled.div.attrs(testRefToDataTestReference)`
  display: flex;
  flex-direction: column;
  border-radius: var(--bx-radius);
  box-shadow: var(--bx-shadow);
  background-color: var(--bx-color---surface);
  transition: box-shadow 500ms;
`;

const HeaderContainer = styled.div`
  ${props => props.hideHeadingOnMobile ? 'display: none;' : 'display: flex;'}
  justify-content: space-between;
  padding: ${gridUnit * 6}px ${sidePanelPadding};
  background-color: var(--color--white);
  border-bottom: var(--border);

  ${breakpointSmall(css`
    display: flex;
    padding-right: ${sidePanelPaddingBreakpointSmall};
    padding-left: ${sidePanelPaddingBreakpointSmall};
    background-color: var(--color--white);
  `)}
`;

const HeaderTitle = styled(SubHeading)`
  flex: 1 0 auto;
  ${breakpointSmall(`
    text-align: left;
  `)}
`;

const Content = styled.div.attrs(testRefToDataTestReference)`
  flex: 1 0 auto;
  padding: ${topBottomPanelPadding} ${sidePanelPadding};
  background-color: var(--color--white);

  ${breakpointSmall(`
    padding-right: ${sidePanelPaddingBreakpointSmall};
    padding-left: ${sidePanelPaddingBreakpointSmall};
  `)}
`;

const WidgetPanel = ({
  className,
  rootClassName,
  title,
  action,
  children,
  titleAlign,
  alwaysHaveShadow,
  hideHeadingOnMobile,
  contentTestRef,
  testRef,
}) => (
  <Root className={rootClassName} alwaysHaveShadow={alwaysHaveShadow} testRef={testRef}>
    {title && (
      <HeaderContainer hideHeadingOnMobile={hideHeadingOnMobile}>
        <HeaderTitle
          fontSize="2x-small"
          fontWeight="normal"
          {...(titleAlign ? { align: titleAlign } : {})}
        >
          {title}
        </HeaderTitle>
        {action}
      </HeaderContainer>
    )}
    <Content className={className} testRef={contentTestRef}>{children}</Content>
  </Root>
);

export default WidgetPanel;
