import styled from 'styled-components';
import { Heading, SubHeading } from 'src/design/styleguide/Headings';
// import { RegistrationWidgetBottom } from 'src/rentToBuy/RegistrationWidget';
import {
  breakpoint2xSmall,
  breakpointLarge,
  breakpointSmall,
} from 'src/design/styleguide/common/breakpoints';
import { getThemeColor } from 'src/themes/themeUtils';
import {
  gridUnit,
  sideGutter,
} from 'src/design/styleguide/common/measurements';
import ContentBlock from 'src/design/styleguide/contentBlock/ContentBlock';
import ContentContainer from 'src/design/styleguide/contentContainer/ContentContainer';
import ContentSection from 'src/design/styleguide/contentSection/ContentSection';
import Icon from 'src/design/components/icon/Icon';
import Image from 'src/design/components/image/Image';
import Paragraph from 'src/design/styleguide/Paragraph';
import RentToBuyHomeMeta from 'src/components/meta/RentToBuyHomeMeta';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import Text from 'src/design/styleguide/text/Text';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import { Typography, colors } from '@mui/material';

const AccentHeading = styled(Text).attrs({
  color: 'primary',
  fontWeight: 'regular',
})`
  ${breakpoint2xSmall(`
    margin-right: -30px;
  `)}
`;

const AccentHeadingBreak = styled.br`
  ${breakpoint2xSmall(`
    display: none;
  `)}
`;

const BigSection = (props) => (
  <ContentBlock
    as="section"
    topPadding="x-large"
    bottomPadding="x-large"
    {...props}
  />
);

const SectionHeading = ({ children, ...props }) => (
  <Spacing bottom="5x-large">
    <Heading {...props}>{children}</Heading>
  </Spacing>
);

const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpointSmall(`
    display: block;
  `)}
`;

const HeaderImageContainer = styled.div`
  order: 1;
  width: 100%;
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;

  ${breakpointSmall(`
    float: right;
    max-width: none;
    width: 50%;
    padding: 32px 0 0 64px;
  `)}
`;

const HeaderParagraph = styled(Paragraph)`
  ${breakpointSmall(`
    max-width: 500px;
  `)}
`;

// const InvestorIconsContainer = styled.div`
//   display: flex;
//   align-items: center;
// `;

// const InvestorIconWrapper = styled.div`
//   flex: 1 0 0px;
//   padding: ${gridUnit * 5}px;

//   &:first-child {
//     padding-left: 0;
//   }

//   &:last-child {
//     padding-right: 0;
//   }
// `;

// const InvestorIcon = ({ src, alt, ...props }) => (
//   <InvestorIconWrapper {...props}>
//     <Image {...{ src, alt }} />
//   </InvestorIconWrapper>
// );

const WhyReasonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  ${breakpointLarge(`
    justify-content: space-between;
  `)}
`;

const IconTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
  padding: 0 ${sideGutter};

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledIcon = styled(Icon)`
  color: ${getThemeColor('primary')};
  font-size: 50px;
`;

const IconText = ({ title, text, icon }) => (
  <Spacing bottom="2x-large">
    <IconTextContainer>
      <StyledIcon type={icon} />
      <Spacing bottom="x-small">
        <SubHeading as="h3" fontSize="x-small">
          {title}
        </SubHeading>
      </Spacing>
      <Paragraph align="center">{text}</Paragraph>
    </IconTextContainer>
  </Spacing>
);

const HowReasonsContainer = styled.div``;

const HorizontalIconTextContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

const ImageContainer = styled.div`
  position: relative;
  flex: 0 0 50px;
`;

// const StyledRegistrationWidgetBottom = styled(RegistrationWidgetBottom)`
//   max-width: 575px;
//   margin-left: auto;
// `;

const HorizontalImageText = ({ title, text, src, alt }) => (
  <Spacing bottom="3x-large">
    <HorizontalIconTextContainer>
      <ImageContainer>
        <Image src={src} alt={alt} />
      </ImageContainer>

      <Spacing left="large">
        <div>
          <Spacing bottom="small">
            <SubHeading align="left" as="h3" fontSize="x-small">
              {title}
            </SubHeading>
          </Spacing>

          <Paragraph>{text}</Paragraph>
        </div>
      </Spacing>
    </HorizontalIconTextContainer>
  </Spacing>
);

const FaqsContainer = styled.ul`
  display: grid;
  grid-column-gap: ${gridUnit * 12}px;
  padding: 0;
  margin: 0;

  ${breakpointSmall(`
    grid-template-columns: 1fr 1fr;
  `)}
`;

const FaqContainer = styled.li`
  display: flex;
  flex-direction: column;
`;

const Faq = ({ question, answer }) => (
  <Spacing bottom="x-large">
    <FaqContainer>
      {question}
      <Spacing top="x-small">{answer}</Spacing>
    </FaqContainer>
  </Spacing>
);

const QuestionText = ({ children, ...props }) => (
  <Heading
    as="h3"
    align="left"
    color="onDarkSurfacePrimary"
    fontSize="x-small"
    {...props}
  >
    {children}
  </Heading>
);

const AnswerText = ({ children, ...props }) => (
  <Paragraph color="light" fontSize="2x-small" fontWeight="medium" {...props}>
    {children}
  </Paragraph>
);

const AnswerTextListContainer = styled(AnswerText).attrs({
  forwardedAs: 'ul',
})`
  list-style-type: disc;
`;

const AnswerTextList = ({ items }) => (
  <AnswerTextListContainer>
    {items.map((item, index) => (
      <li key={index}>{item}</li>
    ))}
  </AnswerTextListContainer>
);

const RentToBuyHome = () => (
  <div data-test-reference="rent-to-buy-home-page">
    <RentToBuyHomeMeta />

    <ContentSection as="section">
      <ContentContainer topPadding="small" bottomPadding="small">
        <HeaderContent>
          <Spacing top={{ base: '2x-large', breakpointSmall: 'none' }}>
            <HeaderImageContainer>
              <Image
                src="static/img/illustration-rent-to-buy-waitlist.svg"
                alt="illustration of a house on a sunny day"
              />
            </HeaderImageContainer>
          </Spacing>

          <Heading align="left">
            <AccentHeading>
              Introducing
              <AccentHeadingBreak /> Smart Renter
            </AccentHeading>
            <br />
            Purchase your Home sooner!
          </Heading>

          <Spacing top="normal">
            <HeaderParagraph>
              BrickX Smart Renter helping you get a step closer to owning your
              own home!
            </HeaderParagraph>
          </Spacing>

          <Spacing bottom="normal">
            <HeaderParagraph>
              See below for <strong>how it works</strong> and{' '}
              <strong>register your interest</strong> to receive more
              information.
            </HeaderParagraph>
          </Spacing>

          <Spacing bottom="normal">
            <PrimaryButton
              color={PrimaryButton.colors.SECONDARY}
              link={'http://eepurl.com/hXDcUf'}
              forceExternal
              disabled
            >
              Sign Up Now
            </PrimaryButton>
          </Spacing>

          <Spacing top="small">
            <Typography fontSize={'1.2rem'} color={colors.grey[500]}>
              We are working through a high volume of requests and as such will
              temporarily suspend further applications.
              <br />
              <br />
              Stay tuned for further updates and enhancements to the BrickX
              Smart Renter offering.
            </Typography>
          </Spacing>

          {/* <RegistrationWidgetAtf {...props} /> */}
        </HeaderContent>
      </ContentContainer>
    </ContentSection>

    <BigSection
      id="how-rent-to-buy-works"
      backgroundColor="lightgrey"
      width="small"
    >
      <SectionHeading>What is Smart Renter?</SectionHeading>

      <HowReasonsContainer>
        <HorizontalImageText
          src="/static/img/icon-rent-to-buy-waitlist-ph01.svg"
          alt="icon hand pointing at house"
          title="1. Choose your property"
          text={`BrickX creates a shortlist of properties meeting our investment criteria from which you can choose your Smart Renter home. ("Smart Renter Property")`}
        />
        <HorizontalImageText
          src="/static/img/icon-rent-to-buy-waitlist-ph02.svg"
          alt="icon house with dollar sign"
          title="2. The BrickX Trust buys the property"
          text="You buy a minimum 5% of the Bricks in the BrickX Trust that purchases the Smart Renter Property."
        />
        <HorizontalImageText
          src="/static/img/icon-rent-to-buy-waitlist-ph03.svg"
          alt="icon truck carrying stuff"
          title="3. Move into your home"
          text="You pay the agreed rent on the property."
        />
        <HorizontalImageText
          src="/static/img/icon-rent-to-buy-waitlist-ph04.svg"
          alt="icon pie chart"
          title="4. Save for a deposit"
          text="Continue to save a deposit or even increase your stake in the property over the life of the lease and apply for a standard mortgage of your choice, or fund the purchase of the property however you like!"
        />
        <HorizontalImageText
          src="/static/img/icon-rent-to-buy-waitlist-ph05.svg"
          alt="icon house with a tick"
          title="5. First right to buy"
          text="At the end of your lease, you have the first right to buy the property, otherwise you can offer to extend the lease or walk away with the savings you’ve accumulated over the timeframe with BrickX."
        />
      </HowReasonsContainer>
    </BigSection>

    <ContentContainer
      id="why-rent-to-buy"
      as="section"
      topPadding="x-large"
      bottomPadding="x-large"
    >
      <ContentSection>
        <SectionHeading>Why Smart Renter?</SectionHeading>
      </ContentSection>

      <WhyReasonsContainer>
        <IconText
          icon="hands_around_house"
          title="Feel like a Home Owner"
          text="Have a pet? Want to paint the walls or hang a picture? No worries, with Smart Renter, you are not just a tenant!"
        />
        <IconText
          icon="house_ladder"
          title="Live in the Smart Renter Property"
          text="You get to live in the Smart Renter Property, while you save for a deposit or decide what to do next."
        />
        <IconText
          icon="piggy_bank"
          title="Saving for a Purpose"
          text="We can provide general tips that may assist in keeping you on track towards being able to buy the property at the end of the lease."
        />
      </WhyReasonsContainer>
    </ContentContainer>

    <BigSection id="rent-to-buy-faqs" backgroundColor="charcoal" width="large">
      <SectionHeading color="light">FAQs</SectionHeading>

      <FaqsContainer>
        <Faq
          question={<QuestionText>Who can apply?</QuestionText>}
          answer={
            <AnswerText>
              BrickX Smart Renter has been designed to assist those who are
              purchasing their home; so that they are able to live in the
              property sooner whilst they save the standard 20% deposit required
              for a mortgage.
              <br />
              <br />
              Any Home Buyers who are Australian tax residents and are over the
              age of 18.
            </AnswerText>
          }
        />
        <Faq
          question={<QuestionText>How is the rent calculated?</QuestionText>}
          answer={
            <AnswerText>
              Rent is calculated based on the agreed rate at the time the lease
              is initiated and will be reviewed on an annual basis to ensure it
              is in line with agreed lease terms.
            </AnswerText>
          }
        />
        <Faq
          question={
            <QuestionText>
              How does Smart Renter compare to a standard mortgage?
            </QuestionText>
          }
          answer={
            <div>
              <AnswerText>
                There are a couple of ways Smart Renter differs from a standard
                mortgage. For example:
              </AnswerText>
              <AnswerTextList
                items={[
                  'Smart Renter is not a loan and is therefore not debt. You are a Brick owner in the BrickX Trust that owns the Smart Renter Property as well as being a tenant.',
                  'Smart Renter provides you with a first right to purchase the Smart Renter Property without the standard 20% deposit that most banks currently require for a mortgage.',
                  'You pay an agreed rate of rent, as opposed to a mortgage where you pay either principal and interest or interest only on the loan amount.',
                  'If debt is used to fund the initial purchase of the property the debt is held in the name of the BrickX Trust and not yours, the debt itself will be secured by the property.',
                ]}
              />
            </div>
          }
        />
        <Faq
          question={<QuestionText>How is the property selected?</QuestionText>}
          answer={
            <AnswerText>
              The BrickX property team will select a shortlist of properties
              which meet BrickX’s investment criteria, from that shortlist you
              will select your home.
              <br />
              <br />
              You also have the opportunity to propose properties of your choice
              which will be reviewed for suitability and alignment to BrickX’s
              investment criteria.
              <br />
              <br />
              The investment criteria are based on a few factors which include
              such things as the property characteristics, independent
              valuations, suburb capital growth and yield returns. This
              information will be shared with the successful Smart Renter
              candidates to ensure full transparency and collaboration.
            </AnswerText>
          }
        />

        <Faq
          question={<QuestionText>How long is the lease?</QuestionText>}
          answer={
            <AnswerText>
              You can lock in a lease for up to 10 years in which time you will
              be able to save further, and even continue to buy additional
              Bricks in the BrickX Trust that owns the Smart Renter Property in
              which you live.
            </AnswerText>
          }
        />
        <Faq
          question={
            <QuestionText>What happens at the end of the lease?</QuestionText>
          }
          answer={
            <AnswerText>
              At the end of lease, you have the first right to buy the Smart
              Renter Property at either:
              <br />
              <br />
              a) a pre-determined purchase price as agreed between BrickX and
              you; or
              <br />
              b) the market value,
              <br />
              <br />
              How you do fund the purchase of the Smart Renter Property is your
              choice and if you take out a mortgage this will be done completely
              separate to BrickX.
            </AnswerText>
          }
        />
        <Faq
          question={
            <QuestionText>
              Can the lease be extended for a an additional term?
            </QuestionText>
          }
          answer={
            <AnswerText>
              Should you decide not to purchase the Smart Renter Property at the
              end of lease, you may request to extend the lease by a further
              period. BrickX will propose resolutions to all Brick Holders of
              the Smart Renter Property to decide on whether to extend the lease
              or put the Smart Renter Property up for sale.
            </AnswerText>
          }
        />
        <Faq
          question={
            <QuestionText>
              What happens if I can’t buy at the end of the lease?
            </QuestionText>
          }
          answer={
            <AnswerText>
              Should you decide not to buy the Smart Renter Property at the end
              of the lease and the lease is not extended, BrickX will facilitate
              the sale with all Brick holders receiving their share of the
              proceeds net of any closing expenses like real estate agent
              commission.
            </AnswerText>
          }
        />
        <Faq
          question={
            <QuestionText>Can I rent or sublease the property?</QuestionText>
          }
          answer={
            <AnswerText>
              For the duration of the agreement, state-based standard tenancy
              rules will apply.
            </AnswerText>
          }
        />
        {/* <Faq
          question={
            <QuestionText>
              How is the sale price determined? Is it fixed at the beginning of
              the term?
            </QuestionText>
          }
          answer={
            <AnswerText>
              The sale price is not fixed and will be determined at the time of
              sale, in-line with market conditions. If yourself and BrickX
              cannot come to an agreement on price, a final binding decision
              will be made by an independent valuer.
            </AnswerText>
          }
        /> */}
        {/* <Faq
          question={
            <QuestionText>
              What costs are involved? Will BrickX charge any fees?
            </QuestionText>
          }
          answer={
            <div>
              <AnswerText>
                The below costs will be split between yourself and BrickX,
                relative to your initial investment percentage. For eg if you
                initially invest 5% then you will be responsible for 5% of the
                below costs - with the exception of rent where you would be
                responsible for paying 95%.
              </AnswerText>
              <AnswerText>At the time of purchase:</AnswerText>
              <AnswerTextList
                items={[
                  'Stamp Duty ',
                  'Legal Fees including Conveyancing, Title Transfer/ Lodgement, Building Report and Pest Report, Depreciation Schedule',
                  'Buyers Agent Fee',
                  'Cash Reserve',
                ]}
              />
              <AnswerText>Monthly:</AnswerText>
              <AnswerTextList
                items={[
                  'Rent',
                  'Property Management',
                  'Council',
                  'Water',
                  'Repair & Maintenance',
                  'Landlord Insurance',
                  'Building Insurance',
                  'Land Tax',
                ]}
              />
              <AnswerText>
                BrickX Smart Renter will not charge any fees, the above associated
                costs are standard when purchasing a property.
              </AnswerText>
            </div>
          }
        /> */}
        <Faq
          question={
            <QuestionText>What documents will I need to apply?</QuestionText>
          }
          answer={
            <div>
              <AnswerText>
                Once you have completed the initial application and have been
                notified that you are successful, BrickX will request the
                following:
              </AnswerText>
              <AnswerTextList
                items={[
                  <span>
                    Drivers Licence or Proof of ID + Utility Bill |{' '}
                    <Text color="onDarkSurfacePrimary">
                      verifying your identity + address
                    </Text>
                  </span>,
                  <span>
                    2 Pay Slips |{' '}
                    <Text color="onDarkSurfacePrimary">
                      verifying proof of employment
                    </Text>
                  </span>,
                  <span>
                    Bank statement |{' '}
                    <Text color="onDarkSurfacePrimary">
                      verifying proof of deposit
                    </Text>
                  </span>,
                ]}
              />
            </div>
          }
        />
      </FaqsContainer>
    </BigSection>

    {/* <BigSection
      backgroundImage="/static/img/photo-room-purple-chair-furniture.jpg"
      overlayOpacity="0"
    >
      <StyledRegistrationWidgetBottom {...props} />
    </BigSection> */}
  </div>
);

export default RentToBuyHome;
