import classNames from 'classnames';
import { Heading } from 'src/design/components/heading/Headings';
import styles from 'src/design/components/heading/Heading.mscss';

const PageHeading = ({ children, className, ...props }) =>
  <Heading
    className={classNames(styles.pageHeading, className)}
    {...props}
  >
    <h1>{children}</h1>
  </Heading>;

export default PageHeading;
