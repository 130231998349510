import React from 'react';
import LoginForm from 'src/components/oldOnboarding/login/LoginForm';
import { AccountSwitchMeta } from 'src/components/meta/LoginMeta';
import OnboardingScreen, {
  OnboardingScreenHeader,
  OnboardingScreenContent,
} from 'src/components/oldOnboarding/common/onboardingScreen/OnboardingScreen';
import { LoginBox } from './AccountSwitchPage';
import Spacing from 'src/design/styleguide/spacing/Spacing';

const AccountSwitchLoginPage = ({ email, onPreviousPage }) => {
  return (
    <OnboardingScreen>
      <AccountSwitchMeta />
      <LoginBox>
        <OnboardingScreenContent>
          <OnboardingScreenHeader>
            <div className="stack stack-row stack-between">
              <div className="button" onClick={onPreviousPage}>
                <i className="fa fa-chevron-left"></i> back
              </div>
            </div>
            <Spacing bottom="large">
              <h1>
                <strong>Login</strong>
              </h1>
            </Spacing>
            <LoginForm email={email} disableEmailEdit doRedirect />
          </OnboardingScreenHeader>
        </OnboardingScreenContent>
      </LoginBox>
    </OnboardingScreen>
  );
};

export default AccountSwitchLoginPage;
