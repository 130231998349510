import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { elements as elementsPropType } from 'scripts/constants/PropTypes';
import Portal from 'scripts/components/shared/Portal';

import $ from 'jquery';
import isFunction from 'lodash.isfunction';


export default class Modal extends Component {
  static propTypes = {
    title: PropTypes.string,
    sizeClass: PropTypes.string,
    body: elementsPropType,
    footer: elementsPropType,
    onClose: PropTypes.func,
  };

  render() {
    const { title, body, footer, onClose, className, sizeClass } = this.props;
    return (
      <Portal to={Modal.root}>
        <div className={classNames('modal fade', className)}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="modal-label"
          aria-hidden="true"
          data-backdrop="static"
          data-keyboard="false">
          <div className={classNames('modal-dialog', sizeClass)}>
            <div className="modal-content">
              {title &&
                <div className="modal-header">
                  {onClose &&
                    <div className="close-panel" onClick={onClose}>
                      <span aria-hidden="true" className="close-icon">×</span>
                    </div>
                  }
                  <h4 className="modal-title" id="modal-label">{title}</h4>
                </div>}
              <div className="modal-body">
                {body}
              </div>
              {footer &&
                <div className="modal-footer">
                  {footer}
                </div>}
            </div>
          </div>
        </div>
      </Portal>
    );
  }

  open = () => {
    this.$modal.modal('show');
  };

  close = (callback) => {
    this.$modal.modal('hide');
    const { onClose } = this.props;
    if (isFunction(onClose)) {
      this.$modal.one('hidden.bs.modal', onClose);
    }
    if (isFunction(callback)) {
      this.$modal.one('hidden.bs.modal', callback);
    }
  };

  get $modal() {
    return $('.modal', Modal.root);
  }
}
