import classNames from 'classnames';
import BodyText from 'src/design/components/bodyText/BodyText';
import Icon from 'src/design/components/icon/Icon';
import styles from 'src/design/components/scrollDownHint/ScrollDownHint.mscss';

const ScrollDownHint = ({ className, text }) => (
  <div className={classNames(className, styles.scrollDownHint)} data-test-reference="scroll-down-hint">
    <BodyText className={styles['scrollDownHint--text']}>{text}</BodyText>
    <Icon className={styles['scrollDownHint--arrowIcon']} type={Icon.types.chevron_down}/>
  </div>
);

export default ScrollDownHint;
