import Icon from 'src/design/components/icon/Icon';
import Image from 'src/design/components/image/Image';
import {
  breakpoint2xSmall,
  breakpointLarge,
  breakpointSmall,
} from 'src/design/styleguide/common/breakpoints';
import {
  gridUnit,
  sideGutter,
} from 'src/design/styleguide/common/measurements';
import ContentBlock from 'src/design/styleguide/contentBlock/ContentBlock';
import { Heading, SubHeading } from 'src/design/styleguide/Headings';
import Paragraph from 'src/design/styleguide/Paragraph';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import Text from 'src/design/styleguide/text/Text';
import { getThemeColor } from 'src/themes/themeUtils';
import styled from 'styled-components';
import ListItemNumber from '../listItemNumber/ListItemNumber';


// UI Specs
export const iconSizes = {
  xxs: '10px',
  xs: '15px',
  sm: '20px',
  md: '30px',
  lg: '40px',
  xl: '75px',
  xxl: '100px',
};

export const LandingPageLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow: hidden;
  position: relative;
`;

export const AccentHeading = styled(Text).attrs({
  color: 'primary',
  fontWeight: 'regular',
})`
  ${breakpoint2xSmall(`
    margin-right: -30px;
  `)}
`;

export const AccentHeadingLight = styled(Text).attrs({
  color: 'light',
  fontWeight: 'regular',
})`
  ${breakpoint2xSmall(`
    margin-right: -30px;
  `)}
`;

export const AccentHeadingBreak = styled.br`
  ${breakpoint2xSmall(`
    display: none;
  `)}
`;

export const BigSection = (props) => (
  <ContentBlock
    as="section"
    topPadding="x-large"
    bottomPadding="x-large"
    {...props}
  />
);

export const SectionHeading = ({ children, ...props }) => (
  <Spacing bottom="5x-large">
    <Heading {...props}>{children}</Heading>
  </Spacing>
);

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpointSmall(`
    display: block;
  `)}
`;

export const HeaderImageContainer = styled.div`
  order: 1;
  width: 100%;
  /* max-width: 400px; */
  margin-left: auto;
  margin-right: auto;

  ${breakpointSmall(`
    float: right;
    max-width: none;
    width: 50%;
    padding: 32px 0 0 0;
  `)}

  .videoWrapper {
    border-radius: var(--bx-radius);
    overflow: hidden;
  }
`;

export const VideoContainer = styled.div`
overflow: hidden;
border-radius: var(--bx-radius);
`;

export const HeaderParagraph = styled(Paragraph)`
  ${breakpointSmall(`
    max-width: 500px;
  `)}
`;

// *** FAQ ***
export const FaqsContainer = styled.ul`
  display: grid;
  grid-column-gap: ${gridUnit * 12}px;
  padding: 0;
  margin: 0;

  ${breakpointSmall(`
    grid-template-columns: 1fr 1fr;
  `)}

  hr {
    border-color: #bbb;
  }
`;

export const FaqContainer = styled.li`
  display: flex;
  flex-direction: column;
`;

export const Faq = ({ question, answer }) => (
  <Spacing bottom="x-large">
    <FaqContainer>
      {question}
      <Spacing top="x-small">{answer}</Spacing>
    </FaqContainer>
  </Spacing>
);

export const QuestionText = ({ children, ...props }) => (
  <Heading
    as="h3"
    align="left"
    // color="whtie"
    fontSize="x-small"
    {...props}
  >
    {children}
  </Heading>
);

export const AnswerText = ({ children, ...props }) => (
  <Paragraph color="light" fontSize="2x-small" fontWeight="medium" {...props}>
    {children}
  </Paragraph>
);

export const AnswerTextDark = ({ children, ...props }) => (
  <Paragraph fontSize="2x-small" fontWeight="medium" {...props}>
    {children}
  </Paragraph>
);

export const AnswerTextListContainer = styled(AnswerText).attrs({
  forwardedAs: 'ul',
})`
  list-style-type: disc;
`;

export const AnswerTextOListContainer = styled(AnswerText).attrs({
  forwardedAs: 'ol',
})`
  list-style-type: decimal;
`;

export const AnswerTextDarkOListContainer = styled(AnswerTextDark).attrs({
  forwardedAs: 'ol',
})`
  list-style-type: decimal;
`;

export const AnswerTextList = ({ items }) => (
  <AnswerTextListContainer>
    {items.map((item, index) => (
      <li key={index}>{item}</li>
    ))}
  </AnswerTextListContainer>
);

export const AnswerTextOList = ({ items }) => (
  <AnswerTextOListContainer>
    {items.map((item, index) => (
      <li key={index}>{item}</li>
    ))}
  </AnswerTextOListContainer>
);

export const AnswerTextDarkOList = ({ items }) => (
  <AnswerTextDarkOListContainer>
    {items.map((item, index) => (
      <li key={index}>{item}</li>
    ))}
  </AnswerTextDarkOListContainer>
);

// *** Faq (1 in a row) ***

export const FaqRow = styled.div`
display: grid;
grid-template-columns: 1fr;
grid-gap: 2rem;
padding: 1rem 0;
.question {
  font-size: 2.2rem;
  font-weight: bold;
}
.answer {
  font-size: 2rem;
}

${breakpointSmall(`
  grid-template-columns: 4fr 8fr;
  grid-template-areas: 'question answer';
`)}

`;


// *** Containers ***

export const WhyReasonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  ${breakpointLarge(`
  justify-content: space-between;
`)}
`;

export const WhyReasonsContainer2Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${gridUnit * 4}px;
  div {
    max-width: unset;
  }

  ${breakpointLarge(`
  grid-template-columns: 1fr 1fr;
`)}
`;


export const WhyReasonsContainer4Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${gridUnit * 4}px;
  div {
    max-width: unset;
  }

  ${breakpointLarge(`
  grid-template-columns: 1fr 1fr 1fr 1fr;
`)}
`;

export const IconTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => props.align || 'center'};
  max-width: 350px;
  /* padding: 0 ${sideGutter}; */

  &:last-child {
    margin-bottom: 0;
  }
`;

export const StyledIcon = styled(Icon)`
  color: ${getThemeColor('primary')};
  font-size: ${props => props.fontSize || '50px'};
`;

export const IconText = ({ title, text, icon, size, align }) => {
  return (
  <Spacing bottom="2x-large">
    <IconTextContainer align={align}>
    <StyledIcon type={icon} fontSize={size} />
      <Spacing bottom="x-small">
        <SubHeading as="h3" fontSize="x-small">
          {title}
        </SubHeading>
      </Spacing>
      <Paragraph align={align || 'center'}>{text}</Paragraph>
    </IconTextContainer>
  </Spacing>);
};

const ImageWrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
margin: 40px;
width: ${(props) => props.width || 55}px;
height: ${(props) => props.width || 55}px;
}
`;

export const StaticIconText = ({ title, text, icon, width, size }) => {
  const theWidth =
    width ||
    (size
      ? {
          small: 55,
          medium: 80,
          large: 120,
          'x-large': 150,
        }[size]
      : 55);
  return (
    <Spacing bottom="2x-large">
      <IconTextContainer>
        <ImageWrapper width={theWidth}>
          <Image src={icon} />
        </ImageWrapper>
        <Spacing bottom="x-small">
          <SubHeading as="h3" fontSize="x-small">
            {title}
          </SubHeading>
        </Spacing>
        <Paragraph align="center">{text}</Paragraph>
      </IconTextContainer>
    </Spacing>
  );
};

export const SocialLinksContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  .socialLink {
    transition: all 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
    text-decoration: none;
    background: ${getThemeColor('primary')};
    color: #ffffff;
    font-size: 1.6rem;
  }

  .socialLink:hover {
    cursor: pointer;
    opacity: 0.9;
  }
`;

export const socialLinkGenerator = (social, link) => {
  return {
    facebook: `https://www.facebook.com/sharer.php?u=${link}`,
    twitter: `https://twitter.com/intent/tweet?url=${link}&text=&via=brickx`,
    linkedin: `https://www.linkedin.com/shareArticle?url=${link}`,
    whatsapp: `whatsapp://send?text=${link}`,
    email: `mailto:?subject=&body=${link}`,
  }[social];
};

export const SocialLinkIcon = ({ link, social, icon }) => {
  return (
    <a
      className="socialLink"
      href={socialLinkGenerator(social, link)}
      target="_blank"
    >
      <i className={`fa fa-${icon || social}`}></i>
    </a>
  );
};

export const SocialLinkShareLabel = styled.div`
  margin-right: 10px;
`;

// Number Point: First used in SMSF

export const StyledNumberedPoint = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1 0 0px;
  margin-bottom: ${gridUnit * 8}px;
`;

const StyledListItemNumber = styled(ListItemNumber)`
  margin-top: 6px;
  margin-right: ${gridUnit * 4}px;
`;

export const NumberedPointTextContainer = styled(Text)``;

export const NumberedPoint = ({ heading, text, colorCode }) => (
  <StyledNumberedPoint>
    <StyledListItemNumber color={colorCode || 'salmon'}/>
    <NumberedPointTextContainer>
      <Heading fontSize="x-small" align="left">
        {heading}
      </Heading>
      <Spacing top="x-small">
        <Text>{text}</Text>
      </Spacing>
    </NumberedPointTextContainer>
  </StyledNumberedPoint>
);


