import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { extend, sortBy } from 'lodash';
import moment from 'moment';
import {
  RowContainer,
  HorizontalBarSelector,
} from 'scripts/components/shared/FinancialsPanel';
import {
  years as historicalGrowthYears,
  dynamicOptions,
} from 'scripts/constants/HistoricalGrowth';
import { htmlEncoded } from 'scripts/utilities/formatters';
import {
  property as propertyPropType,
  historicalGrowthData as historicalGrowthDataPropType,
} from 'scripts/constants/PropTypes';
import Highchart from 'src/third-party/highchart/Highchart';
import chartConfig from 'scripts/constants/chartConfig';
import chartLineConfig from 'scripts/constants/chartLineConfig';
import moneyOrPercent from 'scripts/utilities/moneyOrPercent';
import { historicalGrowthChartBottom } from 'src/settings/text';

const calculateYearsRange = (data) => {
  if (data) {
    if (data.length > 0) {
      const offset = moment(data[data.length - 1].monthEnd).diff(
        moment(data[0].monthEnd),
        'years',
        true
      );
      return offset;
    }
  }
  return 20;
};

const DEFAULT_NOY_SELECTED = (historicalGrowthData) => {
  if (historicalGrowthData) {
    const yOptions = dynamicOptions(
      calculateYearsRange(sortBy(historicalGrowthData, 'monthEnd'))
    );
    return yOptions[0];
  }
  return historicalGrowthYears.TWENTY;
};

export default class HistoricalSuburbPerformance extends Component {
  static propTypes = {
    property: propertyPropType,
    historicalGrowthData: historicalGrowthDataPropType,
    propertyTab: PropTypes.string,
  };

  state = {
    numberOfYearsSelected: DEFAULT_NOY_SELECTED(
      this.props.historicalGrowthData
    ),
  };

  render() {
    const { historicalGrowthData } = this.props;
    const { numberOfYearsSelected } = this.state;

    const yearsToShow = parseInt(numberOfYearsSelected, 10);
    const endTimestamp = (() => {
      if (!historicalGrowthData) {
        return moment();
      }
      if (historicalGrowthData.length < 1) {
        return moment();
      }
      return moment(
        historicalGrowthData[historicalGrowthData.length - 1].monthEnd
      );
    })();
    const startTimestamp = endTimestamp.subtract('years', yearsToShow);
    const growthDataToShow = sortBy(historicalGrowthData, 'monthEnd')
      .slice()
      .filter(({ monthEnd }) => moment(monthEnd).isSameOrAfter(startTimestamp))
      .map(({ monthEnd, medianPrice }) => ({
        x: monthEnd,
        y: medianPrice,
      }));

    const chartBottomText = historicalGrowthChartBottom(
      this.props.property.propertyType
    );
    const yOptions = dynamicOptions(
      calculateYearsRange(sortBy(historicalGrowthData, 'monthEnd'))
    );

    return (
      <Fragment>
        <RowContainer>
          <HorizontalBarSelector
            options={yOptions}
            selected={numberOfYearsSelected}
            onChange={(selected) =>
              this.setState({ numberOfYearsSelected: selected })
            }
            reverse
          />
        </RowContainer>
        <Highchart
          hotReload
          className="historical-suburb-performance__highchart"
          config={extend(chartConfig, chartConfig.options, {
            xAxis: {
              type: 'datetime',
            },
            tooltip: {
              formatter: function () {
                return `<b>${moment(this.x).format(
                  "MMMM 'YY"
                )}</b>: ${moneyOrPercent(this.y)::htmlEncoded()}`;
              },
            },
            series: [
              extend(chartLineConfig, {
                type: 'areaspline',
                data: growthDataToShow,
                animation: false,
              }),
            ],
          })}
        />
        <RowContainer>
          <small className="asterisk-info">{chartBottomText}</small>
        </RowContainer>
      </Fragment>
    );
  }
}
