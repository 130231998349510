import { connect } from 'react-redux';
import Interval from 'src/components/interval/Interval';
import {
  logoutAndRedirectToTimedOutPage as logoutAndRedirectToTimedOutPageAction
} from 'scripts/redux/actions/user';
import { getCurrentUserId, isTimedOut } from 'scripts/auth/session';

const SessionTimeout = ({ logoutAndRedirectToTimedOutPage }) => {
  return !!getCurrentUserId()
    ? (
      <Interval
        callback={() => isTimedOut() && logoutAndRedirectToTimedOutPage()}
        timeout={5000}
        callImmediately
      />
    )
    : null;
};

export default connect(null, {
  logoutAndRedirectToTimedOutPage: logoutAndRedirectToTimedOutPageAction,
})(SessionTimeout);
