import { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  accountSelectorV2,
  fetchAccountInfoV2 as fetchAccountInfoAction
} from 'scripts/redux/actions/account';
import HistoricalPanel from
  'src/components/dashboard/portfolio/HistoricalPanel';
import Loading from 'src/components/loading/Loading';
import PageLayout from 'src/components/dashboard/common/PageLayout';
import PortfolioBreakdownPanel from 'src/components/dashboard/portfolio/PortfolioBreakdownPanel';
import PortfolioMeta from 'src/components/meta/PortfolioMeta';
import PropertiesPanel from
  'src/components/dashboard/portfolio/PropertiesPanel';
import SummaryPanel from 'src/components/dashboard/portfolio/SummaryPanel';
import withSelectablePortfolio from 'src/components/dashboard/common/withSelectablePortfolio';
import withState from 'src/decorators/withState';
import { userSelector } from 'scripts/redux/selectors/user';

const PortfolioContent = withSelectablePortfolio(({ account, portfolio, portfolioSwitchCheckboxes }) => (
  <PageLayout testRef="portfolio-screen">
    <SummaryPanel portfolio={portfolio} checkboxes={portfolioSwitchCheckboxes}/>
    <PropertiesPanel portfolio={portfolio} checkboxes={portfolioSwitchCheckboxes}/>
    <PortfolioBreakdownPanel account={account} portfolio={portfolio} checkboxes={portfolioSwitchCheckboxes}/>
    <HistoricalPanel/>
  </PageLayout>
));

const Portfolio = withState({
  name: 'Portfolio',
  componentDidMount: ({ fetchAccountInfo }) => fetchAccountInfo(),
  Component: ({ account, user }) => (
    <Fragment>
      <PortfolioMeta/>
      {
        account
          ? <PortfolioContent account={account} user={user}/>
          : <Loading/>
      }
    </Fragment>
  ),
});

export default connect((state) => ({
  ...accountSelectorV2(state),
  ...userSelector(state)
}), { fetchAccountInfo: fetchAccountInfoAction })(Portfolio);
