import { createAction, handleActions } from 'redux-actions';
import update from 'immutability-helper';
import Authentication from 'scripts/constants/Authentication';
import _ from 'lodash';


const LOGIN = 'scripts/auth/LOGIN';
const LOGOUT = 'scripts/auth/LOGOUT';
const loginAction = createAction(LOGIN);
const logoutAction = createAction(LOGOUT);

export const loggedIn = (data) => loginAction(_.mergeWith(data, { action: Authentication.LOGIN }));
export const loggedOut = (data) => logoutAction(_.mergeWith(data, { action: Authentication.LOGOUT }));

const reducer = handleActions({
  [LOGIN]: (state, action) => update(state, {
    $set: action.payload
  }),
  [LOGOUT]: (state, action) => update(state, {
    $set: action.payload
  })
}, null);

export default reducer;
