import React from 'react';
import Colors from 'src/themes/brickx/colors';
import styled from 'styled-components';

const Container = styled.div`
  padding: 0px 6px;
  border-radius: 20px;
  background-color: ${(props) => props.color || Colors.primary};
  color: ${Colors.white};
  font-size: 1.1rem;
  font-weight: bold;
  text-transform: uppercase;
  display: inline-block;
`;

const Tag = ({ label, color }) => {
  return <Container color={color}>{label}</Container>;
};

export default Tag;
