import { transactionFeeText } from 'src/settings/text';
import TermsPage from 'src/components/terms/TermsPage';
import styles from 'src/components/terms/Terms.mscss';

const NoFeesPreOrderTC = () => (<TermsPage title="No Fees to Pre Order Promo - Terms and conditions (CNG01)">
<ol className={styles.termsList}>
  <li>This promotion is offered by The Brick Exchange Pty Limited (ABN 27 600 762 749) (BrickX) as the manager of the BrickX Platform (ARSN 613 156 433). BrickX Financial Services Limited (ABN 67 616 118 088, AFSL 494878) is the product issuer for the BrickX Platform, but is not responsible for offering or administering this BrickX promotion.</li>
  <li>The transaction fee of {transactionFeeText()} on Brick purchases is being waived and will be fully funded by BrickX from its own funds and will not be sourced from the assets of the BrickX Platform or any BrickX Trust.</li>
  <li>The usual {transactionFeeText()} transaction fee will apply to the sale of Bricks.</li>
  <li>
    This promotion is only available for pre-orders of the launch of our Carnegie property (CNG01) which opens at 12pm AEDT on Monday 27 September 2021 and closes at 8.30pm AEDT on Wednesday 6 October 2021 (or earlier upon being fully subscribed). To be eligible, you must be or become a Member of the BrickX Platform including ensuring that you have accessed, read and understood the following important documents:
    <br/>
    <br/>
    <ul>
      <li>Product Disclosure Statement;</li>
      <li>Financial Services Guide;</li>
      <li>Additional Disclosure Document for CNG01;</li>
    </ul>
    which can be accessed <a href="https://www.brickx.com/pds" target="_blank">here</a>.
  </li>
  <li>Settlement of pre-ordered bricks will take place at 8.30pm on Wednesday 6 October 2021. Distributions for October 2021 will be paid in November 2021 within 10 business days of the end of the month for all bricks held at 11.59pm on the last day of the month.</li>
  <li>You should consider any tax implications and we recommend you seek independent tax advice.</li>
  <li>Capitalised terms have the meaning given in the BrickX Platform product disclosure statement unless otherwise defined.</li>
  <li>Please note that BPAY payments can take up to 1-2 business days to be received and appear as cleared funds in your Digital Wallet. Direct Debits take up to 4 business days to clear. Payments can also be made through POLi to provide instant funds (up to $20,000).</li>
  <li>All questions relating to this promotion should be directed to BrickX at <a href="mailto:info@brickx.com">info@brickx.com</a>.</li>
</ol>
</TermsPage>);

export default NoFeesPreOrderTC;
