import styles from 'src/components/promos/placements/PropertySummaryBanner.mscss';

const PropertySummaryBannerContainer = ({ children }) => (
  <div className={styles.propertySummaryBanner}>
    {children}
  </div>
);

const PropertySummaryBanner = () => (
  <PropertySummaryBannerContainer>
    {null}
  </PropertySummaryBannerContainer>
);

export default PropertySummaryBanner;
