import { track } from 'src/tracking/analytics';

export const trackPickAPlanClick = (chosenPlan) =>
  track('Pick a Plan Click - FE', { button: chosenPlan });

export const trackSmartInvestSelected = () => {
  track('Onboarding Product Selection - SI Selected');
  trackPickAPlanClick('Smart Invest');
};
export const trackBuildMyOwnSelected = () => {
  track('Onboarding Product Selection - BMO Selected');
  trackPickAPlanClick('Build My Own');
};

export const trackNotSureYetAnswerSelected = (chosenAnswer) =>
  track('Not Sure Yet Answer Click - FE', { answer: chosenAnswer });

export const trackFrequencySelectionButtonClick = (button) => track('How would you like to invest button click - FE', { button });
export const trackFrequencySelectionSecondScreenButtonClick = (button) => track('How would you like to invest considering feature screen button click - FE', { button });

export const trackDirectDebitAmountSelected = (amount) => track('Direct Debit Amount Selected', { amount });
export const trackDirectDebitDayOfMonthSelected = (dayOfMonth) => track('Direct Debit Day of Month Selected', { dayOfMonth });
export const trackDirectDebitBankDetailsEntered = ({ amount, dayOfMonth, bankName }) => track('Direct Debit Bank Details Entered', {
  amount,
  dayOfMonth,
  bankName,
});

export const trackDepositAmountSelectAttempt = ({
  amount,
  product,
  paymentMethod,
}) => track('Deposit Amount Select Attempt', {
  Amount: amount,
  Product: product,
  'Payment Method': paymentMethod,
});

export const trackDepositDriverCtaClick = (button) =>
  track('Initial Deposit Driver button click - FE', {
    button,
  });

export const sendConversionEvent = (action) =>
  track('First Brick or SI Sign Up - FE', {
    action,
  });

export const trackInvestmentTermQuestion = (answer) =>
  track('Onboarding investment term question answered', { answer });

export const trackHowDidYouHearAboutUsQuestion = (answer) =>
  track('Onboarding how did you hear about us question answered', { answer });
