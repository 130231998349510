import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactModal from 'scripts/components/shared/ReactModal/ReactModal';
import { user as userPropType, memberStatus as memberStatusPropType, referralRegistration as referralRegistrationPropType } from 'scripts/constants/PropTypes';
import { userSelector } from 'scripts/redux/selectors/user';
import { memberSelector } from 'scripts/redux/selectors/member';
import { referralRegistrationSelector } from 'scripts/redux/selectors/referral';
import { registerPartnerReferral as registerPartnerReferralAction } from 'scripts/redux/actions/referral';
import { getMemberStatus as getMemberStatusAction } from 'scripts/redux/actions/member';
import { viewedPartnerReferralConfirmation } from 'scripts/redux/actions/segment/events/referralEvents';
import PartnershipModalContent from 'src/components/partnerships/PartnershipModalContent';

const mapStateToProps = (state) => ({
  ...userSelector(state),
  ...referralRegistrationSelector(state),
  ...memberSelector(state),
});

@connect(mapStateToProps, {
  registerPartnerReferral: registerPartnerReferralAction,
  getMemberStatus: getMemberStatusAction,
})
export default class PartnershipReferralConfirmation extends Component {
  static propTypes = {
    user: userPropType.isRequired,
    token: PropTypes.string.isRequired,
    referralRegistration: referralRegistrationPropType,
    registerPartnerReferral: PropTypes.func.isRequired,
    memberStatus: memberStatusPropType,
    getMemberStatus: PropTypes.func.isRequired,
  };

  state = { showModal: true }

  componentDidMount() {
    document.body.classList.add('modal-open');
    const { token, registerPartnerReferral } = this.props;
    if (token) {
      registerPartnerReferral(token);
      const { user, getMemberStatus } = this.props;
      if (user.loggedIn) {
        getMemberStatus(user.id);
      }
    }
  }

  componentWillUnmount() {
    this.closeModal();
  }

  closeModal = () => {
    document.body.classList.remove('modal-open');
    this.setState({ showModal: false });
  }

  render() {
    const { referralRegistration } = this.props;
    if (!referralRegistration) {
      return null;
    }

    const { showModal } = this.state;
    const partnerName = referralRegistration.referrerName;

    viewedPartnerReferralConfirmation({ from: 'Partner Referral Confirmation', partner: partnerName });
    return (
      <ReactModal
        isOpen={showModal}
        toggle={this.closeModal}
        lightBackground
        testRef="partner-referral-modal"
      >
        <PartnershipModalContent partnerName={partnerName}/>
      </ReactModal>
    );
  }
}
