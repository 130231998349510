const PropertyCardFourStatLayout = ({ first, second, third, fourth }) => (
  <div className="property-card-stats-container">
    <div className="property-card-stats-container__top">
      <div className="property-card-stats-container__stat-box property-card-stats-container__stat-box--right-border">
        {first}
      </div>
      <div className="property-card-stats-container__stat-box">
        {second}
      </div>
    </div>
    <div className="property-card-stats-container__bottom">
      <div className="property-card-stats-container__stat-box property-card-stats-container__stat-box--right-border">
        {third}
      </div>
      <div className="property-card-stats-container__stat-box">
        {fourth}
      </div>
    </div>
  </div>
);

export default PropertyCardFourStatLayout;
