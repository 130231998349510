import { connect } from 'react-redux';
import withState from 'src/decorators/withState';

const mapArrayToObject = (array, mapItemToKeyValue) => array.reduce((acc, item) => ({ ...acc, ...mapItemToKeyValue(item) }), {});

const withFetching = (selectors, actions, name) => (WrappedComponent) => connect(
  (state) => mapArrayToObject(selectors, (selector) => selector(state)),
  mapArrayToObject(actions, (action) => ({ [action.name]: action }))
)(withState({
  name,
  componentDidMount: (props) => actions.map((action) => props[action.name]()),
  Component: WrappedComponent,
}));

export default withFetching;
