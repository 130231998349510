import _ from 'lodash';
import AccountType from 'scripts/constants/AccountType';
import Configuration from 'scripts/constants/Configuration';
import RegistrationStep from 'scripts/constants/RegistrationStep';

export const hasNominatedBankAccount = (user) =>
  !!(user.financialDetails && user.financialDetails.bankAccountDetails);

export const hasTfnProvided = (user) => {
  return _.isNil(user.financialDetails) ? false : user.financialDetails.hasTfn;
};

export const hasBasicEmailSubscription = (user) => {
  return _.isNil(user.emailSubscriptions)
    ? false
    : user.emailSubscriptions.basic;
};

export const hasFirstDistributionCollected = (user) => {
  return user.firstDistributionCollected;
};

export const isInvestor = (user) => {
  return _.isNil(user)
    ? false
    : user.nextRegistrationStep === RegistrationStep.DONE;
};

export const isMember = (user) => {
  return _.isNil(user)
    ? false
    : user.nextRegistrationStep === RegistrationStep.INVEST ||
        user.nextRegistrationStep === RegistrationStep.DONE;
};

export const hasBricksOnSoldoutProperty = (account) => {
  return _.isNil(account)
    ? false
    : !_.every(account.portfolio.records, [
        'brickPriceType',
        'LOWEST_AVAILABLE',
      ]);
};

export const isUserLoggedIn = (user) => {
  return user && user.loggedIn;
};

export const isUserWithAdminRole = (user) => {
  return user && user.loggedIn && user.email.endsWith('@brickx.com');
};

export const hasUserAcceptedPds = (user) => {
  return (
    isUserLoggedIn(user) &&
    user.nextRegistrationStep !== RegistrationStep.VERIFY &&
    user.nextRegistrationStep !== RegistrationStep.SIGNUP &&
    user.nextRegistrationStep !== RegistrationStep.PDS
  );
};

export const checkUserWithTradePermission = (user, router) => {
  var userPermission = false;
  if (isUserLoggedIn(user)) {
    if (user.nextRegistrationStep === RegistrationStep.VERIFY) {
      router.push(Configuration.PAGE_URLS.ONBOARDING.VERIFY);
    } else if (user.nextRegistrationStep === RegistrationStep.PDS) {
      router.push(Configuration.PAGE_URLS.ONBOARDING.ACCEPT_PDS);
    } else if (user.nextRegistrationStep === RegistrationStep.SETUP) {
      router.push(Configuration.PAGE_URLS.ACCOUNT.SETUP_DETAILS);
    } else if (user.nextRegistrationStep === RegistrationStep.VEDA) {
      router.push(Configuration.PAGE_URLS.ONBOARDING.VEDA_FAILED);
    } else if (user.nextRegistrationStep === RegistrationStep.DEPOSIT) {
      router.push(Configuration.PAGE_URLS.ACCOUNT.DEPOSIT);
    } else {
      userPermission = true;
    }
  } else {
    router.push(Configuration.PAGE_URLS.ONBOARDING.SIGNUP);
  }

  return userPermission;
};

export const hasMinorDetails = (user) =>
  user.accountType === 'MINOR' && user.minorDetails;

export const canSetupMinorAccount = (user) =>
  user.accountType !== 'MINOR' && hasUserAcceptedPds(user);

export const canApplySmartRenter = (user) =>
  user.accountType === AccountType.INDIVIDUAL ||
  user.accountType === AccountType.INTERNAL;

export const isMinorAccount = (user) =>
  user && user.accountType === AccountType.MINOR;

export const isSMSForTrustAccount = (user) =>
  user &&
  (user.accountType === AccountType.SMSF ||
    user.accountType === AccountType.TRUST);

export const canSetupWholesale = (user) =>
  user.accountType === AccountType.INDIVIDUAL && hasUserAcceptedPds(user);

export const isUserBoAllVerified = (user) => {
  if (isSMSForTrustAccount(user)) {
    if (
      user.boDetails &&
      user.boDetails.length > 0 &&
      user.boDetails.filter((ele) => !ele.idChecked).length > 0
    ) {
      return false;
    }
  }
  return true;
};

export const isSmsfAndTrustUserProcessed = (user) => {
  if (isSMSForTrustAccount(user)) {
    if (user.applicationPassed === false) {
      return false;
    }
  }
  return true;
};
