import { Fragment } from 'react';
import styled from 'styled-components';
import {
  accountSelectorV2,
  fetchAccountInfoV2,
} from 'scripts/redux/actions/account';
import { breakpointSmall } from 'src/design/styleguide/common/breakpoints';
import { dollarDecimal, getPlural } from 'src/utils/formats';
import { fetchProperties } from 'scripts/redux/actions/properties';
import { getFromLocation } from 'src/utils/router';
import { getThemeColor } from 'src/themes/themeUtils';
import { gridUnit } from 'src/design/styleguide/common/measurements';
import { hasAtLeastOnePropertyOwned } from 'src/utils/account';
import {
  portfolioUrl,
  propertiesUrl,
  propertyUrl,
  sellBricksUrl,
} from 'src/utils/pageUrls';
import { propertiesSelector } from 'scripts/redux/selectors/properties';
import {
  sidePanelPadding,
  topBottomPanelPadding,
} from 'src/components/dashboard/common/styles';
import { testRefToDataTestReference } from 'src/design/styleguide/common/styledComponentsUtils';
import Hyperlink from 'src/design/components/hyperlink/Hyperlink';
import Icon from 'src/design/components/icon/Icon';
import Loading from 'src/components/loading/Loading';
import PageLayout from 'src/components/dashboard/common/PageLayout';
import Paragraph from 'src/design/styleguide/Paragraph';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import Text from 'src/design/styleguide/text/Text';
import WidgetPanel from 'src/components/dashboard/common/WidgetPanel';
import withFetching from 'src/decorators/withFetching';
import withSelectablePortfolio from 'src/components/dashboard/common/withSelectablePortfolio';
import { renderCode } from 'src/settings/properties';

const BackLinkAnchor = styled(Hyperlink)`
  display: flex;
  align-items: center;
  color: inherit;
`;

const BackLink = ({ className, location }) => {
  const { url, name } = getFromLocation(location);

  return (
    <Text
      className={className}
      as="div"
      fontSize="4x-small"
      color="onSurfaceContrast500"
    >
      <BackLinkAnchor href={url || portfolioUrl()} testRef="back-link">
        <Icon type="arrow_back" />
        <Spacing left="x-small">
          <span>Back to {name || 'My Portfolio'}</span>
        </Spacing>
      </BackLinkAnchor>
    </Text>
  );
};

const PropertyListingContainerLink = styled(Hyperlink).attrs(
  testRefToDataTestReference
)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: -${sidePanelPadding};
  margin-right: -${sidePanelPadding};
  border: 1px solid ${getThemeColor('shale')};
  border-left-width: 0;
  border-right-width: 0;
  padding: ${gridUnit * 4}px ${sidePanelPadding};

  &:first-child {
    margin-top: -${topBottomPanelPadding};
  }

  ${breakpointSmall(`
    margin-left: 0;
    margin-right: 0;
    border-left-width: 1px;
    border-right-width: 1px;

    &:first-child {
      margin-top: 0;
      border-top-width: 1px;
    }
  `)}
`;

const ValuationContainer = styled.div.attrs(testRefToDataTestReference)`
  display: flex;
  flex-direction: column;

  ${breakpointSmall(`
    flex-direction: row;
    align-items: center;
  `)}
`;

const ValuationsSeparator = styled(Text).attrs((props) => ({
  color: 'onSurfaceContrast500',
  ...testRefToDataTestReference(props),
}))`
  display: none;

  ${breakpointSmall(`
    display: inline;
  `)}
`;

const BrickAmountContainer = styled(Text).attrs((props) => ({
  as: 'div',
  fontSize: '2x-small',
  color: 'onSurfaceContrast800',
  ...testRefToDataTestReference(props),
}))`
  display: flex;
  align-items: center;
`;

const PropertyListing = ({
  propertyCode,
  suburb,
  state,
  brickValue,
  brickPrice,
  numberOfBricks,
}) => (
  <PropertyListingContainerLink
    href={{
      pathname: propertyUrl(propertyCode),
      state: { from: sellBricksUrl() },
    }}
    testRef={`${propertyCode}-link`}
  >
    <div>
      <Spacing bottom="x-small">
        <div data-test-reference={`${propertyCode}-identifier`}>
          <Text
            fontSize="2x-small"
            color="onSurfaceContrast800"
            fontWeight="medium"
          >
            {suburb} {state}
          </Text>
          <Spacing left="x-small">
            <Text fontSize="4x-small" color="primary">
              {renderCode(propertyCode)}
            </Text>
          </Spacing>
        </div>
      </Spacing>
      <ValuationContainer testRef={`${propertyCode}-valuations`}>
        <Text fontSize="4x-small" color="onSurfaceContrast500">
          Brick Valuation <strong>{dollarDecimal(brickValue)}</strong>
        </Text>
        <Spacing left="x-small" right="x-small">
          <ValuationsSeparator>・</ValuationsSeparator>
        </Spacing>
        <Text fontSize="4x-small" color="onSurfaceContrast500">
          Lowest Available Brick Price{' '}
          <strong>{dollarDecimal(brickPrice)}</strong>
        </Text>
      </ValuationContainer>
    </div>
    <BrickAmountContainer testRef={`${propertyCode}-brick-amount`}>
      {numberOfBricks}{' '}
      {getPlural({
        number: numberOfBricks,
        singular: 'Brick',
        plural: 'Bricks',
      })}
      <Icon type="chevron_right" />
    </BrickAmountContainer>
  </PropertyListingContainerLink>
);

const NoBricksToSellContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NoBricksToSellMessage = () => (
  <NoBricksToSellContainer>
    <Paragraph align="center">
      You do not have any bricks to sell. Would you like to buy some?
    </Paragraph>
    <PrimaryButton
      color={PrimaryButton.colors.SECONDARY}
      link={propertiesUrl()}
      testRef="buy-button"
    >
      Buy Bricks
    </PrimaryButton>
  </NoBricksToSellContainer>
);

const SellBricksPanel = withSelectablePortfolio(
  ({ account, portfolio, properties, portfolioSwitchCheckboxes }) => (
    <WidgetPanel title="Sell Bricks" testRef="sell-bricks-panel">
      {hasAtLeastOnePropertyOwned(account) ? (
        <Fragment>
          <Spacing bottom="large">{portfolioSwitchCheckboxes}</Spacing>
          {portfolio.records.map((record) => (
            <PropertyListing
              key={record.propertyCode}
              propertyCode={record.propertyCode}
              suburb={record.propertySuburb}
              state={record.propertyState}
              brickValue={record.brickValue}
              brickPrice={
                properties.find((p) => p.propertyCode === record.propertyCode)
                  .financials.lowestAvailableBrickPrice
              }
              numberOfBricks={record.totalBricks}
            />
          ))}
        </Fragment>
      ) : (
        <NoBricksToSellMessage />
      )}
    </WidgetPanel>
  )
);

const selectors = [accountSelectorV2, propertiesSelector];

const fetchers = [fetchAccountInfoV2, fetchProperties];

const SellBricks = withFetching(
  selectors,
  fetchers,
  'SellBricks'
)(({ account, properties, location }) =>
  !account || !properties.length ? (
    <Loading />
  ) : (
    <PageLayout>
      <Spacing
        bottom={{ base: 'normal', breakpointSmall: 'none' }}
        left={{ base: 'normal', breakpointSmall: 'none' }}
      >
        <BackLink location={location} />
      </Spacing>
      <SellBricksPanel account={account} properties={properties} />
    </PageLayout>
  )
);

export default SellBricks;
