import { connect } from 'react-redux';
import { showDirectDebitRequest as showDirectDebitRequestAction } from 'scripts/redux/actions/smartInvest';
import OnClicklink from 'src/design/components/hyperlink/OnClicklink';


const DirectDebitRequestLink = ({ showDirectDebitRequest, values, disabled, children, testRef }) => (
  <OnClicklink
    onClick={() => showDirectDebitRequest(values)}
    disabled={disabled}
    testRef={testRef}
  >
    {children}
  </OnClicklink>
);

export default connect(null, { showDirectDebitRequest: showDirectDebitRequestAction })(DirectDebitRequestLink);
