

const NoBricksCurrentlyAvailableBox = () => (
  <div className="no-bricks-currently-available-box">
    <div className="the-padding">
      <div className="the-padding">
        <span className="icn icn-brick-orange-large"></span>
        <div className="no-bricks">No Bricks Currently Available</div>
      </div>
    </div>
  </div>
);

export default NoBricksCurrentlyAvailableBox;
