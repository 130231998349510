import classNames from 'classnames';
import TextInput from 'src/design/components/textInput/TextInput';
import FormLabel from 'src/design/components/formLabel/FormLabel';


export const OnboardingScreenHeader = ({ children, className }) =>
  <div className={classNames('onboarding-screen__header', className)}>{children}</div>;

export const OnboardingScreenContent = ({ className, children }) =>
  <div className={classNames('onboarding-screen__content', className)}>{children}</div>;

export const OnboardingScreenContentWide = ({ className, children }) =>
  <div
    className={classNames('onboarding-screen__content onboarding-screen__content--wide', className)}
  >{children}</div>;

export const OnboardingFormFieldContainer = ({ children }) =>
  <div className="onboarding-screen__form-field-container onboarding-screen__form-element--margin-16">{children}</div>;

export const OnboardingFormLabel = ({ children }) => <FormLabel className="onboarding-screen__form-field-label">{children}</FormLabel>;
export const OnboardingTextInput = ({ className, ...props }) => (
  <TextInput
    className={classNames('onboarding-screen__form-field', className)}
    {...props}
  />
);

const OnboardingScreen = ({ id, className, children, testRef }) =>
  <div id={id} className={classNames(className, 'onboarding-screen')} data-test-reference={testRef}>
    <div className="onboarding-screen__inner-centered">
      {children}
    </div>
  </div>;

export const OnboardingScreenVerticalCentered = ({ id, className, children, testRef }) =>
  <div id={id} className={classNames(className, 'onboarding-screen')} data-test-reference={testRef}>
    <div className="onboarding-screen__inner-vertical-centered">
      {children}
    </div>
  </div>;

export default OnboardingScreen;
