import classNames from 'classnames';
import ExpandingInfoBox from 'src/design/components/expandingInfoBox/ExpandingInfoBox';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import ListItemNumber from 'src/components/landingPages/common/listItemNumber/ListItemNumber';
import styles from 'src/components/landingPages/howItWorks/HowItWorks.mscss';

const TwoWaysExpandableHowItWorksSteps = ({ title, items }) => (
  <Spacing top="x-large">
    <ExpandingInfoBox title={title}>
      <ol className={classNames(styles.twoWaysExpandable__list, ListItemNumber.counterResetClass)}>
        {items.map((item, index) =>
          <Spacing bottom="normal" key={index}>
            <li className={styles.twoWaysExpandable__itemContainer}>
              <ListItemNumber className={styles.twoWaysExpandable__itemCounter} color="salmon"/>
              {item}
            </li>
          </Spacing>
        )}
      </ol>
    </ExpandingInfoBox>
  </Spacing>
);

export default TwoWaysExpandableHowItWorksSteps;
