import { connect } from 'react-redux';
import {
  checkCustomerReferralEligibility as checkCustomerReferralEligibilityAction,
} from 'scripts/redux/actions/referral';
import { getReferralCookie, removeReferralCookie } from 'src/utils/referrals';
import { refereeNotEligiblePageUrl } from 'src/utils/pageUrls';
import { userSelector } from 'scripts/redux/selectors/user';
import history from 'src/browser/history';
import withState from 'src/decorators/withState';

export default (WrappedComponent) => connect((state) => ({
  ...userSelector(state),
}), {
  checkCustomerReferralEligibility: checkCustomerReferralEligibilityAction,
})(withState({
  componentDidMount: ({ user, checkCustomerReferralEligibility }, state, setState) => {
    if (!getReferralCookie()) return;
    checkCustomerReferralEligibility(user.id).then(res => {
      setState({ isEligible: res.eligible });
      if (!res.eligible) {
        removeReferralCookie();
        history.push(refereeNotEligiblePageUrl());
      }
    });
  },
  mapPropsToInitialState: () => ({ isEligible: false }),
  Component: ({ state: { isEligible }, ...props }) => <WrappedComponent isEligible={isEligible} {...props}/>,
}));
