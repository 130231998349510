import { createAction, handleActions } from 'redux-actions';

import PropertyService from 'scripts/services/PropertyService';
import update from 'immutability-helper';

const MONTHLY_DISTRIBUTIONS = 'scripts/property/MONTHLY_DISTRIBUTIONS';
const MONTHLY_PAID_DISTRIBUTIONS =
  'scripts/property/MONTHLY_PAID_DISTRIBUTIONS';
const ALL_DISTRIBUTIONS = 'scripts/property/ALL_DISTRIBUTIONS';

const monthlyDistributions = createAction(MONTHLY_DISTRIBUTIONS);
const monthlyPaidDistributions = createAction(MONTHLY_PAID_DISTRIBUTIONS);
const allMonthlyDistributions = createAction(ALL_DISTRIBUTIONS);

export function fetchMonthlyDistributions(propertyCode) {
  return async function (dispatch) {
    const payload = await PropertyService.getMonthlyDistributions(propertyCode);
    return dispatch(monthlyDistributions(payload));
  };
}

export function fetchMonthlyPaidDistributions(propertyCode) {
  return async function (dispatch) {
    const payload = await PropertyService.getMonthlyPaidDistributions(
      propertyCode
    );
    return dispatch(monthlyPaidDistributions(payload));
  };
}

export const fetchAllMonthlyDistributions = () => {
  return async function (dispatch) {
    const payload = await PropertyService.getAllMonthlyDistributions();
    return dispatch(allMonthlyDistributions(payload));
  };
};

const reducer = handleActions(
  {
    [MONTHLY_DISTRIBUTIONS]: (state, action) =>
      update(state, {
        monthlyDistributions: {
          $set: action.payload,
        },
      }),
    [MONTHLY_PAID_DISTRIBUTIONS]: (state, action) =>
      update(state, {
        monthlyPaidDistributions: {
          $set: action.payload,
        },
      }),
    [ALL_DISTRIBUTIONS]: (state, action) =>
      update(state, {
        allMonthlyDistributions: {
          $set: action.payload,
        },
      }),
  },
  {
    monthlyDistributions: null,
  }
);

export default reducer;
