import classNames from 'classnames';
import Styledlink from 'src/design/components/hyperlink/Styledlink';

import styles from 'src/design/components/hyperlink/Styledlink.mscss';


const StyledBodylink = ({ className, ...props }) => (
  <Styledlink
    className={classNames(styles.StyledBodylink, className)}
    {...props}
  />
);

export default StyledBodylink;
