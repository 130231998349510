import classNames from 'classnames';
import styles from 'src/components/promos/PromoBanner.mscss';

const PromoBanner = ({ className, children }) => (
  <div className={classNames(className, styles.promoBanner)}>
    <span><span className={styles.promoBanner__label}>PROMO</span> {children}</span>
  </div>
);

export default PromoBanner;
