import { Component } from 'react';
import classNames from 'classnames';

import Modal from 'scripts/components/helpers/Modal';
import Carousel from 'scripts/components/shared/Carousel';

import { elements as elementsPropType } from 'scripts/constants/PropTypes';

export default class ExpandingCarousel extends Component {
  static propTypes = {
    items: elementsPropType,
    expandItems: elementsPropType,
  };

  static defaultProps = {
    items: [],
  };

  state = {
    itemIndex: 0,
    showModal: false,
  };

  render() {
    const { items, expandItems = items } = this.props;

    return (
      <div onClick={this.handleSlideClick}>
        <Carousel>
          {items.map((item, index) => (
            <div
              className="property-hero__carousel-item-click-wrapper"
              data-toggle="modal"
              data-target="#modal-property-hero-image"
              data-item-index={index}
              key={index}
            >
              {item}
            </div>
          ))}
        </Carousel>
        <Modal
          id="modal-property-hero-image"
          modalClassName={classNames('expanding-carousel__modal', { 'expanding-carousel__modal--shown': this.state.showModal })}
          sizeClass="modal-lg text-left"
          body={
            <Carousel
              startPosition={this.state.itemIndex}
              showOneSlideOnly
              key={this.state.itemIndex}
            >
              {expandItems}
            </Carousel>
          }
          onClose={() => this.setState({ showModal: false })}
          hideHeader
        />
      </div>
    );
  }

  handleSlideClick = (e) => {
    const clickedSlide = e.target.closest('.property-hero__carousel-item-click-wrapper');
    if (!clickedSlide) return;

    const { itemIndex } = clickedSlide.dataset;

    this.setState({
      itemIndex: parseInt(itemIndex, 10),
      showModal: true,
    });
  }
}
