import { Fragment } from 'react';
import styled, { css } from 'styled-components';
import {
  DISTRIBUTION_PAID_POPOVER_TEXT,
  TOTAL_ROI_POPOVER_TEXT,
  TOTAL_VALUE_POPOVER_TEXT
} from 'src/components/portfolioDetails/PopoverText';
import { breakpointLarge } from 'src/design/styleguide/common/breakpoints';
import { dollarDecimal, floatToPercentDecimal } from 'src/utils/formats';
import { getThemeColor } from 'src/themes/themeUtils';
import { gridUnit } from 'src/design/styleguide/common/measurements';
import { propertiesUrl, sellBricksUrl } from 'src/utils/pageUrls';
import {
  testRefToDataTestReference,
} from 'src/design/styleguide/common/styledComponentsUtils';
import Popover from 'scripts/components/shared/Popover';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import SecondaryButton from 'src/design/components/button/SecondaryButton';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import Text from 'src/design/styleguide/text/Text';
import TotalValueContainer from 'src/components/dashboard/portfolio/TotalValueContainer';
import UpDownNumber from 'src/components/upDownNumber/UpDownNumber';
import WidgetPanel from 'src/components/dashboard/common/WidgetPanel';
import { connect } from 'react-redux';
import { userSelector } from 'scripts/redux/actions/user';
import { getCustomButtonColor, getCustomThemeColor } from '../home/DashboardHome';

const StatLayoutRoot = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;

  ${breakpointLarge(`
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
  `)}
`;

const StatLayoutItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: ${gridUnit * 4}px;

  &::before, &::after {
    content: ' ';
    position: absolute;
    background-color: transparent;
    border: 1px solid transparent;
    pointer-events: none;
  }

  &::before {
    width: 100%;
    height: 80%;
    top: 10%;
    left: 0;
  }

  &::after {
    width: 80%;
    height: 100%;
    top: 0;
    left: 10%;
  }

  &:nth-child(odd)::before {
    border-right-color: ${getThemeColor('shale')};
  }

  &:nth-child(even)::before {
    border-left-color: ${getThemeColor('shale')};
  }

  &:nth-child(-n+2)::after {
    border-bottom-color: ${getThemeColor('shale')};
  }

  &:nth-child(n+3)::after {
    border-top-color: ${getThemeColor('shale')};
  }

  ${breakpointLarge(css`
    padding: ${gridUnit * 6}px;

    &::after {
      width: 100%;
      height: 80%;
      top: 10%;
      left: 0;
    }

    &&::before, &&::after {
      border-color: transparent;
    }

    &&::before {
      border-left-color: ${getThemeColor('shale')};
      border-right-color: ${getThemeColor('shale')};
    }

    &&:first-child::before {
      border-left-color: transparent;
      border-right-color: ${getThemeColor('shale')};
    }

    &&:last-child::before {
      border-left-color: ${getThemeColor('shale')};
      border-right-color: transparent;
    }
  `)}
`;

const StatLayout = ({ className, items }) => (
  <StatLayoutRoot className={className}>
    {items.map((item, index) => <StatLayoutItem key={index}>{item}</StatLayoutItem>)}
  </StatLayoutRoot>
);

const StatRoot = styled.div.attrs(testRefToDataTestReference)`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;

  ${breakpointLarge(`
    flex-direction: column-reverse;
    align-items: flex-start;
    text-align: left;
  `)}
`;

const Stat = ({ title, value, testRef }) => (
  <StatRoot testRef={testRef}>
    <Text fontSize="4x-small">{title}</Text>
    {value}
  </StatRoot>
);

const StatValue = ({ value }) => <Text fontSize="small" fontWeight="bold">{value}</Text>;

const SummaryPanelGrid = styled(WidgetPanel)`
  ${breakpointLarge(css`
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto;

    grid-template-areas:
    'HeadingArea ActionsArea'
    'StatsArea StatsArea';
  `)}
`;

const HeadingArea = styled.div`
  grid-area: HeadingArea;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${breakpointLarge(`
    align-items: flex-start;
  `)}
`;

const StatsArea = styled.div`
  grid-area: StatsArea;
`;

const ActionsArea = styled.div`
  grid-area: ActionsArea;
`;

const buttonStyles = css`
  && {
    display: block;
    width: 100%;
    ${breakpointLarge(`
      width: 240px;
    `)}
  }
`;
const BuyButton = styled(PrimaryButton)`${buttonStyles}`;
const SellButton = styled(SecondaryButton)`${buttonStyles}`;

const SummaryPanel = connect((state) => ({
  ...userSelector(state)
}), {})(({ portfolio, checkboxes, user }) => {
  return (
    <SummaryPanelGrid title="Summary" contentTestRef="summary-panel-content">
      <HeadingArea>
        <Spacing bottom="2x-large">
          {checkboxes}
        </Spacing>
        <TotalValueContainer highlightColor={getCustomThemeColor(user)} testRef="total-account-value">
          <Text as="p" fontWeight="regular" fontSize="4x-small">Total Value of My Bricks <Popover placement="top" content={TOTAL_VALUE_POPOVER_TEXT}/></Text>
          <Text as="p" fontWeight="bold" fontSize="normal">{dollarDecimal(portfolio.totalValue)}</Text>
        </TotalValueContainer>
      </HeadingArea>

      <StatsArea>
        <Spacing top={{ base: 'normal', breakpointLarge: '3x-large' }}>
          <StatLayout
            items={[
              <Stat
                title={<Fragment>Number of<br/>Bricks Owned</Fragment>}
                value={<StatValue value={portfolio.totalBricksOwned}/>}
                testRef="bricks-owned"
              />,
              <Stat
                title={<Fragment>Number of<br/>Properties Invested In</Fragment>}
                value={<StatValue value={portfolio.totalPropertiesOwned}/>}
                testRef="properties-invested-in"
              />,
              <Stat
                title={<Fragment>Total Distributions <Popover placement="top" content={DISTRIBUTION_PAID_POPOVER_TEXT}/><br/>Paid to Date</Fragment>}
                value={<StatValue value={dollarDecimal(portfolio.distributionsPaid)}/>}
                testRef="total-distributions"
              />,
              <Stat
                title={<Fragment>Total ROI <Popover placement="top" content={TOTAL_ROI_POPOVER_TEXT}/><br/>(Fees not included)</Fragment>}
                value={<StatValue value={
                  <UpDownNumber value={portfolio.totalRoi}>
                    {floatToPercentDecimal(portfolio.totalRoi)}
                  </UpDownNumber>
                }/>}
                testRef="total-roi"
              />,
            ]}
          />
        </Spacing>
      </StatsArea>

      <ActionsArea>
        <Spacing top={{ base: 'normal', breakpointLarge: 'none' }}>
          <BuyButton
            link={propertiesUrl()}
            color={getCustomButtonColor(user)}
            testRef="buy-bricks-button"
          >
            Buy Bricks
          </BuyButton>
        </Spacing>

        <Spacing top="x-small">
          <SellButton
            link={sellBricksUrl()}
            color={SecondaryButton.colors.PRIMARY}
            testRef="sell-bricks-button"
          >
            Sell Bricks
          </SellButton>
        </Spacing>
      </ActionsArea>
    </SummaryPanelGrid>
  );
});

export default SummaryPanel;
