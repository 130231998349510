import styled from 'styled-components';
import { FinePrintStyles } from 'src/design/styleguide/FinePrint';
import Paragraph from 'src/design/styleguide/Paragraph';

const FinePrintParagraph = styled(Paragraph)`
  ${FinePrintStyles}
  line-height: 1.3;
`;

export default FinePrintParagraph;
