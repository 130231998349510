import { createAction, handleActions } from 'redux-actions';
import _ from 'lodash';
import update from 'immutability-helper';
import { getByPropertyCode } from 'scripts/utilities/helpers';
import { propertiesUrl } from 'src/utils/pageUrls';
import MarketService from 'scripts/services/MarketService';
import PropertyService from 'scripts/services/PropertyService';
import history from 'src/browser/history';
import { isAOffMarketProperty } from 'scripts/utilities/propertyHelper';
const PROPERTIES = 'scripts/properties/PROPERTIES';
const properties = createAction(PROPERTIES);

const SMART_INVEST_PROPERTIES = 'scripts/properties/SMART_INVEST_PROPERTIES';
const smartInvestProperties = createAction(SMART_INVEST_PROPERTIES);

const PROPERTY_SELECTED = 'scripts/properties/PROPERTY_SELECTED';
const propertySelected = createAction(PROPERTY_SELECTED);

export function fetchProperties() {
  return async function (dispatch) {
    const propertyPayload = await PropertyService.getPublishedProperties();
    const propertyFinancials = await PropertyService.getAllFinancials();
    const propertyInvestmentMetricsPayload =
      await MarketService.getInvestmentMetrics();

    const payload = _.map(propertyPayload, function (property) {
      return _.mergeWith(property, {
        financials: _.filter(propertyFinancials, function (financial) {
          if (financial.propertyCode === property.propertyCode) {
            return financial;
          }
        })[0],
        investmentMetrics: _.filter(
          propertyInvestmentMetricsPayload,
          function (investmentMetrics) {
            if (investmentMetrics.propertyCode === property.propertyCode) {
              return investmentMetrics;
            }
          }
        )[0],
      });
    });
    return dispatch(properties(payload));
  };
}

export function fetchSmartInvestProperties() {
  return async function (dispatch) {
    const propertiesPayload = await PropertyService.getPublishedProperties();
    const smartInvestPropertyPayload =
      await PropertyService.getSmartInvestProperties();
    const payload = propertiesPayload.filter(
      (prop) => smartInvestPropertyPayload.indexOf(prop.propertyCode) !== -1
    );
    return dispatch(smartInvestProperties(payload));
  };
}

export function fetchProperty(propertyCode, publishedProperties) {
  return async function (dispatch) {
    const propertiesOnMarket = publishedProperties.filter(
      (prop) => !isAOffMarketProperty(prop)
    );
    const payload = getByPropertyCode(propertiesOnMarket, propertyCode);
    if (payload === undefined) {
      return history.push(propertiesUrl());
    }
    return dispatch(propertySelected(payload));
  };
}

const reducer = handleActions(
  {
    [PROPERTIES]: (state, action) =>
      update(state, {
        indexed: {
          $set: _.keyBy(action.payload, 'propertyCode'),
        },
        list: {
          $set: action.payload,
        },
      }),
    [SMART_INVEST_PROPERTIES]: (state, { payload }) => ({
      ...state,
      smartInvestProperties: payload,
    }),
    [PROPERTY_SELECTED]: (state, action) =>
      update(state, {
        selected: {
          $set: action.payload,
        },
      }),
  },
  {
    indexed: {},
    list: [],
    selected: null,
  }
);

export default reducer;
