import { Fragment } from 'react';
import FormLabel from 'src/design/components/formLabel/FormLabel';
import Spacing from 'src/design/styleguide/spacing/Spacing';

const withLabel = (WrappedComponent) => ({ label, placeholderLabel, labelTestRef, ...props }) => (
  <Fragment>
    {placeholderLabel ? null : (
      <Spacing top="normal" bottom="x-small">
        <FormLabel testRef={labelTestRef}>{label}</FormLabel>
      </Spacing>
    )}
    <WrappedComponent
      {...(placeholderLabel ? { placeholder: label } : {})}
      {...props}
    />
  </Fragment>
);

export default withLabel;
