import { isMemberFromCookie } from 'src/utils/user';
import { scrollToTop } from 'src/browser/window';
import { dashboardUrl, loginUrl, logoutUrl } from 'src/utils/pageUrls';
import { AccountSwitchMeta } from 'src/components/meta/LoginMeta';
import OnboardingScreen, {
  OnboardingScreenHeader,
} from 'src/components/oldOnboarding/common/onboardingScreen/OnboardingScreen';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import styles from 'src/components/oldOnboarding/login/Login.mscss';
import styled from 'styled-components';
import { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { userSelector } from 'scripts/redux/actions/user';
import { isUserLoggedIn } from 'scripts/utilities/userAccountHelper';
import history from 'src/browser/history';
import AccountType from 'scripts/constants/AccountType';
import AccountSwitchLoginPage from './AccountSwitchLoginPage';
import AddNewAccountPage from './AddNewAccountPage';
import {
  themeForBrickXMinorLandingPage,
  themeForIndividualAccount,
  themeForSmsfAccount,
} from 'src/themes/landingPageThemes';
import Storage from 'scripts/utilities/Storage';
import Styledlink from 'src/design/components/hyperlink/Styledlink';

const storage = new Storage(localStorage);

export const LoginBox = styled.div`
  min-width: 300px;
  border-radius: var(--bx-radius-lg);
  background: white;

  .login-header {
  }
  .signup-link,
  .signup-link a {
    font-size: 16px;
  }

  .header-button-group {
    text-align: left;
    margin-bottom: 10px;
  }
`;

const UserBox = styled.div`
  border-radius: var(--bx-radius-lg);
  border: 1px solid #eee;
  padding: 1.5rem;
  margin: 1rem 0;
  &:hover {
    cursor: pointer;
    border: 1px solid
      ${(props) =>
        props.themeColors
          ? props.themeColors.primary
          : 'var(--bx-color---pink-400)'};
    background-color: ${(props) =>
      props.themeColors
        ? props.themeColors.lightBackgroundPrimary
        : 'var(--bx-color---pink-50)'};
  }
  .flexBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .icon-part {
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    font-size: 2rem;
    border: 1px solid #ddd;
  }

  .user-name-and-badge {
    display: flex;
    align-items: center;
  }
  .user-badge {
    margin-left: 5px;
  }
  .user-name {
    font-size: 2rem;
    font-weight: bold;
    text-align: left;
    margin-bottom: -5px;
  }
  .user-email {
    font-size: 1.5rem;
    text-align: left;
  }
  .right-link {
    font-size: 1.4rem;
    margin-left: 14px;
  }
`;

const UserBoxColorSchemes = (accountType) => {
  const colorSchemes = {
    [AccountType.MINOR]: themeForBrickXMinorLandingPage.colors,
    [AccountType.SMSF]: themeForSmsfAccount.colors,
    [AccountType.TRUST]: themeForSmsfAccount.colors,
    default: themeForIndividualAccount.colors,
  };
  if (accountType && accountType in colorSchemes) {
    return colorSchemes[accountType];
  }
  return colorSchemes.default;
};

const Pages = {
  AccountSwitchPage: {
    index: 0,
  },
  AccountSwitchLoginPage: {
    index: 1,
  },
  NewAccountLoginPage: {
    index: 2,
  },
};

const AccountTypeBadge = (accountType) => {
  const Badge = styled.span`
    display: inline-block;
    text-align: left;
    padding: 2px 5px;
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: 1px;
    background-color: ${(props) => props.color || 'var(--bx-color---pink-400)'};
    color: #fff;
    border-radius: 4px;
  `;
  if (accountType === AccountType.MINOR) {
    return <Badge color="var(--bx-color---yellow-700)">{accountType}</Badge>;
  }
  if (accountType === AccountType.SMSF) {
    return (
      <Badge color={themeForSmsfAccount.colors.primary}>{accountType}</Badge>
    );
  }
  return '';
};

/**
 *
 * @param {*} param0
 * @returns
 */
const AccountSwitchPage = ({ user }) => {
  const existingAccounts = storage.getAccountDetails();

  const [page, setPage] = useState(Pages.AccountSwitchPage.index);
  const [isMember, setIsMember] = useState(null);
  const [accountToSwitchDetails, setAccountToSwitchDetails] = useState({
    email: null,
    givenName: null,
  });
  const isLoggedIn = isUserLoggedIn(user);

  useEffect(() => {
    if (!isLoggedIn) history.push(loginUrl());
    setIsMember(isMemberFromCookie());
    scrollToTop();
  }, [isMember]);

  const onSwitchToAnotherAccount = ({ email, givenName }) => {
    setAccountToSwitchDetails({ email, givenName });
    setPage(Pages.AccountSwitchLoginPage.index);
  };

  const onAddNewAccount = () => {
    setPage(Pages.NewAccountLoginPage.index);
  };

  const onReturnToCurrentPage = () => {
    setAccountToSwitchDetails({ email: null, givenName: null });
    setPage(Pages.AccountSwitchPage.index);
  };

  if (page === Pages.AccountSwitchLoginPage.index) {
    return (
      <AccountSwitchLoginPage
        {...{
          email: accountToSwitchDetails.email,
          givenName: accountToSwitchDetails.givenName,
          onPreviousPage: onReturnToCurrentPage,
        }}
      />
    );
  }

  if (page === Pages.NewAccountLoginPage.index) {
    return (
      <AddNewAccountPage
        {...{
          email: accountToSwitchDetails.email,
          givenName: accountToSwitchDetails.givenName,
          onPreviousPage: onReturnToCurrentPage,
        }}
      />
    );
  }

  const otherAccounts = existingAccounts.filter(
    (ele) => ele.email !== user.email
  );

  return (
    <OnboardingScreen id="login" testRef="login-form" className={styles.panel}>
      <AccountSwitchMeta />
      <LoginBox>
        {!isLoggedIn && (
          <OnboardingScreenHeader>
            <h2>Loading ...</h2>
          </OnboardingScreenHeader>
        )}
        {isLoggedIn && (
          <OnboardingScreenHeader>
            <Spacing bottom="large" top="large">
              <h4>Return to my Dashboard</h4>
            </Spacing>
            <UserBox
              onClick={() => {
                history.push(dashboardUrl());
              }}
            >
              <div className="flexBox">
                <div className="flexBox">
                  <div className="icon-part">
                    <i className="fa fa-home"></i>
                  </div>
                  <div>
                    <div className="user-name-and-badge">
                      <div className="user-name">{user.givenName}</div>
                      <div className="user-badge">
                        {AccountTypeBadge(user.accountType)}
                      </div>
                    </div>
                    <div className="user-email">{user.email}</div>
                  </div>
                </div>
                <div className="right-link flexBox">
                  <i className="fa fa-chevron-right"></i>
                </div>
              </div>
            </UserBox>
            {otherAccounts.length > 0 && (
              <Fragment>
                <Spacing bottom="large" top="large">
                  <h4>Switch to another account</h4>
                </Spacing>
                {otherAccounts.map((ele, idx) => (
                  <UserBox
                    key={idx}
                    onClick={() =>
                      onSwitchToAnotherAccount({
                        email: ele.email,
                        givenName: ele.givenName,
                      })
                    }
                    themeColors={UserBoxColorSchemes(ele.accountType)}
                  >
                    <div className="flexBox">
                      <div className="flexBox">
                        <div className="icon-part">
                          <i className="fa fa-user"></i>
                        </div>
                        <div>
                          <div className="user-name-and-badge">
                            <div className="user-name">{ele.givenName}</div>
                            <div className="user-badge">
                              {AccountTypeBadge(ele.accountType)}
                            </div>
                          </div>
                          <div className="user-email">{ele.email}</div>
                        </div>
                      </div>
                      <div className="right-link flexBox">
                        <div>
                          <i className="fa fa-chevron-right"></i>
                        </div>
                      </div>
                    </div>
                  </UserBox>
                ))}
              </Fragment>
            )}
            <UserBox onClick={onAddNewAccount}>
              <div className="flexBox">
                <div className="flexBox">
                  <div className="icon-part">
                    <i className="fa fa-plus"></i>
                  </div>
                  <div>
                    <div className="user-name">Add another account</div>
                    <div className="user-email"></div>
                  </div>
                </div>
              </div>
            </UserBox>
            {otherAccounts.length > 0 && (
              <div>
                <Styledlink
                  onClick={() => {
                    storage.removeAllAccountDetails();
                    setTimeout(() => window.location.replace(logoutUrl()), 400);
                  }}
                >
                  Remove all accounts and logout
                </Styledlink>
              </div>
            )}
          </OnboardingScreenHeader>
        )}
      </LoginBox>
    </OnboardingScreen>
  );
};

const mapStateToProps = (state) => ({
  ...userSelector(state),
});
export default connect(mapStateToProps, {})(AccountSwitchPage);
