import { location } from 'src/browser/window';
import { load, save, remove } from 'src/browser/cookies';

export const generateReferralsLink = ({ withProtocol = true, customerReferral }) => {
  //                                      ^  Mandrill needs links without a protocol
  const { token } = customerReferral;
  return !token
    ? null
    : withProtocol
    ? `${location.protocol}//${location.host}/referrals?token=${token}`
    : `${location.host}/referrals?token=${token}`;
};

export const getReferralCookie = () => {
  const referralCookie = load('referral');
  return referralCookie
    ? JSON.parse(referralCookie)
    : false;
};

export const getReferralPreActivationCookie = () => {
  const cookie = load('referral_pre_activation');
  return cookie
    ? JSON.parse(cookie)
    : false;
};

export const removeReferralCookie = () => remove('referral');

export const removeReferralPreActivationCookie = () => remove('referral_pre_activation');

export const saveReferralCookie = ({ referrerName, referralRecordId }) =>
  save('referral', JSON.stringify({ referrerName, referralRecordId }));

export const saveReferralPreActivationCookie = ({ referrerName }) =>
  save('referral_pre_activation', JSON.stringify({ referrerName }));
