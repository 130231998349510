import styled from 'styled-components';
import { breakpointSmall } from 'src/design/styleguide/common/breakpoints';
import { layoutStyles } from 'src/components/dashboard/common/styles';
import { testRefToDataTestReference } from 'src/design/styleguide/common/styledComponentsUtils';

const PageLayout = styled.div.attrs(testRefToDataTestReference)`
  ${breakpointSmall(`
    ${layoutStyles}
    grid-template-columns: minmax(0, 1fr);
  `)}
`;

export default PageLayout;
