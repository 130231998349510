import { createAction, handleActions } from 'redux-actions';
import update from 'immutability-helper';

import { errorAlert, successAlert } from 'scripts/redux/actions/alerts';

import UserService from 'scripts/services/UserService';

import ResetPasswordActionType from 'scripts/constants/ResetPasswordActionType';


const RESET_PASSWORD_REQUEST = 'scripts/user/RESET_PASSWORD';
const resetPasswordRequest = createAction(RESET_PASSWORD_REQUEST);

export function requestResetPasswordToken(email) {
  return async function (dispatch) {
    try {
      await UserService.requestResetPasswordToken(email);
      const greeting = 'Thank you. We have sent instructions to the email address entered.\n \nIf you do not receive an email, please try again ensuring you have correctly entered the email address linked to your BrickX account.';
      dispatch(successAlert(greeting));
      dispatch(resetPasswordRequest({ action: ResetPasswordActionType.REQUEST, success: true }));
    } catch (e) {
      dispatch(errorAlert(e.message));
      dispatch(resetPasswordRequest({ action: ResetPasswordActionType.REQUEST, success: false }));
    }
  };
}

export function validateResetPasswordToken(token) {
  return async function (dispatch) {
    try {
      await UserService.validateResetPasswordToken(token);
      dispatch(resetPasswordRequest({ action: ResetPasswordActionType.VALIDATE, success: true }));
    } catch (e) {
      dispatch(errorAlert(e.message));
      dispatch(resetPasswordRequest({ action: ResetPasswordActionType.VALIDATE, success: false }));
    }
  };
}

export function processResetPassword(data, token) {
  return async function (dispatch) {
    try {
      await UserService.processResetPassword(data, token);
      dispatch(successAlert('Your password has been successfully changed.'));
      dispatch(resetPasswordRequest({ action: ResetPasswordActionType.PROCESS, success: true }));
    } catch (e) {
      dispatch(errorAlert(e.message));
      dispatch(resetPasswordRequest({ action: ResetPasswordActionType.PROCESS, success: false }));
    }
  };
}

const reducer = handleActions({
  [RESET_PASSWORD_REQUEST]: (state, action) => update(state, {
    result: {
      $set: action.payload
    }
  }),
}, {
  result: null
});

export default reducer;
