export const DEPOSIT_META = {
  title: 'Deposit Funds | Property Investment | BrickX',
  description: 'Deposit fund to you BrickX account',
  meta: {
    name: {
      keywords: 'deposit, add fund, put money into your BrickX account'
    }
  }
};

export const SUCCESS_DEPOSIT_META = {
  title: 'Successful Deposit | Property Investment | BrickX',
  description: 'Deposit fund to you BrickX account',
  meta: {
    name: {
      keywords: 'deposit, add fund, put money into your BrickX account'
    }
  }
};

export const SETUP_META = {
  title: 'Personal Details | Property Investment | BrickX',
  description: 'Setup your personal details',
  meta: {
    name: {
      keywords: 'setup, personal details, identity setup'
    }
  }
};

export const WITHDRAW_META = {
  title: 'Withdraw Funds | Property Investment | BrickX',
  description: 'Withdraw fund from your BrickX account',
  meta: {
    name: {
      keywords: ''
    }
  }
};

export const WITHDRAW_VERIFY_META = {
  title: 'Verify Withdrawal | Property Investment | BrickX',
  description: 'Verify your withdrawal using your mobile',
  meta: {
    name: {
      keywords: ''
    }
  }
};

export const WITHDRAW_REQUESTED_META = {
  title: 'Withdraw Requested | Property Investment | BrickX',
  description: 'Your Withdraw has been submitted',
  meta: {
    name: {
      keywords: ''
    }
  }
};

export const DASHBOARD_META = {
  title: 'Account Dashboard | Property Investment | BrickX',
  description: '',
  meta: {
    name: {
      keywords: ''
    }
  }
};

export const SETTINGS_META = {
  title: 'Personal Details | Property Investment | BrickX',
  description: '',
  meta: {
    name: {
      keywords: ''
    }
  }
};

export const PORTFOLIO_META = {
  title: 'My Portfolio | Property Investment | BrickX',
  description: '',
  meta: {
    name: {
      keywords: ''
    }
  }
};

export const TRANSACTION_HISTORY_META = {
  title: 'Transaction History | Property Investment | BrickX',
  description: '',
  meta: {
    name: {
      keywords: ''
    }
  }
};

export const DOCUMENTS_META = {
  title: 'My Documents | Property Investment | BrickX',
  description: '',
  meta: {
    name: {
      keywords: ''
    }
  }
};

export const PENDING_ORDERS_META = {
  title: 'My Pending Orders | Property Investment | BrickX',
  description: '',
  meta: {
    name: {
      keywords: ''
    }
  }
};

export const MY_TRADE_CONFIRMATION_META = {
  title: 'My Transaction Confirmation | Property Investment | BrickX',
  description: '',
  meta: {
    name: {
      keywords: ''
    }
  }
};

export const MY_DEPRECIATION_SCHEDULE_META = {
  title: 'My Depreciation Schedule | Property Investment | BrickX',
  description: '',
  meta: {
    name: {
      keywords: ''
    }
  }
};


export const ANNUAL_INDIVIDUAL_STATEMENT_META = {
  title: 'Annual Individual Statement | Property Investment | BrickX',
  description: '',
  meta: {
    name: {
      keywords: ''
    }
  }
};
