import FormCheckbox from 'src/design/components/formCheckbox/FormCheckbox';
import DirectDebitRequestServiceAgreementLink from 'src/components/oldOnboarding/smartInvestDetails/bankDetailsInput/DirectDebitRequestServiceAgreementLink';


const DirectDebitRequestServiceAgreementCheckbox = ({ disabled, ...props }) => {
  const link = (
    <DirectDebitRequestServiceAgreementLink
      disabled={disabled}
      testRef="direct-debit-request-service-agreement-link"
    >
      The Brick Exchange Pty Ltd Direct Debit Request Service Agreement available here
    </DirectDebitRequestServiceAgreementLink>
  );

  return (
    <FormCheckbox
      disabled={disabled}
      {...props}
    >
      I have read and agree to {link}.
    </FormCheckbox>
  );
};

export default DirectDebitRequestServiceAgreementCheckbox;
