// TODO: does not belong in constants, move it to config
import moneyOrPercent from 'scripts/utilities/moneyOrPercent';
import { htmlEncoded } from 'scripts/utilities/formatters';

export const colors = {
  orange: 'rgba(255,130,130,1)',
  orangeGradientStops: [
    [0, 'rgba(255,130,130,0.4)'],
    [1, 'rgba(255,130,130,0.15)']
  ],
};

export default {
  options: {
    chart: {
      type: 'spline',
      backgroundColor: '#FFFFFF'
    },
    plotOptions: {
      series: {
        stacking: ''
      },
      areaspline: {
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1
          },
          stops: colors.orangeGradientStops,
        },
        threshold: null,
      },
    },
    tooltip: {
      formatter: function () {
        return `<b>${this.x::htmlEncoded()}</b>: ${moneyOrPercent(this.y)::htmlEncoded()}`;
      }
    }
  },
  series: [],
  title: false,
  credits: {
    enabled: false
  },
  loading: false,
  xAxis: {
    allowDecimals: false
  },
  yAxis: {
    title: false,
    opposite: true,
    labels: {
      formatter: function () {
        return moneyOrPercent(this.value)::htmlEncoded();
      }
    }
  }
};
