

import classNames from 'classnames';

import Icon from 'scripts/components/shared/Icon';
import { property as propertyPropType, user as userPropType } from 'scripts/constants/PropTypes';
import FinancialsPanel from 'scripts/components/shared/FinancialsPanel';
import { hasSetupPersonalDetails } from 'scripts/utilities/registrationHelper';

const DueDiligenceAndDocuments = ({ user, property }) => (
  <FinancialsPanel title="Due Diligence & Key Documents">
    <div className="row">
      <ul>
        {property.documents.map((doc, index) => {
          const canDownloadDocument = hasSetupPersonalDetails(user);
          return (
            <div key={index}>
              {canDownloadDocument
                ?
                <a href={doc.url} target="_blank" title={doc.type} className="document">
                  <Icon type="fa" className="fa-file-text" name="cloud-download"/>
                  {doc.type}
                </a>
                :
                <a title={doc.type} className={classNames('document', 'inactive')}>
                  <Icon type="fa" className="fa-file-text" name="lock"/>
                  {doc.type}
                </a>
              }
            </div>
          );
        })}
      </ul>
    </div>
    <div className="row">
      <div className="col-md-12">
        <div className="note">
          {!user.loggedIn && 'Sign up or login to gain access to these due diligence documents.'}
        </div>
      </div>
    </div>
  </FinancialsPanel>
);

DueDiligenceAndDocuments.propTypes = {
  user: userPropType,
  property: propertyPropType,
};

export default DueDiligenceAndDocuments;
