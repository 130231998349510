/**
 * Validate Email Address
 *
 * @param {string} string
 * @returns
 */
export const validateEmailAddress = (string) => {
  return /^[^@]+@[^@]+\.[^@]+$/.test(string);
};

/**
 * validate ABN
 *
 * @param {string} abnInput
 * @returns
 */
export const validateAbn = (abnInput) => {
  if (abnInput.length !== 11) {
    return {
      valid: false,
      reason: `The ABN should contain exactly 11 digits, but contains ${abnInput.length} digits`,
    };
  }

  const weights = new Array(10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19);
  const abn =
    parseInt(abnInput.substring(0, 1), 10) - 1 + abnInput.substring(1);
  let total = 0;
  const checkResult = {};
  for (let i = 0; i < abn.length; i++) {
    total += weights[i] * parseInt(abn.charAt(i), 10);
  }
  if (total % 89 === 0) {
    checkResult.valid = true;
  } else {
    checkResult.valid = false;
    checkResult.reason =
      'This ABN number is not valid. Please check and try again.';
  }

  return checkResult;
};

/**
 * Validate TFN (Australian Tax File Number)
 *
 * @param {string} tfnInput
 * @returns boolean
 */
export const validateTfn = (tfnInput) => {
  const nineDigitTfnWeights = [1, 4, 3, 7, 5, 8, 6, 9, 10];
  const eightDigitTfnWeights = [10, 7, 8, 4, 6, 3, 5, 1, 0];
  let checkResult;

  const validate = (digits, weights) => {
    const sum = digits.reduce((p, v, i) => p + v * weights[i], 0);
    return sum === 0 || sum % 11 !== 0
      ? 'This TFN number is not valid. Please check and try again.'
      : false;
  };

  const digits = tfnInput.replace(/[^\d]/g, '').split('').map(Number);
  if (digits.length === 8) {
    checkResult = validate(digits, eightDigitTfnWeights);
  } else if (digits.length === 9) {
    checkResult = validate(digits, nineDigitTfnWeights);
  } else {
    checkResult = `TFN should contain exactly 8 or 9 digits, but contains ${digits.length} digits`;
  }

  return checkResult;
};

export const validateName = (string) => /^[A-Za-z ,.'-]{0,50}$/.test(string);
export const validatePassword = (string) =>
  /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,254}$/.test(string);
export const isNumber = (number) => !isNaN(parseFloat(number));

export const valideAustralianMobileNumber = (str) =>
  /^0?[0-8]\d{3} ?\d{3} ?\d{3}$/.test(str);
