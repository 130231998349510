import classNames from 'classnames';
import { connect } from 'react-redux';

import withState from 'src/decorators/withState';
import { SUCCESS, WARNING, ERROR } from 'src/components/toasts/toastTypes';

import {
  dismissToast as dismissToastAction,
} from 'src/components/toasts/toastActions';
import Icon from 'src/design/components/icon/Icon';

import styles from 'src/components/toasts/Toasts.mscss';

const toastIcons = {
  error: Icon.types.error_alert,
  warning: Icon.types.info_outline,
  success: Icon.types.check,
};

const Toast = connect(null, {
  dismissToast: dismissToastAction,
})(withState({
  Component: ({
    type,
    id,
    text,
    dismissToast,
    exitAnimation,
    testRef,
    closeButton,
  }) => {
    return (
      <li
        className={classNames(styles.toast, {
          [styles.exitAnimation]: exitAnimation,
        })}
        data-test-reference={testRef}
      >
        <Icon
          type={toastIcons[type]}
          className={classNames(styles.icon, styles[`icon-${type}`])}
          testRef="toast-icon"
        />
        <span className={styles.toastText}>{text}</span>
        {closeButton ? (
          <a
            data-test-reference="toast-x-button"
            className={styles.dismissToastButton}
            onClick={() => dismissToast({ id })}
          >
            <Icon type={Icon.types.close} className={styles['dismissToastButton--icon']} />
          </a>
        )
        : null}
      </li>
    );
  },
  componentDidMount: ({ id, dismissToast, duration = 4000 }) =>
    setTimeout(() => dismissToast({ id }), duration),
}));

Toast.types = { SUCCESS, WARNING, ERROR };

export const ErrorToast = ({ ...props }) => <Toast type={ERROR} duration={7000} closeButton {...props} />;
export const WarningToast = ({ ...props }) => <Toast type={WARNING} {...props} />;
export const SuccessToast = ({ ...props }) => <Toast type={SUCCESS} {...props} />;
