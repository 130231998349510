import styled from 'styled-components';
import { BodyTableRow, Table, TableData } from 'src/design/components/table/Table';
import { hasNominatedBankAccount, hasTfnProvided, hasMinorDetails } from 'scripts/utilities/userAccountHelper';
import { user as userPropType } from 'scripts/constants/PropTypes';
import AccountType from 'scripts/constants/AccountType';

const MyDetailsTableData = styled(TableData)`
  &:first-child {
    padding-left: 0;
  }
`;

const WrappingTableData = styled(MyDetailsTableData)`
  white-space: normal;
`;

const MyDetails = ({ user, businessDetails }) => {
  if (!user.givenName) {
    return null;
  }

  const showMinorDetails = hasMinorDetails(user);

  if (showMinorDetails) {
    return (
      <Table>
        <tbody>
          <BodyTableRow>
            <MyDetailsTableData><strong>Minor's Details:</strong></MyDetailsTableData>
          </BodyTableRow>
          <BodyTableRow>
            <MyDetailsTableData>Trust name</MyDetailsTableData>
            <WrappingTableData>{user.minorDetails.trustName}</WrappingTableData>
          </BodyTableRow>
          <BodyTableRow>
            <MyDetailsTableData>Given name</MyDetailsTableData>
            <WrappingTableData>{user.minorDetails.firstName}</WrappingTableData>
          </BodyTableRow>
          <BodyTableRow>
            <MyDetailsTableData>Middle name</MyDetailsTableData>
            <WrappingTableData>{user.minorDetails.middleName}</WrappingTableData>
          </BodyTableRow>
          <BodyTableRow>
            <MyDetailsTableData>Family name</MyDetailsTableData>
            <WrappingTableData>{user.minorDetails.lastName}</WrappingTableData>
          </BodyTableRow>
          <BodyTableRow>
            <MyDetailsTableData>Date of Birth</MyDetailsTableData>
            <WrappingTableData>{user.minorDetails.dateOfBirth}</WrappingTableData>
          </BodyTableRow>
          <BodyTableRow>
            <MyDetailsTableData><strong>Adult's Details:</strong></MyDetailsTableData>
          </BodyTableRow>
          <BodyTableRow>
            <MyDetailsTableData>First name</MyDetailsTableData>
            <WrappingTableData>{user.givenName}</WrappingTableData>
          </BodyTableRow>
          <BodyTableRow>
            <MyDetailsTableData>Middle name</MyDetailsTableData>
            <WrappingTableData>{user.middleName || '-'}</WrappingTableData>
          </BodyTableRow>
          <BodyTableRow>
            <MyDetailsTableData>Last name</MyDetailsTableData>
            <WrappingTableData>{user.familyName}</WrappingTableData>
          </BodyTableRow>
          <BodyTableRow>
            <MyDetailsTableData>E-mail</MyDetailsTableData>
            <WrappingTableData>{user.email}</WrappingTableData>
          </BodyTableRow>
          <BodyTableRow>
            <MyDetailsTableData>Mobile</MyDetailsTableData>
            <WrappingTableData>{user.mobilePhone ?
              user.mobilePhone : 'Not Provided'
            }</WrappingTableData>
          </BodyTableRow>
          <BodyTableRow>
            <MyDetailsTableData>Address</MyDetailsTableData>
            <WrappingTableData>{user.address ?
              user.address : 'Not Provided'
            }</WrappingTableData>
          </BodyTableRow>
          <BodyTableRow>
            <MyDetailsTableData>Date of birth</MyDetailsTableData>
            <WrappingTableData>
              <span>
                {user.dateOfBirth
                  ?
                  'Provided'
                  :
                  'Not Provided'
                }
              </span>
            </WrappingTableData>
          </BodyTableRow>
          <BodyTableRow>
            <MyDetailsTableData>TFN</MyDetailsTableData>
            <WrappingTableData>
              {hasTfnProvided(user)
                ?
                'Provided'
                :
                'Not Provided'
              }
            </WrappingTableData>
          </BodyTableRow>
          { hasNominatedBankAccount(user) &&
          <BodyTableRow>
            <MyDetailsTableData>Withdraw Account BSB</MyDetailsTableData>
            <WrappingTableData>
              <span>{user.financialDetails.bankAccountDetails.BSB}</span>
            </WrappingTableData>
          </BodyTableRow>
          }
          { hasNominatedBankAccount(user) &&
          <BodyTableRow>
            <MyDetailsTableData>Withdraw Account Number</MyDetailsTableData>
            <WrappingTableData>
              <span>{user.financialDetails.bankAccountDetails.bankAccountNumber}</span>
            </WrappingTableData>
          </BodyTableRow>
          }
        </tbody>
      </Table>
    );
  }

  return (
    <Table>
      <tbody>
        <BodyTableRow>
          <MyDetailsTableData>First name</MyDetailsTableData>
          <WrappingTableData>{user.givenName}</WrappingTableData>
        </BodyTableRow>
        <BodyTableRow>
          <MyDetailsTableData>Last name</MyDetailsTableData>
          <WrappingTableData>{user.familyName}</WrappingTableData>
        </BodyTableRow>
        <BodyTableRow>
          <MyDetailsTableData>E-mail</MyDetailsTableData>
          <WrappingTableData>{user.email}</WrappingTableData>
        </BodyTableRow>
        <BodyTableRow>
          <MyDetailsTableData>Mobile</MyDetailsTableData>
          <WrappingTableData>{user.mobilePhone ?
            user.mobilePhone : 'Not Provided'
          }</WrappingTableData>
        </BodyTableRow>
        <BodyTableRow>
          <MyDetailsTableData>Address</MyDetailsTableData>
          <WrappingTableData>{user.address ?
            user.address : 'Not Provided'
          }</WrappingTableData>
        </BodyTableRow>
        <BodyTableRow>
          <MyDetailsTableData>Date of birth</MyDetailsTableData>
          <WrappingTableData>
            <span>
              {user.dateOfBirth
                ?
                'Provided'
                :
                'Not Provided'
              }
            </span>
          </WrappingTableData>
        </BodyTableRow>
        {user.accountType !== AccountType.INDIVIDUAL &&
        <BodyTableRow>
          <MyDetailsTableData>Entity type</MyDetailsTableData>
          <WrappingTableData>{user.accountType}</WrappingTableData>
        </BodyTableRow>
        }
        {user.isBusinessAccount &&
        <BodyTableRow>
          <MyDetailsTableData>Entity name</MyDetailsTableData>
          <WrappingTableData>{businessDetails.entityName}</WrappingTableData>
        </BodyTableRow>
        }
        {user.isBusinessAccount &&
        <BodyTableRow>
          <MyDetailsTableData>ABN</MyDetailsTableData>
          <WrappingTableData>{businessDetails.abn}</WrappingTableData>
        </BodyTableRow>
        }
        <BodyTableRow>
          <MyDetailsTableData>TFN</MyDetailsTableData>
          <WrappingTableData>
            {hasTfnProvided(user)
              ?
              'Provided'
              :
              'Not Provided'
            }
          </WrappingTableData>
        </BodyTableRow>
        { hasNominatedBankAccount(user) &&
        <BodyTableRow>
          <MyDetailsTableData>Withdraw Account BSB</MyDetailsTableData>
          <WrappingTableData>
            <span>{user.financialDetails.bankAccountDetails.BSB}</span>
          </WrappingTableData>
        </BodyTableRow>
        }
        { hasNominatedBankAccount(user) &&
        <BodyTableRow>
          <MyDetailsTableData>Withdraw Account Number</MyDetailsTableData>
          <WrappingTableData>
            <span>{user.financialDetails.bankAccountDetails.bankAccountNumber}</span>
          </WrappingTableData>
        </BodyTableRow>
        }
      </tbody>
    </Table>
  );
};

MyDetails.propTypes = {
  user: userPropType.isRequired
};

export default MyDetails;
