import React from 'react';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import Configuration from 'scripts/constants/Configuration';
import { property as propertyPropType } from 'scripts/constants/PropTypes';
import { renderCode } from 'src/settings/properties';

const PropertySummaryMetaTags = ({ property, url }) => {
  const socialShare = {
    name: `My New Property Investment in ${property.suburb}`,
    description: `BrickX offers a secure, simple, and low cost way to get into the property market. Invest in AU residential properties today for under $100.`,
    url: url,
    picture: _.isEmpty(property.propertyImages)
      ? Configuration.PROPERTY_THUMB_PLACEHOLDER
      : property.propertyImages[0].optimizedUrl,
  };
  return (
    <Helmet key="summary-helmet">
      <title>{`${renderCode(
        property.propertyCode
      )} Property Summary | BrickX`}</title>
      <meta name="description" content={socialShare.description} />
      <meta property="keywords" content="property,properties,invest" />
      <meta property="og:title" content={socialShare.name} />
      <meta property="og:description" content={socialShare.description} />
      <meta id="og-image" property="og:image" content={socialShare.picture} />
      <meta property="og:image:secure_url" content={socialShare.picture} />
      <meta property="og:url" content={socialShare.url} />
      <meta property="og:image:alt" content="trade succeed" />
      <meta name="twitter:description" content={socialShare.description} />
      <meta name="twitter:url" content={socialShare.url} />
      <meta name="twitter:title" content={socialShare.name} />
      <meta name="twitter:image:src" content={socialShare.picture} />
    </Helmet>
  );
};

PropertySummaryMetaTags.prototype = {
  property: propertyPropType,
};

export default PropertySummaryMetaTags;
