import request from 'scripts/services/request';
import { latestStats as stats, recentPropertyTransactions as propertyTransactions } from 'scripts/services/api';


class StatisticsService {
  getLatestStats() {
    return request(stats());
  }

  getRecentPropertyTransactions() {
    return request(propertyTransactions());
  }
}

export default new StatisticsService();
