import request from 'scripts/services/request';
import {
  indexContents,
  media,
  webinar,
  keyDates,
  helpfulLinks,
  propertiesRg46,
  latestDistributions,
  distributions,
} from 'scripts/services/api';
import { withCredentials } from 'scripts/services/helpers';

class ContentsService {
  getIndexContents() {
    return request(indexContents(), { ...withCredentials });
  }

  getMedia() {
    return request(media(), { ...withCredentials });
  }

  getWebinar() {
    return request(webinar(), { ...withCredentials });
  }

  getKeyDates() {
    return request(keyDates(), { ...withCredentials });
  }

  getHelpfulLinks() {
    return request(helpfulLinks(), { ...withCredentials });
  }

  getPropertiesRg46() {
    return request(propertiesRg46(), { ...withCredentials });
  }

  getLatestDistributions() {
    return request(latestDistributions(), { ...withCredentials });
  }

  getAllDistributions() {
    return request(distributions(), { ...withCredentials });
  }
}

export default new ContentsService();
