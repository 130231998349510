import withState from 'src/decorators/withState';

import WelcomeGreeting from 'src/components/oldDashboard/welcomeWidgets/WelcomeGreeting';
import DepositFundsDriver from 'src/components/oldDashboard/welcomeWidgets/DepositFundsDriver';

const screens = {
  WelcomeGreeting: { next: 'DepositFundsDriver' },
  DepositFundsDriver: {},
};

const WelcomeScreens = ({ setState, state, onFinish }) => {
  const { currentScreen } = state;
  const goToNextScreen = () => {
    setState({ currentScreen: screens[currentScreen].next });
  };

  const renderedScreens = {
    'WelcomeGreeting': <WelcomeGreeting onNext={goToNextScreen} />,
    'DepositFundsDriver': <DepositFundsDriver onNext={onFinish} />,
  };

  return renderedScreens[currentScreen];
};

export default withState({
  Component: WelcomeScreens,
  mapPropsToInitialState: () => ({
    currentScreen: 'WelcomeGreeting',
  }),
});
