import Meta from 'src/components/meta/Meta';

const LoginMeta = () => (
  <Meta
    title="Login | BrickX"
    description="Login to your BrickX account to view your property portfolio."
  />
);

export const AccountSwitchMeta = () => (
  <Meta
    title="Switch Account | BrickX"
    description="Switch to another account or add a new account"
  />
);

export default LoginMeta;
