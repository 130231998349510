import styled from 'styled-components';
import { SubHeading } from 'src/design/styleguide/Headings';
import { breakpointLarge, breakpointMedium, breakpointXSmall } from 'src/design/styleguide/common/breakpoints';
import { gridUnit } from 'src/design/styleguide/common/measurements';
import Hyperlink from 'src/design/components/hyperlink/Hyperlink';
import Icon from 'src/design/components/icon/Icon';
import Paragraph from 'src/design/styleguide/Paragraph';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import SecondaryButton from 'src/design/components/button/SecondaryButton';
import Spacing, { getSizePx } from 'src/design/styleguide/spacing/Spacing';
import Text from 'src/design/styleguide/text/Text';
import WidgetPanel from 'src/components/dashboard/common/WidgetPanel';

export const WelcomePageWidgetPanel = styled(WidgetPanel).attrs((props) => ({
  rootClassName: props.className,
  alwaysHaveShadow: true,
  titleAlign: 'left',
}))``;

export const LinkText = styled(Text)`
  display: flex;
  align-items: center;
`;

export const WidgetPanelLink = ({ text, href, testRef }) => (
  <Hyperlink href={href} testRef={testRef}>
    <LinkText
      fontSize="3x-small"
      color="onSurfaceContrast500"
      lineHeight="none"
      >
        {text}
        <Spacing left="x-small">
          <Icon type="arrow_forward"/>
        </Spacing>
      </LinkText>
  </Hyperlink>
);

export const SeeMoreLink = ({ href, testRef }) => <WidgetPanelLink text="See more" href={href} testRef={testRef}/>;

export const HorizontalAtMediumBreakpointContainer = styled.div`
  ${breakpointXSmall(`
    display: flex;
    align-items: flex-start;
  `)}
  ${breakpointMedium(`display: block;`)}
  ${breakpointLarge(`display: flex;`)}
`;

export const TotalValueContainer = styled.div`
  flex: 1 0 0px;
  text-align: center;

  ${breakpointXSmall(`text-align: left;`)}
  ${breakpointMedium(`text-align: center;`)}
  ${breakpointLarge(`text-align: left;`)}
`;

const horizontalSpacing = `
  margin-top: ${getSizePx('none')};
  margin-left: ${getSizePx('normal')};
`;

const verticalSpacing = `
  margin-top: ${getSizePx('x-large')};
  margin-left: ${getSizePx('none')};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 0px;
  margin-top: ${getSizePx('x-large')};

  ${breakpointXSmall(horizontalSpacing)}
  ${breakpointMedium(verticalSpacing)}
  ${breakpointLarge(horizontalSpacing)}
`;

const buttonWithLessHorizontalPadding = (WrappedComponent) => styled(WrappedComponent)`
  && {
    padding-left: ${gridUnit * 2}px;
    padding-right: ${gridUnit * 2}px;
  }
`;

export const PrimaryButtonWithLessPadding = buttonWithLessHorizontalPadding(PrimaryButton);
export const SecondaryButtonWithLessPadding = buttonWithLessHorizontalPadding(SecondaryButton);

export const PanelValueHeading = ({ className, children }) => (
  <SubHeading
    as="h3"
    className={className}
    fontSize="3x-small"
    fontWeight="regular"
    color="onSurfaceContrast500"
    align="inherit"
  >
    {children}
  </SubHeading>
);

export const PanelValueText = ({ className, children, testRef }) => (
  <Paragraph
    className={className}
    fontSize="small"
    fontWeight="bold"
    color="onSurfaceContrast800"
    testRef={testRef}
  >
    {children}
  </Paragraph>
);
