import { createAction, handleActions } from 'redux-actions';
import update from 'immutability-helper';
import FacebookService from 'scripts/services/FacebookService';
import { errorAlert } from 'scripts/redux/actions/alerts';

const PAGE_INFO = 'scripts/facebook/PAGE_INFO';

export const pageInfoAction = createAction(PAGE_INFO);


export function fetchFacebookPageInfo() {
  return async function (dispatch) {
    try {
      const payload = await FacebookService.getFacebookPageInfo();
      return dispatch(pageInfoAction(payload));
    } catch (e) {
      dispatch(errorAlert(e.message));
    }
  };
}


const reducer = handleActions({
  [PAGE_INFO]: (state, action) => update(state, {
    $set: action.payload
  })
}, {
  facebook: null
});


export default reducer;
