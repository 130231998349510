import { transactionFeeText } from 'src/settings/text';
import TermsPage from 'src/components/terms/TermsPage';
import styles from 'src/components/terms/Terms.mscss';

const FeesPromo2018 = () => (
  <TermsPage title="No Fees to Buy Bricks Promo - Terms and conditions">
    <ol className={styles.termsList}>
      <li>This promotion is offered by The Brick Exchange Pty Limited (ABN 27 600 762 749) (BrickX) as the manager of the BrickX Platform (ARSN 613 156 433). Theta Asset Management Ltd (ABN 37 071 807 684, AFSL 230920) (Theta) is the product issuer for the BrickX Platform, but is not responsible for offering or administering this BrickX promotion. </li>
      <li>The transaction fee of {transactionFeeText()} on Brick purchases is being waived and will be fully funded by BrickX from its own funds and will not be sourced from the assets of the BrickX Platform or any BrickX Trust. </li>
      <li>The usual {transactionFeeText()} transaction fee will apply to the sale of Bricks. To be eligible for the transaction fee-free purchase you must be or become a Member of the BrickX Platform. </li>
      <li>This promotion opens at 10:00am AEDT on Thursday 8th November 2018 and closes at 11:59pm AEDT on Tuesday 20th November 2018. </li>
      <li>You should consider any tax implications and we recommend you seek independent tax advice. </li>
      <li>Capitalised terms have the meaning given in the BrickX Platform product disclosure statement unless otherwise defined. </li>
      <li>Please note that BPAY payments can take up to 1-2 business days to be received and appear as cleared funds in your Digital Wallet. Direct Debits take up to 4 business days to clear. Payments can also be made through POLi to provide instant funds (up to $20,000).</li>
      <li>All questions relating to this promotion should be directed to BrickX at info@brickx.com.</li>
    </ol>
  </TermsPage>
);

export default FeesPromo2018;
