const oneSecond = 1000;
const oneMinute = oneSecond * 60;
const oneHour = oneMinute * 60;
const oneDay = oneHour * 24;

const addAllDaysInBetweenDataPoints = (data) => {
  return data.reduce((acc, point, index, originalList) => {
    if (index === 0) { return [...acc, point]; }
    const previousPoint = originalList[index - 1];
    const previousTimestamp = previousPoint.date;
    const thisTimestamp = point.date;
    const daysBetweenPreviousTimestamp = ((thisTimestamp - previousTimestamp) / oneDay) - 1;
    const pointsBetween = Array(daysBetweenPreviousTimestamp).fill()
      .map((_, zeroIndexedDayNumber) => ({
        value: previousPoint.value,
        date: previousTimestamp + (oneDay * (zeroIndexedDayNumber + 1))
      }));
    return [...acc, ...pointsBetween, point];
  }, []);
};

export default addAllDaysInBetweenDataPoints;
