import { transactionFeeText } from 'src/settings/text';
import PromoBanner from 'src/components/promos/PromoBanner';
import Styledlink from 'src/design/components/hyperlink/Styledlink';

const FeePromoBanner = ({ className }) => (
  <PromoBanner className={className}>
    <span style={{ textDecoration: 'line-through' }}>
      {transactionFeeText()}
    </span>{' '}
    <strong>0% transaction fee</strong> when you buy or sell Bricks until 31st
    December 2022.{' '}
    <Styledlink href="/terms/fees-promo-2022" target="_blank">
      T&Cs apply
    </Styledlink>
    .
  </PromoBanner>
);

export default FeePromoBanner;
