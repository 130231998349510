import classNames from 'classnames';
import PropTypes from 'prop-types';
import Icon from 'src/design/components/icon/Icon';

const ReactModal = ({ testRef, title, toggle, children, isOpen, lightBackground, hideCloseBtn }) => {
  if (!isOpen) return null;
  return (
    <div
      className="react-modal-wrapper"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      data-test-reference={testRef}
    >
      <div className={classNames('react-modal', { 'react-modal--light': lightBackground })}>
        { !hideCloseBtn && <Icon className={classNames('react-modal__close-icon', { 'react-modal__close-icon--light': lightBackground })} type="close" onClick={toggle}/> }
        {
          title &&
          <div className="react-modal__header">
            <h2>{title}</h2>
          </div>
        }
        <div className="react-modal__body">
          {children}
        </div>
      </div>
    </div>
  );
};

ReactModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  title: PropTypes.string
};

export default ReactModal;
