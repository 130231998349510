import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateUserId as updateUserIdAction } from 'src/components/flows/store/flowsActions';
import ExtraInfoStep from 'src/rentToBuy/ExtraInfoStep';
import Flows from 'src/components/flows/Flows';
import RentToBuyHome from 'src/rentToBuy/RentToBuyHome';
import ThanksForYourInterest from 'src/rentToBuy/ThanksForYourInterest';

const getComponentForStepId = (stepId) => {
  switch (stepId) {
    case 'register-interest':
      return RentToBuyHome;
    case 'extra-info':
      return ExtraInfoStep;
    default:
      return ThanksForYourInterest;
  }
};

@connect(null, { updateUserId: updateUserIdAction })
class RentToBuyFlow extends Component {
  static propTypes = {
    updateUserId: PropTypes.func,
    location: PropTypes.shape({
      query: PropTypes.shape({ userId: PropTypes.string }),
    }),
  };

  constructor(props) {
    super(props);

    const userId = props.location.query.userId;
    userId && this.props.updateUserId(userId);
  }

  render() {
    return (
      <Flows
        flowType="smart-rent"
        getComponentForStepId={getComponentForStepId}
        loading={<RentToBuyHome />}
        {...this.props}
      />
    );
  }
}

export default RentToBuyFlow;
