import { kebabCase, snakeCase } from 'lodash';
import planTypes from 'src/enums/planTypes';

export const toPlanName = (planType) => ({
  [planTypes.SMART_INVEST]: 'Smart Invest',
  [planTypes.BUILD_YOUR_OWN]: 'Build My Own',
}[planType]);
export const toPlanTypeUrlComponent = (planType) => kebabCase(planType);
export const fromPlanTypeUrlComponent = (planTypeUrlComponent) => snakeCase(planTypeUrlComponent);
export const hasAtLeastOnePlan = (account) => account.activatedPlans.length > 0;
export const hasMoreThanOnePlan = (account) => account.activatedPlans.length > 1;
export const getPlans = (account) =>
  account.activatedPlans.map(planId =>
    account.plans.find((plan) =>
      plan.planSubscription.planSubscriptionId === planId
    )
  );
export const getSmartInvestPlan = (account) =>
  account.plans.find((plan) =>
    plan.planSubscription.planType === planTypes.SMART_INVEST
  );
export const getBuildYourOwnPlan = (account) =>
  account.plans.find((plan) =>
    plan.planSubscription.planType === planTypes.BUILD_YOUR_OWN
  );
export const getPlanFunds = (plan) => plan ? plan.availableToTradeBalance : 0;
export const planIsActive = (plan) => plan && !plan.planSubscription.cancelledAt;
export const planIsCancelled = (plan) => plan && plan.planSubscription.cancelledAt;
export const planWasNeverSignedUpTo = (plan) => !plan;
export const hasFinishedOnboarding = (account) => hasAtLeastOnePlan(account);
export const hasAtLeastOnePropertyOwned = (account) => account.aggregatedPortfolio.records.length > 0;
export const hasMoreThanOnePropertyOwned = (account) => account.aggregatedPortfolio.records.length > 1;
