export const Formatter = {
  MONEY: 'MONEY',
};

const NumberFormatter = ({
  value,
  formatter,
  decimal,
  prefix,
  suffix,
  withSeparator,
}) => {
  if (value === undefined) return value;
  const decimal_ = decimal !== undefined ? decimal : 2;
  let value_ = value;
  if (formatter === Formatter.MONEY) {
    if (value >= 1.0e9) value_ = `${(value_ / 1.0e9).toFixed(decimal_)}B`;
    else if (value >= 1.0e6) value_ = `${(value_ / 1.0e6).toFixed(decimal_)}M`;
    else if (value >= 1.0e3) value_ = `${(value_ / 1.0e3).toFixed(decimal_)}K`;
    else value_ = `${value_.toFixed(decimal_)}`;
  } else {
    value_ = `${value_.toFixed(decimal_)}`;
  }
  if (withSeparator) {
    value_ = value_.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return `${prefix || ''}${value_}${suffix || ''}`;
};

export default NumberFormatter;
