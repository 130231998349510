import styled from 'styled-components';
import { Heading, SubHeading } from 'src/design/styleguide/Headings';
import { rentToBuyFlyerUrl } from 'src/utils/pageUrls';
import ContentBlock from 'src/design/styleguide/contentBlock/ContentBlock';
import Image from 'src/design/components/image/Image';
import Paragraph from 'src/design/styleguide/Paragraph';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import Styledlink from 'src/design/components/hyperlink/Styledlink';

const PurpleChampaignGlassesContainer = styled.div`
  max-width: 100px;
  margin-right: auto;
  margin-left: auto;
`;

const PurpleChampaignGlasses = ({ className }) => (
  <PurpleChampaignGlassesContainer className={className}>
    <Image src="/static/img/icon-purple-champaign-glasses.svg"/>
  </PurpleChampaignGlassesContainer>
);

const ThanksForYourInterest = () => (
  <ContentBlock
    topPadding="small"
    bottomPadding="small"
    width="small"
    verticallyCentered
    testRef="thanks-for-your-interest-page"
  >
    <PurpleChampaignGlasses/>

    <Spacing top="x-large">
      <Heading>Thanks!</Heading>
    </Spacing>

    <Spacing top="x-large">
      <SubHeading fontSize="x-small" color="primary" lineHeight="large">Avenue will be launching in early 2020.</SubHeading>
    </Spacing>

    <Spacing top="x-large">
      <Paragraph align="center">Your email has been added to our database and we will provide updates as they become available. In the meantime, take a look at the <Styledlink href={rentToBuyFlyerUrl()} target="_blank">Avenue Flyer</Styledlink> and if you have any questions, please contact us at <Styledlink href="mailto:info@brickx.com">info@brickx.com</Styledlink>.</Paragraph>
    </Spacing>
  </ContentBlock>
);

export default ThanksForYourInterest;
