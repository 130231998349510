import RegistrationStep from 'scripts/constants/RegistrationStep';

export const hasSetupPersonalDetails = (user) => {
  const registrationStep = user.nextRegistrationStep;
  return registrationStep === RegistrationStep.DEPOSIT || registrationStep === RegistrationStep.INVEST || registrationStep === RegistrationStep.DONE;
};

export const hasDeposited = (user) => {
  const registrationStep = user.nextRegistrationStep;
  return registrationStep === RegistrationStep.INVEST || registrationStep === RegistrationStep.DONE;
};

export const hasInvested = (user) => {
  const registrationStep = user.nextRegistrationStep;
  return registrationStep === RegistrationStep.DONE;
};

