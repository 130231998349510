import React, { Component } from 'react';
import DocumentMeta from 'react-document-meta';

import { SEPTEMBER_2018_FACEBOOK_COMPETITION_TERMS_AND_CONDITIONS } from 'scripts/metadata/statics';

import Styledlink from 'src/design/components/hyperlink/Styledlink';
import { privacyUrl } from 'src/utils/pageUrls';


export default class September2018FacebookCompetition extends Component {
  render() {
    return (
      <div className="survey-terms-and-conditions">
        <DocumentMeta {...SEPTEMBER_2018_FACEBOOK_COMPETITION_TERMS_AND_CONDITIONS} />
          <div className="survey-terms-and-conditions-details">
            <span className="survey-terms-and-conditions-details__title">Facebook Competition Sept '18 Terms and Conditions</span>
            <ol className="survey-terms-and-conditions-details__items">
              <li className="survey-terms-and-conditions-details__item">Information on how to enter form part of these conditions. By participating, entrants agree to be bound by these conditions. Entries must comply with these conditions to be valid.</li>
              <li className="survey-terms-and-conditions-details__item">The promoter of this competition is The Brick Exchange Pty Ltd, ABN 27 600 762 749, 17-19 Bridge Street, Sydney NSW 2000 (BrickX). Theta Asset Management Ltd (ABN 37 071 807 684, AFSL 230920), is product issuer for the BRICKX Platform, but is not responsible for offering, promoting or administering this competition.</li>
              <li className="survey-terms-and-conditions-details__item">This competition commences at 4:00pm Australian Eastern Standard Time (AEST) on Thursday 20 September 2018 and closes at 3:59pm AEST Tuesday 25 September 2018 (“Entry Period”). BrickX may in its absolute discretion change the closing time and date prior to 3:59pm AEST Tuesday 25 September 2018.</li>
              <li className="survey-terms-and-conditions-details__item">The winners will be selected and notified by 5:00pm AEST on Friday 28 September 2018.</li>
              <li className="survey-terms-and-conditions-details__item">
                To enter, entrants must:
                <ol className="survey-terms-and-conditions-details__sub-items">
                  <li className="survey-terms-and-conditions-details__item">Be a Member of the BrickX Platform.</li>
                  <li className="survey-terms-and-conditions-details__item">Comment on any of the designated giveaway post on the BrickX Facebook page with the answer to the question “Tell us your favourite thing about Aussie regional towns”.</li>
                  <li className="survey-terms-and-conditions-details__item">Give permission to BrickX for sharing the competition submission.</li>
                </ol>
              </li>
              <li className="survey-terms-and-conditions-details__item">Entries must be received by BrickX during the Entry Period. Entries received after the end of the Entry Period will not be considered.</li>
              <li className="survey-terms-and-conditions-details__item">Only one submission per person is permitted.</li>
              <li className="survey-terms-and-conditions-details__item">This competition is a game of skill. Chance plays no part in determining the winners. All entries will be judged individually on their merits based on relevance and individuality, against the criteria of ‘The answer that inspires us most to pack our bags and head on an Aussie road trip will win’. The best entry as determined by the judges will win a prize.</li>
              <li className="survey-terms-and-conditions-details__item">Entries will be judged by a panel of judges from the BrickX team.</li>
              <li className="survey-terms-and-conditions-details__item">There is one prize of AUD$100, added to the winner’s BrickX Wallet, to be given away. The winning submission may appear on the BrickX social media pages such as Instagram and Facebook. If, for any reason the prize cannot be claimed by the entrant, BrickX reserves the right to select the next best entry as determined by the judges.</li>
              <li className="survey-terms-and-conditions-details__item">The prize is not transferable, exchangeable or redeemable for cash. The judges’ decision is final and binding - no correspondence will be entered into. BrickX accepts no responsibility for late, lost or misdirected entries or other communications. </li>
              <li className="survey-terms-and-conditions-details__item">The winner will be notified through a direct message on Facebook.</li>
              <li className="survey-terms-and-conditions-details__item">Entrants agree to release Facebook of responsibility, and agree to Facebook’s terms of use.</li>
              <li className="survey-terms-and-conditions-details__item">This competition is not sponsored, endorsed or administered by, or associated with, Facebook. Entrants must note that they are providing the information to BrickX and not to Facebook. It is each entrant’s responsibility to adhere to the requirements of the Terms &amp; Conditions and the prevailing terms and conditions of Facebook and Instagram. BrickX will take no responsibility for any comment that has been posted incorrectly.</li>
              <li className="survey-terms-and-conditions-details__item">BrickX shall not be liable for any loss, damage or injury suffered or sustained (including but not limited to indirect or consequential loss) as a result of entering this competition, accepting and/or using a prize, except for any liability which cannot be excluded by law.</li>
              <li className="survey-terms-and-conditions-details__item">BrickX reserves the right to disqualify any individual who is involved in any way in interfering or tampering with the conduct of this competition.</li>
              <li className="survey-terms-and-conditions-details__item">All entries become the property of BrickX. Eligible entrants consent to the use and disclosure of their personal information by BrickX in accordance with its Privacy Policy and for the purposes of this competition. For further details on Privacy, please see <Styledlink href={privacyUrl()}>https://www.brickx.com/privacy</Styledlink>.</li>
              <li className="survey-terms-and-conditions-details__item">BrickX accepts no responsibility for any tax implications that may arise from taking the prize. Independent financial advice should be sought.</li>
            </ol>
          </div>
      </div>
    );
  }
}
