import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import DocumentMeta from 'react-document-meta';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { PENDING_ORDERS_META } from 'scripts/metadata/account';
import {
  PENDING_PRE_ORDERS_HELP_TEXT,
  PENDING_SELL_ORDERS_HELP_TEXT,
} from 'src/components/dashboard/home/helpText';
import { fetchMyOrders, cancelOrder } from 'scripts/redux/actions/market';
import { fetchProperties } from 'scripts/redux/actions/properties';
import {
  myOrders as myOrdersPropType,
  property as propertyPropType,
} from 'scripts/constants/PropTypes';
import { myOrdersSelector } from 'scripts/redux/selectors/market';
import { order as orderFormatter, uuid } from 'scripts/utilities/formatters';
import { propertiesSelector } from 'scripts/redux/selectors/properties';
import { sendCancelPendingOrderEvent } from 'scripts/redux/actions/segment/events/tradeEvents';
import Icon from 'scripts/components/shared/Icon';
import Loading from 'src/components/loading/Loading';
import Modal from 'scripts/components/shared/Modal';
import MyPreOrdersTable from 'scripts/components/account/myOrders/MyPreOrdersTable';
import MySellOrdersTable from 'scripts/components/account/myOrders/MySellOrdersTable';
import OrderSide from 'scripts/constants/OrderSide';
import PendingOrderType from 'scripts/constants/PendingOrderType';
import Popover from 'scripts/components/shared/Popover';
import SegmentEventTypes from 'scripts/redux/actions/segment/SegmentEventTypes';
import withLegacyModals from 'src/decorators/withLegacyModals';

const mapStateToProps = (state) => ({
  ...myOrdersSelector(state),
  ...propertiesSelector(state),
});

@withLegacyModals
@connect(mapStateToProps, {
  fetchMyOrders,
  fetchProperties,
  cancelOrder,
})
export default class MyPendingOrders extends Component {
  static propTypes = {
    myOrders: myOrdersPropType.isRequired,
    properties: PropTypes.arrayOf(propertyPropType).isRequired,
    fetchMyOrders: PropTypes.func.isRequired,
    fetchProperties: PropTypes.func.isRequired,
    cancelOrder: PropTypes.func.isRequired,
  };

  state = {
    currentOrder: null,
  };

  componentDidMount() {
    const { fetchMyOrders, fetchProperties } = this.props;
    fetchProperties();
    fetchMyOrders();
  }

  render() {
    const { myOrders, properties } = this.props;

    if (!myOrders || _.isEmpty(properties)) {
      return <Loading />;
    }

    const { currentOrder } = this.state;

    return (
      <div>
        <DocumentMeta {...PENDING_ORDERS_META} />
        <MyPreOrdersTable
          title={
            <Fragment>
              Pending Pre Orders{' '}
              <Popover placement="top" content={PENDING_PRE_ORDERS_HELP_TEXT} />
            </Fragment>
          }
          myOrders={myOrders.pendingPre}
          properties={properties}
          type={PendingOrderType.PENDING_PRE}
          action={(myOrder) => (
            <button
              id={myOrder.orderId::uuid()}
              className="button"
              data-test-reference={`button-cancel-order-${myOrder.orderId::uuid()}`}
              onClick={() => this._setCurrentOrder(myOrder)}
            >
              <span className="hidden-xs hidden-sm">CANCEL</span>
              <span className="hidden-md hidden-lg">
                <Icon type="fa" name="times" />
              </span>
            </button>
          )}
          emptyMessage={
            <div className="card-cta">
              <h4 className="message-no-pre-orders">
                You do not have any pending pre orders.
              </h4>
            </div>
          }
        />
        <MySellOrdersTable
          title={
            <Fragment>
              Pending Sell Orders{' '}
              <Popover
                placement="top"
                content={PENDING_SELL_ORDERS_HELP_TEXT}
              />
            </Fragment>
          }
          myOrders={myOrders.pendingSell}
          properties={properties}
          type={PendingOrderType.PENDING_SELL}
          action={(myOrder) => (
            <button
              id={myOrder.orderId::uuid()}
              className="button"
              data-test-reference={`button-cancel-order-${myOrder.orderId::uuid()}`}
              onClick={() => this._setCurrentOrder(myOrder)}
            >
              <span className="hidden-xs hidden-sm">CANCEL</span>
              <span className="hidden-md hidden-lg">
                <Icon type="fa" name="times" />
              </span>
            </button>
          )}
          emptyMessage={
            <div className="card-cta">
              <h4 className="message-no-sell-orders">
                You do not have any pending sell orders.
              </h4>
            </div>
          }
        />
        <Modal
          ref="modal"
          className="modal-orders"
          onClose={() => this._setCurrentOrder(null)}
          title="Cancel Order"
          body={
            currentOrder && (
              <div>
                Are you sure you want to cancel this order?
                <div className="row">
                  <div className="col-lg-12">
                    <div className="modal-info">
                      {currentOrder::orderFormatter()}
                    </div>
                    <div className="modal-reference">
                      Reference: <span>{currentOrder.orderId::uuid()}</span>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
          footer={
            <div>
              <p className="button btn-default" onClick={::this._closeModal}>
                Close
              </p>
              &nbsp;
              <p
                className="button red-button cancel-order-button"
                onClick={::this._cancelOrder}
              >
                Yes, I am sure
              </p>
            </div>
          }
        />
      </div>
    );
  }

  _setCurrentOrder(currentOrder) {
    const callback = currentOrder ? this._openModal : this._closeModal;
    this.setState(
      {
        currentOrder: currentOrder,
      },
      callback
    );
  }

  _openModal() {
    this.refs.modal.open();
  }

  _closeModal() {
    this.refs.modal.close();
  }

  async _cancelOrder() {
    const { cancelOrder } = this.props;
    const { currentOrder } = this.state;
    await cancelOrder(currentOrder.propertyCode, {
      orderId: currentOrder.orderId,
    });
    this._closeModal();
    sendCancelPendingOrderEvent(
      currentOrder.orderSide === OrderSide.SELL
        ? SegmentEventTypes.SELL.SELL_ORDER_CANCELLED
        : SegmentEventTypes.PRE_ORDER.PRE_ORDER_CANCELLED,
      currentOrder
    );
  }
}
