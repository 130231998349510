import classNames from 'classnames';

import { Heading } from 'src/design/components/heading/Headings';
import TickedBulletList from 'src/design/components/bulletList/TickedBulletList';
import StyledBodylink from 'src/design/components/hyperlink/StyledBodylink';
import Tabs from 'src/design/components/tabs/Tabs';
import FinePrint from 'src/design/components/bodyText/FinePrint';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import Styledlink from 'src/design/components/hyperlink/Styledlink';
import {
  pdsUrl,
} from 'src/utils/pageUrls';
import { minimumDeposit } from 'src/settings/funds';

import styles from 'src/components/landingPages/smartInvest/investGrowWithdraw/InvestGrowWithdraw.mscss';

const TabPanel = ({
  items,
  image,
  learnMoreLinkUrl,
  className,
} = {}) => (
  <div className={classNames(styles.tabPanel, className)}>
    {image}
    <div className={styles['tabPanel--contentContainer']}>
      <TickedBulletList items={items} />
      <StyledBodylink
        withArrow
        href={learnMoreLinkUrl}
        className={styles['tabPanel--learnMoreLink']}
        target="_blank"
      >Learn more</StyledBodylink>
    </div>
  </div>
);

const InvestGrowWithdraw = ({ }) => {
  return (
    <section className={styles.investGrowWithdrawContainer}>
      <Heading align="center" className={styles.title}><h2>How Smart Invest Works</h2></Heading>
      <Spacing bottom="large" top="normal">
        <FinePrint align="center"><div>Consider whether investing in Bricks is right for you by reading the <Styledlink target="_blank" href={pdsUrl()}>PDS</Styledlink></div></FinePrint>
      </Spacing>
      <Tabs
        testRef="invest-grow-withdraw-tabs"
        startFromPanel={0}
        centeredLabels
        panels={[{
          label: 'Invest',
          panel: <TabPanel
            items={[
              `Choose how much you'd like to invest each month via direct debit, from $${minimumDeposit}`,
              'Build a diversified property portfolio of Bricks by investing regularly',
              'Ability to skip a month or cancel',
            ]}
            image={
              <img
                src="/static/img/illustration-pie-charting-with-legend.svg"
                alt="Picture of a pie chart next to a legend, and a pointer pointing toward $1000"
                className={classNames(styles['tabPanel--image'], styles['tabPanel--imageOnRight'])}
              />
            }
            learnMoreLinkUrl="https://help.brickx.com/support/solutions/folders/17000132416"
          />,
          labelTestRef: 'invest-tabs-label',
        }, {
          label: 'Grow',
          panel: <TabPanel
            items={[
              "We'll invest your money in Bricks in properties, selected by our specialist team based on their growth potential",
              'Receive regular updates on how your portfolio is performing',
              'Earn your share of any rent, after expenses, paid monthly, automatically reinvested',
              'As the value of the property changes, so does the value of your Bricks',
            ]}
            image={
              <img
                src="/static/img/illustration-house-with-a-seedling-and-a-growth-sparkline.svg"
                alt="Picture of a house atop a yellow sparkline graph, and a small seedling to the right"
                className={classNames(styles['tabPanel--image'], styles['tabPanel--imageOnRight'])}
              />
            }
            learnMoreLinkUrl="http://help.brickx.com/support/solutions/17000081352"
          />,
          labelTestRef: 'grow-tabs-label',
        }, {
          label: 'Withdraw',
          panel: <TabPanel
            items={[
              'To withdraw your money, disable Smart Invest and sell your Bricks',
              'Simply set the sale price and the amount you’d like to sell. Other investors can then buy them.',
              'As soon as your Bricks are sold, you can withdraw your money (including any capital returns)',
            ]}
            image={
              <img
                src="/static/img/illustration-dollar-coin-and-money-bag.svg"
                alt="Picture of a large dollar coin next to a bag of money, with 2 activity indicators, one of which says SELL"
                className={classNames(styles['tabPanel--image'], styles['tabPanel--imageOnRight'])}
              />
            }
            learnMoreLinkUrl="http://help.brickx.com/support/solutions/articles/17000074567-how-do-i-sell-my-bricks-"
          />,
          labelTestRef: 'grow-tabs-label',
        }]}
      />
    </section>
  );
};

export default InvestGrowWithdraw;
