import { createAction, handleActions } from 'redux-actions';
import getWithAuth from 'scripts/services/request/getWithAuth';
import UserService from 'scripts/services/UserService';
import { userAccountV2Url, userAccountOverTime, wholesaleInvestorRegister, smartRenterDetailsUrl } from 'scripts/services/api';
import { userSelector } from 'scripts/redux/selectors/user';

export const accountInfo = createAction('scripts/account/ACCOUNT_INFO');
export const accountV2Info = createAction('scripts/account/ACCOUNT_INFO_V2');
export const fetchAccountOverTimeCompleted = createAction('scripts/account/FETCH_ACCOUNT_OVER_TIME_COMPLETE');
export const wholesaleInfo = createAction('scripts/account/WHOLESALE_INFO');
export const smartRenterDetails = createAction('scripts/account/SMART_RENTER_DETAILS');

export const fetchAccountInfo = () => async (dispatch, getState) => {
  const { user } = userSelector(getState());
  const payload = await UserService.getAccountInfo(user.id);
  return dispatch(accountInfo(payload));
};

export const fetchAccountInfoV2 = () => async (dispatch, getState) => {
  const { user } = userSelector(getState());
  if (user.id) {
    const payload = await getWithAuth(userAccountV2Url(user.id));
    return dispatch(accountV2Info(payload));
  }
};

export const fetchAccountOverTime = () => async (dispatch, getState) => {
  const { user } = userSelector(getState());
  const payload = await getWithAuth(userAccountOverTime(user.id));
  if (payload) { return dispatch(fetchAccountOverTimeCompleted(payload)); }
};

export const accountSelectorV2 = (state) => ({
  account: state.account.accountV2
});

export const accountOverTimeSelector = (state) => ({ accountOverTime: state.account.accountOverTime });

// *** wholesale

export const fetchAccountWholesale = () => async (dispatch, getState) => {
  const { user } = userSelector(getState());
  const payload = await getWithAuth(wholesaleInvestorRegister(user.id));
  if (payload && !payload.error) { return dispatch(wholesaleInfo(payload)); }
};

export const fetchSmartRenterDetails = () => async (dispatch, getState) => {
  const { user } = userSelector(getState());
  const payload = await getWithAuth(smartRenterDetailsUrl(user.id));
  if (payload && !payload.error) { return dispatch(smartRenterDetails(payload)); }
};

export const wholesaleInfoSelector = (state) => ({
  wholesaleInfo: state.account.wholesaleInfo
});

export const smartRenterDetailsSelector = (state) => ({
  smartRenterDetails: state.account.smartRenterDetails
});

const reducer = handleActions({
  [accountInfo]: (state, action) => ({ ...state, accountV1: action.payload }),
  [accountV2Info]: (state, action) => ({ ...state, accountV2: action.payload }),
  [fetchAccountOverTimeCompleted]: (state, action) => ({ ...state, accountOverTime: action.payload }),
  [wholesaleInfo]: (state, action) => ({ ...state, wholesaleInfo: action.payload }),
  [smartRenterDetails]: (state, action) => ({ ...state, smartRenterDetails: action.payload })
}, {
  accountV1: null,
  accountV2: null,
  accountOverTime: [],
  wholesaleInfo: null,
  smartRenterDetails: null
});

export default reducer;
