import FinancialsPanel from 'scripts/components/shared/FinancialsPanel';
import { property as propertyPropType } from 'scripts/constants/PropTypes';
import {
  dollar,
  dateWithSlash,
  dollarDecimal,
} from 'scripts/utilities/formatters';
import {
  InitialDebt,
  InitialEquity,
  CashReserve,
} from 'src/settings/financials';

const HistoricalPurchase = ({ property }) => {
  const isGeared = property.financials.gearingEffect > 0;

  return (
    <FinancialsPanel
      className="historical-purchase-panel"
      title="Historical Purchase"
    >
      <div className="row my-row">
        <div className="col-xs-7 col-no-padding-right bold">
          Settlement Date
        </div>
        <div className="col-xs-5 col-no-padding-left text-right acquisition-date">
          {property.platformSettlementDate::dateWithSlash()}
        </div>
      </div>
      <div className="gray-line" />
      <div className="row my-row">
        <div className="col-xs-7 col-no-padding-right bold">Initial Equity</div>
        <div className="col-xs-5 col-no-padding-left text-right initial-equity">
          {isGeared
            ? InitialEquity[property.propertyCode] !== undefined
              ? InitialEquity[property.propertyCode]::dollar()
              : property.financials.initialEquity::dollar()
            : property.financials.purchasePrice::dollar()}
        </div>
      </div>
      <div className="gray-line" />
      <div className="row my-row">
        <div className="col-xs-7 col-no-padding-right bold">Initial Debt</div>
        <div className="col-xs-5 col-no-padding-left text-right initial-debt">
          {isGeared
            ? InitialDebt[property.propertyCode] !== undefined
              ? InitialDebt[property.propertyCode]::dollar()
              : property.financials.debtTerms.initialDebt::dollar()
            : '$0'}
        </div>
      </div>
      <div className="gray-line" />
      <div className="row my-row">
        <div className="col-xs-7 col-no-padding-right bold">
          Acquisition Costs
        </div>
        <div className="col-xs-5 col-no-padding-left text-right acquisition-costs">
          {property.financials.acquisitionCost::dollar()}
        </div>
      </div>
      <div className="gray-line" />
      <div className="row my-row">
        <div className="col-xs-7 col-no-padding-right bold">Cash Reserve</div>
        <div className="col-xs-5 col-no-padding-left text-right cash-reserves">
          {CashReserve[property.propertyCode] !== undefined
            ? CashReserve[property.propertyCode]::dollar()
            : property.financials.cashReserve::dollar()}
        </div>
      </div>
      <div className="black-line" />
      <div className="row my-row">
        <div className="col-xs-7 col-no-padding-right bold">
          Total Purchase Price
        </div>
        <div className="col-xs-5 col-no-padding-left text-right purchase-price">
          {property.financials.totalPurchasePrice::dollar()}
        </div>
      </div>
      <div className="black-line" />
      <div className="row my-row">
        <div className="col-xs-7 col-no-padding-right bold">
          Initial Brick Price
        </div>
        <div className="col-xs-5 col-no-padding-left text-right purchase-price">
          {property.financials.initialBrickPrice::dollarDecimal()}
        </div>
      </div>
      <div className="black-line" />
    </FinancialsPanel>
  );
};

HistoricalPurchase.propTypes = {
  property: propertyPropType.isRequired,
};

export default HistoricalPurchase;
