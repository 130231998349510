import withState from 'src/decorators/withState';

import { HeadingLarge } from 'src/design/components/heading/Headings';
import IconRippleButton from 'src/design/components/button/IconRippleButton';
import Modal from 'scripts/components/helpers/Modal';
import Video from 'scripts/components/shared/Video';
import { smartInvestVideoId } from 'src/utils/videoIds';

import styles from 'src/components/landingPages/smartInvest/heroVideoSection/HeroVideoSection.mscss';


const HeroVideoSection = withState({
  Component: ({
    state,
    setState,
  }) => {
    const { modalIsOpen } = state;
    return (
      <section className={styles.heroVideoSection}>
        <div className={styles.innerContainer}>
          <HeadingLarge className={styles.title}><h1>Our mission is to help you build your home deposit smarter, sooner and easier than ever before</h1></HeadingLarge>
          <IconRippleButton
            iconType={IconRippleButton.types.play_triangle}
            className={styles.videoButton}
            color={IconRippleButton.colors.SECONDARY}
            onClick={() => setState({ modalIsOpen: true })}
            data-toggle="modal"
            data-target="#si-video"
          >Introducing Smart Invest</IconRippleButton>
        </div>
        <Modal
          id="si-video"
          title="Smart Invest Video"
          noPadding
          darkBackground
          hideFooter
          hideHeader
          body={modalIsOpen ? <Video videoId={smartInvestVideoId} autoplay/> : null}
          onClose={() => setState({ modalIsOpen: false })}
        />
      </section>
    );
  },
});

export default HeroVideoSection;
