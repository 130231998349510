/**
 * Simply put, it returns nothing. Used for reserved component before release.
 *
 * @returns null
 */
const HideComponent = () => {
  return null;
};

export default HideComponent;
