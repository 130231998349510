import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateUserId as updateUserIdAction } from 'src/components/flows/store/flowsActions';
import ExtraInfoStep from 'src/components/landingPages/brickxAgriculture/ExtraInfoStep';
import Flows from 'src/components/flows/Flows';
import BrickxAgriHome from 'src/components/landingPages/brickxAgriculture/BrickxAgriHome';
import ThanksForYourInterest from 'src/components/landingPages/brickxAgriculture/ThanksForYourInterest';

const getComponentForStepId = (stepId) => {
  switch (stepId) {
    case 'register-interest': return BrickxAgriHome;
    case 'extra-info': return ExtraInfoStep;
    default: return ThanksForYourInterest;
  }
};

@connect(null, { updateUserId: updateUserIdAction })
export default class BrickxAgriFlow extends Component {
  static propTypes = {
    updateUserId: PropTypes.func,
    location: PropTypes.shape({
      query: PropTypes.shape({ userId: PropTypes.string })
    }),
  }

  constructor(props) {
    super(props);
    const userId = props.location.query.userId;
    userId && this.props.updateUserId(userId);
  }

  render() {
    return (
      <Flows
        flowType="smart-rent"
        getComponentForStepId={getComponentForStepId}
        loading={<BrickxAgriHome />}
        {...this.props}
      />
    );
  }
}
