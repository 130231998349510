
import classNames from 'classnames';

const MathFormula = ({ molecular, denominator, className }) => {
  return (
    <div className={classNames('math-formula', className)}>
      <div className="math-formula-box">
        <div className="math-formula-molecular">{molecular}</div>
        <div className="math-formula-line"></div>
        <div className="math-formula-denominator">{denominator}</div>
      </div>
    </div>
  );
};

export default MathFormula;
