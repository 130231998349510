import { accountUrl, settingsUrl, portfolioUrl } from 'src/utils/pageUrls';

// TODO: does not belong in constants, move it to config and split to their specific domains, or jam it into one file, I dunno
export default {
  PLACEHOLDER_COMPONENT_BG_COLOR: '#CCCCCD',
  DEFAULT_NUMBER_OF_PROPERTY_CARD_PLACEHOLDER: 6,
  DEFAULT_NUM_TRANSACTIONS_SHOWN: 5,
  MAXIMUM_NUM_TRANSACTIONS_SHOWN: 50,
  FACEBOOK_APP_ID: '888707087900723',
  PROPERTY_THUMB_PLACEHOLDER:
    'https://assets.brickx.com/images/bx-og-properties.png',
  PAGE_URLS: {
    HOME_PAGE: '/',
    MAINTENANCE: '/maintenance',
    NOT_FOUND: '/404',
    HOW_IT_WORKS: '/how-it-works',
    FAQ: '/faq',
    ABOUT: '/about',
    GLOSSARY: '/glossary',
    CONTACT: '/contact',
    MEDIA: '/media',
    JOBS: 'https://jobs.brickx.com',
    MEDIA_ENQUIRIES: '/media-enquiries',
    TERMS: '/terms',
    EOFY_SPECIAL_OFFER: '/terms/eofy2017',
    PRIVACY: '/privacy',
    COMPLAINTS: '/complaints',
    PDS: '/pds',
    LATEST_PDS: '/pds/latest-pds-updates',
    PROPERTY_TEAM: '/team',
    RETURNS_CALCULATOR: '/returns-calculator',
    SMSF_DOWNLOAD: '/smsf-download',
    SMSF: '/smsf',
    ONBOARDING: {
      LOGIN: '/login',
      LOGOUT: '/logout',
      SIGNUP: '/signup',
      SIGNUPMINOR: '/signup/minor',
      SIGNUPSMSF: '/signup/smsf',
      VERIFY: '/verify',
      ACCEPT_PDS: '/accept-pds',
      VEDA_FAILED: '/veda',
    },
    ACCOUNT: {
      DASHBOARD: accountUrl(),
      SETUP_DETAILS: '/setup',
      SETTINGS: settingsUrl(),
      DEPOSIT: '/account/deposit',
      WITHDRAW: '/account/withdraw',
      REFERRALS: '/account/referrals',
      TRANSACTIONS: '/account/transactions',
      DOCUMENTS: '/account/documents',
      MY_PENDING_ORDERS: '/account/my-pending-orders',
      PORTFOLIO: portfolioUrl(),
      RESET_PASSWORD: '/reset-password',
    },
    PROPERTIES: {
      LIST: '/properties',
      SETUP_DETAILS: '/setup',
      SETTINGS: settingsUrl(),
    },
  },
  COOKIES: {
    AUTHORIZATION: 'Authorization',
    CLOSED_TESTING: 'closed-testing',
    TIMEOUT_WARNING_SHOWN: 'timeout-warning-shown',
    SEGMENT_COOKIES: {
      ANONYMOUS_ID_COOKIE: 'ajs_anonymous_id',
      GROUP_ID_COOKIE: 'ajs_group_id',
      USER_ID_COOKIE: 'ajs_user_id',
      MIXPANEL_COUNT_COOKIE: 'mp_mixpanel__c',
    },
    SIGN_UP_VERIFY_TOKEN: 'SignUpVerifyToken',
    EXPAND_LIVE_STATS: 'expandLiveStats',
    MEMBERSHIP_STATUS: 'm',
  },
  LOCAL_SESSION_STORAGE: {
    REFERRAL: 'referral',
    USER_SESSION: 'current-login-user-session',
    SESSION_STARTTIME_FOR_SIGNUP_POPUP: 'sessionStartTimeForSignupPopup',
  },
  LOCAL_STORAGE: {
    CURRENT_USER: '__user_traits',
    EXISTING_ACCOUNTS: '056e0421-4697-4bdc-ac40-cfd1452f8aaa',
  },
  SYSTEMS: {
    DEFAULT_TIMEOUT_SIGNUP_POPUP: 150000,
    USER_INPUT_DEBOUNCE_TIMEOUT_MS: 1000,
  },
  GOOGLE_MAP: {
    DEFAULT_OPTIONS: {
      streetViewControl: false,
      mapTypeControl: false,
      panControl: false,
      scrollwheel: false,
      signInControl: false,
      draggable: true,
      zoomControl: true,
    },
    JAVASCRIPT_API_URL:
      'https://maps.googleapis.com/maps/api/js?v=3&key=AIzaSyB_ZgEQzmqHwL4HnN3FckEcqphuqiML95o&libraries=places',
  },
};
