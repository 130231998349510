import { OnboardingFormLabel } from 'src/components/oldOnboarding/common/onboardingScreen/OnboardingScreen';
import AddressInput from 'src/design/components/addressInput/AddressInput';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import styles from 'src/components/oldOnboarding/identityVerification/ResidentialAddress.mscss';

const ResidentialAddress = (props) => (
  <Spacing bottom="normal">
    <div className={styles.suggestionInputContainer}>
      { !props.hideLabel && <OnboardingFormLabel>Residential Address</OnboardingFormLabel> }
      <AddressInput {...props}/>
    </div>
  </Spacing>
);

export default ResidentialAddress;
