// TODO: does not belong in constants, move it to config
export default {
  helpText: {
    initialBrickPrice: 'The value of 1 Brick in this property investment, based on the Total Purchase Cost and/or Equity at Settlement Date.',
    brickValuation: 'The value of 1 Brick in this property investment, based on the Latest Value and/or Equity at the last Independent Valuation.',
  },
  aboveBelowValuation: {
    aboveValuationText: 'Above Valuation',
    belowValuationText: 'Below Valuation',
    equalToValuationText: 'Same as Valuation',
  }
};
