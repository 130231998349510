import {
  property as propertyType,
  propertyAverageAnnualGrowthMetrics as historicalGrowthMetricsType,
} from 'scripts/constants/PropTypes';

import PropertyCardMarketOpen from 'src/components/propertyCards/propertyCardMarketOpen/PropertyCardMarketOpen';
import NewPropertyFlag from 'src/components/propertyCards/common/flags/NewPropertyFlag';

const PropertyCardNewProperty = ({ property, historicalGrowthMetrics }) => (
  <PropertyCardMarketOpen
    property={property}
    historicalGrowthMetrics={historicalGrowthMetrics}
    flag={<NewPropertyFlag />}
  />
);

PropertyCardNewProperty.propTypes = {
  property: propertyType,
  historicalGrowthMetrics: historicalGrowthMetricsType,
};

export default PropertyCardNewProperty;
