import React from 'react';
import { Helmet } from 'react-helmet';
import OnboardingScreen, {
  OnboardingScreenHeader,
} from 'src/components/oldOnboarding/common/onboardingScreen/OnboardingScreen';
import Paragraph from 'src/design/styleguide/Paragraph';

const SmsfAndTrustProcessingPage = () => {
  return (
    <OnboardingScreen>
      <Helmet>
        <title>Application in review | BrickX</title>
      </Helmet>
      <OnboardingScreenHeader>
        <h1>
          <strong>You application is in review</strong>
        </h1>
        <Paragraph>
          Thank you for registering BrickX SMSF/Trust. We are reviewing your
          application. Once complete we'll contact you.
        </Paragraph>
      </OnboardingScreenHeader>
    </OnboardingScreen>
  );
};

export default SmsfAndTrustProcessingPage;
