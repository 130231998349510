import PropTypes from 'prop-types';
import classNames from 'classnames';
import { property as propertyPropType } from 'scripts/constants/PropTypes';
import Icon from 'scripts/components/shared/Icon';
import { propertyTypeFormatter } from 'src/settings/text';
import PropertyTypes from 'src/enums/propertyTypes';
import { IoConstructOutline } from 'react-icons/io5';

const PropertyIcon = ({ iconName, count, children }) => (
  <div>
    <Icon type="icon" name={iconName} />
    <b>{count}</b>
    <span className="hidden-xs hidden-sm hidden-md">{children}</span>
  </div>
);

PropertyIcon.propTypes = {
  iconName: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

const PropertyStats = ({ property }) => {
  if (
    [PropertyTypes.house, PropertyTypes.unit].includes(property.propertyType)
  ) {
    return (
      <div className="property-hover">
        <ul className="property-icons">
          <li>
            <div data-test-reference="number-of-bedrooms">
              {property.bedroomCount}
            </div>
            <span className="icn icn-small icn-bed"></span>
          </li>
          <li>
            <div data-test-reference="number-of-bathrooms">
              {property.bathroomCount}
            </div>
            <span className="icn icn-small icn-bath"></span>
          </li>
          <li>
            <div data-test-reference="number-of-carspots">
              {property.carSpotCount}
            </div>
            <span className="icn icn-small icn-car"></span>
          </li>
          <li>
            <div className="capitalize" data-test-reference="property-type">
              {property.propertyType}
            </div>
            <span
              className={classNames('icn icn-small', {
                'icn-unit': property.propertyType === 'unit',
                'icn-house2': property.propertyType === 'house',
              })}
            ></span>
          </li>
        </ul>
        <ul className="debt">
          {property.financials.lVR > 0 && (
            <li data-test-reference="geared">
              <div>Geared</div>
              <span className="icn icn-small icn-gear-white"></span>
            </li>
          )}
        </ul>
      </div>
    );
  } else if ([PropertyTypes.farm].includes(property.propertyType)) {
    return (
      <div className="property-hover">
        <ul className="property-icons">
          <li>
            <div className="capitalize" data-test-reference="property-type">
              {property.propertyType}
            </div>
            <span className="icn icn-small icn-farm-2"></span>
          </li>
        </ul>
        <ul className="debt">
          {property.financials.lVR > 0 && (
            <li data-test-reference="geared">
              <div>Geared</div>
              <span className="icn icn-small icn-gear-white"></span>
            </li>
          )}
        </ul>
      </div>
    );
  } else if (
    [PropertyTypes.offices, PropertyTypes.development].includes(
      property.propertyType
    )
  ) {
    return (
      <div className="property-hover">
        <ul className="property-icons">
          <li>
            <div className="capitalize" data-test-reference="property-type">
              {property.sqmArea} m²
            </div>
            <span className="icn icn-small icn-area-2"></span>
          </li>
          {property.carSpotCount > 0 && (
            <li>
              <div data-test-reference="number-of-carspots">
                {property.carSpotCount}
              </div>
              <span className="icn icn-small icn-car"></span>
            </li>
          )}
          {property.bedroomCount > 0 && (
            <li>
              <div data-test-reference="number-of-bedrooms">
                {property.bedroomCount}
              </div>
              <span className="icn icn-small icn-bed"></span>
            </li>
          )}
          {property.bathroomCount > 0 && (
            <li>
              <div data-test-reference="number-of-bathrooms">
                {property.bathroomCount}
              </div>
              <span className="icn icn-small icn-bath"></span>
            </li>
          )}
          <li>
            <div className="capitalize" data-test-reference="property-type">
              {property.propertyType}
            </div>
            <span className="icn icn-small icn-com-2"></span>
          </li>
        </ul>
        <ul className="debt">
          {property.financials.lVR > 0 && (
            <li data-test-reference="geared">
              <div>Geared</div>
              <span className="icn icn-small icn-gear-white"></span>
            </li>
          )}
        </ul>
      </div>
    );
  } else if (
    [PropertyTypes.multihouse, PropertyTypes.multiunit].includes(
      property.propertyType
    )
  ) {
    return (
      <div className="property-hover">
        <ul className="property-icons">
          <li>
            <div className="capitalize" data-test-reference="property-type">
              {property.multiPropertyPlan.numberOfProperties}
            </div>
            <span
              className={classNames('icn icn-small', {
                'icn-unit': property.propertyType === 'multiunit',
                'icn-house2': property.propertyType === 'multihouse',
              })}
            ></span>
          </li>
          <li>
            <div className="capitalize" data-test-reference="property-type">
              {propertyTypeFormatter(property.propertyType)}
            </div>
            <span
              className={classNames('icn icn-small', {
                'icn-unit': property.propertyType === 'multiunit',
                'icn-house2': property.propertyType === 'multihouse',
              })}
            ></span>
          </li>
        </ul>
        <ul className="debt">
          {property.financials.lVR > 0 && (
            <li data-test-reference="geared">
              <div>Geared</div>
              <span className="icn icn-small icn-gear-white"></span>
            </li>
          )}
        </ul>
      </div>
    );
  } else if ([PropertyTypes.development].includes(property.propertyType)) {
    return (
      <div className="property-hover">
        <ul className="property-icons">
          <li>
            <div className="capitalize" data-test-reference="property-type">
              {propertyTypeFormatter(property.propertyType)}
            </div>
            <span
              style={{
                display: 'inline-block',
                fontSize: '2.5rem',
                transform: 'translateY(5px)',
              }}
            >
              <IoConstructOutline />
            </span>
          </li>
        </ul>
        <ul className="debt">
          {property.financials.lVR > 0 && (
            <li data-test-reference="geared">
              <div>Geared</div>
              <span className="icn icn-small icn-gear-white"></span>
            </li>
          )}
        </ul>
      </div>
    );
  }
  return '';
};

PropertyStats.propTypes = {
  property: propertyPropType.isRequired,
};

export default PropertyStats;
