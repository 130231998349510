import styled from 'styled-components';
import ContentContainer from 'src/design/styleguide/contentContainer/ContentContainer';
import ContentSection from 'src/design/styleguide/contentSection/ContentSection';

const InnerContentContainer = styled(ContentContainer)`
  position: relative;
  ${p => p.withVerticalMargin && 'margin: 0px auto 70px;'}
`;

const ContentBlock = ({ children, innerContainerClassName, width, topPadding, bottomPadding, verticallyCentered, verticallyExpanded, withVerticalMargin, ...props }) => (
  <ContentSection {...props}>
    <InnerContentContainer {...{
      width,
      topPadding,
      bottomPadding,
      className: innerContainerClassName,
      verticallyCentered,
      verticallyExpanded,
      withVerticalMargin,
      testRef: 'inner-content-container',
    }}>
      {children}
    </InnerContentContainer>
  </ContentSection>
);

export default ContentBlock;
