
import { Component } from 'react';
import PropTypes from 'prop-types';

import { property as propertyPropType } from 'scripts/constants/PropTypes';

import Popover from 'scripts/components/shared/Popover';

import { percentDecimal } from 'scripts/utilities/formatters';
import { isAPreOrderProperty } from 'scripts/utilities/propertyHelper';
import Copy from 'scripts/constants/Copy';


export default class AboveBelowValuationComponent extends Component {
  static propTypes = {
    property: propertyPropType.isRequired,
    showBrickValuationText: PropTypes.bool,
  };

  render() {
    const { property, showBrickValuationText } = this.props;
    const isPreOrderProperty = isAPreOrderProperty(property);
    const isPreOrderOrNotIndependentValuedProperty = isPreOrderProperty || !property.financials.isIndependentValued;
    const discount = property.financials.discountOnBrickValue;
    const belowValuationText = showBrickValuationText ? Copy.aboveBelowValuation.belowValuationText : '';
    const aboveValuationText = showBrickValuationText ? Copy.aboveBelowValuation.aboveValuationText : '';
    const equalToValuationText = showBrickValuationText ? Copy.aboveBelowValuation.equalToValuationText : '';
    const belowValuationPopoverText = `The Lowest Available Brick Price is below the ${isPreOrderOrNotIndependentValuedProperty ? 'Initial Brick Price' : 'latest Brick Valuation'} by this percentage.`;
    const equalValuationPopoverText = `The Lowest Available Brick Price is the same as the ${isPreOrderOrNotIndependentValuedProperty ? 'Initial Brick Price.' : 'latest Brick Valuation.'}`;
    const aboveValuationPopoverText = `The Lowest Available Brick Price is above the ${isPreOrderOrNotIndependentValuedProperty ? 'Initial Brick Price' : 'latest Brick Valuation'} by this percentage.`;
    const popoverText = discount >= 0 ? (discount === 0 ? equalValuationPopoverText : aboveValuationPopoverText) : belowValuationPopoverText; // eslint-disable-line no-nested-ternary

    return (
      <span className="premium-discount-component">
        <span className="premium-discount-text">
          <span className="premium-discount">
            {!!discount && Math.abs(discount)::percentDecimal()}
            &nbsp;
            {discount === 0 && equalToValuationText}
            {discount > 0 && aboveValuationText}
            {discount < 0 && belowValuationText}
          </span>&nbsp;
          <Popover placement="top" content={popoverText}/>
        </span>
      </span>
    );
  }
}
