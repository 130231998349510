import { Component } from 'react';
import { connect } from 'react-redux';
import DocumentMeta from 'react-document-meta';
import PropTypes from 'prop-types';
import { WITHDRAW_META } from 'scripts/metadata/account';
import { accountSelector } from 'scripts/redux/selectors/account';
import { sendOtp } from 'scripts/redux/actions/user';
import {
  user as userPropType,
  account as accountPropType,
  withdrawal as withdrawalPropType,
} from 'scripts/constants/PropTypes';
import { withdrawFunds } from 'scripts/redux/actions/wallet';
import { withdrawalSelector } from 'scripts/redux/selectors/wallet';
import Wallet from 'scripts/constants/Wallet';
import Withdraw from 'scripts/components/account/wallet/Withdraw';
import WithdrawVerify from 'scripts/components/account/wallet/WithdrawVerify';
import withLegacyAccount from 'src/decorators/withLegacyAccount';

const mapStateToProps = (state) => ({
  ...accountSelector(state),
  ...withdrawalSelector(state),
});

@withLegacyAccount
@connect(mapStateToProps, {
  withdrawFunds,
  sendOtp,
})
export default class WithdrawComponent extends Component {
  static propTypes = {
    user: userPropType.isRequired,
    account: accountPropType.isRequired,
    withdrawal: withdrawalPropType,
    withdrawFunds: PropTypes.func.isRequired,
    sendOtp: PropTypes.func.isRequired,
  };

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  state = {
    withdrawStep: Wallet.WITHDRAW_STEPS.INITIAL,
    withdrawAmount: 0,
  };

  shouldComponentUpdate() {
    const { withdrawal } = this.props;

    if (withdrawal && withdrawal.type === Wallet.WITHDRAWAL.REQUESTED) {
      this._redirectTo('/account/withdraw/requested');
    } else {
      return true;
    }
  }

  UNSAFE_componentWillUpdate() {
    const { withdrawal } = this.props;

    if (withdrawal && withdrawal.type === Wallet.WITHDRAWAL.REQUESTED) {
      this._redirectTo('/account/withdraw/requested');
    }
  }

  render() {
    const { user, account, sendOtp, withdrawFunds } = this.props;

    return (
      <div className="account-withdraw">
        <DocumentMeta {...WITHDRAW_META} />
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1>
                <strong>Withdraw Funds</strong>
              </h1>
            </div>
            {this.state.withdrawStep === Wallet.WITHDRAW_STEPS.INITIAL ? (
              <Withdraw
                user={user}
                account={account}
                sendOtp={sendOtp}
                updateAmountCallback={this._updateAmount}
              />
            ) : (
              <WithdrawVerify
                user={user}
                account={account}
                amount={this.state.withdrawAmount}
                sendOtp={sendOtp}
                withdrawFunds={withdrawFunds}
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  _updateAmount = (e) => {
    this.setState({
      withdrawStep: Wallet.WITHDRAW_STEPS.VERIFY,
      withdrawAmount: e,
    });
  };

  _redirectTo = (path) => {
    const { router } = this.context;
    router.push(path);
  };
}
