import { useState } from 'react';
import OnboardingScreen, {
  OnboardingScreenHeader,
  OnboardingScreenContent,
  OnboardingFormLabel,
} from 'src/components/oldOnboarding/common/onboardingScreen/OnboardingScreen';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import Heading from 'src/design/components/heading/Heading';
import { Formik } from 'formik';
import AddressInput from 'src/design/components/addressInput/AddressInputV2';
import Divider from 'src/design/components/divider/Divider';
import _ from 'lodash';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import SmsVerify from './SmsVerify';
import UserService from 'scripts/services/UserService';
import { getCurrentUserId } from 'scripts/auth/session';
import { settingsUrl } from 'src/utils/pageUrls';
import Paragraph from 'src/design/styleguide/Paragraph';
import { Table, TableCell, TableRow } from '@mui/material';

const UpdateAddressComponent = ({ onClickBack }) => {
  const [hasSentSms, setHasSentSms] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const onSubmit = async (values) => {
    setError(null);
    const userId = getCurrentUserId();
    try {
      await UserService.updateAddress(userId, values);
      setSuccess(true);
    } catch (e) {
      if (e._body) setError(e._body);
    }
  };

  if (success) {
    return (
      <OnboardingScreen>
        <OnboardingScreenHeader>
          <Spacing bottom="x-large">
            <Heading>Your Address has been updated</Heading>
          </Spacing>
        </OnboardingScreenHeader>
        <OnboardingScreenContent>
          <PrimaryButton
            fullWidth
            color={PrimaryButton.colors.SECONDARY}
            link={settingsUrl()}
            forceExternal
          >
            RETURN TO DASHBOARD
          </PrimaryButton>
        </OnboardingScreenContent>
      </OnboardingScreen>
    );
  }

  return (
    <div className="inner">
      <div className="content">
        <Spacing bottom="x-large">
          <Heading>Update Address</Heading>
        </Spacing>
        <Spacing bottom="x-large">
          <Paragraph>
            Please note we can only accept your valid Australian residential
            address.
          </Paragraph>
        </Spacing>
      </div>
      <div className="content">
        <Formik
          initialValues={{
            otpValue: '',
            newAddress: {
              street: '',
              suburb: '',
              state: '',
              postcode: '',
            },
            newAddressFullAddress: '',
          }}
          onSubmit={async (values) => onSubmit(values)}
        >
          {(props) => {
            const { values, handleSubmit, setFieldValue } = props;
            return (
              <form onSubmit={handleSubmit}>
                <OnboardingFormLabel>
                  Enter your new address
                </OnboardingFormLabel>
                <Spacing bottom="x-large">
                  <AddressInput
                    value={values.newAddressFullAddress}
                    onChange={(v) => {
                      setFieldValue('newAddressFullAddress', v);
                    }}
                    onSuggestionSelect={(v) => {
                      setFieldValue('newAddress', {
                        street: _.first(v.fullAddress.split(',')) || '',
                        suburb: v.locality,
                        state: v.state,
                        postcode: v.postcode,
                      });
                      setFieldValue('newAddressFullAddress', v.fullAddress);
                    }}
                    fullWidth
                  />
                </Spacing>
                {(values.newAddress.street ||
                  values.newAddress.suburb ||
                  values.newAddress.state ||
                  values.newAddress.postcode) && (
                  <Spacing top="normal">
                    <div>
                      <Table size="small">
                        <TableRow>
                          <TableCell>
                            <strong>Street Address</strong>
                          </TableCell>
                          <TableCell>
                            {values.newAddress.street || '-'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Suburb</strong>
                          </TableCell>
                          <TableCell>
                            {values.newAddress.suburb || '-'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>State</strong>
                          </TableCell>
                          <TableCell>
                            {values.newAddress.state || '-'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Postcode</strong>
                          </TableCell>
                          <TableCell>
                            {values.newAddress.postcode || '-'}
                          </TableCell>
                        </TableRow>
                      </Table>
                    </div>
                  </Spacing>
                )}
                <Divider />
                {hasSentSms && (
                  <SmsVerify
                    onChangeCode={(v) => {
                      setError(null);
                      setFieldValue('otpValue', v);
                    }}
                  />
                )}
                {error && (
                  <Spacing>
                    <div className="text-red">{error}</div>
                  </Spacing>
                )}
                <Spacing top="normal">
                  {!hasSentSms ? (
                    <PrimaryButton
                      fullWidth
                      color={PrimaryButton.colors.SECONDARY}
                      onClick={() => setHasSentSms(true)}
                      disabled={
                        !(
                          values.newAddress.street &&
                          values.newAddress.suburb &&
                          values.newAddress.state &&
                          values.newAddress.postcode
                        )
                      }
                    >
                      NEXT
                    </PrimaryButton>
                  ) : (
                    <PrimaryButton
                      fullWidth
                      color={PrimaryButton.colors.SECONDARY}
                      disabled={values.otpValue.length !== 6}
                    >
                      SUBMIT
                    </PrimaryButton>
                  )}
                </Spacing>
                <Spacing top="normal">
                  <PrimaryButton
                    fullWidth
                    color={PrimaryButton.colors.PRIMARY}
                    onClick={onClickBack}
                  >
                    CANCEL
                  </PrimaryButton>
                </Spacing>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default UpdateAddressComponent;
