import PropTypes from 'prop-types';
import {
  property as propertyType,
  propertyAverageAnnualGrowthMetrics as historicalGrowthMetricsType,
} from 'scripts/constants/PropTypes';
import BrickPriceStat from 'src/components/propertyCards/common/stats/BrickPriceStat';
import EstNetRentalYieldStat from 'src/components/propertyCards/common/stats/EstNetRentalYieldStat';
import HistoricalSuburbGrowthStat from 'src/components/propertyCards/common/stats/HistoricalSuburbGrowthStat';
import LowestAvailableBrickPriceStat from 'src/components/propertyCards/propertyCardMarketOpen/lowestAvailableBrickPriceStat/LowestAvailableBrickPriceStat';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import PropertyCardBodyFiveStatLayout from 'src/components/propertyCards/common/propertyCardBodyFiveStatLayout/PropertyCardBodyFiveStatLayout';
import PropertyCardButtonContainer from 'src/components/propertyCards/common/propertyCardButtonContainer/PropertyCardButtonContainer';
import PropertyCardContainer from 'src/components/propertyCards/common/propertyCardContainer/PropertyCardContainer';
import PropertyCardFeature from 'src/components/propertyCards/common/propertyCardHero/PropertyCardHero';
import PropertyCardHeader from 'src/components/propertyCards/common/propertyCardHeader/PropertyCardHeader';
import PropertyCardStat from 'src/components/propertyCards/common/propertyCardStat/PropertyCardStat';
import PropertyStatus from 'scripts/constants/PropertyStatus';
import NumberFormatter, { Formatter } from 'scripts/utilities/NumberFormatter';
import _ from 'lodash';
import PropertyCardFlag from './PropertyCardFlag';
import { SOLD_PROPERTIES } from 'src/settings/trading';
import PropertyCardPromoBody from './common/PropertyCardPromoBody';
import Formatters from 'scripts/utilities/formattersV2';

/*
========================================
PropertyCard :: "Future" Properties
========================================
*/

export const PropertyCardForFuturePlan = ({ futurePlan }) => {
  return (
    <PropertyCardContainer>
      <div className="property-card-header property-card-header--future">
        <div className="property-card-header__left-content">
          <div className="property-card-header__property-code">
            {futurePlan.subTitle}
          </div>
          <div className="property-card-header__location">
            {futurePlan.title}
          </div>
        </div>
      </div>
      <div className="property-card-feature">
        <div className="property-card-feature__image-container">
          <div
            className="property-card-feature__image"
            style={{
              backgroundImage: `url(${
                futurePlan.featureImageUrl || '/static/images/properties.png'
              })`,
            }}
            data-test-reference="property-card-feature-image"
          ></div>
        </div>
      </div>
      <PropertyCardPromoBody body={futurePlan.body} />
      <PropertyCardButtonContainer>
        {futurePlan.link ? (
          <PrimaryButton
            className="property-card__button"
            color={PrimaryButton.colors.SECONDARY}
            arrow
            testRef="button"
            link={futurePlan.link}
          >
            LEARN MORE
          </PrimaryButton>
        ) : (
          <PrimaryButton
            className="property-card__button"
            color={PrimaryButton.colors.SECONDARY}
            testRef="button"
            disabled
          >
            COMING SOON
          </PrimaryButton>
        )}
      </PropertyCardButtonContainer>
    </PropertyCardContainer>
  );
};

/*
========================================
PropertyCard :: "Market Open" Properties
========================================
*/
const PropertyCardForMarketOpen = ({
  property,
  historicalGrowthMetrics,
  flag,
}) => {
  return (
    <PropertyCardContainer link={`/properties/${property.propertyCode}`}>
      <PropertyCardHeader property={property} flag={flag} />
      <PropertyCardFeature property={property} />
      <PropertyCardBodyFiveStatLayout
        primary={<LowestAvailableBrickPriceStat property={property} />}
        secondaries={[
          <EstNetRentalYieldStat property={property} />,
          <HistoricalSuburbGrowthStat
            historicalGrowthMetrics={historicalGrowthMetrics}
            propertyType={property.propertyType}
          />,
          <BrickPriceStat property={property} />,
          <PropertyCardStat
            label="Investors"
            value={
              property.investmentMetrics
                ? Formatters.numeric.number(
                    property.investmentMetrics.investors
                  )
                : '-'
            }
            valueProps={{ 'data-test-reference': 'investors' }}
          />,
        ]}
      />
      <PropertyCardButtonContainer>
        <PrimaryButton
          className="property-card__button"
          color={PrimaryButton.colors.SECONDARY}
          arrow
          testRef="button"
        >
          VIEW PROPERTY
        </PrimaryButton>
      </PropertyCardButtonContainer>
    </PropertyCardContainer>
  );
};

/*
=======================================
PropertyCard :: "Off Market" Properties
=======================================
*/
const PropertyCardForOffMarket = ({ property, distributions }) => {
  let distributionStats = null;
  if (distributions && distributions.length > 0) {
    distributionStats = {
      totalDistributedPerBrick: _.sumBy(distributions, 'amount'),
      avgDistributed: _.meanBy(distributions, 'amount'),
      monthsDistributed: distributions.length,
      totalDistributed:
        _.sumBy(distributions, 'amount') * property.financials.numberBricks,
    };
  } else {
    distributionStats = {
      totalDistributedPerBrick: 0,
      avgDistributed: 0,
      monthsDistributed: 0,
    };
  }
  const soldStats = {
    soldPrice: SOLD_PROPERTIES[property.propertyCode]
      ? SOLD_PROPERTIES[property.propertyCode].soldPrice
      : 0,
    finalBrickPrice: SOLD_PROPERTIES[property.propertyCode]
      ? SOLD_PROPERTIES[property.propertyCode].finalBrickPrice
      : 0,
    valueChange: SOLD_PROPERTIES[property.propertyCode]
      ? SOLD_PROPERTIES[property.propertyCode].soldPrice /
          property.financials.purchasePrice -
        1
      : 0,
    finalBrickPriceChange: SOLD_PROPERTIES[property.propertyCode]
      ? SOLD_PROPERTIES[property.propertyCode].finalBrickPrice /
          property.financials.initialBrickPrice -
        1
      : 0,
    totalReturn: SOLD_PROPERTIES[property.propertyCode]
      ? distributionStats.totalDistributed +
        (SOLD_PROPERTIES[property.propertyCode].finalBrickPrice -
          property.financials.initialBrickPrice) *
          property.financials.numberBricks
      : 0,
    totalReturnInPerc: SOLD_PROPERTIES[property.propertyCode]
      ? (distributionStats.totalDistributedPerBrick +
          SOLD_PROPERTIES[property.propertyCode].finalBrickPrice) /
          property.financials.initialBrickPrice -
        1
      : 0,
  };

  return (
    <PropertyCardContainer>
      <PropertyCardHeader
        property={property}
        flag={<PropertyCardFlag property={property} />}
      />
      <PropertyCardFeature property={property} withGreyout />
      <PropertyCardBodyFiveStatLayout
        primary={
          <PropertyCardStat
            label="Total Return"
            style={PropertyCardStat.styles.PRIMARY}
            value={
              <span>
                <span style={{ display: 'inline-block' }}>
                  <span
                    style={{
                      // fontSize: '1.5rem',
                      color: soldStats.totalReturnInPerc > 0 ? 'green' : 'grey',
                    }}
                  >
                    <NumberFormatter
                      value={soldStats.totalReturnInPerc * 100}
                      formatter={Formatter.MONEY}
                      suffix={'%'}
                    />{' '}
                    <i
                      className={
                        soldStats.totalReturnInPerc > 0
                          ? 'fa fa-caret-up'
                          : 'fa fa-caret-down'
                      }
                    ></i>
                  </span>
                </span>
                <span
                  style={{
                    fontSize: '1.3rem',
                    display: 'block',
                    marginTop: '-5px',
                  }}
                >
                  ( Capital + Revenue )
                </span>
              </span>
            }
          />
        }
        secondaries={[
          <PropertyCardStat
            label="Purchase Price"
            value={
              <NumberFormatter
                value={property.financials.purchasePrice}
                formatter={Formatter.MONEY}
                prefix={'$'}
              />
            }
          />,
          <PropertyCardStat
            label="Sold Price"
            value={
              <span>
                <NumberFormatter
                  value={soldStats.soldPrice}
                  formatter={Formatter.MONEY}
                  prefix={'$'}
                />{' '}
              </span>
            }
          />,
          <PropertyCardStat
            label="Final Brick Price"
            value={
              <span>
                <NumberFormatter
                  value={soldStats.finalBrickPrice}
                  formatter={Formatter.MONEY}
                  prefix={'$'}
                />{' '}
              </span>
            }
          />,
          <PropertyCardStat
            label={
              <span>
                Total distributions paid
                <br />
                per brick
              </span>
            }
            value={
              <span>
                <NumberFormatter
                  value={distributionStats.totalDistributedPerBrick}
                  formatter={Formatter.MONEY}
                  prefix={'$'}
                />
              </span>
            }
          />,
        ]}
      />
    </PropertyCardContainer>
  );
};

const PropertyCard = (props) => {
  if (props.property.propertyStatus === PropertyStatus.MARKET_OPEN) {
    return <PropertyCardForMarketOpen {...props} />;
  }
  if (props.property.propertyStatus === PropertyStatus.OFF_MARKET) {
    return <PropertyCardForOffMarket {...props} />;
  }
  return <PropertyCardForMarketOpen {...props} />;
};

PropertyCard.propTypes = {
  property: propertyType,
  historicalGrowthMetrics: historicalGrowthMetricsType,
  flag: PropTypes.element,
};

export default PropertyCard;
