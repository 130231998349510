import _, { omit } from 'lodash';
import { connect } from 'react-redux';
import { HarmonyConfig } from 'src/browser/harmonyConfigEnvMapper';
import { addressSuggestionsSelector } from 'src/store/addressLookupReducer';
import { fetchAddressSuggestions as fetchAddressSuggestionsAction } from 'src/store/addressLookupActions';
import AutoFillTextInput from 'src/design/components/autoFillTextInput/AutoFillTextInput';
import harmony from 'src/browser/harmony';
import withState from 'src/decorators/withState';
import { settings } from './addressInputConfigs';

const REJECTED_POSTAL_TYPES = settings.REJECTED_POSTAL_TYPES;

const AddressInput = withState({
  Component: ({
    fetchAddressSuggestions,
    addressSuggestions,
    value,
    harmonyValue,
    onChange,
    onSuggestionSelect,
    showError,
    ...props
  }) => (
    <AutoFillTextInput
      placeholder="e.g. 100 Smith Street"
      error={showError && 'Please enter a valid address'}
      testRef="address"
      errorTestRef="address-error"
      listTestRef="address-suggestions-list"
      listItemTestRef="address-suggestions-item"
      {...omit(props, ['setState', 'state'])}
      value={value}
      onChange={(newValue) => {
        fetchAddressSuggestions(newValue);
        onChange(newValue);
      }}
      suggestions={
        harmonyValue
          ? []
          : addressSuggestions
              .filter(
                (ele) => !_.includes(REJECTED_POSTAL_TYPES, ele.postalType)
              )
              .map((harmonyObject) => harmonyObject.fullAddress)
      }
      onSuggestionSelect={(index) =>
        onSuggestionSelect(addressSuggestions[index])
      }
    />
  ),
  componentDidMount: () => {
    const harmonyConfig = HarmonyConfig();
    harmony.init(
      harmonyConfig.username,
      harmonyConfig.password,
      harmony[harmonyConfig.sourceOfTruth]
    );
  },
});

export default connect(addressSuggestionsSelector, {
  fetchAddressSuggestions: fetchAddressSuggestionsAction,
})(AddressInput);
