import { Component } from 'react';
import PropTypes from 'prop-types';
import Markdown from 'react-remarkable';

import { property as propertyPropType, monthlyUpdates as monthlyUpdatesPropType } from 'scripts/constants/PropTypes';

import Loading from 'src/components/loading/Loading';
import FinancialsPanel from 'scripts/components/shared/FinancialsPanel';
import ExtendibleText from 'scripts/components/shared/ExtendibleText';

import _ from 'lodash';
import { monthYear } from 'scripts/utilities/formatters';


const INITIAL_HEIGHT = 240;
export default class MonthlyUpdates extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    // showAll: PropTypes.bool,
    property: propertyPropType.isRequired,
    monthlyUpdates: monthlyUpdatesPropType,
  };


  render() {
    const { title, monthlyUpdates } = this.props;

    if (!monthlyUpdates) {
      return <Loading/>;
    }

    return (
      <FinancialsPanel title={title}>
        {_.isEmpty(monthlyUpdates)
          ?
          <p className="no-news">There are no updates.</p>
          :
          <ExtendibleText initialHeight={INITIAL_HEIGHT}>
            {monthlyUpdates.map((monthlyUpdate, index) => (
              <div key={index}>
                <p className="monthly-update-time"><b>{(monthlyUpdate.date)::monthYear()}</b></p>
                <Markdown source={monthlyUpdate.monthlyDistributionInfo}/>
                <Markdown source={monthlyUpdate.brickValuationInfo}/>
                <Markdown source={monthlyUpdate.additionalInfo}/>
              </div>
            ))}
          </ExtendibleText>
        }
      </FinancialsPanel>
    );
  }

  // handleShowAllClick = () => {
  //  this.setState({ showAll: !this.state.showAll });
  //  this.forceUpdate();
  // }

}
