import { HarmonyConfig } from 'src/browser/harmonyConfigEnvMapper';
import harmony from 'src/browser/harmony';

export const establishHarmonyAddressLookupTransaction = () =>
  HarmonyConfig().env !== 'LOCAL' &&
  harmony.transaction(harmony[HarmonyConfig().sourceOfTruth], () => {});

export const getHarmonySuggestions = (searchText, successCallback) => {
  harmony.address(
    { fullAddress: searchText },
    harmony[HarmonyConfig().sourceOfTruth],
    (response) =>
      response.status === harmony.SUCCESS && successCallback(response)
  );
};
