import { cloneElement } from 'react';
import classNames from 'classnames';
import styles from 'src/design/components/bodyText/BodyText.mscss';

const alignmentClass = (alignment) => `bodyText--align-${alignment}`;
const sizeClass = (size) => `bodyText--${size}-text`;
const colorClass = (color) => `bodyText--color-${color}`;

const classMap = ({ align, size, color }) => [
  styles.bodyText,
  styles[alignmentClass(align)],
  styles[sizeClass(size)],
  styles[colorClass(color)],
];

const BodyText = ({ children, className, textSize, align, color, testRef }) => {
  const customClassNames = classMap({
    align,
    size: textSize,
    className,
    color,
  });

  return children && children.props ? (
    cloneElement(children, {
      className: classNames(
        children.props.className,
        ...customClassNames,
        className
      ),
    })
  ) : (
    <span
      className={classNames(...customClassNames, className)}
      data-test-reference={testRef}
    >
      {children}
    </span>
  );
};

export default BodyText;
