import moment from 'moment-timezone';
import DateTime from 'scripts/constants/DateTime';
import numeral from 'numeral';
import escapeTextContentForBrowser from 'react-lib-escape-text-content-for-browser';
import { renderCode } from 'src/settings/properties';

const Formatters = {
  /**
   *
   *
   * All numeric value formatters
   *
   *
   */
  numeric: {
    dollar: (value) => {
      return numeral(value).format('$0,0');
    },
    dollarAbbreviated: (value) => {
      return numeral(value).format('$0.0a');
    },
    dollarDecimal: (value) => {
      return numeral(value).format('$0,0.00');
    },
    threeDollarDecimal: (value) => {
      return numeral(value).format('$0,0.000');
    },
    number: (value) => {
      return numeral(value).format('0,0');
    },
    priceWithNoDecimalsRounded: (value) => {
      return numeral(value).format('$0,0');
    },
    priceWithNoDecimalsFloored: (value) => {
      return numeral(value).format('$0,0', Math.floor);
    },
    decimals: (value) => {
      return numeral(value).format('.00');
    },
    /**
     * convert text/number type value to "0,0.00" format
     *
     * @param {*} value
     * @returns string
     */
    numberDecimal: (value) => {
      return numeral(value).format('0,0.00');
    },
    /**
     * convert text/number type value to "0%" format
     *
     * @param {*} value
     * @returns string
     */
    percent: (value) => {
      return numeral(value).format('0%');
    },
    /**
     * convert text/number type value to "0.00%" format
     *
     * @param {*} value
     * @returns string
     */
    percentDecimal: (value) => {
      return numeral(value).format('0.00%');
    },
    /**
     * convert text/number type value to "+0.00%" format
     *
     * @param {*} value
     * @returns string
     */
    percentDecimalWithSign: (value) => {
      return numeral(value).format('+0.00%');
    },
    /**
     * convert text/number type value to "-0.00%" format
     *
     * @param {*} value
     * @returns string
     */
    percentDecimalWithMinusSign: (value) => {
      return numeral(value).format('-0.00%');
    },
    roundPercentDecimal: (value) => {
      return Math.round(value * 100) / 100;
    },
    normalizePercent: (value) => {
      return value * 100;
    },
    normalizedPercentDecimal: (value) => {
      return numeral(value * 100).format('0,0.0');
    },
    dividePercent: (value) => {
      return value / 100;
    },
    brickFloor: (value) => {
      return Math.floor(value);
    },
  },
  /**
   *
   *
   * All time related value formatters
   *
   *
   */
  time: {
    dayMonthCommaYearExtended: (value) => {
      return moment(value).tz(DateTime.TIME_ZONE).format('DD MMMM, YYYY');
    },
    dayMonthCommaYearAndTimeExtendedWithTimeZone: (value) => {
      return moment(value)
        .tz(DateTime.TIME_ZONE)
        .format('Do MMMM, YYYY [at] hh:mm a z');
    },
    monthCommaYearExtended: (value) => {
      return moment(value).tz(DateTime.TIME_ZONE).format('MMMM, YYYY');
    },
    dayMonthYearExtended: (value) => {
      return moment(value).tz(DateTime.TIME_ZONE).format('DD MMMM YYYY');
    },
    monthYearExtended: (value) => {
      return moment(value).tz(DateTime.TIME_ZONE).format('MMMM YYYY');
    },
    monthYear: (value) => {
      return moment(value).tz(DateTime.TIME_ZONE).format('MMM YYYY');
    },
    monthYearShorten: (value) => {
      return moment(value).tz(DateTime.TIME_ZONE).format("MMM [']YY");
    },
    monthYearShort: (value) => {
      return moment(value).tz(DateTime.TIME_ZONE).format('MMM-YY');
    },
    dayMonthAndShortYearNoDash: (value) => {
      return moment(value).tz(DateTime.TIME_ZONE).format("DD MMM [']YY");
    },
    nextMonthYear: (value) => {
      return moment(value)
        .add(1, 'months')
        .tz(DateTime.TIME_ZONE)
        .format('MMM YYYY');
    },
    year: (value) => {
      return moment(value).tz(DateTime.TIME_ZONE).format('YYYY');
    },
    month: (value) => {
      return moment(value).tz(DateTime.TIME_ZONE).format('MMM');
    },
    dateWithSlash: (value) => {
      return moment(value).tz(DateTime.TIME_ZONE).format('DD/MM/YYYY');
    },
    dateWithSlashUS: (value) => {
      return moment(value).tz(DateTime.TIME_ZONE).format('MM/DD/YYYY hh:mm:s');
    },
    dayAndExtendedMonth: (value) => {
      return moment(value).tz(DateTime.TIME_ZONE).format('DD MMM');
    },
    timeWithTimeZoneShort: (value) => {
      return moment(value).tz(DateTime.TIME_ZONE).format('hh A z');
    },
    timeWithTimeZone: (value) => {
      return moment(value).tz(DateTime.TIME_ZONE).format('hh:mm A z');
    },
    date: (value) => {
      return moment(value).tz(DateTime.TIME_ZONE).format('DD MMM YYYY');
    },
    dateTime: (value) => {
      return moment(value)
        .tz(DateTime.TIME_ZONE)
        .format(DateTime.DATE_TIME_FORMAT);
    },
    dateHalfExtended: (value) => {
      return moment(value).tz(DateTime.TIME_ZONE).format('Do MMM YYYY');
    },
    daysTillNow: (value) => {
      const today = moment().tz(DateTime.TIME_ZONE).startOf('day');
      const givenTime = moment(value).tz(DateTime.TIME_ZONE).startOf('day');
      return today.diff(givenTime, 'days') + 1;
    },
    monthsTillNow: (value) => {
      const today = moment().tz(DateTime.TIME_ZONE).startOf('month');
      const givenTime = moment(value).tz(DateTime.TIME_ZONE).startOf('month');
      return today.diff(givenTime, 'months');
    },
    daysUntil: (value) => {
      const today = moment().tz(DateTime.TIME_ZONE).startOf('day');
      const givenTime = moment(value).tz(DateTime.TIME_ZONE).startOf('day');
      return givenTime.diff(today, 'days');
    },
    today: () => {
      return moment().tz(DateTime.TIME_ZONE).format('DD MMM YYYY');
    },
    isInLastMonth: (value) => {
      const today = moment().tz(DateTime.TIME_ZONE);
      const daysOfMonth = 30;
      const aMonthAgo = moment()
        .tz(DateTime.TIME_ZONE)
        .subtract(daysOfMonth, 'day');
      const givenDateTime = moment(value).tz(DateTime.TIME_ZONE);
      return givenDateTime.isBetween(aMonthAgo, today, null, '[]');
    },
    isToday: (value) => {
      const today = moment().tz(DateTime.TIME_ZONE).startOf('day');
      const givenTime = moment(value).tz(DateTime.TIME_ZONE).startOf('day');
      return givenTime.diff(today, 'days') === 0;
    },
    now: () => {
      return moment().tz(DateTime.TIME_ZONE).format(DateTime.DATE_TIME_FORMAT);
    },
    months: (value) => {
      let pluralisedText = '';
      if (this > 1) pluralisedText = `${value} months`;
      else pluralisedText = `${value} month`;
      return pluralisedText;
    },
    minute: (value) => {
      var minute = '';
      if (value > 1) minute = `${value} minutes`;
      else minute = `${value} minute`;
      return minute;
    },
  },
  /**
   *
   *
   * All Text formatters
   *
   *
   */
  text: {
    uuid: (value) => {
      return (value || '').substring(24);
    },
    htmlEncoded: (value) => {
      return escapeTextContentForBrowser(value);
    },
    capitalize: (value) => {
      const value_ = value || '';
      return value_.charAt(0).toUpperCase() + value_.slice(1);
    },
    suburb: () => {
      return this.split(',')[1];
    },
    street: () => {
      return this.split(',')[0];
    },
    propertyName: ({ suburb, state, propertyCode }) => {
      return `${suburb} ${state} (${renderCode(propertyCode)})`;
    },
  },
};

export default Formatters;
