import PropTypes from 'prop-types';
import Markdown from 'react-remarkable';

import FinancialsPanel from 'scripts/components/shared/FinancialsPanel';

import { property as propertyPropType } from 'scripts/constants/PropTypes';

const TopReasons = ({ property, linkText, link }) => {
  if (!property) {
    return null;
  }

  return (
    <FinancialsPanel
      className="panel-financials-transparent-header panel-financials-reasons-to-invest panel-financials-with-link"
      title={`Investment Case Overview`}
      linkText={linkText}
      link={link ? link : null}
      titleIcon="icn-details-blue"
    >
      <div className="row">
        <div className="col-md-12">
          <div className="grey-box reasons-text">
            {property.reasonsToInvest ? (
              <div className="brickx-react-markdown">
                <Markdown source={property.reasonsToInvest} />
              </div>
            ) : (
              <p className="no-news">No information.</p>
            )}
          </div>
        </div>
      </div>
    </FinancialsPanel>
  );
};

TopReasons.prototype = {
  title: PropTypes.string.isRequired,
  linkText: PropTypes.string,
  link: PropTypes.string,
  property: propertyPropType.isRequired,
};

export default TopReasons;
