import { Component } from 'react';

import Markdown from 'react-remarkable';

import {
  property as propertyPropType,
  user as userPropType,
  monthlyUpdates as monthlyUpdatesPropType,
} from 'scripts/constants/PropTypes';
import CriticalNews from 'scripts/components/property/CriticalNews';
import PropertyFeatures from 'scripts/components/property/details/PropertyFeatures';
import DueDiligenceAndDocuments from 'scripts/components/property/details/DueDiligenceAndDocuments';
import FinancialsPanel from 'scripts/components/shared/FinancialsPanel';
import PropertyVideo from 'scripts/components/property/PropertyVideo';
import MonthlyUpdates from 'scripts/components/property/MonthlyUpdates';
import PrevNextBar from 'scripts/components/property/PrevNextBar';
import ExtendibleText from 'scripts/components/shared/ExtendibleText';

import DocumentMeta from 'react-document-meta';
import { DETAILS_META } from 'scripts/metadata/properties';
import { sendPropertyTabLoad } from 'scripts/redux/actions/segment/events/propertiesPageEvents';

const INITIAL_HEIGHT = 200;

export default class PropertyDetails extends Component {
  static propTypes = {
    user: userPropType,
    property: propertyPropType,
    monthlyUpdates: monthlyUpdatesPropType,
  };

  componentDidMount() {
    sendPropertyTabLoad({
      tabName: 'Details',
      propertyCode: this.props.property.propertyCode,
    });
  }

  render() {
    const { user, property, monthlyUpdates } = this.props;

    return (
      <div className="property-overview">
        <div className="container">
          <div>
            <DocumentMeta {...DETAILS_META(property)} />
            <div className="row">
              <div className="col-md-12">
                <CriticalNews criticalNews={property.criticalNews} />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 investment-case-panel">
                <FinancialsPanel title="Investment Case">
                  <div className="description">
                    <ExtendibleText initialHeight={INITIAL_HEIGHT}>
                      <div className="brickx-react-markdown">
                        <Markdown source={property.description} />
                      </div>
                    </ExtendibleText>
                  </div>
                </FinancialsPanel>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <PropertyFeatures property={property} />
              </div>
              <div className="col-md-6">
                <PropertyVideo videoUrl={property.videoUrl} />
              </div>
            </div>
            <div className="details-section row">
              <div className="col-md-6">
                <MonthlyUpdates
                  title="Monthly Updates"
                  property={property}
                  monthlyUpdates={monthlyUpdates}
                />
              </div>
              <div className="due-diligence col-md-6">
                <DueDiligenceAndDocuments user={user} property={property} />
              </div>
            </div>
            <PrevNextBar
              prevTitle="Capital Returns"
              prevLink={`/properties/${property.propertyCode}/returns`}
            />
          </div>
        </div>
      </div>
    );
  }
}
