export const scrollToTop = () => window.scrollTo(0, 0);
export const scrollToId = (id) => {
  const el = document.getElementById(id);
  if (el) el.scrollIntoView();
};
export const scrollTo = (x, y) => window.scrollTo(x, y);
export const getScrollY = () => window.scrollY;
export const updateLocationHref = (href) => window.location.href = href;
export const sessionStorage = window.sessionStorage;
export const location = window.location;
export const dataLayer = window.dataLayer;
export const fcWidget = window.fcWidget;
export const open = (...props) => window.open(...props);
export const env = window.__env__;
