import styled, { css } from 'styled-components';
import { getThemeColor } from 'src/themes/themeUtils';
import { sideGutter } from 'src/design/styleguide/common/measurements';

const getBackgroundImageStyles = (imageUrl) => css`
  position: relative;
  background: url('${imageUrl}') no-repeat center;
  background-size: cover;
  background-position-x: ${props => props.backgroundPositionX || 'center'};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: ${props => props.overlayColor ? getThemeColor(props.overlayColor)(props) : 'white'};
    opacity: ${props => props.overlayOpacity || 0.75};
  }
`;

const colors = {
  'lightgrey': 'grey50',
  'grey': 'grey100',
  'charcoal': 'charcoal',
  'white': 'white'
};

const ContentSection = styled.div.attrs((props) => ({
  'data-test-reference': props.testRef,
  ...props,
}))`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;

  ${props => props.noGutter
    ? ''
    : `
      padding-right: ${sideGutter};
      padding-left: ${sideGutter};
    `
  }

  ${props => props.backgroundImage
    ? getBackgroundImageStyles(props.backgroundImage)
    : ''
  }

  ${props => props.backgroundColor
    ? `background-color: ${getThemeColor(colors[props.backgroundColor])(props)};`
    : ''
  }
`;

export default ContentSection;
