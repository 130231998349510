import update from 'immutability-helper';
import {
  ERRORS_UPDATED,
  FIELD_UPDATED,
  GET_STEP_COMPLETE,
  SERVER_ERRORS_UPDATED,
  USER_ID_UPDATED,
} from 'src/components/flows/store/flowsActions';

export const flowsFieldsSelector = (state) => ({ fields: state.flows.fields });
export const flowsStepIdSelector = (state) => ({ currentStepId: state.flows.currentStepId });
export const flowsUserIdSelector = (state) => ({ userId: state.flows.userId });

const defaultState = {
  currentStepId: null,
  fields: [],
  userId: null,
};

const updateStep = (state, { id, fields }) => update(state, {
  currentStepId: { $set: id },
  fields: { $set: fields.map((field) => ({ ...field, value: '', error: null, serverErrors: [] })) },
});

const updateFields = (state, { fieldId, value }) => {
  const indexOfFieldToUpdate = state.fields.findIndex((field) => field.id === fieldId);

  return update(state, {
    fields: {
      $splice: [[
        indexOfFieldToUpdate,
        1,
        {
          ...state.fields[indexOfFieldToUpdate],
          value: value,
          error: null,
          serverErrors: [],
        }
      ]]
    },
  });
};

const updateErrors = (state, errors) => update(state, {
  fields: {
    $set: state.fields.map((field) =>
      errors[field.id]
        ? { ...field, error: errors[field.id] }
        : field
    )
  },
});

const updateServerErrors = (state, serverError) => update(state, {
  fields: {
    $set: state.fields.map((field) =>
      serverError.validationErrors
        ? serverError.validationErrors[field.id]
        ? { ...field, serverErrors: serverError.validationErrors[field.id].map(({ rule }) => rule) }
        : field
        : field
    )
  },
});

const updateUserId = (state, userId) => update(state, {
  userId: { $set: userId },
});

const flowsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_STEP_COMPLETE: return updateStep(state, action.payload);
    case FIELD_UPDATED: return updateFields(state, action.payload);
    case ERRORS_UPDATED: return updateErrors(state, action.payload);
    case SERVER_ERRORS_UPDATED: return updateServerErrors(state, action.payload);
    case USER_ID_UPDATED: return updateUserId(state, action.payload);
    default: return state;
  }
};

export default flowsReducer;
