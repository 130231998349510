import { WelcomePageWidgetPanel, SeeMoreLink } from 'src/components/dashboard/home/commonComponents';
import { transactionsUrl } from 'src/utils/pageUrls';
import NoTransactionsMessageJustText from 'src/components/transactions/NoTransactionsMessageJustText';
import TransactionsTable from 'src/components/transactions/TransactionsTable';
import TransactionsTabs from 'src/components/transactions/TransactionsTabs';
import withDetailedTransactions from 'src/decorators/withDetailedTransactions';

const TransactionsPanel = withDetailedTransactions(({ className, detailedTransactions }) => (
  <WelcomePageWidgetPanel
    className={className}
    title="Latest Transactions"
    action={<SeeMoreLink href={transactionsUrl()}/>}
    testRef="transactions-panel"
  >
    <TransactionsTabs
      detailedTransactions={detailedTransactions}
      noTransactionsMessage={<NoTransactionsMessageJustText/>}
      renderTransactionsTable={(transactions) => (
        <TransactionsTable
          transactions={transactions.slice(0, 5)}
          trackingLocation="Dashboard Welcome Page"
        />
      )}
    />
  </WelcomePageWidgetPanel>
));

export default TransactionsPanel;
