import styled from 'styled-components';
import { Heading } from 'src/design/styleguide/Headings';
import { breakpoint2xSmall } from 'src/design/styleguide/common/breakpoints';
import { gridUnit } from 'src/design/styleguide/common/measurements';
import { pdsUrl } from 'src/utils/pageUrls';
import { trackInvestmentTermQuestion } from 'src/tracking/onboarding';
import ContentContainer from
  'src/design/styleguide/contentContainer/ContentContainer';
import FinePrintParagraph from 'src/design/styleguide/FinePrintParagraph';
import Image from 'src/design/components/image/Image';
import Paragraph from 'src/design/styleguide/Paragraph';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import SecondaryButton from 'src/design/components/button/SecondaryButton';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import Styledlink from 'src/design/components/hyperlink/Styledlink';

const LightbulbIcon = styled(Image).attrs({
  src: '/static/img/icon-light-bulb-with-house-inside.svg',
  alt: 'illustrated graph going up and down',
})`
  display: block;
  max-width: 100px;
  margin-left: auto;
  margin-right: auto;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpoint2xSmall(`
    flex-direction: row;
    justify-content: center;
  `)}
`;

const CancelButton = styled(PrimaryButton).attrs({
  color: PrimaryButton.colors.SECONDARY,
  testRef: 'cancel-button',
})`
  margin-bottom: ${gridUnit * 4}px;

  ${breakpoint2xSmall(`
    margin-bottom: 0;
    margin-right: ${gridUnit * 4}px;
  `)}
`;

const ContinueButton = styled(SecondaryButton).attrs({
  color: SecondaryButton.colors.PRIMARY,
  testRef: 'continue-anyway-button',
})``;

const MainParagraph = styled(Paragraph)`
  font-size: ${gridUnit * 6}px;
`;

const ShortTermAnswerSelectedPopup = ({ closeThisModal, onContinue }) => (
  <ContentContainer size="small">
    <LightbulbIcon/>

    <Spacing top="normal">
      <Heading>Did you know?</Heading>
    </Spacing>

    <Spacing top="large">
      <MainParagraph align="center">Property is generally better for a longer-term investment, given market cycles where property prices may go up and down.</MainParagraph>
    </Spacing>
    <Spacing top="x-large">
      <FinePrintParagraph align="center">Please refer to the <Styledlink href={pdsUrl()}>PDS</Styledlink> to see if investing in BrickX to access the property market is right for you.</FinePrintParagraph>
    </Spacing>

    <Spacing top="x-large">
      <ButtonsContainer>
        <CancelButton
          onClick={() => {
            trackInvestmentTermQuestion('cancel modal');
            closeThisModal();
          }}
        >
          Cancel
        </CancelButton>

        <ContinueButton
          onClick={() => {
            closeThisModal();
            onContinue();
          }}
        >
          Continue Anyway
        </ContinueButton>
      </ButtonsContainer>
    </Spacing>
  </ContentContainer>
);

export default ShortTermAnswerSelectedPopup;
