import { Component, cloneElement } from 'react';
import PropTypes from 'prop-types';
import request from 'scripts/services/request';
import { bankNameUrl } from 'scripts/services/api';
import {
  isBsbValid,
  isBsbObfuscated,
} from 'src/utils/user';


class BankNameProvider extends Component {
  static propTypes = {
    bsb: PropTypes.string,
    onChange: PropTypes.func,
  }

  state = { bankName: '', error: false };

  componentDidMount() {
    this.mounted = true;
    this.getBankName(this.props.bsb);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.bsb === this.props.bsb) { return; }

    this.getBankName(nextProps.bsb);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getBankName(bsb) {
    if (!isBsbValid(bsb) || isBsbObfuscated(bsb)) { return this.updateBankName(''); }

    request(bankNameUrl(bsb))
      .then(
        ({ fiName }) => this.updateBankNameSuccess(fiName),
        () => this.updateBankNameFailure()
      );
  }

  updateBankNameSuccess(bankName) {
    if (!this.mounted) return;
    this.updateBankName(bankName);
  }

  updateBankNameFailure() {
    if (!this.mounted) return;
    this.updateBankName('', { error: true });
  }

  updateBankName(bankName, { error = false } = {}) {
    this.props.onChange && this.props.onChange(bankName);
    this.setState({ bankName: bankName, error });
  }

  render() {
    const { children } = this.props;
    const { bankName, error } = this.state;

    return cloneElement(children, { bankName, error });
  }
}

export default BankNameProvider;
