import { createAction, handleActions } from 'redux-actions';
import update from 'immutability-helper';

import MarketService from 'scripts/services/MarketService';

import { sendFirstBrickPurchasedSegmentEvents } from 'scripts/redux/actions/segment/events/tradeEvents';
import { sendConversionEvent } from 'src/tracking/onboarding';
import { errorAlert, successAlert } from 'scripts/redux/actions/alerts';

import { uuid as uuidFormatter } from 'scripts/utilities/formatters';
import RegistrationStep from 'scripts/constants/RegistrationStep';
import Trade from 'scripts/constants/Trade';
import Configuration from 'scripts/constants/Configuration';
import { planWasNeverSignedUpTo, getSmartInvestPlan } from 'src/utils/account';
import request from 'scripts/services/request';
import { fees as feesApiUrl } from 'scripts/services/api';

const ORDERS = 'scripts/market/ORDERS';
const TRADES = 'scripts/market/TRADES';
const LAST_N_TRADES = 'scripts/market/LAST_N_TRADES';

const REMAINING_BRICKS = 'scripts/market/REMAINING_BRICKS';
const BRICKS_TRANSACTED_LAST_MONTH =
  'scripts/market/BRICKS_TRANSACTED_LAST_MONTH';
const INVESTORS = 'scripts/market/INVESTORS';
const TRADE_PROPOSAL = 'scripts/market/TRADE_PROPOSAL';
const TRADE_SUCCEED = 'scripts/market/TRADE_SUCCEED';
const MY_ORDERS = 'scripts/market/MY_ORDERS';
const MY_TRADES = 'scripts/market/MY_TRADES';
const TRANSACTIONS = 'scripts/market/TRANSACTIONS';
const MARKET_STATUS = 'scripts/market/MARKET_STATUS';
const RETURNS_CALCULATOR_STATUS = 'scripts/market/RETURNS_CALCULATOR_STATUS';
const PROMOTIONS = 'scripts/market/PROMOTIONS';
const FEE_FREE_PROMOTION_STATUS = 'scripts/market/FEE_FREE_PROMOTION_STATUS';
const ORDER_BOOK_STATUS = 'scripts/market/ORDER_BOOK_ENABLED';
const FEES = 'scripts/market/FEES';

export const orders = createAction(ORDERS);
export const trades = createAction(TRADES);
export const lastNTrades = createAction(LAST_N_TRADES);
export const remainingBricks = createAction(REMAINING_BRICKS);
export const bricksTransactedLastMonth = createAction(
  BRICKS_TRANSACTED_LAST_MONTH
);
export const investors = createAction(INVESTORS);
export const tradeProposal = createAction(TRADE_PROPOSAL);
export const tradeSucceedAction = createAction(TRADE_SUCCEED);
export const myOrders = createAction(MY_ORDERS);
export const myTrades = createAction(MY_TRADES);
export const transactions = createAction(TRANSACTIONS);
export const marketStatus = createAction(MARKET_STATUS);
export const returnsCalculatorStatus = createAction(RETURNS_CALCULATOR_STATUS);
export const promotions = createAction(PROMOTIONS);
export const feeFreePromotionStatus = createAction(FEE_FREE_PROMOTION_STATUS);
export const orderBookStatus = createAction(ORDER_BOOK_STATUS);
export const feesAction = createAction(FEES);

export const tradeProposed = (data) =>
  tradeProposal({
    type: Trade.PROPOSED,
    data,
  });

export const tradeConfirmed = (data) =>
  tradeProposal({
    type: Trade.CONFIRMED,
    data,
  });

export const tradeDone = (data) =>
  tradeProposal({
    type: Trade.SUCCEED,
    data,
  });

const tradeSucceed = (type, data) =>
  tradeSucceedAction({
    type: type,
    data,
  });

export function fetchOrderBookStatus(propertyCode) {
  return async function (dispatch) {
    try {
      const payload = await MarketService.getOrderBookStatus(propertyCode);
      return dispatch(orderBookStatus(payload));
    } catch (e) {
      dispatch(errorAlert(e.message));
    }
  };
}

export function fetchOrders(propertyCode) {
  return async function (dispatch) {
    try {
      const payload = await MarketService.getOrders(propertyCode);
      dispatch(orders(payload));
    } catch (e) {
      dispatch(errorAlert(e.message));
    }
  };
}

export function clearOrders() {
  return async function (dispatch) {
    dispatch(orders(null));
  };
}

export function fetchTrades(propertyCode) {
  return async function (dispatch) {
    try {
      const payload = await MarketService.getTrades(propertyCode);
      dispatch(trades(payload));
    } catch (e) {
      dispatch(errorAlert(e.message));
    }
  };
}

export function fetchLastNTrades(
  propertyCode,
  limit = Configuration.MAXIMUM_NUM_TRANSACTIONS_SHOWN
) {
  return async function (dispatch) {
    try {
      const payload = await MarketService.getLastNTrades(propertyCode, limit);
      dispatch(lastNTrades(payload));
    } catch (e) {
      dispatch(errorAlert(e.message));
    }
  };
}

export function fetchRemainingBricks(propertyCode) {
  return async function (dispatch) {
    const payload = await MarketService.getPropertyRemainingBricks(
      propertyCode
    );
    return dispatch(remainingBricks(payload));
  };
}

export function fetchNumOfBricksTransactedLastMonth(propertyCode) {
  return async function (dispatch) {
    const payload = await MarketService.getNumOfBricksTransactedLastMonth(
      propertyCode
    );
    return dispatch(bricksTransactedLastMonth(payload));
  };
}

export function fetchInvestors(propertyCode) {
  return async function (dispatch) {
    try {
      const payload = await MarketService.getPropertyInvestors(propertyCode);
      return dispatch(investors(payload));
    } catch (e) {
      dispatch(errorAlert(e.message));
    }
  };
}

export function fetchReturnsCalculatorStatus() {
  return async function (dispatch) {
    try {
      const payload = await MarketService.getReturnsCalculatorStatus();
      return dispatch(returnsCalculatorStatus(payload));
    } catch (e) {
      dispatch(errorAlert(e.message));
    }
  };
}

export function fetchMarketStatus() {
  return async function (dispatch) {
    try {
      const payload = await MarketService.getMarketStatus();
      return dispatch(marketStatus(payload));
    } catch (e) {
      dispatch(errorAlert(e.message));
    }
  };
}

export function fetchPromotions() {
  return async function (dispatch, getState) {
    try {
      const { user } = getState();
      const payload = await MarketService.getPromotions(user.id);
      return dispatch(promotions(payload));
    } catch (e) {
      dispatch(errorAlert(e.message));
    }
  };
}

/*
  @TODO: This endpoint has been retired.

  If / when we next use "fee-free" promo functionality,
  we should utilise the new fetchFees() call listed down below.
  In the meantime (to save unexpected errors allover the place),
  just hardcode the enabled status to false without hitting the endpoint.
*/
export const fetchFeeFreePromotionStatus = () => (dispatch) =>
  dispatch(feeFreePromotionStatus({ enabled: false }));

export function fetchMyOrders() {
  return async function (dispatch, getState) {
    try {
      const { user } = getState();
      if (user.loggedIn) {
        const payload = await MarketService.getMyOrders(user.id);
        return dispatch(myOrders(payload));
      }
    } catch (e) {
      dispatch(errorAlert(e.message));
    }
  };
}

export function fetchMyTrades() {
  return async function (dispatch, getState) {
    try {
      const { user } = getState();
      const payload = await MarketService.getMyTrades(user.id);
      return dispatch(myTrades(payload));
    } catch (e) {
      dispatch(errorAlert(e.message));
    }
  };
}

export function fetchTransactions(year) {
  return async function (dispatch, getState) {
    try {
      const { user } = getState();
      const payload = await MarketService.transactions(user.id, year);
      return dispatch(transactions(payload));
    } catch (e) {
      dispatch(errorAlert(e.message));
    }
  };
}

export function fetchPurchaseProposal(propertyCode, quantity) {
  return async function (dispatch) {
    try {
      const purchaseProposalResponse =
        await MarketService.placePurchaseProposal(propertyCode, quantity);
      dispatch(fetchOrderBookStatus(propertyCode));
      dispatch(fetchOrders(propertyCode));
      dispatch(fetchLastNTrades(propertyCode));
      dispatch(tradeProposed(purchaseProposalResponse));
    } catch (e) {
      dispatch(errorAlert(e.message));
    }
  };
}

export function confirmPurchase(
  { propertyCode, quantity, basisPrice },
  isPreOrderProperty
) {
  return async function (dispatch, getState) {
    try {
      const tradeTransacted = await MarketService.confirmPurchase(
        propertyCode,
        { quantity, basisPrice }
      );
      const {
        user,
        account: { accountV2 },
      } = getState();
      if (
        user.nextRegistrationStep !== RegistrationStep.DONE &&
        !isPreOrderProperty
      ) {
        sendFirstBrickPurchasedSegmentEvents(tradeTransacted);
        const plan = getSmartInvestPlan(accountV2);
        if (planWasNeverSignedUpTo(plan)) {
          sendConversionEvent('First Brick Purchased');
        }
      }
      dispatch(tradeConfirmed(tradeTransacted));
    } catch (e) {
      console.error(e);
      dispatch(errorAlert(e.message));
    }
  };
}

export function purchaseDone(
  tradeType,
  { propertyCode, quantity, basisPrice, valueOfBricks, transactionFee, total },
  quantityProposed
) {
  return function (dispatch) {
    dispatch(tradeDone());
    return dispatch(
      tradeSucceed(tradeType, {
        propertyCode,
        quantityProposed,
        quantityTransacted: quantity,
        basisPrice,
        valueOfBricks,
        transactionFee,
        total,
      })
    );
  };
}

export function sellDone({
  propertyCode,
  quantity,
  basisPrice,
  valueOfBricks,
  transactionFee,
  total,
}) {
  return function (dispatch) {
    dispatch(tradeDone());
    return dispatch(
      tradeSucceed(Trade.TRADE_TYPE.SELL, {
        propertyCode,
        quantityProposed: quantity,
        quantityTransacted: quantity,
        basisPrice,
        valueOfBricks,
        transactionFee,
        total,
      })
    );
  };
}

export function confirmSell(
  propertyCode,
  { price, quantity, valueOfBricks, transactionFee, total }
) {
  return async function (dispatch) {
    try {
      await MarketService.confirmSell(propertyCode, { price, quantity });
      dispatch(
        tradeConfirmed({
          propertyCode,
          quantity,
          basisPrice: price,
          valueOfBricks,
          transactionFee,
          total,
        })
      );
    } catch (e) {
      dispatch(errorAlert(e.message));
    }
  };
}

export function cancelOrder(propertyCode, { orderId }) {
  return async function (dispatch) {
    try {
      await MarketService.cancelOrder(propertyCode, { orderId });
      dispatch(fetchMarketStatus());
      dispatch(fetchMyOrders());
      const message = `Your order (${orderId::uuidFormatter()}) has been cancelled.`;
      dispatch(successAlert(message));
    } catch (e) {
      dispatch(errorAlert(e.message));
    }
  };
}

export const fetchFees = () => (dispatch) => {
  try {
    request(feesApiUrl()).then((payload) => dispatch(feesAction(payload)));
  } catch (error) {
    dispatch(errorAlert("Can't get fees at the moment"));
  }
};

const reducer = handleActions(
  {
    [ORDER_BOOK_STATUS]: (state, action) =>
      update(state, {
        orderBookStatus: {
          $set: action.payload,
        },
      }),
    [ORDERS]: (state, action) =>
      update(state, {
        orders: {
          $set: action.payload,
        },
      }),
    [TRADES]: (state, action) =>
      update(state, {
        trades: {
          $set: action.payload,
        },
      }),
    [LAST_N_TRADES]: (state, action) =>
      update(state, {
        lastNTrades: {
          $set: action.payload,
        },
      }),
    [REMAINING_BRICKS]: (state, action) =>
      update(state, {
        remainingBricks: {
          $set: action.payload,
        },
      }),
    [BRICKS_TRANSACTED_LAST_MONTH]: (state, action) =>
      update(state, {
        bricksTransactedLastMonth: {
          $set: action.payload,
        },
      }),
    [INVESTORS]: (state, action) =>
      update(state, {
        investors: {
          $set: action.payload,
        },
      }),
    [TRADE_PROPOSAL]: (state, action) =>
      update(state, {
        tradeProposal: {
          $set: action.payload,
        },
      }),
    [TRADE_SUCCEED]: (state, action) =>
      update(state, {
        tradeSucceed: {
          $set: action.payload,
        },
      }),
    [MY_ORDERS]: (state, action) =>
      update(state, {
        myOrders: {
          $set: action.payload,
        },
      }),
    [MY_TRADES]: (state, action) =>
      update(state, {
        myTrades: {
          $set: action.payload,
        },
      }),
    [TRANSACTIONS]: (state, action) =>
      update(state, {
        transactions: {
          $set: action.payload,
        },
      }),
    [RETURNS_CALCULATOR_STATUS]: (state, action) =>
      update(state, {
        returnsCalculatorStatus: {
          $set: action.payload,
        },
      }),
    [MARKET_STATUS]: (state, action) =>
      update(state, {
        marketStatus: {
          $set: action.payload,
        },
      }),
    [PROMOTIONS]: (state, action) =>
      update(state, {
        promotions: {
          $set: action.payload,
        },
      }),
    [FEE_FREE_PROMOTION_STATUS]: (state, action) =>
      update(state, {
        feeFreePromotionStatus: {
          $set: action.payload,
        },
      }),
    [FEES]: (state, { payload }) => ({ ...state, fees: { ...payload } }),
  },
  {
    orders: null,
    trades: null,
    lastNTrades: null,
    orderBookStatus: null,
    investors: null,
    remainingBricks: null,
    bricksTransactedLastMonth: null,
    tradeProposal: null,
    tradeSucceed: null,
    myOrders: null,
    myTrades: null,
    returnsCalculatorStatus: null,
    marketStatus: null,
    promotions: [],
    feeFreePromotionStatus: null,
    transactions: [],
    fees: {},
  }
);

export default reducer;
