import { connect } from 'react-redux';
import {
  flowsFieldsSelector,
  flowsStepIdSelector,
} from 'src/components/flows/store/flowsReducer';
import {
  getNextStep as getNextStepAction,
  submitStep as submitStepAction,
  updateField as updateFieldAction,
  validateFields as validateFieldsAction,
} from 'src/components/flows/store/flowsActions';
import Generic from 'src/components/flows/steps/Generic';
import Loading from 'src/components/loading/Loading';
import withState from 'src/decorators/withState';

const Flows = connect((state) => ({
  ...flowsFieldsSelector(state),
  ...flowsStepIdSelector(state),
}), {
  getNextStep: getNextStepAction,
  updateField: updateFieldAction,
  validateFields: validateFieldsAction,
  submitStep: submitStepAction,
})(withState({
  name: 'Flows',
  componentDidMount: ({ flowType, getNextStep }) => getNextStep({ flowType }),
  Component: ({
    flowType,
    getComponentForStepId,
    updateField,
    validateFields,
    submitStep,
    fields,
    currentStepId,
    loading,
    ...props,
  }) => {
    if (!currentStepId) return loading || <Loading/>;

    const Component = getComponentForStepId(currentStepId) || Generic;

    return (
      <Component
        stepId={currentStepId}
        fields={fields}
        validateFields={validateFields}
        onChange={(fieldId, value) => updateField(fieldId, value)}
        onSubmit={(options) => submitStep({ flowType, ...options })}
        {...props}
      />
    );
  }
}));

export default Flows;
