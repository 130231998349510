import Modal from 'scripts/components/shared/Modal';
import withState from 'src/decorators/withState';

const withLegacyModals = (WrappedComponent) => withState({
  componentDidMount: () => {
    Modal.root = document.querySelector('.modals');
  },
  Component: WrappedComponent,
});

export default withLegacyModals;
