export const detailedTransactionsSelector = (state) => ({
  detailedTransactions: state.transactions.detailedTransactions
});

export const depositTransactionsSelector = (state) => ({
  depositTransactions: state.transactions.depositTransactions
});

export const depositInfoSelector = (state) => ({
  depositInfo: state.transactions.depositInfo
});
