import generateId from 'nanoid';
import update from 'immutability-helper';

import { findIndexOfItemToRemove } from 'src/utils/collections';

import {
  DISMISS_TOAST,
  SHOW_TOAST,
  PRE_DISMISS_TOAST,
} from 'src/components/toasts/toastActions';

const defaultState = {
  toastList: [],
};

export const toastsSelector = (state) => ({ toasts: state.toasts.toastList });

const reducer = (state = defaultState, { type, payload }) => {
  const { toastList } = state;
  switch (type) {
    case DISMISS_TOAST:
      return update(state, {
        toastList: {
          $splice: [[
            findIndexOfItemToRemove({ items: toastList, id: payload }), 1
          ]],
        },
      });
    case PRE_DISMISS_TOAST:
      const toastIndex = findIndexOfItemToRemove({ items: toastList, id: payload });
      return toastIndex !== -1
        ? update(state, {
          toastList: {
            [toastIndex]: {
              $merge: {
                exitAnimation: true,
              },
            },
          },
        })
        : state;
    case SHOW_TOAST: return update(state, {
      toastList: {
        $push: [{ ...payload, id: generateId(6) }],
      },
    });
    default: return state;
  }
};


export default reducer;
