import React, { Component } from 'react';
import TermsMeta from 'src/components/meta/TermsMeta';
import { pdsUrl } from 'src/utils/pageUrls';

export default class Terms extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="container">
        <TermsMeta/>
        <div className="row">
          <div className="col-lg-12 terms-page">
            <h1>Terms and Conditions</h1>

            <p>This document together with the Product Disclosure Statement (PDS), BrickX Platform Constitution and Privacy Policy and any other terms and conditions adopted by us from time to time and available on the BrickX Platform, constitute the legal relationship between you and us in respect of your use of the BrickX Platform.</p>

            <h2 id="the-purpose-of-this-document">The Purpose of This Document</h2>

            <p>
              1. This document has been created to outline the terms of use that govern your use of The BrickX Platform (ARSN 613 156 433), including its website (<b>BrickX Platform</b>), found at: www.brickx.com (<b>Terms and Conditions</b>). By using the BrickX Platform and accessing the website you agree to these Terms and Conditions.
            </p>

            <p>
              2. Please regularly check the Terms and Conditions periodically for changes. We reserve the right to modify these at any time.  Amendments will become effective immediately upon publication on the BrickX Platform.  By continuing to use the BrickX Platform you accept such amendments.
            </p>

            <p>
              3. If you do not agree to these Terms and Conditions, you must refrain from using the BrickX Platform.
            </p>

            <p>
              4. These Terms and Conditions were last updated on 16 October 2020.
            </p>

            <h2>Platform Access</h2>

            <p>
              5. We only use the personal information that you provide to us in accordance with our Privacy Policy.
              By using this the BrickX Platform, you consent to such use and you warrant that all information
              provided by you is complete and accurate and agree that we may rely on that information unless and
              until we receive written notice of any change signed by you or through a facility provided by us.
              Any such varied information will be covered by this warranty. We shall not be liable for any loss arising
              from the provision of any inaccurate information. We may ask you at any time to confirm the accuracy of
              your information or to provide documents or other evidence.
            </p>

            <p>
              6. Certain features of the BrickX Platform will only be available if you have registered your details on the BrickX Platform and paid the application fee, such persons are “Members”, please see the PDS for further information on Members.  If you are an individual, you must be 18 years or older to use our services and by becoming a Member you declare that you are at least 18 years old. We may at any time require you to provide evidence of your age.
            </p>

            <p>
              7. These Terms and Conditions must be read in conjunction with any other notices,
              disclaimers and policies displayed elsewhere on this the BrickX Platform. You are responsible for making
              all arrangements necessary for you to have access to the BrickX Platform.  You are also responsible
              for ensuring that all persons who access the BrickX Platform through your Internet BrickX Account
              are aware of these Terms and Conditions and that they comply with them.
            </p>

            <h2>Member Services</h2>

            <p>
              8. Currently BrickX services are only available to Australians and as such you cannot use our services
              if you reside outside of Australia unless you are an Australian citizen.
            </p>

            <p>
              9. We reserve the right to suspend or terminate your access to our Members Services at any time if we
              reasonably believe we are required to do so by law or in order to comply with recommendations issued
              by a relevant government authority or recognised body for the prevention of crime.
            </p>

            <p>
              10. Members will be issued a username and password for accessing information, functions and documents
              which are only available to Members (Members Services). If your username or access to Member Services
              is suspended by BrickX for any reason (for example, if an incorrect password has been entered or we
              otherwise believe your online security is at risk) you will be unable to access the Member Services
              (which includes your Digital Wallet) until it is reactivated.
            </p>

            <p>
              11. We will notify you of any suspension or restriction and of the reasons for such suspension or
              restriction in advance or, where we are unable to do so, immediately after the suspension or restriction
              has been imposed, unless notifying you would be unlawful. We will lift the suspension and/or the restriction
              as soon as practicable after the reasons for the suspension and/or restriction have ceased to exist.
            </p>

            <h2>Protecting Your Username and Password</h2>

            <p>
              12. You must take all reasonable steps to keep your password safe at all times and not disclose it to anyone,
              including a family member or friend. The BrickX Team will never ask you to provide your password to us
              (other than when logging into your BrickX Account) or to a third party. It is advisable to change your
              password regularly (at least every 3 to 6 months) in order to reduce the risk of a security breach in
              relation to your Digital Wallet.
            </p>

            <p>
              13. You should always
              <ul>
                <li>
                  Memorise a password or other code as soon as possible or disguise any password
                  that is recorded so that others will not be able to decipher it or make a reasonable attempt
                  to prevent unauthorised access to the password, including keeping the password record in a
                  securely locked container or password protected device or computer.
                </li>

                <li>
                  Destroy or delete passwords or other code notifications as soon as possible after receiving them.
                </li>

                <li>
                  Take precautions when accessing your Digital Wallet e.g. be ready to access your Digital Wallet
                  when you arrive at the BrickX Platform and never let anyone watch you enter your password or other code.
                  Check the location of mirrors, security cameras or any other means of observing a password or other code
                  entry and then shield it from anyone.
                </li>
              </ul>
            </p>

            <p>
              14. We also advise you not to choose a password that is easily guessed from information someone might know or
              gather about you or a password that has a meaning. You must never allow anyone to access your Digital Wallet
              using your username or password or watch you access it. You should not disguise passwords so as to make your
              password obvious to another person.
              For example, you should not:
              <br/>
              <ul>
                <li>Reverse the order of your password.</li>
                <li>Say a disguised number is your password.</li>
                <li>Disguise your password as a telephone number.</li>
                <li>Replace your password with letters (e.g. A=1, B=2, C=3).</li>
                <li>Write numbers that contain the same sequence of numbers as your password.</li>
                <li>Record it on a computer or electronic device where it can easily be retrieved.</li>
                <li>Keep a record of it in close proximity to where it can be obtained with the access method
                  (unless you make a reasonable attempt to protect the security of your password).</li>
                <li>Keep a written record of all passwords required to perform a transaction on one or more
                  articles or devices liable to be lost or stolen simultaneously, without making a reasonable
                  attempt to protect the security of your password.</li>
              </ul>
            </p>

            <p>
              15. If you have any indication or suspicion your login details or other security features have been lost, stolen,
              misappropriated, used without authorisation or otherwise compromised, you are advised to change your password and
              should contact our Customer Services at: info@brickx.com without delay. Even if you are confident that codes are
              secure you must tell us as soon as you become aware of the loss or theft of an access method or of any unauthorised
              access to your Digital Wallet.
            </p>

            <p>
              16. You must take all reasonable care to ensure that your e-mail account(s) are secure and only accessed by you,
              as your e-mail address may be used to reset passwords or to communicate with you about the security of your
              Digital Wallet. In case any of the e-mail addresses registered with your Digital Wallet are compromised, you should
              contact Customer Services at: info@brickx.com without delay and also contact your e-mail service provider.
            </p>

            <p>
              17 Any message you receive that asks for your password, other than when logging into your BrickX Account,
              should be reported to us. If you are in doubt whether a request is genuine, you should contact the BrickX
              Customer Services at: info@brickx.com.
            </p>

            <p>
              18. We shall not be liable for any loss arising from unauthorised access to your Digital Wallet.
            </p>

            <h2>Contact</h2>

            <p>
              19. We may contact Members by e-mail or in other ways with information or notices regarding Member
              Services including your Digital Wallet. In order to receive notices and statements electronically
              you must provide us with your email address. It is your responsibility to regularly check the proper
              functioning of your e-mail account or other methods of communication that you have registered with
              your Digital Wallet and to retrieve and read messages relating to your Digital Wallet promptly. We
              shall not be liable for any loss arising out of your failure to do so.
            </p>

            <p>
              20. You are required to check for incoming messages regularly. Emails may contain links to further
              communications on the BrickX Platform. We will contact and communicate to you in English and will
              only accept communications made to us in English.
            </p>

            <p>
              21. Any notice, request, demand or other communication may be sent to your postal or email address
              last notified to us and the notice, request, demand or other communication shall be taken to have
              been received by you:
              <ul>
                <li>When sent by email, one hour after we have sent it.</li>
                <li>When sent by mail, three (3) business days after we have sent it;</li>
                <li>When delivered, on the day of delivery. </li>
              </ul>

            </p>

            <p>
              22. A notice, request, demand or other communication that you send to us will be acknowledged as
              having been received by us when we receive it.
            </p>

            <h2>Linked Websites</h2>

            <p>
              23. We may provide links to third party websites (Linked Websites).  Those links are provided
              for convenience only and may not remain current or may not be maintained.  Unless otherwise
              notified on the BrickX Platform, Linked Websites are not reviewed, controlled or examined by
              us in any way.  We are not responsible for the content, privacy practices, availability, advertising,
              products or other materials on any such Linked Websites, or any links on the Linked Websites.
            </p>

            <p>
              24. Except as otherwise expressly stated on the BrickX Platform, we do not endorse, or associate with
              the Linked Websites.  It is your sole responsibility to comply with the appropriate rules and guidelines,
              if any, applicable to the use of the Linked Platforms.
            </p>

            <p> 25. In no event shall BrickX or our affiliates be liable, directly or indirectly, to anyone for any loss
              or damage arising from or occasioned by the creation or use of links to the Linked Websites, the Linked Websites
              themselves or the information or material accessed through the Linked Websites.  You should direct any concerns
              to the relevant Linked Website’s administrator.  BrickX reserves the right, in its sole discretion, to add, change,
              decline or remove, without notice, any feature or link to any of the Linked Websites from the BrickX Platform and/or
              introduce different features or links.
            </p>

            <h2>Disclaimer</h2>

            <p>
              26. BrickX cannot accept responsibility for matters relating to the BrickX Platform that are outside of the
              reasonable control of BrickX.
            </p>

            <p>
              27. All information provided by BrickX on the BrickX Platform is provided in good faith, without any guarantees,
              conditions or warranties as to its accuracy.
            </p>

            <p>
              28. BrickX products are issued by BrickX Financial Services Limited (ABN 67 616 118 088) (AFSL 494878) (BrickX Financial Services).  The Brick Exchange Pty Ltd (ABN 27 600 762 749) is an authorised representative (No. 001000043) of BrickX Financial Services Limited (BrickX Financial Services) (ABN 67 616 118 088) (AFSL 494878).
            </p>

            <p>
              29. The advice provided in relation to the BrickX Platform, including on the BrickX Platform website,
              is general advice only and has been prepared without taking into account your objectives, financial situation
              and needs.
            </p>

            <p>
              30. Before making any decision in relation to BrickX or any products offered by BrickX you should read the
              Product Disclosure Statement (PDS) and consider whether they are right for you. The PDS is available at <a href={pdsUrl()}>www.brickx.com/pds</a>
            </p>

            <p>
              31. Past performance is no indication of future performance.
            </p>

            <p>
              32. Any forecasts are subject to change without notice.
            </p>

            <p>
              33. Neither BrickX nor BrickX Financial Services guarantee any income or return of capital.
            </p>

            <p>
              34. BrickX reserves the right to update this information at any time.
            </p>

            <h2>Limitation of Liability</h2>

            <p>
              35. Certain non-excludable statutory warranties under consumer protection laws will be
              implied for the benefit of consumers.  Nothing in these Terms and Conditions is intended to exclude
              or restrict the application of such laws but BrickX does not give any guarantee or warranties or
              make any representation of any kind, express or implied, with respect to use of the BrickX Platform outside these laws.

            </p>

            <p>
              36. Subject to claims available under consumer protection laws:
              <ul>
                <li>
                  BrickX is not liable to you or anyone else (including for negligence, breach of contract or tort)
                  for any loss or damage (including specific, indirect, consequential, economic or any other kind of loss)
                  however caused, even if foreseeable, and which is suffered directly or indirectly in connection with the
                  use of or inability to use the BrickX Platform;
                </li>

                <li>
                  BrickX is not liable for disruptions to the BrickX Platform; and
                </li>

                <li>
                  BrickX is not liable to you or anyone else if interference with or damage to your computer systems,
                  equipment, programs, data or other proprietary material occurs in connection with the use of
                  the BrickX Platform, a Linked Website or other external platform.  You must take your own precautions
                  to ensure that whatever you select for use from the BrickX Platform is free of viruses or anything else
                  that may interfere with or damage the operation of your computer systems.
                </li>
              </ul>
            </p>

            <p>
              37. If we breach any non-excludable statutory warranties you may be entitled to compensation. When you are not
              a consumer under consumer protection laws our liability for loss or damage is limited to re-supplying
              the service to you or paying the costs of having the service re-supplied to you. When you are a consumer
              under consumer protection laws our liability is limited in this way only to the extent permitted by those laws.
            </p>

            <p>
              38. BrickX accepts no liability for any failure to comply with these Terms and Conditions where such failure is
              due to circumstances beyond the reasonable control of BrickX.
            </p>

            <h2>Viruses, Hacking and Other Offences</h2>

            <p>
              39. You must not misuse the BrickX Platform by knowingly introducing viruses, trojans, worms, logic bombs
              or other material that is malicious or technologically harmful. You must not attempt to gain unauthorised
              access to the BrickX Platform, the server on which the BrickX Platform is stored or any server,
              computer or database connected to the BrickX Platform. If you engage in such conduct, your right to use
              the BrickX Platform will cease immediately.
            </p>

            <h2>Intellectual Property Rights</h2>

            <p>
              40. The material contained on the BrickX Platform, including promotional materials and the BrickX
              Platform website, is protected by copyright.  You may use the material on the BrickX Platform only for your
              personal and non-commercial purposes.  Except to the extent permitted by relevant copyright legislation,
              you must not use, copy, modify, transmit, store, publish or distribute the material on the BrickX Platform,
              or create any other material using material on the BrickX Platform, without obtaining the prior written consent of BrickX.
            </p>

            <p>
              41. Our trademarks (whether registered or unregistered) and logos must not be used or modified in any way without
              obtaining the prior written consent of BrickX.
            </p>

            <p>
              42. The BrickX Platform and the products, technology and processes contained on the BrickX Platform may
              be the subject of other intellectual property rights owned by BrickX or by third parties.  No license
              is granted in respect of those intellectual property rights other than as set out in these Terms and Conditions.
              Your use of the BrickX Platform must not in any way infringe the intellectual property rights of any person.
            </p>

            <p>
              43. When you post or transmit material on the BrickX Platform, you assign all intellectual property rights that
              subsist in such material to BrickX, which BrickX may then assign to a third party.  To the extent necessary,
              you consent to any acts by BrickX or BrickX’s licensees or assignees that may infringe your moral rights
              in the material that you create (for example, BrickX may not always be able to identify you as the author
              of the material that you create and submit to the BrickX Platform).
            </p>

            <h2>Cookies</h2>

            <p>
              44. Platform analytics measurement software is used to assist in tracking traffic patterns to and from
              the BrickX Platform to survey users of the sites.
            </p>

            <p>
              45. Cookies are small pieces of text stored on your computer to help us determine the type of browser and
              settings you are using, where you have been on the BrickX Platform, when you return to the BrickX Platform,
              where you came from, and to ensure your information is secure. The purpose of this information is to provide
              you with a more relevant and effective experience on the BrickX Platform, including presenting web pages
              according to your needs or preferences.
            </p>

            <p>
              46. BrickX may also use external service providers to track the traffic and usage on the BrickX Platform.
              Cookies are frequently used on many platforms on the internet and you can choose if and how a cookie will
              be accepted by changing your preferences and options in your browser. You may not be able to access some
              parts of the BrickX Platform if you choose to disable the cookie acceptance in your browser, particularly
              the secure parts of the BrickX Platform. We therefore recommend you enable cookie acceptance to benefit
              from all the services on the BrickX Platform.
            </p>

            <h2>Digital Wallet Terms</h2>

            <p>
              47. A Member’s Digital Wallet (referred to only as ‘Wallet’ on the BrickX Platform) is an arrangement
              between the Member and BrickX under which BrickX holds Member funds, and any money that a Member receives
              as a distribution or from the sale of Bricks, on trust for a Member.  A Member can use the money in
              their Digital Wallet to allocate to additional Brick purchases and/or withdraw the Funds Available into a Member’s
              nominated personal bank account.
            </p>

            <p>
              48. Digital Wallet means the account (or, if determined in BrickX’s discretion, multiple accounts) opened and
              maintained by BrickX on behalf of each Member through which transfers of funds are made for Brick purchases and funds
              are received in connection with Bricks, including distributions and the proceeds of a disposal of Bricks. The Digital
              Wallet consists of Funds Available and Funds Awaiting Investment.
            </p>

            <p>
              49. Fund transfers into the BrickX Platform are executed through external payment gateways, that support every
              major Australian bank. BrickX may, at its discretion, advance some or all of the funds deposited to the Member’s
              Digital Wallet ahead of the funds transfer being complete, for the purpose of allowing a Member to buy Bricks
              (Advanced Funds). During this period of advancement, the Member will only have the functionality to buy Bricks.
              Once the funds transfer is complete, the ability to sell Bricks and withdraw Funds Available will be enabled.
            </p>

            <p>
              50. A Member’s Digital Wallet will be debited of all funds required to settle an acquisition of Bricks by the Member.
              This includes fees and such other amounts as BrickX determines to be appropriate to debit the Member’s Digital Wallet
              in relation to dealing with Bricks and any other amounts payable by a Member to the Responsible Entity in accordance
              with the BrickX Platform Constitution.
            </p>

            <p>
              51. BrickX may determine that a Member must hold a minimum amount in their Digital Wallet from time to time.
            </p>

            <h2>Your Digital Wallet</h2>

            <p>
              52. The funds in your Digital Wallet belong to you. No person other than the Digital Wallet holder has any rights
              in relation to the funds held in your Digital Wallet.  However, we may withdraw amounts from the Digital Wallet
              where authorised by you.
            </p>

            <h2>The Risks Involved</h2>

            <p>
              53. You should be aware of risks such as the possibility of unauthorised transactions on your Digital Wallet.
              Please refer to ‘protecting your username and password’ in these Terms and Conditions for more information.
            </p>

            <p>
              54. BrickX is dependent on the reliability of its operational processes and those of its technology and telecommunications
              partners in respect of computers, computer networks and telecommunications. Disruptions to these processes may result in
              the Digital Wallet and Member Services being unavailable from time to time. There is a risk that you may not be able to
              access your Digital Wallet or make transactions through a service which is reliant on computer and/or telecommunications systems.
            </p>
            55. While BrickX allows you to transact simply using your electronic equipment and login details, because of that simplicity,
            it also poses special risks, including:
            <ul>
              <li>If someone else has your passwords or codes, they can transact on your account as if they were you.
                You may be liable for such transactions (refer to ‘liability’ for details).
              </li>

              <li>You should ignore any emails that appear to come from us that require you to input your passwords
                or codes. We will never send such email requests. These emails may come from third parties seeking to
                use the information to access your accounts.
              </li>

              <li>You should also be careful about using services, which offer to interface with BrickX or other means
                of electronic banking on your behalf. For example, you may be led to believe that you are inputting
                your passwords or codes into the BrickX Platform, while in fact the disclosure is being made to a third
                party hosting a hoax platform.
              </li>

              <li>Your access to the BrickX Platform may be suspended if we detect or suspect unusual activity which
                may be the result of malware or a computer virus, ID takeover or phishing. BrickX reserves the right to
                withhold access to the Digital Wallet and Member Services where we continue to detect malware or a computer
                virus, or you refuse to follow our recommendations provided to you to assist with the removal of malware
                and/or computer viruses from your computer.
              </li>
            </ul>

            <h2>Maintaining Your Digital Wallet</h2>

            <p>
              56. Transferred funds, monthly distributions, Brick payments and fund withdrawals are all displayed in your online
              transactions history together with the fees charged. Each transaction is given a unique transaction ID and shown in the
              transaction history. You should quote this transaction ID when communicating with us about a particular transaction.
              You should check your Digital Wallet balance and transaction history regularly.
            </p>

            <p>
              57. You should report any irregularities or clarify any questions you have as soon as possible by contacting BrickX Customer
              Services at: <a href="info@brickx.com">info@brickx.com</a>.
            </p>

            <h2>Receiving Funds</h2>
            <p>
              58. You direct that your BrickX Trust income (if any, and reduced by the payment of any expenses properly incurred by the trustee
               in the authorized administration of the BrickX Trust) distributed in accordance with clause 14.11 of the deed establishing the
               relevant BrickX Trust is to be paid by the trustee into your Digital Wallet.
            </p>

            <p>
              59. When you receive funds into your Digital Wallet, you may receive an electronic notification either in your BrickX
              Account or to your nominated e-mail address depending on the type of income it is. You should regularly reconcile incoming
              payments with your own records.
            </p>

            <h2>Prohibitions</h2>

            <p>
              60. You must not use your Digital Wallet for any illegal purpose including, but not limited to, fraud, money laundering and
              counter-terrorism financing. We will report any suspicious activity to the relevant law enforcement agency.
            </p>

            <p>
              61. You must not manipulate the Order Book, or attempt to manipulate the Order Book.
            </p>

            <p>
              62. If you directly or indirectly conduct or attempt to conduct any transaction in violation of the
              prohibitions contained in this section, or we are prohibited by law from providing services to you,
              without the necessary approval, we reserve the right to take any of the following steps:
              <ul>
                <li>Reverse the transaction.</li>
                <li>Close or suspend your Digital Wallet.</li>
                <li>Report the transaction to the relevant law enforcement agency.</li>
                <li>Claim damages from you </li>
              </ul>
            </p>

            <h2>Withdrawing Funds Available</h2>

            <p>
              63. To withdraw Funds Available, a Member must login to their BrickX Account and place a withdrawal request.
              The withdrawn funds will be paid into the bank account nominated by the Member.
            </p>

            <p>
              64. You will not be able to withdraw funds while your Digital Wallet contains Advanced Funds.
            </p>

            <h2>Closing Your Digital Wallet</h2>

            <p>
              65. You may close your Digital Wallet by selling all your Bricks and withdrawing all Funds Available in your
              Digital Wallet and deactivating your BrickX Account. Note that Funds Awaiting Investment in the Smart Invest approach
              can only be withdrawn once Smart Invest is disabled.
            </p>

            <h2>Fees and Other Costs</h2>

            <p>
              66. Please review the BrickX Product Disclosure Statement to understand the fees and other costs you may be charged.
            </p>

            <p>
              67. You should read all the information about fees and costs because it is important to understand their impact
              on your investment.
            </p>

            <h2>Unauthorised Payments</h2>

            <p>
              68. In case of an unauthorised payment or a payment that was incorrectly executed due to an error by us, we shall
              at your request refund the payment amount including all fees deducted. This shall not apply where the unauthorised
              payment arises from your failure to keep the personalised security features of your Digital Wallet safe, in which
              case you shall remain liable for the transaction and you release and discharge us from liability in this regard.
            </p>

            <p>
              69. We take all reasonable steps to ensure that our electronic equipment functions properly. However, failures may
              occur and this has an impact on liability. We shall not be liable for any disruption or impairment of our service
              or for disruptions or impairments of intermediary services on which we rely. We shall not be liable for any indirect
              or consequential losses, including but not limited to loss of profit, loss of business and loss of reputation. If the
              BrickX Platform does not accept your instructions we are not liable to you. In the event of a malfunction of the BrickX
              Platform or equipment, our liability is limited to correcting any errors and refunding any fees or charges imposed on you.
            </p>

            <p>
              70. We are not liable if you can’t make a transaction because the BrickX Platform did any of the following:
              <ul>
                <li>Did not recognise your username or password for any reason other than faulty electronic equipment.</li>
                <li>Did not work because of your deliberate or negligent act.</li>
                <li>Was not used according to these Terms and Conditions or any other terms in place between you and us.</li>
              </ul>
            </p>

            <p>
              71. We shall not be liable for any losses arising from our compliance with legal and regulatory requirements.
              We shall not be liable for the assessment or payment of any taxes, duties or other charges that arise from
              the underlying commercial transaction between you and another BrickX customer.
            </p>

            <h2>Suspension</h2>

            <p>
              72. We may suspend your Digital Wallet at any time if the following has occurred:
              <ul>
                <li>We reasonably believe that your Digital Wallet has been compromised or for other security reasons.</li>
                <li>We reasonably suspect your Digital Wallet to have been used or is being used without your authorisation or fraudulently.</li>
                <li>We reasonably believe you have breached any condition of these Terms and Conditions or any other terms in place between you and us.</li>
                <li>We have reason to believe you are in violation of any law or regulation (whether local or foreign) that is applicable to your use of our Member Services.</li>
                <li>We have reason to believe that you are in any way involved in any fraudulent activity, money laundering, terrorism financing or other criminal activity.</li>
                <li>We shall notify you either prior to the suspension or, if prior notification is not possible under the
                  circumstances, promptly after the suspension unless we are prohibited by law to notify you.</li>
                <li>If we exercise our rights to suspend your Digital Wallet, you must pay any losses, costs or expenses that we incur in relation to any such action.</li>
              </ul>
            </p>

            <h2>Consent to disclosure to Credit Reporting Agency, Document Issuer or Official Record Holder</h2>

            <p>
              73. As a part of verification processes for the purpose of complying with the Anti-money Laundering and Counter-terrorism
              Financing Act 2006 (Cth) (AML/CTF Act) and associated rules and regulations, we may need to disclose your full name,
              residential address and date of birth to a service provider acting as our agent, who may provide such information to a
              credit reporting agency for the purpose of providing an assessment of whether this identification information matches
              (in whole or in part) personal information held by the credit reporting agency.
              <br/>
              Further document verification services may be conducted involving personal details being checked with the document issuer or official record holder.
            </p>

            <p>
              74. The credit reporting agency may compare your details with personal information held by the agency (being the names,
              residential address and dates of birth of other individuals) for the purposes of making this assessment.
            </p>

            <p>
              75. The assessment will be provided to BrickX, or its agent, for the sole purpose of verifying your identity. If BrickX,
              or its agent, is unable to verify your identity using information held by a credit reporting agency, BrickX will provide
              you with a notice to this effect and the name of the credit reporting agency and give you the opportunity to contact the
              CRB to update your information.
            </p>

            <p>
              76. Records of the verification request will be made by BrickX and the credit reporting agency and retained for 7 years
              from the date of the request and for 7 years after BrickX ceases to provide services to you. You can request access to your records.
            </p>

            <p>
              77. By clicking the checkbox confirming you have read and accept these Terms and Conditions, you provide your express consent to BrickX disclosing your personal information to a credit reporting agency or document issuer or official record holder for the purposes of the AML/CTF Act.
            </p>

            <p>
              78. You have the right to let us know by email at info@brickx.com if you do not want us to disclose your personal information to a credit
              reporting agency for this purpose and opt for an alternative means of verifying your identity for the purposes of the AML/CTF Act.
            </p>

            <h2>Applicable Laws</h2>

            <p>
              79. These Terms and Conditions are governed by the laws of News South Wales, Australia. Each party submits to the non-exclusive jurisdiction
              of the courts exercising jurisdiction in New South Wales, and any court that may hear appeals from any of those courts, for any proceedings
              in connection with these Terms and Conditions.
            </p>

            <p>
              80. All dealings between you and us are subject to the Corporations Act 2001 (Cth) and the AML/CTF Act.
              Please refer to the PDS for more information.
            </p>

            <p>
              81. In the event that you appear to be a Proscribed Person, we will not be responsible for any loss, liability
              or costs incurred by you where we are unable to receive or act on your instructions.
            </p>

            <h2>Your Commitments</h2>

            <p>
              82. You warrant, if you are a company incorporated or registered in Australia, that we are able to rely on the Australian
              Securities and Investments Commission (ASIC) company search of you as being up to date and correct at all times.
            </p>

            <p>
              83. You agree to notify us either via email or through a facility provided by us of changes in information that are relevant
              to any dealing or proposed dealing. Information which is relevant to any dealing includes your:
              <ul>
                <li>name;</li>
                <li>postal address;</li>
                <li>contact phone number, facsimile number and email address;</li>
                <li>bank account details; and</li>
                <li>authorised representatives and particulars of that authorised representative as set out in the items above.</li>
              </ul>
            </p>

            <p>
              84. You acknowledge and agree:
              <ul>
                <li>That if you or another person who has gained access to your Member rights appear to be a Proscribed Person, then we may
                  immediately refuse to process or complete any transaction or dealing of yours; suspend the provision of a product or service to you;
                  refuse to allow or to facilitate any of your assets held by us to be used or dealt with; refuse to make any asset available to you or
                  to any other Proscribed Person or entity; or terminate these arrangements with you. We will be under no liability to you if we do any
                  or all of these things. Our rights under this section are in addition to all other rights we may have under relevant laws; and</li>

                <li>that if we exercise the rights set out above, you must pay us any damages, losses, costs or expenses that we incur in relation
                  to any action taken, including without limitation administrative costs and/or costs of sale or purchase of any transaction or deal put
                  in place for the purposes of meeting our obligations under these Terms and Conditions.</li>

                <li>You acknowledge that as soon as you become aware of an error or omission relating to your participation on the BrickX Platform
                  (including in relation to Member Services and your Digital Wallet), you must notify BrickX of such error or omission and you are
                  responsible for taking necessary action to mitigate further loss. See ‘Contact Information’ for BrickX’s contact details.
                  You acknowledge that time is of the essence in dealings with us. </li>

                <li>When referring friends to the BrickX Platform through the <a href="/terms/referrals">BrickX Referrals</a> program, you acknowledge and
                  agree that you are not acting on behalf of BrickX and that you undertake such referral activity at your own risk.</li>
              </ul>
            </p>

            <h2>Severance</h2>

            <p>
              If any part of any of these Terms and Conditions is found to be void or unenforceable for unfairness or any other reason
              (for example, if a court or other tribunal or authority declares it so), the remaining parts of these terms and conditions
              will continue to apply as if the void or unenforceable part had never existed.
            </p>

            <h2>Contact Information</h2>

            <p>
              85. These Terms and Conditions are publicly available on the BrickX Platform website at <a href="www.brickx.com/terms">www.brickx.com</a>.
            </p>

            <p>
              86. If you have any concerns about material that appears on the BrickX Platform website, please contact us in accordance with the details set out below.
              <br/> The Brick Exchange Pty Limited
              <br/> Address: Level 27, 101 Collins Street, Melbourne VIC 3000 Australia
              <br/> Email: info@brickx.com
            </p>

            <h2>Definitions</h2>

            <p>
              87. Capitalised terms not otherwise defined in these Terms and Conditions have the meaning given in the Product Disclosure
              Statement relating to the BrickX Platform.
            </p>

            <p>
              88. “Proscribed Person” means a person who appears to us either:
              <ul>
                <li>Appears to us to be in breach of the laws of any jurisdiction relating to anti-money
                  laundering or counter-terrorism financing;</li>
                <li>Appears in a list of persons with whom dealings are proscribed by the government or
                  regulatory authority of any jurisdiction; or </li>
                <li>Acts on behalf, or for the benefit of, a person listed in bullet point 1 or 2 of this definition.</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    );
  }
}
