

const InfoBox = ({ children, className }) => {
  return (
    <div id="info-box" className={className}>
      <img src="/static/images/icons/icn_info.png" className="info-icon"/>
      <div className="description-info">
        { children }
      </div>
    </div>
  );
};


export default InfoBox;
