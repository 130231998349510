import _ from 'lodash';
import authStore from 'scripts/auth/authStore';
import { status, json } from 'scripts/services/helpers';
import { error as rollbarError } from 'scripts/analytics/rollbar';

export default function (url, { credentials, ...options } = {}) {
  const finalOptions = {
    ...options,
  };
  if (credentials === 'include') {
    finalOptions.headers = {
      ...finalOptions.headers,
      Authorization: authStore.getAuthToken()
    };
  }

  return fetch(url, finalOptions)
  .then(status)
  .then(json)
  .catch((error) => {
    rollbarError(error, { url, credentials, options: _.omit(options, 'body') });
    throw error;
  });
}
