import styled from 'styled-components';
import { breakpointSmall } from 'src/design/styleguide/common/breakpoints';
import { gridUnit } from 'src/design/styleguide/common/measurements';
import ClonedChild from 'src/design/styleguide/common/ClonedChild';
import ContentBlock from 'src/design/styleguide/contentBlock/ContentBlock';
import ContentContainer from
  'src/design/styleguide/contentContainer/ContentContainer';
import Paragraph from 'src/design/styleguide/Paragraph';
import SectionHeading from
  'src/components/landingPages/howItWorks/common/sectionHeading/SectionHeading';
import Spacing from 'src/design/styleguide/spacing/Spacing';

const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${breakpointSmall(`
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  `)}
`;

const StyledCard = styled(ClonedChild)`
  ${breakpointSmall(`
    max-width: 350px;
    margin-right: ${gridUnit * 6}px;
    margin-left: ${gridUnit * 6}px;
  `)}
`;

const TeamSection = ({
  backgroundColor,
  topPadding,
  title,
  description,
  cards,
}) => (
  <ContentBlock
    topPadding={topPadding}
    bottomPadding="x-small"
    backgroundColor={backgroundColor}
  >
    <SectionHeading>{title}</SectionHeading>

    <ContentContainer size="small">
      <Spacing top="normal">
          <Paragraph align="center">
            {description}
          </Paragraph>
      </Spacing>
    </ContentContainer>

    <CardsContainer>
      {cards.map((card) => (
        <Spacing top="2x-large">
          <StyledCard>
            {card}
          </StyledCard>
        </Spacing>
      ))}
    </CardsContainer>
  </ContentBlock>
);

export default TeamSection;
