import request from 'scripts/services/request';
import {
  smartInvestDetailsUrl,
  generateDirectDebitRequestPreviewUrl,
  generateDirectDebitRequestServiceAgreementPreviewUrl,
} from 'scripts/services/api';
import { withCredentials, headers } from 'scripts/services/helpers';


const convertSmartInvestDetailsToApiPayload = ({ amount, dayOfMonth, bankAccountName, bsb, bankAccountNumber }) => ({
  directDebitSchedule: {
    amount,
    dayOfMonth
  },
  bankDetails: {
    bankAccountName,
    bsb,
    bankAccountNumber
  },
});

class SmartInvestService {
  getSmartInvestDetails(userId) {
    return request(smartInvestDetailsUrl(userId), {
      ...withCredentials
    });
  }

  updateSmartInvestDetails(userId, smartInvestDetails) {
    return request(smartInvestDetailsUrl(userId), {
      ...withCredentials,
      headers,
      method: 'PUT',
      body: JSON.stringify(smartInvestDetails)
    });
  }

  addSmartInvestDetails(userId, smartInvestDetails) {
    const payload = convertSmartInvestDetailsToApiPayload(smartInvestDetails);

    return request(smartInvestDetailsUrl(userId), {
      ...withCredentials,
      headers,
      method: 'POST',
      body: JSON.stringify(payload)
    });
  }

  getDirectDebitRequestPreviewUrl(userId, smartInvestDetails) {
    const payload = convertSmartInvestDetailsToApiPayload({
      amount: smartInvestDetails.smartInvestAmount,
      dayOfMonth: smartInvestDetails.smartInvestDayOfMonth,
      bsb: smartInvestDetails.bankAccountBsb,
      bankAccountNumber: smartInvestDetails.bankAccountNumber,
      bankAccountName: smartInvestDetails.bankAccountName,
    });

    return request(generateDirectDebitRequestPreviewUrl(userId), {
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      method: 'POST',
      body: JSON.stringify(payload),
    });
  }

  getDirectDebitRequestServiceAgreementPreviewUrl(userId) {
    return request(generateDirectDebitRequestServiceAgreementPreviewUrl(userId), { credentials: 'include' });
  }
}

export default new SmartInvestService();
