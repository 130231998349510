import { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { breakpointSmall } from 'src/design/styleguide/common/breakpoints';
import { getBuildYourOwnPlan, getSmartInvestPlan, hasMoreThanOnePlan } from 'src/utils/account';
import FormCheckbox from 'src/design/components/formCheckbox/FormCheckbox';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import { getCustomThemeColor } from '../home/DashboardHome';

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${breakpointSmall(`
    justify-content: flex-start;
  `)}
`;

const PlanCheckboxes = ({ className, onChange, user, bmoChecked, siChecked }) => {
  const bmoDisabled = bmoChecked && !siChecked;
  const siDisabled = siChecked && !bmoChecked;

  return (
    <CheckboxContainer className={className}>
      <FormCheckbox
        checked={bmoChecked}
        onChange={(value) => onChange({ bmoChecked: value, siChecked })}
        verticallyCenteredLabel
        labelProps={{ fontSize: '4x-small' }}
        disabled={bmoDisabled}
        customColor={getCustomThemeColor(user)}
        disabledStyleType="colored-checkbox"
        testRef="bmo-checkbox"
      >
        Build My Own
      </FormCheckbox>
      <Spacing left="normal">
        <FormCheckbox
          checked={siChecked}
          onChange={(value) => onChange({ siChecked: value, bmoChecked })}
          verticallyCenteredLabel
          labelProps={{ fontSize: '4x-small' }}
          disabled={siDisabled}
          customColor={getCustomThemeColor(user)}
          disabledStyleType="colored-checkbox"
          testRef="si-checkbox"
        >
          Smart Invest
        </FormCheckbox>
      </Spacing>
    </CheckboxContainer>
  );
};

const withSelectablePortfolio = (WrappedComponent) => {
  class WithSelectablePortfolio extends Component {
    static propTypes = {
      account: PropTypes.object,
      user: PropTypes.object
    };

    state = { useBmoData: true, useSiData: true };

    render() {
      const { useBmoData, useSiData } = this.state;
      const { account, user, ...otherProps } = this.props;
      const portfolio = (useBmoData && useSiData)
        ? account.aggregatedPortfolio
        : useSiData
        ? getSmartInvestPlan(account).portfolio
        : useBmoData
        ? getBuildYourOwnPlan(account).portfolio
        : {};

      const portfolioSwitchCheckboxes = hasMoreThanOnePlan(account)
        ? (
          <PlanCheckboxes
            user={user}
            onChange={({ bmoChecked, siChecked }) => this.setState({ useBmoData: bmoChecked, useSiData: siChecked })}
            bmoChecked={useBmoData}
            siChecked={useSiData}
          />
        )
        : null;

      return (
        <WrappedComponent
          account={account}
          portfolio={portfolio}
          portfolioSwitchCheckboxes={portfolioSwitchCheckboxes}
          {...otherProps}
        />
      );
    }
  }

  return WithSelectablePortfolio;
};

export default withSelectablePortfolio;
