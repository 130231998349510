import SegmentEventTypes from 'scripts/redux/actions/segment/SegmentEventTypes';
import { track } from 'src/tracking/analytics';

export function sendReferralLinkCopied() {
  return track(SegmentEventTypes.REFERRAL_PARTNERSHIP_PROGRAMME.LINK_COPIED);
}

export function sendFacebookButtonClicked() {
  return track(SegmentEventTypes.CLICK, { 'From': 'Referral page', 'Title': 'Referral', 'Located': 'Hero image', 'Shared via': 'Facebook' });
}

export function sendTwitterButtonClicked() {
  return track(SegmentEventTypes.CLICK, { 'From': 'Referral page', 'Title': 'Referral', 'Located': 'Hero image', 'Shared via': 'Twitter' });
}

export function sendLinkedInButtonClicked() {
  return track(SegmentEventTypes.CLICK, { 'From': 'Referral page', 'Title': 'Referral', 'Located': 'Hero image', 'Shared via': 'LinkedIn' });
}

export function sendEmailButtonClicked() {
  return track(SegmentEventTypes.CLICK, { 'From': 'Referral page', 'Title': 'Referral', 'Located': 'Hero image', 'Shared via': 'Email' });
}

export function sendHomePageReferralLoaded() {
  return track(SegmentEventTypes.LOAD_PAGE, { 'title': 'Referral Pop-Up' });
}

export function viewedPartnerReferralConfirmation(data) {
  track(SegmentEventTypes.PARTNER_REFERRAL.VIEW, data);
}

export function clickedPartnerReferralConfirmation(data) {
  track(SegmentEventTypes.PARTNER_REFERRAL.CLICK, data);
}
