import PropTypes from 'prop-types';
import React from 'react';
import { percent, dollar, date, monthYear } from 'scripts/utilities/formatters';
import { propertyCapitalReturnsBoxHistoricalSuburbGrowthDescription, historicalGrowthDataProvider, historicalGrowthTitle } from 'src/settings/text';
import { property as propertyPropType } from 'scripts/constants/PropTypes';
import FinancialsBox from 'scripts/components/property/FinancialsBox';
import FinancialsPanel from 'scripts/components/shared/FinancialsPanel';


const CapitalReturn = ({ isPreorderOrComingSoonProperty, property, averageAnnualGrowthAmount, averageAnnualGrowthNumberOfYears }) => {
  const title = 'Capital Returns';

  const isPreOrderOrNotIndependentValuedProperty = isPreorderOrComingSoonProperty || !property.financials.isIndependentValued;
  const latestValueBoxTitle = isPreOrderOrNotIndependentValuedProperty ? 'Total Purchase Cost' : 'LATEST VALUE';
  const latestValueBoxDescription = isPreOrderOrNotIndependentValuedProperty ? (property.platformSettlementDate)::date() : (property.financials.latestValuationDate)::monthYear();
  const debtBoxDescription = isPreOrderOrNotIndependentValuedProperty ? (property.platformSettlementDate)::date() : (property.financials.latestValuationDate)::monthYear();

  return (
      <FinancialsPanel className="panel-financials-transparent-header"
        title={title}
        titleIcon="icn-line-chart-blue"
        linkText="See Capital Returns tab"
        link={`/properties/${property.propertyCode}/returns`}
      >
        <div className="row">
          <FinancialsBox className="col-md-4 five-year-historical-suburb-growth financials-box-small"
            title={historicalGrowthTitle(property.propertyType)}
            value={averageAnnualGrowthAmount}
            description={propertyCapitalReturnsBoxHistoricalSuburbGrowthDescription(averageAnnualGrowthNumberOfYears, historicalGrowthDataProvider(property.propertyType))}
            valueTestRef="capital-returns-value"
            descriptionTestRef="capital-returns-description"
          />
          <FinancialsBox className="col-md-4 change-in-brick-value financials-box-small"
            title={latestValueBoxTitle}
            value={(property.financials.latestValue)::dollar()}
            description={latestValueBoxDescription}
          />
          <FinancialsBox className="col-md-4 loan-value-ratio financials-box-small"
            title={"DEBT"}
            value={(property.financials.lVR)::percent()}
            description={debtBoxDescription}
          />
        </div>
      </FinancialsPanel>
  );
};


CapitalReturn.propTypes = {
  isPreorderOrComingSoonProperty: PropTypes.bool.isRequired,
  property: propertyPropType.isRequired,
  averageAnnualGrowthFor20yr: PropTypes.string.isRequired
};


export default CapitalReturn;
