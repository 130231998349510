import { connect } from 'react-redux';
import classNames from 'classnames';
import { HeadingSmall } from 'src/design/components/heading/Headings';
import { saveTfnDetails as saveTfnDetailsAction } from 'scripts/redux/actions/user';
import { userSelector } from 'scripts/redux/selectors/user';
import { validateTfn } from 'src/utils/validation';
import ContentContainer from 'src/design/styleguide/contentContainer/ContentContainer';
import InfoBox from 'src/design/components/infoBox/InfoBox';
import Paragraph from 'src/design/components/bodyText/Paragraph';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import TextInputWithError from 'src/design/components/textInput/TextInputWithError';
import settingsStyles from 'src/components/settings/Settings.mscss';
import styles from 'src/components/settings/tfn/Tfn.mscss';
import withState from 'src/decorators/withState';

const userHasTfn = (user) => user.financialDetails && user.financialDetails.hasTfn;

const Tfn = connect(userSelector, {
  saveTfnDetails: saveTfnDetailsAction,
})(withState({
  name: 'Tfn',
  mapPropsToInitialState: () => ({ tfn: '', showErrors: false }),
  Component: ({
    title = `Please provide your Tax File Number (TFN)`,
    innerContainerClassName,
    state: { tfn, showErrors },
    setState,
    user,
    saveTfnDetails,
    backgroundColor = 'grey',
  } = {}) => {
    if (userHasTfn(user)) return null;
    const tfnCheck = validateTfn(tfn);
    return (
      <ContentContainer
        backgroundColor={backgroundColor}
        className={classNames(settingsStyles.settings, styles.contentContainer, innerContainerClassName)}
      >
        <HeadingSmall><h2>{title}</h2></HeadingSmall>
        <Spacing top="normal">
          <Paragraph>Please enter your TFN to receive your full distributions.</Paragraph>
        </Spacing>
        <form className={styles.inputAndButtonContainer}>
          <TextInputWithError
            className={styles.input}
            onChange={value => setState({ tfn: value })}
            value={tfn}
            placeholder="Enter your TFN"
            isCharacterValid={TextInputWithError.validation.numbersOnly}
            isStringValid={TextInputWithError.validation.maxLength(9)}
            error={showErrors && tfnCheck}
            testRef="tfn-input"
            errorTestRef="tfn-input-error"
          />
          <PrimaryButton
            color={PrimaryButton.colors.PRIMARY}
            onClick={(ev) => {
              ev.preventDefault();
              return !tfnCheck
                ? saveTfnDetails({ tfn })
                : setState({ showErrors: true });
            }}
            testRef="save-button"
          >
            Save
          </PrimaryButton>
        </form>
        <Spacing top="small">
          <InfoBox>If you choose not to provide your TFN, BrickX is required to withhold tax at the highest marginal rate which is currently 47%.</InfoBox>
        </Spacing>
      </ContentContainer>
    );
  }
}));

export default Tfn;
