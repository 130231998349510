import { createAction, handleActions } from 'redux-actions';
import update from 'immutability-helper';
import UserService from 'scripts/services/UserService';
import { errorAlert } from 'scripts/redux/actions/alerts';

export const BPAY = 'scripts/user/BPAY';
export const bpay = createAction(BPAY);


export function getBpayDetails() {
  return async function (dispatch, getState) {
    const { user } = getState();
    try {
      const bpayInfo = await UserService.getBpayDetails(user.id);
      dispatch(bpay(bpayInfo));
    } catch (e) {
      dispatch(errorAlert(e.message));
    }
  };
}

export function sendBpayDepositEmail() {
  return async function (dispatch, getState) {
    const { user } = getState();
    try {
      await UserService.sendBpayDepositEmail(user.id);
    } catch (e) {
      dispatch(errorAlert(e.message));
    }
  };
}

const reducer = handleActions({
  [BPAY]: (state, action) => update(state, {
    $set: action.payload
  })
}, { 'billerCode': null, 'refNumber': null });

export default reducer;
