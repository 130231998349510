import PageContentBlock from 'src/design/components/pageContentBlock/PageContentBlock';
import PageHeading from 'src/design/components/heading/PageHeading';
import BodyText from 'src/design/components/bodyText/BodyText';
import Spacing from 'src/design/styleguide/spacing/Spacing';

import styles from 'src/components/terms/Terms.mscss';

const TermsPage = ({ title, children }) => (
  <PageContentBlock className={styles.termsPage}>
    <Spacing bottom="2x-large">
      <PageHeading>{title}</PageHeading>
    </Spacing>
    <BodyText textSize="x-small">
      {children}
    </BodyText>
  </PageContentBlock>
);

export default TermsPage;
