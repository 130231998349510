import sanitizeHtml from 'sanitize-html';
import InfoBox from 'src/design/components/infoBox/InfoBox';

const CriticalNews = ({ criticalNews: { message, active } }) => {
  const cleanedMessage = sanitizeHtml(message);

  return (
    <div className="critical-news">
      { active &&
        <InfoBox critical><span dangerouslySetInnerHTML={{ __html: cleanedMessage }}></span></InfoBox>
      }
    </div>
  );
};

export default CriticalNews;
