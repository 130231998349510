
import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { property as propertyPropType } from 'scripts/constants/PropTypes';

import NoBricksCurrentlyAvailableBox from 'scripts/components/property/trade/NoBricksCurrentlyAvailableBox';
import AboveBelowValuationComponent from 'scripts/components/property/trade/AboveBelowValuationComponent';

import { priceWithNoDecimalsRounded } from 'scripts/utilities/formatters';
import { isAPreOrderOrComingSoonProperty } from 'scripts/utilities/propertyHelper';


export default class LowestAvailableBricksPriceBox extends Component {
  static propTypes = {
    property: propertyPropType.isRequired,
    showAboveBelowValuation: PropTypes.bool.isRequired,
    className: PropTypes.string,
  };

  render() {
    const { property, showAboveBelowValuation, className } = this.props;
    const isPreorderOrComingSoonProperty = isAPreOrderOrComingSoonProperty(property);

    if (isPreorderOrComingSoonProperty) {
      return null;
    }
    const lowestPrice = property.financials.lowestAvailableBrickPrice;

    return (
      <div>
        {lowestPrice > 0
          ?
          <div className={classNames('lowest-available-price-box', className)}>
            <img src="/static/img/bx-logo-x-only.svg"/>
            Lowest Available Brick Price
            <div className={classNames(showAboveBelowValuation ? 'value' : 'value larger-text')}>
              {lowestPrice::priceWithNoDecimalsRounded()}
              {showAboveBelowValuation &&
                <AboveBelowValuationComponent property={property} showBrickValuationText/>
              }
            </div>
          </div>
          :
          <NoBricksCurrentlyAvailableBox/>
        }
      </div>
    );
  }
}
