import React, { Component } from 'react';

import { property as propertyPropType, account as accountPropType } from 'scripts/constants/PropTypes';

import FinancialsPanel from 'scripts/components/shared/FinancialsPanel';
import TradeInfoBoxPreOrder from 'scripts/components/property/trade/TradeInfoBoxPreOrder';


export default class OrderBookPreOrder extends Component {
  static propTypes = {
    property: propertyPropType.isRequired,
    account: accountPropType,
  };

  render() {
    const { property, account } = this.props;
    return (
      <FinancialsPanel className="panel-financials-transparent-header panel-financials-invest panel-order-book-pre-order">
        <TradeInfoBoxPreOrder property={property} account={account}/>
      </FinancialsPanel>
    );
  }
}
