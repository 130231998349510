const AccountType = {
  INTERNAL: 'INTERNAL',
  INDIVIDUAL: 'INDIVIDUAL',
  JOINT: 'JOINT',
  DECEASED: 'DECEASED',
  CORPORATION: 'CORPORATION',
  PARTNERSHIP: 'PARTNERSHIP',
  TRUST: 'TRUST',
  SMSF: 'SMSF',
  OTHERNONINDIVIDUAL: 'OTHERNONINDIVIDUAL',
  GOVERNMENT: 'GOVERNMENT',
  MINOR: 'MINOR'
};


export default AccountType;
