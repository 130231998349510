import { connect } from 'react-redux';
import DocumentMeta from 'react-document-meta';
import { SETTINGS_META } from 'scripts/metadata/account';
import { disableSmartInvest as disableSmartInvestAction } from 'scripts/redux/actions/smartInvest';

import PageHeading from 'src/design/components/heading/PageHeading';
import SubHeading from 'src/design/components/heading/SubHeading';
import PageContentBlock from 'src/design/components/pageContentBlock/PageContentBlock';
import Paragraph from 'src/design/components/bodyText/Paragraph';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import SecondaryButton from 'src/design/components/button/SecondaryButton';
import StyledBodylink from 'src/design/components/hyperlink/StyledBodylink';
import Backlink from 'src/design/components/hyperlink/Backlink';
import BodyText from 'src/design/components/bodyText/BodyText';
// import { helpCenterUrl } from 'src/utils/pageUrls';

import styles from 'src/components/settings/Settings.mscss';


const DisableSmartInvest = ({ disableSmartInvest }) => (
  <div
    className={styles.settings}
    data-test-reference="enable-smart-invest-page"
  >
    <DocumentMeta {...SETTINGS_META} />
    <PageHeading>Disable Smart Invest</PageHeading>

    <PageContentBlock>
      <SubHeading className={styles.areYouSureSubHeading}>Are you sure?</SubHeading>

      <Spacing top="large">
        <Paragraph>
          By clicking DISABLE, we will take the following actions:
        </Paragraph>
      </Spacing>
      <Spacing top="normal">
        <BodyText>
          <ul className={styles.settingsBulletList}>
            <li className={styles.settingsBulletListItem}>Stop any future direct debits</li>
            <li className={styles.settingsBulletListItem}>Not purchase any new Bricks</li>
            <li className={styles.settingsBulletListItem}>Funds Awaiting Investment will be transferred into Funds Available your Wallet.</li>
            <li className={styles.settingsBulletListItem}>Any distributions will be in Funds Available, not Funds Awaiting Investment.</li>
            <li className={styles.settingsBulletListItem}>
              You will retain ownership of your Bricks (You are responsible for selling Bricks you own. <StyledBodylink href="http://help.brickx.com/support/solutions/articles/17000077278-how-do-i-disable-smart-invest-" testRef="learn-more-button">Learn more</StyledBodylink>)
            </li>
          </ul>
        </BodyText>
      </Spacing>
      <Spacing top="2x-large" bottom="2x-large">
        <SecondaryButton
          fullWidth
          color={SecondaryButton.colors.SECONDARY}
          onClick={() => disableSmartInvest()}
          testRef="disable-si-button"
        >Disable</SecondaryButton>
      </Spacing>
      <Backlink fullWidth grey testRef="go-back-button">Go Back</Backlink>
    </PageContentBlock>
  </div>
);

export default connect(null, {
  disableSmartInvest: disableSmartInvestAction
})(DisableSmartInvest);
