import { Component } from 'react';


const informWhenJsLoaded = (WrappedComponent) => class ProvideJsLoadedInfo extends Component {
  state = { jsLoaded: false };

  componentDidMount() {
    this.setState({ jsLoaded: true }); // eslint-disable-line react/no-did-mount-set-state
  }

  render() {
    return <WrappedComponent {...this.props} jsLoaded={this.state.jsLoaded}/>;
  }
};

export default informWhenJsLoaded;
