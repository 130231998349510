import React from 'react';
import { Helmet } from 'react-helmet';
import Configuration from 'scripts/constants/Configuration';
import { renderCode } from 'src/settings/properties';

const PropertyDefaultMetaTags = ({ propertyCode, url }) => {
  const socialShare = {
    name: `View BrickX Property ${propertyCode}`,
    description: `BrickX offers a secure, simple, and low cost way to get into the property market. Invest in AU residential properties today for under $100.`,
    url: url,
    picture: Configuration.PROPERTY_THUMB_PLACEHOLDER,
  };
  return (
    <Helmet>
      <title>{`${renderCode(propertyCode)} | BrickX`}</title>
      <meta name="description" content={socialShare.description} />
      <meta
        property="keywords"
        content={`brickx,property,properties,invest,${renderCode(
          propertyCode
        )}`}
      />
      <meta property="og:title" content={socialShare.name} />
      <meta property="og:description" content={socialShare.description} />
      <meta property="og:url" content={socialShare.url} />
      <meta property="og:image" content={socialShare.picture} />
      <meta property="og:image:alt" content="BrickX Property" />
      <meta property="og:image:secure_url" content={socialShare.picture} />
      <meta name="twitter:description" content={socialShare.description} />
      <meta name="twitter:url" content={socialShare.url} />
      <meta name="twitter:title" content={socialShare.name} />
      <meta name="twitter:image:src" content={socialShare.picture} />
    </Helmet>
  );
};

export default PropertyDefaultMetaTags;
