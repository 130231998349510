

import { property as propertyPropType } from 'scripts/constants/PropTypes';
import FinancialsPanel from 'scripts/components/shared/FinancialsPanel';

const PropertyFeatures = ({ property }) => (
  <FinancialsPanel title="Property Features" className="amenities">
    <ul className="property-features">
      {property.propertyFeatures.split(',').map((feature, index) => (
        <li key={index} className="feature">{feature.trim()}</li>
      ))}
    </ul>
  </FinancialsPanel>
);

PropertyFeatures.propTypes = {
  property: propertyPropType.isRequired
};

export default PropertyFeatures;
