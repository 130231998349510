import { cloneElement } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
  closeModal as closeModalAction,
} from 'src/components/modals/modalActions';
import Icon from 'src/design/components/icon/Icon';
import styles from 'src/components/modals/Modals.mscss';

const Modal = ({
  id,
  content,
  closeModal,
  onClose = () => {},
  exitAnimation,
  notVerticallyCentered,
  noCloseButton,
  testRef,
} = {}) => {
  const closeThisModal = () => {
    closeModal({ id });
    onClose({ id });
  };

  return (
    <li
      className={classNames(styles.modal, {
        [styles.exitAnimation]: exitAnimation,
      })}
      data-test-reference={testRef}
    >
      <div className={styles['modal--inner']}>
        <div className={classNames({ [styles['modal--inner--centeredContent']]: !notVerticallyCentered })} data-test-reference="modal-content-container">
          {cloneElement(content, {
            closeThisModal,
          })}
        </div>
        {!noCloseButton && (
          <a
            data-test-reference="modal-x-button"
            className={styles['modal--closeButton']}
            onClick={() => closeThisModal()}
          >
            <Icon type={Icon.types.close} className={styles['modal--closeButton--icon']} />
          </a>
        )}
      </div>
    </li>
  );
};

export default connect(null, {
  closeModal: closeModalAction,
})(Modal);
