import { useState } from 'react';
import OnboardingScreen, {
  OnboardingScreenHeader,
  OnboardingScreenContent,
  OnboardingFormLabel,
} from 'src/components/oldOnboarding/common/onboardingScreen/OnboardingScreen';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import Heading from 'src/design/components/heading/Heading';
import { Formik } from 'formik';
import Divider from 'src/design/components/divider/Divider';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import SmsVerify from './SmsVerify';
import UserService from 'scripts/services/UserService';
import { getCurrentUserId } from 'scripts/auth/session';
import { contactUrl, settingsUrl } from 'src/utils/pageUrls';
import TextInput from 'src/design/components/textInput/TextInput';
import Paragraph from 'src/design/styleguide/Paragraph';
import Styledlink from 'src/design/components/hyperlink/Styledlink';
import { valideAustralianMobileNumber } from 'src/utils/validation';

const UpdateMobileNumberComponent = ({ onClickBack }) => {
  const [hasSentSms, setHasSentSms] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const onSubmit = async (values) => {
    setError(null);
    const userId = getCurrentUserId();
    try {
      await UserService.updateMobileNumber(userId, values);
      setSuccess(true);
    } catch (e) {
      if (e._body) setError(e._body);
    }
  };

  if (success) {
    return (
      <OnboardingScreen>
        <OnboardingScreenHeader>
          <Spacing bottom="x-large">
            <Heading>Your Mobile Number has been updated</Heading>
          </Spacing>
        </OnboardingScreenHeader>
        <OnboardingScreenContent>
          <PrimaryButton
            fullWidth
            color={PrimaryButton.colors.SECONDARY}
            link={settingsUrl()}
            forceExternal
          >
            RETURN TO DASHBOARD
          </PrimaryButton>
        </OnboardingScreenContent>
      </OnboardingScreen>
    );
  }

  const validateForm = (formData) => {
    return (
      formData.newMobileNumber &&
      valideAustralianMobileNumber(formData.newMobileNumber)
    );
  };

  return (
    <div className="inner">
      <div className="header">
        <Spacing bottom="x-large">
          <Heading>Update Mobile Number</Heading>
        </Spacing>
        <Spacing bottom="large">
          <Paragraph>
            <Paragraph>
              Please note you must enter an Australian mobile number to be able
              to receive a PIN code via SMS for withdrawals.
              <br />
              <br />
              If you no longer have access to the mobile number on your account,
              please <Styledlink href={contactUrl()}>contact us</Styledlink>.
              <br />
            </Paragraph>
          </Paragraph>
        </Spacing>
      </div>
      <div className="content">
        <Formik
          initialValues={{
            otpValue: '',
            newMobileNumber: '',
          }}
          onSubmit={async (values) => onSubmit(values)}
        >
          {(props) => {
            const { values, handleSubmit, setFieldValue } = props;
            return (
              <form onSubmit={handleSubmit}>
                <OnboardingFormLabel>
                  Enter your new mobile number
                </OnboardingFormLabel>
                <TextInput
                  placeholder="eg. 0400123456"
                  value={values.newMobileNumber}
                  onChange={(v) => {
                    setFieldValue('newMobileNumber', v);
                  }}
                  fullWidth
                />
                <Divider />
                {hasSentSms && (
                  <SmsVerify
                    onChangeCode={(v) => {
                      setError(null);
                      setFieldValue('otpValue', v);
                    }}
                  />
                )}
                {error && (
                  <Spacing>
                    <div className="text-red">{error}</div>
                  </Spacing>
                )}
                <Spacing top="normal">
                  {!hasSentSms ? (
                    <PrimaryButton
                      fullWidth
                      color={PrimaryButton.colors.SECONDARY}
                      onClick={() => setHasSentSms(true)}
                      disabled={!validateForm(values)}
                    >
                      NEXT
                    </PrimaryButton>
                  ) : (
                    <PrimaryButton
                      fullWidth
                      color={PrimaryButton.colors.SECONDARY}
                      disabled={values.otpValue.length !== 6}
                    >
                      SUBMIT
                    </PrimaryButton>
                  )}
                </Spacing>
                <Spacing top="normal">
                  <PrimaryButton
                    fullWidth
                    color={PrimaryButton.colors.PRIMARY}
                    onClick={onClickBack}
                  >
                    CANCEL
                  </PrimaryButton>
                </Spacing>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default UpdateMobileNumberComponent;
