import $ from 'jquery';
import { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from 'scripts/components/shared/Icon';
import Text from 'src/design/styleguide/text/Text';


export default class Popover extends Component {
  static propTypes = {
    placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
    content: PropTypes.string.isRequired,
    color: PropTypes.string,
  };

  componentDidMount() {
    $(this.popover).popover();
  }

  componentWillUnmount() {
    $(this.popover).popover('destroy');
  }

  render() {
    const { color } = this.props;

    return (
      <Text color="onSurfaceContrast500">
        <Text
          {...(color ? { color } : {})}
          ref={(el) => this.popover = el}
          className="popover-container"
          data-placement={this.props.placement}
          data-content={this.props.content}
          data-trigger="hover"
          data-html
        >
          <Icon type="fa" name="question-circle"/>
        </Text>
      </Text>
    );
  }
}
