// TODO: besides WITHDRAWAL and WITHDRAW_STEPS this does not belong in constants, move it to configuration
import { maximumAustralianTaxAmount } from 'src/settings/financials';
import { minimumDeposit } from 'src/settings/funds';

export default {
  MINIMUM_DEPOSIT_AMOUNT: minimumDeposit,
  MAXIMUM_DEPOSIT_AMOUNT: 500000,
  TAX_AMOUNT: maximumAustralianTaxAmount,
  SMS_PIN_LENGTH: 6,
  WITHDRAWAL: {
    NOT_DEFINED: 'Not Defined',
    REQUESTED: 'withdrawal requested',
  },
  WITHDRAW_STEPS: {
    INITIAL: 'initial',
    VERIFY: 'verify',
  },
};
