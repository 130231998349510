import BrickPriceStat from 'src/components/propertyCards/common/stats/BrickPriceStat';
import EstNetRentalYieldStat from 'src/components/propertyCards/common/stats/EstNetRentalYieldStat';
import HistoricalSuburbGrowthStat from 'src/components/propertyCards/common/stats/HistoricalSuburbGrowthStat';
import PreMarketClosedFlag from 'src/components/propertyCards/common/flags/PreMarketClosedFlag';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import PropertyCardBodyFiveStatLayout from 'src/components/propertyCards/common/propertyCardBodyFiveStatLayout/PropertyCardBodyFiveStatLayout';
import PropertyCardButtonContainer from 'src/components/propertyCards/common/propertyCardButtonContainer/PropertyCardButtonContainer';
import PropertyCardContainer from 'src/components/propertyCards/common/propertyCardContainer/PropertyCardContainer';
import PropertyCardFeature from 'src/components/propertyCards/common/propertyCardHero/PropertyCardHero';
import PropertyCardHeader from 'src/components/propertyCards/common/propertyCardHeader/PropertyCardHeader';
import PropertyCardStat from 'src/components/propertyCards/common/propertyCardStat/PropertyCardStat';
import { number } from 'scripts/utilities/formatters';
import _ from 'lodash';
import { VOTE_CLOSED_PROPERTIES } from 'src/settings/trading';

const PropertyCardMarketClosed = ({ property, historicalGrowthMetrics }) => (
  <PropertyCardContainer link={`/properties/${property.propertyCode}`}>
    <PropertyCardHeader property={property} flag={<PreMarketClosedFlag isClosed={ _.includes(VOTE_CLOSED_PROPERTIES, property.propertyCode) }/>} style={PropertyCardHeader.styles.MARKET_OPEN}/>
    <PropertyCardFeature property={property}/>
    <PropertyCardBodyFiveStatLayout
      secondaries={[
        <EstNetRentalYieldStat property={property}/>,
        <HistoricalSuburbGrowthStat historicalGrowthMetrics={historicalGrowthMetrics} propertyType={property.propertyType}/>,
        <BrickPriceStat property={property}/>,
        <PropertyCardStat label="Investors" value={property.investmentMetrics ? property.investmentMetrics.investors::number() : 0} valueProps={{ 'data-test-reference': 'investors' }}/>,
        // <PropertyCardStat label="Investors" value={""} valueProps={{ 'data-test-reference': 'investors' }}/>
      ]}
    />
    <PropertyCardButtonContainer>
      <PrimaryButton
        className="property-card__button"
        color={PrimaryButton.colors.SECONDARY}
        arrow
        testRef="button"
      >
        VIEW PROPERTY
      </PrimaryButton>
    </PropertyCardButtonContainer>
  </PropertyCardContainer>
);

export default PropertyCardMarketClosed;
