import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Heading } from 'src/design/styleguide/Headings';
import { isLoggedIn, redirectToStepPage } from 'src/utils/user';
import { notFoundUrl } from 'src/utils/pageUrls';
import { resendEmail as resendEmailAction } from 'scripts/redux/actions/registration';
import { userSelector } from 'scripts/redux/actions/user';
import Image from 'src/design/components/image/Image';
import OnboardingScreen from
  'src/components/oldOnboarding/common/onboardingScreen/OnboardingScreen';
import Paragraph from 'src/design/styleguide/Paragraph';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import RegistrationStep from 'scripts/constants/RegistrationStep';
import SecondaryButton from 'src/design/components/button/SecondaryButton';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import history from 'src/browser/history';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 360px;
  margin: auto;
  text-align: center;
`;

const Icon = styled(Image).attrs({
  src: 'static/img/icon-handshake.svg',
  alt: 'handshake icon',
})`
  max-width: 80px;
`;

const identityVerificationDoneSteps = [
  RegistrationStep.DEPOSIT,
  RegistrationStep.SMSF,
  RegistrationStep.INVEST,
  RegistrationStep.DONE,
];

const hasAlreadyVerifiedEmail = (user) => user.nextRegistrationStep !== RegistrationStep.VERIFY;
const hasCompletedIdentityVerification = (user) => identityVerificationDoneSteps.includes(user.nextRegistrationStep);

const SmsfSuccess = ({ user, resendEmail }) => {
  if (!isLoggedIn(user)) {
    history.push(notFoundUrl()); // TODO: push in render
    return null;
  }

  const verifyEmailStuff = (
    <Fragment>
      <Spacing top="2x-large">
        <Paragraph>Please check your inbox to verify your email and continue the signup process.</Paragraph>
      </Spacing>

      <Spacing top="normal">
        <SecondaryButton
          onClick={resendEmail}
          color={SecondaryButton.colors.SECONDARY}
          fullWidth
          testRef="resend-email-button"
        >
          RESEND EMAIL
        </SecondaryButton>
      </Spacing>
    </Fragment>
  );

  const continueOnboardingStuff = (
    <Fragment>
      <Spacing top="2x-large">
        <Paragraph>Looks like you didn't get a chance to finish the setup steps.</Paragraph>
      </Spacing>

      <Spacing top="normal">
        <PrimaryButton
          onClick={redirectToStepPage(user)}
          color={PrimaryButton.colors.SECONDARY}
          fullWidth
          testRef="continue-onboarding-button"
        >
          CONTINUE
        </PrimaryButton>
      </Spacing>
    </Fragment>
  );

  return (
    <OnboardingScreen testRef="verify-email-screen">
      <Helmet>
        <meta name="robots" content="noindex"/>
      </Helmet>

      <Container>
        <Icon/>

        <Spacing top="normal">
          <Heading>Thank you for submitting your SMSF details!</Heading>
        </Spacing>

        {
          hasCompletedIdentityVerification(user)
          ? null
          : hasAlreadyVerifiedEmail(user)
          ? continueOnboardingStuff
          : verifyEmailStuff
        }

        <Spacing top="normal">
          <Paragraph>We'll be in touch within 2 business days, once your account is ready. While we set you up please <strong>hold off</strong> on buying Bricks.</Paragraph>
        </Spacing>
      </Container>
    </OnboardingScreen>
  );
};

export default connect(userSelector, { resendEmail: resendEmailAction })(SmsfSuccess);
