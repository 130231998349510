import { identityCheckConsentCheckboxText, minorConsentCheckboxText } from 'src/settings/text';
import DateOfBirth from 'src/design/components/dateOfBirth/DateOfBirth';
import Divider from 'src/design/components/divider/Divider';
import FormCheckbox from 'src/design/components/formCheckbox/FormCheckbox';
import Heading from 'src/design/components/heading/Heading';
import HelpDisclaimer from 'src/design/components/helpDisclaimer/HelpDisclaimer';
import InfoBox from 'src/design/components/infoBox/InfoBox';
import InputError from 'src/design/components/inputError/InputError';
import OnboardingNextButton from 'src/components/oldOnboarding/common/onboardingNextButton/OnboardingNextButton';
import OnboardingScreen, {
  OnboardingScreenHeader,
  OnboardingScreenContent,
  OnboardingFormLabel,
} from 'src/components/oldOnboarding/common/onboardingScreen/OnboardingScreen';
import Paragraph from 'src/design/components/bodyText/Paragraph';
import RadioSelect from 'src/design/components/radioSelect/RadioSelect';
import ResidentialAddress from 'src/components/oldOnboarding/identityVerification/ResidentialAddress';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import TextInputWithError from 'src/design/components/textInput/TextInputWithError';
import { validateTfn } from 'src/utils/validation';
import { FaChild, FaUser } from 'react-icons/fa';
import FormSelect from 'src/design/components/formSelect/FormSelect';

const usCitizenFormValueToFieldValue = (formValue) => {
  if (formValue === true) return 'yes';
  if (formValue === false) return 'no';
  return '';
};

const usCitizenFieldValueToFormValue = (fieldValue) => {
  if (fieldValue === 'yes') return true;
  if (fieldValue === 'no') return false;
};

const submitAllowed = (values) => {
  return (values.givenName
    && values.familyName
    && values.address
    && values.mobilePhone
    && values.dayOfBirth
    && values.monthOfBirth
    && values.yearOfBirth
    && values.tfn
    && usCitizenFormValueToFieldValue(values.usCitizen)
    && values.tsAndCsAgreed
    && values.minorAck
    && values.minorGivenName
    && values.minorFamilyName
    && values.minorMonthOfBirth
    && values.minorDayOfBirth
    && values.minorYearOfBirth
    && values.minorRelation);
};

const EnterDetailsAsTheyAppear = (props) => (
  <InfoBox {...props}>
    <Paragraph>Please enter details exactly as they appear on your ID.</Paragraph>
  </InfoBox>
);

const IdentityVerificationFormMinor = ({
  onSubmit,
  onChange,
  values,
  identityVerificationFormErrors,
  identityVerificationFormErrorsCleared,
  submitting,
}) => {
  return (
    <OnboardingScreen testRef="identity-verification-screen">
      <OnboardingScreenHeader>
        <Spacing bottom="large">
          <Heading>Please tell us a bit more about yourself and the Minor you are investing for</Heading>
        </Spacing>
        <Spacing bottom="large">
          <Paragraph>We are required by Australian law to verify all BrickX investors</Paragraph>
        </Spacing>
      </OnboardingScreenHeader>
      <OnboardingScreenContent>
        <form>
          <Spacing bottom="large">
            <Paragraph><FaUser/> Your Details:</Paragraph>
          </Spacing>
          <OnboardingFormLabel>First Name</OnboardingFormLabel>
          <Spacing bottom="normal">
            <TextInputWithError
              value={values.givenName}
              onChange={(value) => {
                identityVerificationFormErrorsCleared('givenName');
                onChange({ givenName: value });
              }}
              placeholder="John"
              error={identityVerificationFormErrors.givenName && 'Please enter a valid first name (alphabets only)'}
              testRef="given-name"
              errorTestRef="given-name-error"
            />
          </Spacing>
          <OnboardingFormLabel>Middle Name (Optional)</OnboardingFormLabel>
          <Spacing bottom="normal">
            <TextInputWithError
              value={values.middleName}
              onChange={(value) => {
                identityVerificationFormErrorsCleared('middleName');
                onChange({ middleName: value });
              }}
              placeholder="William"
              error={identityVerificationFormErrors.middleName && 'Please enter a valid first name (alphabets only)'}
              testRef="middle-name"
              errorTestRef="middle-name-error"
            />
          </Spacing>
          <OnboardingFormLabel>Last Name</OnboardingFormLabel>
          <Spacing bottom="normal">
            <TextInputWithError
              value={values.familyName}
              onChange={(value) => {
                identityVerificationFormErrorsCleared('familyName');
                onChange({ familyName: value });
              }}
              placeholder="Doe"
              error={identityVerificationFormErrors.familyName && 'Please enter a valid last name (alphabets only)'}
              testRef="family-name"
              errorTestRef="family-name-error"
            />
          </Spacing>
          <ResidentialAddress
            value={values.address}
            harmonyValue={values.harmonyAddressObject}
            onChange={(value) => {
              identityVerificationFormErrorsCleared('address');
              onChange({
                address: value,
                harmonyAddressObject: undefined,
              });
            }}
            onSuggestionSelect={(harmonyAddressObject) => {
              onChange({
                address: harmonyAddressObject.fullAddress,
                harmonyAddressObject,
              });
            }}
            showError={identityVerificationFormErrors.address}
          />
          <OnboardingFormLabel>Mobile</OnboardingFormLabel>
          <Spacing bottom="normal">
            <TextInputWithError
              value={values.mobilePhone}
              onChange={(value) => {
                identityVerificationFormErrorsCleared('mobilePhone');
                onChange({ mobilePhone: value });
              }}
              placeholder="e.g. 04xxxxxxxx"
              error={identityVerificationFormErrors.mobilePhone && 'Please enter a valid Australian mobile number'}
              testRef="phone-number"
              errorTestRef="phone-number-error"
            />
          </Spacing>
          <OnboardingFormLabel>Date of Birth</OnboardingFormLabel>
          <Spacing bottom="normal">
            <DateOfBirth
              day={{
                value: values.dayOfBirth,
                onChange: (value) => {
                  identityVerificationFormErrorsCleared('dateOfBirth');
                  onChange({ dayOfBirth: value });
                },
                testRef: 'day-of-birth-select',
              }}
              month={{
                value: values.monthOfBirth,
                onChange: (value) => {
                  identityVerificationFormErrorsCleared('dateOfBirth');
                  onChange({ monthOfBirth: value });
                },
                testRef: 'month-of-birth-select',
              }}
              year={{
                value: values.yearOfBirth,
                onChange: (value) => {
                  identityVerificationFormErrorsCleared('dateOfBirth');
                  onChange({ yearOfBirth: value });
                },
                testRef: 'year-of-birth-select',
              }}
              error={identityVerificationFormErrors.dateOfBirth && 'You must be at least 18 years old to register'}
              errorTestRef="date-of-birth-error"
            />
          </Spacing>
          <Spacing bottom="large">
            <EnterDetailsAsTheyAppear/>
          </Spacing>
          <Divider top="normal" bottom="normal"/>
          <OnboardingFormLabel>Australian Tax File Number</OnboardingFormLabel>
          <Spacing bottom="normal">
            <TextInputWithError
              key={identityVerificationFormErrors.tfn === false}
              value={values.tfn}
              onChange={(value) => {
                const checkTfn = validateTfn(value);
                if (value.length === 8 || value.length === 9) {
                  identityVerificationFormErrors.tfn = checkTfn;
                }
                if (checkTfn === false) {
                  identityVerificationFormErrorsCleared('tfn');
                }
                if (value.length !== 8 && value.length !== 9) {
                  identityVerificationFormErrorsCleared('tfn');
                }
                onChange({ tfn: value });
              }}
              placeholder="Enter a valid 8 or 9 digit tax file number."
              error={identityVerificationFormErrors.tfn}
              testRef="tfn"
              errorTestRef="tfn-error"
            />
          </Spacing>
          <Divider top="normal" bottom="normal"/>
          <OnboardingFormLabel>Are you a US citizen?</OnboardingFormLabel>
          <Spacing bottom="x-small">
            <RadioSelect
              name="us-citizen"
              value={usCitizenFormValueToFieldValue(values.usCitizen)}
              onChange={(value) => onChange({ usCitizen: usCitizenFieldValueToFormValue(value) })}
              options={[
                { value: 'yes', labelText: 'Yes' },
                { value: 'no', labelText: 'No' },
              ]}
              testRef="us-citizen"
            />
          </Spacing>
          <HelpDisclaimer>Your answer does not affect your eligibility to invest, and is for reporting purposes only.</HelpDisclaimer>
          <Divider top="normal" bottom="normal"/>
          <Spacing bottom="large">
            <Paragraph><FaChild /> Minor's Details:</Paragraph>
          </Spacing>
          <OnboardingFormLabel>First Name</OnboardingFormLabel>
          <Spacing bottom="normal">
            <TextInputWithError
              value={values.minorGivenName}
              onChange={(value) => {
                identityVerificationFormErrorsCleared('minorGivenName');
                onChange({ minorGivenName: value });
              }}
              placeholder="John"
              error={identityVerificationFormErrors.minorGivenName && 'Please enter a valid first name (alphabets only)'}
              testRef="minor-given-name"
              errorTestRef="minor-given-name-error"
            />
          </Spacing>
          <OnboardingFormLabel>Middle Name (if applicable)</OnboardingFormLabel>
          <Spacing bottom="normal">
            <TextInputWithError
              value={values.minorMiddleName}
              onChange={(value) => {
                identityVerificationFormErrorsCleared('minorFamilyName');
                onChange({ minorMiddleName: value });
              }}
              placeholder="William"
              error={identityVerificationFormErrors.minorMiddleName && 'Please enter a valid last name (alphabets only)'}
              testRef="minor-middle-name"
              errorTestRef="minor-middle-name-error"
            />
          </Spacing>
          <OnboardingFormLabel>Last Name</OnboardingFormLabel>
          <Spacing bottom="normal">
            <TextInputWithError
              value={values.minorFamilyName}
              onChange={(value) => {
                identityVerificationFormErrorsCleared('minorFamilyName');
                onChange({ minorFamilyName: value });
              }}
              placeholder="Doe"
              error={identityVerificationFormErrors.minorFamilyName && 'Please enter a valid last name (alphabets only)'}
              testRef="minor-family-name"
              errorTestRef="minor-family-name-error"
            />
          </Spacing>
          <OnboardingFormLabel>Date of Birth (must be under 18)</OnboardingFormLabel>
          <Spacing bottom="normal">
            <DateOfBirth
              day={{
                value: values.minorDayOfBirth,
                onChange: (value) => {
                  identityVerificationFormErrorsCleared('minorDateOfBirth');
                  onChange({ minorDayOfBirth: value });
                },
                testRef: 'minor-day-of-birth-select',
              }}
              month={{
                value: values.minorMonthOfBirth,
                onChange: (value) => {
                  identityVerificationFormErrorsCleared('minorDateOfBirth');
                  onChange({ minorMonthOfBirth: value });
                },
                testRef: 'minor-month-of-birth-select',
              }}
              year={{
                value: values.minorYearOfBirth,
                onChange: (value) => {
                  identityVerificationFormErrorsCleared('minorDateOfBirth');
                  onChange({ minorYearOfBirth: value });
                },
                testRef: 'minor-year-of-birth-select',
              }}
              error={identityVerificationFormErrors.minorDateOfBirth && 'You must be at least 18 years old to register'}
              errorTestRef="date-of-birth-error"
            />
          </Spacing>
          <OnboardingFormLabel>I am his/her:</OnboardingFormLabel>
          <Spacing bottom="large">
              <FormSelect
                testRef="minor-relation-select"
                onChange={(value) => onChange({ minorRelation: value })}
              >
                <option value="">Select a relation</option>
                <option value="PARENT">Parent</option>
                <option value="AUNTUNCLE">Aunt/Uncle</option>
                <option value="GRANDPARENT">Grandparent</option>
                <option value="LEGALGUARDIAN">Legal Guardian</option>
              </FormSelect>
          </Spacing>
          <FormCheckbox
            value={values.tsAndCsAgreed}
            onChange={(value) => onChange({ tsAndCsAgreed: value })}
            testRef="ts-and-cs-checkbox"
            labelTestRef="ts-and-cs-checkbox-label"
          >
            {identityCheckConsentCheckboxText()}
          </FormCheckbox>
          <Spacing top="large">
            <FormCheckbox
              value={values.minorAck}
              onChange={(value) => onChange({ minorAck: value })}
              testRef="minorAck-checkbox"
              labelTestRef="minorAck-checkbox-label"
            >
              {minorConsentCheckboxText()}
            </FormCheckbox>
          </Spacing>
          <OnboardingNextButton
            testRef="next-button"
            disabled={!submitAllowed(values) || submitting}
            onClick={(e) => {
              e.preventDefault();
              onSubmit();
            }}
          />
          {
            identityVerificationFormErrors.requestError
            ? (
              <Spacing top="x-small">
                <InputError error="We are having some trouble contacting our verification service at the moment. Please try again later."/>
              </Spacing>
            )
            : null
          }
        </form>
      </OnboardingScreenContent>
    </OnboardingScreen>
  );
};

export default IdentityVerificationFormMinor;
