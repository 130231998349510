import Paragraph from 'src/design/components/bodyText/Paragraph';
import StyledBodylink from 'src/design/components/hyperlink/StyledBodylink';

import styles from 'src/components/landingPages/howItWorks/HowItWorks.mscss';


const TwoWaysSecondaryCta = () => (
  <div className={styles.twoWaysSecondaryCtaContainer}>
    <Paragraph>Still have questions?</Paragraph>
    <StyledBodylink href="http://help.brickx.com/support/solutions/articles/17000077275-how-does-smart-invest-differ-from-build-my-own-">Check out our Help Centre</StyledBodylink>
  </div>
);

export default TwoWaysSecondaryCta;
