import RatioContainer from 'scripts/components/shared/RatioContainer';
import BindYoutubeTrackingToIframe from 'src/tracking/BindYoutubeTrackingToIframe';

const createIframeId = (videoId) => `youtube-player-iframe-${videoId}`;

const Video = ({ className, videoId, autoplay, onPlay, onFinish }) => (
  <RatioContainer className={className}>
    <BindYoutubeTrackingToIframe iframeId={createIframeId(videoId)} onPlay={onPlay} onFinish={onFinish}/>
    <iframe
      id={createIframeId(videoId)}
      style={{ display: 'block', background: 'transparent' }}
      src={`//www.youtube.com/embed/${videoId}?showinfo=0&enablejsapi=1&autoplay=${autoplay ? '1' : '0'}&modestbranding=1&playsinline=1&rel=0`}
      frameBorder="0"
      allow="autoplay; encrypted-media"
      allowFullScreen
      allowscriptaccess="always"
    />
  </RatioContainer>
);

export default Video;
