import request from 'scripts/services/request';
import { deposit, withdraw } from 'scripts/services/api';
import { headers, withCredentials } from 'scripts/services/helpers';


class WalletService {
  deposit(userId, amount) {
    return request(deposit(userId), {
      method: 'POST',
      ...withCredentials,
      headers,
      body: JSON.stringify(amount)
    });
  }

  withdraw(userId, amount) {
    return request(withdraw(userId), {
      method: 'POST',
      ...withCredentials,
      headers,
      body: JSON.stringify(amount)
    });
  }
}

export default new WalletService();
