import { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import _ from 'lodash';


export default class Portal extends Component {
  static propTypes = {
    to: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]).isRequired,
    children: PropTypes.element.isRequired
  }

  componentDidMount() {
    const { to } = this.props;
    this.target = _.isString(to) ? document.querySelector(to) : to;
    this._renderContent(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this._renderContent(nextProps);
  }

  componentWillUnmount() {
    if (this.target) {
      ReactDOM.unmountComponentAtNode(this.target);
    } else {
      console.warn('Could not find DOM to unmount!'); // eslint-disable-line no-console
    }
  }

  _renderContent(props) {
    const { to, children } = props;
    if (this.target) {
      ReactDOM.render(children, this.target);
    } else {
      console.warn('Could not find', to); // eslint-disable-line no-console
    }
  }

  render() {
    return null;
  }
}
