import { createAction, handleActions } from 'redux-actions';
import update from 'immutability-helper';
import StatisticsService from 'scripts/services/StatisticsService';
import { errorAlert } from 'scripts/redux/actions/alerts';

const LATEST_STATS = 'scripts/stats/LATEST_STATS';
const latestStats = createAction(LATEST_STATS);

const RECENT_TRADES = 'scripts/stats/RECENT_TRADES';
const recentTrades = createAction(RECENT_TRADES);


export function fetchLatestStats() {
  return async function (dispatch) {
    try {
      const stats = await StatisticsService.getLatestStats();
      dispatch(latestStats(stats));
    } catch (e) {
      console.log(e.message);
      dispatch(errorAlert('Failed to load latest statistics.'));
    }
  };
}

export function fetchRecentPropertyTransactions() {
  return async function (dispatch) {
    try {
      const trades = await StatisticsService.getRecentPropertyTransactions();
      dispatch(recentTrades(trades));
    } catch (e) {
      console.log(e.message);
      dispatch(errorAlert('Failed to load recent transaction.'));
    }
  };
}

const reducer = handleActions({
  [LATEST_STATS]: (state, action) => update(state, {
    latestStats: {
      $set: action.payload
    }
  }),
  [RECENT_TRADES]: (state, action) => update(state, {
    recentTrades: {
      $set: action.payload
    }
  })
}, {
  latestStats: null,
  recentTrades: []
});

export default reducer;
