const Rollbar = CLIENT && window.Rollbar;

export function addPersonData({ id, email, username }) {
  Rollbar && Rollbar.configure({
    payload: {
      person: {
        id,
        username,
        email,
      },
    },
  });
}

export function critical(...args) { Rollbar && Rollbar.critical(...args); }
export function error(...args) { Rollbar && Rollbar.error(...args); }
export function info(...args) { Rollbar && Rollbar.info(...args); }
export function warning(...args) { Rollbar && Rollbar.warning(...args); }
export function debug(...args) { Rollbar && Rollbar.debug(...args); }
