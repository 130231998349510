import FinancialsPanel from 'scripts/components/shared/FinancialsPanel';
import { property as propertyPropType } from 'scripts/constants/PropTypes';
import { dollarDecimal } from 'scripts/utilities/formatters';
import Popover from 'scripts/components/shared/Popover';
import Icon from 'scripts/components/shared/Icon';
import { getGrossRentalIncomePopoverText, getInterestPaymentsPopoverText, getPropertyExpensesPopoverText } from 'src/settings/text';

const ForecastUnitCashFlow = ({ property }) => {
  const grossRentalIncomePopoverText = getGrossRentalIncomePopoverText(property.propertyType);
  const propertyExpensesPopoverText = getPropertyExpensesPopoverText(property.propertyType);
  const interestPaymentsPopoverText = getInterestPaymentsPopoverText(property.propertyType);
  const netRentalIncomePopoverText = 'The amount remaining from rental income, after expenses and interest payments are deducted. This amount is distributed to investors, on a pro-rata monthly basis.';

  return (
    <FinancialsPanel className="panel-rental-distributions" title="Cashflow: Annual Forecast">
      <div className="table-responsive">
        <table className="table">
          <tbody>
          <tr>
            <td><Icon className="fa-fw grey" type="fa" name="square" /> Gross Rental Income</td>
            <td className="text-right annual-gross-rental-income">
              {property.financials.annualGrossRentalIncome::dollarDecimal()} &nbsp;
              <Popover placement="top" content={grossRentalIncomePopoverText}/>
            </td>
          </tr>
          <tr>
            <td><Icon className="fa-fw blue" type="fa" name="square" /> Property Expenses</td>
            <td className="text-right annual-property-expenses">
              - {property.financials.annualPropertyExpenses::dollarDecimal()} &nbsp;
              <Popover placement="top" content={propertyExpensesPopoverText}/>
            </td>
          </tr>
          <tr>
            <td><Icon className="fa-fw red" type="fa" name="square" /> Interest Payments</td>
            <td className="text-right interest-payment">
              {property.financials.debtTerms
                ?
                <div>
                  - {property.financials.debtTerms.yearlyInterestPayment::dollarDecimal()} &nbsp;
                  <Popover placement="top" content={interestPaymentsPopoverText}/>
                </div>
                :
                <div>
                  $0 <Popover placement="top" content={interestPaymentsPopoverText}/>
                </div>
              }
            </td>
          </tr>
          <tr className="total">
            <td><Icon className="fa-fw green" type="fa" name="square" /> Net Rental Income</td>
            <td className="text-right net-rental-income">
              {(property.financials.annualNetRentalIncome)::dollarDecimal()} &nbsp;
              <Popover placement="top" content={netRentalIncomePopoverText}/>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </FinancialsPanel>
  );
};

ForecastUnitCashFlow.propTypes = {
  property: propertyPropType.isRequired
};


export default ForecastUnitCashFlow;
