import classNames from 'classnames';
import { BsX } from 'react-icons/bs';
import styled from 'styled-components';

const Layout = styled.div`

display: ${p => p.isHidden ? 'none' : 'block'};
text-align: left;
background-color: ${p => p.darkBackground ? '#00000068' : 'transparent' };

.modal-dialog-centered {
  height: 90%;
  display:flex;
  justify-content: center;
  align-items:center;
}

.modal-content {
  box-shadow: var(--bx-shadow);
  border: none;
  animation: ${p => !p.noAnimation && 'moveUpAndFadeIn .3s'} ;
}

.close {
  margin-top: 0;
  font-size: 25px;
  color: white;
  opacity: 1;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s;
}

.close:hover {
  background-color: #ffffff16;
}

`;

const Modal = ({
  isHidden,
  onClose,
  sizeClass,
  title,
  body,
  footer,
  noAnimation,
  darkBackground,
  hideHeader,
  hideFooter,
  hideCloseIcon,
}) => {
  const sizeClassName = sizeClass || 'modal-lg';
  return (
    <Layout className="modal" tabindex="-1" role="dialog" {...{ darkBackground, isHidden, noAnimation }}>
      <div className={classNames('modal-dialog', 'modal-dialog-centered', sizeClassName)} role="document">
        <div className="modal-content">
          { !hideHeader &&
            <div className="modal-header">
              <span className="modal-title" id="myModalLabel">{title}</span>
              <span className="close" aria-label="Close" onClick={onClose}><BsX /></span>
            </div>
          }
          <div className="modal-body">
            {body}
            {hideHeader && (!hideCloseIcon && <span className="close-panel" data-dismiss="modal">
                <span className="close-icon"><BsX /></span>
              </span>)
            }
          </div>
          { footer && !hideFooter && <div className="modal-footer">{footer}</div> }
        </div>
      </div>
    </Layout>
  );
};

export default Modal;
