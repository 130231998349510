/* global DEBUG */
/* global CLIENT */
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'react-router-redux';
import { browserHistory } from 'react-router';
import rootReducer from 'scripts/redux/rootReducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import { getTracker } from 'src/tracking/reduxSegmentTracker';

const trackerEnabled = CLIENT;

export default (initialState, history = browserHistory) => {
  let store;
  const routingMiddleware = routerMiddleware(history);

  let middlewares = [
    thunk,
    routingMiddleware,
  ];

  if (trackerEnabled) { middlewares = [...middlewares, getTracker()]; }

  store = createStore(rootReducer, initialState, composeWithDevTools(
    applyMiddleware(...middlewares),
  ));

  return store;
};
