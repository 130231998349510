import { connect } from 'react-redux';
import DynamicNumber from 'react-dynamic-number';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import {
  brickPrice,
  percentDecimal,
  brick,
} from 'scripts/utilities/formatters';
import { checkUserWithTradePermission } from 'scripts/utilities/userAccountHelper';
import {
  fetchPurchaseProposal,
  confirmPurchase,
  purchaseDone,
  clearOrders as clearOrdersAction,
} from 'scripts/redux/actions/market';
import { transactionFeeInfoText as freeTransactionFeeInfoText } from 'scripts/components/property/trade/feeFreePromotion';
import { preOrderHelpUrl } from 'src/utils/pageUrls';
import {
  property as propertyPropType,
  account as accountPropType,
  user as userPropType,
  tradeProposal as tradeProposalPropType,
  purchaseProposalForm as purchaseProposalFormPropType,
} from 'scripts/constants/PropTypes';
import { sendDepositLinkClick } from 'scripts/redux/actions/segment/events/depositEvents';
import {
  sendPreOrderProposalEngagementEvent,
  sendPreOrderProposalSubmitEvent,
} from 'scripts/redux/actions/segment/events/tradeEvents';
import { tradeProposalSelector } from 'scripts/redux/selectors/market';
import { validateTradeProposalQuantity } from 'scripts/utilities/tradeHelper';
import Constants from 'scripts/constants/Constants';
import DepositLink from 'scripts/components/shared/DepositLink';
import Numbers from 'scripts/constants/Numbers';
import SoldOut from 'scripts/components/property/trade/SoldOut';
import Trade from 'scripts/constants/Trade';
import { PROPERTIES_WITH_MAX_BUY_LIMIT_MAPPING } from 'src/settings/trading';

const isMaxNumOfBricksExempted = (propertyCode) =>
  !(propertyCode in PROPERTIES_WITH_MAX_BUY_LIMIT_MAPPING);
const maxNumOfBricksPerProperty = (propertyCode) =>
  PROPERTIES_WITH_MAX_BUY_LIMIT_MAPPING[propertyCode] || 10000;
const maxNumOfBricksPerPropertyRatio = (propertyCode) =>
  `${(PROPERTIES_WITH_MAX_BUY_LIMIT_MAPPING[propertyCode] || 10000) / 100}%`;
const timeOutInterval = Numbers.TWO_HUNDREDS;

const INTEGERS = Numbers.TEN;
const FRACTION = Numbers.ZERO;

const mapStateToProps = (state) => ({
  ...tradeProposalSelector(state),
});

@connect(mapStateToProps, {
  fetchPurchaseProposal,
  confirmPurchase,
  purchaseDone,
  clearOrders: clearOrdersAction,
})
export default class TradeProposalFormPreOrder extends Component {
  static propTypes = {
    user: userPropType.isRequired,
    account: accountPropType,
    property: propertyPropType.isRequired,
    tradeProposal: tradeProposalPropType,
    isFeeFreePromotionEnabled: PropTypes.bool.isRequired,
    fetchPurchaseProposal: PropTypes.func.isRequired,
    clearOrders: PropTypes.func,
    confirmPurchase: PropTypes.func.isRequired,
    purchaseDone: PropTypes.func.isRequired,
    redirectTo: PropTypes.func.isRequired,
    proposalForm: purchaseProposalFormPropType.isRequired,
    totalBricksOwned: PropTypes.number.isRequired,
    numOfBricksAvailable: PropTypes.number,
  };

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  state = {
    hasSufficientFund: true,
    loading: false,
    buyProposalParams: {
      quantity: '',
      hasError: false,
      errorMsg: '',
    },
    terms: {
      accepted: false,
      showErrorMessage: false,
    },
    checkboxHighPriceOn: false,
    checkboxHighPriceError: false,
  };

  componentDidMount() {
    this._fetchPurchaseProposal = _.debounce(
      this._fetchPurchaseProposal,
      timeOutInterval
    );

    const { fetchPurchaseProposal, proposalForm, property, clearOrders } =
      this.props;
    fetchPurchaseProposal(property.propertyCode, 0);
    clearOrders();
    this._handleQuantityChange(proposalForm.quantity);
    this._buildBuyProposalParams(proposalForm);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.numOfBricksAvailable &&
      nextProps.numOfBricksAvailable !== this.props.numOfBricksAvailable
    ) {
      this._handleQuantityChange(
        this.state.buyProposalParams.quantity,
        nextProps.numOfBricksAvailable
      );
    }
  }

  componentDidUpdate() {
    const { property, tradeProposal, purchaseDone, redirectTo } = this.props;

    if (tradeProposal && tradeProposal.type === Trade.CONFIRMED) {
      redirectTo(`/properties/${property.propertyCode}/preorder-success`);
      purchaseDone(
        Trade.TRADE_TYPE.PRE_ORDER,
        tradeProposal.data,
        this.state.buyProposalParams.quantity
      );
    }
  }

  render() {
    const { property, tradeProposal, proposalForm, isFeeFreePromotionEnabled } =
      this.props;
    const { hasSufficientFund, loading } = this.state;

    var tradeProposalData = {
      proposedBuyOrders: [],
      propertyCode: property.propertyCode,
      quantity: proposalForm.quantity,
      quantityWillBeTransacted: proposalForm.quantity,
      basisPrice: 0,
      valueOfBricks: 0,
      transactionFee: 0,
      total: 0,
    };
    if (
      tradeProposal &&
      tradeProposal.data &&
      tradeProposal.data.propertyCode === property.propertyCode
    ) {
      tradeProposalData = tradeProposal.data;
    }

    const hasBeenSoldOut = this._hasBricksSoldOut();

    return (
      <div className="place-order-panel">
        <div className="title hidden-xs">Place your order</div>

        <div className="white-boxes-container">
          <div className="col-inline col-inline-3 col-no-padding-left">
            <div className="white-box quantity">
              Quantity
              <DynamicNumber
                id="id_buy_quantity"
                className="form-control quantity-to-be-purchased"
                name="buy_quantity"
                value={this.state.buyProposalParams.quantity}
                integer={INTEGERS}
                fraction={FRACTION}
                positive={Constants.TRUE}
                thousand={Constants.FALSE}
                placeholder="0"
                onChange={this._onQuantityChange}
              />
            </div>
          </div>
          <div className="col-inline col-inline-3 hidden-xs">
            <div className="white-box">
              <div>Initial Brick Price</div>
              <div className="value basis-price">
                {tradeProposalData.basisPrice::brickPrice()}
              </div>
            </div>
          </div>
          <div className="col-inline col-inline-4 col-no-padding-right hidden-xs">
            <div className="white-box">
              <div>Pre-Order Total</div>
              <div className="value purchase-proposal-total">
                {tradeProposalData.total::brickPrice()}
              </div>
            </div>
          </div>
        </div>

        {hasBeenSoldOut && (
          <div className="row">
            <SoldOut propertyCode={property.propertyCode} isPreOrderProperty />
          </div>
        )}

        {proposalForm.hasError && (
          <div className="row">
            <div className="col-xs-12 error error-proposal">
              {proposalForm.errorMsg}
            </div>
          </div>
        )}

        <div className="visible-xs">
          <div className="row table-row">
            <div className="col-xs-7 col-no-padding-right col-left">
              <i aria-hidden="true"></i>Initial Brick Price
            </div>
            <div className="col-xs-5 col-no-padding-left col-right value-of-bricks">
              {tradeProposalData.basisPrice::brickPrice()}
            </div>
          </div>
          <div className="gray-line"></div>
        </div>

        <div className="row table-row">
          <div className="col-xs-7 col-no-padding-right col-left">
            <i aria-hidden="true"></i>Cost of Bricks
          </div>
          <div className="col-xs-5 col-no-padding-left col-right value-of-bricks">
            {tradeProposalData.valueOfBricks::brickPrice()}
          </div>
        </div>

        <div className="gray-line"></div>

        <div className="row table-row">
          <div className="col-xs-7 col-no-padding-right col-left">
            <i aria-hidden="true"></i>Transaction fee{' '}
            <br className="visible-xs" /> (
            {isFeeFreePromotionEnabled ? (
              <b>{freeTransactionFeeInfoText}</b>
            ) : (
              <span data-test-reference="transaction-fee">
                {tradeProposalData.transactionFeeRatio::percentDecimal()}
              </span>
            )}
            )
          </div>
          <div className="col-xs-5 col-no-padding-left col-right transaction-fee">
            {tradeProposalData.transactionFee::brickPrice()}
          </div>
        </div>

        <div className="gray-line"></div>

        <div className="row table-row">
          <div className="col-xs-7 col-no-padding-right col-left">
            <i aria-hidden="true"></i>
            Pre-Order Total
          </div>
          <div className="col-xs-5 col-no-padding-left col-right purchase-proposal-total">
            {tradeProposalData.total::brickPrice()}
          </div>
        </div>

        <div className="gray-line"></div>
        <br />

        <div className="action-buttons">
          <div>
            <div>
              <input
                type="checkbox"
                checked={this.state.terms.accepted}
                id="consent"
                required
                onChange={this._onAcceptTermsChange}
              />
              <label htmlFor="consent">
                &nbsp;I confirm that I have accessed, read and understood the{' '}
                <a href="/pds" target="_blank">
                  Product Disclosure Statement, Financial Services Guide, Target
                  Market Determination and Additional Disclosure Document
                </a>{' '}
                and agree to this pre-order.
              </label>
              &nbsp;
            </div>
            {this.state.terms.showErrorMessage && (
              <span className="text-danger accept-terms-warning">
                Check this box before confirming Pre-Order.
              </span>
            )}
          </div>
          <div>
            <button
              className="button orange-button confirm-pre-order-button action-full-button right-arrow-button"
              disabled={
                !hasSufficientFund ||
                hasBeenSoldOut ||
                loading ||
                proposalForm.hasError
              }
              onClick={::this._confirmPurchase}
            >
              CONFIRM PRE-ORDER
            </button>
            <div className="note text-left">
              Note: Pre-Orders are considered as pending orders and not
              transactions. You can cancel anytime before Settlement.&nbsp;
              <a target="_blank" href={preOrderHelpUrl()}>
                Learn More about Pre-Orders&nbsp;
                <span className="fa fa-angle-right"></span>
              </a>
            </div>
          </div>

          {!hasSufficientFund && (
            <div className="error error-insufficient-funds">
              You have insufficient funds.&nbsp;
              <DepositLink
                id="deposit-link-purchase-panel"
                onClick={this._onDepositLinkClick}
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  _onDepositLinkClick = () => {
    sendDepositLinkClick({ from: 'Trade Proposal Panel' });
  };

  _onAcceptTermsChange = () => {
    this.setState({
      terms: { accepted: !this.state.terms.accepted, showErrorMessage: false },
    });
  };

  _onQuantityChange = (event) => {
    var updatedQuantity = event.target.value;
    this._handleQuantityChange(updatedQuantity);
  };

  _handleQuantityChange = (
    quantity,
    numOfBricksAvailable = this.props.numOfBricksAvailable
  ) => {
    if (this._validateQuantity(quantity, numOfBricksAvailable)) {
      this._fetchPurchaseProposal(quantity);
    }
  };

  _validateQuantity = (quantity, numOfBricksAvailable) => {
    var isValid = false;

    const { proposalForm, totalBricksOwned } = this.props;
    var updatedProposalForm = proposalForm;

    if (validateTradeProposalQuantity(quantity)) {
      const updatedQuantityInt = Number.parseInt(quantity, 10);
      const hasBeenSoldOut = this._hasBricksSoldOut();
      const maxNumOfBricksCanBuy =
        this._calculateMaxNumOfBricksCanBuy(totalBricksOwned);
      const isMaxNumOfBricksExemptedResult = isMaxNumOfBricksExempted(
        this.props.property.propertyCode
      );
      if (isMaxNumOfBricksExemptedResult) {
        updatedProposalForm.hasError = false;
        updatedProposalForm.errorMsg = '';
        isValid = true;
      } else {
        if (maxNumOfBricksCanBuy === 0) {
          updatedProposalForm.hasError = true;
          updatedProposalForm.errorMsg = `You have reached the maximum number of Bricks you can pre order in this property (${maxNumOfBricksPerProperty(
            this.props.property.propertyCode
          )}).`;
        } else if (
          updatedQuantityInt > numOfBricksAvailable &&
          !hasBeenSoldOut
        ) {
          updatedProposalForm.hasError = true;
          updatedProposalForm.errorMsg = `There ${
            numOfBricksAvailable === 1 ? 'is' : 'are'
          } only ${numOfBricksAvailable::brick()} available. Please enter ${numOfBricksAvailable::brick()} or less to continue your transaction.`;
        } else if (updatedQuantityInt > maxNumOfBricksCanBuy) {
          if (isMaxNumOfBricksExemptedResult) {
            updatedProposalForm.hasError = true;
            updatedProposalForm.errorMsg = `You can only place maximum ${maxNumOfBricksCanBuy} bricks per order!`;
          } else {
            updatedProposalForm.hasError = true;
            updatedProposalForm.errorMsg = `You already own ${totalBricksOwned::brick()} in this property, and can only pre order ${maxNumOfBricksCanBuy} more to reach the maximum of ${maxNumOfBricksPerPropertyRatio(
              this.props.property.propertyCode
            )}, or ${maxNumOfBricksPerProperty(
              this.props.property.propertyCode
            )} Bricks per property. Please enter ${maxNumOfBricksCanBuy} or less in Quantity of Bricks to continue your transaction.`;
          }
        } else {
          updatedProposalForm.hasError = false;
          updatedProposalForm.errorMsg = '';
          isValid = true;
        }
      }

      updatedProposalForm.quantity = updatedQuantityInt;
    } else {
      updatedProposalForm.hasError = true;
      updatedProposalForm.errorMsg = `How many Bricks would you like to buy? Enter a Quantity to continue your transaction.`;

      updatedProposalForm.quantity = quantity;
    }
    this._updateForm(updatedProposalForm);

    return isValid;
  };

  _validateSufficientFunds = () => {
    const { account, tradeProposal } = this.props;
    const hasSufficientFund = tradeProposal.data.total
      ? account.availableToTradeBalance >= tradeProposal.data.total
      : true;
    this.setState({ hasSufficientFund });
    return hasSufficientFund;
  };

  _isFormValid = () => {
    if (!this.state.terms.accepted) {
      this.setState({ terms: { showErrorMessage: true } });
    }

    const isValid =
      this.state.terms.accepted &&
      this.state.hasSufficientFund &&
      !this.state.buyProposalParams.hasError;
    return isValid;
  };

  async _fetchPurchaseProposal(quantity) {
    const quantityIntValue = Number.parseInt(quantity, 10);

    const { property, fetchPurchaseProposal, account, totalBricksOwned } =
      this.props;

    await fetchPurchaseProposal(property.propertyCode, quantityIntValue);

    this._validateSufficientFunds();

    const { tradeProposal } = this.props;
    sendPreOrderProposalEngagementEvent({
      tradeProposalData: tradeProposal.data,
      account: account,
      totalBricksOwned: totalBricksOwned,
    });
  }

  async _confirmPurchase(e) {
    if (e) e.preventDefault();

    const { user, tradeProposal, confirmPurchase, account, totalBricksOwned } =
      this.props;
    if (checkUserWithTradePermission(user, this.context.router)) {
      if (this._isFormValid()) {
        this.setState({ loading: true });
        await confirmPurchase(tradeProposal.data, true);
        sendPreOrderProposalSubmitEvent({
          tradeProposalData: tradeProposal.data,
          account: account,
          totalBricksOwned:
            totalBricksOwned + tradeProposal.quantityWillBeTransacted,
        });

        this.setState({ loading: false });
      }
    }
  }

  _calculateMaxNumOfBricksCanBuy = (totalBricksOwned) => {
    return (
      maxNumOfBricksPerProperty(this.props.property.propertyCode) -
      totalBricksOwned
    );
  };

  _hasBricksSoldOut = () => {
    return this.props.numOfBricksAvailable <= Numbers.ZERO;
    // return true; // This is used to temporarily turn off pre-order
  };

  _buildBuyProposalParams = (updatedProposalForm) => {
    this.setState({
      buyProposalParams: {
        quantity: updatedProposalForm.quantity,
        hasError: updatedProposalForm.hasError,
        errorMsg: updatedProposalForm.errorMsg,
      },
    });
  };

  _updateForm = (updatedProposalForm) => {
    this._buildBuyProposalParams(updatedProposalForm);
    updatedProposalForm.callback(updatedProposalForm);
  };
}
