import styled from 'styled-components';
import {
  poppingCardBorderStyles,
  poppingCardBorderTopFeatureStyles,
} from 'src/design/components/poppingCard/poppingCardStyles';
import { testRefToDataTestReference } from 'src/design/styleguide/common/styledComponentsUtils';

const PoppingCard = styled.div.attrs(testRefToDataTestReference)`
  overflow: hidden;
  ${poppingCardBorderStyles}
  ${(props) => props.withBorderTopFeature && poppingCardBorderTopFeatureStyles}
  ${(props) => props.borderColor && `border-top-color: ${props.borderColor};`}
`;

export default PoppingCard;
