import { track } from 'src/tracking/analytics';

export const trackTradeSuccess = ({
  eventType,
  propertyCode,
  quantity,
  brickPrice,
  tradeAmount,
  commissionAmount,
  total,
}) => track(eventType, {
  'Property Code': propertyCode,
  'Quantity': quantity,
  'Average Brick Price': brickPrice,
  'Value of Bricks': tradeAmount,
  'Transaction Fees': commissionAmount,
  'Order Total': total,
  'value': total
});

export const trackBuySuccess = (values) => trackTradeSuccess({ eventType: 'Buy Bricks Success - FE', ...values });
export const trackPreOrderSuccess = (values) => trackTradeSuccess({ eventType: 'Pre Order Bricks Success - FE', ...values });
