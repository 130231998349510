import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { breakpointSmall } from 'src/design/styleguide/common/breakpoints';
import { contact, contactWithAttachments } from 'scripts/redux/actions/user';
import { user as userPropType } from 'scripts/constants/PropTypes';
import { userSelector } from 'scripts/redux/selectors/user';
import ContactUsMeta from 'src/components/meta/ContactUsMeta';
import Regex from 'scripts/utilities/regex';
import _ from 'lodash';
import InfoBox from 'src/design/components/infoBox/InfoBox';
import { countryCodePhoneNumber } from 'scripts/utilities/formatters';

const IS_INVESTOR = 1;
const NOT_INVESTOR = 2;
const messageTypes = {
  UPDATE_PHONE_NUMBER: 'UPDATE_PHONE_NUMBER',
};

export const ContactMessage = ({ message, messageChange, placeholder }) => (
  <div className="row form-input">
    <div className="col-md-2 input-label">Message:</div>
    <div className="col-md-8">
      <textarea
        className="bx-input"
        name="message"
        id="message"
        value={message.value}
        rows="15"
        placeholder={placeholder}
        onChange={messageChange}
      ></textarea>
      <div className="form-message__item form-message__item_error error-message">
        {message.invalid && 'Please enter your message'}
      </div>
    </div>
  </div>
);

export const ContactSubmitButton = () => (
  <div className="row form-input">
    <div className="col-md-2 input-label"></div>
    <div className="col-md-8">
      <button className="button orange-button right-arrow-button" type="submit">
        Send message
      </button>
    </div>
  </div>
);

const ContactMethodsContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${breakpointSmall(`
    flex-direction: row;
    justify-content: space-around;
  `)}
`;

const mapStateToProps = (state) => ({
  ...userSelector(state),
});

@connect(mapStateToProps, {
  contact,
  contactWithAttachments,
})
export default class Contact extends Component {
  static propTypes = {
    user: userPropType.isRequired,
    contact: PropTypes.func.isRequired,
    contactWithAttachments: PropTypes.func.isRequired,
  };

  state = {
    investor: { value: 0, invalid: false },
    name: { value: '', invalid: false },
    email: { value: '', invalid: false },
    phoneNumber: { value: '', invalid: false },
    messageType: { value: '', invalid: false },
    message: { value: '', invalid: false },
    verifyFullname: { value: '', invalid: false },
    verifyAddress: { value: '', invalid: false },
    verifyDob: { value: '', invalid: false },
    verifyNumOfBricks: { value: '', invalid: false },
    newEmailAddress: { value: '', invalid: false },
    newPhoneNumber: { value: '', invalid: false },
    newAddress: { value: '', invalid: false },
    attachment1: {
      name: '',
      type: '',
      file: '',
    },
    attachment2: {
      name: '',
      type: '',
      file: '',
    },
    formError: '',
  };

  UNSAFE_componentWillMount() {
    const { user } = this.props;
    this._initState(user);
  }

  onChangeFile(fileid, file) {
    const currentState = this.state;
    currentState[fileid] = file;
    currentState.formError = '';
    this.setState(currentState);
  }

  renderInput({
    label,
    inputType,
    inputId,
    inputName,
    inputValue,
    inputPlaceholder,
    onchange,
    invalid,
    invalidMessage,
  }) {
    return (
      <div className="row form-input">
        <div className="col-md-2 input-label">{label}:</div>
        <div className="col-md-5">
          <input
            className="bx-input"
            type={inputType || 'text'}
            id={inputId || 'input-id'}
            value={inputValue}
            name={inputName}
            placeholder={inputPlaceholder}
            onChange={onchange}
          />
          <div className="form-message__item form-message__item_error error-name">
            {invalid && invalidMessage}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { user } = this.props;
    const {
      investor,
      name,
      email,
      phoneNumber,
      messageType,
      verifyAddress,
      verifyFullname,
      verifyDob,
      verifyNumOfBricks,
      newAddress,
      newEmailAddress,
      newPhoneNumber,
      message,
    } = this.state;

    const notLoggedIn = !user.id;
    const messageTypeValue = messageType.value;
    const messageTypeOptions = [
      { enabled: true, label: 'General', value: 'general' },
      { enabled: true, label: 'Registration', value: 'registration' },
      { enabled: true, label: 'Specific Property', value: 'specific property' },
      { enabled: true, label: 'Buying Bricks', value: 'buying bricks' },
      { enabled: true, label: 'Feedback', value: 'feedback' },
      { enabled: true, label: 'My Account', value: 'my account' },
      {
        enabled: true,
        label: 'Update my Phone Number',
        value: messageTypes.UPDATE_PHONE_NUMBER,
      },
    ];
    const formInputs = {
      name: {
        label: 'Name',
        inputId: 'name',
        inputType: 'text',
        inputName: 'name',
        inputValue: name.value,
        inputPlaceholder: 'John',
        onchange: this._onNameChange,
        invalid: name.invalid,
        invalidMessage: 'Please enter your name',
      },
      email: {
        label: 'Email',
        inputId: 'email',
        inputType: 'email',
        inputName: 'email',
        inputValue: email.value,
        inputPlaceholder: 'john@gmail.com',
        onchange: this._onEmailChange,
        invalid: email.invalid,
        invalidMessage: 'Please enter a valid name',
      },
      phonenumber: {
        label: 'Phone number',
        inputId: 'phone',
        inputType: 'text',
        inputName: 'phone',
        inputValue: phoneNumber.value,
        inputPlaceholder: '0412 345 678',
        onchange: this._onPhoneChange,
        invalid: phoneNumber.invalid,
        invalidMessage: 'Please enter a valid phone number',
      },
      verifyFullname: {
        label: 'Full Name',
        inputId: 'verify-fullname',
        inputType: 'text',
        inputName: 'verify-fullname',
        inputValue: verifyFullname.value,
        inputPlaceholder: '',
        onchange: (e) =>
          this.setState({
            verifyFullname: { invalid: false, value: e.target.value },
          }),
        invalid: verifyFullname.invalid,
        invalidMessage: 'Please enter a valid full name',
      },
      verifyAddress: {
        label: 'Address',
        inputId: 'address',
        inputType: 'text',
        inputName: 'address',
        inputValue: verifyAddress.value,
        inputPlaceholder: '',
        onchange: (e) =>
          this.setState({
            verifyAddress: { invalid: false, value: e.target.value },
          }),
        invalid: verifyAddress.invalid,
        invalidMessage: 'Please enter a valid address',
      },
      verifyDob: {
        label: 'Date of Birth',
        inputId: 'dob',
        inputType: 'text',
        inputName: 'dob',
        inputValue: verifyDob.value,
        inputPlaceholder: 'e.g. 1980-01-01',
        onchange: (e) =>
          this.setState({
            verifyDob: { invalid: false, value: e.target.value },
          }),
        invalid: verifyDob.invalid,
        invalidMessage: 'Please enter a valid date of birth',
      },
      verifyNumOfBricks: {
        label: 'Number of Brickx Owned',
        inputId: 'nob',
        inputType: 'number',
        inputName: 'nob',
        inputValue: verifyNumOfBricks.value,
        inputPlaceholder: 'e.g. 200',
        onchange: (e) =>
          this.setState({
            verifyNumOfBricks: { invalid: false, value: e.target.value },
          }),
        invalid: verifyNumOfBricks.invalid,
        invalidMessage: 'Please enter a valid number',
      },
      newAddress: {
        label: 'New Address',
        inputId: 'newAddress',
        inputType: 'text',
        inputName: 'newAddress',
        inputValue: newAddress.value,
        inputPlaceholder: '',
        onchange: (e) =>
          this.setState({
            newAddress: { invalid: false, value: e.target.value },
          }),
        invalid: newAddress.invalid,
        invalidMessage: 'Please enter a valid address',
      },
      newEmailAddress: {
        label: 'New Email Address',
        inputId: 'newEmailAddress',
        inputType: 'email',
        inputName: 'newEmailAddress',
        inputValue: newEmailAddress.value,
        inputPlaceholder: '',
        onchange: (e) =>
          this.setState({
            newEmailAddress: { invalid: false, value: e.target.value },
          }),
        invalid: newEmailAddress.invalid,
        invalidMessage: 'Please enter an Email address',
      },
      newPhoneNumber: {
        label: 'New Phone Number',
        inputId: 'newPhoneNumber',
        inputType: 'text',
        inputName: 'newPhoneNumber',
        inputValue: newPhoneNumber.value,
        inputPlaceholder: '0412 345 789',
        onchange: (e) =>
          this.setState({
            newPhoneNumber: { invalid: false, value: e.target.value },
          }),
        invalid: newPhoneNumber.invalid,
        invalidMessage: 'Please enter a valid phone number',
      },
    };
    return (
      <div className="container contact-page">
        <ContactUsMeta />
        <h1>Contact Us</h1>
        <form className="contactForm" onSubmit={this._onContactSubmit}>
          <div className="row">
            <div className="col-md-12">
              <div className="form-container">
                {notLoggedIn && (
                  <div className="row form-input">
                    <div className="col-md-2 input-label">
                      Are you a BrickX Investor?
                    </div>
                    <div className="col-md-8">
                      <label>
                        <input
                          type="radio"
                          value={IS_INVESTOR}
                          id="is-investor"
                          name="investor"
                          onChange={this._onInvestorChange}
                        />{' '}
                        Yes
                      </label>
                      <label>
                        <input
                          type="radio"
                          value={NOT_INVESTOR}
                          id="not-investor"
                          name="investor"
                          onChange={this._onInvestorChange}
                        />{' '}
                        No
                      </label>
                      <div className="form-message__item form-message__item_error error-investor">
                        {investor.invalid && 'Please choose a value'}
                      </div>
                    </div>
                  </div>
                )}
                {this.renderInput(formInputs.name)}
                {this.renderInput(formInputs.email)}
                {this.renderInput(formInputs.phonenumber)}
                <div className="row form-input">
                  <div className="col-md-2 input-label">Message type:</div>
                  <div className="col-md-8">
                    <div className="select-box">
                      <select
                        name="messageType"
                        id="messageType"
                        value={messageType.value}
                        onChange={this._onMessageTypeChange}
                      >
                        <option value="">SELECT ONE</option>
                        {messageTypeOptions.map(
                          (o) =>
                            o.enabled && (
                              <option value={o.value}>{o.label}</option>
                            )
                        )}
                      </select>
                    </div>
                    <div className="form-message__item form-message__item_error error-message-type">
                      {messageType.invalid && 'Please select a message type'}
                    </div>
                  </div>
                </div>

                {!_.includes(
                  [messageTypes.UPDATE_PHONE_NUMBER],
                  messageTypeValue
                ) && (
                  <ContactMessage
                    message={message}
                    messageChange={this._onMessageChange}
                    placeholder="Explain your question, and how we will be able to help you."
                  />
                )}

                {messageTypeValue === messageTypes.UPDATE_PHONE_NUMBER && (
                  <Fragment>
                    <div className="row form-input">
                      <div className="col-md-10 input-label">
                        <InfoBox>
                          For the security of your BrickX account, we need to
                          complete a verification check before your details can
                          be changed. Please confirm your current registered
                          information:
                        </InfoBox>
                      </div>
                    </div>
                    {this.renderInput(formInputs.verifyFullname)}
                    {this.renderInput(formInputs.verifyAddress)}
                    {this.renderInput(formInputs.verifyDob)}
                    {this.renderInput(formInputs.verifyNumOfBricks)}
                    <br />
                    <br />
                    <br />
                    {this.renderInput(formInputs.newPhoneNumber)}
                  </Fragment>
                )}
                <ContactSubmitButton />
              </div>
            </div>
          </div>
        </form>
        <div className="contact-us">
          <div className="sub-contact-us">
            If you have any questions, we&apos;d be happy to help...
          </div>
          <ContactMethodsContainer>
            <div className="contact-box">
              <div className="title">
                <span className="icn icn-medium icn-email" />
                EMAIL US:{' '}
                <a href="mailto:info@brickx.com?subject=Contact Us Question: General">
                  info@brickx.com
                </a>
              </div>
            </div>
          </ContactMethodsContainer>
        </div>
      </div>
    );
  }

  _initState = (user) => {
    if (user.id) {
      this.setState({
        investor: {
          value: IS_INVESTOR,
          invalid: false,
        },
        name: {
          value: user.givenName,
          invalid: this.state.name.invalid,
        },
        email: {
          value: user.email,
          invalid: this.state.email.invalid,
        },
        phoneNumber: {
          value: user.mobilePhone,
          invalid: this.state.phoneNumber.invalid,
        },
      });
    }
  };

  _onInvestorChange = (e) => {
    this.setState({
      investor: {
        value: e.target.value,
      },
    });
  };

  _onNameChange = (e) => {
    this.setState({
      name: {
        value: e.target.value,
      },
    });
  };

  _onEmailChange = (e) => {
    this.setState({
      email: {
        value: e.target.value,
      },
    });
  };

  _onPhoneChange = (e) => {
    this.setState({
      phoneNumber: {
        value: e.target.value,
      },
    });
  };

  _onMessageTypeChange = (e) => {
    const messageType = e.target.value;

    if (messageType === messageTypes.UPDATE_PHONE_NUMBER) {
      this.setState({
        message: {
          value: `
Full Name: <strong>{{verifyFullname}}</strong>
Address: <strong>{{verifyAddress}}</strong>
DOB: <strong>{{verifyDob}}</strong>
Number of Bricks Owned: <strong>{{verifyNumOfBricks}}</strong>
---
New Phone Number: <strong>{{newPhoneNumber}}</strong>
`,
          invalid: false,
        },
        messageType: {
          value: messageType,
          invalid: false,
        },
      });
    } else {
      this.setState({
        message: { value: '', invalid: false },
        messageType: { value: messageType, invalid: false },
      });
    }
  };

  _onMessageChange = (e) => {
    this.setState({
      message: {
        value: e.target.value,
      },
    });
  };

  _focusOnInvalidInput = () => {
    const { investor, name, email, phoneNumber, messageType, message } =
      this.state;
    if (investor.invalid) {
      document.getElementById('is-investor').focus();
    } else if (name.invalid) {
      document.getElementById('name').focus();
    } else if (email.invalid) {
      document.getElementById('email').focus();
    } else if (phoneNumber.invalid) {
      document.getElementById('phone').focus();
    } else if (messageType.invalid) {
      document.getElementById('messageType').focus();
    } else if (message.invalid) {
      document.getElementById('message').focus();
    }
  };

  _validateForm = () => {
    const {
      investor,
      name,
      email,
      phoneNumber,
      messageType,
      message,
      verifyAddress,
      verifyFullname,
      verifyDob,
      verifyNumOfBricks,
      newAddress,
      newEmailAddress,
      newPhoneNumber,
    } = this.state;
    const isUpdateAddres = messageType.value === 'update my address';
    const isUpdateEmailAddress =
      messageType.value === 'update my email address';
    const isUpdatePhoneNumber =
      messageType.value === messageTypes.UPDATE_PHONE_NUMBER;
    const isUpdateBankDetails = messageType.value === 'update my bank details';
    const needVerify =
      isUpdateEmailAddress || isUpdatePhoneNumber || isUpdateBankDetails;
    const newState = {
      investor: { ...investor, invalid: investor.value === 0 },
      email: { ...email, invalid: !email.value.trim().match(Regex.email) },
      name: { ...name, invalid: !name.value.match(Regex.name) },
      phoneNumber: {
        ...phoneNumber,
        invalid: !phoneNumber.value.match(Regex.phone),
      },
      messageType: { ...messageType, invalid: messageType.value === '' },
      message: { ...message, invalid: message.value === '' },
      verifyAddress: {
        ...verifyAddress,
        invalid: needVerify && verifyAddress.value === '',
      },
      verifyFullname: {
        ...verifyFullname,
        invalid: needVerify && !verifyFullname.value.match(Regex.name),
      },
      verifyDob: {
        ...verifyDob,
        invalid: needVerify && verifyDob.value === '',
      },
      verifyNumOfBricks: {
        ...verifyNumOfBricks,
        invalid: needVerify && verifyNumOfBricks.value === '',
      },
      newAddress: {
        ...newAddress,
        invalid: isUpdateAddres && newAddress.value === '',
      },
      newEmailAddress: {
        ...newEmailAddress,
        invalid:
          isUpdateEmailAddress &&
          !newEmailAddress.value.trim().match(Regex.email),
      },
      newPhoneNumber: {
        ...newPhoneNumber,
        invalid:
          isUpdatePhoneNumber && !newPhoneNumber.value.match(Regex.phone),
      },
    };
    this.setState(newState);
    const isFormValid = _.every([
      !newState.investor.invalid,
      !newState.name.invalid,
      !newState.email.invalid,
      !newState.phoneNumber.invalid,
      !newState.messageType.invalid,
      !newState.message.invalid,
      needVerify
        ? !newState.verifyAddress.invalid &&
          !newState.verifyFullname.invalid &&
          !newState.verifyDob.invalid &&
          !newState.verifyNumOfBricks.invalid
        : true,
      isUpdateAddres ? !newState.newAddress.invalid : true,
      isUpdateEmailAddress ? !newState.newEmailAddress.invalid : true,
      isUpdatePhoneNumber ? !newState.newPhoneNumber.invalid : true,
    ]);
    if (!isFormValid) {
      this._focusOnInvalidInput();
    }
    return isFormValid;
  };

  _resetForm = () => {
    this.setState({
      formError: '',
      messageType: {
        value: '',
        invalid: false,
      },
      message: {
        value: '',
        invalid: false,
      },
      verifyFullname: { value: '', invalid: false },
      verifyAddress: { value: '', invalid: false },
      verifyDob: { value: '', invalid: false },
      verifyNumOfBricks: { value: '', invalid: false },
      newEmailAddress: { value: '', invalid: false },
      newPhoneNumber: { value: '', invalid: false },
      newAddress: { value: '', invalid: false },
      attachment1: {
        name: '',
        type: '',
        file: '',
      },
      attachment2: {
        name: '',
        type: '',
        file: '',
      },
    });
  };

  _onContactSubmit = (e) => {
    e.preventDefault();
    const isFormValid = this._validateForm();
    if (!isFormValid) {
      return;
    }
    const { name, email, phoneNumber, messageType, message } = this.state;
    const { contact } = this.props;
    const formattedPhone = phoneNumber.value
      ? phoneNumber.value::countryCodePhoneNumber()
      : 'N/A';

    const embedToMessage = (message, withSearch) => {
      let newMessage = message;
      for (const m of [
        'newAddress',
        'verifyFullname',
        'verifyAddress',
        'verifyDob',
        'verifyNumOfBricks',
        'newEmailAddress',
        'newPhoneNumber',
      ]) {
        if (_.includes(message, m)) {
          newMessage = newMessage.replace(`{{${m}}}`, this.state[m].value);
        }
      }
      if (withSearch) {
        newMessage += `\n\n<a href="https://api.brickx.com/admin/finduser?email=${email.value}" target="_blank">🔍 Search This User</a>`;
      }
      return newMessage;
    };

    const payload = {
      investor: this.state.investor.value === IS_INVESTOR,
      name: name.value,
      email: email.value,
      phoneNumber: formattedPhone,
      messageType: messageType.value,
      message: embedToMessage(
        message.value,
        _.includes([messageTypes.UPDATE_PHONE_NUMBER], messageType.value)
      )
        .split('\n')
        .join('<br>'),
    };
    contact(payload);
    this._resetForm();
  };
}
