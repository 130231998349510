const getYoutubeApi = (() => {
  let YTPromise;

  return () => {
    if (YTPromise) return YTPromise;

    YTPromise = new Promise((resolve) => {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      window.onYouTubeIframeAPIReady = function () {
        resolve(window.YT);
      };
    });

    return YTPromise;
  };
})();

export default getYoutubeApi;
