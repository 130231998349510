import { handleActions } from 'redux-actions';
const update = require('immutability-helper');

export const SEGMENT_EVENT = 'scripts/segment/events';

const reducer = handleActions({
  [SEGMENT_EVENT]: (state, action) => update(state, {
    $set: action.payload
  })
}, null);

export default reducer;
