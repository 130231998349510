import { transactionFeeText } from 'src/settings/text';
import TermsPage from 'src/components/terms/TermsPage';
import styles from 'src/components/terms/Terms.mscss';
import { pdsUrl } from 'src/utils/pageUrls';

const FeesPromo2022 = () => (
  <TermsPage title="No Transaction Fees Promo August-December 2022 - Terms and conditions">
    <ol className={styles.termsList}>
      <li>
        This promotion is offered by The Brick Exchange Pty Limited (ABN 27 600
        762 749) (BrickX) as the manager of the BrickX Platform (ARSN 613 156
        433). BrickX Financial Services Limited (ABN 67 616 118 088, AFSL
        494878) is the product issuer for the BrickX Platform, but is not
        responsible for offering or administering this BrickX promotion.
      </li>
      <li>
        The transaction fee of {transactionFeeText()} on Brick purchases and
        Brick sales is being waived and will be fully funded by BrickX from its
        own funds and will not be sourced from the assets of the BrickX Platform
        or any BrickX Trust.
      </li>
      <li>
        This promotion is available for all transactions on the BrickX platform
        (purchases and sales of Bricks) during the Promotion Period. To be
        eligible, you must be or become a Member of the BrickX Platform
        including ensuring that you have accessed, read and understood the
        following important documents:
        <br />
        <br />
        <ul>
          <li>Product Disclosure Statement;</li>
          <li>Target Market Determination;</li>
          <li>Financial Services Guide; &</li>
          <li>Additional Disclosure Documents;</li>
        </ul>
        <br />
        which can be accessed <a href={pdsUrl()}>here</a>
      </li>
      <li>
        This promotion opens at 10:00 am Melbourne time on Wednesday 3rd August
        2022 and closes at 10.00 am Melbourne time on Saturday 31st December
        2022 (Promotion Period).
      </li>
      <li>
        You should consider any tax implications and we recommend you seek
        independent tax advice.
      </li>
      <li>
        Capitalised terms have the meaning given in the BrickX Platform product
        disclosure statement unless otherwise defined.
      </li>
      <li>
        Please note that BPAY payments can take up to 1-2 business days to be
        received and appear as cleared funds in your Digital Wallet. Direct
        Debits take up to 4 business days to clear. Payments can also be made
        through POLi to provide instant funds (up to $20,000).
      </li>
      <li>
        Please note that the promotion only applies to bricks transacted during
        the Promotion Period. Pending sell orders that do not execute before
        10.00 a.m. Melbourne time on 31 December 2022 will not attract the
        waiver of the usual 0.5% transaction fee.
      </li>
      <li>
        All questions relating to this promotion should be directed to BrickX at{' '}
        <a href="mailto:info@brickx.com">info@brickx.com</a>.
      </li>
    </ol>
  </TermsPage>
);

export default FeesPromo2022;
