import moment from 'moment-timezone';

import Configuration from 'scripts/constants/Configuration';
import DateTime from 'scripts/constants/DateTime';
import Storage from 'scripts/utilities/Storage';

const storage = new Storage(localStorage);

export function setReferral(referralRegistration, isPartnership = false) {
  const programme = {
    referralId: referralRegistration.referralRecordId,
    isPartnership: isPartnership,
    expiryTimeStamp: moment(referralRegistration.activationDueDate).tz(DateTime.TIME_ZONE)
  };
  storage.setItem(Configuration.LOCAL_SESSION_STORAGE.REFERRAL, programme);
}

export function clearReferral() {
  const programme = {
    referralId: undefined,
    isPartnership: undefined
  };
  storage.setItem(Configuration.LOCAL_SESSION_STORAGE.REFERRAL, programme);
}

export function getReferral() {
  let result = {
    customerReferralId: undefined,
    partnerReferralId: undefined
  };
  const referral = storage.getItem(Configuration.LOCAL_SESSION_STORAGE.REFERRAL);
  if (referral && referral.expiryTimeStamp) {
    const isExpired = moment().tz(DateTime.TIME_ZONE).isAfter(moment(referral.expiryTimeStamp).tz(DateTime.TIME_ZONE));
    if (!isExpired) {
      result = {
        customerReferralId: referral.isPartnership ? undefined : referral.referralId,
        partnerReferralId: referral.isPartnership ? referral.referralId : undefined
      };
    }
  }
  return result;
}
